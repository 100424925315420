import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const LandingHomePage = Loadable(lazy(() => import('./pages/LandingHomePage')));
const LandingProductPage = Loadable(lazy(() => import('./pages/LandingProductPage')));
const LandingFeaturesPage = Loadable(lazy(() => import('./pages/LandingFeaturesPage')));
const LandingWhyUsPage = Loadable(lazy(() => import('./pages/LandingWhyUsPage')));
const LandingPricingPage = Loadable(lazy(() => import('./pages/LandingPricingPage')));
const LandingContactPage = Loadable(lazy(() => import('./pages/LandingContactPage')));
const NavPage = Loadable(lazy(() => import('./pages/NavPage')));
const StagesPage = Loadable(lazy(() => import('./pages/StagesPage')));
const FunnelsPage = Loadable(lazy(() => import('./pages/FunnelsPage')));
const FunnelCreatePage = Loadable(lazy(() => import('./pages/FunnelCreatePage')));
const FunnelUpdatePage = Loadable(lazy(() => import('./pages/FunnelUpdatePage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const EmailPage = Loadable(lazy(() => import('./pages/EmailPage')));
const ContactsPage = Loadable(lazy(() => import('./pages/ContactsPage')));
const ContactsBulkTagsPage = Loadable(lazy(() => import('./pages/ContactsBulkTagsPage')));
const ContactCreatePage = Loadable(lazy(() => import('./pages/ContactCreatePage')));
const ContactEditPage = Loadable(lazy(() => import('./pages/ContactEditPage')));
const ContactDetailsPage = Loadable(lazy(() => import('./pages/ContactDetailsPage')));
const CRMPage = Loadable(lazy(() => import('./pages/CRMPage')));
const ContactCRMPage = Loadable(lazy(() => import('./pages/ContactCRMPage')));
const SurveysPage = Loadable(lazy(() => import('./pages/SurveysPage')));
const SurveyEditPage = Loadable(lazy(() => import('./pages/SurveyEditPage')));
const SurveyAnalyticsPage = Loadable(lazy(() => import('./pages/SurveyAnalyticsPage')));
const SurveyTakePage = Loadable(lazy(() => import('./pages/SurveyTakePage')));
const SurveyShareContainerPage = Loadable(lazy(() => import('./pages/SurveyShareContainerPage')));
const FormsPage = Loadable(lazy(() => import('./pages/FormsPage')));
const FormsEditPage = Loadable(lazy(() => import('./pages/FormsEditPage')));
const FormsSubscribeContainerPage = Loadable(lazy(() => import('./pages/FormsSubscribeContainerPage')));
const FormsSubscribePage = Loadable(lazy(() => import('./pages/FormsSubscribePage')));
const CustomFieldsPage = Loadable(lazy(() => import('./pages/CustomFieldsPage')));
const CampaignStatusPage = Loadable(lazy(() => import('./pages/CampaignStatusPage')));
const AutomationPage = Loadable(lazy(() => import('./pages/AutomationPage')));
const IconPreviewPage = Loadable(lazy(() => import('./pages/IconPreviewPage')));
const CsvUploadPage = Loadable(lazy(() => import('./pages/CsvUploadPage')));
const CsvUploadQueuedPage = Loadable(lazy(() => import('./pages/CsvUploadQueuedPage')));
const CsvImportListPage = Loadable(lazy(() => import('./pages/CsvImportListPage')));
const PricingPage = Loadable(lazy(() => import('./pages/PricingPage')));
const FeedbackPanelPage = Loadable(lazy(() => import('./pages/FeedbackPanelPage')));
const TagsListPage = Loadable(lazy(() => import('./pages/TagsListPage')));
const TagSingle = Loadable(lazy(() => import('./singles/TagSingle')));
const CompleteRegistrationPage = Loadable(lazy(() => import('./pages/CompleteRegistrationPage')));
const EmailVerificationPage = Loadable(lazy(() => import('./pages/EmailVerificationPage')));
const EmailBuilderPage = Loadable(lazy(() => import('./pages/EmailBuilderPage')));
const EmailTemplatesPage = Loadable(lazy(() => import('./pages/EmailTemplatesPage')));
const EmailTemplatePage = Loadable(lazy(() => import('./pages/EmailTemplatePage')));
const SignupPage = Loadable(lazy(() => import('./pages/SignupPage')));
const SignupRegisterPage = Loadable(lazy(() => import('./pages/SignupRegisterPage')));
const SignupTenantPage = Loadable(lazy(() => import('./pages/SignupTenantPage')));
const SignupTenantDetailsPage = Loadable(lazy(() => import('./pages/SignupTenantDetailsPage')));
const SignupSubscribePage = Loadable(lazy(() => import('./pages/SignupSubscribePage')));
const SideMenuPage = Loadable(lazy(() => import('./pages/SideMenuPage')));
const SenderDetailsWizardPage = Loadable(lazy(() => import('./pages/SenderDetailsWizardPage')));
const SenderDetailsPage = Loadable(lazy(() => import('./pages/SenderDetailsPage')));
const PaymentPortalPage = Loadable(lazy(() => import('./pages/PaymentPortalPage')));
const SurveyResponsesPage = Loadable(lazy(() => import('./pages/SurveyResponsesPage')));
const SubuserManagementPage = Loadable(lazy(() => import('./pages/SubuserManagementPage')));
const IndustryDetailPage = Loadable(lazy(() => import('./pages/IndustryDetailPage')));
const UsagePage = Loadable(lazy(() => import('./pages/UsagePage')));
const TermsPage = Loadable(lazy(() => import('./pages/TermsPage')));
const TermsServicePage = Loadable(lazy(() => import('./pages/TermsServicePage')));
const TermsPrivacyPage = Loadable(lazy(() => import('./pages/TermsPrivacyPage')));
const TermsCookiesPage = Loadable(lazy(() => import('./pages/TermsCookiesPage')));

export const userRoutes = [
  { path: '/', element: <LandingHomePage /> },
  { path: 'product', element: <LandingProductPage /> },
  { path: 'features', element: <LandingFeaturesPage /> },
  { path: 'why', element: <LandingWhyUsPage /> },
  { path: 'prices', element: <LandingPricingPage /> },
  { path: 'contact', element: <LandingContactPage /> },
  { path: 'nav', element: <NavPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'accounts/login', element: <LoginPage /> },
  { path: 'signup', element: <SignupPage /> },
  { path: 'register', element: <SignupRegisterPage /> },
  { path: 'register_tenant', element: <SignupTenantPage /> },
  { path: 'register_details', element: <SignupTenantDetailsPage /> },
  { path: 'subscribe', element: <SignupSubscribePage /> },
  { path: 'crm', element: <CRMPage /> },
  { path: 'contacts-crm', element: <ContactCRMPage /> },
  { path: 'contacts', element: <ContactCRMPage /> },
  { path: 'contacts/bulk-tags', element: <ContactsBulkTagsPage /> },
  { path: 'tags/tags-list', element: <TagsListPage /> },
  { path: 'contacts/:reactContactId', element: <ContactDetailsPage /> },
  { path: 'single-tag', element: <TagSingle /> },
  { path: 'stages', element: <StagesPage /> },
  { path: 'funnels', element: <FunnelsPage /> },
  { path: 'funnel-create', element: <FunnelCreatePage /> },
  { path: 'funnel-update/:reactFunnelId', element: <FunnelUpdatePage /> },
  { path: 'automation', element: <FunnelsPage /> },
  { path: 'csv-upload', element: <CsvUploadPage /> },
  { path: 'csv-queued', element: <CsvUploadQueuedPage /> },
  { path: 'csv-imports', element: <CsvImportListPage /> },
  { path: 'email', element: <EmailPage /> },
  { path: 'add-contact', element: <ContactCreatePage /> },
  { path: 'edit-contact', element: <ContactEditPage /> },
  { path: 'surveys', element: <SurveysPage /> },
  { path: 'surveys/add', element: <SurveyEditPage /> },
  { path: 'surveys/:reactSurveyId/edit', element: <SurveyEditPage /> },
  { path: 'surveys/:reactSurveyId/detail', element: <SurveyAnalyticsPage /> },
  { path: 'surveys/:reactSurveyId/view', element: <SurveyTakePage /> },
  { path: 'surveys/survey-share-page/:tokenId', element: <SurveyShareContainerPage /> },
  { path: 'forms', element: <FormsPage /> },
  { path: 'forms/new', element: <FormsEditPage /> },
  { path: 'forms/:reactFormId/edit', element: <FormsEditPage /> },
  { path: 'forms/display', element: <FormsSubscribeContainerPage /> },
  { path: 'forms/actions/create_subscription/:tokenId', element: <FormsSubscribeContainerPage /> },
  { path: 'forms/subscribe', element: <FormsSubscribePage /> },
  { path: 'custom-fields', element: <CustomFieldsPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'feedback-panel', element: <FeedbackPanelPage /> },
  { path: 'icon-preview', element: <IconPreviewPage /> },
  { path: 'complete-registration', element: <CompleteRegistrationPage /> },
  { path: 'verify-email', element: <EmailVerificationPage /> },
  { path: 'email-builder/:reactTemplateId', element: <EmailBuilderPage /> },
  { path: 'email-builder', element: <EmailTemplatesPage /> },
  { path: 'templates', element: <EmailTemplatesPage /> },
  { path: 'templates/new', element: <EmailTemplatePage /> },
  { path: 'templates/:reactTemplateId', element: <EmailTemplatePage /> },
  { path: 'campaign-status', element: <CampaignStatusPage /> },
  { path: 'side-menu', element: <SideMenuPage /> },
  { path: 'settings/sender-details', element: <SenderDetailsWizardPage /> },
  { path: 'surveys/:reactSurveyId/responses', element: <SurveyResponsesPage />},
  { path: 'accounts/user-management', element: <SubuserManagementPage /> },
  { path: 'accounts/usage', element: <UsagePage /> },
  { path: 'accounts/customer-portal', element: <PaymentPortalPage /> },
  { path: 'industry/*', element: <IndustryDetailPage /> },
  { path: 'terms', element: <TermsPage /> },
  { path: 'terms/privacy', element: <TermsPrivacyPage /> },
  { path: 'terms/service', element: <TermsServicePage /> },
  { path: 'terms/cookies', element: <TermsCookiesPage /> },
  {
    // path: '/*', element: <NotFoundPage />
  }
];