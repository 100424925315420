import React, { useState } from 'react';
import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-newsletter';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { createEmailTemplate, getEmailTemplateFolder } from '../../hooks/mutations';
import { getRandomInts } from '../../lib/Functions';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { InputField } from '../ui/Fields';
import { AddSVG, CloseRoundedSVG } from '../../assets/icons';

const NewEmailTemplateForm = (props) => {
  const { handleCloseCreateTemplate, setReset } = props;
  const [snackMessage, setSnackMessage] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [creating, setCreating] = useState(false);

  const theme = useTheme();

  const remoteStoragePlugin = (editor) => {

    editor.Storage.add('remote', {

      //*** NON-IDEAL HACK TO PREVENT 'STORAGE.LOAD IS NOT A FUNCTION' JS ERROR:
      // PASS IN SEEDED DATA FOR EDITOR TO LOAD IN HIDDEN COLLAPSABLE ELEMENT IN EMAILTEMPLATESMENU COMPONENT ***//
      async load() {
        const result = 
        // {
        //   data: {"assets":[],"styles":[],"pages":[{"frames":[{"component":{"type":"wrapper","stylable":["background","background-color","background-image","background-repeat","background-attachment","background-position","background-size"]},"id":"KIXcrfRZQ4AlL8PZ"}],"type":"main","id":"Eal7lkIrOh4SKg0F"}]}
        // }
        {
          data:{"assets":[],"styles":[{"selectors":["#ic4z"],"style":{"padding":"10px","text-align":"center","font-family":"Arial, Helvetica, sans-serif"}}],"pages":[{"frames":[{"component":{"type":"wrapper","stylable":["background","background-color","background-image","background-repeat","background-attachment","background-position","background-size"],"attributes":{"id":"ii5o"},"components":[{"type":"text","attributes":{"id":"ic4z"},"components":[{"type":"textnode","content":"{-{unsub}-}"}]}]},"id":"KIXcrfRZQ4AlL8PZ"}],"type":"main","id":"Eal7lkIrOh4SKg0F"}]},"content":"<body id=\"ii5o\" style=\"box-sizing: border-box; margin: 0;\"><div id=\"ic4z\" style=\"box-sizing: border-box; padding: 10px; text-align: center; font-family: Arial, Helvetica, sans-serif;\">{-{unsub}-}</div></body>","existing":true
        }

        return result;
      },

      async store(data) {
        try {
          const storageFolder = await getEmailTemplateFolder();
          const storageFolderId = storageFolder.folderId;
          // console.log('storageFolderId', storageFolderId);
          const jsonBlob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
          const fileName = 'template.json';
          const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
          const formData = new FormData();
          formData.append('file', jsonFileObj);
          formData.append('name', templateName);
          formData.append('folder', storageFolderId);

          const emailTemplateObj = {
            storageFolderId: storageFolderId,
            templatePayload: {
              'name': templateName,
              'content': '',
              'update_date': new Date(),
              'editor_json': null,
              'last_used_campaign': null 
            },
            storagePayload: formData
          }

          const savedTemplate = await createEmailTemplate(emailTemplateObj);
          if (savedTemplate.status === "success") {
            setSnackMessage({open: true, message: 'New email template created', severity:'success'})
            setTimeout(() => {
              window.location.href = `/templates/${savedTemplate.newTemplateData.id}`
            }, 500);
          } else if (savedTemplate.status === "error") {
            setSnackMessage({open: true, message: 'Error creating email template', severity:'error'})
            setCreating(false);
          }
        } catch (error) {
          console.log('Error creating new email template', error)
          setSnackMessage({open: true, message: 'Error creating email template', severity:'error'})
        }
      }
    });
  }

  const editor = grapesjs.init({
    container: '#gjs',
    storageManager: {
      type: 'remote',
      options: {
        remote: {
          //Enrich remote call to include HTML/CSS of pages along with IDs and data
          onStore: (data, editor) => {

            //***THESE TWO LINES FOR DEBUGGING PURPOSES ONLY ***//
            // console.log(editor.runCommand('gjs-get-inlined-html'));
            // console.log(JSON.stringify(editor.getProjectData()));

            const htmlWithCss = editor.runCommand(`gjs-get-inlined-html`);
            return { data, content: htmlWithCss }
          },
          onLoad: result => result.data,
          contentTypeJson: true
        }
      }
    },
    plugins: [plugin, remoteStoragePlugin]
  });

  const createNewTemplate = async () => {
    if (templateName != null && templateName != '') {
      setCreating(true);
      await editor.store();
    } else {
      return;
    }
  }

  return (
      <Grid
        container
        style={{
          // justifyContent: 'center',
        }}
      >
        <Grid
          style={{
            width:'100%',
            // padding: 24
          }}
          // md={8}
        >
          <InputField
            label="Template Name"
            placeholder="Enter a name for the template"
            fullWidth
            singleLine
            value={templateName}
            onChange={(e) => { setTemplateName(e.target.value); }}
            required
          />

          <Grid
            container
            style={{
              marginTop:24,
              justifyContent:'space-around'
            }}
          >
            <Button
              color="mid"
              variant="outlined"
              startIcon={<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />}
              onClick={handleCloseCreateTemplate}
              style={{
                minWidth:120
              }}
            >
              Nevermind
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={templateName != null && templateName != '' ? false : true}
              startIcon={creating ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : <AddSVG width={12} color1="#fff" />}
              onClick={createNewTemplate}
              style={{
                minWidth:120
              }}
            >
              Create Template
            </Button>
          </Grid>

          {
            creating &&
              <Grid container variant="center" style={{marginTop:16}}>
                <Typography>Please wait while we create your template.</Typography>
              </Grid>
          }

        </Grid>

        <SnackbarWrapper
          onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
          notice={snackMessage}
        />

      </Grid>
    
  );
}

export default NewEmailTemplateForm;