import React, { useState } from 'react'
import { Button, Card, Grid, Typography } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { useLocation } from 'react-router-dom';
import { api_endpoint, auth_request_headers } from '../../lib/Backend';
import axios from 'axios';



const VerifyMessage = () => {
  const [email, setEmail] = useState();
  const [resent, setResent] = useState(false);
  const location = useLocation();

  const resendVerificationEmail = async () => {
      try {
        setEmail(location.state.email);
        //*** USING DUMMY API BELOW FOR DEMO PURPOSES ***/
        const response = await axios.post(api_endpoint() + `accounts/api/resend-email`, {
          'email': email,
        }).catch( function (error) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          if (error.response) {        
            console.log(error.message);
            console.log(error.response.data);
        }
      });
    
        console.log('Email sent successfully!', response.data);
      } catch (error) {
        console.log('There was an error communicating with the email verification api endpoint')
      }
    setResent(true);
  }
  

  
  return (
    <>
      <Grid
        container
        sx={{
          height:'80vh',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
        <Grid
          zIndex={1}
          display="flex"
          alignItems="center"
          flexDirection="column"
          ts={12}
          sm={10}
          md={8}
          lg={6}
        >
          <Card
            sx={{
              maxWidth:400
            }}
          >
            <Grid
              variant="center"
              flexDirection="column"
              sx={{
                // justifyContent:'center'
              }}
            >
              <Typography textAlign="center">
                {`A confirmation email has been sent to ${location.state?.email ? location.state.email : 'your email'}. Confirm your email and login!`}
              </Typography>

              <Button
                variant="contained-thin"
                style={{
                  marginTop:16
                }}
                href="/accounts/login/"
              >
                Go to login
              </Button>

              <Grid
                variant="center"
                style={{
                  marginTop:16
                }}
              >
                {
                  resent
                    ?
                      <Typography>
                        Email Sent.
                      </Typography>
                    :
                      <Button
                        onClick={resendVerificationEmail}
                      >
                        Resend Email Verification
                      </Button>
                }
              </Grid>
                
            </Grid>
          </Card>
        </Grid>

      </Grid>
      

    </>
  )
}

export default VerifyMessage