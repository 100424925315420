import './policy-css.css';

export const cookie_policy_html = `


<body>
  <h1 class="c26 c42" id="h.2ls8ll4ekp68"><span class="c38">COOKIE POLICY</span></h1>
  <p class="c5"><span class="c16">Effective date: August 01, 2024</span></p>
  <p class="c5"><span class="c16">Last updated July 05, 2024</span></p>
  <p class="c5 c10"><span class="c16"></span></p>
  <div class="table-of-contents">
  <p class="c5"><span class="c14">1. <a class="c9" href="#h.a2jx8u1rqpr4">WHAT ARE COOKIES?</a></span></p>
  <p class="c5"><span class="c14">2. <a class="c9" href="#h.hr6ojpvvngnx">WHY DO WE USE COOKIES?</a></span></p>
  <p class="c5"><span class="c14">3. <a class="c9" href="#h.hfmedqom91oj">HOW CAN I CONTROL COOKIES?</a></span></p>
  <p class="c5"><span class="c14">4. <a class="c9" href="#h.uvydetgo27nh">HOW CAN I CONTROL COOKIES ON MY
        BROWSER?</a></span></p>
  <p class="c5"><span class="c14">5. <a class="c9" href="#h.5d0ymln3jae8">WHAT ABOUT OTHER TRACKING
        TECHNOLOGIES?</a></span></p>
  <p class="c5"><span class="c14">6. <a class="c9" href="#h.h1n4o4cpl46w">DO YOU USE FLASH COOKIES OR LOCAL SHARED
        OBJECTS?</a></span></p>
  <p class="c5"><span class="c14">7. <a class="c9" href="#h.ejolvi87b7ei">DO YOU SERVE TARGETED ADVERTISING?</a></span>
  </p>
  <p class="c5"><span class="c14">8. <a class="c9" href="#h.h4qul4b5brbr">HOW OFTEN WILL YOU UPDATE THIS COOKIE
        POLICY?</a></span></p>
  <p class="c5"><span class="c14">9. <a class="c9" href="#h.36pdl62t781e">WHERE CAN I GET FURTHER
        INFORMATION?</a></span></p>
  </div>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span>This Cookie Policy explains how Fill (&quot;</span><span>Company</span><span>,&quot;
      &quot;</span><span>we</span><span>,&quot; &quot;</span><span>us</span><span>,&quot; and
      &quot;</span><span>our</span><span>&quot;) uses cookies and similar technologies to recognize you when you visit
      our website at &nbsp;</span><span><a class="c9"
        href="https://www.google.com/url?q=https://fillmarketing.com/&amp;sa=D&amp;source=editors&amp;ust=1723415943090165&amp;usg=AOvVaw1poXFSj_9DV-1d3cvw0E1s">https://fillmarketing.com</a></span><span>&nbsp;(&quot;</span><span>Website</span><span>&quot;).
      It explains what these technologies are and why we use them, as well as your rights to control our use of
      them.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">In some cases we may use cookies to collect personal information, or that becomes
      personal information if we combine it with other information.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.a2jx8u1rqpr4"><span>WHAT ARE COOKIES?</span></h2>
  <p class="c5"><span class="c0">Cookies are small data files that are placed on your computer or mobile device when you
      visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more
      efficiently, as well as to provide reporting information.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">Cookies set by the website owner (in this case, Fill) are called &quot;first-party
      cookies.&quot; Cookies set by parties other than the website owner are called &quot;third-party cookies.&quot;
      Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g.,
      advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize
      your computer both when it visits the website in question and also when it visits certain other websites.</span>
  </p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.hr6ojpvvngnx"><span class="c17">WHY DO WE </span><span>USE </span><span
      class="c17">COOKIES?</span></h2>
  <p class="c5"><span class="c0">We use first- and third-party cookies for several reasons. Some cookies are required
      for technical reasons in order for our Website to operate, and we refer to these as &quot;essential&quot; or
      &quot;strictly necessary&quot; cookies. Other cookies also enable us to track and target the interests of our
      users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for
      advertising, analytics, and other purposes. This is described in more detail below.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.hfmedqom91oj"><span>HOW CAN I CONTROL COOKIES?</span></h2>
  <p class="c5"><span class="c0">You have the right to decide whether to accept or reject cookies. You can exercise your
      cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to
      select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly
      necessary to provide you with services.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">The Cookie Consent Manager can be found in the notification banner and on our website.
      If you choose to reject cookies, you may still use our website though your access to some functionality and areas
      of our website may be restricted. You may also set or amend your web browser controls to accept or refuse
      cookies.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">The specific types of first- and third-party cookies served through our Website and the
      purposes they perform are described in the table below (please note that the specific cookies served may vary
      depending on the specific Online Properties you visit):</span></p>
  <p class="c5 c10"><span class="c25"></span></p>
  <h3 class="c5 c26" id="h.l2mtazuu1f0w"><span class="c25">Essential website cookies:</span></h3>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">These cookies are strictly necessary to provide you with services available through our
      Website and to use some of its features, such as access to secure areas.</span></p><a
    id="t.f0328a56d25ab936b52b7d1425935746c317ed3e"></a><a id="t.0"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c1" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">csrftoken</span></p>
      </td>
    </tr>
    <tr class="c31">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Purpose:</span></p>
      </td>
      <td class="c1" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">Helps prevent Cross-Site Request Forgery (CSRF) attacks using Javascript. It is a
            first party cookie with unique value. It expires in 1 year or until a user submits a form.</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c1" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">.fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Service:</span></p>
      </td>
      <td class="c1" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">Django </span><span class="c21"><a class="c9"
              href="https://www.google.com/url?q=https://www.djangoproject.com/foundation/faq/&amp;sa=D&amp;source=editors&amp;ust=1723415943097682&amp;usg=AOvVaw2q5bfsYLaAphzYa_MkbuL6">View
              Service Privacy Policy</a></span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c1" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">server_cookie</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c1" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">11 months 29 days</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p>
  <h3 class="c5 c26" id="h.dbkreecn7t51"><span class="c25">Unclassified cookies:</span></h3>
  <p class="c18"><span class="c0">These are cookies that have not yet been categorized. We are in the process of
      classifying these cookies with the help of their providers.</span></p><a
    id="t.c48ad41e8ae7d648b1aa7a8863cc7daf0b9d1889"></a><a id="t.1"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c29" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_posthog</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c29" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c29" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_local_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c29" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">persistent</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.60c5ba293e767148cc2b77176c6b85bc9a275233"></a><a id="t.2"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c23" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_window_id</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c23" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c23" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_session_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c23" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">session</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.fc1309e553114a2a3a3e17295ff9d4ae3b21a5a7"></a><a id="t.3"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c27" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">userDataBagContext</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c27" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c27" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_local_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c27" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">persistent</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.ef7eb961d51ef6a04d0021262dd53bc46ca3abd7"></a><a id="t.4"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c34" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">tenantContext</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c34" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c34" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_local_storag</span><span class="c44">e</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c34" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">persistent</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.1af5ec35019ad9ed7d2909bf7cb1d57d69051023"></a><a id="t.5"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c40" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_posthog</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c40" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">.fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c40" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">http_cookie</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c40" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">11 months 30 days</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.7d80800f212c6a85ccb7d9adc26deab9ddd74022"></a><a id="t.6"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c32" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">_fl_sess_dja_lf</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c32" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">.fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c32" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">server_cookie</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c13" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c32" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">14 days</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.20b0d4c6604a5d04be2cfd2ddd9a2098d9400aa5"></a><a id="t.7"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c28" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">userContext</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c28" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c28" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_local_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c28" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">persistent</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.abc6860946a732bfb95fee49898f4422440888fb"></a><a id="t.8"></a>
  <table class="c7">
    <tr class="c43">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c8" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_primary_window_exists</span>
        </p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c8" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c8" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_session_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c8" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">session</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.6b20cfa5c61f6cc94c18b279a4180af3dbde1d88"></a><a id="t.9"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c22" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">tenantDataBagContext</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c22" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c22" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_local_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c22" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">persistent</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p><a id="t.0adf8f4e92d378b23e096fdee7c61d36903429bb"></a><a id="t.10"></a>
  <table class="c7">
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Name:</span></p>
      </td>
      <td class="c30" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">customFieldsContext</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Provider:</span></p>
      </td>
      <td class="c30" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">fillmarketing.com</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Type:</span></p>
      </td>
      <td class="c30" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">html_local_storage</span></p>
      </td>
    </tr>
    <tr class="c12">
      <td class="c4" colspan="1" rowspan="1">
        <p class="c3"><span class="c6">Expires in:</span></p>
      </td>
      <td class="c30" colspan="1" rowspan="1">
        <p class="c5"><span class="c2">persistent</span></p>
      </td>
    </tr>
  </table>
  <p class="c5 c10"><span class="c0"></span></p>
  <h2 class="c15" id="h.uvydetgo27nh"><span>HOW CAN I CONTROL COOKIES ON MY BROWSER?</span></h2>
  <p class="c5"><span class="c0">As the means by which you can refuse cookies through your web browser controls vary
      from browser to browser, you should visit your browser&#39;s help menu for more information. The following is
      information about how to manage cookies on the most popular browsers:</span></p>
  <ul class="c35 lst-kix_ptkiw6b29ymb-0 start">
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647%23zippy%3D%252Callow-or-block-cookies&amp;sa=D&amp;source=editors&amp;ust=1723415943128770&amp;usg=AOvVaw3xNSg1QQdx5UxHE-HldCt1">Chrome</a></span>
    </li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d&amp;sa=D&amp;source=editors&amp;ust=1723415943129280&amp;usg=AOvVaw3adPuWxtwObKafvNcVOAuv">Internet
          Explorer</a></span></li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug%3Denable-and-disable-cookies-website-preferences%26redirectlocale%3Den-US&amp;sa=D&amp;source=editors&amp;ust=1723415943129624&amp;usg=AOvVaw2D9x-_UBVxx57lyufdSuEN">Firefox</a></span>
    </li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://support.apple.com/en-ie/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1723415943129900&amp;usg=AOvVaw2_wPbs47JdlacxwD7ULfwi">Safari</a></span>
    </li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd&amp;sa=D&amp;source=editors&amp;ust=1723415943130216&amp;usg=AOvVaw2mGBp1bX6nd1Pr4UuYuMT5">Edge</a></span>
    </li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://help.opera.com/en/latest/web-preferences/&amp;sa=D&amp;source=editors&amp;ust=1723415943130490&amp;usg=AOvVaw2Mf5VbepXxvWwbiuT1ppa5">Opera</a></span>
    </li>
  </ul>
  <p class="c5"><span class="c0">In addition, most advertising networks offer you a way to opt out of targeted
      advertising. If you would like to find out more information, please visit:</span></p>
  <ul class="c35 lst-kix_e19e1y2gmxsk-0 start">
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1723415943130920&amp;usg=AOvVaw0QrcGI1q-oERaRmcfDsM6Y">Digital
          Advertising Alliance</a></span></li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=https://youradchoices.ca/&amp;sa=D&amp;source=editors&amp;ust=1723415943131197&amp;usg=AOvVaw1tBiHUwOEtdH7xGc8nkIXp">Digital
          Advertising Alliance of Canada</a></span></li>
    <li class="c18 c19 li-bullet-0"><span class="c21"><a class="c9"
          href="https://www.google.com/url?q=http://www.youronlinechoices.com/&amp;sa=D&amp;source=editors&amp;ust=1723415943131464&amp;usg=AOvVaw2khriQyF6AUEghQPomL91E">European
          Interactive Digital Advertising Alliance</a></span></li>
  </ul>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.5d0ymln3jae8"><span>WHAT ABOUT OTHER TRACKING TECHNOLOGIES, LIKE WEB BEACONS?</span></h2>
  <p class="c5"><span class="c0">Cookies are not the only way to recognize or track visitors to a website. We may use
      other, similar technologies from time to time, like web beacons (sometimes called &quot;tracking pixels&quot; or
      &quot;clear gifs&quot;). These are tiny graphics files that contain a unique identifier that enables us to
      recognize when someone has visited our Website or opened an email including them. This allows us, for example, to
      monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with
      cookies, to understand whether you have come to the website from an online advertisement displayed on a
      third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many
      instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair
      their functioning.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.h1n4o4cpl46w"><span>DO YOU USE FLASH COOKIES OR LOCAL SHARED OBJECTS?</span></h2>
  <p class="c5"><span class="c0">Websites may also use so-called &quot;Flash Cookies&quot; (also known as Local Shared
      Objects or &quot;LSOs&quot;) to, among other things, collect and store information about your use of our services,
      fraud prevention, and for other site operations.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c24">If you do not want Flash Cookies stored on your computer, you can adjust the settings
      of your Flash player to block Flash Cookies storage using the tools contained in the </span><span class="c33"><a
        class="c9"
        href="https://www.google.com/url?q=http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html&amp;sa=D&amp;source=editors&amp;ust=1723415943133040&amp;usg=AOvVaw2dCWnsdqPXye0__L2uFQEH">Website
        Storage Settings Panel</a></span><span class="c20">. You can also control Flash Cookies by going to the
    </span><span class="c33"><a class="c9"
        href="https://www.google.com/url?q=http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html&amp;sa=D&amp;source=editors&amp;ust=1723415943133302&amp;usg=AOvVaw3AVf8XKqtOuSnNi9gEHvN_">Global
        Storage Settings Panel</a></span><span class="c0">&nbsp;and following the instructions (which may include
      instructions that explain, for example, how to delete existing Flash Cookies (referred to &quot;information&quot;
      on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked,
      and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the
      page you are on at the time).</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">Please note that setting the Flash Player to restrict or limit acceptance of Flash
      Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash
      applications used in connection with our services or online content.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.ejolvi87b7ei"><span class="c41 c17">DO YOU SERVE TARGETED ADVERTISING?</span></h2>
  <p class="c5"><span class="c0">Third parties may serve cookies on your computer or mobile device to serve advertising
      through our Website. These companies may use information about your visits to this and other websites in order to
      provide relevant advertisements about goods and services that you may be interested in. They may also employ
      technology that is used to measure the effectiveness of advertisements. They can accomplish this by using cookies
      or web beacons to collect information about your visits to this and other sites in order to provide relevant
      advertisements about goods and services of potential interest to you. The information collected through this
      process does not enable us or them to identify your name, contact details, or other details that directly identify
      you unless you choose to provide these.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.h4qul4b5brbr"><span>HOW OFTEN WILL YOU UPDATE THIS COOKIE POLICY?</span></h2>
  <p class="c5"><span class="c0">We may update this Cookie Policy from time to time in order to reflect, for example,
      changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit
      this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</span></p>
  <p class="c5 c10"><span class="c11"></span></p>
  <p class="c5"><span class="c0">The date at the top of this Cookie Policy indicates when it was last updated.</span>
  </p>
  <p class="c5 c10"><span class="c11"></span></p>
  <h2 class="c15" id="h.36pdl62t781e"><span>WHERE CAN I GET FURTHER INFORMATION?</span></h2>
  <p class="c5"><span class="c0">If you have any questions about our use of cookies or other technologies, please email
      us at fillmarketing.user@gmail.com.</p>
  <p class="c5 c10"><span class="c0"></span></p>
</body>

`