import React, { useState } from 'react'
import Surveys from '../components/surveys/Surveys'
import MainPageTemplate from '../templates/MainPageTemplate'
import SurveysBuilder from '../components/surveys/SurveysBuilder'
import { useGetSurvey } from '../hooks/mutations'
import { useParams } from 'react-router-dom'
import SurveyDisplayStartCollect from '../components/surveys/form-1/SurveyDisplayStartCollect'

const SurveyTakePage = () => {
  const { reactSurveyId } = useParams();

  // Get the survey before the component is loaded to determine which survey "taking" component to display
  // For now we are only using the SurveyDisplayStartCollect, but others can be used later on.
  const [token, setToken] = useState(null);
  console.log('token', token)

  // Get the script element by its id
  const scriptElement = document.getElementById('external-forms-root');
  console.log('scriptElement', scriptElement)

  if (scriptElement && !token) {
    // Access the content of the script element
    const djangoFormString = scriptElement?.textContent;
    console.log('djangoFormString', djangoFormString)
  
    // Parse the JSON data
    const djangoForm = JSON.parse(djangoFormString);
    console.log('djangoForm', djangoForm)

    // Set the form id state from the JSON data
    setToken(djangoForm.id)
  }


  const survey = useGetSurvey(reactSurveyId);

  return (
    <MainPageTemplate title="Welcome">
      <SurveyDisplayStartCollect incomingSurvey={survey} />
    </MainPageTemplate>
  )
}

export default SurveyTakePage