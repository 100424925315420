import React, { useEffect, useState } from 'react'
import { useGetSurvey, useGetTakeSurvey } from '../hooks/mutations'
import MainPageTemplate from '../templates/MainPageTemplate'
import FormEditWrapper from '../components/forms/FormEditWrapper' 
import SurveyShareContainer from '../components/surveys/SurveyShareContainer'
import { useParams } from 'react-router'

const SurveyShareContainerPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const [reload, setReload] = useState();
  const { tokenId } = useParams();
  const [token, setToken] = useState(currentEnv === "STAGING" ? tokenId : null);
  console.log('token', token);

  // Get token from url
  // const tempPath = window.location.pathname;
  // console.log('tempPath', tempPath)
  
  // const tempToken = tempPath.split('/').pop();
  // console.log('tempToken', tempToken);
  
  // Get the script element by its id
  const scriptElement = document.getElementById('external-surveys-root');
  console.log('scriptElement', scriptElement)

  if (scriptElement && !token) {
    // Access the content of the script element
    const djangoSurveyString = scriptElement?.textContent;
    console.log('djangoSurveyString', djangoSurveyString)
  
    // Parse the JSON data
    const djangoSurvey = JSON.parse(djangoSurveyString);
    console.log('djangoSurvey', djangoSurvey)

    // Set the Survey id state from the JSON data
    setToken(djangoSurvey.id)
  }

  const incomingSurvey = useGetTakeSurvey(token);
  console.log('incomingSurvey', incomingSurvey)

  return (
    <MainPageTemplate title="Survey">
      <SurveyShareContainer survey={incomingSurvey} token={token} />
    </MainPageTemplate>
  )
}

export default SurveyShareContainerPage
