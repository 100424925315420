import React, { useEffect, useState } from 'react'
import FormEdit from './FormEdit'
import FormPreview from './FormPreview'
import { useGetAllCustomFields, useGetAllLists, useGetForm } from '../../hooks/mutations'
import { Grid } from '@mui/material';
import { initForm, initFormResponse } from '../../lib/Data';
import { useTheme } from '@emotion/react';
import FormDisplay from './FormDisplay';

const FormEditWrapper = (props) => {
  const { formId } = props;
  const incomingForm = useGetForm(formId);
  // const incomingFormPreview = useGetForm(formId);
  const mailingLists = useGetAllLists();
  const customFields = useGetAllCustomFields();
  const [formEdit, setFormEdit] = useState(incomingForm ? incomingForm : initForm);
  const [formPreview, setFormPreview] = useState(initFormResponse);
  const [mobilePreview, setMobilePreview] = useState(false);
  const [parentHeight, setParentHeight] = useState(null);
  const theme = useTheme();



  // console.log('mailingLists', mailingLists);
  // console.log('initForm', initForm);
  // console.log('formEdit', formEdit);
  // console.log('customFields', customFields);

  // useEffect(() => {
  //   function parentHeight(el) {
  //     return el.parentElement.parentElement.parentElement.parentElement.parentElement.clientHeight;
  //   }

  //   const height = parentHeight(document.getElementById('form-container'));
  //   setParentHeight(height);

  // }, )

  // Translate the POST form format to the GET form format to simulate the form response for display purposes
  const translateForPreview = () => {
    const postForm = { ...formEdit };
    let updatedForm = { ...postForm, custom_fields: [], mailing_lists: [] };

    if (customFields) {
      // Get the required items from customFields
      const postCustomFields = customFields.filter(field => postForm.custom_field_options.includes(field.id));
      // Refactor the items to match the GET response format
      const getCustomFields = postCustomFields.map(x => x);
      updatedForm = { ...updatedForm, custom_fields: getCustomFields };
    }

    if (mailingLists) {
      // Get the required items from  mailingLists
      const postMailingLists = mailingLists.filter(list => postForm.mailing_list_options.includes(list.id));
      // Refactor the items to match the GET response format
      const getMailingLists = postMailingLists.map(x => ({ id: x.id, user_visible_name: x.user_visible_name }));
      updatedForm = { ...updatedForm, mailing_lists: getMailingLists };
    }

    // Remove the options arrays
    delete updatedForm.custom_field_options;
    delete updatedForm.mailing_list_options;

    // Replace the options arrays with the custom_fields and mailing_lists arrays
    // console.log('updatedForm', updatedForm);
    setFormPreview(updatedForm);
  }

  // Set existing form to form state
  useEffect(() => {
    if (incomingForm) {
      // console.log('incomingForm', incomingForm);
      setFormEdit(incomingForm);
    }
  }, [incomingForm])

  // Set the GET form state (For preview) every time the POST form state is updated
  useEffect(() => {
    if (formEdit) {
      translateForPreview();
    }
  }, [formEdit, customFields, mailingLists])

  return (
    <Grid
      id="form-container"
      container
      style={{
        height: 'calc(100vh - 100px)' //parentHeight-60
      }}
    >
      {/* <Grid
        item
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          background: theme.palette.background.secondary,
          height: '100%',
          overflow: 'auto'
        }}
        xs
        sm={6}
        md={4}
        lg={3}
      >
        <FormEdit
          form={form}
          setForm={setForm}
          customFields={customFields}
          mailingLists={mailingLists}
          incomingForm={incomingForm}
          mobilePreview={mobilePreview}
          setMobilePreview={setMobilePreview}
          preview={<FormDisplay form={formPreview} />}
        />
      </Grid> */}
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          overflow: 'auto'
        }}
        xs
      >
        <Grid
          container
          style={{
            justifyContent: 'center',
            // padding: '24px 0',
          }}
        >
          <FormDisplay
            form={formPreview}
            formPreview
            formEdit={formEdit}
            setFormEdit={setFormEdit}
            customFields={customFields}
            mailingLists={mailingLists}
            incomingForm={incomingForm}
            mobilePreview={mobilePreview}
            setMobilePreview={setMobilePreview}
          />
        </Grid>
        {/* <FormPreview form={form} /> */}
      </Grid>
    </Grid>
  )
}

export default FormEditWrapper