export const landingCopy = {
  header_card: [
    {
      title: `CRM Tools`,
      shortTitle: `CRM Tools`,
      body: `Our CRM platform is designed to enhance your business operations by streamlining your workflow and improving customer relationships. With a user-friendly interface, it allows you to manage customer interactions effortlessly, ensuring that you never miss a follow-up or important detail.`,
      icon: 'relationship-management_500px.png'
    },
    {
      title: `Email Marketing`,
      shortTitle: `Marketing`,
      body: `Our email marketing tools empower you to reach your audience with precision and impact, fostering stronger connections and driving engagement. With our platform, you can create targeted campaigns that resonate with your subscribers, leading to higher open rates and conversions.`,
      icon: 'email-love_500px.png'
    },
  ],
  why_us: [
    {
      title: `Affordable and Transparent Pricing`,
      hash: `pricing`,
      body: `One of our most noticable differences with our large-scale competitors is our pricing. At our core, we believe in providing exceptional value without hidden fees or unexpected costs. As a small, agile company focused on nurturing our early beta customers, we pride ourselves on keeping our pricing straightforward and affordable.
      
      We maintain a single, transparent pricing tier. There are no upgrade fees, no hidden costs, and no complicated pricing schemes. Our focus is on delivering straightforward, honest value that helps small businesses grow and succeed.`,
      cta: `View Price`,
      ctaHref: `/prices/`
    },
    {
      title: `We Are Like You`,
      hash: `similar`,
      body: `We are not a large company. We understand the needs of small and mid-sized companies to connect with their customers without breaking the bank. We had those same needs at our previous company, but couldn't justify the expense of using products from Hubspot or Salesforce that were setup for corporations, not small businesses. That is why we built the Fill platform.`
    },
    {
      title: `A Commitment to Our Customers`,
      hash: `commitment`,
      body: <>We understand that loyalty is a two-way street, and we are committed to rewarding the trust our customers place in us. Our dedication to our customers goes beyond just providing a great product; it’s about building lasting relationships and ensuring their continued success.
      <br /><br />
      <span style={{fontWeight:600}}>Here’s how we demonstrate our commitment:</span></>,
      key: `commitment`,
      items: [
        {
          title: `Honest and Affordable Pricing`,
          body: `We are a small business just like you, and we prioritize providing the lowest cost for the highest value. We believe that long-term customers deserve consistency and predictability in pricing, and we work hard to provide that.`
        },
        {
          title: `Continuous Improvement and User-Driven Development`,
          body: `We actively seek feedback from our users and prioritize their needs in our development roadmap. Our product evolves based on the real-world experiences and suggestions of our customers. By engaging with our user community through surveys, beta programs, and direct feedback channels, we ensure that our CRM grows in ways that genuinely benefit our users.`
        },
        {
          title: `Flexible and Transparent Policies`,
          body: `Our subscription model is designed with flexibility in mind. Customers can sign up and cancel at any time without any penalties or long-term contracts. We trust our customers to know what’s best for their business, and we respect their autonomy. This flexibility also extends to how we handle data and privacy, ensuring that our customers have complete control over their information.`
        },
        {
          title: `Recognition and Rewards`,
          body: `We acknowledge and reward our loyal customers. From special discounts and exclusive features to early access to new tools, we ensure that our long-term users know they are valued.`
        },
        {
          title: `Transparent Communication`,
          body: `We believe in open and honest communication. Whether it’s updates about new features, changes to our services, or responses to feedback, we keep our customers informed every step of the way. Transparency builds trust, and we are committed to being as open as possible with our user base.`
        },
      ]
    },
    {
      title: `Investor-Driven CRMs vs. Customer-Driven Values`,
      hash: `values`,
      body: `Many CRMs in the market are driven by investor goals, which often prioritize rapid growth and high-value customer acquisition. This model frequently sidelines small businesses, as they are not seen as the most profitable segment. We recognize this disconnect and have chosen a different path. Our focus remains steadfast on serving small businesses and ensuring their success.`
    },
    {
      title: `Self-Funded for Ultimate Flexibility`,
      hash: `flexible`,
      body: `Our self-funded approach grants us the freedom to operate in ways that are most beneficial to our customers. Since our inception, we have adhered to a business model that emphasizes sustainable growth and genuine customer care. We are not in the race to become the most profitable CRM and Marketing platform; instead, we aim to build a company that values integrity and long-term relationships.`
    },
    {
      title: `Our Unique Approach`,
      hash: `approach`,
      body: `Our self-funded approach grants us the freedom to operate in ways that are most beneficial to our customers. Since our inception, we have adhered to a business model that emphasizes sustainable growth and genuine customer care. We are not in the race to become the most profitable CRM and Marketing platform; instead, we aim to build a company that values integrity and long-term relationships.`,
      key: `unique`,
      bg_graphic: `Parallelogram`,
      items: [
        {
          title: `Low-Cost Software`,
          body: `We consistently strive to offer our software at the lowest possible price, ensuring accessibility for small businesses.`
        },
        {
          title: `Customer Autonomy`,
          body: `With no contracts binding our customers, they can sign up or cancel their subscription anytime, reflecting our commitment to flexibility and customer satisfaction.`
        },
        {
          title: `Free, Exceptional Support`,
          body: `We believe that great customer service should be a given, not an upsell. Our dedicated support team is always ready to assist without any additional charges.`
        },
        {
          title: `Tailored Solutions`,
          body: `Our features are designed with small businesses in mind, ensuring they get exactly what they need to thrive without the pressure to cater to larger enterprises.`
        },
      ]
    },
    {
      title: `Why Our Model Works`,
      hash: `works`,
      body: `By staying small and self-funded, we can remain agile and responsive to our customers' needs. We aren’t driven by the need to meet investor expectations, allowing us to focus on what truly matters: providing a CRM and marketing platform that supports and enhances the operations of small businesses. Our journey may be slower and more measured, but it’s one that ensures we stay true to our mission and our customers.`
    },
    {
      title: `Join Us in Our Journey`,
      body: `We invite you to be part of our community, where your needs are our priority, and your success is our goal. Experience a CRM that is genuinely committed to your growth and satisfaction, backed by a company that values loyalty, integrity, and sustainable business practices. Together, we can achieve great things without the burden of investor-driven pressures.`
    },

  ]
}

export const pricingCopy = {
  dedicated_ip: {
    description: '',
    pros: [
      {
        title:`Better Deliverability`,
        body:`Dedicated IPs can improve email deliverability since the sender's reputation is not affected by other users.`
      },
      {
        title: `Reputation Control`,
        body: `You have full control over your sending reputation, which is crucial for maintaining high deliverability rates.`
      },
      {
        title: `Avoiding Shared IP Issues`,
        body: `You avoid problems caused by other users on a shared IP, such as blacklisting or high bounce rates.`
      },
      {
        title: `Consistency`,
        body: `Provides a consistent sender identity which ISPs may favor for inbox placement.`
      },
      {
        title: `Improved Reporting`,
        body: `Easier to monitor and analyze email performance metrics specific to your IP.`
      },
      {
        title: `Compliance`,
        body: `Helps in compliance with regulations and guidelines that may require dedicated IPs.`
      },
      {
        title: `Customization`,
        body: `Allows for customization of reverse DNS and other settings specific to your domain.`
      },
      {
        title: `Scalability`,
        body: `Supports high-volume sending without being throttled by other users' activities.`
      },
      {
        title: `Segmentation`,
        body: `Can be used to segment different types of email traffic (marketing vs. transactional).`
      },
      {
        title: `Brand Image`,
        body: `Enhances brand image and recognition with consistent sender addresses.`
      }
    ],
    cons: [
      {
        title: 'Cost',
        body: 'Dedicated IPs are more expensive than shared IPs. You can have your own dedicated IP for $98/month.',
        responsibility: 'user' 
      },
      {
        title: 'Reputation Management',
        body: 'Requires more effort and knowledge to manage and maintain a good IP reputation.',
        responsibility: 'fill' 
      },
      {
        title: 'Warm-up Period',
        body: 'New dedicated IPs need to be warmed up to establish a good sending reputation, which can take time.',
        responsibility: 'fill' 
      },
      {
        title: 'Volume Requirements',
        body: 'Effective use of a dedicated IP requires a high volume of emails, making it less suitable for small senders.',
        responsibility: 'user' 
      },
      {
        title: 'Maintenance',
        body: 'Ongoing maintenance and monitoring are needed to ensure the IP remains in good standing.',
        responsibility: 'fill' 
      },
      {
        title: 'Responsibility',
        body: 'Full responsibility for any deliverability issues lies with the sender, with no fallback on the provider\'s reputation.',
        responsibility: 'fill' 
      },
      {
        title: 'Complexity',
        body: 'Increased complexity in setting up and managing DNS records and other technical settings.',
        responsibility: 'fill' 
      },
      {
        title: 'ISP Relations',
        body: 'May need to establish and maintain relationships with ISPs to ensure optimal deliverability.',
        responsibility: 'fill' 
      },
      // {
      //   title: 'Risk of Blacklisting',
      //   body: 'A single mistake can lead to the IP being blacklisted, severely impacting email deliverability.',
      //   responsibility: 'fill' 
      // },
      {
        title: 'Technical Expertise',
        body: 'Requires a higher level of technical expertise to manage effectively, which may necessitate additional training or hiring.',
        responsibility: 'fill' 
      }
    ]  }
}

export const productCopy = [
  {
    name: `Customer Relationship Management`,
    shortName: `CRM`,
    hash: `crm`,
    description: ``,
    benefits: [
      {
        label: `Centralized Data`,
        body: `Tired of playing hide-and-seek with your customer info? Our CRM puts everything you need in one place. Contact details, interaction history, purchase records, and preferences are all at your fingertips, securely stored and ready for action. No more digging through endless spreadsheets or sticky notes.`,
        graphic: `centralized`
      },
      {
        label: `Enhanced Customer Service`,
        body: `Want to wow your customers with stellar service? Our CRM gives your support team a 360° view of each customer’s journey, making it a breeze to resolve issues quickly. Personalized service is no longer a dream but a reality, keeping your customers happy and loyal.`,
        graphic: `support`
      },
      {
        label: `Improved Sales Performance`,
        body: `Boost your sales team’s productivity with powerful CRM tools. Track leads, manage sales pipelines, and forecast revenues accurately. Our CRM enables you to automate repetitive tasks, freeing up your sales team to focus on closing deals and nurturing relationships.`,
        graphic: `performance`
      },
      // {
      //   label: `Marketing Automation`,
      //   body: `Maximize the impact of your marketing campaigns with our CRM’s automation features. Segment your audience based on specific criteria and send targeted messages at the right time. Measure the success of your campaigns with detailed analytics, allowing you to refine your strategy for better results.`
      // },
      {
        label: `Data-Driven Insights`,
        body: `Make informed business decisions with our CRM’s robust analytics and reporting capabilities. Gain insights into customer behavior, sales trends, and marketing performance. Use this data to identify opportunities for growth, optimize your processes, and stay ahead of the competition.`,
        graphic: `insights`
      },
      // {
      //   label: `Security and Compliance`,
      //   body: `Protect your customer data with our CRM’s advanced security measures. We prioritize data privacy and compliance, ensuring your information is safe and your business adheres to industry regulations.`
      // },
      // {
      //   label: `   `,
      //   body: `   `
      // },
    ]
  },
  {
    name: `Email Marketing`,
    shortName: `Marketing`,
    hash: `marketing`,
    description: `Email marketing remains one of the most effective ways to reach and engage your audience. Our email marketing platform is designed to maximize the impact of your campaigns, helping you connect with your customers, drive sales, and grow your business. Here’s how our platform can benefit you:`,
    benefits: [
      {
        label: `Custom Email Design`,
        body: `Create stunning, professional emails with ease. Our platform offers a user-friendly drag-and-drop editor, customizable templates, and a rich media library. No design or coding skills are required—just drag, drop, and customize to match your brand’s look and feel.`,
        graphic: `email_template_ex_1`
      },
      {
        label: `Advanced Segmentation`,
        body: `Deliver the right message to the right audience. Our advanced segmentation tools allow you to categorize your subscribers based on demographics, behavior, purchase history, and more. Personalize your emails to each segment, ensuring your content resonates and drives engagement.`,
        graphic: 'segmentation'
      },
      {
        label: `Scheduling`,
        body: `Create your campaigns when it is convenient for you. Then schedule your campaigns to be sent when your audience is most likely to engage.`,
        graphic: 'scheduling'
      },
      {
        label: `Robust Deliverability`,
        body: `Ensure your emails reach the inbox, not the spam folder. Our platform is built with industry-leading deliverability features, including spam testing, email verification, authentication protocols, and options for dedicated IP addresses. Maintain a high sender reputation and maximize your email reach.`,
        graphic: `deliverability`
      },
      {
        label: `Compliance and Security`,
        body: `Protect your customer data and stay compliant with industry regulations. Our platform is equipped with advanced security measures and adheres to GDPR, CAN-SPAM, and other data protection laws. Focus on your campaigns with peace of mind, knowing your data is secure.`,
        graphic: `security`
      },
      {
        label: `Scalable for Growth`,
        body: `Whether you’re a small business or a large enterprise, our email marketing platform scales with your needs. Manage a growing subscriber list, launch more complex campaigns, and expand your reach without compromising performance or functionality.`,
        graphic: `scalable`
      },
      // {
      //   label: `   `,
      //   body: `   `
      // },

    ]
  }
]
