import React, {useState} from 'react'
import MainPageTemplate from '../../templates/MainPageTemplate'
import { InputField } from '../ui/Fields';
import { Button, Grid, Typography, CircularProgress } from '@mui/material'
import { createUser } from '../../hooks/mutations';
import SnackbarWrapper from '../ui/SnackbarWrapper';

const SubuserAdd = ({ setReloadUsers }) => {
  // const [buttonClicked, setButtonClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [snackMessage, setSnackMessage] = useState(null);

  const submitCreateUser = async () => {
    const userObj = {
      payload: {
        email: userEmail,
        name: userName
      }
    }
    const submittedUser = await createUser(userObj);
    console.log('submittedUser', submittedUser)
    if (submittedUser.status == "success") {
      setSnackMessage({open: true, message: 'New user created', severity:'success'});
      setUserName('');
      setUserEmail('');
      setReloadUsers(true);
    } else if (submittedUser.status === "error") {
      setSnackMessage({open: true, message: submittedUser.error, severity:'error'})
    }
  }

  return (
    <>
      <Grid
        container
        minHeight="calc(40vh)"
        minWidth="500px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography 
          variant="h5"
          style={{
            paddingBottom: 16
          }}
        >
          Add New User
        </Typography>
        <Grid
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
        >
          <InputField
            label="Name"
            margin
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder='Enter user name'
            name="name"
            width="350px"
          />
          <InputField 
            label="Email"
            margin
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder='Enter user email'
            name="email"
            width="350px"
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          // startIcon={buttonClicked && !errorDisplay ? <CircularProgress size={16} thickness={8} style={{ color: '#fff' }} /> : null}
          onClick={submitCreateUser}
          sx={{
            px:4,
            py:2,
            mt:0
          }}
        >
          Save
        </Button>
      </Grid>
      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
    </>
  );
};

export default SubuserAdd;
