import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { InfoFilledOldSVG, QuestionFillSVG } from '../../assets/icons'
import { useTheme } from '@emotion/react'
import DialogQuestion from './DialogQuestion'
import DialogInfo from './DialogInfo'
import { useApi } from '../../context/apiContext'

const Infotip = (props) => {
  const { title, tip, messages, question, info, persist } = props;
  const theme = useTheme();
  const apiContext = useApi();

  const [openQuestion, setOpenQuestion] = useState(false);
  const handleCloseQuestion = () => {
    setOpenQuestion(false);
  }

  const [openInfo, setOpenInfo] = useState(false);
  const handleCloseInfo = () => {
    setOpenInfo(false);
  }

  return (
    apiContext?.contextUserDataBag?.data?.general?.show_info || persist
      ?
        <>
          <Tooltip
            title={tip ? tip : title}
          >
            {question && <QuestionFillSVG width={15} color1={theme.palette.primary.main} onClick={() => setOpenQuestion(true)} />}
            {info && <InfoFilledOldSVG width={15} color1={theme.palette.primary.main} onClick={() => setOpenInfo(true)} />}
          </Tooltip>

          {
            question &&
              <DialogQuestion
                open={openQuestion}
                onClose={handleCloseQuestion}
                header="Explanation"
                title={title}
                messages={messages}
                icons
              />
          }
          {
            info &&
              <DialogInfo
                open={openInfo}
                onClose={handleCloseInfo}
                header="Info"
                title={title}
                messages={messages}
                icons
              />
          }
        </>
      : 
        null
  )
}

export default Infotip