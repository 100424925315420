import React, { useState } from 'react'
import { Alert, AlertTitle, Button, Card, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { EmailSVG, HiddenSVG, VisibleSVG } from '../../assets/icons';

import axios from 'axios';
import { api_endpoint } from '../../lib/Backend';
import { InputField } from '../ui/Fields';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { postRegister } from '../../hooks/mutations';


const RegisterForm = () => {
  const [registerEmail, setRegisterEmail] = useState();
  const [registerPassword, setRegisterPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [emailRegistration, setEmailRegistration] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);
  const theme = useTheme();
  const authContext = useAuth();
  const navigate = useNavigate();

  // SHOW PASSWORD FUNCTIONALITY
  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  }

  // EMAIL REGISTRATION
  const registerUser = async () => {
    try {
      if (!registerEmail) {
        setSnackMessage({open: true, message: 'Please include your email', severity:'error'});
        return;
      }
  
      if (!registerPassword) {
        setSnackMessage({open: true, message: 'Please include your password', severity:'error'});
        return;
      }
  
      if (registerPassword !== confirmPassword) {
        setSnackMessage({open: true, message: 'Your passwords do not match', severity:'error'});
        return;
      }

      setButtonClicked(true);

      const registerObj = { 
        'password' : registerPassword, 
        'email' : registerEmail 
      }
  
      const response = await postRegister(registerObj);
      if (response.status === 'success') {
        setSnackMessage({open: true, message: 'You have been registered', severity:'success'});
        window.location = '/verify_email/';

      } else if (response.status === 'error') {
        setSnackMessage({open: true, message: response.error, severity:'error'});
        setTimeout(() => {
          setButtonClicked(false);
        }, 750);
      }
      // const response = await axios.post(api_endpoint() + `/actions/api/register/`,
      //   { 
      //     'password' : registerPassword, 
      //     'email' : registerEmail 
      //   },
      //   {
      //     'Content-Type': 'application/json'
      //   }).catch( function (error) {
      //     // The request was made and the server responded with a 400 Bad Request status code due to an issue with the email property
      //     if (error.response.data.email != null) {
      //       console.log(error.message);
      //       console.log(error.response.data);
      //       setErrorDisplay("There was an error registering for an account: " + error.response.data.email[0])
      //       setButtonClicked(false);
      //     } else if (error.response) {
      //       // The request was made and the server responded with a status code that falls out of the range of 2xx
      //       console.log(error.message);
      //       console.log(error.response.data);
      //       setErrorDisplay("There was an error registering for an account")
      //       setButtonClicked(false);
      //     }
      //   });


      // if (response.status == 201) {
      //   // await sendVerificationEmail(); // <-- Handled on the backend now
      //   // navigate('/check-email', { state: { email: registerEmail}})
      // }

      //authContext.setUser(response.data.id);
      //setIsAuthenticated(true);

    } catch (error) {

    }
  }

  const ErrorDisplay = () => {

    return (
      errorDisplay &&
      <Grid container justifyContent="center">

        <Alert severity="warning">
          <AlertTitle>{errorDisplay}</AlertTitle>
        </Alert>
      </Grid>

    )
  }

  // UPDATE USER STATE FROM authContext
  const initializeAccount = async () => {
    await registerUser();
  }

  return (
    <Grid
      container
    >
      <InputField
        // disabled
        label="Email"
        placeholder="Email"
        fullWidth
        value={registerEmail}
        onChange={(e) => { setRegisterEmail(e.target.value); setErrorDisplay(null) }}
        singleLine
        margin
      />
      <InputField
        // disabled
        label="Password"
        type={showPassword ? 'text' : 'password'}
        // variant="standard"
        placeholder="Password"
        fullWidth
        noAutoFocus
        value={registerPassword}
        onChange={(e) => setRegisterPassword(e.target.value)}
        customInputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleShowPassword}>
              {
                showPassword
                  ? <HiddenSVG width={20} style={{ padding: 12 }} color1={theme.palette.mid.main} />
                  : <VisibleSVG width={20} style={{ padding: 12 }} color1={theme.palette.mid.main} />
              }
            </InputAdornment>
          )
        }}
        singleLine
        margin
      />
      <InputField
        // disabled
        label="Confirm Password"
        type={showPassword ? 'text' : 'password'}
        // helperText={
        //   registerPassword === confirmPassword
        //     ? null
        //     : <Typography style={{ width: '100%', textAlign: 'center' }}>Passwords do not match</Typography>
        // }
        // variant="standard"
        placeholder="Confirm Password"
        fullWidth
        noAutoFocus
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            initializeAccount()
          }
        }}
        customInputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleShowPassword}>
              {
                showPassword
                  ? <HiddenSVG width={20} style={{ padding: 12 }} color1={theme.palette.mid.main} />
                  : <VisibleSVG width={20} style={{ padding: 12 }} color1={theme.palette.mid.main} />
              }
            </InputAdornment>
          )
        }}
        singleLine
        margin
      />


      {
        registerEmail && registerPassword && confirmPassword
          ? null
          : <Typography style={{ width: '100%', color:'#aaa', textAlign: 'center', marginBottom:8 }}>Please fill out all fields</Typography>
      }

      <Grid
        container
        style={{
          flexDirection:'column',
          alignItems: 'center',
        }}
      >

        {/* <ErrorDisplay /> */}
        <Button
          variant="contained"
          startIcon={buttonClicked && !errorDisplay ? <CircularProgress size={16} thickness={8} style={{ color: '#fff' }} /> : null}
          onClick={initializeAccount}
          style={{
            minWidth: 120
          }}
        >
          {buttonClicked && !errorDisplay ? "Registering" : "Register"}
        </Button>

        <Button
          href="/accounts/login/"
          style={{
            minWidth: 120
          }}
        >
          Login
        </Button>
      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </Grid>
  )
}

export default RegisterForm


