import { useTheme } from "@emotion/react";

export const Typography = (props) => {
  const { children, heading, style, ...other } = props;
  const theme = useTheme();

  switch (heading) {
    case 'h1':
      return (
        <h1
          style={{
            // fontSize:72,
            // fontFamily:'DM Sans',
            // color:'#fff',
            ...theme.typography.h1,
            ...style
          }}
          {...other}
        >
          {children}
        </h1>
      )
  
    case 'h2':
      return (
        <h2
          style={{
            // fontSize:36,
            // fontFamily:'DM Sans',
            ...theme.typography.h2,
            ...style
          }}
          {...other}
        >
          {children}
        </h2>
      )
  
    case 'h3':
      return (
        <h3
          style={{
            // fontSize:28,
            // fontFamily:'DM Sans',
            // margin:0,
            ...theme.typography.h3,
            ...style
          }}
          {...other}
        >
          {children}
        </h3>
      )
  
    case 'h4':
      return (
        <h4
          style={{
            // fontSize:28,
            // fontFamily:'DM Sans',
            // margin:0,
            ...theme.typography.h4,
            ...style
          }}
          {...other}
        >
          {children}
        </h4>
      )
  
    case 'medium':
      return (
        <h3
          style={{
            // fontSize:20,
            // fontWeight:500,
            // fontFamily:'DM Sans',
            // margin:0,
            ...theme.typography.medium,
            ...style
          }}
          {...other}
        >
          {children}
        </h3>
      )
  
    default:
      return (
        <p
          style={{
            // fontSize:20,
            // fontFamily:'DM Sans',
            // margin:0,
            ...theme.typography.default,
            ...style
          }}
          {...other}
        >
          {children}
        </p>
      )
  }
}