import React from 'react'
import Step4Subscribe from '../components/signup/Step4Subscribe'
import SignupPageHeader from '../components/signup/SignupPageHeader'

const SignupSubscribePage = () => {


  
  return (
    <>
      <SignupPageHeader step={3} />
      <Step4Subscribe />
    </>
  )
}

export default SignupSubscribePage