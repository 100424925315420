import React, { cloneElement, useEffect, useState } from 'react';
import './EmailTemplate.css';
import { Column1SVG, Column2SVG, Column3SVG, CopySVG, DeleteSVG, DesktopSVG, EditSVG, MobileSVG, MoveAllDirectionsSVG, MoveSVG, TabletSVG } from '../../assets/icons';
import ElementEditorText from './ElementEditorText';
import ElementEditorButton from './ElementEditorButton';
import ElementRenderer from './ElementRenderer';
import { Dialog, Grid, IconButton, Typography } from '@mui/material';
import { constantTemplateVariables } from '../../lib/Data';
import { createEmailTemplate, getEmailTemplateFolder, putEmailTemplate, putStorageFile, useGetAllCustomFields } from '../../hooks/mutations';
import { copyString, getRandomString } from '../../lib/Functions';
import ElementEditorImage from './ElementEditorImage';
import ElementEditorDivider from './ElementEditorDivider';
import SectionEditor from './SectionEditor';
import { useTheme } from '@emotion/react';
import EmailHtml from './EmailHtml';
import { renderToStaticMarkup } from 'react-dom/server';
import SnackbarWrapper from '../ui/SnackbarWrapper';

// Element types for the email builder
const ELEMENT_TYPES = [
  { type: 'Text', label: 'Text', id: 'text', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Button', label: 'Button', id: 'button', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Image', label: 'Image', id: 'image', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Video', label: 'Video', id: 'video', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Divider', label: 'Divider', id: 'divider', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Quote', label: 'Quote', id: 'quote', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Link', label: 'Link', id: 'link', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'List', label: 'List', id: 'list', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Grid Items', label: 'Grid Items', id: 'grid-items', base: 'element', style:{}, wrapStyle:{} },
];

const BUTTON_TYPES = [
  { type: 'Variables', label: 'Variables', id: 'variables', base: 'button', style:{} },
]

const SECTION_TYPES = [
  { type: 'column-1', label: '1 Column', id: 'column-1', base: 'section', style:{}, icon:<Column1SVG height={16} color="#ccc" /> },
  { type: 'column-2', label: '2 Column', id: 'column-2', base: 'section', style:{}, icon:<Column2SVG height={16} color="#ccc" /> },
  { type: 'column-3', label: '3 Column', id: 'column-3', base: 'section', style:{}, icon:<Column3SVG height={16} color="#ccc" /> },
];

const EmailTemplate = (props) => {
  const { emailTemplate, template, storageFile, create=true } = props; // Get incoming template parts if it exists
  const [sections, setSections] = useState([]); // Track the email sections
  const [dragging, setDragging] = useState(null); // Track the dragged element or section
  const [dragOverSection, setDragOverSection] = useState(null); // Track the section being dragged over
  const [dragOverElement, setDragOverElement] = useState(null); // Track the section being dragged over
  const [selectedItem, setSelectedItem] = useState(null); // Track the selected section or element
  const [snackMessage, setSnackMessage] = useState(null); // Track the snackbar messages
  const [openVariables, setOpenVariables] = useState(false); // Track the variables dialog open state
  const [templateName, setTemplateName] = useState(template?.name || 'Untitled Template');
  const [openName, setOpenName] = useState(false);

  const [preview, setPreview] = useState(false);
  const [previewSize, setPreviewSize] = useState(800);

  const customFields = useGetAllCustomFields();
  const theme = useTheme();

  // console.log('emailTemplate', emailTemplate)
  // console.log('template', template)
  // console.log('storageFile', storageFile)
  // console.log('sections', sections?.[0]?.style.backgroundColor)

  // Update sections if there is an incomingTemplate
  useEffect(() => {
    if (emailTemplate) {
      setSections(emailTemplate.data);
    }
  }, [emailTemplate])

  useEffect(() => {
    if (template?.name) {
      setTemplateName(template.name);
    }
  }, [template?.name])

  // Handle updates to the root of a section
  const handleSectionChange = (newSection) => {
    if (selectedItem && selectedItem.base === 'section') {
      const copiedSections = [ ...sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.uid);
      let updatedSection = copiedSections[sectionIndex];
      updatedSection = { ...updatedSection, ...newSection };

      copiedSections[sectionIndex] = updatedSection;

      setSections(copiedSections);
      setSelectedItem(updatedSection);
    }
  }

  // Handle style updates when a text element is selected
  const handleSectionStyleChange = (newStyle) => {
    if (selectedItem && selectedItem.base === 'section') {
      const copiedSections = [ ...sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.uid);
      let updatedSection = copiedSections[sectionIndex];
      updatedSection.style={ ...updatedSection.style, ...newStyle };

      copiedSections[sectionIndex] = updatedSection;

      setSections(copiedSections);
    }
  }

  // Handle general updates to element root fields
  const onElementChange = (field, newContent) => {
    const copiedSections = [ ...sections ];
    const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
    const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
    let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
    updatedElement[field] = newContent;
    copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

    setSections(copiedSections);
  }

  // Handle style updates when a text element is selected
  const handleTextStyleChange = (newStyle) => {
    if (selectedItem && (selectedItem.type === 'Text' || selectedItem.type === "Button" || selectedItem.type === "Image" || selectedItem.type === "Divider")) {
      const copiedSections = [ ...sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.style={ ...updatedElement.style, ...newStyle };

      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(copiedSections);
    }
  }

  // Handle style updates when a text element is selected
  const handleWrapStyleChange = (newStyle) => {
    const copiedSections = [ ...sections ];
    const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
    const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
    let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
    updatedElement.wrapStyle={ ...updatedElement.wrapStyle, ...newStyle };

    copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

    setSections(copiedSections);
  }

  // Handle updates to the content field
  const onTextContentChange = (newContent) => {
    if (selectedItem && (selectedItem.type === 'Button')) {
      const copiedSections = [ ...sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.content=newContent;
      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(copiedSections);
    }
  }
  
  // Handle updates to the url field
  const onTextUrlChange = (newUrl) => {
    if (selectedItem && (selectedItem.type === 'Button' || selectedItem.type === "Image")) {
      const copiedSections = [ ...sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.url=newUrl;
      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(copiedSections);
    }
  }

  // Handle updates to the alt field
  const onTextAltChange = (newAlt) => {
    if (selectedItem && selectedItem.type === "Image") {
      const copiedSections = [ ...sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.alt=newAlt;
      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(copiedSections);
    }
  }
  
  // Handle general updates to an element
  const handleElementUpdate = (updatedElement) => {
    const updatedSections = sections.map((section) => {
      if (section.uid === updatedElement.sectionId) {
        return {
          ...section,
          elements: section.elements.map((el) =>
            el.uid === updatedElement.uid ? updatedElement : el
          ),
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  // Handle deleting an element
  const handleElementDelete = (deletedElement) => {
    const updatedSections = sections.map((section) => {
      if (section.uid === deletedElement.sectionId) {
        return {
          ...section,
          elements: section.elements.filter(x => x.uid != deletedElement.uid)
        };
      }
      return section;
    });
    setSelectedItem(null);
    setSections(updatedSections);

  }

  // Handle duplicating an element and putting it below the original
  const handleElementCopy = (copiedElement) => {
    console.log('copiedElement', copiedElement)
    const newSections = [ ...sections ]
    // Find the section and the element
    for (const section of newSections) {
        const index = section.elements.findIndex(el => el.uid === copiedElement.uid);
        console.log('copiedElement', copiedElement)
        if (index !== -1) {
            // Copy the element
            const newElement = { ...section.elements[index] };
            console.log('copiedElement', copiedElement)
  
            // Generate a new uid
            newElement.uid = getRandomString(16);
  
            // Insert the new element after the original one
            section.elements.splice(index + 1, 0, newElement);
        }
    }

    setSections(newSections);
  }
  
  // Handle the drag start event when dragging sections (using the icon)
  const handleSectionDragStart = (e, element, sectionId) => {
    e.stopPropagation();
    setDragging({ element, sectionId });
  };

  // Handle drag over for sections to visualize where the section is being dragged
  const handleDragOverSection = (e, sectionId) => {
    e.preventDefault();
    setDragOverSection(sectionId); // Highlight the section being dragged over
  };

  // Handle the drop event for sections to move them to a new position
  const handleSectionDrop = (e, targetSectionId) => {
    e.preventDefault();
    if (dragging && dragging.sectionId !== targetSectionId) {
      const draggedSectionId = dragging.sectionId;
      const newSections = [...sections];

      const draggedIndex = newSections.findIndex(s => s.uid === draggedSectionId);
      const targetIndex = newSections.findIndex(s => s.uid === targetSectionId);

      const [movedSection] = newSections.splice(draggedIndex, 1);
      newSections.splice(targetIndex, 0, movedSection);

      setSections(newSections);
      setDragging(null);
      setDragOverSection(null);
    }
  };

  // Handle the drag end event for sections and elements
  const handleDragEnd = () => {
    setDragging(null);
    setDragOverSection(null); // Reset when the drag ends
  };

  // Add a new section (column-1, column-2, column-3) when the user drops the respective column element
  const addSection = (element) => {
    setSections([
      ...sections,
      {
        uid: `${element.base}-${getRandomString(16)}`,
        id: element.id,
        type: element.type,
        label: element.label,
        base: element.base,
        class: 'fill-eb-section',
        style: {},
        elements: [],
      },
    ]);
  };

  // Remove a section
  const removeSection = (sectionId) => {
    setSections(sections.filter(section => section.uid !== sectionId));
    if (selectedItem && selectedItem.uid === sectionId) {
      setSelectedItem(null); // Clear selection if the section is removed
    }
  };

  // Handle drag start for elements within sections
  const handleElementDragStart = (e, element, sectionId) => {
    e.stopPropagation(); // Prevent triggering section drag while dragging elements
    setDragging({ element, sectionId });
  };

  // Handle drag start for elements within sections
  const handleElementButton = (button) => {
    // e.stopPropagation(); // Prevent triggering section drag while dragging elements
    if (button.id === 'variables') {
      setOpenVariables(true);
    }
  };

  // Handle the drag over event to visualize where to drop elements
  const handleElementDragOver = (e, sectionId, targetElementIndex) => {
    e.preventDefault();
    setDragOverSection(sectionId);
    setDragOverElement(targetElementIndex); // Store the target index
};

  // Handle the drop event for elements within the same section
// Handle the drop event for elements within the same section
  const handleElementDrop = (e, sectionId, columnIndex) => {
    e.preventDefault();
    e.stopPropagation();

    // Stop is the element is a column 'section' base type
    if (dragging.element.base === 'section') {
      setDragging(null);  // Clear the dragging state
      return;
    }

    // If there's an element being dragged
    if (dragging && dragging.element) {
        const newSections = [...sections];
        const draggedElement = dragging.element;

        // Check if the dragged element is new (not previously in a section)
        if (!draggedElement.sectionId) {
            // Add the element to the target section's column
            const targetSectionIndex = newSections.findIndex(s => s.uid === sectionId);
            if (targetSectionIndex === -1) {
                return; // Target section not found
            }

            const updatedElement = {
                ...draggedElement,
                uid: draggedElement.uid || getRandomString(16), // Generate a new UID if it doesn't exist
                class: `fill-eb-${draggedElement.id}`,
                columnIndex,
                sectionId,
            };

            // Insert the element at the target index, or push to the end if null
            if (dragOverElement !== null) {
                newSections[targetSectionIndex].elements.splice(dragOverElement, 0, updatedElement);
            } else {
                newSections[targetSectionIndex].elements.push(updatedElement);
            }

        } else {
            // If the element was previously in a section, move it to the new section
            const originalSectionIndex = newSections.findIndex(s => s.uid === draggedElement.sectionId);
            if (originalSectionIndex === -1) {
                return; // Original section not found
            }

            // Remove the element from its original section
            newSections[originalSectionIndex].elements = newSections[originalSectionIndex].elements.filter(
                (el) => el.uid !== draggedElement.uid
            );

            // Prepare the updated element
            const updatedElement = {
                ...draggedElement,
                columnIndex,
                sectionId,
            };

            // Insert the element at the target index in the new section
            const targetSectionIndex = newSections.findIndex(s => s.uid === sectionId);
            if (targetSectionIndex === -1) {
                return; // Target section not found
            }

            if (dragOverElement !== null) {
                newSections[targetSectionIndex].elements.splice(dragOverElement, 0, updatedElement);
            } else {
                newSections[targetSectionIndex].elements.push(updatedElement);
            }
        }

        // Update the sections state and clear dragging
        setSections(newSections);
        setDragging(null);  // Clear the dragging state

    } else {
        console.log('No element being dragged.');
    }
  };

  // Function to export sections as JSON
  const exportJSON = () => {
    const json = JSON.stringify(sections, null, 2);
    console.log("Exported JSON:");
    console.log(json);
  };

  // Function to export sections as HTML
  const exportHTML = () => {
    // Render the EmailHtml component to a string
    const html = renderToStaticMarkup(<EmailHtml json={sections} />);
    console.log("Exported HTML:");
    console.log(html);
    return html;
  };

  // Select an item (either a section or element)
  const selectItem = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item); // Toggle selection
    // setSelectedItem(item === selectedItem ? null : item); // Toggle selection
  };

  const handleVariableCopy = (field) => {
    copyString(`{-{${field.field_name}}-}`);
    setSnackMessage({open: true, message: `${field.user_visible_name} variable copied`, severity: 'success'});
    setOpenVariables(false);
  }

  const updateTemplate = async () => {
    try {
      // Check if name has been changed and update the template if it has
      if (templateName !== template.name) {
        const emailTemplateObj = {
          emailTemplateId: template.id,
          payload: { ...template, name: templateName },
        }
        const savedTemplate = await putEmailTemplate(emailTemplateObj);
        console.log('savedTemplate', savedTemplate)
        if (savedTemplate.status === "success") {
          setOpenName(false);
          setSnackMessage({open: true, message: 'Name updated successfully', severity: 'success'})
      
        } else if (savedTemplate.status === "error") {
          setSnackMessage({open: true, message: savedTemplate.error, severity: 'error'})
        }    
      }
      const storageFolder = await getEmailTemplateFolder();
      const storageFolderId = storageFolder.folderId;

      // Place the sections json and html into a json
      const rawJson = { content: exportHTML(), data: sections, existing: true };
      console.log('data for json', rawJson);
      const jsonBlob = new Blob([JSON.stringify(rawJson, null, 2)], { type: 'application/json' });
      console.log('jsonBlob', jsonBlob);
      const fileName = `${template.name.replace(' ', '_')}-${storageFile.id}-${getRandomString(16)}`;
      console.log('fileName', fileName);
      const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
      console.log('jsonFileObj', jsonFileObj);
      const formData = new FormData();
      formData.append('file', jsonFileObj);
      formData.append('name', fileName); // <-- use state variable to save and edit the name
      formData.append('folder', storageFolderId);
      console.log('formData', formData);

      // setSnackMessage({open: true, message: <LinearProgress color='primary' style={{width:'200px', height:'15px', borderRadius:30}} />})
      const storageObj = {
        storageFilePayload: formData,
        folderId: storageFolderId,
        fileId: template.content_file
      }
      console.log('storageObj', storageObj);

      const updatedFile = await putStorageFile(storageObj);
      if (updatedFile.status === "success") {
        setSnackMessage({open: true, message: 'Template Saved', severity: 'success'})

      } else {
        setSnackMessage({open: true, message: 'Error saving template', severity: 'error'})

      }

    } catch (error) {
      console.log(`Error updating template ${template.id}`, error)
    }
  };

  const createTemplate = async () => {
    try {
      const storageFolder = await getEmailTemplateFolder();
      const storageFolderId = storageFolder.folderId;
      
      // Place the sections json and html into a json
      const rawJson = { content: exportHTML(), data: sections, existing: true };
      console.log('data for json', rawJson);
      const jsonBlob = new Blob([JSON.stringify(rawJson, null, 2)], { type: 'application/json' });
      const fileName = `${templateName.replace(' ', '_')}-${getRandomString(16)}`;
      console.log('fileName', fileName);
      const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
      const formData = new FormData();
      formData.append('file', jsonFileObj);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const emailTemplateObj = {
        storageFolderId: storageFolderId,
        storagePayload: formData,
        templatePayload: {
          'name': templateName,
          'content': '',
          'update_date': new Date(),
          'editor_json': null,
          'last_used_campaign': null 
        }
      }

      const savedTemplate = await createEmailTemplate(emailTemplateObj);
      if (savedTemplate.status === "success") {
        setSnackMessage({open: true, message: 'New email template created', severity:'success'})
        setTimeout(() => {
          window.location.href = `/templates/${savedTemplate.newTemplateData.id}`
        }, 500);
      } else if (savedTemplate.status === "error") {
        setSnackMessage({open: true, message: 'Error creating email template', severity:'error'})
      }
    } catch (error) {
      console.log('Error creating new email template', error)
      setSnackMessage({open: true, message: 'Error creating email template', severity:'error'})
    }
  }



  return (
    <div className="email-builder">
      {/* Elements Panel */}
      <div className="elements-panel">
        <h3>Content</h3>
        <h4>Sections</h4>
        {SECTION_TYPES.map((section) => (
          <div
            key={section.uid}
            draggable
            onDragStart={(e) => handleElementDragStart(e, section)}
            className="element flex space-between"
          >
            {section.label} {cloneElement(section.icon, {width:'100%'})}
          </div>
        ))}
        <h4>Elements</h4>
        {ELEMENT_TYPES.map((element) => (
          <div
            key={element.uid}
            draggable
            onDragStart={(e) => handleElementDragStart(e, element)}
            className="element"
          >
            {element.type}
          </div>
        ))}
        {BUTTON_TYPES.map((button) => (
          <div
            key={button.id}
            onClick={() => handleElementButton(button)}
            // draggable
            // onDragStart={(e) => handleElementButton(e, button)}
            className="element-clickable"
          >
            <div>
            {button.type}
            </div>
            <div>
            {`>`}
            </div>
          </div>
        ))}
        {/* Export Buttons */}
        <div className="section-header gap-8">
          {/* <button onClick={exportJSON}>Export JSON</button> */}
          <button onClick={exportHTML} style={{width:'100%', marginBottom:8, padding:'12px 8px'}}>Export HTML</button>
        </div>
        <div className="section-header gap-8">
          <button onClick={emailTemplate ? updateTemplate : createTemplate} style={{width:'100%', padding:'12px 8px'}}>Save Template</button>
        </div>
      </div>

      {/* Email Builder */}
      <div
        className={`builder-panel`}
        onClick={() => setSelectedItem(null)}
      >
        <div
          className={`builder-display ${previewSize}`}
          onClick={() => setSelectedItem(null)}
        >
          <div className="flex space-between">
            <div className="flex" onClick={() => setOpenName(true)}>
              <h3>
                {openName 
                  ? <input
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    onBlur={() => setOpenName(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setOpenName(false);
                      }
                    }}          
                    autoFocus
                    style={{
                      outline:'none',
                      border:'none'
                    }}
                  />
                  : templateName}
              </h3>
              {openName ? null : <EditSVG onClick={() => setOpenName(true)} width={16} style={{marginLeft:8}} />}
            </div>
            <button onClick={() => setPreview(prev => !prev)}>{preview ? "Edit" : "Preview"}</button>
            <div className="flex ">
              <button class={`${previewSize === 'mobile' ? 'active-icon' : 'invisible'}`} onClick={() => setPreviewSize('mobile')}><MobileSVG height={22} color={previewSize === 'mobile' ? '#FFF' : theme.palette.tertiary.main} /></button>
              <button class={`${previewSize === 'tablet' ? 'active-icon' : 'invisible'}`} onClick={() => setPreviewSize('tablet')}><TabletSVG height={22} color={previewSize === 'tablet' ? '#FFF' : theme.palette.tertiary.main} /></button>
              <button class={`${previewSize === 'desktop' ? 'active-icon' : 'invisible'}`} onClick={() => setPreviewSize('desktop')}><DesktopSVG height={22} color={previewSize === 'desktop' ? '#FFF' : theme.palette.tertiary.main} /></button>
            </div>
          </div>

          {
            !preview
              ?
                // Edit Sections
                <div className="builder-layout">
                  {sections.map((section, sectionIndex) => (
                    <div
                      key={section.uid}
                      className={`section ${dragOverSection === section.uid ? 'drag-over' : ''} ${selectedItem === section ? 'selected' : ''}`}
                      onDragOver={(e) => handleDragOverSection(e, section.uid)}
                      onDrop={(e) => handleSectionDrop(e, section.uid)}
                      onClick={(e) => selectItem(e, section)} // Handle click for section selection
                      style={
                        section.style
                      }
                    >
                      <div
                        className="section-header drag-handle remove"
                        draggable
                        onDragStart={(e) => handleSectionDragStart(e, section, section.uid)}
                        onDragEnd={handleDragEnd}
                      >
                        <MoveAllDirectionsSVG height={20} />
                        <div className="flex gap-8">Section {SECTION_TYPES.find(x => x.type === section.type).icon}</div>
                        <div onClick={() => removeSection(section.uid)}><DeleteSVG height={18} style={{marginRight:10}} /></div>
                      </div>

                      <table className={`section-columns`}>
                        <tbody>
                          {section.type === 'column-1' && (
                            <tr>
                              <td
                                className={`column ${section.type}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleElementDrop(e, section.uid, 0)}
                              >
                                {section.elements.map((element, elementIndex) => (
                                  element.columnIndex === 0 ? (
                                    <div
                                      key={elementIndex}
                                      className={`element ${selectedItem === element ? 'selected' : ''}`}  // Highlight selected elements
                                      onClick={(e) => selectItem(e, element)} // Handle click for element selection
                                      draggable
                                      onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                      onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                      onDragEnd={handleDragEnd}
                                    >
                                      <ElementRenderer
                                        key={element.uid}
                                        element={element}
                                        elementStyle={element.style}
                                        focused={selectedItem?.uid === element.uid}
                                        onElementUpdate={handleElementUpdate}
                                        onElementDelete={handleElementDelete}
                                        onElementCopy={handleElementCopy}
                                        preview={preview}
                                      />
                                    </div>
                                  ) : null
                                ))}
                              </td>
                            </tr>
                          )}

                          {/* Other column layouts (column-2) */}
                          {section.type === 'column-2' && (
                            <tr>
                              <td
                                className={`column ${section.type}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleElementDrop(e, section.uid, 0)}
                              >
                                {section.elements.map((element, elementIndex) =>
                                  element.columnIndex === 0 ? (
                                    <div
                                      key={elementIndex}
                                      className={`element ${selectedItem === element ? 'selected' : ''}`}
                                      onClick={(e) => selectItem(e, element)}
                                      draggable
                                      onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                      onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                      onDragEnd={handleDragEnd}
                                    >
                                      <ElementRenderer
                                        key={element.uid}
                                        element={element}
                                        focused={selectedItem?.uid === element.uid}
                                        onElementUpdate={handleElementUpdate}
                                        onElementDelete={handleElementDelete}
                                        onElementCopy={handleElementCopy}
                                        preview={preview}
                                      />
                                    </div>
                                  ) : null
                                )}
                              </td>
                              <td
                                className={`column ${section.type}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleElementDrop(e, section.uid, 1)}
                              >
                                {section.elements.map((element, elementIndex) =>
                                  element.columnIndex === 1 ? (
                                    <div
                                      key={elementIndex}
                                      className={`element ${selectedItem === element ? 'selected' : ''}`}
                                      onClick={(e) => selectItem(e, element)}
                                      draggable
                                      onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                      onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                      onDragEnd={handleDragEnd}
                                    >
                                      <ElementRenderer
                                        key={element.uid}
                                        element={element}
                                        focused={selectedItem?.uid === element.uid}
                                        onElementUpdate={handleElementUpdate}
                                        onElementDelete={handleElementDelete}
                                        onElementCopy={handleElementCopy}
                                        preview={preview}
                                      />
                                    </div>
                                  ) : null
                                )}
                              </td>
                            </tr>
                          )}

                          {/* Other column layouts (column-3) */}
                          {section.type === 'column-3' && (
                            <tr>
                              <td
                                className={`column ${section.type}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleElementDrop(e, section.uid, 0)}
                              >
                                {section.elements.map((element, elementIndex) =>
                                  element.columnIndex === 0 ? (
                                    <div
                                      key={elementIndex}
                                      className={`element ${selectedItem === element ? 'selected' : ''}`}
                                      onClick={(e) => selectItem(e, element)}
                                      draggable
                                      onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                      onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                      onDragEnd={handleDragEnd}
                                    >
                                      <ElementRenderer
                                        key={element.uid}
                                        element={element}
                                        focused={selectedItem?.uid === element.uid}
                                        onElementUpdate={handleElementUpdate}
                                        onElementDelete={handleElementDelete}
                                        onElementCopy={handleElementCopy}
                                        preview={preview}
                                      />
                                    </div>
                                  ) : null
                                )}
                              </td>
                              <td
                                className={`column ${section.type}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleElementDrop(e, section.uid, 1)}
                              >
                                {section.elements.map((element, elementIndex) =>
                                  element.columnIndex === 1 ? (
                                    <div
                                      key={elementIndex}
                                      className={`element ${selectedItem === element ? 'selected' : ''}`}
                                      onClick={(e) => selectItem(e, element)}
                                      draggable
                                      onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                      onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                      onDragEnd={handleDragEnd}
                                    >
                                      <ElementRenderer
                                        key={element.uid}
                                        element={element}
                                        focused={selectedItem?.uid === element.uid}
                                        onElementUpdate={handleElementUpdate}
                                        onElementDelete={handleElementDelete}
                                        onElementCopy={handleElementCopy}
                                        preview={preview}
                                      />
                                    </div>
                                  ) : null
                                )}
                              </td>
                              <td
                                className={`column ${section.type}`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleElementDrop(e, section.uid, 2)}
                              >
                                {section.elements.map((element, elementIndex) =>
                                  element.columnIndex === 2 ? (
                                    <div
                                      key={elementIndex}
                                      className={`element ${selectedItem === element ? 'selected' : ''}`}
                                      onClick={(e) => selectItem(e, element)}
                                      draggable
                                      onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                      onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                      onDragEnd={handleDragEnd}
                                    >
                                      <ElementRenderer
                                        key={element.uid}
                                        element={element}
                                        focused={selectedItem?.uid === element.uid}
                                        onElementUpdate={handleElementUpdate}
                                        onElementDelete={handleElementDelete}
                                        onElementCopy={handleElementCopy}
                                        preview={preview}
                                      />
                                    </div>
                                  ) : null
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ))}

                  {/* Drop Root Section */}
                  <div
                    className="drop-root"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      const sectionType = dragging?.element?.type;
                      const isSection = dragging?.element?.base === 'section';
                      if (sectionType && isSection) {
                        addSection(dragging.element);
                      }
                      setDragging(null);
                    }}
                  >
                    <span>Drop new column sections here</span>
                  </div>

                </div>
              :
                <EmailHtml json={sections} />
          }


        </div>
      </div>

      {/* Editor Panel */}
      <div className="editor-panel">
        <h3>Editor</h3>
        {
          selectedItem
            ?
              <>
                <h4>{selectedItem.base === "section" ? `${selectedItem.label} Section` : selectedItem.type}</h4>
                {selectedItem && selectedItem.base === 'section' && (
                  <SectionEditor selectedElement={selectedItem} onSectionChange={handleSectionChange} onSectionStyleChange={handleSectionStyleChange} />
                )}
                {selectedItem && selectedItem.type === 'Text' && (
                  <ElementEditorText selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange}  />
                )}
                {selectedItem && selectedItem.type === 'Button' && (
                  <ElementEditorButton selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange} onTextContentChange={onTextContentChange} onTextUrlChange={onTextUrlChange} />
                )}
                {selectedItem && selectedItem.type === 'Image' && (
                  <ElementEditorImage selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange}  onTextAltChange={onTextAltChange} onTextUrlChange={onTextUrlChange} />
                )}
                {selectedItem && selectedItem.type === 'Divider' && (
                  <ElementEditorDivider selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} />
                )}
              </>
      
            :
              <p>Select an element to edit</p>
        }
      </div>
      <Dialog
        open={openVariables}
        onClose={() => setOpenVariables(false)}
      >
        {
          constantTemplateVariables && customFields
            ?
              <Grid
                container
                style={{
                  padding:24
                }}
              >
                <Typography
                  style={{
                    fontSize:'1.3rem',
                    textAlign:'center',
                    width:'100%'
                  }}
                >
                  Field Variables
                </Typography>
                <Typography
                  style={{
                    textAlign:'center',
                    width:'100%',
                    marginBottom:16
                  }}
                >
                  Copy and paste into a text element
                </Typography>
                <Typography
                  style={{
                    fontSize:'.9rem',
                    fontWeight: 700,
                    textAlign:'center',
                    width:'100%',
                    marginBottom:16,
                    textTransform:'uppercase'
                  }}
                >
                  Click to copy
                </Typography>
                {
                  [ ...constantTemplateVariables, ...customFields ]?.map(field => (
                    <Grid
                      container
                      onClick={() => handleVariableCopy(field)}
                      sx={{
                        alignItems:'center',
                        padding:'4px 16px',
                        background:'#eee',
                        '&&:hover':{
                          background:'#ddd'
                        },
                        borderRadius:2,
                        marginBottom:1,
                        cursor:'pointer'
                      }}
                    >
                      <Grid
                        item
                        // xs
                      >
                        <Typography
                          style={{
                            fontSize:'.9rem',
                          }}
                        >
                          {field.user_visible_name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize:'.9rem',
                            fontWeight: 600
                          }}
                        >
                          {`{-{${field.field_name}}-}`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display:'flex',
                          justifyContent:'flex-end'
                        }}
                        xs
                      >
                        <IconButton

                        >
                          <CopySVG width={15} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
            : null
        }
      </Dialog>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
    </div>
  );
};

export default EmailTemplate;
