import React, { useState } from 'react'
import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
import { EmailSVG } from '../../assets/icons';
import RegisterForm from './RegisterForm';


const Register = () => {
  const [emailRegistration, setEmailRegistration] = useState(true);
  const theme = useTheme();


  return (
    <Grid
      container
      sx={{
        flexDirection:'column',
        padding:4
      }}
    >
      <Grid
        variant="center"
        sx={{
          flexDirection:'column'
        }}
      >
        <LogoHorizontal
          width={65
          }
        />
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontSize:'1.4rem',
            fontWeight: 600
          }}
        >
          REGISTER
        </Typography>
      </Grid>

      {
        emailRegistration
          ? // Show email registration fields                
          <RegisterForm />
          : // Show Registration buttons for different registration types
          <>
            <Grid
              variant="center"
            >
              <Grid
                variant="center"
                style={{
                  marginTop: 24
                }}
              >

                <Button
                  onClick={() => setEmailRegistration(true)}
                  variant="contained"
                  style={{
                    height: 65,
                    width: 292
                  }}
                >
                  <EmailSVG
                    width={30}
                    color1="#ffffff"
                    style={{
                      margin: '0 16px 0 0'
                    }}
                  />
                  Register with Email
                </Button>
              </Grid>
            </Grid>
          </>
      }

    </Grid>
  )
}

export default Register


