import { AppBar, Button, Collapse, Dialog, Divider, Drawer, Grid, IconButton, Menu, Popover, Slide, SpeedDial, Toolbar, Typography, useScrollTrigger } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { LogoHorizontal } from '../../assets/logos'
import { hashScroll } from '../../lib/Functions'
import { useScreenSize } from '../../lib/Interface';
import { ArrowSVG, CloseFilledSVG, CloseRoundedSVG, MoreMenuSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';
import ReusableModal from '../ui/ReusableModal';
import Register from '../auth/Register';
import HoverMenu from '../ui/CustomPopover';
import CustomPopover from '../ui/CustomPopover';
import { featureList, industryList } from '../../lib/Data';
import { productCopy } from '../../lib/Copy';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const MainNav = (props) => {
  const { single } = props;
  const theme = useTheme();
  const screenSize = useScreenSize();
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpen = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  }

  const handleClose = () => {
    setOpenMenu(false);
  }

  return (
    <HideOnScroll {...props}>
      <AppBar
        sx={{
          boxShadow:'0 0 10px #00000075'
        }}
      >
        {/* <Toolbar> */}
          <Grid
            container
            style={{
              background: '#fff',
              height:60,
              padding:'0 24px',
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
            <Grid
              style={{
                display:'flex',
                alignItems:'center'
              }}
            >
              <a href="/">
                <LogoHorizontal height={24} />
              </a>
            </Grid>
            {
              screenSize.xs
                ?
                  <>
                    <IconButton
                      onClick={handleOpen}
                    >
                      <MoreMenuSVG width={18} color1={theme.palette.primary.main} />
                    </IconButton>
                    <Drawer
                      anchor="right"
                      open={openMenu}
                      onClose={handleClose}
                    >
                      <Grid
                        container
                        style={{
                          flexDirection:'column',
                          alignItems:'center',
                          textAlign:'center',
                        }}
                      >
                        <IconButton
                          onClick={handleClose}
                          style={{
                            // padding:0,
                            margin:'15px 0'
                          }}
                        >
                          <CloseRoundedSVG width={18} style={{height:18}} color1={theme.palette.primary.dark} />
                        </IconButton>
                        <NavMenu single={single} popover={false} />
                        <Divider style={{margin:'24px 0'}} />
                        <AuthMenu single={single} />
                      </Grid>
                    </Drawer>

                  </>
                :
                  <>
                    <NavMenu single={single} />
                    <AuthMenu single={single} />
                  </>
            }
          </Grid>
        {/* </Toolbar> */}
      </AppBar>
    </HideOnScroll>

  )
}

export default MainNav

const NavMenu = ({ single, popover=true }) => {
  const screenSize = useScreenSize();
  const isMobile = screenSize.xs;

  return (
    <Grid
      style={{
        display:'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems:'center',
        // columnGap: isTablet ? 0 : 8,
        minWidth: isMobile ? 150 : 0
      }}
    >
      <NavPopoverButton
        label="Product"
        hash="product"
        href={ single ? null : '/product/'}
        popover={popover}
        sections={[
          {
            label:'Product Benefits',
            href:'/product/',
            items: productCopy.map(product => 
              ({
                label: product.shortName,
                href: `/product/#${product.hash}`
              })
            )
          },
          {
            label:'Industries',
            href:'/product/#industry',
            items: industryList.map(industry => 
              ({
                label: industry.shortName,
                href: `/product/#${industry.hash}`
              })
            )
          },
        ]}
      />
      {/* <NavPopoverButton
        label="Features"
        hash="features"
        href={ single ? null : '/features/'}
        popover={popover}
        sections={[
          {
            label:'Core Features',
            href:'/features/',
            items: featureList.filter(x => x.availability === 'now' && x.tier === 'included').map(feature => 
              ({
                label: feature.shortName,
                href: `/features/#${feature.hash}`
              })
            )
          },
          {
            label:'Addon Features',
            href:'/features/#addon',
            items:[
              {
                label:'Dedicated IP',
                href:'/features/#dedicated-ip'
              },
              {
                label:'Additional Emails',
                href:'/features/#more-emails'
              }
            ]
          }

        ]}
      /> */}
      <NavPopoverButton
        label="Why Us"
        hash="why"
        href={ single ? null : '/why/'}
        popover={popover}
        sections={[
          {
            label:'Our Difference',
            href:'/why/#difference',
            // items: featureList.filter(x => x.availability === 'now' && x.tier === 'included').map(feature => 
            //   ({
            //     label: feature.shortName,
            //     href: `/features/#${feature.hash}`
            //   })
            // )
          },
          {
            label:'Benefits',
            href:'/why/#benefits',
            // items: featureList.filter(x => x.availability === 'now' && x.tier === 'included').map(feature => 
            //   ({
            //     label: feature.shortName,
            //     href: `/features/#${feature.hash}`
            //   })
            // )
          },
        ]}
      />
      <NavPopoverButton
        label="Pricing"
        hash="pricing"
        href={ single ? null : '/prices/'}
        popover={popover}
        sections={[
          {
            label:'Subscription Prices',
            href:'/prices/',
            items:[
              {
                label:'Monthly',
                href:'/prices/?model=monthly'
              },
              {
                label:'Annual',
                href:'/prices/?model=annual'
              }
            ]
          },
          {
            label:'Addon Prices',
            href:'/prices/#addons',
            items:[
              {
                label:'Additional Emails',
                href:'/prices/#addon-emails'
              },
              {
                label:'Dedicated IP',
                href:'/prices/#addon-dedicated-ip'
              }
            ]
          }
        ]}
      />
      <NavButton
        label="Contact"
        hash="contact"
        href={ single ? null : '/contact/'}
      />
      {/* <Button
        onClick={() => setOpenRegister(true)}
        // fullWidth
        style={{
          fontWeight:600,
          minWidth:0,
          paddingLeft:8,
          paddingRight:8
        }}
      >
        Sign Up
      </Button>
      <Dialog
        open={openRegister}
        onClose={() => setOpenRegister(false)}
        maxWidth="sm"
      >
        <Register />
      </Dialog>
      <NavButton
        label="Login"
        href="/accounts/login/"
      /> */}
    </Grid>

  )
}

const AuthMenu = ({ single }) => {
  const [openRegister, setOpenRegister] = useState(false);
  const screenSize = useScreenSize();
  const isMobile = screenSize.xs;

  return (
    <Grid
      style={{
        display:'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems:'center',
        columnGap:8,
        minWidth: isMobile ? 150 : 0
      }}
    >
      {/* <AuthButton
        label="About"
        hash="about"
        href={ single ? null : '/#about'}
      />
      <AuthButton
        label="Features"
        hash="features"
        href={ single ? null : '/features'}
      />
      <AuthButton
        label="Pricing"
        hash="pricing"
        href={ single ? null : '/prices'}
      /> */}
      <Button
        onClick={() => setOpenRegister(true)}
        variant="contained"
        style={{
          fontWeight:600,
          minWidth:0,
          paddingLeft:24,
          paddingRight:24,
          textTransform:'capitalize',
          borderRadius:30
        }}
        >
        Sign Up
      </Button>
      <Dialog
        open={openRegister}
        onClose={() => setOpenRegister(false)}
        maxWidth="sm"
      >
        <Register />
      </Dialog>
      <AuthButton
        label="Login"
        href="/accounts/login/"
      />
    </Grid>

  )
}

const NavButton = (props) => {
  const { label, hash, href } = props;

  return (
    <Button
      onClick={() => hashScroll(hash)}
      href={href}
      // fullWidth
      style={{
        fontWeight:600,
        minWidth:0,
        paddingLeft:8,
        paddingRight:8
      }}
    >
      {label}
    </Button>
  )
}

const NavPopoverButton = (props) => {
  const { label, hash, href, sections, popover } = props;
  const [hover, setHover] = useState(null);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const [waitToOpen, setWaitToOpen] = useState(true);
  const theme = useTheme();
  const screenSize = useScreenSize();
  const isTablet = screenSize.sm;

  console.log('hover', hover);

  useEffect(() => {
    setTimeout(() => {
      setWaitToOpen(false);
    }, 1000);
  }, [])

  const handleOpen = () => {
    if (!waitToOpen) {
      if (open) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }

  return (
    <div
      ref={buttonRef}
      onMouseEnter={handleOpen}
      onMouseLeave={() => setOpen(false)}
    >
      <Button
        onClick={() => hashScroll(hash)}
        href={href}
        // fullWidth
        style={{
          fontWeight:600,
          minWidth:0,
          paddingLeft: isTablet ? 8 :16,
          paddingRight: isTablet ? 8 :16
        }}
      >
        {label}
      </Button>
      {
        sections && popover
          ?
            <CustomPopover
              anchorEl={buttonRef.current}
              open={open}
              // onClose={() => setOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              offset={20}
            >
              <Grid container maxWidth={800} minWidth={300}>
                {
                  sections.map((section, index) => (
                    <Grid
                      item
                      style={{
                        padding:8,
                        maxWidth:300,
                        minWidth:200
                      }}
                      xs={!index % 2 && index === sections.length-1 ? 12 : 6}
                    >
                      <Grid
                        // container
                        onMouseEnter={() => setHover(section.label)}
                        onMouseLeave={() => setHover(null)}
                        style={{
                          // border:`1px solid #CCCCCC`,
                          borderRadius:8,
                          overflow:'clip',
                          // background:'#fff',
                          boxShadow:'1px 1px 6px #00000020'
                        }}
                      >
                        <a href={section.href} style={{textDecoration:'none'}}>
                          <Grid
                            container
                            style={{
                              position:'relative',
                              // justifyContent:'space-between',
                              padding:8
                            }}
                          >
                            <Typography
                              style={{
                                color: hover === section.label ? '#FFF' : '#777777',
                                zIndex:100
                              }}
                            >
                              {section.label}
                            </Typography>
                            <ArrowSVG width={16} color1={theme.palette.background.secondary} style={{marginLeft:8, zIndex:100}} />
                            <div
                              style={{
                                position:'absolute',
                                bottom:0,
                                left:0,
                                background: `${theme.palette.primary.main}90`,
                                width:'100%',
                                height: hover === section.label ? '100%' : '5%',
                                transition:'.2s',
                              }}
                            />
                          </Grid>
                        </a>
                        {
                          section.items &&
                            <>
                              {/* <Divider style={{width:'100%'}} /> */}
                              <Grid
                                container
                                className='fill-scrollbar'
                                style={{
                                  padding:8,
                                  // maxHeight: hover === section.label ? 200 : 0,
                                  // transition:'.3s',
                                  maxHeight:200,
                                  overflow:'auto'
                                }}
                              >
                                {
                                  section.items?.map(item =>(
                                    <Grid
                                      container
                                    >
                                      <Button
                                        // variant="outlined"
                                        href={item.href}
                                        fullWidth
                                        style={{
                                          borderRadius:48,
                                          justifyContent:'flex-start',
                                          padding:'2px 16px'
                                        }}
                                      >
                                        {item.label}
                                      </Button>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                            </>
                        }
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
            </CustomPopover>
          :
            null
      }

    </div>
  )
}

const AuthButton = (props) => {
  const { label, hash, href } = props;

  return (
    <Button
      onClick={() => hashScroll(hash)}
      href={href}
      // fullWidth
      style={{
        fontWeight:600,
        minWidth:0,
        paddingLeft:8,
        paddingRight:8,
        textTransform:'capitalize'
      }}
    >
      {label}
    </Button>
  )
}
