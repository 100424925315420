import React from 'react'
import { Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import ReusableModal from '../ui/ReusableModal';
import { useAuth } from '../../context/authContext';
import PriceFeatureList from './PriceFeatureList';
import FinalCheckoutForm from './FinalCheckoutForm';
import Register from '../auth/Register';

//CREATED NEW TYPE.JS FILE IN /UI
import { Typography } from '../ui/type';
import { featureList } from '../../lib/Data';
import { useScreenSize } from '../../lib/Interface';


const PriceTableItem = (props) => {
  const { stacked, title, description, benefits, priceMon, priceAnn, priceModel, term, moreAction, moreButton, ctaButton, subscriptionDetails } = props;

  const authContext = useAuth();
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Grid
      variant="space-between"
      flexDirection="column"
      style={{
        background:'#fff',
        borderRadius:'16px',
        padding: screenSize.xs ? 18 : 40,
        boxShadow:'5px 5px 10px #00000015',
        height:'100%',
        width:'100%'
      }}
    >
      <Grid
        variant="center"
        flexDirection="column"
      >
        <Grid
          variant="center"
          flexDirection="column"
          mb={4}
        >
          {/* <Typography
            heading="h3"
            style={{
              color:theme.palette.primary.dark,
              fontWeight:500
            }}
          >
            {title}
          </Typography> */}
          {
            stacked
              ?
                <Grid
                  container
                  direction="column"
                  columnGap={1}
                >
                  <Grid
                    sx={{
                      textAlign:'center'
                    }}
                  >
                    <Typography
                      heading="h1"
                      style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'flex-start',
                        fontSize:72,
                        lineHeight:1,
                        color:theme.palette.primary.main,
                        padding:0,
                        margin:0,
                        marginTop:8,
                        marginLeft:-24
                      }}
                    >
                      <span style={{fontSize:48}}>$</span>
                      {
                        priceMon != 'INQUIRE'
                          ? priceModel === 'monthly' ? priceMon : priceAnn
                          : priceMon
                      }
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display:'flex',
                      // flexDirection:'column',
                      justifyContent:'center',
                      // alignItems:'flex-start',
                      marginBottom:6
                    }}
                  >
                    {
                      term &&
                        <Typography style={{lineHeight:1}}>
                          /{term}
                        </Typography>
                    }

                  </Grid>
                </Grid>
              :
                <Grid
                  container
                  columnGap={1}
                >
                  <Grid
                    sx={{
                      textAlign:'right'
                    }}
                  >
                    <Typography
                      heading="h1"
                      style={{
                        fontSize:50,
                        lineHeight:1,
                        color:theme.palette.primary.main,
                        padding:0,
                        margin:0,
                        marginTop:8
                      }}
                    >
                      {
                        priceMon != 'INQUIRE'
                          ? priceModel === 'monthly' ? `$${priceMon}` : `$${priceAnn}`
                          : priceMon
                      }
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      justifyContent:'flex-end',
                      alignItems:'flex-start',
                      marginBottom:6
                    }}
                  >
                    {
                      term &&
                        <Typography style={{lineHeight:1}}>
                          /{term}
                        </Typography>
                    }

                  </Grid>
                </Grid>
          }
        </Grid>

        <Typography
          style={{
            fontSize:'1.1rem',
            width:'80%'
          }}
        >
          {description}
        </Typography>

        {
          benefits?.map(benefit => (
            <Typography
              style={{
                fontSize:'1.1rem',
              }}
            >
              {benefit}
            </Typography>

          ))
        }

        <Grid
          variant="center"
          flexDirection="column"
          style={{
            marginTop:24,
            width:'100%',
            maxWidth:500
          }}
        >
          <Typography
            style={{
              fontSize:'1.1rem',
              fontWeight:700,
              color:'#000'
            }}
          >
            Included Features:
          </Typography>
          <Grid
            variant="center"
            style={{
              boxShadow: '0 0 5px #00000030',
              background: `${theme.palette.background.main}aa`,
              borderRadius: 16,
              marginTop: 8,
              width: '100%',
              overflow: 'clip'
            }}
          >
            <PriceFeatureList
              features={featureList.filter(x => x.availability === 'now' && x.tier === 'included')}
              info
            />
          </Grid>

          {
            featureList.filter(x => x.availability === 'now' && x.tier === 'addon').length
              ?
                <>
                  <Typography
                    style={{
                      fontSize:'1.1rem',
                      fontWeight:700,
                      color:'#000',
                      marginTop:24
                    }}
                  >
                    Available Addons:
                  </Typography>
                  <Grid
                    variant="center"
                    style={{
                      boxShadow: '0 0 5px #00000030',
                      background: `${theme.palette.background.main}aa`,
                      borderRadius: 16,
                      marginTop: 8,
                      width: '100%',
                      overflow: 'clip'
                    }}
                  >
                    <PriceFeatureList
                      features={featureList.filter(x => x.availability === 'now' && x.tier === 'addon')}
                      info
                    />
                  </Grid>
                </>
              :
                null
          }

{
            // featureList.filter(x => x.availability === 'soon').length
            //   ?
            //     <>
            //       <Typography
            //         style={{
            //           fontSize:'1.1rem',
            //           fontWeight:700,
            //           color:'#000',
            //           marginTop:24
            //         }}
            //       >
            //         Coming Soon:
            //       </Typography>
            //       <Grid
            //         variant="center"
            //         style={{
            //           boxShadow: '0 0 5px #00000030',
            //           background: `${theme.palette.background.main}aa`,
            //           borderRadius: 16,
            //           marginTop: 8,
            //           width: '100%',
            //           overflow: 'clip'
            //         }}
            //       >
            //         <PriceFeatureList
            //           features={featureList.filter(x => x.availability === 'soon')}
            //           info
            //         />
            //       </Grid>
            //     </>
            //   :
            //     null
          }

          {
            // featureList.filter(x => x.availability === 'later').length
            //   ?
            //     <>
            //       <Typography
            //         style={{
            //           fontSize:'1.1rem',
            //           fontWeight:700,
            //           color:'#000',
            //           marginTop:24
            //         }}
            //       >
            //         On The Roadmap:
            //       </Typography>

            //       <Grid
            //         variant="center"
            //         style={{
            //           boxShadow: '0 0 5px #00000030',
            //           background: `${theme.palette.background.main}aa`,
            //           borderRadius: 16,
            //           marginTop: 8,
            //           width: '100%',
            //           overflow: 'clip'
            //         }}
            //       >
            //         <PriceFeatureList
            //           features={featureList.filter(x => x.availability === 'later')}
            //           info
            //         />
            //       </Grid>
            //     </>
            //   :
            //     null
          }



          {/* <PriceFeatureList
            features={list}
            compact
            standard
          /> */}
        </Grid>
      </Grid>
      <Grid
        variant="center"
        flexDirection="column"
        style={{
          marginTop:24
        }}
      >
        {
          moreAction
            ?
              moreAction
            :
              moreButton
        }
        <span style={{marginTop:10}} />
        <ReusableModal maxWidth="sm">
          {ctaButton}

          <div>
          {
            // authContext.user
            //   ?
            //     <FinalCheckoutForm
            //       subscriptionDetails={subscriptionDetails}
            //     />
            //   :
                <Register />
          }
          </div>
        </ReusableModal>
      </Grid>
    </Grid>
  )
}

export default PriceTableItem

