import React from 'react'
import Lottie from 'react-lottie';

const LottiePlayer = (props) => {
  const { data, width, height, ratio } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: ratio ? ratio : "xMidYMid slice"
    }
  };

  return (
      <Lottie 
        isClickToPauseDisabled
        progressiveLoad
        options={defaultOptions}
        height={height ? height : "100%"}
        width={width ? width : "100%"}
      />
  );
}

export default LottiePlayer