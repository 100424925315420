import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Divider, Grid, IconButton, LinearProgress, Stack, Typography, formGroupClasses } from '@mui/material';
import { deleteContact, deleteForm, putForm, useGetAllCustomFields, useGetContacts, useGetForms, useGetUsers, deleteUser } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG, CheckSVG, SelectSVG, RemoveFillSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { formColumns } from '../../lib/Data';
import { Link } from 'react-router-dom';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import CustomTooltip from '../ui/CustomTooltip';
import ReusableModal from '../ui/ReusableModal';
import { mockData } from '../../lib/mockData';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { Dialog } from '@mui/material';
import SubuserAdd from './SubuserAdd';
import SubuserEdit from './SubuserEdit';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import DialogCreate from '../ui/DialogCreate';

const SubuserList = () => {
  const [reloadUsers, setReloadUsers] = useState(true);
  const [reloadFields, setReloadFields] = useState(false);
  const incomingUsers = useGetUsers({reload:reloadUsers});
  const [users, setUsers] = useState(incomingUsers?.results); // (mockData.contacts); // <-- Replace mockData
  const [snackMessage, setSnackMessage] = useState("");
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  const theme = useTheme();
  const screenSize = useScreenSize();

  // console.log('reloadContacts', reloadContacts);

  const displayColumns = [
    {
      field: 'name',
      label: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/contacts/${params.row.id}/edit/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'email',
      label: 'Email',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'actions',
      label: '',
      width:100,
      // flex:1,
      editable: false,
      renderCell: (params) => (
        <UserActions
          user={params.row}
          setSnackMessage={setSnackMessage}
          setReloadUsers={setReloadUsers}
        />
      )
    }
  ]

  const dataColumns = [
    {
      field: 'name',
      label: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/contacts/${params.row.id}/edit/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'actions',
      headerName: '',
      width:100,
      // flex:1,
      editable: false,
      renderCell: (params) => (
        <UserActions
          user={params.row}
          setSnackMessage={setSnackMessage}
          setReloadUsers={setReloadUsers}
        />
      )
    }
  ]

  // Reset 'reloadUsers' state to false after it is triggered
  useEffect(() => {
    if (reloadUsers) {
      setReloadUsers(false);
    }
  }, [reloadUsers])

  // Reset 'reloadFields' state to false after it is triggered
  useEffect(() => {
    if (reloadFields) {
      setReloadFields(false);
    }
  }, [reloadFields])

  // Set contacts state to incoming data on load
  useEffect(() => {
    if (incomingUsers) {
      setUsers(incomingUsers.results);  // <-- Uncomment when not using mock data
      setResetSearch(false);
    }
  }, [incomingUsers]);

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => users.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

  return (

    // <h1>Users Page</h1>

    <Grid
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        style={{
          borderRadius:8,
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
        }}
      >

        {
          users?.length
            ?
              // <DataGrid
              //   getRowId={(row) => row.id} 
              //   rows={users?.length ? users : []}
              //   columns={dataColumns}
              //   density="comfortable"
              //   disableVirtualization
              //   onRowSelectionModelChange={(ids) => {
              //     handleRowSelection(ids);
              //   }}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 20,
              //       },
              //     },
              //   }}
              //   sx={{
              //     '&.MuiDataGrid-root':{
              //       borderBottom:'none',
              //       '& .MuiDataGrid-cell:focus': {
              //         outline: 'none',
              //         borderBottom:`2px solid ${theme.palette.primary.main}`,
              //         background:`${theme.palette.primary.light}15`
              //       }
              //     },
              //     ...fill_mui_scrollbar
              //   }}
              //   // checkboxSelection
              //   disableRowSelectionOnClick
              // />
            <FillTable
              title="Team Members"
              rows={users?.length ? users : []}
              columns={displayColumns}
              // onRowClick={handleRowClick}
              // setFilterBy={setFilterBy}
              // pages={pages}
              // selectedRows={selected}
              // setSelectedRows={setSelected}
              // handleRowSelection={handleRowSelection}
              checkboxes={false}
              actionCell={(row) => (
                <UserActions
                  user={row}
                  setSnackMessage={setSnackMessage}
                  setReloadUsers={setReloadUsers}
                />
              )}
              renderCell={(row, column) => (
                <Stack direction="row">
                  <RenderCell row={row} column={column} />
                </Stack>
              )}
              // searchButton={
              //   <ReusableFilterSearch
              //     useSearch
              //     isOpen
              //     placeholder="Search Surveys"
              //     content={users} // incomingSurveys
              //     setContent={setUsers}
              //     filterBy={filterBy}
              //     setFilterBy={setFilterBy}
              //     searchFields={['name']}
              //   />
              // }
              toolbarButtons={[
                <CustomTooltip
                  title="Add Team Member"
                >
                  <IconButton
                    onClick={() => setOpenCreateUser(true)}
                    text={screenSize.sm ? null : "New Team Member"}
                  >
                    <AddSVG width={18} />
                  </IconButton>
                </CustomTooltip>
              ]}
              // toolbarActions={
              // }
              sx={{
                ...fill_mui_scrollbar
              }}
            />

            :
              <Loading />
        }

      </Grid>

      <DialogCreate
        open={openCreateUser}
        onClose={() => setOpenCreateUser(false)}
        hideButtons
        header="Add New Team Member"
        // title="Create An Email Template"
        messages={{
          // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
        }}
      >
        <SubuserAdd setReloadUsers={setReloadUsers} />
      </DialogCreate>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
  )
}

export default SubuserList


const UserActions = (props) => {
  const { user, setSnackMessage, setReloadUsers } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const removeUser = async () => {
    const deletedUser = await deleteUser(user.id);
    console.log('deleted');
    if (deletedUser.status === 'success') {
      console.log('successfully deleted the user(s)', deletedUser.data)
      setDeleting(true);
      setTimeout(() => {
        handleCloseConfirmDelete();
        setDeleting(false);
        setSnackMessage({ open: true, message: 'User removed', severity: 'success' })
        setReloadUsers(true);
      }, 2000);
    } else if (deletedUser.status === 'error') {
      console.log('failed to delete the user(s)', deletedUser)
      setSnackMessage({ open: true, message: deletedUser.error, severity: 'error' })
    }

  }


  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit user"
      >
        <PopUp mode="edit" 
          user={user}
          setReloadUsers={setReloadUsers} />
      </CustomTooltip>
      <CustomTooltip
        title="Delete user"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={18} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete User"
        title={`You are about to delete ${user?.name ? user?.name : 'Untitled User'}`}
        messages={{
          one:`Are you sure you want to delete the user with email ${user?.email}?`,
          two: `The user will be removed and permanently deleted. This cannot be undone.`,
          confirm:'Delete User'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removeUser}
        icons
      />

    </Stack>
  )
}

const PopUp = ({ mode, user, setReloadUsers }) => {
  const screenSize = useScreenSize();
  const [open, setOpen] = useState(false); // for the popup open & close (Dialog component)

  return (
    <>
      {mode === 'add' ? (
        <ButtonCustom
          onClick={() => setOpen(true)}
          iconStart={<AddSVG width={16} color1="#fff" />}
          text={screenSize.sm ? null : "New User"}
        />
      ) : (
        <IconButton
          onClick={() => setOpen(true)}
        >
          <EditSVG height={20} style={{ width: 20 }} />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            padding: 24,
            width: 'fit-content'
          }
        }}
      >
        {
          mode === 'add' 
          ?
            <SubuserAdd
              setReloadUsers={setReloadUsers} // Pass setReloadUsers to SubuserAdd
            />
          : <SubuserEdit user={user} setReloadUsers={setReloadUsers} />
        }
      </Dialog>
    </>
  )
}

const RenderCell = ({ row, column }) => {

  switch (column.field) {

    default:
      return (
        row[column.field]
      )
  }
}
