import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useApi } from '../../context/apiContext';
import DragAndDropFormCustomFieldOrder from '../ui/DragAndDropFormCustomFieldOrder';
import { getAllCustomFields, putUserDataBag, useGetUserDataBag } from '../../hooks/mutations';
import { useTheme } from '@emotion/react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { CloseFilledSVG, EditSVG } from '../../assets/icons';
import { InputField } from '../ui/Fields';
import CustomTooltip from '../ui/CustomTooltip';
import Infotip from '../ui/Infotip';

const customContactProfiles = [
  {
    id: 1,
    label: 'Profile 1', 
    order: [],
    attached: [],
    active: false
  },
  {
    id: 2,
    label: 'Profile 2', 
    order: [],
    attached: [],
    active: false
  },
  {
    id: 3,
    label: 'Profile 3', 
    order: [],
    attached: [],
    active: false
  }
]

const SettingsContactProfiles = () => {
  const apiContext = useApi();
  const contextUserDataBag = apiContext.contextUserDataBag;
  const savedContactProfiles = apiContext.contextUserDataBag?.contactProfiles;
  const contextCustomFields = apiContext.contextCustomFields;
  const [updatedContactProfiles, setUpdatedContactProfiles] = useState(savedContactProfiles);
  const [currentLayout, setCurrentLayout] = useState(null);
  const [activeLayout, setActiveLayout] = useState()
  const userDataBag = useGetUserDataBag();
  const theme = useTheme();

  // console.log('userDataBag', userDataBag)
  // console.log('updatedContactProfiles', updatedContactProfiles)

  // Initialize field profiles
  useLayoutEffect(() => {
    if (userDataBag?.data?.contactProfiles) {
      setUpdatedContactProfiles(userDataBag.data.contactProfiles);
    } else {
      setUpdatedContactProfiles(customContactProfiles);
    }
  }, [userDataBag?.data?.contactProfiles])
  
  const handleCustomFieldOrder = async (newOrder, index) => {
    // 
    const setOrder = newOrder.map((x, index) => ({ ...x, order: index+1 }));
    // apiContext.handleSetContextCustomFields(setOrder);

    const newLayout = [ ...updatedContactProfiles ];
    newLayout[currentLayout - 1].order = setOrder;

    setUpdatedContactProfiles(newLayout);
  }

  const handleCustomFieldLabel = async (value, index) => {
    // Update custom label for custom field layout
    const newLayout = [ ...updatedContactProfiles ];
    newLayout[index].label = value;
    setUpdatedContactProfiles(newLayout);
  }

  const handleCustomFieldVisibility = async (value, index) => {
    // Update visibility for custom field item in current layout
    const newLayout = [ ...updatedContactProfiles ];

    const newOrder = [ ...newLayout[currentLayout - 1].order ];
    // console.log('newOrder', newOrder);

    newOrder[index].visible = value;
    // console.log('newOrder', newOrder);

    newLayout[currentLayout - 1].order = newOrder;
    setUpdatedContactProfiles(newLayout);
  }

  const handleCustomFieldActive = async () => {
    // Update custom label for custom field layout
    const newLayout = [ ...updatedContactProfiles ];
    const newActive = newLayout.map((x, index) => {
      if (x.id === currentLayout) {
        return {
          ...x,
          active:true
        }
      } else {
        return {
          ...x,
          active:false
        }
      }
    })
    setUpdatedContactProfiles(newActive);
  }

  const handleSyncFields = async () => {
    const customFields = (await getAllCustomFields()).data;
    const reduced = customFields.map(x => ({ id: x.id, data_type: x.data_type, field_name: x.field_name, order: x.order}))

    const newLayout = [ ...updatedContactProfiles ];

    // Remove any fields in newLayout that don't exist in custom fields anymore
    // Filter out items from 'layoutItem.order' that don't exist in the 'reduced' array
    const newRemoved = newLayout.map(layoutItem => {
      const filteredOrder = layoutItem.order.filter(order =>
        reduced.some(item => item.id === order.id)
      );

      return {
        ...layoutItem,
        order: filteredOrder
      };
    })

    
    console.log('reduced', reduced);
    console.log('newRemoved', newRemoved);



    // Map over each item in newLayout
    const newActive = newRemoved.map(layoutItem => {
      // Filter out items from 'reduced' that don't exist in the 'order' array of the current layoutItem
      const filteredItems = reduced.filter(item =>
        !layoutItem.order.some(order => order.id === item.id)
      );

      // Combine filtered items with the current layoutItem
      const combinedOrder = [...layoutItem.order, ...filteredItems];

      // Filter out items that don't have an id
      const filteredOrder = combinedOrder.filter(x => x.id);

      // Add a visible false field if no visible field exists
      const visibleOrder = filteredOrder.map(field => {
        if (field.hasOwnProperty('visible')) {
          return field;
        } else {
          return {
            ...field,
            visible: true
          }
        }
      });

      // Remove duplicate items based on their 'id' fields
      const uniqueOrder = filteredOrder.filter(
        (item, index, self) => self.findIndex(i => i.id === item.id) === index
      );

      return {
        ...layoutItem,
        order: uniqueOrder
      };
    });

    setUpdatedContactProfiles(newActive);
  }

  const updateDataBag = async () => {

    const dataObj = {
      id: userDataBag.id,
      data: {
        data: {
          ...userDataBag.data,
          contactProfiles: updatedContactProfiles
        }
      }
    }

    const updateDataBag = await putUserDataBag(dataObj);

    if (updateDataBag.status === 'success') {
      // console.log(updateDataBag.response);
      apiContext.handleSetContextUserDataBag(updateDataBag.data);
    }
  }

  // Save data bag field updates after no changes have been made for 3 seconds
  useEffect(() => {
    let timeoutId;
    if (updatedContactProfiles && userDataBag) {
      timeoutId = setTimeout(() => {
        updateDataBag();       
      }, 100);
    }
  
    return () => {
      // Cleanup function
      clearTimeout(timeoutId);
    };
  }, [updatedContactProfiles]);
  

  return (
    userDataBag &&
    <Grid
    >
      <Grid
        style={{
          display:'flex',
          flex:1,
          height:70,
          borderBottom:'1px solid #aaa',
          alignItems:'center',
          justifyContent:'space-between',
          padding:'0 8px',
        }}
      >
        <div style={{display:'flex', alignItems:'center', gap:8}}>
          <Typography
            style={{
              fontSize:'1.1rem',
              fontWeight:500,
              color:'#aaa',
              marginLeft:8
            }}
          >
            Contact Profies
          </Typography>
          <Infotip
            question
            title="Contact Profiles"
            tip="Profiles are quick ways to display fields"
            messages={{
              one: "Contact Profiles allow you to quickly display a predefined set of fields for a contact.",
              two: "Profiles do not modify contact data. It only changes the fields that are currently being displayed. All other fields are still available when the profile is turned off."
            }}
          />
        </div>
        <div style={{display:'flex', alignItems:'center', gap:8}}>
          {/* <Button
            onClick={handleCustomFieldActive}
            variant="contained"
            style={{
              transform: currentLayout ? 'scale(1)' : 'scale(0)',
              width: currentLayout ? 'inherit' : 0,
              transition:'.25s'
            }}
          >
            Make Active
          </Button> */}
          {/* <Infotip
            info
            tip="Make the selected layout active for CRM"
            messages={{
              one: "Make the selected layout active for CRM"
            }}
          /> */}

          <Button
            onClick={handleSyncFields}
            variant="contained"
            style={{
              transform: currentLayout ? 'scale(1)' : 'scale(0)',
              width: currentLayout ? 'inherit' : 0,
              transition:'.25s'
            }}
          >
            Sync Fields
          </Button>
        </div>
      </Grid>
      <Grid
        container
      >
        <Grid
          item
          style={{
            height:'calc(100vh - 70px)',
            paddingTop:16,
            width: 250,
            borderRight:'1px solid #aaa'
          }}
          xs="auto"
        >
          {
            updatedContactProfiles?.map((layout, index) => (
              <MenuTab
                key={layout.id}
                tab={layout}
                index={index}
                currentLayout={currentLayout}
                setCurrentLayout={setCurrentLayout}
                handleCustomFieldLabel={handleCustomFieldLabel}
              />
            ))
          }
        </Grid>

        <Grid
          style={{
            height:'calc(100vh - 70px)',
            borderRight:'1px solid #aaa',
            overflow:'auto'
          }}
        >
          <Grid
            style={{
              display:'flex',
              justifyContent:'center',
              // width: currentLayout ? '100%' : '0%',
              transition: '.4s',
              overflowX:'hidden',
              paddingTop:16,
              width: currentLayout ? 300 : 0,
              // height:'100vh'
            }}
          >
            <DragAndDropFormCustomFieldOrder
              title="Position"
              incomingData={
                updatedContactProfiles?.[currentLayout - 1]?.order.length
                  ?
                    updatedContactProfiles[currentLayout - 1].order
                  :
                    contextCustomFields
              }
              nameField="field_name"
              updateData={handleCustomFieldOrder}
              handleCustomFieldVisibility={handleCustomFieldVisibility}
              setCurrentPage={() => {}}
              currentPage={() => {}}
            />
          </Grid>
        </Grid>

        {/* <Grid
          item
          style={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            padding:24
          }}
          xs
        >
          <Typography>
            Click and drag to rearrange custom fields
          </Typography>
          <Typography>
            {savedContactProfiles ? (savedContactProfiles.filter(x => x.active).length ? savedContactProfiles.filter(x => x.active)[0].label : 'No layout') : 'No layout'} is currently the active layout for custom fields. Make this the active layout by clicking below.
          </Typography>
          <Button
            onClick={handleCustomFieldActive}
            variant="contained"
          >
            Make Active
          </Button>
          <Typography>
            Did you add or delete any custom fields? Sync fields to update all of your field profiles.
          </Typography>
          <Button
            onClick={handleSyncFields}
            variant="contained"
            style={{
              transform: currentLayout ? 'scale(1)' : 'scale(0)',
              width: currentLayout ? 'inherit' : 0,
              transition:'.25s'
            }}
          >
            Sync Fields
          </Button>

        </Grid> */}
      </Grid>

      {/* <Grid
        item
        style={{
          width: currentLayout ? '100%' : '0%',
          transition: '.4s',
          overflowX:'hidden',
          // overflowY:'auto',
          // height:'100vh'
        }}
        xs="auto"
      >

        <DragAndDropFormCustomFieldOrder
          title="Position"
          incomingData={contextCustomFields}
          nameField="field_name"
          updateData={handleCustomFieldOrder}
          setCurrentPage={() => {}}
          currentPage={() => {}}
        />
      </Grid> */}
    </Grid>
  )
}

export default SettingsContactProfiles

const MenuTab = (props) => {
  const { tab, index, currentLayout, setCurrentLayout, handleCustomFieldLabel } = props;
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const theme = useTheme();

  return (
    <div
      onClick={() => setCurrentLayout(tab.id)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        background: currentLayout === tab.id ? `${theme.palette.primary.light}10` : 'none',
        transition:'.4s',
        paddingLeft:24,
        cursor:'pointer',
        // borderRight: currentLayout === tab.id ? `10px solid blue` : 'none'
      }}
    >
      {
        edit
          ?
            <InputField
              value={tab.label}
              onChange={(e) => handleCustomFieldLabel(e.target.value, index)}
              onBlur={() => setEdit(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setEdit(false);
                }
              }}
              style={{
                maxWidth:170
              }}
            />
          :
              <CustomTooltip
                title={tab.label.length > 14 ? tab.label : null} // Only show tooltip if label is long enough to overflow
              >
                <Typography
                  sx={{ // Match nav button style in django
                    fontSize: '1rem',
                    fontWeight: currentLayout === tab.id ? 500 : 400,
                    lineHeight:1.2,
                    color:'inherit',
                    textTransform:'none',
                    maxWidth:150,
                    overflow:'hidden',
                    whiteSpace:'nowrap',
                    textOverflow:'ellipsis',
                    '&&:hover': {
                      fontWeight:500,
                      background:'none'
                    }
                  }}
                >
                  {index+1}. {tab.label}
                </Typography>
              </CustomTooltip>
      }
      {
        hover && !edit &&
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEdit(true)
            }}
            style={{marginLeft:8}}
          >
            <EditSVG width={15} />
          </IconButton>
      }
      <div
        style={{
          position:'absolute',
          top: currentLayout === tab.id ? 0 : '50%',
          right:0,
          background:theme.palette.primary.main,
          height: currentLayout === tab.id ? '100%' : '0%',
          width:'10px',
          transition:'.4s'
        }}
      />
      <div
        style={{
          position:'absolute',
          top: 'calc(50% - 8px)',
          right:18,
          opacity: currentLayout === tab.id ? 1 : 0,
        }}
      >
        <a
          onClick={(e) => {
            if (currentLayout === tab.id) {
              e.stopPropagation();
              setCurrentLayout(null)
            }
          }}
          style={{
            position:'relative',
            zIndex:1200
          }}
        >
          <CloseFilledSVG width={16} color1={`${theme.palette.primary.light}77`} />
        </a>
      </div>
    </div>
  )
}