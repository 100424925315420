import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import CsvImportList from '../components/csv-impot/CsvImportList'
import { useGetCsvImports } from '../hooks/mutations'
import Paginate from '../components/ui/Paginate'
import { Grid } from '@mui/material'

const CsvImportListPage = () => {
  const [pageId, setPageId] = useState(1);
  const importResponse = useGetCsvImports(pageId);
  const importList = importResponse?.results;
  const [isPaginated, setIsPaginated] = useState(false);

  console.log('importResponse', importResponse);

  useEffect(() => {
    if (importResponse) {
      const needsPagination = importResponse.next || importResponse.previous;
      setIsPaginated(needsPagination);
    }
  }, [importResponse]);

  const nextPage = () => {
    if (importResponse?.next) {
      setPageId(prev => prev + 1);    
    }
  }

  const prevPage = () => {
    if (importResponse?.previous) {
      setPageId(prev => prev - 1);    
    }
  }

  const directPage = (pageNumber) => {
    setPageId(pageNumber);    
  }

  console.log('isPaginated', isPaginated);
  return (
    // <MainPageTemplate title="Welcome">
    <Grid container>
      <CsvImportList imports={importList} isPaginated={isPaginated} setPageId={setPageId} />
      <Paginate
        currentPage={pageId}
        count={importResponse?.count}
        prevClick={prevPage}
        nextClick={nextPage}
        directPage={directPage}
      />
    </Grid>

    // </MainPageTemplate>
  )
}

export default CsvImportListPage