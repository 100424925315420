import React, { useEffect, useState } from 'react'
import { Tag } from '../components/ui/Tags';
import { useGetTags } from '../hooks/mutations';
import { Skeleton } from '@mui/material';
import { getContrastTone } from '../lib/Functions';

const TagSingle = () => {
  const [tag, setTag] = useState(null);

  // var el = document.querySelector(`color_${tag?.color.replace('#', '')}`);
  // el.style.background = `color_${tag?.color}`;


  console.log('tag', tag);
  // Get the script element by its id
  // const scriptElement = {textContent: {tag: "Tag-1"}};
  const scriptElement = document.getElementById('django-tag');

  // Query all span elements with the class name 'your-class-name'
  const spans = document.querySelectorAll('span.tag_single');

  // Get the list of classes for each span element
    
  // Convert the DOMTokenList to an array for easier manipulation

  // Iterate over the selected elements
  spans.forEach(span => {
    // Do something with each span element
    const classes = span.classList;
    // classesArray = classesArray.concat(classes);
    const classesArray = Array.from(classes);
    console.log('classesArray', classesArray)

    // Filter the classes that start with 'django-tag-'
    const djangoTagClasses = classesArray.filter(className => className.startsWith('django-tag-'))[0];
    console.log('djangoTagClasses', djangoTagClasses)

    const scriptElement = document.getElementById(djangoTagClasses);
    console.log('scriptElement', scriptElement)

    try {
      const djangoTag = JSON.parse(scriptElement.textContent);
      console.log('Parsed djangoTag:', djangoTag);

      // Check if the properties exist in djangoTag
      if (djangoTag && typeof djangoTag === 'object') {

          // // Construct the CSS class name
          // const className = `name_${djangoTag.color?.replace('#', '')}`;

          // // Use querySelector to select an element with the constructed class name
          // const el = document.querySelector(`.${className}`);
          // console.log('Selected element:', el);

          // Check if the element is selected and update its background color
          if (span) {
            span.style.position ='relative';
            span.style.background = getContrastTone(djangoTag.color).background;
            span.style.padding = '4px 12px';
            span.style.borderRadius = '16px' ;
            span.style.overflow ='clip';
            span.style.color = getContrastTone(djangoTag.color).text
          } else {
              console.log('Element not found.');
          }
      } else {
          console.log('Invalid djangoTag object.');
      }
  } catch (error) {
      console.error('Error parsing JSON:', error);
  }


  });

 
  // useEffect(() => {
  //   if (scriptElement && !tag) {
  //     console.log('running useEffect')
  //   // Parse the JSON data
  //   try {
  //       const djangoTag = JSON.parse(scriptElement.textContent);
  //       console.log('Parsed djangoTag:', djangoTag);

  //       // Check if the properties exist in djangoTag
  //       if (djangoTag && typeof djangoTag === 'object') {
  //           console.log('djangoTag name:', djangoTag.name);
  //           console.log('djangoTag color:', djangoTag.color);
  //           console.log('djangoTag id:', djangoTag.id);

  //           // Construct the CSS class name
  //           // const className = `color_${djangoTag.color?.replace('#', '')}`;
  //           const className = 'tag_single'

  //           // Use querySelector to select an element with the constructed class name
  //           const el = document.querySelector(`.${className}`);
  //           console.log('Selected element:', el);

  //           // Check if the element is selected and update its background color
  //           if (span) {
  //             el.style.position ='relative';
  //             el.style.background = getContrastTone(djangoTag.color).background;
  //             el.style.padding = '4px 12px';
  //             el.style.borderRadius = '16px' ;
  //             el.style.overflow ='clip';
  //             el.style.color = getContrastTone(djangoTag.color).text
  //           } else {
  //               console.log('Element not found.');
  //           }
  //       } else {
  //           console.log('Invalid djangoTag object.');
  //       }
  //   } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //   }
  //   }
  // }, [scriptElement])
  
  // return (
    // tag
    //   ? <div className={`color_${tag?.color.replace('#', '')}`}><Tag tag={tag} /></div>
    //   : <Skeleton />
  // )
}

export default TagSingle