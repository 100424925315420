import { Grid, Typography } from '@mui/material'
import React from 'react'
import SurveyDisplayStartCollect from './form-1/SurveyDisplayStartCollect';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
// import SurveyDisplayEndCollect from './form-1/SurveyDisplayEndCollect';
import SurveysBuilder from './SurveysBuilder';

// I think we do need the EndCollect component here to display survey? Maybe?

const SurveyShareContainer = (props) => {
  const { survey, token } = props;
  const theme = useTheme();
  
  console.log('survey', survey);
  return (
    <Grid
      container
      style={{
        minHeight: '100vh',
        background: `${theme.palette.primary.main}33`,
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Grid container style={{ zIndex: 10, justifyContent: 'center', height:'100%' }}>
        <SurveyDisplayStartCollect survey={survey} incomingSurvey={survey} token={token} incomingPreview />
        {/* <SurveyDisplayEndCollect survey={survey} token={token} incomingPreview /> */}

      </Grid>
      <Typography>Powered by Fill Marketing</Typography>
      <img
        src={`http://internal.localfill.com:3000/static/img/relationship-management_500px.png`}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 50,
          height: '50%',
          width: 'auto',
          objectFit: 'contain',
          zIndex: 0
        }}
      />
      <a
        href="https://fillmarketing.com"
        target="_blank"
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          textDecoration: 'none',
          color: 'inherit'
        }}
      >
        <LogoHorizontal width={50} />
        <Typography>Fill Marketing</Typography>
      </a>

    </Grid>
  )
}

export default SurveyShareContainer