import React, { cloneElement, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, ClickAwayListener, Collapse, Dialog, Divider, FormControlLabel, Grid, IconButton, LinearProgress, Menu, Stack, TextField, Typography, gridClasses } from '@mui/material';
import { useTheme } from '@emotion/react';
import ReusableModal from '../ui/ReusableModal';
import ReusableSearch from '../ui/ReusableSearch';
import { AddRoundedSVG, AddSVG, AlignJustifySVG, CheckSVG, CloseFilledSVG, ContactSVG, CopySVG, DeleteSVG, DownloadSVG, EditSVG, EmailSVG, FilterSVG, MoreMenuSVG, MoreOptionsSVG, OpenInNewSVG, SelectColumnsSVG, SelectSVG, TeamSVG, UploadSVG } from '../../assets/icons';
import { copyString, generateContactName, transformFields, translateFieldType } from '../../lib/Functions';
import { contactStatusEnum, initHeaderColumns } from '../../lib/Data';
import { deleteContact, getCustomFieldFolder, getStorageFile, putContact, useGetAllLists, useGetTags } from '../../hooks/mutations';
import { DataGrid } from '@mui/x-data-grid';
import { Tag, TagButton } from '../ui/Tags';
import Infotip from '../ui/Infotip';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { InputField, SelectField } from '../ui/Fields';
import ReusableContactSearch from '../ui/ReusableContactSearch';
import { FillContactTable } from '../ui/FillContactTable';
import { DjangoComponents } from '../../templates/DjangoStyles';
import { useScreenSize } from '../../lib/Interface';
import { useApi } from '../../context/apiContext';


const ContactsCRMFillTable = (props) => {
  const { setSelectedContact, contacts, headerColumns, selectedContacts, setSelectedContacts, setActionType, open, setOpen, setFocusField, setReloadContacts, filterBy, setFilterBy, customFields, lists, pages, setDestinationPageId, activeFieldLayout, rowsPerPage, setRowsPerPage } = props;
  const [currentContact, setCurrentContact] = useState(null);
  const [selected, setSelected] = useState([]);
  // const [selectedColumns, setSelectedColumns] = useState(null);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const apiContext = useApi();
  const contextAllFields = apiContext.contextAllFields;
  // const fieldLayouts = apiContext.contextUserDataBag.data.fieldLayouts;
  // const activeLayout = fieldLayouts.find(x => x.active).order;
  // const visibleFields = activeLayout.filter(x => x.visible);
  // const visibleFieldsRemapped = visibleFields.map(x => ({ id: x.id, columnType: x.column_type, label: x.field_name, type: x.data_type }))
  const fieldLayouts = apiContext.contextUserDataBag?.data?.fieldLayouts ?? [];  // Ensure fieldLayouts exists
  const activeLayout = fieldLayouts.find(x => x.active)?.order ?? [];  // Ensure activeLayout exists
  
  // Memoize visibleFieldsRemapped so it only recalculates when the dependencies change
  const visibleFieldsRemapped = useMemo(() => {
    const visibleFields = activeLayout.filter(x => x.visible);
      return visibleFields.map(x => ({
        id: x.id,
        columnType: x.column_type,
        label: x.field_name,
        field: x.id,
        type: x.data_type,
      }));
  }, [activeLayout]);
  
  const [displayColumns, setDisplayColumns] = useState(headerColumns ? [ ...new Set([...initHeaderColumns, ...headerColumns]) ] : initHeaderColumns);
  // const [displayColumns, setDisplayColumns] = useState(visibleFieldsRemapped ? visibleFieldsRemapped : initHeaderColumns);
  const [dataColumns, setDataColumns] = useState([]);
  const [hover, setHover] = useState(null);
  const [reloadTags, setReloadTags] = useState(false);
  const tags = useGetTags({pageId:null, reload:reloadTags});
  // const lists = useGetAllLists();
  const [paginationModel, setPaginationModel] = useState({
    pageSize:20,
    page:0,
  })

  const parentRef = useRef(null);
  const theme = useTheme();

  // console.log('headerColumns', headerColumns);
  // console.log('initHeaderColumns', initHeaderColumns);
  // console.log('displayColumns', displayColumns);
  // console.log('contacts', contacts);

  const handleFocusLeave = () => {
    // Your function logic here
    // console.log('Focus has left the parent and its children.');

    // console.log('contacts', contacts);
  };

  // Add header columns to display columns when headerColumns comes in through props
  useEffect(() => {
    if (contextAllFields) {
      const newColumns = [ ...new Set([...initHeaderColumns, ...headerColumns]) ];
      setDisplayColumns(newColumns)
    }
  }, [contextAllFields])

  useEffect(() => {
    if (contacts) {
      sortContacts();
      if (currentContact) {
        updateCurrentContact();
      }
      // generateHeaderColumns();
    }
  }, [contacts])

  useEffect(() => {
    if (parentRef) {
      handleFocusLeave();
    }
  }, [parentRef]);

  useEffect(() => {
    if (reloadTags) {
      setReloadTags(false);
    }
  }, [reloadTags]);

  // Update dataColumns every time displayColums changes
  useEffect(() => {
    if (displayColumns) {
      dataGridDisplayColumns();
    }
  }, [displayColumns])

  // Set display columns for data grid display
  const dataGridDisplayColumns = () => {
    const newDataColumns = displayColumns.map(item => {
      return ({
        field: item.field,
        headerName: item.label,
        minWidth:125,
        flex:1,
        type: item.field === 'tags' ? 'actions' : null,
        editable: false,
        renderCell: 
        (params) => {
          // if (item.type === 'enum' || item.type === 'list') {
            // return null;
          // }
          const contact = params.row;
          // Handle row double click

          const handleDoubleClick = () => {
            setCurrentContact(contact);
            setSelectedContact(contact);
            setActionType('Update Contact');
            setFocusField(item.field);
            setSelectedContacts([])
            if(!open) setOpen(true);
          }


          return (
            <Stack
              // onClick={(e) => {
              //   if (e.detail === 1) {

              //   }
              //   if (e.detail === 2) {
              //     // handleDoubleClick()
              //   }
              // }}
              direction="row"
              spacing={2}
            >
              {
                <TextCell
                  contactId={contact.id}
                  key={item.field}
                  label={item.label}
                  field={item.field}
                  type={item.type}
                  input={item.columnType === 'custom'
                    ? contact.custom_fields?.[item.id] // We use the id in custom fields because the format is { [id]: value }
                    : typeof contact[item.field] === 'object' && Array.isArray(contact[item.field])
                      ? item.type === 'tags'
                        ? contact[item.field]
                        : contact[item.field].map(item => item.name)
                      : contact[item.field]
                  }
                  // selectedContact={selectedContact}
                  setCurrentContact={setCurrentContact}
                  displayColumns={displayColumns}
                />
              }
            </Stack>

          )

        }

      })
    })
    newDataColumns.push({
        field: 'actions',
        headerName: '',
        width:100,
        // flex:1,
        editable: false,
        renderCell: 
        (params) => {
          const contact = params.row;

          return (
            <Stack
              direction="row"
              spacing={.5}
            >
              <CustomTooltip
                title="View Contact"
              >
                <IconButton
                  href={ `/contacts/${contact.id}`}
                >
                  <ContactSVG height={16} style={{width:20}} />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip
                title="Delete contact"
              >
                <IconButton
                  onClick={() => {
                    setContactToDelete(contact);
                    setOpenConfirmDelete(true);
                  }}
                >
                  <DeleteSVG height={18} style={{width:20}} />
                </IconButton>
              </CustomTooltip>
            </Stack>

          )

        }


    })

    setDataColumns(newDataColumns);

  }

  const handleOnCellClick = (params) => {
    // console.log('param', params);
    // Actions for Mailing List column
    if (params.fields === 'mailing_lists') {

    }
  }

  // Update currently selected contact if contacts state changes
  const updateCurrentContact = () => {
    const newContacts = [ ...contacts ];
    const currentItem = newContacts.filter(x => x.id === currentContact.id);
    setCurrentContact(currentItem);
  }

  const handleFocus = (contact) => {
    setCurrentContact(contact);
    setSelectedContact(contact);
  }

  // This is a duplicate of the removeContact function in the BulkActions component
  const removeContact = async () => {
    setDeleting(true);
    const deletedContact = await deleteContact(contactToDelete.id);
    if (deletedContact.status === 'success') {
      setReloadContacts(true); // <-- Reload the contacts
      setOpenConfirmDelete(false);
      setDeleting(false);
    }

  }

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  // // Create header column list based on incoming contact fields
  // const generateHeaderColumns = () => {
  //   const headers = transformFields(contacts);
  //   setHeaderColumns(headers);
  // } 

  // Sort contacts
  const sortContacts = () => {
    const newContacts = [...contacts];
    // const sorted = newContacts.filter(x => x.status === "Make Call");
    // console.log('sorted', sorted);
    // const unsorted = newContacts.filter(x => x.status != "Make Call");
    // console.log('unsorted', unsorted);

    setSortedContacts(newContacts);

  }

  // Determine which columns will be used
  const handleColumnDisplay = (col) => {
    let newColumns = [];
  
    if (displayColumns.some(column => column.field === col.field)) {
      // Column is already in displayColumns, remove it
      newColumns = displayColumns.filter(x => x.field !== col.field);
    } else {
      // Column is not in displayColumns, add it
      newColumns = [...displayColumns, col];
    }
  
    setDisplayColumns(newColumns);
  };

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => contacts.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

    
  // Select or unselect ONE contact from the display grid and add/remove from selectedContacts array
  const handleSingleSelect = (contact) => {
    if (selectedContacts[0] != contact) {
      setSelectedContacts([contact]);
    }
    setSelectedContact(contact);
    setCurrentContact(contact);
  }

  // Select or unselect ONE contact from the display grid and add/remove from selectedContacts array
  const handleDoubleClick = (contact) => {
    setSelectedContact(contact);
    setCurrentContact(contact);
    setOpen(true);
  }


  // Select or unselect contacts from the display grid and add/remove from selectedContacts array
  const handleMultiSelect = (contact) => {
    if (selectedContacts.includes(contact)) {
      const newSelection = selectedContacts.filter(x => x != contact);
      setSelectedContacts(newSelection);
    } else {
      const newSelection = [...selectedContacts, contact];
      setSelectedContacts(newSelection)
    }
    setActionType('Update Multiple Contacts')
  }

  const handleRowClick = (row) => {
    setCurrentContact(row);
    setSelectedContact(row);
    setSelectedContacts([]);
    if (!open) setOpen(true);
  }


  // MENU ITEMS
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const openFilter = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  // CUSTOM FILTER KEYS
  const keys = Object.keys(filterBy);
  const hiddenKeys = ['page_size', 'page'];
  const filterKeys = keys.filter(k => !hiddenKeys.includes(k));

  function filterKeysWithValues(obj) {
    const newObj = { ...obj };
    // Remove any filters the user should not have access to in the menu
    delete newObj.page_size;
    delete newObj.page;

    const keysWithValues = Object.keys(newObj).filter(key => {
      const value = newObj[key];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== undefined;
    });

    return keysWithValues;
  }

  const keysWithFilter = filterKeysWithValues(filterBy);

  // HANDLE SERVER SIDE PAGINATION

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    const newPage = newPaginationModel.page + 1;
    setDestinationPageId(newPage);
    setSortedContacts([])
  }




  return (
    <DjangoComponents card inner scrollable>
      <Grid
        style={{
          // maxHeight:'calc(100vh - 200px)',
          flex:1,
          height:'100%',
          overflowY:'clip'
        }}
      >
        {
          sortedContacts && dataColumns
            ?
              <FillContactTable
                className="fill-scroll"
                title="Contact List"
                rows={sortedContacts}
                columns={visibleFieldsRemapped}
                onRowClick={handleRowClick}
                setFilterBy={setFilterBy}
                pages={pages}
                setDestinationPageId={setDestinationPageId}
                selectedRows={selected}
                setSelectedRows={setSelected}
                handleRowSelection={handleRowSelection}
                actionCell={
                  <ActionsCell
                    contact={null}
                    setContactToDelete={setContactToDelete}
                    setOpenConfirmDelete={setOpenConfirmDelete}
                  />
                }
                renderCell={
                  <TextCell
                    setCurrentContact={setCurrentContact}
                    displayColumns={visibleFieldsRemapped}
                  />
                }
                searchButton={
                  <ReusableContactSearch
                    useSearch
                    isOpen
                    content={contacts}
                    setContent={setSortedContacts}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    placeholder="Search for name or email"
                    searchFields={['name', 'primary_email']}
                  />
                }
                toolbarButtons={[
                  // <ReusableModal tooltip="Select which columns to display">
                  //   <IconButton
                      
                  //   >
                  //     <SelectColumnsSVG width={18} color1={theme.palette.primary.main} />
                  //   </IconButton>
              
                  //   <Grid
                  //     style={{
                  //       padding:24
                  //     }}
                  //   >
                  //     <Typography
                  //       style={{
                  //         fontSize:'1.5rem',
                  //         textAlign:'center'
                  //       }}
                  //     >
                  //       Display Columns
                  //     </Typography>
                  //     {
                  //       activeFieldLayout &&
                  //         <Grid container variant="center" gap={1}>
                  //           <Typography
                  //             style={{
                  //               fontSize:'1rem',
                  //               textAlign:'center'
                  //             }}
                  //           >
                  //             Using <span style={{fontWeight:600}}>{activeFieldLayout.label}</span> fields layout
                  //           </Typography>
                  //           <Infotip
                  //             question
                  //             persist
                  //             title="Google Recaptcha"
                  //             tip="Prevent Spam Submissions"
                  //             messages={{
                  //               one: 'Google recaptcha requires contacts to confirm they are human before the from can be submitted. This prevents bots from spamming you.',
                  //               two: 'We highly recommend using this option.'
                  //             }}
                  //           />
                  //         </Grid>
                  //     }
                  //     <Button
                  //       onClick={() => setDisplayColumns(initHeaderColumns)}
                  //     >
                  //       Remove Custom Fields
                  //     </Button>
                  //     <Button
                  //       onClick={() => setDisplayColumns(headerColumns)}
                  //     >
                  //       Use All Fields
                  //     </Button>
                      
                  //     {
                  //       headerColumns.map(col => { 
                  //         if (col.type === 'large_text_field') return;  // Skip rich text field types
                  //         return (
                  //           <Grid
                  //             key={col.field}
                  //             variant="space-between"
                  //             onClick={() => handleColumnDisplay(col)}
                  //             style={{
                  //               background: displayColumns.some(column => column.field === col.field) ? theme.palette.primary.light : '#eee',
                  //               padding:'4px 8px 4px 16px',
                  //               margin:'4px 0',
                  //               borderRadius:32,
                  //               cursor:'pointer'
                  //             }}
                  //           >
                  //             <Typography>
                  //               {col.label}
                  //             </Typography>
                  //             <Typography style={{fontSize:'.85rem', textTransform:'uppercase', background:'#ffffffcc', borderRadius:16, lineHeight:1, padding:'2px 8px'}}>
                  //               {translateFieldType(col.type ? col.type : 'required')}
                  //             </Typography>
                  //           </Grid>
                  //       )})
                  //     }
                  //     <Typography
                  //       textAlign="center"
                  //     >
                  //       Rich text cannot be displayed in the list view
                  //     </Typography>
                  //   </Grid>
                  // </ReusableModal>,
                  <>
                    <CustomTooltip
                      title="Filter contacts"
                    >
                      <IconButton
                        onClick={handleClickFilter}
                      >
                        <FilterSVG width={18} color1={theme.palette.primary.main} />
                      </IconButton>
                    </CustomTooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElFilter}
                      open={openFilter}
                      onClose={handleCloseFilter}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        padding:0,
                      }}
                    >
                      <FilterMenu
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        customFields={customFields}
                        keys={filterKeys}
                        keysWithFilter={keysWithFilter}
                      />
                    </Menu>
                  </>,
                  <CustomTooltip
                    title="Import contacts from csv"
                  >
                    <IconButton
                      href="/contacts/api/import/csv/"
                    >
                      <UploadSVG height={18} color1={theme.palette.primary.main} />
                    </IconButton>
                  </CustomTooltip>,                
                  <CustomTooltip
                    title="Create contact"
                  >
                    <IconButton
                      href="/contacts/add/"
                      variant="outlined"
                    >
                      <AddRoundedSVG width={18} color1={theme.palette.primary.main} />
                    </IconButton>
                  </CustomTooltip>
                ]}
                toolbarActions={
                  <BulkActions
                    selected={selected}
                    tags={tags}
                    lists={lists}
                    customFields={customFields}
                    setReloadContacts={setReloadContacts}
                    setReloadTags={setReloadTags}
                  />
                }
              />
            : null
        }

      </Grid>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Contact"
        title={`You are about to delete ${contactToDelete?.name ? contactToDelete?.name : 'Untitled Contact'}`}
        messages={{
          one:`Are you sure you want to delete the contact with email ${contactToDelete?.primary_email}?`,
          two: `The user will be removed from all mailing lists and permanently deleted. This cannot be undone.`,
          confirm:'Delete Contact'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removeContact}
        icons
      />

    </DjangoComponents>
  )
}

export default ContactsCRMFillTable

const TextCell = (props) => {
  const { contactId, input, type, field, label, onClick, setCurrentContact, displayColumns, header } = props;
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [file, setFile] = useState(null);
  const [openListDialog, setOpenListDialog] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (input && type === 'image_file') {
        // console.log('input', input)
        try {
          const storageFolder = await getCustomFieldFolder();
          const storageFolderId = storageFolder.folderId;

          const imgObj = {
            folderId: storageFolderId,
            fileId: input
          };
    
          const file = await getStorageFile(imgObj);
          setFile(file.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      setFile(null); // cleanup function
    };
  }, [type, input]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied])

  // Tag Menu
  const handleClickTag = () => {
    setOpenTag(true);
  }

  const handleCloseTag = () => {
    setOpenTag(false);
  }

  const handleClickList = () => {
    setOpenList(true);
  }

  const handleCloseList = () => {
    setOpenList(false);
  }

  const copyText = () => {
    const copiedString = copyString(input);
  }

  const TextWrap = () => {
    
    switch (type) {
      case 'status': // This is a type for the status field (not a custom field type)
        return (
          <TagButton
            bgColor={contactStatusEnum.filter(x => x.status === input)[0]?.color}
            fontColor={contactStatusEnum.filter(x => x.status === input)[0]?.text}
            outlined
            outlinedColor={contactStatusEnum.filter(x => x.status === input)[0]?.color}
            // onClick={handleAddButtonStatusClick}
            // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingButtonTag ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
            label={contactStatusEnum.filter(x => x.status === input)[0]?.label}
          />
        );

      case 'tags': // This is a type for the tags fields (not a custom field type)
        return (
          <Grid
            container
            onMouseEnter={handleClickTag}
            onMouseLeave={handleCloseTag}
            gap={.5}
            style={{
              alignItems:'flex-start'
            }}
          >
            {
              input?.slice(0,2)?.map(tag => (
                <Tag
                  tag={tag}
                  // removeTag={removeTag}
                  // setReload={setReload}
                  // contactId={contact.id}
                  // reloadContact={reloadContact}
                  // showOptions
                />
              ))
            }
            {
              input.length > 2
                ?
                  <div>
                    <TagButton
                      bgColor={theme.palette.light.light}
                      fontColor={theme.palette.mid.dark}
                      // outlined
                      // outlinedColor={contactStatusEnum.filter(x => x.status === input)[0].color}
                      onClick={handleClickTag}
                      // iconStart={<MoreOptionsSVG width={12} color1="#fff" />}
                      label={<MoreOptionsSVG width={20} color1="#000" style={{height:14}} />} //{`${input.length - 2} more`}
                    />

                    <ClickAwayListener
                      onClickAway={handleCloseTag}
                    >
                    <div style={{position:'absolute', zIndex:500}}>
                      <Collapse
                        in={openTag}
                        onKeyDown={(e) => {
                          if (e.key === "Escape") {
                            handleCloseTag()
                          }
                        }}  
                      >
                        <Grid
                          container
                          style={{
                            flexDirection:'column',
                            gap:4,
                            marginTop:4,
                            padding:16,
                            background:'#fff',
                            borderRadius:8,
                            boxShadow: `0 0 10px #00000030`
                          }}
                        >
                          {
                            input.map(tag => (
                              <Tag
                                tag={tag}
                              />
                            ))
                          }
                        </Grid>
                      </Collapse>
                    </div>
                    </ClickAwayListener>
                  </div>
                :
                  null
            }
          </Grid>
      );

      case 'url':
        return (
          <Typography
            id={`id-${input}`}
            style={{
              // padding:'16px 12px',
              overflow:'hidden',
              textOverflow:'ellipsis',
              whiteSpace:'nowrap',
            // cursor:'default'
            }}
          >
            <a
              href={`http://${input}`}
              target="_blank"
              variant="text"
              style={{
                // textDecoration:'none',
                // color:'inherit'
              }}
            >
              {input}
            </a>
          </Typography>
        );

      case 'image_file':
        return (
          file
            ?
              <img
                src={file.file}
                style={{
                  maxHeight:56,
                  objectFit:'contain'
                }}
              />
            :
              null
        );

      case 'list':
        return (
          <Grid
            container
            style={{
              position:'relative',
              overflow:'hidden',
              textOverflow:'ellipsis',
              whiteSpace:'nowrap',
            }}
          >
            {
              input?.length > 1
                ?
                  <Grid container style={{position:'relative'}}>
                    <Grid
                      item
                      id={`more-${contactId}`}
                      component="button"
                      onClick={() => setOpenListDialog(true)}
                      style={{
                        position:'relative',
                        border:`1px solid ${theme.palette.primary.light}`,
                        background:'#ffffff70',
                        cursor:header ? 'default' : 'pointer',
                        padding:'2px 8px',
                        margin:'1px 1px',
                        borderRadius:16
                      }}
                    >
                      <Typography
                        component="button"
                        style={{
                          textDecoration:'none',
                          outline:'none',
                          border:'none',
                          background:'none',
                          padding:0,
                          lineHeight:1,
                          fontSize:'.85rem',
                          fontWeight:500,
                          overflow:'hidden',
                          textOverflow:'ellipsis',
                          whiteSpace:'nowrap',
                          color:theme.palette.primary.main
                        }}
                      >
                        {input?.length} lists
                      </Typography>
                    </Grid>
                    <Dialog
                      id="basic-menu"
                      open={openListDialog}
                      onClose={() => setOpenListDialog(false)}
                    >
                      <Grid
                        container
                        variant="center"
                        style={{
                          padding:24
                        }}
                      >
                        <Grid
                          container
                          variant="center"
                        >
                          <Typography
                            style={{
                              fontSize:'1.3rem',
                              fontWeight:500
                            }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                        {input?.map(item => (
                          <Grid
                            item
                            onDoubleClick={copyText}
                            style={{
                              border:`1px solid ${theme.palette.primary.light}`,
                              background:'#ffffff70',
                              cursor:header ? 'default' : 'pointer',
                              padding:'2px 16px',
                              margin:'4px 4px',
                              borderRadius:16
                            }}
                          >
                            <Typography
                              id={`id-${item}`}
                              style={{
                                padding:0,
                                // lineHeight:1,
                                // fontSize:'.85rem',
                                overflow:'hidden',
                                textOverflow:'ellipsis',
                                whiteSpace:'nowrap',
                                // cursor:'default'
                              }}
                            >
                              {item}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                    </Dialog>

                  </Grid>
                :
                  input?.map(item => (
                    <Grid
                      item
                      // onDoubleClick={copyText}
                      style={{
                        border:`1px solid ${theme.palette.primary.light}`,
                        background:'#ffffff70',
                        cursor:header ? 'default' : 'pointer',
                        padding:'2px 8px',
                        margin:'1px 1px',
                        borderRadius:16
                      }}
                    >
                      <Typography
                        id={`id-${item}`}
                        style={{
                          padding:0,
                          lineHeight:1,
                          fontSize:'.85rem',
                          fontWeight:500,
                          overflow:'hidden',
                          textOverflow:'ellipsis',
                          whiteSpace:'nowrap',
                          color:theme.palette.primary.main
                        }}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  ))
            }

          </Grid>
        );
      
      default:
        return (
          <Grid
            container
            style={{
              position:'relative',
              cursor:header ? 'default' : 'pointer'
            }}
          >
            <CustomTooltip
              title={input}
            >
              <Typography
                id={`id-${input}`}
                style={{
                  // padding:'16px 12px',
                  overflow:'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace:'nowrap',
                  cursor:'default'
                }}
              >
                {input}
              </Typography>
            </CustomTooltip>
            {/* <IconButton
              onClick={copyText}
              style={{
                position:'absolute',
                bottom:0,
                right:0,
                width:18,
                height:18
              }} 
            >
              <CopySVG width={15} />
            </IconButton> */}
              
          </Grid>
        );
    }
  }

  const HeaderWrap = () => {
    return (
      <Grid
        onDoubleClick={copyText}
        style={{
          cursor:header ? 'default' : 'pointer'
        }}
      >
        <Typography
          id={`id-${input}`}
          style={{
            // padding:'16px 12px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            color:'#fff'
          }}
        >
          {input}
        </Typography>
      </Grid>

    )
  }


  return (
    <>
      <Grid
        container
        onClick={onClick}
        style={{
          alignItems:'flex-start'
        }}
        // xs={12 / displayColumns.length}
      >
        {
          header && !type
            ? <HeaderWrap />
            : <TextWrap />

        }
      </Grid>
      
      {
        copied &&
          <Grid
            style={{
              position:'fixed',
              bottom:50,
              right:50,
              display:'flex',
              background:'#333',
              padding:'4px 16px',
              borderRadius:8,
              zIndex:5000
            }}
          >
            <CopySVG width={20} color1="#fff" color2="#aaa" />
            <Typography style={{color:'#fff', marginLeft:8}}>
              {input}
            </Typography>
          </Grid>
      }
    </>
  )
}

const BulkActions = (props) => {
  const { selected, tags, lists, customFields, setReloadContacts, setReloadTags } = props;
  const [action, setAction] = useState(null);
  const theme = useTheme();
  const screenSize = useScreenSize();

  const handleCancel = () => {
    setAction(null)
  }

  return (
    <Grid
      container
      gap={1}
    >
      <ActionButton
        label="Tag"
        icon={<CheckSVG height={18} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('tag')}
      />
      <ActionButton
        label="Add to list"
        icon={<EmailSVG height={18} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('list')}
      />
      <ActionButton
        label="Generate name"
        icon={<TeamSVG height={18} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('name')}
      />
      <ActionButton
        label="Delete"
        icon={<DeleteSVG height={18} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('delete')}
      />

      <Dialog
        open={action}
        onClose={() => setAction(null)}
        scroll={screenSize.md ? 'body' : 'paper'}
      >
        {action === 'tag' && 
          <BulkTags
            selected={selected}
            tags={tags}
            setReloadContacts={setReloadContacts}
            setReloadTags={setReloadTags}
            handleCancel={handleCancel}
          />
        }
        {action === 'list' && 
          <BulkLists
            selected={selected}
            lists={lists}
            setReloadContacts={setReloadContacts}
            handleCancel={handleCancel}
          />
        }
        {action === 'name' && 
          <BulkNames
            selected={selected}
            customFields={customFields}
            setReloadContacts={setReloadContacts}
            handleCancel={handleCancel}
          />
        }
        {action === 'delete' && 
          <BulkDelete
            selected={selected}
            setReloadContacts={setReloadContacts}
            handleCancel={handleCancel}
          />
        }
      </Dialog>

    </Grid>
  )
}

const BulkTags = (props) => {
  const { selected, tags, setReloadContacts, setReloadTags, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const theme = useTheme();

  const updateContacts = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const contact = selected[index];
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            addTag(selectedTags, contact)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }

      setReloadTags(true);

      if (setReloadContacts) {
        setReloadContacts(true);
      }

    }
    
    catch (error) {
      console.log(error)
    }
  };

  const addTag = async (tags, contact) => {
    const existingTagNames = contact.tags.map(x => x.name);
    const newTagNames = tags.map(x => x.name);
    // console.log('existingTagNames', existingTagNames);
    const combinedTags = [ ...new Set([ ...existingTagNames, ...newTagNames ]) ];
    // console.log('combinedTags', combinedTags);
  
    const contactObj = {
      contactId: contact.id,
      payload: { ...contact, tags: combinedTags}
    }

    // console.log('contactObj', contactObj);
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully added new tag to contacts', updatedContact.data)
    }

  }


  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      const newTags = selectedTags.filter(x => x.id != tag.id);
      setSelectedTags(newTags);
    } else {
      const newTags = [ ...selectedTags, tag ];
      setSelectedTags(newTags);
    }
  }


  return (
    <ActionContent
      title="Add Tag"
      progress={progress}
      cancel={handleCancel}
      confirm={updateContacts}
      confirmCTA="Add Tags"
      disableConfirm={!tags?.length || !selectedTags?.length}
      completedMessage="Finished Adding Tags"
    >
      <Grid
        container
        style={{
          gap:8,
          justifyContent:'center'
        }}
      >
        {
          tags.length
            ?
              tags.map(tag => (
                <a
                  onClick={() => handleTagClick(tag)}
                  style={{
                    cursor:'pointer'
                  }}
                >
                  <Grid
                    style={{
                      outline: selectedTags.includes(tag) ? `1px solid ${theme.palette.primary.main}` : 'none',
                      background: selectedTags.includes(tag) ? `${theme.palette.primary.light}20` : 'none',
                      padding:2,
                      borderRadius:20,
                      display:'flex'
                    }}
                  >
                    <CheckSVG width={15} color1={theme.palette.primary.main} style={{padding:'0 4px', width:selectedTags.includes(tag) ? 15 : 0, transform:selectedTags.includes(tag) ? `scale(1)` : `scale(0)`, transition:'.4s'}} />
                    <Tag
                      tag={tag}
                    />
                  </Grid>
                </a>
              ))
            :
              <Typography
                style={{
                  margin:'36px 0'
                }}
              >
                You have not created any tags yet.
              </Typography>

        }
      </Grid>
    </ActionContent>
  )
}

const BulkLists = (props) => {
  const { selected, lists, setReloadContacts, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedLists, setSelectedLists] = useState([]);
  const theme = useTheme();

  const updateContacts = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const contact = selected[index];
        
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            addList(selectedLists, contact)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadContacts) {
        setReloadContacts(true);
      }

    }
    
    catch (error) {
      console.log(error)
    }
  };

  const addList = async (lists, contact) => {
    const existingListNames = contact.mailing_lists.map(x => ({id: x.id}));
    const newListNames = lists.map(x => ({id: x.id}));
    // console.log('existingListNames', existingListNames);
    // Merge arrays and remove duplicates based on the 'id' property
    const combinedListsSet = new Set([...existingListNames, ...newListNames].map(JSON.stringify));
    // console.log('combinedListsSet', combinedListsSet);
    const combinedLists = Array.from(combinedListsSet).map(JSON.parse);
    // console.log('combinedLists', combinedLists);
  
    const existingTags = [ ...contact.tags ];
    const existingTagNames = existingTags.map(x => x.name);
  
    const contactObj = {
      contactId: contact.id,
      payload: {
        ...contact,
        mailing_lists: combinedLists,
        tags: existingTagNames,
      }
    }

    // console.log('contactObj', contactObj);
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully added new tag to contacts', updatedContact.data)
    }

  }

  const handleListClick = (list) => {
    if (selectedLists.includes(list)) {
      const newLists = selectedLists.filter(x => x.id != list.id);
      setSelectedLists(newLists);
    } else {
      const newLists = [ ...selectedLists, list ];
      setSelectedLists(newLists);
    }
  }

  return (
    <ActionContent
      title="Add To Mailing List"
      progress={progress}
      cancel={handleCancel}
      confirm={updateContacts}
      confirmCTA="Add To Mailing Lists"
      disableConfirm={!selectedLists?.length}
      completedMessage="Finished Adding Contacts To Mailing Lists"
    >
      <Grid
        container
        style={{
          justifyContent:'center'
        }}
      >
        {
          lists.length
            ?
              lists.map((list, index) => {
                const selected = selectedLists.map(x => x.id ? x.id : x).includes(list.id);
                return (
                  <Grid
                    container
                    // onMouseEnter={() => setHover(`list-${list.id}`)}
                    // onMouseLeave={() => setHover(null)}
                    onClick={() => handleListClick(list)}
                    style={{
                      background: selected ? theme.palette.primary.main : `${theme.palette.primary.light}15`,
                      padding: selected ? '4px 8px' : '4px 8px',
                      margin:4,
                      borderRadius: selected ? 16 : 8,
                      transition:'border-radius .4s, padding .4s',
                      // justifyContent:'space-between',
                      cursor:'pointer'
                    }}
                  >
                    <div style={{width: selected ? 35 : 10, transition:'.25s', display:'flex', alignItems:'center', justifyContent:'center'}}>
                      <CheckSVG width={15} color1="#fff" style={{transform:selected ? 'scale(1)' : 'scale(0)', transition:'.5s'}} />
                    </div>
                    <Typography
                      style={{
                        color: selected ? '#ffffff' : 'inherit'
                      }}
                    >
                      {list.name}
                    </Typography>
                    </Grid>
                )
              })
            :
              <Typography
                style={{
                  margin:'36px 0'
                }}
              >
                There are no mailings lists yet.
              </Typography>

        }
      </Grid>

    </ActionContent>
  )
}

const BulkNames = (props) => {
  const { selected, customFields, setReloadContacts, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [replaceExisting, setReplaceExisting] = useState(false);
  const theme = useTheme();

  const updateContacts = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const contact = selected[index];
        
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            createNameFromFields(contact)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadContacts) {
        setReloadContacts(true);
      }

    }
    
    catch (error) {
      console.log(error)
    }
  };

  // CREATE USER NAME FROM CUSTOM FIELDS
  const createNameFromFields = async (contact) => {
    let fullName = generateContactName(contact.custom_fields, customFields);
    
    if (contact.name) {
      fullName = contact.name
    };

    const contactObj = {
      contactId: contact.id,
      payload: { ...contact, name: fullName }
    }

    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully updated full name', updatedContact.data)
    }
  }
  


  return (
    <ActionContent
      title="Generate full contact name"
      progress={progress}
      cancel={handleCancel}
      confirm={updateContacts}
      confirmCTA="Generate Name"
      completedMessage="Finished Finding Full Contact Names"
    >
      <Grid
        container
        style={{
          justifyContent:'center'
        }}
      >
        <Typography

        >
          The following contacts will have their full name added to their contact profile. The name will be created from existing fields you have uploaded, if the exist. Are you ready?
        </Typography>
        <Grid container>
          <Grid item xs="auto">
            <Checkbox checked={replaceExisting} onChange={(e) => setReplaceExisting(e.target.checked)} />
          </Grid>
          <Grid item xs>
            <Typography style={{paddingTop:8}}>
              <><span style={{fontWeight:600}}>Replace existing profile names.</span> We recommend leaving this off to prevent accidentally overwriting existing names.</>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className="fill-scrollbar"
          style={{
            marginTop:16,
            maxHeight:'60vh',
            overflowY:'auto',
          }}
        >
          <Grid
            container
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
              >
                {`Name (preview)`}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
              >
                Contact Email
              </Typography>
            </Grid>
            <Divider style={{width:'100%', margin:'8px 0'}} />
          </Grid>
          {
            selected.map(contact => (
              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {generateContactName(contact.custom_fields, contact.name, customFields, replaceExisting)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {contact.primary_email}
                  </Typography>
                </Grid>
                <Divider style={{width:'100%', margin:'8px 0'}} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>

    </ActionContent>
  )
}

const BulkDelete = (props) => {
  const { selected, setReloadContacts, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const theme = useTheme();

  const updateContacts = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const contact = selected[index];
        
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            removeContact(contact)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadContacts) {
        setReloadContacts(true);
      }

    }
    
    catch (error) {
      console.log(error)
    }
  };
  
  // This is a duplicate of the removeContact function in the main ContactsCRMDisplayGrid component
  const removeContact = async (contact) => {
    const deletedContact = await deleteContact(contact.id);
    if (deletedContact.status === 'success') {
      console.log('successfully deleted the contact(s)', deletedContact.data)
    }

  }

  return (
    <ActionContent
      title="Delete contacts"
      progress={progress}
      cancel={handleCancel}
      confirm={updateContacts}
      confirmCTA="Delete Contacts"
      completedMessage="Finished Deleting Contacts"
    >
      <Grid
        container
        style={{
          justifyContent:'center'
        }}
      >
        <Typography

        >
          The following contacts will be permanently deleted. Are you sure?
        </Typography>
        <Grid
          container
          style={{
            marginTop:16,
            maxHeight:'70vh',
            overflowY:'auto'
          }}
        >
          {
            selected.map(contact => (
              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {contact.name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {contact.primary_email}
                  </Typography>
                </Grid>
                <Divider style={{width:'100%', margin:'8px 0'}} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>

    </ActionContent>
  )
}

const ActionButton = (props) => {
  const { label, icon, color, setAction } = props;

  return (
    <CustomTooltip
      title={label}
    >
      <IconButton
        onClick={setAction}
        style={{
          width:34,
          height:34
        }}
      >
        {
          cloneElement(icon, {color1: color})
        }
      </IconButton>
    </CustomTooltip>
  )
}

const ActionContent = (props) => {
  const { children, progress, title, cancel, confirm, confirmCTA, completedMessage, disableConfirm } = props;

  return (
    <Grid container>
      <Grid container padding={3}>
        <Typography
          style={{
            fontSize:'1.3rem',
            width:'100%',
            textAlign:'center'
          }}
        >
          {title}
        </Typography>
        
        {
          progress < 100 &&
            <Grid container style={{margin:'16px 0'}}>
              {children}
            </Grid>
        }

        {
          progress < 100 && progress > 0
            ?
              <>
                <LinearProgress variant="determinate" value={progress} style={{width:'100%', height:10, borderRadius:20}} />
                <Typography
                  color="primary"
                  style={{
                    textAlign:'center',
                    width:'100%',
                    fontWeight:600,
                  }}
                >
                  {Math.floor(progress)}%
                </Typography>
              </>
            :
              progress > 0
                ?
                  <Typography
                    color="primary"
                    style={{
                      textAlign:'center',
                      width:'100%',
                      fontWeight:600,
                      margin:'36px 0'
                    }}
                  >
                    {completedMessage}
                  </Typography>
                : null

        }
      </Grid>
      <Divider style={{width:'100%', margin:'0px 0 0 0'}} />
      <Grid
        container
        style={{
          justifyContent:'space-between',
          alignItems:'center',
          padding:8
        }}
      >
        <Button
          onClick={cancel}
        >
          {progress < 100 ? 'Cancel' : 'Close'}
        </Button>
        {
          progress < 100
            ?
              <Button
                disabled={disableConfirm}
                onClick={confirm}
              >
                {confirmCTA}
              </Button>
            :
              null
        }

      </Grid>
    </Grid>

  )
}

const FilterMenu = (props) => {
  const { filterBy, setFilterBy, customFields, keys, keysWithFilter} = props;
  // console.log('filterBy', filterBy)
  const { name, email, status, tags, mailing_lists, field, search } = filterBy;
  const [filter, setFilter] = useState(filterBy);
  const [addFilter, setAddFilter] = useState(false);
  const theme = useTheme();

  // useEffect(() => {
  //   if (filter) {
  //     setFilterBy(filter)
  //   }
    
  // }, [filter])

  // const keys = Object.keys(filterBy);

  // function filterKeysWithValues(obj) {
  //   const keysWithValues = Object.keys(obj).filter(key => {
  //     const value = obj[key];
  //     if (Array.isArray(value)) {
  //       return value.length > 0;
  //     }
  //     return value !== null && value !== undefined;
  //   });
  
  //   return keysWithValues;
  // }

  // const keysWithFilter = filterKeysWithValues(filterBy);
  // console.log('keysWIthFIlter', keysWithFilter)

  const clearFilters = () => {
    setFilterBy({
      name:null,
      email:null,
      status:null,
      tags:null,
      page:1,
      page_size:10,
      mailing_lists: null,
      fields: [],
      search:null  
    })
  }

  // Currently onSubmit is not necessary because filterBy is automatically updated on change
  const onSubmit = () => {
    setFilterBy(filter);
  }

  return (
    <Grid
      container
      style={{
        rowGap:8,
        padding:16,
        borderRadius:8,
        minWidth:350,
        maxWidth:720
      }}
    >
      <Grid
        container
        style={{
          justifyContent:'space-between'
        }}
      >
        <Typography>Filter</Typography>
        {
          keysWithFilter.length
            ?
              <Button
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            :
              null
        }
      </Grid>
      <Divider style={{width:'100%'}} />
      {
        keysWithFilter.map(key => (
          key === 'fields'
            ?
              <FilterFieldItem
                filter={key}
                keys={keys}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                customFields={customFields}
              />
            :
              <FilterItem
                filter={key}
                keys={keys}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
              />
        ))
      }
      {
        addFilter
          ?
            <FilterItem
              keys={keys}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              setAddFilter={setAddFilter}
            />
          :
            <Grid container style={{justifyContent:'space-between'}}>
              <Button
                variant="outlined"
                onClick={() => setAddFilter(true)}
                startIcon={<AddSVG width={10} color1={theme.palette.primary.main} />}
              >
                Add Filter
              </Button>
              {/* <Button
                variant="contained"
                // color='light'
                onClick={() => setAddFilter(true)}
                startIcon={<FilterSVG width={14} color1="#fff" />}
              >
                Filter Contacts
              </Button> */}
            </Grid>
      }
    </Grid>
  )
}

const FilterItem = (props) => {
  const { filter, keys, filterBy, setFilterBy, setAddFilter } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        background:'#eee',
        padding:8,
        borderRadius:8,
        alignItems:'center'
      }}
    >
      <Grid
        item
        // order={{xs:0, sm:0}}
        style={{
          padding:'0 8px',
          minWidth:115
          // minWidth:filter ? 115 : '100%'
        }}
        xs
        sm={filter ? "auto" : true}
      >
        {
          filter
            ?
              <Typography>{filter}</Typography>
            :
              <SelectField
                value="Select"
                placeholder="Select"
                onChange={(e) => {
                  setFilterBy(prev => (
                    { 
                      ...prev, 
                      [e.target.value]: e.target.value === 'fields'
                        ? [ ...prev.fields, {name:null, value:null}] // Set empty object to trigger an "active" fields object
                        : " " 
                    }
                  ));
                  setAddFilter(false);
                }}
                options={keys.map(key => ({key: key, value: key}))}
              />
        }
      </Grid>

      <Grid
        item
        order={{xs:3, sm:2}}
        style={{
          padding:'0 8px'
        }}
        xs={12}
        sm
      >
        {
          filter &&
            <InputField
              placeholder="Must enter full value"
              value={filterBy[filter]}
              onChange={(e) => setFilterBy(prev => ({ ...prev, [filter]: e.target.value, page:1 }) )}
            />
        }
      </Grid>

      <Grid
        item
        order={{xs:1, sm:2}}
        style={{
          width:50
        }}
      >
        <IconButton
          onClick={() => {
            if (filter) {
              if (filter === "fields") {
                setFilterBy(prev => ({ ...prev, [filter]: [] }) )
              } else {
                setFilterBy(prev => ({ ...prev, [filter]: null }) )
              }
            } else {
              setAddFilter(false)
            }
          }}
        >
          <CloseFilledSVG width={20} color1="#ccc" /> 
        </IconButton>
      </Grid>
    </Grid>
  )
}

const FilterFieldItem = (props) => {
  const { filter, keys, filterBy, setFilterBy, setAddFilter, customFields } = props;
  const theme = useTheme();
  // console.log('filter fields', filterBy.fields);
  // console.log('filter', filter);

  function arrayToObject(arr) {
    return arr.reduce((obj, item) => {
      obj[item.field_name] = item.id;
      return obj;
    }, {});
  }
  
  const allFieldKeys = customFields?.map(field => field.field_name);
  const usedFieldKeys = filterBy.fields.map(x => x.name);
  const customFieldKeys = allFieldKeys.filter(key => !usedFieldKeys.includes(key));
  const allFieldIdMaps = arrayToObject(customFields);
  // console.log('allFieldIdMaps', allFieldIdMaps);

  return (
    <Grid
      container
      style={{
        background:'#eee',
        padding:8,
        borderRadius:8,
        alignItems:'center'
      }}
    >
      <Grid
        container
        style={{
          justifyContent:'space-between',
          alignItems:'center'
        }}
      >
        <Grid
          item
          order={{xs:0, sm:0}}
          style={{
            padding:'0 8px',
            minWidth:115
            // minWidth:filter ? 115 : '100%'
          }}
          xs
          sm={filter ? "auto" : true}
        >
          {
            filter
              ?
                <Typography>{filter}</Typography>
              :
                null
          }
        </Grid>

        <Grid
          item
          order={{xs:3, sm:2}}
          style={{
            padding:'0 8px'
          }}
          xs={12}
          sm
        >
          <SelectField
            value="Select"
            placeholder="Select"
            trimValue="Select A Field"
            // helperText="Select A Field"
            // FormHelperTextProps={{
            //   style:{
            //     background:'#ffffff',
            //     margin:0,
            //     padding:'0 8px'
            //   }
            // }}
            onChange={(e) => {
              setFilterBy(prev => {
                const removedEmpty = prev.fields.filter(x => x.name);
                const updatedFields = [ 
                  ...removedEmpty,
                  {
                    name: e.target.value,
                    id:allFieldIdMaps[e.target.value],
                    value:" "
                  }
                ]
                return ({ 
                  ...prev,
                  fields: updatedFields
                })
              });
              // setAddFilter(false);
            }}
            options={customFieldKeys.map(cfk => ({key: cfk, value: cfk}))}
          />
        </Grid>

        <Grid
          item
          order={{xs:2, sm:3}}
          style={{
            width:50
          }}
        >
          <IconButton
            onClick={() => {
              if (filter) {
                if (filter === "fields") {
                  setFilterBy(prev => ({ ...prev, [filter]: [] }) )
                } else {
                  setFilterBy(prev => ({ ...prev, [filter]: null }) )
                }
              } else {
                setAddFilter(false)
              }
            }}
          >
            <CloseFilledSVG width={20} color1="#ccc" /> 
          </IconButton>
        </Grid>

      </Grid>

      {
        filterBy.fields.length
          ?
            <Grid
              item
              // order={{xs:3, sm:2}}
              style={{
                padding:'0 8px'
              }}
              xs={12}
              sm
            >
              {
                filterBy.fields?.map((field, index) => (
                  <Grid
                    id={field.name}
                    container
                    style={{
                      // margin:'8px 0',
                      alignItems:'center',
                      justifyContent:'space-between'
                    }}
                  >
                    <Divider style={{width:'100%', margin:'8px 0'}} />
                    <Grid
                      item
                      order={{xs:3, sm:2}}
                      style={{
                        padding:'0 8px'
                      }}
                      xs={12}
                      sm
                    >
                      {
                        field.name && <Typography>{field.name}</Typography>
                      }

                    </Grid>
                    {
                      field.value &&
                        <>
                          <Grid
                            item
                            order={{xs:3, sm:2}}
                            xs={12}
                            sm={6}
                          >
                            <InputField
                              value={field.value}
                              onChange={(e) => {
                                const copied = [ ...filterBy.fields ];
                                const fieldId = copied.findIndex(x => x.id === field.id);
                                copied[fieldId] = { ...copied[fieldId], value: e.target.value.trim() };
                                const removedEmpty = copied.filter(x => x.value.length);
                                const updatedFields = removedEmpty.length ? removedEmpty : [{name:null, value:''}];
                                setFilterBy(prev => ({ ...prev, fields: updatedFields }))
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            order={{xs:2, sm:3}}
                            style={{
                              width:50,
                              padding:'0 8px'
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                const updatedFields = filterBy.fields.filter(x => x.name !== field.name);
                                setFilterBy(prev => ({ ...prev, fields: updatedFields }) )
                              }}
                            >
                              <CloseFilledSVG width={20} color1="#ccc" /> 
                            </IconButton>
                          </Grid>
                        </>
                      }

                  </Grid>
                ))
              }
            </Grid>
          :
            <Typography>No fields yet</Typography>
      }
      
    </Grid>
  )
}

const ActionsCell = (props) => {
  const { contact, setContactToDelete, setOpenConfirmDelete } = props;
  return (
    <Stack
      direction="row"
      spacing={.5}
      justifyContent="flex-end"
    >
      <CustomTooltip
        title="View Contact"
      >
        <IconButton
          href={ `/contacts/${contact.id}`}
        >
          <ContactSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete contact"
      >
        <IconButton
          onClick={() => {
            setContactToDelete(contact);
            setOpenConfirmDelete(true);
          }}
        >
          <DeleteSVG height={18} style={{width:20}} />
        </IconButton>
      </CustomTooltip>
    </Stack>

  )
}

// const ToolbarButtons = (props) => {
//   const {
//     contacts,
//     setSortedContacts,
//     filterBy,
//     setFilterBy,
//     activeFieldLayout,
//   }
//   const theme = useTheme();

//   return (
//   <Grid
//     style={{
//       display:'flex',
//       alignItems:'center', 
//       gap:8
//     }}
//   >
//     <ReusableContactSearch
//       useSearch
//       content={contacts}
//       setContent={setSortedContacts}
//       filterBy={filterBy}
//       setFilterBy={setFilterBy}
//       placeholder="Search for name or email"
//       searchFields={['name', 'primary_email']}
//     />

//     <ReusableModal tooltip="Select which columns to display">
//       <IconButton
        
//       >
//         <SelectSVG width={15} color1={theme.palette.primary.main} style={{transform:'rotate(-90deg)'}} />
//       </IconButton>

//       <Grid
//         style={{
//           padding:24
//         }}
//       >
//         <Typography
//           style={{
//             fontSize:'1.5rem',
//             textAlign:'center'
//           }}
//         >
//           Display Columns
//         </Typography>
//         {
//           activeFieldLayout &&
//             <Typography
//               style={{
//                 fontSize:'1rem',
//                 textAlign:'center'
//               }}
//             >
//               Using {activeFieldLayout.label} fields layout
//             </Typography>
//         }
//         <Button
//           onClick={() => setDisplayColumns(initHeaderColumns)}
//         >
//           Remove Custom Fields
//         </Button>
//         <Button
//           onClick={() => setDisplayColumns(headerColumns)}
//         >
//           Use All Fields
//         </Button>
        
//         {
//           headerColumns.map(col => { 
//             if (col.type === 'large_text_field') return;  // Skip rich text field types
//             return (
//               <Grid
//                 key={col.field}
//                 variant="space-between"
//                 onClick={() => handleColumnDisplay(col)}
//                 style={{
//                   background: displayColumns.some(column => column.field === col.field) ? theme.palette.primary.light : '#eee',
//                   padding:'4px 8px 4px 16px',
//                   margin:'4px 0',
//                   borderRadius:32,
//                   cursor:'pointer'
//                 }}
//               >
//                 <Typography>
//                   {col.label}
//                 </Typography>
//                 <Typography style={{fontSize:'.85rem', textTransform:'uppercase', background:'#ffffffcc', borderRadius:16, lineHeight:1, padding:'2px 8px'}}>
//                   {translateFieldType(col.type ? col.type : 'required')}
//                 </Typography>
//               </Grid>
//           )})
//         }
//         <Typography
//           textAlign="center"
//         >
//           Rich text cannot be displayed in the list view
//         </Typography>
//       </Grid>
//     </ReusableModal>

//     <CustomTooltip
//       title="Filter contacts"
//     >
//       <IconButton
//         onClick={handleClickFilter}
//       >
//         <FilterSVG width={15} color1={theme.palette.primary.main} />
//       </IconButton>
//     </CustomTooltip>

//     <CustomTooltip
//       title="Import contacts from csv"
//     >
//       <IconButton
//         href="/contacts/api/import/csv/"
//       >
//         <UploadSVG height={16} color1={theme.palette.primary.main} />
//       </IconButton>
//     </CustomTooltip>

//     <CustomTooltip
//       title="Create contact"
//     >
//       <IconButton
//         href="/contacts/add/"
//         variant="outlined"
//       >
//         <AddRoundedSVG width={15} color1={theme.palette.primary.main} />
//       </IconButton>
//     </CustomTooltip>

//     <Menu
//       id="basic-menu"
//       anchorEl={anchorElFilter}
//       open={openFilter}
//       onClose={handleCloseFilter}
//       MenuListProps={{
//         'aria-labelledby': 'basic-button',
//         padding:0,
//       }}
//     >
//       <FilterMenu
//         filterBy={filterBy}
//         setFilterBy={setFilterBy}
//         customFields={customFields}
//         keys={keys}
//         keysWithFilter={keysWithFilter}
//       />
//     </Menu>

//     {
//       selected.length
//         ?
//           <ReusableModal>
//             <Button
//               variant='contained'
//               style={{
//                 lineHeight:1,
//                 padding:'10px 16px'
//               }}
//             >
//               Bulk Actions
//             </Button>

//             <Grid
//               style={{
//                 padding:24
//               }}
//             >
//               <Typography
//                 variant="h5"
//                 textAlign="center"
//               >
//                 Bulk Actions
//               </Typography>

//               <Grid
//                 container
//               >
//                 <BulkActions
//                   selected={selected}
//                   tags={tags}
//                   lists={lists}
//                   customFields={customFields}
//                   setReloadContacts={setReloadContacts}
//                   setReloadTags={setReloadTags}
//                 />
//               </Grid>
//             </Grid>
//           </ReusableModal>
//         : null
//     }


//   </Grid>

//   )
// }
