import React from 'react'
import { Button, Grid, Typography } from '@mui/material'

// This component is to alert the user that the still need to set up the sender details before the can:
// 1. Send a campaign

const SenderDetailsAlert = () => {
  return (
    <Grid
      container
      sx={{
        flexDirection:'column',
        alignItems:'center'
      }}
    >
      <Typography>
        You must set up your account to send emails before you can run a campaign.
      </Typography>
      <Button
        variant="contained"
        href="/accounts/sender-details/"
        sx={{
          mt:2
        }}
      >
        Set up sender details
      </Button>
    </Grid>
  )
}

export default SenderDetailsAlert