import React, { useEffect } from 'react'
import { Alignment, Fit, Layout, useRive, useStateMachineInput } from '@rive-app/react-canvas'

const RiveRender = (props) => {
  const { artboard, state, style, restart } = props;
  const { rive, RiveComponent } = useRive({
    src: process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/rive/window_graphics.riv` : `https://static.fillmarketing.com/static/static/rive/window_graphics.riv`,
    artboard: artboard,
    stateMachines: state,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center
    }),
  });

  let active = useStateMachineInput(rive, state, 'active');

  useEffect(() => {
    if (active && restart) {
      const interval = setInterval(() => {
        handleActive();
        console.log('Active state set to true');
      }, restart); // 10000 milliseconds = 10 seconds
  
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [active]);



  const handleActive = () => {
    active.value = !active.value;
   }
  
  return (
    <div
      style={{
        width:'100%',
        aspectRatio:1,
        ...style
      }}
    >
      <RiveComponent
        // onMouseEnter={() => rive && rive.play()}
        // onMouseLeave={() => rive && rive.pause()}
      />
    </div>
  )
}

export default RiveRender