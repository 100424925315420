import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import TagsList from '../components/tags/TagsList'
import { useGetTags } from '../hooks/mutations';

const TagsListPage = () => {

  return (
    // <MainPageTemplate title="Welcome">
      <TagsList />
    // </MainPageTemplate>
  )
}

export default TagsListPage