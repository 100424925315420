import React from 'react';
import './Loading.css';

const Loading = (props) => {
  const { color, minHeight } = props;

  return (
    <div className="wrapper" style={{ minHeight: `${minHeight}px` || '' }}>
      <div className="container">
      <div className="dot" style={{ backgroundColor: color || '' }}></div>
        <div className="dot" style={{ backgroundColor: `${color}dd` || '' }}></div>
        <div className="dot" style={{ backgroundColor: `${color}aa` || '' }}></div>
        <div className="dot" style={{ backgroundColor: `${color}70` || '' }}></div>
      </div>
    </div>
  )
}

export default Loading