import React, { useState, useEffect } from 'react'
import {  Button, CircularProgress, Divider, Grid, IconButton, Table, TableCell, TableRow, Typography } from '@mui/material';
import { initSenderDetail } from '../../lib/Data';
import { InputField } from '../ui/Fields';
import { postSenderDetails, postSenderVerifyDomain, putSenderDetails } from '../../hooks/mutations';
import { CheckmarkFilledSVG, RemoveFillSVG, UpCaretSVG, WarningSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import ReusableModalTooltip from '../ui/ReusableModalTooltip';
import { theme } from '../../theme';
import { DjangoComponents } from '../../templates/DjangoStyles';
import Loading from '../ui/Loading';

// STAGES
// STEP 1: Domain name
// STEP 2: Find DNS records
// STEP 3: Update DNS records & Verify domain 
// STEP 4: Fill out From email, From name, and website 

const SenderDetailsWizard = (props) => {
  const { existingSenderDetail, setReload } = props;
  const [senderDetail, setSenderDetail] = useState(initSenderDetail);
  const [step, setStep] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);

  console.log('existingSenderDetail', existingSenderDetail)


  // Update senderDetail if an existing detail already exists
  useEffect(() => {
    if (existingSenderDetail) {
      setSenderDetail(existingSenderDetail);
      determineStep();
    } else {
      determineStep();
    }
  }, [existingSenderDetail]);

  const determineFoundRecords = () => {

  }

  const determineStep = () => {
    // STEP 1 check: !domain_name
    if (!existingSenderDetail) {
      setStep(1);
      return;
    }

    if (!existingSenderDetail.domain_name) {
      setStep(1);
      return;
    }

    // STEP 2 check: !is_validated    
    if (!existingSenderDetail.is_validated) {
      setStep(2);
      return;
    }

    // STEP 3 check: is_validated
    if (existingSenderDetail.is_validated) {
      setStep(3);
      return;
    }

  }

  // API CALLS
  const submitNewDetails = async () => {
    console.log('POST')
    const submitted = await postSenderDetails(senderDetail);
    if (submitted.status === "success") {
      setSnackMessage({ open: true, message: 'Domain details submitted', severity: 'success' });
      setReload(true);
    } else if ( submitted.status === "error") {
      setSnackMessage({open: true, message: submitted.error, severity:'error'});
    }
  }

  const submitUpdatedDetails = async () => {
    console.log('PUT')
    const submitted = await putSenderDetails({ id: existingSenderDetail.id, data: { ...existingSenderDetail, ...senderDetail } });
    if (submitted.status === "success") {
      setSnackMessage({ open: true, message: 'Domain details updated', severity: 'success' });
      setReload(true);
    } else if ( submitted.status === "error") {
      setSnackMessage({open: true, message: submitted.error, severity:'error'});
    }
  }

  const submitVerifyDomain = async () => {
    if (senderDetail.id) {
      setIsVerifying(true);
      const verifiedDomain = await postSenderVerifyDomain(senderDetail.id);
      console.log('verfiedDomain', verifiedDomain);
      if (verifiedDomain.status === "success") {
        if (verifiedDomain.data.is_validated) {
          setSnackMessage({open: true, message: 'Domain has been verified', severity:'success'});
          setReload(true);
        } else {
          setSnackMessage({open: true, message: 'Domain could not be verified', severity:'error'});
        }
        setIsVerifying(false);
      } else if (verifiedDomain.status === "error") {
        setSnackMessage({open: true, message: verifiedDomain.error, severity:'error'});
        setIsVerifying(false);
      }
    }
  }


  return (
    <Grid
      container
      style={{
        padding:16
      }}
    >
      <StepDisplay
        step={step}
        setStep={setStep}
        senderDetail={senderDetail}
      />
      {
        step === 1 &&
          <SenderDetailsStep1
            senderDetail={senderDetail}
            setSenderDetail={setSenderDetail}
            submitNewDetails={submitNewDetails}
            submitUpdatedDetails={submitUpdatedDetails}
            existingSenderDetail={existingSenderDetail}
            determineStep={determineStep}
          />
      }
      {
        step === 2 &&
          <SenderDetailsStep2
            submitVerifyDomain={submitVerifyDomain}
            senderDetail={senderDetail}
            setSenderDetail={setSenderDetail}
            isVerifying={isVerifying}
          />
      }
      {
        step === 3 &&
          <SenderDetailsStep3
            senderDetail={senderDetail}
            setSenderDetail={setSenderDetail}
            submitUpdatedDetails={submitUpdatedDetails}
          />
      }

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </Grid>
  )
}

export default SenderDetailsWizard

const SenderDetailsStep1 = (props) => {
  const { senderDetail, setSenderDetail, submitNewDetails, submitUpdatedDetails, existingSenderDetail, determineStep } = props;
  const unchanged = senderDetail?.domain_name === existingSenderDetail?.domain_name;

  return (
    <StepWrapper title="Domain Name">
      <InputField
        label="Domain Name"
        placeholder="Enter the domain name"
        fullWidth
        value={senderDetail.domain_name}
        onChange={(e) => setSenderDetail({ ...senderDetail, domain_name: e.target.value }) }
        margin
      />
      <Grid
        container
        style={{
          justifyContent: unchanged ? 'flex-end' : 'flex-start'
        }}
      >
        {
          unchanged
            ?
              <Button
                onClick={determineStep}
                variant="contained"
              >
                Continue
              </Button>
            :
              existingSenderDetail?.domain_name
                ?
                  <Button
                    onClick={submitUpdatedDetails}
                    variant="contained"
                  >
                    Update Domain Name
                  </Button>
                :
                  <Button
                    onClick={submitNewDetails}
                    variant="contained"
                  >
                    Submit Domain Name
                  </Button>
        }
      </Grid>

    </StepWrapper>
  )
}

const SenderDetailsStep2 = (props) => {
  const { senderDetail, setSenderDetail, submitVerifyDomain, isVerifying } = props;

  return (
    <StepWrapper title={senderDetail.is_validated ? "DNS CNAME RECORDS" : "Add DNS CNAME Records"}>
      <Grid
        style={{
          maxWidth:800
        }}
      >
        {
          senderDetail.is_validated
            ?
              <DNSDetails senderDetail={senderDetail} />
            :
              <>
                {
                  updateDNS.map((item, index) => (
                    <Grid
                      container
                      key={item.label}
                      style={{
                        position:'relative',
                        boxShadow:'0 0 5px #00000030',
                        padding:8,
                        marginBottom:24,
                        marginTop: index === 0 ? 16 : 0
                      }}
                    >
                      {console.log('item', item)}
                      <Typography
                        style={{
                          position:'absolute',
                          top:-12,
                          left:-5,
                          fontSize:'.75rem',
                          fontWeight:600,
                          lineHeight:1,
                          textTransform:'uppercase',
                          background:'black',
                          color:'#fff',
                          padding:'4px 12px',
                          borderRadius:24
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight:600
                        }}
                      >
                        {item.instruction}
                      </Typography>
                        {item.example}

                        {/* {item.label === "Step 4" && <DNSDetails senderDetail={senderDetail} />} */}
                        {item.label === "Step 4" &&
                          <Grid container variant="center">
                            <DNSDetails senderDetail={senderDetail} />
                            <Button
                              disabled={isVerifying}
                              variant="contained"
                              onClick={submitVerifyDomain}
                              style={{
                                marginTop:8
                              }}
                            >
                              {
                                isVerifying
                                  ?
                                    <><CircularProgress size={16} thickness={6} style={{marginRight:8}} /> 'Verifying'</>
                                  :
                                    'Verify Domain'
                              }
                            </Button>
                            {
                              !senderDetail.is_validated &&
                                <Grid container variant="center" style={{marginBottom:16}}>
                                  <WarningSVG width={20} color1={theme.palette.warning.main} />
                                  <Typography
                                    style={{
                                      marginLeft:8,
                                      fontWeight:600
                                    }}
                                  >
                                    Your domain has not been verified
                                  </Typography>
                                </Grid>
                            }
                          </Grid>
                        }
                    </Grid>
                  ))
                }
            
                <Grid
                  container
                  variant="center"
                  style={{
                    margin:'24px 0',
                    flexDirection:'column'
                  }}
                >
                  <ReusableModalTooltip
                    tooltipTitle="DNS record help docs from popular domain hosting platforms"
                    small
                  >
                    <Button style={{color:theme.palette.primary.main}}>DNS Record Update Help</Button>
                    <Grid><HelpLinks /></Grid>
                  </ReusableModalTooltip>

                </Grid>
              </>
        }
      </Grid>
    </StepWrapper>
  )
}

const SenderDetailsStep3 = (props) => {
  const { senderDetail, setSenderDetail, submitUpdatedDetails } = props;

  return (
    <StepWrapper title="Verify Your Domain">
      <InputField
        label="Domain Name"
        placeholder="Enter the domain name"
        fullWidth
        value={senderDetail.domain_name}
        onChange={(e) => setSenderDetail({ ...senderDetail, domain_name: e.target.value }) }
        margin
      />

      <InputField
        label="From Email Address"
        placeholder="Enter the default From email address"
        fullWidth
        value={senderDetail.default_from_email}
        onChange={(e) => setSenderDetail({ ...senderDetail, default_from_email: e.target.value }) }
        margin
      />

      <InputField
        label="From Email Name"
        placeholder="Enter the default name for your emails"
        fullWidth
        value={senderDetail.default_from_name}
        onChange={(e) => setSenderDetail({ ...senderDetail, default_from_name: e.target.value }) }
        margin
      />

      <InputField
        label="Website"
        placeholder="Enter the website url"
        fullWidth
        value={senderDetail.website_address}
        onChange={(e) => setSenderDetail({ ...senderDetail, website_address: e.target.value }) }
        margin
      />

      <Button
        onClick={submitUpdatedDetails}
        variant="contained"
      >
        Update Domain Details
      </Button>

    </StepWrapper>
  )
}

const SenderDetailsStep4 = () => {

  return (
    <StepWrapper>

    </StepWrapper>
  )
}

const StepWrapper = (props) => {
  const { children, title } = props;

  return (
    <Grid
      container
      variant="center"
    >
      <Grid
        item
        xs={11}
        lg={8}
        xl={6}
      >
        <DjangoComponents card padding>
        <Grid container
        >
          <Typography
            color="primary.main"
            style={{
              fontSize:'1.2rem',
              fontWeight:600
            }}
          >
            {title}
          </Typography>
          <Divider style={{width:'100%', marginBottom:16}} />
        </Grid>
        {children}
        </DjangoComponents>
      </Grid>
    </Grid>
  )
}

const StepDisplay = (props) => {
  const { step, setStep, senderDetail } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        justifyContent:'center',
        marginBottom:36
      }}
    >
      {
        [{id:1, label:'Domain Name'},{id:2, label:'DNS Records'},{id:3, label:'Sender Details'}].map(({ id, label }) => (
          <Grid
            item
            variant="center"
            flexDirection="column"
            xs
          >
            <Grid
              onClick={() => {
                if (id < step || senderDetail.is_validated) {
                  setStep(id)
                }
              }}
              variant="center"
              flexDirection="column"
              style={{
                cursor: id < step || senderDetail.is_validated ? 'pointer' : 'inherit'
              }}
            >
              {console.log('step: ', step, 'id: ', id)}
              {
                (id < step || senderDetail.is_validated)
                  ?
                      <CheckmarkFilledSVG width={25} color1={id < step || senderDetail.is_validated ? theme.palette.primary.main : '#ccc'} />
                  :
                    <Grid
                      style={{
                        width:25,
                        height:25,
                        borderRadius:25,
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        background: id <= step ? theme.palette.primary.main : '#bbb'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:'.75rem',
                          textTransform:'uppercase',
                          fontWeight:700,
                          color:'#fff'
                        }}
                      >
                        {id}
                      </Typography>
                    </Grid>
              }
              <Typography
                style={{
                  fontSize:'.85rem',
                  fontWeight:600,
                  textTransform:'uppercase',
                  color: id <= step ? theme.palette.primary.dark : '#bbb',
                  marginTop:8
                }}
              >
                {label}
              </Typography>
            </Grid>
          </Grid>
        ))
      }
    </Grid>

  )
}

const updateDNS = [
  {
    label: 'Step 1',
    instruction: 'Login to your domain hosting provider',
  },
  {
    label: 'Step 2',
    instruction: 'Find the domain you want to edit'
  },
  {
    label: 'Step 3',
    instruction: 'Navigate to the DNS records for the domain'
  },
  {
    label: 'Step 4',
    instruction: 'Add the following items to your DNS records and verify your domain'
  },
  // {
  //   label: 'Step 5',
  //   instruction: 'Click the Verify Domain button'
  // }
]

const helpLinks = [
  {
    provider: 'GoDaddy',
    link: 'https://www.godaddy.com/help/add-a-cname-record-19236'
  },
  {
    provider: 'Ionos',
    link: 'https://www.ionos.com/help/domains/configuring-cname-records-for-subdomains/configuring-a-cname-record-for-a-subdomain/'
  },
  {
    provider: 'Hostinger',
    link: 'https://support.hostinger.com/en/articles/1583249-how-to-manage-dns-records-at-hostinger'
  },
  {
    provider: 'Bluehost',
    link: 'https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries'
  },
  {
    provider: 'Namecheap',
    link: 'https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain/'
  },
  {
    provider: 'Hostgator',
    link: 'https://www.hostgator.com/help/article/manage-dns-records-with-hostgatorenom'
  },
  {
    provider: 'Dreamhost',
    link: 'https://help.dreamhost.com/hc/en-us/categories/360001957611-DNS'
  },
  {
    provider: 'Squarespace',
    link: 'https://support.squarespace.com/hc/en-us/articles/205812348-Accessing-your-Squarespace-managed-domain-s-DNS-settings'
  },
  {
    provider: 'Domain.com',
    link: 'https://www.domain.com/help/article/dns-management-how-to-update-cname-aliases'
  },
  {
    provider: 'Web.com',
    link: 'https://web.com/help/article/manage-dns-and-adns-records'
  },
]

const DNSDetails = (props) => {
  const { senderDetail } = props;

  return (
    <>
      {
        // !senderDetail.is_validated &&
        //   <Grid container variant="center" style={{marginBottom:16}}>
        //     <WarningSVG width={20} color1={theme.palette.warning.main} />
        //     <Typography
        //       style={{
        //         marginLeft:8,
        //         fontWeight:600
        //       }}
        //     >
        //       Your domain has not been verified
        //     </Typography>
        //   </Grid>
      }

      <Grid
        container
        style={{
          boxShadow:'0 0 5px #00000015',
          paddingTop:8,
          borderRadius:8,  
        }}
      >
        {
          senderDetail?.dns_details &&Object.keys(senderDetail.dns_details).length &&
            <DNSRecordItems
              mail_item={senderDetail.dns_details.mail_cname}
              dkim1_item={senderDetail.dns_details.dkim1}
              dkim2_item={senderDetail.dns_details.dkim2}
            />
        }
      </Grid>

    </>
  )
}

const HelpLinks = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        padding:'16px 36px'
      }}
    >
      <Typography
        style={{
          fontSize:'1.05rem',
          lineHeight:1.3
        }}
      >
        <span style={{fontWeight:600}}>View examples for updating DNS records from the popular domain hosting platforms below.</span> Don't see your hosting platform? The steps to update your DNS records are similar in all platforms. If none of the options below help, email us at support@fillmarketing.com. 
      </Typography>
      {/* <Typography
        style={{
          fontSize:'1rem',
          lineHeight:1.2
        }}
      >
        Don't see your hosting platform? The steps to update your DNS records are similar in all platforms. If none of the options below help, email us at support@fillmarketing.com. 
      </Typography> */}
      <Divider style={{width:'100%', margin:'16px 0'}} />
      {
        helpLinks.map(({provider, link}) => (
          <Grid
            item
            style={{
              display:'flex',
              alignItems:'center'
            }}
            xs={6}
          >
            <Typography>{provider}</Typography>
            <Button
              href={link}
              target="_blank"
              // variant="outlined"
              endIcon={<UpCaretSVG width={16} color1={theme.palette.primary.main} style={{transform:'rotate(90deg)'}} />}
            >
              Go
            </Button>
          </Grid>
        ))
      }
    </Grid>
  )
}

const DNSRecordItems = (props) => {
  const { mail_item, dkim1_item, dkim2_item } = props;

  return (
    <Table style={{width:'100%'}}>
      <TableRow>
        <TableCell>valid</TableCell>
        <TableCell>type</TableCell>
        <TableCell>host</TableCell>
        <TableCell>value</TableCell>
      </TableRow>
      <CustomRow item={mail_item} />
      <CustomRow item={dkim1_item} />
      <CustomRow item={dkim2_item} />
    </Table>
  )
}

const CustomRow = ({ item }) => {
  const { valid, type, host, data } = item;
  const theme = useTheme();

  return (
    <TableRow style={{overflow:'hidden', overflowWrap:'break-word'}}>
      <CustomCell>
        <Grid container>
          {
            valid
              ? <CheckmarkFilledSVG width={18} color1={theme.palette.success.main} />
              : <RemoveFillSVG width={18} color1={theme.palette.error.main} />
          }
        </Grid>
      </CustomCell>
      <CustomCell>{type}</CustomCell>
      <CustomCell>{host}</CustomCell>
      <CustomCell>{data}</CustomCell>
    </TableRow>

  )
}

const CustomCell = ({ children }) => {

  return (
    <TableCell
      style={{
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        wordBreak:'break-word',
        minWidth:50
      }}        
    >
        {children}
    </TableCell>
)}