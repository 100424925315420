
const LoadingScreen = () => {

  return (
    <div
      style={{
        background: 'green',
        minHeight: '100%'
      }}
    />
  );
};

export default LoadingScreen;
