import React from 'react'
import Step2Login from '../components/signup/Step2Login'

const SignupLoginPage = () => {


  
  return (
    // <MainPageTemplate title="Signup">
      <Step2Login />
    // </MainPageTemplate>
  )
}

export default SignupLoginPage