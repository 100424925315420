import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { theme } from '../../../../theme';
import { DeleteSVG } from '../../../../assets/icons';
import AnswerElement from './AnswerElement';
import { getRandomInts } from '../../../../lib/Functions';
import { InputField } from '../../../ui/Fields';
import FileDropZone from '../../../ui/FileDropZone';
import ImageUploadAnswerElement from './ImageUploadAnswerElement';
import ImageUploadQuestionElement from './ImageUploadQuestionElement';

const QuestionElement = (props) => {
  const { page, index, handlePageChange, handlePageAnswerChange, addAnswer, deleteAnswer, deleteQuestion } = props;

  const [showDescription, setShowDescription] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [requiresChoices, setRequiresChoices] = useState(false);
  const [requiresImages, setRequiresImages] = useState(false);
  const [images, setImages] = useState([]);

  const handleDescriptionChange = (e) => {
    handlePageChange('description', 0, index, e.target.value);
    setShowDescription(false);
  }

  const handleQuestionImageChange = (images) => {
    handlePageChange('images', 0, index, images);
    setShowDescription(false);
  }

  const handleAnswerImageChange = (images) => {
    handlePageChange('choices', 0, index, images);
    setShowDescription(false);
  }

  const checkIfRequires = () => {
    // Check if choices need to be displayed
    if (
      page.type !== 'radio' &&
      page.type !== 'select' &&
      page.type !== 'select-multiple' &&
      page.type !== 'checkbox'
    ) {
      setRequiresChoices(false);
    } else {
      setRequiresChoices(true);
    }

    // Check if image uploads need to be displayed
    if (
      page.type !== 'select-image'
    ) {
      setRequiresImages(false);
    } else {
      setRequiresImages(true);
    }
  }

  useEffect(() => {
    if (page) {
      checkIfRequires();
    }
  }, [page])

  return (
    page
      ?
        <Grid
          container
          className="QuestionElement"
          style={{
            flexDirection: 'column',
            background: '#ffffff',
            boxShadow: '0 0 10px #00000030',
            padding: 24,
            marginBottom: 24,
            borderRadius: 16
          }}
        >
          <Grid
            container
            style={{
              justifyContent:'space-between',
              marginBottom:8
            }}
          >
            <Grid display="flex">
              <Typography
                style={{
                  fontSize: '1.25rem'
                }}
              >
                Question {index + 1}
              </Typography>
              {!page.description &&
                <Button
                  onClick={() => 
                    setShowDescription(true)
                    // handlePageChange('description', 0, index, ' ')
                  }
                  style={{
                    padding: 0,
                    fontSize: '.9rem',
                    textTransform: 'capitalize',
                    marginLeft: 8
                  }}
                >
                  + Description
                </Button>
              }
              {/* {!page.images?.length &&
                <Button
                  onClick={() => 
                    setShowImage(true)
                    // handlePageChange('description', 0, index, ' ')
                  }
                  style={{
                    padding: 0,
                    fontSize: '.9rem',
                    textTransform: 'capitalize',
                    marginLeft: 8
                  }}
                >
                  + Image
                </Button>
              } */}
            </Grid>

            <DeleteSVG
              onClick={() => deleteQuestion(index)}
              width={15}
              color1={theme.palette.secondary.main} />
          </Grid>
          <InputField
            placeholder="Question"
            value={page.text}
            onChange={(e) => handlePageChange('text', 0, index, e.target.value)}
            multiline
            maxRows={4}
            marginAmount="8px 0"
          />

          {/* <TextField
            name="text"
            label="Question"
            multiline
            maxRows={4}
            InputProps={{
              style: {
                background: '#fff'
              }
            }}
            value={page.text}
            onChange={(e) => handlePageChange('text', 0, index, e.target.value)}
          /> */}

          {page.description || showDescription ?
            <InputField
              placeholder="Add more information"
              value={page.description}
              onChange={(e) => handleDescriptionChange(e)}
              multiline
              maxRows={5}
              marginAmount="8px 0"
            />

            : null
          }
          {/* {page.images?.length || showImage ?
            <Grid
              container
              variant="center"
              style={{
                marginTop:16,
                justifyContent:'center'
              }}
            >
              {
                page?.images?.map((image, imageIndex) => (
                  <ImageUploadQuestionElement
                    key={image.id}
                    image={image}
                    imageIndex={imageIndex}
                    images={page.images}
                    setImages={setImages}
                    handleQuestionImageChange={handleQuestionImageChange}
                  />
                ))
              }
              {
                showImage &&
                  <ImageUploadQuestionElement
                    images={page.images}
                    setImages={setImages}
                    handleQuestionImageChange={handleQuestionImageChange}
                  />
              }
            </Grid>

            : null
          } */}
          <Grid
            container
            style={{
              marginTop:8
            }}
          >
            {[
              {label: 'text', value:'short-text'},
              {label: 'text-area', value:'text'},
              {label: 'select', value:'select'},
              {label: 'multi-select', value:'select-multiple'},
              // {label: 'integer', value:'integer'},
              {label: 'number', value:'float'},
              // {label: 'radio', value:'radio'},
              // {label: 'checkbox', value:'checkbox'}, // <-- Not currently a valid option on backend
              // {label: 'image', value:'select-image'},
              {label: 'date', value:'date'}
            ].map((type, typeIndex) => (
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 4
                }}
                xs={12}
                sm={6}
                lg={4}
              >
                <Grid
                  tabIndex={0}
                  onClick={(e) => handlePageChange('type', 0, index, type.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handlePageChange('type', 0, index, type.value);
                    }
                  }}
                  style={{
                    border: `1px solid ${page.type === type.value ? theme.palette.primary.main : '#ccc'}`,
                    background: page.type === type.value ? `${theme.palette.primary.light}60` : 'none',
                    padding: '8px 16px 6px 16px',
                    borderRadius: 36,
                    width:'100%',
                    cursor: 'pointer'
                  }}
                >
                  <Typography
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '.8rem',
                      fontWeight: 600,
                      lineHeight: '.8rem',
                      color: page.type === type.value ? theme.palette.primary.main : '#ccc'
                    }}
                  >
                    {type.label}
                  </Typography>
                </Grid>
              </Grid>

            ))}

          </Grid>
          { // Show choices
            requiresChoices &&
            <Grid
              container
              variant="center"
            >
              {
                page.choice_set?.map((answer, answerIndex) => (
                  <AnswerElement
                    key={answer.id}
                    index={index}
                    answer={answer.choice_text}
                    answerIndex={answerIndex}
                    handlePageAnswerChange={handlePageAnswerChange}
                    deleteAnswer={deleteAnswer} />
                ))
              }
              <Button
                onClick={() => addAnswer(0, index)}
                color="primary"
                variant="contained"
                fullWidth
                style={{
                  marginTop:16
                }}
              >
                Add Answer
              </Button>
            </Grid>
          }
          {/* { // Show image uploads
            requiresImages &&
              <Grid
                container
                variant="center"
                style={{
                  marginTop:16
                }}
              >
                {
                      page?.choices.map((image, imageIndex) => (
                        <ImageUploadAnswerElement
                          key={image.id}
                          image={image}
                          questionIndex={index}
                          imageIndex={imageIndex}
                          images={page.images}
                          setImages={setImages}
                          handleAnswerImageChange={handleAnswerImageChange}
                          handlePageAnswerChange={handlePageAnswerChange}
                        />
                      ))
                }
                      <ImageUploadAnswerElement
                        images={page.choices}
                        setImages={setImages}
                        questionIndex={index}
                        handleAnswerImageChange={handleAnswerImageChange}
                        handlePageAnswerChange={handlePageAnswerChange}
                      />
              </Grid>
          } */}
        </Grid>
      :
        null
  );
};

export default QuestionElement