import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingHome from '../components/landing/LandingHome'

const LandingHomePage = () => {
  return (
    <LandingTemplate title="Welcome">
      <LandingHome />
    </LandingTemplate>
  )
}

export default LandingHomePage
