import React, { useEffect, useState } from 'react';
import ElementRTEditor from './ElementRTEditor';
import DOMPurify from 'dompurify'; // Import DOMPurify
import { CopySVG, DeleteSVG, GallerySVG } from '../../assets/icons';

// ElementRenderer Component
const ElementRenderer = ({ element, elementStyle, focused, onElementUpdate, onElementDelete, onElementCopy, preview }) => {
    const [elementText, setElementText] = useState(element?.content || '');

    const handleEditorChange = (newContent) => {
        setElementText(newContent); // Update elementText when editor content changes
    };

    const handleBlur = () => {
      if (element.type === 'Text') {
          onElementUpdate({ ...element, content: elementText });
      }
  };

    const handleDelete = (e) => {
      e.stopPropagation()
      onElementDelete(element);
    }

    const handleCopy = (e) => {
      e.stopPropagation()
      onElementCopy(element);
    }

    useEffect(() => {
      if (elementText) {

      }
    }, [elementText])

    // Render based on element type
    switch (element.type) {
        case 'Text':
            return (
                <ElementWrapper
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  onBlur={handleBlur}
                  preview={preview}
                  wrapStyle={element.wrapStyle}
                >
                  <ElementRTEditor handleEditorChange={handleEditorChange} elementId={element?.uid} initialContent={element?.content || ''} focused={focused} preview={preview} />
                </ElementWrapper>
            );

        case 'Button':
            return (
                <ElementWrapper
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  onBlur={handleBlur}
                  preview={preview}
                  wrapStyle={element.wrapStyle}
                >
                  <a
                    href={preview ? element.url : null}
                    target="_blank"
                    // className={`${!preview && 'element-button'}`}
                  >
                    <button
                      // className="fill-eb-button"
                      style={element.style}
                    >
                      {element.content || 'Button'}
                    </button>
                  </a>
                </ElementWrapper>
            );

        case 'Image':
            return (
                <ElementWrapper
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  onBlur={handleBlur}
                  preview={preview}
                  wrapStyle={element.wrapStyle}
                >
                  {
                    element.url
                      ?
                        <img
                          className="fill-eb-image"
                          alt={element.alt || 'email image'}
                          src={element.url || '/fill-logo_500px.png'}
                          style={{
                            width: '100%',
                            height: 200,
                            objectFit: 'contain',
                            ...element.style,
                            maxWidth:'100%'
                          }}
                        />
                      :
                        <GallerySVG height={150} width="100%" style={{opacity:.5}} />
                  }
                </ElementWrapper>
            );

            case 'Divider':
                return (
                    <ElementWrapper
                      handleDelete={handleDelete}
                      handleCopy={handleCopy}
                      onBlur={handleBlur}
                      preview={preview}
                      wrapStyle={element.wrapStyle}
                    >
                      <hr
                        className="fill-eb-divider"
                        style={element.style}
                      >
                      </hr>
                    </ElementWrapper>
                );
                
            // // Video is currently being integrated but not ready yet
            // case 'Video':
            //     return (
            //         <ElementWrapper
            //           handleDelete={handleDelete}
            //           onBlur={handleBlur}
            //         >
            //           {
            //             element.url
            //               ?
            //                 <img
            //                   className="element-image"
            //                   alt={element.alt || 'email image'}
            //                   src={element.url || '/fill-logo_500px.png'}
            //                   style={{
            //                     width: '100%',
            //                     height: 200,
            //                     objectFit: 'contain',
            //                     ...element.style,
            //                     maxWidth:'100%'
            //                   }}
            //                 />
            //               :
            //                 <GallerySVG height={200} />
            //           }
            //         </ElementWrapper>
            //     );


    

        // Other cases...

        default:
            return <div>{element.type || 'Unknown element type'}</div>;
    }
};

export default ElementRenderer;

const ElementWrapper = (props) => {
  const { children, handleDelete, handleCopy, onBlur, wrapStyle, preview } = props;

  return (
    <div
        className="fill-eb-element-wrapper"
        style={wrapStyle}
        onBlur={onBlur}
    >
      {children}
      <div
        className={`${preview && 'remove'} element-wrapper actions gap-16`}
      >
        <div
          onClick={(e) => handleCopy(e)}
        >
          <CopySVG width={11} color1="#fff" color2="#ffffffcc" color3="#ffffffcc" />
        </div>
        <div
          onClick={(e) => handleDelete(e)}
        >
          <DeleteSVG width={9} color1="#fff" />
        </div>
      </div>
    </div>

  )
}