import React, { useEffect, useRef, useState } from 'react'
import Pricing from '../pricing/Pricing'
import { CenterColumn } from './Landing'
import MainNav from '../nav/MainNav'
import { Grid } from '@mui/material'
import { useTheme } from '@emotion/react'
import PricingAddons from '../pricing/PricingAddons'

const LandingPricing = () => {
  const theme = useTheme();
  
  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary
      }}
    >
      {/* <MainNav /> */}

      <CenterColumn id="pricing">
        <Pricing />
        <PricingAddons />
      </CenterColumn>
    </Grid>
  )
}

export default LandingPricing