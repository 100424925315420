import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingProduct from '../components/landing/LandingProduct'
import { SEOData } from '../lib/SEOData';

const LandingProductPage = () => {
  const data = SEOData.product;

  return (
    <LandingTemplate 
      title={data.title}
      description={data.description}
      name={data.name}
      type={data.type}
    >
      <LandingProduct />
    </LandingTemplate>
  )
}

export default LandingProductPage
