import { Button, Dialog, Typography } from '@mui/material';
import React, { useState, cloneElement, useEffect } from 'react';
import CustomTooltip from './CustomTooltip';

const ReusableModal = (props) => {
  const { children, clickToClose, onClose, onOpen, maxWidth, noBackdropClose, overflowable, manualClose, preOpen, tooltip } = props;
  const [open, setOpen] = useState(preOpen ? true : false);

  useEffect(() => {
    if (manualClose) {
      handleClose();
    }
  }, [manualClose])

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
      if (onOpen) {
        onOpen();
      }
      }
  }

  const handleInnerClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  const handleOpen = () => {
    setOpen(true);
    console.log('onOpen', onOpen)
    if (onOpen) {
      onOpen();
    }
  }

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  const WarningElement = () => {
    return (
      <Typography sx={{textAlign:'center', width:'100%', p:2}}>You need exactly two children to use ReusableModal</Typography>
    )
  }

  return (
    <>
      {
        tooltip
          ?
            <CustomTooltip
             title={tooltip}
            >
              { cloneElement( children.length ? children[0] : children, { onClick: handleClick } ) }
            </CustomTooltip>
          :
            cloneElement( children.length ? children[0] : children, { onClick: handleClick } )
      }
      <Dialog
        open={open}
        scroll="body"
        onClose={noBackdropClose ? null : handleClose}
        fullWidth
        maxWidth={maxWidth ? maxWidth : "sm"}
        PaperProps={{
          sx:{
            borderRadius:3,
            overflow: overflowable ? 'visible' : 'inherit'
          }
        }}
      >
        {
          clickToClose
            ?
              cloneElement( children.length ? children[1] : <WarningElement />, { onClick: handleInnerClick, handleClose: handleClose, handleOpen: handleOpen, open:open } )
            :
              <>
                {
                  noBackdropClose
                    ?
                      <Button
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    :
                      null
                }
                {cloneElement( children.length ? children[1] : <WarningElement />, { handleClose: handleClose, handleOpen: handleOpen, open:open } )}
              </>
        }
        {/* {children[1]} */}
      </Dialog>
    </>
  )
}

export default ReusableModal