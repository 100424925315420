import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Forms from '../components/forms/Forms'

const FormsPage = () => {
  return (

    // <MainPageTemplate title="Welcome">
      <Forms />
    // </MainPageTemplate>

    
  )
}

export default FormsPage
