import React from 'react'
import Step1Register from '../components/signup/Step1Register'
import SignupPageHeader from '../components/signup/SignupPageHeader'

const SignupRegisterPage = () => {
  
  return (
    <>
      <SignupPageHeader step={1} />
      <Step1Register />
    </>
  )
}

export default SignupRegisterPage