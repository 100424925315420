import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import SubuserList from '../components/subusers/SubuserList'

const SubuserManagementPage = () => {
  return (
    // <MainPageTemplate title="Welcome">
    <SubuserList />
    // </MainPageTemplate>
  )
}

export default SubuserManagementPage