import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useApi } from '../../context/apiContext';
import DragAndDropFormCustomFieldOrder from '../ui/DragAndDropFormCustomFieldOrder';
import { getAllCustomFields, putUserDataBag, useGetAllCustomFields, useGetUserDataBag } from '../../hooks/mutations';
import { useTheme } from '@emotion/react';
import { Button, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { ArrowSVG, CloseFilledSVG, EditSVG } from '../../assets/icons';
import { InputField } from '../ui/Fields';
import CustomTooltip from '../ui/CustomTooltip';
import Infotip from '../ui/Infotip';
import { initHeaderColumns } from '../../lib/Data';
import DialogWarning from '../ui/DialogWarning';

const customFieldLayouts = [
  {
    id: 1,
    label: 'Layout 1', 
    order: [],
    attached: [],
    hideStandard: false,
    active: true
  },
  {
    id: 2,
    label: 'Layout 2', 
    order: [],
    attached: [],
    hideStandard: false,
    active: false
  },
  {
    id: 3,
    label: 'Layout 3', 
    order: [],
    attached: [],
    hideStandard: false,
    active: false
  }
]

const PreferencesFieldOrder = () => {
  const apiContext = useApi();
  const savedFieldLayouts = apiContext.contextUserDataBag?.data?.fieldLayouts;
  const contextAllFields = apiContext.contextAllFields;
  const customFields = useGetAllCustomFields();
  const [updatedFieldLayouts, setUpdatedFieldLayouts] = useState(savedFieldLayouts);
  const [currentLayout, setCurrentLayout] = useState(1);
  const [openWarning, setOpenWarning] = useState(false);
  const userDataBag = useGetUserDataBag();
  const theme = useTheme();

  // console.log('savedFieldLayouts', savedFieldLayouts);
  // console.log('updatedFieldLayouts', updatedFieldLayouts)

  // Initialize field layouts
  useLayoutEffect(() => {
    if (userDataBag?.data?.fieldLayouts) {
      setUpdatedFieldLayouts(userDataBag.data.fieldLayouts);
      const activeLayout = userDataBag.data.fieldLayouts.find(x => x.active);
      if (activeLayout) {
        setCurrentLayout(activeLayout.id);
      }
    } else {
      setUpdatedFieldLayouts(customFieldLayouts);
    }
  }, [userDataBag?.data?.fieldLayouts])

  useEffect(() => {
    if (customFields) {
      handleSyncFields();
    }
  }, [customFields])

  const handleResetAllLayouts = () => {
    updateDataBag(true)
  }
  
  const handleResetLayout = () => {
    const copiedLayouts = [ ...updatedFieldLayouts ];

    // Remap the custom field names
    const remappedCustomFields = customFields.map(x => ({ id: x.id, column_type: 'custom', data_type: x.data_type, field_name: x.field_name, field: x.field, order: x.order}));

    // Get standard fields
    const standardFields = [ ...initHeaderColumns ];

    // Remap the standard field names
    const remappedStandardFields = standardFields.map(x => ({id: x.field, column_type: x.columnType, data_type: x.type, field_name: x.label, field: x.field, visible:true}) )

    copiedLayouts[currentLayout - 1].order = [ ...remappedCustomFields, ...remappedStandardFields ];
    setUpdatedFieldLayouts(copiedLayouts);
    setOpenWarning(false);
  }
  
  const handleCloseWarning = () => {
    setOpenWarning(false);
  }

  const handleCustomFieldOrder = (newOrder, index) => {
    const setOrder = newOrder.map((x, index) => ({ ...x, order: index+1 }));

    const newLayout = [ ...updatedFieldLayouts ];
    newLayout[currentLayout - 1].order = setOrder;

    setUpdatedFieldLayouts(newLayout);
  }

  const handleCustomFieldLabel = (value, index) => {
    // Update custom label for custom field layout
    const newLayout = [ ...updatedFieldLayouts ];
    newLayout[index].label = value;
    setUpdatedFieldLayouts(newLayout);
  }

  const handleCustomFieldVisibility = (value, newOrder, index) => {
    // Update visibility for custom field item in current layout
    const newLayout = [ ...updatedFieldLayouts ];
    // console.log('newLayout', newLayout);
    // console.log('currentLayout', currentLayout);

    if (newLayout[currentLayout - 1].order.length) {
      const unsetOrder = [ ...newLayout[currentLayout - 1].order ];
      // console.log('newOrder', newOrder);
      // console.log('newOrder Index', newOrder[index]);
  
      unsetOrder[index].visible = value;
      // console.log('newOrder', newOrder);
  
      newLayout[currentLayout - 1].order = unsetOrder;
      setUpdatedFieldLayouts(newLayout);
    } else {

      newOrder[index].visible = value;
      // console.log('newOrder', newOrder);

      newLayout[currentLayout - 1].order = newOrder;
      setUpdatedFieldLayouts(newLayout);

    }

  }

  const handleCustomFieldActive = () => {
    // Update custom label for custom field layout
    const newLayout = [ ...updatedFieldLayouts ];
    const newActive = newLayout.map((x, index) => {
      if (x.id === currentLayout) {
        return {
          ...x,
          active:true
        }
      } else {
        return {
          ...x,
          active:false
        }
      }
    })
    setUpdatedFieldLayouts(newActive);
  }

  const handleTabChange = (event, newValue) => {
    const selected = newValue + 1;
    setCurrentLayout(selected);
  };

  const handleSyncFields = async () => {
    const reduced = customFields.map(x => ({ id: x.id, column_type: 'custom', data_type: x.data_type, field_name: x.field_name, field: x.field, order: x.order}));

    const newLayout = [ ...updatedFieldLayouts ];

    // Remove any fields in newLayout that don't exist in custom fields anymore
    // Filter out items from 'layoutItem.order' that don't exist in the 'reduced' array
    const newRemoved = newLayout.map(layoutItem => {
      const filteredOrder = layoutItem.order.filter(order =>
        reduced.some(item => item.id === order.id || order.column_type === 'default')
      );

      return {
        ...layoutItem,
        order: filteredOrder
      };
    })

    // Handle adding in each default field if it doesn't exist
    const defaultHeaderLength = initHeaderColumns.length;

    // Map over each item in newLayout
    const newActive = newRemoved.map(layoutItem => {
      // Filter out items from 'reduced' that don't exist in the 'order' array of the current layoutItem
      const filteredItems = reduced.filter(item =>
        !layoutItem.order.some(order => order.id === item.id)
      );

      // Combine filtered items with the current layoutItem
      const combinedOrder = [...layoutItem.order, ...filteredItems];

      // Filter out items that don't have an id
      const filteredOrder = combinedOrder.filter(x => x.id);

      // Remove duplicate items based on their 'id' fields
      const uniqueOrder = filteredOrder.filter(
        (item, index, self) => self.findIndex(i => i.id === item.id) === index
      );

      // Add default fields if the total number of default fields isn't correct
      const existingDefaultFields = layoutItem.order.filter(x => x.column_type === 'default');
      if (existingDefaultFields.length != defaultHeaderLength) {
        // Get standard fields
        const standardFields = [ ...initHeaderColumns ];

        // Remap the field names
        const remappedFields = standardFields.map(x => ({id: x.field, column_type: x.columnType, data_type: x.type, field_name: x.label, field: x.field, visible:true}) )

        uniqueOrder.unshift(...remappedFields)
      }


      return {
        ...layoutItem,
        order: uniqueOrder
      };
    });

    setUpdatedFieldLayouts(newActive);
  }

  // Function to send updates to user bag to the server
  const updateDataBag = async (reset) => {

    const dataObj = {
      id: userDataBag.id,
      data: {
        data: {
          ...userDataBag.data,
          fieldLayouts: reset ? null : updatedFieldLayouts
        }
      }
    }

    const updatedDataBag = await putUserDataBag(dataObj);

    if (updatedDataBag.status === 'success') {
      console.log(updatedDataBag.response);
      apiContext.handleSetContextUserDataBag(updatedDataBag.data);
    }
  }

  // Save data bag field updates after no changes have been made for 1/10 of a second
  useEffect(() => {
    let timeoutId;
    if (updatedFieldLayouts && userDataBag) {
      timeoutId = setTimeout(() => {
        updateDataBag();       
      }, 100);
    }
  
    return () => {
      // Cleanup function
      clearTimeout(timeoutId);
    };
  }, [updatedFieldLayouts, userDataBag]);
  

  return (
    userDataBag &&
    <Grid container>
      <Grid
        sx={{
          width:'100%',
        }}
      >
        <Tabs
          value={currentLayout - 1}
          // onChange={handleTabChange}
          // indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="full width tabs"
          centered
          sx={{
            background:'#eee',
            '.MuiTabs-scroller':{
              display:{xs:'inline-block', sm:'flex'},
              justifyContent:'center',
            }
          }}
        >
          {
            updatedFieldLayouts?.map((layout, index) => (
              <MenuTab
                key={layout.id}
                tab={layout}
                index={index}
                currentLayout={currentLayout}
                setCurrentLayout={setCurrentLayout}
                handleCustomFieldLabel={handleCustomFieldLabel}
              />
            ))
          }
        </Tabs>

        {/* <Grid
          item
          style={{
            height:'calc(100vh - 70px)',
            paddingTop:16,
            width: 250,
            borderRight:'1px solid #aaa'
          }}
          xs="auto"
        >
          {
            updatedFieldLayouts?.map((layout, index) => (
              <MenuTab
                key={layout.id}
                tab={layout}
                index={index}
                currentLayout={currentLayout}
                setCurrentLayout={setCurrentLayout}
                handleCustomFieldLabel={handleCustomFieldLabel}
              />
            ))
          }
        </Grid> */}

        {
          currentLayout
            ?
              <Grid
                container
                style={{
                  // height:'calc(100vh - 70px)',
                  // borderRight:'1px solid #aaa',
                  overflow:'auto'
                }}
              >
                <Grid
                  item
                  order={{xs:1, md:0}}
                  sx={{
                    display:'flex',
                    // justifyContent:'center',
                    // width: currentLayout ? '100%' : '0%',
                    transition: '.4s',
                    overflowX:'hidden',
                    padding:2,
                    // width: currentLayout ? 300 : 0,
                    // height:'100vh'
                  }}
                  xs={12}
                  md={6}
                >
                  <DragAndDropFormCustomFieldOrder
                    title="Position"
                    incomingData={
                      updatedFieldLayouts?.[currentLayout - 1]?.order.length
                        ?
                          updatedFieldLayouts[currentLayout - 1].order
                        :
                          contextAllFields
                    }
                    nameField="field_name"
                    updateData={handleCustomFieldOrder}
                    handleCustomFieldVisibility={handleCustomFieldVisibility}
                    setCurrentPage={() => {}}
                    currentPage={() => {}}
                  />
                </Grid>
                <Grid
                  item
                  order={{xs:0, md:1}}
                  sx={{
                    p:2
                  }}
                  xs={12}
                  md={6}
                >
                  <Grid
                    sx={{
                      display:'flex',
                      flexDirection:'column',
                      gap:2,
                      flex:1,
                      // borderBottom:'1px solid #aaa',
                      alignItems:'flex-start',
                      // justifyContent:'space-between',
                      padding:'0 8px',
                    }}
                  >
                    {/* <div style={{display:'flex', alignItems:'center', gap:8}}>
                      <Typography
                        style={{
                          fontSize:'1.1rem',
                          fontWeight:500,
                          color:'#aaa',
                          marginLeft:8
                        }}
                      >
                        Field Layouts
                      </Typography>
                      <Infotip
                        question
                        title="Field Layouts"
                        tip="Layouts let you order your custom fields in the CRM"
                        messages={{
                          one: "Custom field layouts allow you to display different fields in a custom order in the CRM.",
                          two: "Custom field layouts do not modify contact data. They only change which fields are shown, and the order of the displayed fields."
                        }}
                      />
                    </div> */}
                    <InputField
                      value={updatedFieldLayouts[currentLayout-1].label}
                      onChange={(e) => handleCustomFieldLabel(e.target.value, currentLayout-1)}
                      onBlur={() => {
                        if (!updatedFieldLayouts[currentLayout-1].label.trim()) {
                          handleCustomFieldLabel(`Layout ${currentLayout}`, currentLayout-1);
                        }
                      }}
                      style={{
                        maxWidth:250
                      }}
                    />
                    <Button
                      onClick={handleCustomFieldActive}
                      variant="contained"
                      disabled={updatedFieldLayouts[currentLayout-1].active}
                      style={{
                        transform: currentLayout ? 'scale(1)' : 'scale(0)',
                        width: currentLayout ? 'inherit' : 0,
                        transition:'.5s'
                      }}
                    >
                      {
                        updatedFieldLayouts[currentLayout-1].active
                          ? 'Active'
                          : 'Make Active'
                      }
                    </Button>
                    <Grid sx={{display:'flex', gap:2, alignItems:'center'}}>
                      <Button
                        onClick={handleSyncFields}
                        variant="contained"
                        style={{
                          transform: currentLayout ? 'scale(1)' : 'scale(0)',
                          width: currentLayout ? 'inherit' : 0,
                          transition:'.25s'
                        }}
                      >
                        Sync Fields
                      </Button>
                      <Infotip
                        question
                        title="Sync Fields"
                        tip="Sync added or deleted custom fields"
                        messages={{
                          one: "Added or deleted custom fields should automatically sync to field layouts. If they don't, then click the SYNC FIELDS button to have your fields match your custom fields.",
                          two: <span style={{fontStyle:'italic'}}>If you do not see a field here, it is probably because it has not been synced. If you see a field you have deleted, it is also probably because it needs to be synced.</span>
                        }}
                      />
                    </Grid>
                    <Button
                      onClick={() => setOpenWarning(true)}
                      variant="contained"
                      style={{
                        transform: currentLayout ? 'scale(1)' : 'scale(0)',
                        width: currentLayout ? 'inherit' : 0,
                        transition:'.25s'
                      }}
                    >
                      Reset Layout
                    </Button>
                  </Grid>

                </Grid>
              </Grid>
            :
              <Grid
                container
                variant="center"
                flexDirection="column"
                py={12}
              >
                <ArrowSVG width={18} color1={theme.palette.primary.main} style={{transform:'rotate(-90deg)'}} />
                <Typography color="primary" fontWeight={500}>Select a layout to edit above.</Typography>
              </Grid>
        }
      </Grid>
      <DialogWarning
        open={openWarning}
        onClose={handleCloseWarning}
        header="Reset Field Layout"
        title={`You are about to reset the '${updatedFieldLayouts[currentLayout - 1].label}' layout`}
        messages={{
          one:'Are you sure you want to reset the order and visibility of the fields in this layout? They will be reset to their default state.',
          confirm:'Yes'
        }}
        // deleting
        cancel={handleCloseWarning}
        confirm={handleResetLayout}
        // icons
      />

    </Grid>
  )
}

export default PreferencesFieldOrder

const MenuTab = (props) => {
  const { tab, index, currentLayout, setCurrentLayout, handleCustomFieldLabel } = props;
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const theme = useTheme();

  return (
    <Grid
      onClick={() => setCurrentLayout(tab.id)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        background: currentLayout === tab.id ? `${theme.palette.primary.light}10` : 'none',
        transition:'.4s',
        // paddingLeft:24,
        padding:'0 48px',
        cursor:'pointer',
        // borderRight: currentLayout === tab.id ? `10px solid blue` : 'none'
      }}
    >
      {
        edit
          ?
            <InputField
              value={tab.label}
              onChange={(e) => handleCustomFieldLabel(e.target.value, index)}
              onBlur={() => setEdit(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setEdit(false);
                }
              }}
              style={{
                maxWidth:170
              }}
            />
          :
              <CustomTooltip
                title={tab.label?.length > 14 ? tab.label : null} // Only show tooltip if label is long enough to overflow
              >
                <Typography
                  sx={{ // Match nav button style in django
                    fontSize: '1rem',
                    fontWeight: currentLayout === tab.id ? 500 : 400,
                    lineHeight:1.2,
                    color:'inherit',
                    textTransform:'none',
                    maxWidth:150,
                    overflow:'hidden',
                    whiteSpace:'nowrap',
                    textOverflow:'ellipsis',
                    '&&:hover': {
                      fontWeight:500,
                      background:'none'
                    }
                  }}
                >
                  {tab.label}
                </Typography>
              </CustomTooltip>
      }
      {
        // hover && !edit &&
        //   <IconButton
        //     onClick={(e) => {
        //       e.stopPropagation();
        //       setEdit(true)
        //     }}
        //     style={{marginLeft:8}}
        //   >
        //     <EditSVG width={15} />
        //   </IconButton>
      }
      {/* <div
        style={{
          position:'absolute',
          top: currentLayout === tab.id ? 0 : '50%',
          right:0,
          background:theme.palette.primary.main,
          height: currentLayout === tab.id ? '100%' : '0%',
          width:'10px',
          transition:'.4s'
        }}
      />
      <div
        style={{
          position:'absolute',
          top: 'calc(50% - 8px)',
          right:18,
          opacity: currentLayout === tab.id ? 1 : 0,
        }}
      >
        <a
          onClick={(e) => {
            if (currentLayout === tab.id) {
              e.stopPropagation();
              setCurrentLayout(null)
            }
          }}
          style={{
            position:'relative',
            zIndex:1200
          }}
        >
          <CloseFilledSVG width={16} color1={`${theme.palette.primary.light}77`} />
        </a>
      </div> */}
      {
        tab.active &&
          <div
            style={{
              position:'absolute',
              bottom:4,
              left:0,
              width:'100%',
              display:'flex',
              justifyContent:'center'
            }}
          >
            <Typography
              style={{
                fontSize:'.7rem',
                fontWeight:600,
                color: theme.palette.primary.dark
              }}
            >
              ACTIVE
            </Typography>
          </div>
      }
    </Grid>
  )
}
