import React from 'react'
import Surveys from '../components/surveys/Surveys'
import MainPageTemplate from '../templates/MainPageTemplate'

const SurveysPage = () => {
  return (
    <MainPageTemplate title="Welcome">
      <Surveys />
    </MainPageTemplate>
  )
}

export default SurveysPage