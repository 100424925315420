import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingPricing from '../components/landing/LandingPricing'
import { SEOData } from '../lib/SEOData';

const LandingPricingPage = () => {
  const data = SEOData.pricing;

  return (
    <LandingTemplate 
      title={data.title}
      description={data.description}
      name={data.name}
      type={data.type}
    >
      <LandingPricing />
    </LandingTemplate>
  )
}

export default LandingPricingPage
