import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingFeatures from '../components/landing/LandingFeatures'

const LandingFeaturesPage = () => {
  return (
    <LandingTemplate title="Features">
      <LandingFeatures />
    </LandingTemplate>
  )
}

export default LandingFeaturesPage
