import { useTheme } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getScreenSize, useScreenSize, useScreenSizeCalc } from '../../lib/Interface'
import { LogoHorizontal } from '../../assets/logos'
import { ScrollToGrow } from '../ui/ScrollAnimations'
import { Angle } from '../../assets/graphics'
import MainNav from '../nav/MainNav'
import { featureList } from '../../lib/Data'
import Pricing from '../pricing/Pricing'
import { hashScroll } from '../../lib/Functions'

const Landing = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary
      }}
    >
      <Header />
      <About />
      <Features />
      <Prices />
    </Grid>
  )
}

export default Landing

const Header = () => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Grid
      container
      style={{
        position:'relative',
        background: theme.palette.primary.dark,
        height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
        justifyContent:'center',
        // marginTop:60,
        overflow:'hidden'
      }}
    >
      <Grid
        style={{
          position:'absolute',
          // top:60,
          right:0,
          height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
          background:'blue',
          background:'url(https://images.unsplash.com/photo-1554902843-260acd0993f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
          backgroundPosition:'center',
          width:'50vw',
          overflow:'hidden'
        }}
      >
        <Angle width={"100%"} height="100%" color1={theme.palette.primary.dark} style={{height:'100%', width:'100%'}} />
        {/* <img
          // src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          src="https://images.unsplash.com/photo-1554902843-260acd0993f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{
            objectFit:'cover',
            width:'50vw',
            height:'100%',
            // border:'10px solid #fff',
            // zIndex:1
            // opacity:.15
          }}
        /> */}
      </Grid>

      <Grid
        container
        sx={{
          position:'relative',
          alignItems:'center',
          height:'100%',
          width:{xs:250, sm:500, md:800, xl:1000}
        }}
      >
        {/* <img
          src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{
            position:'absolute',
            // top: ( screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh') ) - 200,
            bottom:-150,
            right:-200,
            objectFit:'cover',
            width:'50vw',
            minWidth:400,
            maxWidth:600,
            height:'50vw',
            minHeight:400,
            maxHeight:600,
            borderRadius:400,
            // border:'10px solid #fff',
            zIndex:1
            // opacity:.15
          }}
        /> */}
        <Typography
          sx={{
            fontSize: {xs: '3rem', sm:'4rem', md:'5rem', lg:'5rem', xl:'8rem'},
            fontWeight:700,
            lineHeight:1,
            textTransform:'uppercase',
            color:theme.palette.primary.light, //'#fff'
            textShadow:`
              5px 5px ${theme.palette.primary.dark},
              0px 5px ${theme.palette.primary.dark},
              5px 0px ${theme.palette.primary.dark},
              -5px -5px ${theme.palette.primary.dark},
              0px -5px ${theme.palette.primary.dark},
              -5px 0px ${theme.palette.primary.dark},
              -5px 5px ${theme.palette.primary.dark},
              5px -5px ${theme.palette.primary.dark}
            `,
            zIndex:2
          }}
        >
          Built for small companies with large reach. 
        </Typography>
      </Grid>
    </Grid>
  )
}

const Navbar = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        background: '#fff',
        height:60,
        padding:'0 24px',
        justifyContent:'space-between'
      }}
    >
      <Grid
        style={{
          display:'flex',
          alignItems:'center'
        }}
      >
        <LogoHorizontal height={24} />
      </Grid>
      <Grid
        style={{
          display:'flex',
          alignItems:'center'
        }}
      >
        <NavButton
          label="About"
        />
        <NavButton
          label="Features"
        />
        <NavButton
          label="Pricing"
        />
        <NavButton
          label="Sign Up"
        />
        <NavButton
          label="Login"
        />
      </Grid>
    </Grid>
  )
}

const NavButton = (props) => {
  const { label } = props;

  return (
    <Button
      onClick={() => {}}
      style={{
        fontWeight:600
      }}
    >
      {label}
    </Button>
  )
}

const About = () => {
  const theme = useTheme();
  return (
    <CenterColumn id="about">
      <Typography
        sx={{
          fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
          fontWeight:700,
          lineHeight:1,
          // textTransform:'uppercase',
          color:theme.palette.primary.dark, //'#fff',
          transition:'.4s',
          zIndex:2
        }}
      >
        The best alternative to HubSpot for small business.
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: '1.4', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
          lineHeight:1.2,
          marginTop:'16px',
          transition:'.4s'
        }}
      >
        Most Client Relationship Management tools focus on large enterprise businesses—and they price it that way. Not us.
      </Typography>
    </CenterColumn>
  )
}

const Features = () => {
  const theme = useTheme();
  return (
    <Grid
      id="features"
      container
    >
      <CenterColumn
        alignment={{x:'flex-start'}}
      >
      <Typography
        sx={{
          fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
          fontWeight:700,
          lineHeight:1,
          // textTransform:'uppercase',
          color:theme.palette.primary.dark, //'#fff'
          transition:'.4s',
          zIndex:2
        }}
      >
        Features
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: '1.4', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
          lineHeight:1.2,
          marginTop:'16px',
          transition:'.4s'
        }}
      >
        The Fill CRM and email marketing platform has an amazing list of features built to compete with similar products from the corporate marketing overlords.
      </Typography>

      <Grid container variant="center">
        {
          featureList.map(feature => (
            <Grid
              item
              style={{
                padding:4
              }}
              xs={8}
              sm={6}
              md={4}
              lg={3}
            >
              <Grid
                container
                variant="center"
                onClick={() => hashScroll(feature.icon)}
                style={{
                  padding:'2px 8px',
                  borderRadius:10,
                  background:theme.palette.primary.main,
                  color: '#fff',
                  cursor:'pointer'
                }}
              >
                <Typography>{feature.shortName}</Typography>
              </Grid>
            </Grid>
          ))
        }
      </Grid>

      </CenterColumn>
      {
        featureList.map((feature, index) => (
          <FeatureItem
            key={feature.name}
            feature={feature}
            index={index}
          />
        ))
      }
    </Grid>
  )
}

const FeatureItem = (props) => {
  const { feature, index } = props;
  const [available, setAvailable] = useState({
    label: '',
    color: null,
    background: null
  });
  const theme = useTheme();
  const screenSize = useScreenSize();
  const reverse = index % 2;

  useEffect(() => {
    if (feature) {
      if (feature.availability === "now") {
        setAvailable({
          label: 'Available Now',
          color: '#fff', //nowTheme.color,
          background: theme.palette.primary.main //nowTheme.background
        })
      } else
      if (feature.availability === "soon") {
        setAvailable({
          label: 'Coming Soon',
          color: '#fff', //theme.palette.primary.dark, //soonTheme.color,
          background: theme.palette.primary.light //soonTheme.background
        })
      } else
      if (feature.availability === "later") {
        setAvailable({
          label: 'Later This Year',
          color: '#fff', //laterTheme.color,
          background: theme.palette.secondary.main //laterTheme.background
        })
      }
    }
  }, [feature]);

  return (
    <Grid
      id={feature.icon}
      container
      sx={{
        // margin:'48px 0',
        flexDirection: {xs: 'row', md: reverse ? 'row-reverse' : 'row'},
        padding: '24px',
        // paddingBottom: {xs:4, md:0},
        borderBottom:'1px solid #ccc',
        borderTop: index === 0 ? '1px solid #ccc' : null
      }}
    >
        <Grid
          item
          sx={{
            // height:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            // padding:{xs: 0, md: '0 90px'},
          }}
          xs={12}
          md="auto"        >
          {
            feature.icon
              ?
                <img
                  src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/${feature.icon}_500px.png` : `https://static.fillmarketing.com/static/static/img/${feature.icon}_500px.png`}
                  style={{
                    width: screenSize.xs ? '60%' : (screenSize.sm ? '40%' : '80%'),
                    height:'80%',
                    objectFit:'contain'
                  }}
                />
              :
                null
          }
        </Grid>
        <Grid
          item
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}
          xs={12}
          md
        >
            <Grid
              container
              style={{
                maxWidth:500
              }}
            >
              <Typography
                style={{
                  width:'100%',
                  // textAlign: reverse ? 'right' : 'left',
                  lineHeight:1,
                  fontSize:'2.75rem',
                  fontWeight:600,
                  marginBottom:8,
                  color:theme.palette.primary.dark
                }}
              >
                {feature.name}
              </Typography>
              <Typography
                style={{
                  width:'100%',
                  // textAlign: reverse ? 'right' : 'left',
                  textTransform:'uppercase',
                  lineHeight:1.1,
                  fontSize:'2rem',
                  fontWeight:500,
                  marginBottom:16,
                  color:theme.palette.primary.main
                }}
              >
                {feature.short}
              </Typography>
              <Typography
                style={{
                  width:'100%',
                  fontSize:'1.3rem'
                  // textAlign: reverse ? 'right' : 'left'
                }}
              >
                {feature.description}
              </Typography>
              <Grid
                style={{
                  background:available.background,
                  color: available.color,
                  padding:'0 12px',
                  borderRadius:16,
                  marginTop:8
                }}
              >
                <Typography
                  style={{
                    fontSize:'.85rem',
                    fontWeight:500,
                    textTransform:'uppercase',
                    color:available.color
                  }}
                >
                  {available.label}
                </Typography>
              </Grid>
            </Grid>
        </Grid>
    </Grid>
  )
}

const Prices = () => {
  return (
    <CenterColumn id="pricing">
      <Pricing />
    </CenterColumn>
  )
}

export const CenterColumn = ({ children, id, alignment }) => {
  const screenSize = useScreenSize();
  const theme = useTheme();
  
  return (
    <Grid
      id={id}
      container
      style={{
        justifyContent: 'center',
        alignItems: alignment?.start ? alignment.start : 'center'
      }}
    >
      <Grid
        container
        sx={{
          width:{xs:'96%', sm:600, md:800, xl:1000},
          padding:'48px 0',
          justifyContent: alignment?.x ? alignment.x : 'center'
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}