import React, { useState } from 'react'
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import ReusableModal from '../ui/ReusableModal';

const FeedbackPanelTemplate = (props) => {
  const { bugItems, feedback, featureRequests } = props;
  const [expandItem, setExpandItem] = useState();

  console.log('bugItems', bugItems);
  console.log('featureRequests', featureRequests);
  console.log('feedback', feedback);

  const handleExpandItem = (item) => {
    if (expandItem === item) {
      setExpandItem();
    } else {
      setExpandItem(item);
    }
  }

  return (
    <Grid
      container
    >
      <FeedbackItemsWrapper
        title="Feature Requests"
        amount={featureRequests?.length ? featureRequests.length : 0}
      >
        {
          featureRequests?.map((request, index) => (
            <FeedbackItemTemplate
              key={request.id}
              item={{
                ...request,
                title:request.feature,
                description:request.description
              }}
              index={index}
            />
          ))
        }
      </FeedbackItemsWrapper>

      <FeedbackItemsWrapper
        title="Bug Reports"
        amount={bugItems?.length ? bugItems.length : 0}
      >
        {
          bugItems?.map((bug, index) => (
            <FeedbackItemTemplate
              key={bug.id}
              item={{
                ...bug,
                title:bug.feedback,
                description:bug.description
              }}
              index={index}
            />
          ))
        }
      </FeedbackItemsWrapper>

      <FeedbackItemsWrapper
        title="Feedback"
        amount={feedback?.length ? feedback.length : 0}
      >
        {
          feedback?.map((feedback, index) => (
            <FeedbackItemTemplate
              key={feedback.id}
              item={{
                ...feedback,
                title:feedback.feedback,
                description:feedback.description
              }}
              index={index}
            />
          ))
        }
      </FeedbackItemsWrapper>

    </Grid>
  )
}

export default FeedbackPanelTemplate

const FeedbackItemsWrapper = (props) => {
  const { children, amount, title } = props;

  return (
      <Grid
        container
        sx={{
          background:'#ffffff',
          alignItems:'flex-start',
          borderRadius:3,
          boxShadow:'0 0 7px #00000020',
          p:2,
          mb:2
        }}
      >
        <Grid
          container
          sx={{
            mb:2
          }}
        >
          <Typography
            variant="h4"
          >
            {`${title} (${amount})`}
          </Typography>
        </Grid>
        <Grid
          sx={{
            minHeight:200,
            width:'100%'
          }}
        >
          {children}
        </Grid>
      </Grid>

  )
}

const FeedbackItemTemplate = (props) => {
  const { children, item, expandItem } = props;
  return (
    <>
      <Grid
        container
        style={{
          justifyContent:'flex-end'
        }}
      >
        <Typography
          style={{
            background:'#f3f3f3',
            padding:'0 12px'
          }}
        >
          {item.location}
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          boxShadow:'0 0 5px #00000030',
          justifyContent:'space-between',
          borderRadius:'8px 0 8px 8px',
          width:'100%',
          mb:1,
          px:2,
          py:1
        }}
      >
        <Grid>
          <Typography
            style={{
              fontWeight:700
            }}
          >
            {item.title}
          </Typography>
          <Typography
            style={{
              fontStyle:'italic',
              color:'#777'
            }}
          >
            Reported by {item.name}
          </Typography>
          <Typography>
            {item.description}
          </Typography>
        </Grid>
        { // Popup Content Goes Here
          // <Box>
          //   <ReusableModal>
          //     <Button>Popup</Button>
          //     <Popup>
          //       <Grid
          //         container
          //       >
          //         <Typography>
          //           {request.feature}
          //         </Typography>
          //         <Typography>
          //           {request.description}
          //         </Typography>
          //       </Grid>
          //     </Popup>
          //   </ReusableModal>
          //   <Button
          //     onClick={() => handleExpandItem(request.id)}
          //   >
          //     {
          //       expandItem === request.id
          //         ?
          //           'Collapse'
          //         :
          //           'Expand'
          //     }
          //   </Button>
          // </Box>
        }

      </Grid>
    </>

  )
}

const Popup = (props) => {
  const { children } = props;

  return (
    <Grid
      container
      sx={{
        minHeight:300,
        p:2
      }}
    >
      {children}
    </Grid>

  )
}