import React, { useEffect, useState } from 'react'
import { useGetAllSenderDetails } from '../../hooks/mutations';
import { Button, Grid, Typography } from '@mui/material';
import EditSenderDetail from './EditSenderDetail';
import SenderDetailsList from './SenderDetailsList';

const SingleSenderDetails = () => {
  const [isAddingSender, setIsAddingSender] = useState(false);
  const [reload, setReload] = useState();
  const senderDetails = useGetAllSenderDetails(reload);
  const existingSenderDetail = senderDetails?.length ? senderDetails[0] : null;
  
  console.log('senderDetails', senderDetails);
  console.log('existingSenderDetail', existingSenderDetail);
  console.log('isAddingSender', isAddingSender);
  
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  return (
    <Grid
      container
      style={{
        justifyContent:'center',
        padding:16
      }}
    >
      <EditSenderDetail existingSenderDetail={existingSenderDetail} setReload={setReload} />
    </Grid>
  )
}

export default SingleSenderDetails