import React from 'react'
import Step3Tenant from '../components/signup/Step3Tenant'
import SignupPageHeader from '../components/signup/SignupPageHeader'

const SignupTenantPage = () => {


  
  return (
    <>
      <SignupPageHeader step={2} />
      <Step3Tenant />
    </>
  )
}

export default SignupTenantPage