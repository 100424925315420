import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography, Stack } from '@mui/material'
import { theme } from '../../theme'
import { UpFilledSVG } from '../../assets/icons'
import { useLocation } from 'react-router-dom'
import { hashScroll } from '../../lib/Functions'
import PriceTableItem from './PriceTableItem'
import PriceFeatureList from './PriceFeatureList'
import PricingDetails from './PricingDetails'
import { featureList } from '../../lib/Data'


  const priceTable = [
    {
      title:'Basic',
      id:'basic',
      priceMon:'38',
      priceAnn:'29',
      priceMonId: 1,
      priceAnnId: 2,
      term:'month',
      description:<span>We don't have separate pricing tiers. You can use as many or as few of our core features as you need — all for one low price.</span>,
      // checkout:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      // moreButton:
      //   <Button
      //     onClick={() => hashScroll('basic')}
      //   >
      //     See More Details
      //   </Button>
      // ,
      // benefits: [
      //   `We don't have separate pricing tiers`,
      //   // `Includes 10,000 emails to send every month`,
      //   `Use as many or as few of our core features as you need`,
      //   `All for one super affordable price`
      // ],
      ctaButton:
      <Button
        variant="contained"
        href=""
      >
        GET STARTED
      </Button>
    }
  ]


const Pricing = () => {
  const [priceModel, setPriceModel] = useState('monthly');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const priceModelParam = urlParams.get('model')

  useEffect(() => {
    if (priceModelParam) {
      setPriceModel(priceModelParam)
    }
  }, [priceModelParam])


  //SignedPic app places the plans object in Product.js in /lib  and imports it into Pricing.js.
  const plans = {
    basic:[
      {
        label:'Example Feature #1',
        info:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        icon:null,
      },
      {
        label:'Example Feature #2',
        info:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        icon:null,
      },
      {
        label:'Example Feature #3',
        info:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        icon:null,
      },
    ]
  }

  const location = window.location;

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location])

  return (
    <>
      <Grid
        id="top"
        variant="center"
        style={{
          minHeight: 'calc(100vh - 80px)'
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 1400,
            zIndex: 1
          }}
        >
          <Grid
            container
            display="column"
            style={{
              // background: `${theme.palette.background.main}aa`,
              borderRadius: 16,
              // boxShadow: '0 0 10px #000000030',
              justifyContent:'center',
              paddingBottom:16
            }}
          >
            <Grid
              container
              style={{
                padding: 16,
                marginTop: 100,
                justifyContent: 'center'
              }}
            >
              <Grid
                variant="center"
                flexDirection="column"
              >

                <Typography variant="h4">
                  <span style={{fontWeight:700}}>MORE BANG!</span> Less Buck.
                </Typography>
                <Typography
                  style={{
                    fontSize:'1.1rem'
                  }}
                >
                  One price for all our core features. It's that simple. We promise.
                </Typography>
                <Grid
                  variant="center"
                  onClick={() => {
                    if (priceModel === 'annual') {
                      setPriceModel('monthly')
                    } else {
                      setPriceModel('annual')
                    }
                  }}
                  style={{
                    outline: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 32,
                    background: `${theme.palette.background.main}cc`,
                    padding: 4,
                    margin: '16px 0'
                  }}
                >
                  <Grid
                    style={{
                      padding: '4px 16px',
                      background: priceModel === 'monthly' ? theme.palette.primary.main : 'transparent',
                      transition: '.4s',
                      borderRadius: 32,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: priceModel === 'monthly' ? '#ffffff' : '#777777',
                      }}
                    >
                      Monthly
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: '4px 16px',
                      background: priceModel === 'annual' ? theme.palette.primary.dark : 'transparent',
                      transition: '.4s',
                      borderRadius: 32,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: priceModel === 'annual' ? '#ffffff' : '#777777',
                      }}
                    >
                      Annual
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              priceTable.map((item, index) => (
                <Grid
                  item
                  xs={11.5}
                  // sm={11.5}
                  // md={12 / priceTable.length}
                  // p={2}
                >
                  <PriceTableItem
                    stacked
                    title={item.title}
                    priceMon={item.priceMon}
                    priceAnn={item.priceAnn}
                    priceModel={priceModel}
                    term={item.term}
                    description={item.description}
                    moreButton={item.moreButton}
                    ctaButton={item.ctaButton}
                    benefits={item.benefits}
                    subscriptionDetails={item}
                  />
                </Grid>
              ))
            }

          </Grid>
        </Grid>
      </Grid>

      
      {/* <PricingDetails
        id="basic"
        title="Basic"
        subscription="Basic"
        subscriptionDetails={priceTable.filter(x => x.title === "Basic")[0]}
        showOptions
        heading={
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit 
          `
        }
        description={
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  
          `
        }
      >
        <PriceFeatureList
          features={plans.basic}
          info
          wide
        />
      </PricingDetails>       */}

      {/* <UpFilledSVG
        width={60}
        color1={theme.palette.primary.light}
        onClick={() => hashScroll("top")}
        style={{
          position: 'fixed',
          bottom: 50,
          right: 50,
          zIndex: 1
        }}
      /> */}


    </>
  )
}

export default Pricing