import React from 'react'
import TermsTemplate from './TermsTemplate';
import { cookie_policy_html } from './cookie_policy';

const TermsCookies = () => {

  return (
    <div style={{display:'flex', justifyContent:'center'}}>
      <div style={{maxWidth:700, margin:'96px 0'}}>
        <div dangerouslySetInnerHTML={{__html: cookie_policy_html}} />
      </div>
    </div>
    // <TermsTemplate
    //   contents={cookiePolicy}
    //   term="Cookies Policy"
    //   effective="July 21, 2024"
    //   updated="July 5, 2024"
    //   description="The Cookies Policy describes how we use cookies to enhance your browsing experience and manage your preferences on our site."
    //   general={<>
    //     <p>This Cookie Policy explains how Fill ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at <a href="https://fillmarketing.com" target="_blank">https://fillmarketing.com</a> ("Website") and any associated subdomains. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
    //     <p>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>
    //   </>}
    // />
  )
}

export default TermsCookies;

export const cookiePolicy = [
  {
    title: "COOKIE POLICY",
    body: <>
      <p><strong>Effective date:</strong> August 01, 2024</p>
      <p><strong>Last updated:</strong> July 05, 2024</p>
      <p>This Cookie Policy explains how Fill ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at <a href="https://fillmarketing.com">https://fillmarketing.com</a> ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
      <p>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>
    </>
  },
  {
    title: "WHAT ARE COOKIES?",
    body: <>
      <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
      <p>Cookies set by the website owner (in this case, Fill) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
    </>
  },
  {
    title: "WHY DO WE USE COOKIES?",
    body: <>
      <p>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.</p>
    </>
  },
  {
    title: "HOW CAN I CONTROL COOKIES?",
    body: <>
      <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>
      <p>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.</p>
      <p>The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):</p>
    </>,
    table: [
      {
        title: "Essential website cookies:",
        body: <>
          <p>These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.</p>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>csrftoken</td>
              </tr>
              <tr>
                <td>Purpose</td>
                <td>Helps prevent Cross-Site Request Forgery (CSRF) attacks using Javascript. It is a first party cookie with unique value. It expires in 1 year or until a user submits a form.</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Service</td>
                <td>Django View Service Privacy Policy</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>11 months 29 days</td>
              </tr>
            </tbody>
          </table>
        </>
      },
      {
        title: "Unclassified cookies:",
        body: <>
          <p>These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.</p>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_posthog</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>html_local_storage</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>persistent</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_window_id</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>html_session_storage</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>userDataBagContext</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>html_local_storage</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>persistent</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>tenantContext</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>html_local_storage</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>persistent</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_posthog</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>11 months 30 days</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>_fl_sess_dja_lf</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>14 days</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>userContext</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>html_local_storage</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>persistent</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>ph_phc_j2rkAuM640dXiyfuHHS4fEgVVtcAwAufuHQdBUYhET1_primary_window_exists</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>html_session_storage</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>mp_5ac5357698521c2c7c793cb30ad9fa60_mixpanel</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>365 days</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>_fl_session_03ftxZyz9UnXfOlsrO8k1HgCn90EkAgT4U</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>30 minutes</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>csrfToken</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>fillmarketing.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>30 minutes</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>Mixpanel</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>mixpanel.com</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in</td>
                <td>365 days</td>
              </tr>
            </tbody>
          </table>
        </>
      },
    ]
  },
  {
    title: "DO YOU USE FLASH COOKIES OR LOCAL SHARED OBJECTS?",
    body: <>
      <p>Our Website may use Local Shared Objects (or Flash cookies) to store your preferences for media player volume or to provide interest-based content. Flash cookies are different from browser cookies because of the amount and type of data they store and how they store it. The cookie management tools provided by your web browser will not remove Flash cookies. To manage your Flash cookie settings, visit <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">Macromedia’s website</a>.</p>
    </>
  },
  {
    title: "DO YOU USE GOOGLE COOKIES?",
    body: <>
      <p>Google Analytics is a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies to help us analyze how users use the website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser. For more information about Google Analytics and how to opt out, please visit <a href="https://tools.google.com/dlpage/gaoptout">Google’s opt-out page</a>.</p>
    </>
  },
  {
    title: "HOW OFTEN WILL YOU UPDATE THIS COOKIE POLICY?",
    body: <>
      <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons.</p>
    </>
  }
];
