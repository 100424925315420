import React, { useEffect, useState } from 'react';
import { RadialClosedSVG, RadialOpenSVG } from '../../../../assets/icons';
import { DateField, InputField, NumberField } from '../../../ui/Fields';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import ImageItem from './ImageItem';
import AnswerItem from './AnswerItem';

const FormPage = (props) => {
  const { question, index, handleAnswer, input, preview, nextPage, backPage } = props;
  const [selected, setSelected] = useState(input[index]?.selected ? input[index].selected : null);

  // console.log('selected', selected);
  // console.log('input', input);

  useEffect(() => {
    if (input[index]?.selected) {
      setSelected(input[index]?.selected);
    }
  }, [input[index]?.selected]);

  // Reset selected state on page change
  useEffect(() => {
    setSelected(input[index]?.selected ? input[index].selected : null);
  }, [question]);

  const handleSelectImage = (obj) => {
    const { answerIndex, answer } = obj;
    // console.log('radio index', answerIndex);
    setSelected(answerIndex);
    setTimeout(() => {
      handleAnswer({
        answerIndex: answerIndex,
        selected: [answerIndex],
        answers: answer ? [answer] : [],
        // goNext:true
      });
      setSelected(null);
    }, 1);
  };

  const handleSelectRadio = (obj) => {
    const { answerIndex, answer } = obj;
    setSelected(answerIndex);
    setTimeout(() => {
      handleAnswer({
        answerIndex: answerIndex,
        selected: [answerIndex],
        answers: answer ? [answer] : [],
        // goNext:true
      });
      setSelected(null);
    }, 1);
  };

  const handleSelectTextfield = (obj) => {
    const { answerIndex, answer } = obj;
    handleAnswer({
      answerIndex: answerIndex,
      selected: selected,
      answers: answer ? [answer] : []
    });
  };

  const handleDatefield = (obj) => {
    const { answerIndex, answer } = obj;
    handleAnswer({
      answerIndex: answerIndex,
      selected: selected,
      answers: answer && answer !== "Invalid Date" ? [answer] : []
    });
  };

  const handleSelectCheckbox = (obj) => {
    const { answerIndex, answer } = obj;
    let newAnswers = [];
    let newSelected = [];

    // Check if answer is already selected
    if (!input[index]) {
      newAnswers = [answer];
      setSelected([answerIndex]);
    } else if (input[index].choices.includes(answer)) {
      newAnswers = input[index].choices.filter(x => x != answer);
      newSelected = selected.filter(x => x != answerIndex);
      // setSelected(prev => prev.filter(x => x.answerIndex != answerIndex));
    } else {
      newAnswers = [...new Set([...input[index].choices, answer])];
      newSelected = [...new Set([...selected, answerIndex])];
      // setSelected(prev => [ ...new Set([ ...prev, answerIndex ]) ]);
    }
    setTimeout(() => {
      handleAnswer({ answerIndex: answerIndex, selected: selected ? newSelected : [answerIndex], answers: answer ? newAnswers : [] });
    }, 1);
  };

  // const handleSelectDate = (e) => {
  //   handleAnswer({
  //     answerIndex: 0,
  //     selected: selected,
  //     answers: e ? [e] : []
  //   });
  // }


  return (
    question &&
    <Grid
      container
      style={{
        // height: 'calc(100vh - 60px)',
        width: '100%',
        // overflowY:'auto'
      }}
    >

      {/* <Grid
                  item
                  style={{
                    width:'100%',
                    background:'green'
                  }}
                  xs={6}
                > */}
      <Typography
        style={{
          fontSize: '1.25rem',
          fontWeight: 500,
          marginBottom: question.description ? 0 : 24
        }}
      >
        {question.text}
      </Typography>
      {question.description &&
        <Typography
          style={{
            fontSize: '1rem',
            marginBottom: 24
          }}
        >
          {question.description}
        </Typography>}


        {
        question.type === 'select-image' &&
          <Grid
            container
          >
            {question.choice_set.map((answer, answerIndex) => (
              <ImageItem
                key={answerIndex}
                type="radio"
                input={input}
                index={index}
                selected={(selected?.length ? selected[0] : selected) === answerIndex}
                answer={preview ? answer.blob : answer.file}
                answerIndex={answerIndex}
                onClick={() => handleSelectImage({ answerIndex: answerIndex, answer: answer.choice_text })}
              >
                <Typography>
                  {answer.choice_text}
                </Typography>
              </ImageItem>
            ))}
          </Grid>
      }

      {
        question.type === 'date' &&
          <Grid
            container
          >
            <DateField
              value={input[index]?.choices[0] ? new Date(input[index].choices[0]).toLocaleDateString('en-US') : ''}
              onChange={(e) => handleDatefield({ answerIndex: 0, answer: new Date(e).toLocaleDateString('en-US') })}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                label="Opinion Due Date"
                name="assignmentOpinionDueDate"
                value={input[index]?.choices[0] ? new Date(input[index].choices[0]) : ''}
                onChange={(e) => handleSelectTextfield({ answerIndex: 0, answer: new Date(e).toLocaleDateString('en-US') })}
              />
            </LocalizationProvider> */}
          </Grid>
      }

      {
        question.type === 'select' &&
          <Grid
            container
            style={{
              flexDirection: 'column',
            }}
          >
            {question.choice_set.map((answer, answerIndex) => (
              <AnswerItem
                key={answerIndex}
                input={input}
                index={index}
                selected={(selected?.length ? selected[0] : selected) === answerIndex}
                answer={answer.choice_text}
                answerIndex={answerIndex}
                onClick={() => handleSelectRadio({ answerIndex: answerIndex, answer: answer.choice_text })}
              >
                <Typography>
                  {answer.choice_text}
                </Typography>
              </AnswerItem>
            ))}
          </Grid>
      }

      {
        question.type === 'select-multiple' &&
          <Grid
            container
            style={{
              flexDirection: 'column',
            }}
          >
            {question.choice_set.map((answer, answerIndex) => (
              <AnswerItem
                key={answerIndex}
                type="multiple"
                input={input}
                index={index}
                selected={input[index]?.selected?.includes(answerIndex)}
                answer={answer.choice_text}
                answerIndex={answerIndex}
                onClick={() => handleSelectCheckbox({ answerIndex: answerIndex, answer: answer.choice_text })}
              >
                {/* {input[index]?.selected?.includes(answerIndex)
                  ? <RadialClosedSVG width={20} style={{ marginRight: 8 }} />
                  : <RadialOpenSVG width={20} color1="#ccc" style={{ marginRight: 8 }} />}
                <Typography>
                  {answer.choice_text}
                </Typography> */}
              </AnswerItem>
            ))}
          </Grid>
      }

      {
        question.type === 'integer' &&
          <NumberField
            autoFocus
            value={input[index]?.choices[0] ? input[index].choices[0] : ''}
            onChange={(e) => handleSelectTextfield({ answerIndex: 0, answer: Math.floor(e.target.value) })}
          />
      }

      {
        question.type === 'float' &&
          <NumberField
            autoFocus
            value={input[index]?.choices[0] ? input[index].choices[0] : ''}
            onChange={(e) => handleSelectTextfield({ answerIndex: 0, answer: e.target.value })}
          />
      }

      {
        question.type === 'short-text' &&
          <InputField
            autoFocus
            value={input[index]?.choices[0] ? input[index].choices[0] : ''}
            onChange={(e) => handleSelectTextfield({ answerIndex: 0, answer: e.target.value })}
          />
      }

      {
        question.type === 'text' &&
          <InputField
            placeholder="Details"
            value={input[index]?.choices[0] ? input[index].choices[0] : ''}
            onChange={(e) => handleSelectTextfield({ answerIndex: 0, answer: e.target.value })}
            multiline
            maxRows={4}
            marginAmount="8px 0"
          />
      }


      <Grid
        container
        style={{
          justifyContent: 'space-between'
        }}
      >
        <Button
          disabled={index === 0}
          onClick={backPage}
          style={{
            // fontSize:'1.25rem',
            marginTop: 24
          }}
        >
          {`< Back`}
        </Button>
        <Button
          disabled={!input[index]?.choices.length}
          onClick={nextPage}
          variant="contained"
          style={{
            // fontSize:'1.25rem',
            marginTop: 24
          }}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormPage;