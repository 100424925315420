import React, { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react';
// import { ColorCustomField, ColorTextField } from '../../lib/Styles';
import { Box, Button, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../context/authContext';
import { db } from '../../firebase';
// import * as Yup from 'yup';
// import { ErrorMessage, Field, Form, Formik } from 'formik';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { InputField } from '../ui/Fields';

const FeedbackForm = (props) => {
  const { handleClose } = props;
  const [feedback, setFeedback] = useState({
    email: '',
    emailMe: false,
    feedback: '',
    name: '',
    type: 'feedback'
  })

  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})

  useEffect(() => {
    if (authContext.account)
    setFeedback(prevFeedback => ({
      ...prevFeedback,
      name: `${authContext.account.firstName} ${authContext.account.lastName}`,
      email: authContext.user.email
    }))
  }, [authContext.account])

  // ===========================================================================
  // SEND FEEDBACK TO DATABASE
  // ===========================================================================

  const sendFeedback = async () => {
    try {
      const feedbackRef = collection(db, 'feedback');
      const feedbackPayload = {
        ...feedback,
        // Use the below when auth collection is possible
        // userId: authContext.account ? authContext.account.id : 'anonymous',
        // userFirst: authContext.account ? authContext.account.firstName : 'anonymous',
        // userLast: authContext.account ? authContext.account.lastName : 'anonymous',
        // userEmail: authContext.account ? authContext.user.email : 'anonymous',
        location: window.location.href || 'unknown location',
        browserName: browserName || 'unknown browser',
        browserVersion: browserVersion || 'unknown browser version',
        osName: osName || 'unknown OS',
        osVersion: osVersion || 'unknown OS version',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }

      const updateFeedback = await addDoc(feedbackRef, feedbackPayload, {merge:true});
      handleClose();

    } catch (error) {
      console.log('Error sending feedback', error);
    }

  }


  
  // ===========================================================================
  // FORMIK FUNCTIONS
  // ===========================================================================
  
  // const validationSchema = Yup.object().shape({
  //   // name: Yup.string().required('Enter your name'),
  //   // email: Yup.string().required('Enter your email'),
  //   feedback: Yup.string().required('Enter your feedback'),
  // })

  const onSubmit = (e) => {
    e.preventDefault();
    sendFeedback();
    console.log('Form has been submitted');
  }


  return (
    <form
      onSubmit={onSubmit}
      style={{
        width:'100%'
      }}
    >
      <InputField
        label="Name"
        margin
        value={feedback.name}
        onChange={ (e) => setFeedback({...feedback, name: e.target.value}) }
        placeholder={`Enter your name`}
        name="name"
        // color={theme.palette.brandPastel.eight}
        // sx={{
        //   mb:1
        // }}
      />

      <InputField
        label="Feedback"
        value={feedback.feedback}
        onChange={ (e) => setFeedback({...feedback, feedback: e.target.value}) }
        placeholder={`Enter your feedback`}
        name="feedback"
        multiline
        minRows={2}
        maxRows={6}
        sx={{
          mb:1
        }}
      />

      <Grid
        sx={{
          display:'flex',
          alignItems:'center',
          width:'100%',
          mt: feedback.emailMe ? 3 : 0,
          transition:'.5s'
        }}
      >
        {
          <div
            style={{
              width: feedback.emailMe ? '100%' : '0%',
              opacity: feedback.emailMe ? '100%' : '0%',
              transition:'.75s'
            }}
          >
            <InputField
              label="Email"
              margin
              value={feedback.email}
              onChange={ (e) => setFeedback({...feedback, email: e.target.value}) }
              placeholder={`Enter your email`}
              name="email"
              // color={theme.palette.brandPastel.eight}
              sx={{
                width: feedback.emailMe ? '100%' : '0%',
                transition:'.75s'
              }}
            />
          </div>
        }


        <FormControlLabel
          value={feedback.emailMe}
          control={
            <Checkbox
              checked={feedback.emailMe}
              onChange={(e) => setFeedback({...feedback, emailMe: e.target.checked})}
              disableRipple
              disableTouchRipple
              style={{
                // color:theme.palette.brand.nine,
                transform: "scale(1.5)",
              }}      
            />
          }
          label={
            <Collapse timeout={500} in={!feedback.emailMe} orientation="horizontal">
              <Typography sx={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
                {isMobile ? `Email me` : `Email me with updates to this feedback`}
              </Typography>
            </Collapse>
          }
          labelPlacement="end"
          sx={{
            ml:.5,
            // mt:3
          }}
        />
      </Grid>

      <Grid
        container
        columnGap={2}
        sx={{
          px:2,
          justifyContent:'center'
        }}
      >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // fullWidth
            sx={{
              px:4,
              py:2,
              mt:2
            }}
          >
            Submit Feedback
          </Button>
      </Grid>
    </form>
  )

  // // Formik and Yup version
  // return (
  //   <Formik
  //     initialValues={feedback}
  //     enableReinitialize
  //     validationSchema={validationSchema}
  //     onSubmit={onSubmit}
  //   >
  //     {({errors, touched}) => (
  //       <Form
  //         style={{
  //           width:'100%'
  //         }}
  //       >
  //         <ColorTextField
  //           formikField
  //           value={feedback.name}
  //           onChange={ (e) => setFeedback({...feedback, name: e.target.value}) }
  //           placeholder={`Enter your name`}
  //           name="name"
  //           color={theme.palette.brandPastel.eight}
  //           sx={{
  //             mb:1
  //           }}
  //         />

  //         <Grid
  //           sx={{
  //             display:'flex',
  //             alignItems:'center',
  //             width:'100%',
  //             mb:1
  //           }}
  //         >
  //           <ColorTextField
  //             formikField
  //             value={feedback.email}
  //             onChange={ (e) => setFeedback({...feedback, email: e.target.value}) }
  //             placeholder={`Enter your email`}
  //             name="email"
  //             color={theme.palette.brandPastel.eight}
  //             sx={{
  //               width: feedback.emailMe ? '100%' : '0%',
  //               transition:'.75s'
  //             }}
  //           />


  //           <FormControlLabel
  //             value={feedback.emailMe}
  //             control={
  //               <Checkbox
  //                 checked={feedback.emailMe}
  //                 onChange={(e) => setFeedback({...feedback, emailMe: e.target.checked})}
  //                 disableRipple
  //                 disableTouchRipple
  //                 style={{
  //                   color:theme.palette.brand.nine,
  //                   transform: "scale(1.5)",
  //                 }}      
  //               />
  //             }
  //             label={
  //               <Collapse timeout={500} in={!feedback.emailMe} orientation="horizontal">
  //                 <Typography sx={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
  //                   {isMobile ? `Email me` : `Email me with updates to this feedback`}
  //                 </Typography>
  //               </Collapse>
  //             }
  //             labelPlacement="end"
  //             sx={{
  //               ml:.5,
  //             }}
  //           />
  //         </Grid>


  //         <ColorTextField
  //           formikField
  //           value={feedback.feedback}
  //           onChange={ (e) => setFeedback({...feedback, feedback: e.target.value}) }
  //           placeholder={`Enter your feedback`}
  //           name="feedback"
  //           color={theme.palette.brandPastel.eight}
  //           sx={{
  //             mb:1
  //           }}
  //           fieldProps={{
  //             multiline:true,
  //             minRows:3,
  //             maxRows:8
  //           }}
  //         />
  //         <Grid
  //           container
  //           columnGap={2}
  //           sx={{
  //             px:2,
  //             justifyContent:'center'
  //           }}
  //         >
  //             <Button
  //               type="submit"
  //               variant="contained"
  //               color="secondary"
  //               // fullWidth
  //               sx={{
  //                 p:4,
  //                 mt:2
  //               }}
  //             >
  //               Submit Feedback
  //             </Button>
  //         </Grid>
  //       </Form>
  //     )}
  //   </Formik>
  // )
}

export default FeedbackForm