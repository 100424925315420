import React from 'react'
import { Tooltip } from '@mui/material'
import { useTheme } from '@emotion/react';

const CustomTooltip = (props) => {
  const { children, title } = props;
  const theme = useTheme();

  return (
    <Tooltip
      title={title}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            background: theme.palette.primary.main,
            px:2,
            py:1
          },
          "& .MuiTooltip-arrow": {
            color: theme.palette.primary.main,
          }
        }
      }}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip