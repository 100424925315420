import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Usage from '../components/account_settings/Usage'

const UsagePage = () => {

  return (
    // <MainPageTemplate background="#f8f8f8" title="Contacts">
    <Usage />
    // </MainPageTemplate>
  )
}

export default UsagePage