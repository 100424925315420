import React, { useEffect, useState } from 'react'
import { useGetEmailTemplateStorageFile } from '../hooks/mutations'
import Loading from '../components/ui/Loading'
import MainPageTemplate from '../templates/MainPageTemplate'
import EmailBuilder from '../components/email_builder/EmailBuilder'
import EmailTemplate from '../components/email_templates/EmailTemplate'
import { useParams } from 'react-router-dom'
import CreateEmailTemplate from '../components/email_templates/CreateEmailTemplate'

const EmailTemplatePage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { reactTemplateId } = useParams();
  const [emailTemplateId, setEmailTemplateId] = useState(currentEnv === "STAGING" ? (reactTemplateId ? reactTemplateId : '') : null);
  const [reload, setReload] = useState(false);

  // Get the script element by its id
  const scriptElement = document.getElementById('django-edit-email-template');

  if (scriptElement && !emailTemplateId) {
    // Access the content of the script element
    const djangoContactString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoEmailTemplate = JSON.parse(djangoContactString);

    // Set the contact id state from the JSON data
    setEmailTemplateId(djangoEmailTemplate.id)
  }

  // This hook should make 3 api calls to get the template, templateStorageFile, and finally the file itself
  // It currently cannot get the file from the response because it needs to use 'no-cors' mode
  const emailTemplate = useGetEmailTemplateStorageFile(emailTemplateId);
  console.log('emailTemplate', emailTemplate)
  
  // Reset 'reload' state
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])

  return (
    // <EmailTemplate />
    emailTemplate !== undefined
      ?
        <EmailTemplate
          emailTemplate={emailTemplate?.data ? emailTemplate.data : undefined}
          template={emailTemplate?.template ? emailTemplate.template : undefined}
          storageFile={emailTemplate?.storageFile ? emailTemplate.storageFile : undefined}
          setReload={setReload}
        />
      : <Loading />
  )
}

export default EmailTemplatePage