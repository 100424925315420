import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import { useGetAllCampaigns, useGetAllCampaignsByStatus, useGetAllSenderDetails } from '../hooks/mutations'
import Loading from '../components/ui/Loading'
import CampaignStatus from '../components/campaigns/CampaignStatus'
import { useTheme } from '@emotion/react'
import SenderDetailsAlert from '../components/account_settings/SenderDetailsAlert'

const statusValues = {
  "1": "Sent",
  "2": "Scheduled",
  "3": "Draft",
  "4": "Queued",
  "5": "Delivering",
  "6": "Paused"
}

const CampaignStatusPage = () => {
  const [reload, setReload] = useState();
  const campaigns = useGetAllCampaigns();
  const status_1 = useGetAllCampaignsByStatus(1);
  const status_2 = useGetAllCampaignsByStatus(2);
  const status_3 = useGetAllCampaignsByStatus(3);
  const status_4 = useGetAllCampaignsByStatus(4);
  const status_5 = useGetAllCampaignsByStatus(5);
  const status_6 = useGetAllCampaignsByStatus(6);
  const senderDetails = useGetAllSenderDetails(reload);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])

  return (
    // <MainPageTemplate background="#f8f8f8" title="Contacts">
    senderDetails?.[0]?.is_validated // Display if details are validated
      ? 
        <CampaignStatus
          campaigns={campaigns}
          status_1={status_1}
          status_2={status_2}
          status_3={status_3}
          status_4={status_4}
          status_5={status_5}
          status_6={status_6}
        />
      :
        senderDetails?.[0]?.is_validated === false || senderDetails?.length === 0 // Display if details have not been returned yet and are undefined
          ? <SenderDetailsAlert />
          : <Loading />  // Display if details have not been returned yet and are undefined
    // </MainPageTemplate>
  )
}

export default CampaignStatusPage
