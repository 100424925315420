import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/authContext';
import axios from 'axios';
import { TempProvider } from './context/tempContext';
import { ThemeProvider } from '@emotion/react';
import { ApiProvider } from './context/apiContext';
// import { Provider } from 'react-redux';
// import { store } from './redux/store';
import { theme } from './theme';
import { responsiveFontTheme  } from './theme';
import ContactCreatePage from './pages/ContactCreatePage';
import CustomFieldsPage from './pages/CustomFieldsPage';
import SurveysPage from './pages/SurveysPage';
import CRMPage from './pages/CRMPage';
import CsvUploadPage from './pages/CsvUploadPage';
import ContactEditPage from './pages/ContactEditPage';
import LandingPage from './pages/LandingPage';
import TagSingle from './singles/TagSingle';
import FeedbackPopout from './components/feedback/FeedbackPopout';
import FeedbackPanelPage from './pages/FeedbackPanelPage';
import EmailTemplatesPage from './pages/EmailTemplatesPage';
import EmailBuilderPage from './pages/EmailBuilderPage';
import FormsPage from './pages/FormsPage';
import FormsEditPage from './pages/FormsEditPage';
import SignupPage from './pages/SignupPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import FormsSubscribePage from './pages/FormsSubscribePage';
import FormsSubscribeContainerPage from './pages/FormsSubscribeContainerPage';
import SurveyEditPage from './pages/SurveyEditPage';
import CampaignStatusPage from './pages/CampaignStatusPage';
import SettingsDrawerPage from './pages/SettingsDrawerPage';
import BadgeSingle from './singles/BadgeSingle';
import SideMenuPage from './pages/SideMenuPage';
import SignupRegisterPage from './pages/SignupRegisterPage';
import SignupLoginPage from './pages/SignupLoginPage';
import SignupTenantPage from './pages/SignupTenantPage';
import SignupSubscribePage from './pages/SignupSubscribePage';
import CsvUploadQueuedPage from './pages/CsvUploadQueuedPage';
import SurveyAnalyticsPage from './pages/SurveyAnalyticsPage';
import SurveyTakePage from './pages/SurveyTakePage';
import SurveyShareContainerPage from './pages/SurveyShareContainerPage';
import LandingFeaturesPage from './pages/LandingFeaturesPage';
import LandingPricingPage from './pages/LandingPricingPage';
import LandingHomePage from './pages/LandingHomePage';
import LandingContactPage from './pages/LandingContactPage';
import SenderDetailsPage from './pages/SenderDetailsPage';
import SubuserManagementPage from './pages/SubuserManagementPage';
import ContactDetailsPage from './pages/ContactDetailsPage';
import SenderDetailsWizardPage from './pages/SenderDetailsWizardPage';
import ContactCRMPage from './pages/ContactCRMPage';
import CsvImportListPage from './pages/CsvImportListPage';
import UsagePage from './pages/UsagePage';
import ContactsBulkTagsPage from './pages/ContactsBulkTagsPage';
import TagsListPage from './pages/TagsListPage';
import LandingProductPage from './pages/LandingProductPage';
import LandingWhyUsPage from './pages/LandingWhyUsPage';
import IndustryDetailPage from './pages/IndustryDetailPage';
import TermsPage from './pages/TermsPage';
import TermsPrivacyPage from './pages/TermsPrivacyPage';
import TermsServicePage from './pages/TermsServicePage';
import TermsCookiesPage from './pages/TermsCookiesPage';
import { createPortal } from 'react-dom';
import PaymentPortalPage from './pages/PaymentPortalPage';
import EmailTemplatePage from './pages/EmailTemplatePage';

// // replace console.* for disable log on production
// if (process.env.REACT_APP_CURRENT_ENV === 'LIVE') {
  // console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}
// }

// Toggle between responsive and non-responsive font theme
const selectedTheme = theme;

axios.defaults.withCredentials = true; // even for get requests if
// demand session authentication
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'

// ALL REACT PAGES (FOR TESTING)
const rootElement = document.getElementById('root');
if (rootElement != null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            {/* <Provider store={store}> */}
              <ApiProvider>
                <TempProvider>
                  <ThemeProvider theme={selectedTheme}>
                    <App />
                  </ThemeProvider>
                </TempProvider>
              </ApiProvider>
            {/* </Provider> */}
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    // </StrictMode>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const reactRootElement = document.getElementById('react_root');
  if (reactRootElement != null) {
    const reactRoot = ReactDOM.createRoot(reactRootElement);
    reactRoot.render(
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            {/* <Provider store={store}> */}
              <ApiProvider>
                <TempProvider>
                  <ThemeProvider theme={selectedTheme}>
                    {document.getElementById('landing_root') &&
                      createPortal(<LandingHomePage />, document.getElementById('landing_root'))
                    }
                    {document.getElementById('landing_why_root') &&
                      createPortal(<LandingWhyUsPage />, document.getElementById('landing_why_root'))
                    }
                    {document.getElementById('landing_features_root') &&
                      createPortal(<LandingFeaturesPage />, document.getElementById('landing_features_root'))
                    }
                    {document.getElementById('landing_product_root') &&
                      createPortal(<LandingProductPage />, document.getElementById('landing_product_root'))
                    }
                    {document.getElementById('landing_pricing_root') &&
                      createPortal(<LandingPricingPage />, document.getElementById('landing_pricing_root'))
                    }
                    {document.getElementById('landing_contact_root') &&
                      createPortal(<LandingContactPage />, document.getElementById('landing_contact_root'))
                    }
                    {document.getElementById('email_verification_root') &&
                      createPortal(<EmailVerificationPage />, document.getElementById('email_verification_root'))
                    }
                    {document.getElementById('add_contact_root') &&
                      createPortal(<ContactCreatePage />, document.getElementById('add_contact_root'))
                    }
                    {document.getElementById('contact_details_root') &&
                      createPortal(<ContactDetailsPage />, document.getElementById('contact_details_root'))
                    }
                    {document.getElementById('edit_contact_root') &&
                      createPortal(<ContactEditPage />, document.getElementById('edit_contact_root'))
                    }
                    {document.getElementById('custom_field_root') &&
                      createPortal(<CustomFieldsPage />, document.getElementById('custom_field_root'))
                    }
                    {document.getElementById('email_template_list_root') &&
                      createPortal(<EmailTemplatesPage />, document.getElementById('email_template_list_root'))
                    }
                    {document.getElementById('edit_email_template_root') &&
                      createPortal(<EmailTemplatePage />, document.getElementById('edit_email_template_root'))
                    }
                    {document.getElementById('surveys_list_root') &&
                      createPortal(<SurveysPage />, document.getElementById('surveys_list_root'))
                    }
                    {document.getElementById('survey_edit_root') &&
                      createPortal(<SurveyEditPage />, document.getElementById('survey_edit_root'))
                    }
                    {document.getElementById('survey_analytics_root') &&
                      createPortal(<SurveyAnalyticsPage />, document.getElementById('survey_analytics_root'))
                    }
                    {document.getElementById('survey_take_root') &&
                      createPortal(<SurveyTakePage />, document.getElementById('survey_take_root'))
                    }
                    {document.getElementById('survey_share_container_page_root') &&
                      createPortal(<SurveyShareContainerPage />, document.getElementById('survey_share_container_page_root'))
                    }
                    {document.getElementById('forms_root') &&
                      createPortal(<FormsPage />, document.getElementById('forms_root'))
                    }
                    {document.getElementById('forms_create_root') &&
                      createPortal(<FormsEditPage />, document.getElementById('forms_create_root'))
                    }
                    {document.getElementById('forms_edit_root') &&
                      createPortal(<FormsEditPage />, document.getElementById('forms_edit_root'))
                    }
                    {document.getElementById('forms_subscribe_root') &&
                      createPortal(<FormsSubscribePage />, document.getElementById('forms_subscribe_root'))
                    }
                    {document.getElementById('forms_subscribe_page_root') &&
                      createPortal(<FormsSubscribeContainerPage />, document.getElementById('forms_subscribe_page_root'))
                    }
                    {document.getElementById('crm_root') &&
                      createPortal(<CRMPage />, document.getElementById('crm_root'))
                    }
                    {document.getElementById('contact_crm_root') &&
                      createPortal(<ContactCRMPage />, document.getElementById('contact_crm_root'))
                    }
                    {document.getElementById('csv_upload_root') &&
                      createPortal(<CsvUploadPage />, document.getElementById('csv_upload_root'))
                    }
                    {document.getElementById('csv_queued_root') &&
                      createPortal(<CsvUploadQueuedPage />, document.getElementById('csv_queued_root'))
                    }
                    {document.getElementById('csv_imports_root') &&
                      createPortal(<CsvImportListPage />, document.getElementById('csv_imports_root'))
                    }
                    {document.getElementById('signup_root') &&
                      createPortal(<SignupPage />, document.getElementById('signup_root'))
                    }
                    {document.getElementById('register_root') &&
                      createPortal(<SignupRegisterPage />, document.getElementById('register_root'))
                    }
                    {document.getElementById('login_root') &&
                      createPortal(<SignupLoginPage />, document.getElementById('login_root'))
                    }
                    {document.getElementById('tenant_root') &&
                      createPortal(<SignupTenantPage />, document.getElementById('tenant_root'))
                    }
                    {document.getElementById('details_root') &&
                      createPortal(<SignupTenantPage />, document.getElementById('details_root'))
                    }
                    {document.getElementById('subscribe_root') &&
                      createPortal(<SignupSubscribePage />, document.getElementById('subscribe_root'))
                    }
                    {document.getElementById('feedback_root') &&
                      createPortal(<FeedbackPopout />, document.getElementById('feedback_root'))
                    }
                    {document.getElementById('feedback_panel_root') &&
                      createPortal(<FeedbackPanelPage />, document.getElementById('feedback_panel_root'))
                    }
                    {document.getElementById('settings_button_root') &&
                      createPortal(<SettingsDrawerPage />, document.getElementById('settings_button_root'))
                    }
                    {document.getElementById('campaign_status_root') &&
                      createPortal(<CampaignStatusPage />, document.getElementById('campaign_status_root'))
                    }
                    {document.getElementById('sender_details_root') &&
                      createPortal(<SenderDetailsWizardPage />, document.getElementById('sender_details_root'))
                    }
                    {document.getElementById('user_management_root') &&
                      createPortal(<SubuserManagementPage />, document.getElementById('user_management_root'))
                    }
                    {document.getElementById('usage_root') &&
                      createPortal(<UsagePage />, document.getElementById('usage_root'))
                    }
                    {document.getElementById('payment_portal_root') &&
                      createPortal(<PaymentPortalPage />, document.getElementById('payment_portal_root'))
                    }
                    {document.getElementById('tags_list_root') &&
                      createPortal(<TagsListPage />, document.getElementById('tags_list_root'))
                    }
                    {document.getElementById('bulk_tags_root') &&
                      createPortal(<ContactsBulkTagsPage />, document.getElementById('bulk_tags_root'))
                    }
                    {document.getElementById('industry_details_root') &&
                      createPortal(<IndustryDetailPage />, document.getElementById('industry_details_root'))
                    }
                    {document.getElementById('terms_root') &&
                      createPortal(<TermsPage />, document.getElementById('terms_root'))
                    }
                    {document.getElementById('terms_privacy_root') &&
                      createPortal(<TermsPrivacyPage />, document.getElementById('terms_privacy_root'))
                    }
                    {document.getElementById('terms_service_root') &&
                      createPortal(<TermsServicePage />, document.getElementById('terms_service_root'))
                    }
                    {document.getElementById('terms_cookies_root') &&
                      createPortal(<TermsCookiesPage />, document.getElementById('terms_cookies_root'))
                    }
                    {document.getElementById('side_menu_root') &&
                      createPortal(<SideMenuPage />, document.getElementById('side_menu_root'))
                    }
                    {document.getElementById('tag_single') &&
                      createPortal(<TagSingle />, document.getElementById('tag_single'))
                    }
                    {document.getElementById('badge_single') &&
                      createPortal(<BadgeSingle />, document.getElementById('badge_single'))
                    }
                  </ThemeProvider>
                </TempProvider>
              </ApiProvider>
            {/* </Provider> */}
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    );
  } else {
    console.error('React root element not found in the DOM.');
  }
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ROOT LOG FOR EASY DEFINITION
// root: Main App
// landing_root: Main Landing Page
// add_contact_root: To create a new contact (used in subscriber_form)
// edit_contact_root: To edit a contact (used in subscriber_details)
// custom_field_root:
// surveys_root:
// crm_root:
// csv_upload_root:
// signup_root: 