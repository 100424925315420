import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import SideMenu from '../components/menu/SideMenu'

const SideMenuPage = () => {
  return (
    // <MainPageTemplate title="CRM">
      <SideMenu />
    // </MainPageTemplate>
  )
}

export default SideMenuPage