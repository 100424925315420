import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button, Divider, Grid, IconButton, LinearProgress, Stack, Typography, formGroupClasses } from '@mui/material';
import { useGetSurveyResponses, useGetSurveyResponse, useGetSurvey } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG, CheckSVG, SelectSVG, RemoveFillSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { formColumns } from '../../lib/Data';
import { Link } from 'react-router-dom';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import CustomTooltip from '../ui/CustomTooltip';
import ReusableModal from '../ui/ReusableModal';
import { mockData } from '../../lib/mockData';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { Dialog } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { fill_mui_scrollbar } from '../../lib/Styles';

const SurveyResponses = (props) => {
  const { surveyId, incomingSurveyResponses, handlePaginationModelChange, paginationModel, count } = props;
  const [snackMessage, setSnackMessage] = useState("");
  const incomingSurveyObject = useGetSurvey(surveyId);
  const [individualResponse, setIndividualResponse] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState({
    current: null,
    prev: null,
    next: null,
    isFirst: false,
    isLast: false
  })
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  // const [reloadSurveyResponses, setReloadSurveyResponses] = useState(true);
  // const { reactSurveyId } = useParams();
  // const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  // const [surveyId, setSurveyId] = useState(currentEnv === "STAGING" ? (reactSurveyId ? reactSurveyId : 5) : null);
  // const incomingSurveyResponses = useGetSurveyResponses(surveyId, reloadSurveyResponses);
  // const [responses, setResponses] = useState(incomingSurveyResponses ? incomingSurveyResponses : null);
  // const incomingIndividualSurveyResponse = useGetSurveyResponse(surveyId, responseId);

  console.log("all responses:", incomingSurveyResponses)
  // console.log("responses:", responses)
  // console.log("single response:", incomingIndividualSurveyResponse)


  const selectResponse = (clickedResponseId) => {
    console.log("changing response id", clickedResponseId);
    // console.log("responses:", responses);
    console.log('find by id', findResponseById(incomingSurveyResponses, clickedResponseId));
    setIndividualResponse(findResponseById(incomingSurveyResponses, clickedResponseId));
  }

  const findResponseById = (data, inputId) => {
    for (let response of data) {
      if (response.id === inputId) {
        console.log(response);
        return response;
      }
    }
    return null;
  }

  const handleSelectedResponse = (response) => {
    console.log('response', response);
    const currentIndex = incomingSurveyResponses.findIndex(x => x.id === response.id);
    const nextResponse = incomingSurveyResponses[currentIndex + 1];
    const prevResponse = incomingSurveyResponses[currentIndex - 1];
    const isLastResponse = incomingSurveyResponses.length -1 == currentIndex;
    const isFirstResponse = currentIndex == 0;
    
    setSelectedResponse({
      current: response,
      prev: prevResponse,
      next: nextResponse,
      isFirst: isFirstResponse,
      isLast: isLastResponse
    })
  }
  
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  }

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedResponse({
      current: null,
      prev: null,
      next: null,
      isFirst: false,
      isLast: false
    })
  }

  const dataColumns = [
    {
      field: 'responseId',
      headerName: 'Response ID',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleSelectedResponse(params.row);
              setOpen(params.row.id);
            }}
          >
            {params.row.id}
          </p>

          <Dialog
            open={open === params.row.id}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth={"md"}
            PaperProps={{
              style: {
                padding: 24,
              }
            }}
          >
            <Grid
              container
              style={{
                justifyContent:'space-between',
                marginBottom:8
              }}
            >
              <Button
                disabled={selectedResponse.isFirst}
                onClick={() => { handleSelectedResponse(selectedResponse.prev)}}
              >
                {
                  selectedResponse.isFirst
                    ? 'First response of current page'
                    : '< Last'
                }
              </Button>

              <Typography
                style={{
                  fontSize:'1.3rem'
                }}
              >
                {}
              </Typography>
              
              <Button
                disabled={selectedResponse.isLast}
                onClick={() => { handleSelectedResponse(selectedResponse.next) }}
              >
                {
                  selectedResponse.isLast
                    ? 'Last response of current page'
                    : 'Next >'
                }
              </Button>
            </Grid>
            
            <QuestionResponseTable 
              individualResponse={selectedResponse.current}  // {individualResponse}
              incomingSurveyObject={incomingSurveyObject}
              // responseId={responseId} 
              />
            {/* Respondent's information (name, email, etc) */}
          </Dialog>

        </>
      )
    },
    {
      field: 'timestamp',
      headerName: 'Submitted On',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params, index) => (
        <>
        <p>{formatTimestamp(params.row.created)}</p>
        {/* <p>{params.getRowIndexRelativeToVisibleRows()}</p> */}
        </>
      )
    }
  ]
  

  return (
    <>
      {/* <h1>Survey {surveyId} Responses</h1> */}
      <Grid
        container
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
      <Grid
        style={{
          borderRadius:8,
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
        }}
      >
        <Grid
          container
          style={{
            // padding:16,
            // marginBottom:16,
            display:'flex',
            justifyContent:'right'
          }}
        >
          {/* <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth={"md"}
            PaperProps={{
              style: {
                padding: 24,
              }
            }}
          >
            <Typography
              style={{
                fontSize:'1.3rem'
              }}
            >
              Response Detail
            </Typography>
            
            <QuestionResponseTable 
              individualResponse={selectedResponse.current}  // {individualResponse}
              incomingSurveyObject={incomingSurveyObject}
              // responseId={responseId} 
              />
          </Dialog> */}
        </Grid>

        {
          incomingSurveyResponses?.length
          // mockResponses.length
            ?
              <DataGrid
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                rowCount={count}
                getRowClassName={(params) => {
                  return params.row?.id === selectedResponse.current?.id ? 'selected-row' : '';
                }}
                // pageSizeOptions={[5, 10, 15, 20]}
                getRowId={(row) => row.id} 
                rows={incomingSurveyResponses?.length ? incomingSurveyResponses : []}
                // rows={mockResponses}
                columns={dataColumns}
                density="standard"
                disableVirtualization
                // onRowSelectionModelChange={(ids) => {
                //   handleRowSelection(ids);
                // }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                }}
                sx={{
                  '.selected-row': {
                    background:`${theme.palette.primary.light}45`
                  },
                  '&.MuiDataGrid-root':{
                    // borderBottom:'none',
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                      // borderBottom:`2px solid ${theme.palette.primary.main}`,
                      // background:`${theme.palette.primary.light}15`
                    }
                  },
                  ...fill_mui_scrollbar
                }}
                // checkboxSelection
                disableRowSelectionOnClick
                // autoHeight
              />
            :
              <Loading />
        }

      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
    </>
  )
}

export default SurveyResponses

const QuestionResponseTable = (props) => {
  const { individualResponse, incomingSurveyObject } = props;

  // console.log("response object", individualResponse);
  // console.log("survey object", incomingSurveyObject);
  // console.log(responseId);

  return (
    individualResponse &&
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Question</TableCell>
              <TableCell sx={{ width: '50%' }}>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {individualResponse.answers.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{incomingSurveyObject.category_set[0].question_set[index].text}</TableCell>
                <TableCell>{row.body}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <p>Response ID: {individualResponse.id}</p> */}
    </>
  );
}