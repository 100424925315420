import React, { useState } from 'react'
import { Collapse, Grid, IconButton, Typography } from '@mui/material'
import { useScreenSize } from '../../lib/Interface'
import { useTheme } from '@emotion/react';
import { pricingCopy } from '../../lib/Copy';
import { TagSimple } from '../ui/Tags';
import { UpCaretSVG } from '../../assets/icons';
import PricingAdditionalEmailsCalculator from '../signup/PricingAdditionalEmailsCalculator';


const PricingAddons = () => {
  const screenSize = useScreenSize();
  const theme = useTheme();
  const [expandPro, setExpandPro] = useState(true);
  const [expandCon, setExpandCon] = useState(true);

  return (
    <Grid
      container
      id="addons"
      variant="center"
      style={{
        margin:'96px 16px'
      }}
    >
      <Grid
        container
        style={{
          maxWidth: 1400,
          zIndex: 1
        }}
      >
        <Grid
          container
          style={{
            padding: 16,
            marginBottom: 10,
            justifyContent: 'center'
          }}
        >
          <Grid
            variant="center"
            flexDirection="column"
          >

            <Typography variant="h4">
            <span style={{fontWeight:700}}>LEARN MORE</span> about our addons
            </Typography>
            <Typography
              style={{
                fontSize:'1.1rem'
              }}
            >
              Addons are items that some customers may need in addition to our core features.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          variant="space-between"
          flexDirection="column"
          style={{
            background:'#fff',
            borderRadius:'16px',
            padding: screenSize.xs ? 18 : 40,
            boxShadow:'0 0 5px #00000020',
            height:'100%',
            width:'100%'
          }}
        >

          <Grid
            container
              id="addon-emails"
          >
            <Grid
              container
              style={{
                marginTop:64,
                marginLeft: screenSize.xs ? -10 : -60,
                width: screenSize.xs ? 'calc(100% + 46px)' : 'calc(100% + 110px)',
                background: `${theme.palette.tertiary.light}90`, //'#FFF',
                border:`3px solid ${theme.palette.tertiary.main}`,
                borderRadius: screenSize.xs ? '200px 200px 0 0' : '200px 0 0 200px',
                boxShadow:'2px 2px 5px #00000030'
              }}
            >
              <Grid
                item
                variant="center"
                style={{
                  // maxWidth: screenSize.xs ? null : 150,
                  display:'flex',
                  justifyContent:screenSize.xs ? 'center' : 'flex-start',
                  padding:screenSize.xs ? 8 : 16,
                }}
                xs={12}
                sm="auto"
              >
                <img
                  src="https://static.fillmarketing.com/static/static/img/email-love_500px.png"
                  style={{
                    height:150,
                    marginTop: -70,
                    objectFit:'contain',
                    filter:'drop-shadow(-4px -4px 0 #fff) drop-shadow(-4px 0px 0 #fff)',

                  }}
                />
              </Grid>
              <Grid
                item
                style={{
                  display:'flex',
                  alignItems:'center',
                  padding:16,
                }}
                xs
              >
                <Typography
                  style={{
                    fontSize:'1.8rem',
                    textAlign: screenSize.xs ? 'center' : 'inherit',
                    lineHeight:1
                  }}
                >
                  <span style={{fontWeight:600}}>Additional Emails</span> every month
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop:24
              }}
            >
              <PricingAdditionalEmailsCalculator />
            </Grid>
          </Grid>

          <Grid
            container
            id="addon-dedicated-ip"
          >
            <Grid
              container
              style={{
                marginTop:120,
                marginLeft: screenSize.xs ? -10 : -60,
                width: screenSize.xs ? 'calc(100% + 46px)' : 'calc(100% + 110px)',
                background: `${theme.palette.tertiary.light}90`, //'#FFF',
                border:`3px solid ${theme.palette.tertiary.main}`,
                borderRadius: screenSize.xs ? '200px 200px 0 0' : '200px 0 0 200px',
                boxShadow:'2px 2px 5px #00000030'
              }}
            >
              <Grid
                item
                variant="center"
                style={{
                  // maxWidth: screenSize.xs ? null : 150,
                  display:'flex',
                  justifyContent:screenSize.xs ? 'center' : 'flex-start',
                  padding:screenSize.xs ? 8 : 16,
                }}
                xs={12}
                sm="auto"
              >
                <img
                  src="https://static.fillmarketing.com/static/static/img/dedicated-ip_500px.png"
                  style={{
                    height: 200,
                    marginTop: -120,
                    objectFit:'contain',
                    filter:'drop-shadow(-4px -4px 0 #fff) drop-shadow(-4px 0px 0 #fff)',

                  }}
                />
              </Grid>
              <Grid
                item
                style={{
                  padding:16,
                  display:'flex',
                  alignItems:'center',
                }}
                xs
              >
                <Typography
                  style={{
                    fontSize:'1.8rem',
                    textAlign: screenSize.xs ? 'center' : 'inherit',
                    lineHeight:1
                  }}
                >
                  <span style={{fontWeight:600}}>Dedicated IP</span> for better email deliverability
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
            >
              <Grid
                container
                onClick={() => setExpandPro(prev => !prev)}
                style={{
                  justifyContent:'space-between',
                  alignItems:'center',
                  marginTop:16,
                  cursor:'pointer'
                }}
              >
                <Grid
                  item
                  xs
                >
                  <Typography
                    style={{
                      fontSize:'1.5rem'
                    }}
                  >
                    {screenSize.xs ? 'Pros' : screenSize.sm ? 'Pros of a Dedicated IP' : 'Pros of using a Dedicated IP for sending emails'} 
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    width:42
                  }}
                >
                  <IconButton
                  >
                    <UpCaretSVG width={26} color1="#777" style={{transform: expandPro ? 'rotate(0deg)' : 'rotate(180deg)', transition:'.4s'}} />
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expandPro}>
                <Grid container>
                  {
                    pricingCopy.dedicated_ip.pros.map(pro => (
                      <Grid
                        item
                        style={{
                          padding:8,
                        }}
                        xs={12}
                        // md={6}
                      >
                        <Grid
                          style={{
                            padding:'8px 16px',
                            background:'#f1f8fa',
                            height:'100%',
                            borderRadius:16
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight:600
                            }}
                          >
                            {pro.title}
                          </Typography>
                          <Typography
                          >
                            {pro.body}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                  }
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid
              container
            >
              <Grid
                container
                onClick={() => setExpandCon(prev => !prev)}
                style={{
                  justifyContent:'space-between',
                  alignItems:'center',
                  marginTop:16,
                  cursor:'pointer'
                }}
              >
                <Typography
                  style={{
                    fontSize:'1.5rem'
                  }}
                >
                  {screenSize.xs ? 'Cons' : screenSize.sm ? 'Cons of a Dedicated IP' : 'Cons of using a Dedicated IP for sending emails'} 
                </Typography>
                <IconButton
                >
                  <UpCaretSVG width={26} color1="#777" style={{transform: expandCon ? 'rotate(0deg)' : 'rotate(180deg)', transition:'.4s'}} />
                </IconButton>
              </Grid>

              <Collapse in={expandCon}>
                <Grid container>
                  {
                    pricingCopy.dedicated_ip.cons.map(con => (
                      <Grid
                        item
                        style={{
                          padding:8,
                        }}
                        xs={12}
                        // md={6}
                      >
                        <Grid
                          container
                          style={{
                            flexDirection:'column',
                            alignItems:'flex-start',
                            padding:'8px 16px',
                            background:'#f1f8fa',
                            height:'100%',
                            borderRadius:16
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight:600
                            }}
                          >
                            {con.title}
                          </Typography>
                          <Typography
                          >
                            {con.body}
                          </Typography>
                          {
                            con.responsibility === 'fill'
                              ?
                                <Grid
                                  style={{
                                    margin:'8px 0'
                                  }}
                                >
                                  <TagSimple
                                    name="WE DO THIS FOR YOU"
                                    color={{
                                      background: theme.palette.primary.main
                                    }}
                                  />
                                </Grid>
                              :
                                <div style={{height:20}} />
                          }
                        </Grid>
                      </Grid>
                    ))
                  }
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PricingAddons