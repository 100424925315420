import React, { useRef, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './ElementRTEditor.css';
import { LinkSVG, RedoSVG, UndoSVG } from '../../assets/icons';
import { Popper } from '@mui/material';

const ElementRTEditor = ({ handleEditorChange, elementId, initialContent, focused, preview }) => {
  const editorRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState(initialContent || '');
  const [showHtml, setShowHtml] = useState(false);
  const [activeFormat, setActiveFormat] = useState({
    bold: false,
    italic: false,
    underline: false,
    strikeThrough: false,
  });

  // Initialize editorRef with incoming content
  useEffect(() => {
    if (editorRef.current) {
      // Set initial content to the editor
      editorRef.current.innerHTML = DOMPurify.sanitize(initialContent); // Sanitize and set content
    }
  }, [editorRef, initialContent]);

  // Function to handle keyboard shortcuts like Ctrl+B, Ctrl+I
  const handleKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey) {
      switch (e.key) {
        case 'b':
          e.preventDefault();
          formatText('bold');
          break;
        case 'i':
          e.preventDefault();
          formatText('italic');
          break;
        case 'u':
          e.preventDefault();
          formatText('underline');
          break;
        case 'z':
          e.preventDefault();
          document.execCommand('undo');
          break;
        case 'y':
          e.preventDefault();
          document.execCommand('redo');
          break;
        default:
          break;
      }
    }
  };

  // Function to format the content (bold, italic, etc.)
  const formatText = (command) => {
    document.execCommand(command, false, null);
    updateHtmlContent(); // Update the HTML content
  };

  // Function to insert a link or image
  const insertElement = (elementType) => {
    let value;
    if (elementType === 'link') {
      value = prompt('Enter the URL for the link:', 'http://');
      if (value) {
        document.execCommand('createLink', false, value);
      }
    } else if (elementType === 'image') {
      value = prompt('Enter the image URL:', 'http://');
      if (value) {
        const imageHTML = `<img src="${value}" alt="Image" style="max-width: 100%;" />`;
        document.execCommand('insertHTML', false, imageHTML);
      }
    }
    updateHtmlContent(); // Update the HTML content
  };

  const insertList = (listType) => {
    document.execCommand(listType === 'ordered' ? 'insertOrderedList' : 'insertUnorderedList');
    updateHtmlContent(); // Update the HTML content
  };

  // Update the HTML content whenever the editor content changes
  const updateHtmlContent = () => {
    const currentContent = editorRef.current.innerHTML;
    setHtmlContent(currentContent);
    handleEditorChange(currentContent); // Pass the updated HTML content to the parent
    updateActiveFormats(); // Check active formats
  };

  const updateActiveFormats = () => {
    setActiveFormat({
      bold: document.queryCommandState('bold'),
      italic: document.queryCommandState('italic'),
      underline: document.queryCommandState('underline'),
      strikeThrough: document.queryCommandState('strikeThrough'),
    });
  };

  // Attach event listener for keydown when the component is mounted
  useEffect(() => {
    const editor = editorRef.current;
    editor.addEventListener('keydown', handleKeyDown);
    editor.addEventListener('input', updateHtmlContent);

    return () => {
      editor.removeEventListener('keydown', handleKeyDown);
      editor.removeEventListener('input', updateHtmlContent);
    };
  }, []);

  // Sync the editor's content with the htmlContent state without moving the cursor
  useEffect(() => {
    if (editorRef.current && editorRef.current.innerHTML !== htmlContent) {
      try {
        const sanitizedContent = DOMPurify.sanitize(htmlContent); // Sanitize the content
        const selection = window.getSelection();
        console.log('selection', selection);
        const range = selection.getRangeAt(0);
        const cursorPosition = range.startOffset;

        editorRef.current.innerHTML = sanitizedContent; // Set sanitized innerHTML

        // Restore cursor position
        if (cursorPosition <= editorRef.current.childNodes.length) {
          range.setStart(editorRef.current.childNodes[0], cursorPosition);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }

      catch (error) {
        console.log('Error syncing htmlContent state with editor content', error);
      }
    }
  }, [htmlContent]);

  // Update active formats on mount
  useEffect(() => {
    updateActiveFormats();
  }, []);

  // Toolbar menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (focused) {
      setAnchorEl(document.getElementById('editor-ref'));
    } else (
      setAnchorEl(null)
    )

  }, [focused])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className="rich-text-editor">
      {
        !preview &&
        <Popper
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={document.getElementById(`editor-ref-${elementId}`)}
          open={focused}
          onClose={handleClose}
          placement="top-start"
        >
          <div className={`toolbar ${focused ? 'active' : ''}`}>
            <button
              className={activeFormat.bold ? 'selected-button' : ''}
              onMouseDown={(e) => { e.preventDefault(); formatText('bold'); }}
              title="Bold"
            >
              <b>B</b>
            </button>
            <button
              className={activeFormat.italic ? 'selected-button' : ''}
              onMouseDown={(e) => { e.preventDefault(); formatText('italic'); }}
              title="Italic"
            >
              <i>I</i>
            </button>
            <button
              className={activeFormat.underline ? 'selected-button' : ''}
              onMouseDown={(e) => { e.preventDefault(); formatText('underline'); }}
              title="Underline"
            >
              <u>U</u>
            </button>
            <button
              className={activeFormat.strikeThrough ? 'selected-button' : ''}
              onMouseDown={(e) => { e.preventDefault(); formatText('strikeThrough'); }}
              title="Strikethrough"
            >
              <s>S</s>
            </button>
            <button onMouseDown={(e) => { e.preventDefault(); insertList('unordered'); }} title="Bullet List">•</button>
            <button onMouseDown={(e) => { e.preventDefault(); insertList('ordered'); }} title="Numbered List">1.</button>
            {/* <button className="icon" onMouseDown={(e) => { e.preventDefault(); setShowHtml(!showHtml); }} title="Show Html">{`<>`}</button> */}
            <button className="icon" onMouseDown={(e) => { e.preventDefault(); insertElement('link'); }} title="Insert Link"><LinkSVG height={8} color="inherit" /></button>
            <button className="icon" onMouseDown={(e) => { e.preventDefault(); document.execCommand('undo'); }} title="Undo"><UndoSVG height={12} color="inherit" /></button>
            <button className="icon" onMouseDown={(e) => { e.preventDefault(); document.execCommand('redo'); }} title="Redo"><RedoSVG height={12} color="inherit" /></button>
          </div>
        </Popper>
      }

      {/* Editable Content Area */}
      <div
        id={`editor-ref-${elementId}`}
        ref={editorRef}
        className="editor-content"
        contentEditable={preview ? false : true}
        suppressContentEditableWarning={true}
      />
      {
        !htmlContent && !preview ? <p className="" style={{ position: 'absolute', top: -8, left: 20, pointerEvents: 'none' }}>Text Placeholder</p> : null
      }

      {/* Display the HTML output */}
      {
        showHtml &&
        <div className="editor-output">
          <h4>HTML Output</h4>
          <pre>{htmlContent}</pre>
        </div>
      }
    </div>
  );
};

export default ElementRTEditor;
