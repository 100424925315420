import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import SettingsDrawer from '../components/settings/SettingsDrawer'

const SettingsDrawerPage = () => {

  return (
    // <MainPageTemplate background="#f8f8f8" title="Contacts">
    <SettingsDrawer />
    // </MainPageTemplate>
  )
}

export default SettingsDrawerPage