import React, { useState, useEffect, useRef } from 'react';

const CustomPopover = ({
  anchorEl,
  open,
  onClose,
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  offset = 0,
}) => {
  const popoverRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (anchorEl && open) {
      const rect = anchorEl.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      
      let top = 0, left = 0;

      switch (anchorOrigin.vertical) {
        case 'top':
          top = rect.top;
          break;
        case 'center':
          top = rect.top + rect.height / 2;
          break;
        case 'bottom':
          top = rect.bottom;
          break;
        default:
          top = rect.top;
      }

      switch (anchorOrigin.horizontal) {
        case 'left':
          left = rect.left;
          break;
        case 'center':
          left = rect.left + rect.width / 2;
          break;
        case 'right':
          left = rect.right;
          break;
        default:
          left = rect.left;
      }

      switch (transformOrigin.vertical) {
        case 'top':
          top -= 0;
          break;
        case 'center':
          top -= popoverRect.height / 2;
          break;
        case 'bottom':
          top -= popoverRect.height;
          break;
        default:
          top -= 0;
      }

      switch (transformOrigin.horizontal) {
        case 'left':
          left -= 0;
          break;
        case 'center':
          left -= popoverRect.width / 2;
          break;
        case 'right':
          left -= popoverRect.width;
          break;
        default:
          left -= 0;
      }

      // Check if the popover goes off the right edge of the screen
      if (left + popoverRect.width > screenWidth) {
        left = screenWidth - popoverRect.width - 10; // 10px padding from the right edge
      }

      // Check if the popover goes off the left edge of the screen
      if (left < 0) {
        left = 10; // 10px padding from the left edge
      }

      // Check if the popover goes off the bottom edge of the screen
      if (top + popoverRect.height > screenHeight) {
        top = screenHeight - popoverRect.height - 10; // 10px padding from the bottom edge
      }

      // Check if the popover goes off the top edge of the screen
      if (top < 0) {
        top = 10; // 10px padding from the top edge
      }

      setPosition({ top, left });
      setLoaded(true);
    }
  }, [anchorEl, open, anchorOrigin, transformOrigin]);

  if (!open) return null;

  return (
    <div
      ref={popoverRef}
      style={{
        position: 'absolute',
        top: `${position.top}px`,
        left: `${position.left}px`,
        visibility: loaded ? 'visible': 'hidden',
        zIndex: 1000,
      }}
      onMouseLeave={onClose}
    >
      <div
        style={{
          background: '#FFFBFB',
          margin: `${offset}px`,
          boxShadow: '0 0 10px #00000030',
          borderRadius: 16,
          padding: 16,
          zIndex: 1000,
          backdropFilter: 'blur(4px)'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomPopover;
