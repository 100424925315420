import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import CreateContact from '../components/contacts/CreateContact'
import ContactsOG from '../components/contacts/ContactsOG'

const ContactCreatePage = () => {
  return (
    // <MainPageTemplate title="Contacts">
      <CreateContact />
    // </MainPageTemplate>
  )
}

export default ContactCreatePage