import React from 'react'
import Surveys from '../components/surveys/Surveys'
import MainPageTemplate from '../templates/MainPageTemplate'
import SurveysBuilder from '../components/surveys/SurveysBuilder'

const SurveyEditPage = () => {
  return (
    <MainPageTemplate title="Welcome">
      <SurveysBuilder />
    </MainPageTemplate>
  )
}

export default SurveyEditPage