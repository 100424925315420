import React, { useState, useEffect } from 'react';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const ElementEditorText = ({ selectedElement, onTextStyleChange, onWrapStyleChange }) => {
  console.log('selected', selectedElement)
  // Combine all text-related styles into a single `style` object
  const [style, setStyle] = useState({
    fontFamily: selectedElement?.wrapStyle?.fontFamily || 'Arial',
    fontSize: selectedElement?.wrapStyle?.fontSize || '16px',
    fontWeight: selectedElement?.wrapStyle?.fontWeight || 'normal',
    color: selectedElement?.wrapStyle?.color || '#000000',
    textAlign: selectedElement?.wrapStyle?.textAlign || 'left',
    // textDecoration: selectedElement?.wrapStyle?.textDecoration || 'none',
    // fontStyle: selectedElement?.wrapStyle?.fontStyle || 'normal',
  });

  const [fontSizeFormat, setFontSizeFormat] = useState('px');
  const [fontSize, setFontSize] = useState(selectedElement?.wrapStyle.fontSize?.replace(/[^0-9.]/g, '') || 16);

  // Whenever the selectedElement changes, update the local state
  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.wrapStyle?.fontSize) {
        setFontSizeFormat(selectedElement.wrapStyle?.fontSize.replace(/[0-9.]/g, ''));
        setFontSize(selectedElement.wrapStyle?.fontSize.replace(/[^0-9.]/g, ''));
      };
      setStyle({
        fontFamily: selectedElement.wrapStyle?.fontFamily,
        fontSize: selectedElement.wrapStyle?.fontSize,
        fontWeight: selectedElement.wrapStyle?.fontWeight,
        color: selectedElement.wrapStyle?.color,
        textAlign: selectedElement.wrapStyle?.textAlign,
        // textDecoration: selectedElement.wrapStyle?.textDecoration,
        // fontStyle: selectedElement.wrapStyle?.fontStyle,
      });
    }
  }, [selectedElement]);

  // Update fontSize when value changes
  useEffect(() => {
    if (fontSize) {
      setStyle(prev => ({ ...prev, fontSize: `${fontSize}${fontSizeFormat}` }))
      if (onWrapStyleChange) {
        onWrapStyleChange({...selectedElement.wrapStyle, fontSize: `${fontSize}${fontSizeFormat}` });
      }
    }
  }, [fontSize, fontSizeFormat])

  // Handle change for the style fields
  const handleStyleChange = (field, value) => {
    const updatedStyle = { ...style, [field]: value };
    console.log('updatedStyle', updatedStyle)
    setStyle(updatedStyle);

    // Call the onWrapStyleChange function to update the text element's style
    if (onWrapStyleChange) {
      onWrapStyleChange(updatedStyle);
    }
  };

  return (
    <div className="text-editor">
      <h4>Edit Style</h4>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Family</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.fontFamily} onChange={(e) => handleStyleChange('fontFamily', e.target.value)}>
            <option value="Arial">Arial</option>
            <option value="Verdana">Verdana</option>
            <option value="Georgia">Georgia</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Size</label>
        </div>
        <div className="editor-field-value-col-2">
          <input
            className="editor-field-input input-no-padding-block"
            type="number"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            step={fontSizeFormat === "px" ? "1" : ".01"}
            min={fontSizeFormat === "px" ? "5" : ".5"}
            max={fontSizeFormat === "px" ? "100" : "5"}
          />
        </div>
        <div className="editor-field-value-col-3">
          <div
            className={`editor-field-value-col-3 item ${fontSizeFormat === "px" ? 'selected' : ''}`}
            onClick={() => setFontSizeFormat('px')}
          >
            px
          </div>
          <div
            className={`editor-field-value-col-3 item ${fontSizeFormat === "rem" ? 'selected' : ''}`}
            onClick={() => setFontSizeFormat('rem')}
          >
            rem
          </div>
        </div>

      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Weight</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.fontWeight} onChange={(e) => handleStyleChange('fontWeight', e.target.value)}>
            {FONT_WEIGHTS.map((weight) => (
              <option key={weight} value={weight}>{weight}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Color</label>
        </div>
        <div className="editor-field-value">
          <input
            className="editor-field-input"
            type="color"
            value={style.color}
            onChange={(e) => handleStyleChange('color', e.target.value)}
          />
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Align</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.textAlign} onChange={(e) => handleStyleChange('textAlign', e.target.value)}>
            {TEXT_ALIGN.map((align) => (
              <option key={align} value={align}>{align}</option>
            ))}
          </select>
        </div>
      </div>

      {/* <div className="editor-field">
        <div className="editor-field-key">
          <label>Text Decoration</label>
        </div>
        <div className="editor-field-value">
          <select
            className="editor-field-input"
            value={style.textDecoration}
            onChange={(e) => handleStyleChange('textDecoration', e.target.value)}
          >
            {TEXT_DECORATION.map((decoration) => (
              <option key={decoration} value={decoration}>{decoration}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Text Style</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.fontStyle} onChange={(e) => handleStyleChange('fontStyle', e.target.value)}>
            {TEXT_STYLES.map((style) => (
              <option key={style} value={style}>{style}</option>
            ))}
          </select>
        </div>
      </div> */}

    </div>
  );
};

export default ElementEditorText;
