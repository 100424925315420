import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingContact from '../components/landing/LandingContact'
import { SEOData } from '../lib/SEOData';

const LandingContactPage = () => {
  const data = SEOData.contact;

  return (
    <LandingTemplate 
      title={data.title}
      description={data.description}
      name={data.name}
      type={data.type}
    >
      <LandingContact />
    </LandingTemplate>
  )
}

export default LandingContactPage