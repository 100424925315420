import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import FeedbackPanelTemplate from '../components/feedback/FeedbackPanelTemplate'
import { useGetBugs, useGetFeatureRequests, useGetFeedback } from '../hooks/mutations'

const FeedbackPanelPage = () => {
  const bugItems = useGetBugs();
  const featureRequests = useGetFeatureRequests();
  const feedback = useGetFeedback();
  console.log('bugs', bugItems);
  console.log('featureRequests', featureRequests);
  console.log('feedback', feedback);

  return (
    // <MainPageTemplate title="Contacts">
    bugItems && featureRequests && feedback
      ?
        <FeedbackPanelTemplate
          bugItems={bugItems}
          featureRequests={featureRequests}
          feedback={feedback}
        />
      : null
    // </MainPageTemplate>
  )
}

export default FeedbackPanelPage