import React, { useState, useRef, useEffect } from 'react';
import './rich-text.css';
import { InputField } from './Fields';
import { useTheme } from '@emotion/react';
import { AlignCenterSVG, AlignJustifySVG, AlignLeftSVG, AlignRightSVG, OrderedListSVG, UnorderedListSVG } from '../../assets/icons';
import { createStorageFile, getEmailTemplateFolder, putStorageFile, useGetStorageFileRichText } from '../../hooks/mutations';
import { getRandomInts } from '../../lib/Functions';
import { Grid } from '@mui/material';

export const RichTextEditor = (props) => {
  const { id, contactId, fieldName, placeholder, value, onChange, label, margin, marginAmount, notFull, width, readOnly=false, enableOnClick, noAutoFocus, ...other } = props;
  const theme = useTheme();
  const incomingHtml = useGetStorageFileRichText({fileId: value});
  const mainColor = theme.palette.primary.main;
  const [html, setHtml] = useState(incomingHtml);
  const editorRef = useRef(null);
  const [selectedStyles, setSelectedStyles] = useState({
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
    unorderedList: false,
    orderedList: false,
    justifyLeft: false,
    justifyCenter: false,
    justifyRight: false,
    justifyFull: false,
  });

  // For auto save functionality
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    editorRef.current.focus();
    editorRef.current.addEventListener('mouseup', updateSelectedStyles);
    editorRef.current.addEventListener('keyup', updateSelectedStyles);
  
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('mouseup', updateSelectedStyles);
        editorRef.current.removeEventListener('keyup', updateSelectedStyles);
      }
    };
  
  }, []);

  // Get incoming html data if it doesn't already exist
  useEffect(() => {
    if (incomingHtml) {
      setHtml(incomingHtml);
      editorRef.current.innerHTML = incomingHtml;
    }
  }, [incomingHtml])

  // Get incoming html data if it doesn't already exist
  // useEffect(() => {
  //   if (incomingHtml?.file) {

  //     const fetchData = async () => {
  //       if (incomingHtml) {
  //         console.log('incomingHtml', incomingHtml.file)
  //         try {
  //           const response = await fetch(incomingHtml.file, {
  //             // mode: 'no-cors',
  //             method: 'GET',
  //             headers: {
  //               'Content-Type': 'application/json'
  //             }
  //           });
  //           const getHtml = await response.text();
  //           const event = {target: {innerHTML: getHtml}};
  //           console.log('event', event.target.innerHTML);
  //           handleOnChange(event);

  //           // setHtml(file.data);
  //         } catch (error) {
  //           console.log('Error fetching data:', error);
  //         }
  //       }
  //     };
    
  //     fetchData();
  //   }
  
  // }, [incomingHtml?.file]);
  

  const handleToolbarClick = (style) => {
    editorRef.current.focus();
    document.execCommand(style);
    // if (style === 'bold' || style === 'italic' || style === 'underline') {
    //   setSelectedStyles((prevStyles) => ({ ...prevStyles, [style]: !prevStyles[style] }));
    // }
  };

  const handleShortcutKeys = (event) => {
    if (event.ctrlKey || event.metaKey) {
      switch (event.key.toLowerCase()) {
        case 'b':
          event.preventDefault();
          handleToolbarClick('bold');
          break;
        case 'i':
          event.preventDefault();
          handleToolbarClick('italic');
          break;
        case 'u':
          event.preventDefault();
          handleToolbarClick('underline');
          break;
        case '5':
          event.preventDefault();
          handleToolbarClick('strikethrough');
          break;
        default:
          break;
      }
    }
  };

  const handlePopup = () => {
    // Implement popup menu logic here
    console.log('Popup menu triggered');
  };

  const handleOnChange = (event) => {
    setHtml(event.target.innerHTML);
    // onChange(event);
    updateSelectedStyles();
  };

  // Save the rich text content
  const saveRichText = async () => {
    if (value) {
      // Save file to storage id that already exists
      const storageFolder = await getEmailTemplateFolder();
      const storageFolderId = storageFolder.folderId;
      const fileName = `${contactId}-${fieldName.replace(' ', '_')}-${id}-${getRandomInts(16)}.rtxt`;
      const fileObj = new File([html], fileName, { type: 'text/plain' });
      const formData = new FormData();
      formData.append('file', fileObj);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        fileId: value,
        storageFilePayload: formData
      }

      // Replace the file, keeping the same file id
      const updatedFile = await putStorageFile(storageFileObj);
      // console.log('updatedFile', updatedFile)
      if (updatedFile.status === "success") {
        console.log('saved html')
      }

    } else {
      // Create new file in storage
      console.log('create new storage file');
      const storageFolder = await getEmailTemplateFolder();
      const storageFolderId = storageFolder.folderId;
      const fileName = `${contactId}-${fieldName.replace(' ', '_')}-${id}-${getRandomInts(16)}.rtxt`;
      const fileObj = new File([html], fileName, { type: 'text/plain' });
      const formData = new FormData();
      formData.append('file', fileObj);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      // Create a new file
      const newFile = await createStorageFile(storageFileObj);
      console.log('newFile', newFile)
      if (newFile.status === "success") {
        console.log('created new html file')
        onChange(newFile.data.id);
      }

    }
  }

  useEffect(() => {
    if (loaded) {
      let timeoutId;
      let savingId;
  
      clearTimeout(timeoutId);
      clearTimeout(savingId);
      setIsSaved(false);
      timeoutId = setTimeout(() => {
        handleSave();
      }, 2000);
  
      savingId = setTimeout(() => {
        setIsSaving(true);
      }, 1000);
  
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(savingId);
      };
    } else {
      setLoaded(true);
    }

  }, [html])

  const handleSave = () => {
    saveRichText();
    setIsSaved(true);
    setIsSaving(false);
  }


  const updateSelectedStyles = () => {
    const currentSelection = document.getSelection();
    if (currentSelection.rangeCount > 0) {
      const range = currentSelection.getRangeAt(0);
      const parentElement = range.commonAncestorContainer.parentElement;

      setSelectedStyles({
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikethrough: document.queryCommandState('strikethrough'),
        justifyLeft: parentElement && getComputedStyle(parentElement).textAlign === 'left',
        justifyCenter: parentElement && getComputedStyle(parentElement).textAlign === 'center',
        justifyRight: parentElement && getComputedStyle(parentElement).textAlign === 'right',
        justifyFull: parentElement && getComputedStyle(parentElement).textAlign === 'justify',
        unorderedList: isWithinList(parentElement, 'UL'),
        orderedList: isWithinList(parentElement, 'OL'),
      });
    }
  };
  
  const isWithinList = (element, listType) => {
    while (element) {
      if (element.nodeName === listType) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };
  
  // Enable when not testing
  const handleCopy = (event) => {
    event.clipboardData.setData('text/html', editorRef.current.innerHTML);
    event.preventDefault();
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData.getData('text/html');
    document.execCommand('insertHTML', false, clipboardData);
    event.preventDefault();
  };

  return (
    <div style={{width:'100%'}}>
      <div className="toolbar">
        {/* <div className="toolbar-buttons"> */}
        <Grid container>
          <button className={selectedStyles.bold ? 'active' : ''} onClick={() => handleToolbarClick('bold')}>
            <span style={{color: selectedStyles.bold ? '#fff' : mainColor, fontWeight:900, fontSize:'1.2rem'}}>B</span>
          </button>
          <button className={selectedStyles.italic ? 'active' : ''} onClick={() => handleToolbarClick('italic')}>
            <span style={{color: selectedStyles.italic ? '#fff' : mainColor, fontWeight:800, fontStyle:'italic', fontSize:'1.2rem'}}>I</span>
          </button>
          <button className={selectedStyles.underline ? 'active' : ''} onClick={() => handleToolbarClick('underline')}>
            <span style={{color: selectedStyles.underline ? '#fff' : mainColor, fontWeight:700, textDecoration:'underline',  fontSize:'1.2rem'}}>U</span>
          </button>
          <button className={selectedStyles.strikethrough ? 'active' : ''} onClick={() => handleToolbarClick('strikethrough')}>
            <span style={{color: selectedStyles.strikethrough ? '#fff' : mainColor, fontWeight:700, textDecoration:'line-through',  fontSize:'1.2rem'}}>S</span>
          </button>
          <button className={selectedStyles.unorderedList ? 'active' : ''} onClick={() => handleToolbarClick('insertUnorderedList')}><UnorderedListSVG width={25} color1={selectedStyles.unorderedList ? '#fff' : mainColor} /></button>
          <button className={selectedStyles.orderedList ? 'active' : ''} onClick={() => handleToolbarClick('insertOrderedList')}><OrderedListSVG width={25} color1={selectedStyles.orderedList ? '#fff' : mainColor} /></button>
          <button className={selectedStyles.justifyLeft ? 'active' : ''} onClick={() => handleToolbarClick('justifyLeft')}><AlignLeftSVG width={25} color1={selectedStyles.justifyLeft ? '#fff' : mainColor} /></button>
          <button className={selectedStyles.justifyCenter ? 'active' : ''} onClick={() => handleToolbarClick('justifyCenter')}><AlignCenterSVG width={25} color1={selectedStyles.justifyCenter ? '#fff' : mainColor} /></button>
          <button className={selectedStyles.justifyRight ? 'active' : ''} onClick={() => handleToolbarClick('justifyRight')}><AlignRightSVG width={25} color1={selectedStyles.justifyRight ? '#fff' : mainColor} /></button>
          <button className={selectedStyles.justifyFull ? 'active' : ''} onClick={() => handleToolbarClick('justifyFull')}><AlignJustifySVG width={25} color1={selectedStyles.justifyFull ? '#fff' : mainColor} /></button>
          {/* <button onClick={handlePopup}>//</button> */}
        {/* </div> */}
        </Grid>
          <button className="save-button" onClick={saveRichText} disabled={isSaving || isSaved} style={{background: isSaving || isSaved ? '#ccc' : theme.palette.primary.main }}>
            {
              isSaved ? 'Saved'
                : isSaving
                  ? 'Saving...'
                  : 'Save'
            }
          </button>
      </div>
      <div
        ref={editorRef}
        contentEditable
        className="editor"
        onKeyDown={handleShortcutKeys}
        onInput={handleOnChange}
        // onCopy={handleCopy}  //  <-- Enable when not testing on localhost (needs a https url)
        // onPaste={handlePaste}  //  <-- Enable when not testing on localhost (needs a https url)
        style={{
          // margin: margin || marginAmount ? (marginAmount ? marginAmount : '0 0 24px 0') : 0,
          borderRadius:8,
          outline:'none',
          border:'none',
          // boxShadow:readOnly ? null : '0 0 5px #00000030',
          padding:0,
          // width:  width ? width : '100%', //'12px 32px'
          padding: readOnly ? "0px 16px" : "16px 16px",
          background: readOnly ? 'transparent' : `${theme.palette.primary.light}15}`, //'#fff',
          transition: '.3s',
          borderRadius:1,
        }}
      />
      {/* <div className="output">{html}</div> */}
    </div>
  );
};

export default RichTextEditor;
