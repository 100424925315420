import React, { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import localforage from 'localforage';
import { auth, db } from '../firebase';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
}

export const AuthProvider = ({children}) => {
  // PERSISTANT STATE
  const [user, setUser] = useState(null);

  // ====================================================================================
  // LOCAL FORAGE CONFIG FOR ACCOUNT STORE
  // ====================================================================================
  localforage.config({
    name        : 'accountStore',
    storeName   : 'accountStore', // Should be alphanumeric, with underscores.
  });
  const accountStore = localforage.createInstance({name: 'accountStore', storeName: 'accountStore'});

  // GET USER INFO FROM INDEXED DB ON PAGE LOAD / REFRESH / NEW TAB / ETC
  useEffect(() => {
    getAccountStore();
  }, [])

  // STORE THE USER STATE INTO THE ACCOUNT STORE IN INDEXED DB
  // WHENEVER THE USER STATE UPDATES WITH USEEFFECT

  // STORE USER STATE TO USER INFO ITEM IN INDEXED DB ACCOUNT STORE
  useEffect(() => {
    if (user) {
      setUserInfoItem(user);
    }
  }, [user])

  // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  const getAccountStore = async () => {
    // Set user state
    try {
      const userInfoValue = await accountStore.getItem('userInfo');
      setUser(userInfoValue);

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting user info state:', error);
    }

  }

  // SET USER STATE TO INDEXED DB ACCOUNT STORE
  const setUserInfoItem = async (userInfo) => {
    try {
      const userInfoCopy = JSON.parse(JSON.stringify(userInfo)); // make a copy of the userInfo object
      await accountStore.setItem('userInfo', userInfoCopy);
    }

    catch (error) {
      console.log('Error setting user info', error);
    }
  }

  // CLEAR ACCOUNT STORE INDEXED DB WHEN USER LOGS OUT
  const clearAccountStore = () => {
    accountStore.clear();
  }


  return (
    <>
      <authContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        {children}
      </authContext.Provider>
    </>
  )
}