import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Button, Dialog, Grid, Typography } from '@mui/material'
import { getScreenSize, useScreenSize, useScreenSizeCalc } from '../../lib/Interface'
import { LogoHorizontal } from '../../assets/logos'
import { ScrollToGrow } from '../ui/ScrollAnimations'
import { Angle } from '../../assets/graphics'
import { featureList } from '../../lib/Data'
import { hashScroll } from '../../lib/Functions'
import MainNav from '../nav/MainNav'
import Pricing from '../pricing/Pricing'
import { CenterColumn } from './Landing'
import Register from '../auth/Register'
import { UpCaretSVG } from '../../assets/icons'

const LandingFeatures = () => {
  const theme = useTheme();
  const coreList = [ {name: 'Core Features', hash: 'core', title:true}, ...featureList.filter(x => x.availability === 'now' && x.tier === 'included') ]
  const addonList = [ {name: 'Addons', hash: 'addon', title:true}, ...featureList.filter(x => x.availability === 'now' && x.tier === 'addon') ]

  const hash = window.location.hash;

  useEffect(() => {
    if (hash) {
      let elem = document.getElementById(hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [hash])

  // SVG PATH FUNCTIONS
  const [svgCorePaths, setSvgCorePaths] = useState([]);
  const [svgAddonPaths, setSvgAddonPaths] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      // Use requestAnimationFrame to ensure DOM updates are synchronized with the next paint
      requestAnimationFrame(() => connectDivs(coreList, 'feature'));
      requestAnimationFrame(() => connectDivs(addonList, 'addon'));
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial connection of divs
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const connectDivs = (items, divKey) => {
    const paths = [];
    const noCurveRange = 10; // Adjust this value as needed
    const blendCurveRange = 30;
    
    for (let i = 0; i < items.length - 1; i++) {
      const topDiv = document.getElementById(`${i + 1}-top-${divKey}`);
      const bottomDiv = document.getElementById(`${i}-bottom-${divKey}`);
  
      if (topDiv && bottomDiv) {
        const topRect = topDiv.getBoundingClientRect();
        const bottomRect = bottomDiv.getBoundingClientRect();
        const containerRect = document.getElementById(`svg-container-features`).getBoundingClientRect();
  
        const x1 = bottomRect.left + bottomRect.width / 2 - containerRect.left;
        const y1 = bottomRect.top + bottomRect.height / 2 - containerRect.top;
        const x2 = topRect.left + topRect.width / 2 - containerRect.left;
        const y2 = topRect.top + topRect.height / 2 - containerRect.top;
  
        const curveHeight = Math.abs(y2 - y1) / 2;
        const initControlPointOffset = Math.min(curveHeight, 20); 
        const controlPointOffset = Math.abs(x2 - x1) < blendCurveRange ? initControlPointOffset -15 : initControlPointOffset // Math.min(curveHeight, 20);
  
        let pathData;
        if (Math.abs(x2 - x1) < noCurveRange) {
          // Straight line if within the no curve range
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y2}
          `;
        } else if (x1 < x2) {
          // Case where bottom div is on the left and top div is on the right
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 + controlPointOffset} ${y1 + curveHeight}
            L ${x2 - controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        } else {
          // Case where bottom div is on the right and top div is on the left
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 - controlPointOffset} ${y1 + curveHeight}
            L ${x2 + controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        }
  
        paths.push(<path d={pathData} stroke={theme.palette.primary.main} fill="transparent" strokeWidth="3" strokeDasharray={10} key={`${i}-${divKey}`} />);
      }
    }
  
    if (divKey === "feature") {
      setSvgCorePaths(paths);
    } else if (divKey === "addon") {
      setSvgAddonPaths(paths);
    }
  };


  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary,
        paddingTop:70
      }}
    >
      {/* <MainNav /> */}

      <Grid
        id="features"
        container
      >
        <CenterColumn
          alignment={{x:'flex-start'}}
        >
        <Typography
          sx={{
            fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
            fontWeight:700,
            lineHeight:1,
            // textTransform:'uppercase',
            color:theme.palette.primary.dark, //'#fff'
            transition:'.4s',
            zIndex:2
          }}
        >
          Features
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: '1.4', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
            lineHeight:1.2,
            margin:'16px 0',
            transition:'.4s'
          }}
        >
          The Fill CRM and email marketing platform has an amazing list of features built to compete with similar products from the corporate marketing overlords.
        </Typography>

        <Grid container variant="center">
        {
            // featureList.filter(x => x.availability === 'now' && x.tier === 'included').map(feature => (
            coreList.map(feature => (
              !feature.title &&
              <Grid
                item
                style={{
                  padding:4
                }}
                xs={6}
                sm={6}
                md={4}
                lg={3}
              >
                <Grid
                  container
                  variant="center"
                  onClick={() => hashScroll(feature.hash)}
                  sx={{
                    padding:'2px 8px',
                    borderRadius:10,
                    background:theme.palette.primary.main,
                    color: '#fff',
                    cursor:'pointer'
                  }}
                >
                  <Typography>{feature.shortName}</Typography>
                </Grid>
              </Grid>
            ))
          }
          {
            // featureList.filter(x => x.availability === 'now' && x.tier === 'addon').map(feature => (
            addonList.map(feature => (
              !feature.title &&
              <Grid
                item
                style={{
                  padding:4,
                  position:'relative'
                }}
                xs={6}
                sm={6}
                md={4}
                lg={3}
              >
                <div
                  style={{
                    position:'absolute',
                    bottom:-12,
                    right:10
                  }}
                >
                  <Typography
                    style={{
                      background:'#fff',
                      border:`3px solid ${theme.palette.primary.main}`,
                      borderRadius:20,
                      lineHeight:1.2,
                      fontSize:'.85rem',
                      padding:'0 8px'
                    }}
                  >
                    addon
                  </Typography>
                </div>
                <Grid
                  container
                  variant="center"
                  onClick={() => hashScroll(feature.hash)}
                  sx={{
                    padding:'2px 8px',
                    borderRadius:10,
                    background:theme.palette.primary.main,
                    color: '#fff',
                    cursor:'pointer'
                  }}
                >
                  <Typography>{feature.shortName}</Typography>
                </Grid>
              </Grid>
            ))
          }
        </Grid>

        </CenterColumn>
        <CenterColumn>
        <div style={{position:'relative'}}>
          <svg id={`svg-container-features`} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
            <g id="svg-paths">{svgCorePaths}</g>
            <g id="svg-paths">{svgAddonPaths}</g>
          </svg>

          {
            // featureList.filter(x => x.availability === 'now' && x.tier === 'included').map((feature, index) => (
            coreList.map((feature, index) => (
              feature.title
                ?
                  <TitleItem
                    key={feature.name}
                    divKey="feature"
                    feature={feature}
                    index={index}
                    lastIndex={coreList.length - 1}
                    next={index < coreList.length - 1 && `${coreList[index+1].hash}`}
                    prev={index > 0 && `${coreList[index-1].hash}`}
                  />
                :
                <FeatureItem
                  key={feature.name}
                  divKey="feature"
                  feature={feature}
                  index={index}
                  lastIndex={coreList.length - 1}
                  next={index < coreList.length - 1 && `${coreList[index+1].hash}`}
                  prev={index > 0 && `${coreList[index-1].hash}`}
                />
            ))
          }
          {
            // featureList.filter(x => x.availability === 'now' && x.tier === 'addon').map((feature, index) => (
            addonList.map((feature, index) => (
              feature.title
                ?
                  <TitleItem
                    key={feature.name}
                    divKey="addon"
                    feature={feature}
                    index={index}
                    lastIndex={addonList.length - 1}
                    next={index < addonList.length - 1 && `${addonList[index+1].hash}`}
                    prev={index > 0 && `${addonList[index-1].hash}`}
                  />
                :
                  <FeatureItem
                    key={feature.name}
                    divKey="addon"
                    feature={feature}
                    index={index}
                    lastIndex={addonList.length - 1}
                    next={index < addonList.length - 1 && `${addonList[index+1].hash}`}
                    prev={index > 0 && `${addonList[index-1].hash}`}
                  />
            ))
          }
        </div>

        </CenterColumn>
      </Grid>
    </Grid>
  )
}

export default LandingFeatures

const FeatureItem = (props) => {
  const { feature, index, lastIndex, divKey, next, prev } = props;
  const [available, setAvailable] = useState({
    label: '',
    color: null,
    background: null
  });
  const theme = useTheme();
  const screenSize = useScreenSize();
  const reverse = index % 2;
  const [openRegister, setOpenRegister] = useState(false);

  useEffect(() => {
    if (feature) {
      if (feature.availability === "now") {
        setAvailable({
          label: 'Available Now',
          color: '#fff', //nowTheme.color,
          background: theme.palette.primary.main //nowTheme.background
        })
      } else
      if (feature.availability === "soon") {
        setAvailable({
          label: 'Coming Soon',
          color: '#fff', //theme.palette.primary.dark, //soonTheme.color,
          background: theme.palette.primary.light //soonTheme.background
        })
      } else
      if (feature.availability === "later") {
        setAvailable({
          label: 'Later This Year',
          color: '#fff', //laterTheme.color,
          background: theme.palette.secondary.main //laterTheme.background
        })
      }
    }
  }, [feature]);

  return (
    <div
      id={feature.hash}
      style={{
        padding: screenSize.xs ? '75px 0' : '148px 0',
      }}
    >
      {
        index != 0 &&
          <Grid container justifyContent={screenSize.xs ? "center" : "flex-end"} padding="0 96px">
            <Button
              onClick={() => hashScroll(prev)}
              endIcon={<UpCaretSVG width={16} color1={theme.palette.primary.main} />}
              style={{
                fontWeight:700
              }} 
            >
              Previous
            </Button>
          </Grid>
      }
      <Grid
        container
        sx={{
          position:'relative',
          padding: '24px',
          // paddingBottom: {xs:4, md:0},
          // borderBottom:'1px solid #ccc',
          borderRadius:24,
          boxShadow:'0 0 8px #00000030',
          background:'#FFF',
          // borderTop: index === 0 ? '1px solid #ccc' : null
        }}
      >
        <div
          id={`${index}-top-${divKey}`}
          style={{
            display: index === 0 ? 'none' : 'inherit',
            position: 'absolute',
            top: -13,
            left: screenSize.xs ? (index % 4 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
            width: 26,
            height: 26,
            borderRadius:26,
            background: theme.palette.primary.main,
            // outline: `3px solid ${theme.palette.background.secondary}`
          }}
        />
        <div
          id={`${index}-bottom-${divKey}`}
          style={{
            display: index === lastIndex ? 'none' : 'inherit',
            position: 'absolute',
            bottom: -13,
            left: screenSize.xs ? (index % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
            width: 26,
            height: 26,
            borderRadius:26,
            background: `${theme.palette.alt.light}`,
            outline: `3px solid ${theme.palette.background.secondary}`
          }}
        />

        <CenterColumn>
          <Grid
            container
            sx={{
              flexDirection: {xs: 'row', md: reverse ? 'row-reverse' : 'row'}
            }}
          >
            <Grid
              item
              sx={{
                // height:'100%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                // padding:{xs: 0, md: '0 90px'},
              }}
              xs={12}
              md="auto"        >
              {
                feature.icon
                  ?
                    <img
                      src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/${feature.icon}_500px.png` : `https://static.fillmarketing.com/static/static/img/${feature.icon}_500px.png`}
                      style={{
                        width: screenSize.xs ? '60%' : (screenSize.sm ? '50%' : '80%'),
                        height:'80%',
                        maxWidth:'90%',
                        objectFit:'contain'
                      }}
                    />
                  :
                    null
              }
            </Grid>
            <Grid
              item
              style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                padding:'0 16px'
              }}
              xs={12}
              md
            >
                <Grid
                  container
                  style={{
                    maxWidth:500
                  }}
                >
                  <Typography
                    sx={{
                      width:'100%',
                      // textAlign: reverse ? 'right' : 'left',
                      lineHeight:1,
                      fontSize:{xs: '2rem', sm:'2.5rem', md:'2.75rem'},
                      fontWeight:600,
                      marginBottom:1,
                      color:theme.palette.primary.dark
                    }}
                  >
                    {feature.name}
                  </Typography>
                  <Typography
                    sx={{
                      width:'100%',
                      // textAlign: reverse ? 'right' : 'left',
                      textTransform:'uppercase',
                      lineHeight:1.1,
                      fontSize:{xs:'1.3rem', sm:'1.4rem', md:'1.5rem'},
                      fontWeight:500,
                      marginBottom:2,
                      color:theme.palette.primary.main
                    }}
                  >
                    {feature.short}
                  </Typography>
                  <Typography
                    sx={{
                      width:'100%',
                      fontSize:{xs:'1rem', sm:'1.1rem', md:'1.3rem'}
                      // textAlign: reverse ? 'right' : 'left'
                    }}
                  >
                    {feature.description}
                  </Typography>
                  <Grid
                    style={{
                      background:available.background,
                      color: available.color,
                      padding:'0 12px',
                      borderRadius:16,
                      marginTop:8
                    }}
                  >
                    <Typography
                      onClick={() => setOpenRegister(true)}
                      sx={{
                        fontSize:{xs: '.85rem', sm:'1rem', md:'1.2rem'},
                        fontWeight:500,
                        textTransform:'uppercase',
                        color:available.color,
                        cursor:'pointer'
                      }}
                    >
                      {available.label}
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </CenterColumn>
        <Dialog
          open={openRegister}
          onClose={() => setOpenRegister(false)}
          maxWidth="sm"
        >
          <Register />
        </Dialog>

      </Grid>
      {
        index != lastIndex &&
          <Grid container justifyContent={screenSize.xs ? "center" : "flex-end"} padding="0 96px">
            <Button
              onClick={() => hashScroll(next)}
              endIcon={<UpCaretSVG width={16} color1={theme.palette.primary.main} style={{transform:'rotate(180deg)'}} />}
              style={{
                fontWeight:700
              }} 
            >
              Next
            </Button>
          </Grid>
      }
    </div>
  )
}

const TitleItem = (props) => {
  const { feature, index, lastIndex, divKey, next, prev } = props;
  const [available, setAvailable] = useState({
    label: '',
    color: null,
    background: null
  });
  const theme = useTheme();
  const screenSize = useScreenSize();
  const reverse = index % 2;
  const [openRegister, setOpenRegister] = useState(false);

  useEffect(() => {
    if (feature) {
      if (feature.availability === "now") {
        setAvailable({
          label: 'Available Now',
          color: '#fff', //nowTheme.color,
          background: theme.palette.primary.main //nowTheme.background
        })
      } else
      if (feature.availability === "soon") {
        setAvailable({
          label: 'Coming Soon',
          color: '#fff', //theme.palette.primary.dark, //soonTheme.color,
          background: theme.palette.primary.light //soonTheme.background
        })
      } else
      if (feature.availability === "later") {
        setAvailable({
          label: 'Later This Year',
          color: '#fff', //laterTheme.color,
          background: theme.palette.secondary.main //laterTheme.background
        })
      }
    }
  }, [feature]);

  return (
    <div
      id={feature.hash}
      style={{
        padding: screenSize.xs ? '75px 0' : '148px 0',
      }}
    >
      {
        index != 0 &&
          <Grid container justifyContent={screenSize.xs ? "center" : "flex-end"} padding="0 96px">
            <Button
              onClick={() => hashScroll(prev)}
              endIcon={<UpCaretSVG width={16} color1={theme.palette.primary.main} />}
              style={{
                fontWeight:700
              }} 
            >
              Previous
            </Button>
          </Grid>
      }
      <Grid
        container
        sx={{
          position:'relative',
          padding: '24px',
          // paddingBottom: {xs:4, md:0},
          // borderBottom:'1px solid #ccc',
          borderRadius:24,
          boxShadow:'0 0 8px #00000030',
          background: theme.palette.primary.main,
          // borderTop: index === 0 ? '1px solid #ccc' : null
        }}
      >
        <div
          id={`${index}-top-${divKey}`}
          style={{
            display: index === 0 ? 'none' : 'inherit',
            position: 'absolute',
            top: -13,
            left: screenSize.xs ? (index % 4 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
            width: 26,
            height: 26,
            borderRadius:26,
            background: theme.palette.primary.main,
            // outline: `3px solid ${theme.palette.background.secondary}`
          }}
        />
        <div
          id={`${index}-bottom-${divKey}`}
          style={{
            display: index === lastIndex ? 'none' : 'inherit',
            position: 'absolute',
            bottom: -13,
            left: screenSize.xs ? (index % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
            width: 26,
            height: 26,
            borderRadius:26,
            background: `${theme.palette.alt.light}`,
            outline: `3px solid ${theme.palette.background.secondary}`
          }}
        />

        <CenterColumn>
          <Grid
            container
            variant="center"
          >
              <Typography
                sx={{
                  lineHeight:1,
                  fontSize:{xs: '2rem', sm:'2.5rem', md:'2.75rem'},
                  fontWeight:600,
                  color:'#FFF'
                }}
              >
                {feature.name}
              </Typography>
              <Typography
                sx={{
                  width:'100%',
                  fontSize:{xs:'1rem', sm:'1.1rem', md:'1.3rem'}
                  // textAlign: reverse ? 'right' : 'left'
                }}
              >
                {feature.description}
              </Typography>
          </Grid>
        </CenterColumn>

      </Grid>
      {
        index != lastIndex &&
          <Grid container justifyContent={screenSize.xs ? "center" : "flex-end"} padding="0 96px">
            <Button
              onClick={() => hashScroll(next)}
              endIcon={<UpCaretSVG width={16} color1={theme.palette.primary.main} style={{transform:'rotate(180deg)'}} />}
              style={{
                fontWeight:700
              }} 
            >
              Next
            </Button>
          </Grid>
      }
    </div>
  )
}

// const TitleItem = (props) => {
//   const { feature, index } = props;
//   const [available, setAvailable] = useState({
//     label: '',
//     color: null,
//     background: null
//   });
//   const theme = useTheme();
//   const screenSize = useScreenSize();
//   const reverse = index % 2;
//   const [openRegister, setOpenRegister] = useState(false);

//   useEffect(() => {
//     if (feature) {
//       if (feature.availability === "now") {
//         setAvailable({
//           label: 'Available Now',
//           color: '#fff', //nowTheme.color,
//           background: theme.palette.primary.main //nowTheme.background
//         })
//       } else
//       if (feature.availability === "soon") {
//         setAvailable({
//           label: 'Coming Soon',
//           color: '#fff', //theme.palette.primary.dark, //soonTheme.color,
//           background: theme.palette.primary.light //soonTheme.background
//         })
//       } else
//       if (feature.availability === "later") {
//         setAvailable({
//           label: 'Later This Year',
//           color: '#fff', //laterTheme.color,
//           background: theme.palette.secondary.main //laterTheme.background
//         })
//       }
//     }
//   }, [feature]);

//   return (
//     <div
//       id={feature.hash}
//       style={{
//         padding:'148px 0',
//       }}
//     >
//       <Grid
//         container
//         sx={{
//           position:'relative',
//           padding: '24px',
//           // paddingBottom: {xs:4, md:0},
//           // borderBottom:'1px solid #ccc',
//           borderRadius:24,
//           boxShadow:'0 0 8px #00000030',
//           background:'#FFF',
//           // borderTop: index === 0 ? '1px solid #ccc' : null
//         }}
//       >
//         <div
//           id={`${index}-top-feature`}
//           style={{
//             display: index === 0 ? 'none' : 'inherit',
//             position: 'absolute',
//             top: -13,
//             left: screenSize.xs ? (index % 4 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
//             width: 26,
//             height: 26,
//             borderRadius:26,
//             background: theme.palette.primary.main,
//             // outline: `3px solid ${theme.palette.background.secondary}`
//           }}
//         />
//         <div
//           id={`${index}-bottom-feature`}
//           style={{
//             // display: index === items.length-1 ? 'none' : 'inherit',
//             position: 'absolute',
//             bottom: -13,
//             left: screenSize.xs ? (index % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
//             width: 26,
//             height: 26,
//             borderRadius:26,
//             background: `${theme.palette.alt.light}`,
//             outline: `3px solid ${theme.palette.background.secondary}`
//           }}
//         />

//         <CenterColumn>
//           <Grid
//             container
//             sx={{
//               flexDirection: {xs: 'row', md: reverse ? 'row-reverse' : 'row'}
//             }}
//           >
//             <Grid
//               item
//               sx={{
//                 // height:'100%',
//                 display:'flex',
//                 alignItems:'center',
//                 justifyContent:'center',
//                 // padding:{xs: 0, md: '0 90px'},
//               }}
//               xs={12}
//               md="auto"        >
//               {
//                 feature.icon
//                   ?
//                     <img
//                       src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/${feature.icon}_500px.png` : `https://static.fillmarketing.com/static/static/img/${feature.icon}_500px.png`}
//                       style={{
//                         width: screenSize.xs ? '60%' : (screenSize.sm ? '50%' : '80%'),
//                         height:'80%',
//                         objectFit:'contain'
//                       }}
//                     />
//                   :
//                     null
//               }
//             </Grid>
//             <Grid
//               item
//               style={{
//                 display:'flex',
//                 justifyContent:'center',
//                 alignItems:'center',
//               }}
//               xs={12}
//               md
//             >
//                 <Grid
//                   container
//                   style={{
//                     maxWidth:500
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       width:'100%',
//                       // textAlign: reverse ? 'right' : 'left',
//                       lineHeight:1,
//                       fontSize:{xs: '2rem', sm:'2.5rem', md:'2.75rem'},
//                       fontWeight:600,
//                       marginBottom:1,
//                       color:theme.palette.primary.dark
//                     }}
//                   >
//                     {feature.name}
//                   </Typography>
//                   <Typography
//                     sx={{
//                       width:'100%',
//                       // textAlign: reverse ? 'right' : 'left',
//                       textTransform:'uppercase',
//                       lineHeight:1.1,
//                       fontSize:{xs:'1.3rem', sm:'1.4rem', md:'1.5rem'},
//                       fontWeight:500,
//                       marginBottom:2,
//                       color:theme.palette.primary.main
//                     }}
//                   >
//                     {feature.short}
//                   </Typography>
//                   <Typography
//                     sx={{
//                       width:'100%',
//                       fontSize:{xs:'1rem', sm:'1.1rem', md:'1.3rem'}
//                       // textAlign: reverse ? 'right' : 'left'
//                     }}
//                   >
//                     {feature.description}
//                   </Typography>
//                   <Grid
//                     style={{
//                       background:available.background,
//                       color: available.color,
//                       padding:'0 12px',
//                       borderRadius:16,
//                       marginTop:8
//                     }}
//                   >
//                     <Typography
//                       onClick={() => setOpenRegister(true)}
//                       sx={{
//                         fontSize:{xs: '.85rem', sm:'1rem', md:'1.2rem'},
//                         fontWeight:500,
//                         textTransform:'uppercase',
//                         color:available.color,
//                         cursor:'pointer'
//                       }}
//                     >
//                       {available.label}
//                     </Typography>
//                   </Grid>
//                 </Grid>
//             </Grid>
//           </Grid>
//         </CenterColumn>
//         <Dialog
//           open={openRegister}
//           onClose={() => setOpenRegister(false)}
//           maxWidth="sm"
//         >
//           <Register />
//         </Dialog>

//       </Grid>
//     </div>
//   )
// }

