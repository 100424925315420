import React, { useEffect, useRef, useState } from 'react'
import Pricing from '../pricing/Pricing'
import { CenterColumn } from './Landing'
import MainNav from '../nav/MainNav'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import LandingContactForm from './LandingContactForm'

const LandingContact = () => {
  const theme = useTheme();
  
  return (
    <Grid
      container
      id="top"
      style={{
        background: theme.palette.background.secondary,
        minHeight: '100vh'
      }}
    >
      {/* <MainNav /> */}

      <CenterColumn id="contact" alignment={{start:'flex-start'}}>
        <Grid
          container
          style={{
            padding: 16,
            marginTop: 100,
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4">
            <span style={{fontWeight:700}}>Contact</span> Us.
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            padding:{xs: '24px 16px', sm: '24px', md:'24px 48px'},
            justifyContent: 'center',
            // boxShadow:'0 0 10px #00000030',
            boxShadow:'5px 5px 10px #00000015',
            background:'#ffffffaa',
            borderRadius:2
          }}
        >
          <LandingContactForm />
        </Grid>
      </CenterColumn>
    </Grid>
  )
}

export default LandingContact