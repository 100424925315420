import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { postManageSubscription, useGetAllSubscriptions } from '../../hooks/mutations'

const PaymentPortal = () => {
  const subscriptions = useGetAllSubscriptions();

  const handlePortal = async () => {
    const management = await postManageSubscription();
    if (management.status === "success") {
      window.location = management.data.url;
    }
  }

  return (
    <Grid
      container
    >
      {/* <Typography>Manage your subscription from your customer portal below.</Typography> */}
      {
        subscriptions?.map(subscription => (
          <Grid
            container
            sx={{
              flexDirection:'column',
              alignItems:'center'
            }} 
          >
            <Typography>{subscription.plan_name}</Typography>
            <Typography>Status: {subscription.status}</Typography>
            <Typography>Current subscription start: {new Date(subscription.current_period_start).toLocaleDateString()}</Typography>
            <Typography>Current subscription end: {new Date(subscription.current_period_end).toLocaleDateString()}</Typography>
            <Button
              variant="contained"
              onClick={handlePortal}
              sx={{
                mt:1
              }}
            >
              Go To Customer Portal
            </Button>
          </Grid>
        ))
      }
      </Grid>
  )
}

export default PaymentPortal