import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UpAngleSVG } from '../../../assets/icons';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { createSubmission, putSurvey, useGetSurvey, useGetSubmission, postSurveySubmission } from '../../../hooks/mutations';
import EntranceForm from './EntranceForm';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { getRandomInts } from '../../../lib/Functions';
import { useTemp } from '../../../context/tempContext';
import FormPage from './form-display-elements/FormPage';
import CompletePage from './form-display-elements/CompletePage';


const SurveyDisplayStartCollect = (props) => {
 const { incomingSurvey, preview, surveyType, handleSurveyType, survey, token } = props;
 const [input, setInput] = useState([]);
 const [userInfo, setUserInfo] = useState(null);
 const [currentPage, setCurrentPage] = useState(0);
 const [questions, setQuestions] = useState([]); // <-- This state needs to be moved into forms state the same way we did it in the SurveyBuilder1 component
 const [grantEntrance, setGrantEntrance] = useState(true);
 const tempContext = useTemp();
 const theme = useTheme();
 const [form, setForm] = useState(incomingSurvey);
 const [status, setStatus] = useState(null);


 // console.log('questions[currentPage]', questions[currentPage]);
 console.log('input', input);


 // // Set form state based on temp survey state if preview prop is true
 // useEffect(() => {
 //   if (preview) {
 //     setForm(tempSurvey);
 //   }
 // }, [preview])


 useEffect(() => {
   if (input) {
     getSubmissionReady();
   }
 }, [input]);


 const getSubmissionReady = () => {
   const newInput = [ ...input ];
   const reducedInput = newInput.map(x => ({ question_id: x.id, body: x.choices.join(', ') }));
   console.log('reducedInput', reducedInput);
   return reducedInput;
 }


 // Set form state based on incomingForm
 useEffect(() => {
   if (incomingSurvey) {
     setForm(incomingSurvey);
   }
 }, [incomingSurvey])


 useEffect(() => {
   if (form) {
     setQuestions(form.category_set[0].question_set);
   }
 }, [form]);


 // Determine if an entrance form must be filled out to start the survey
 useEffect(() => {
   if (form && !form?.getContact) {
     setGrantEntrance(true);
   }
 }, [form]);


 // // Create initial submission when grantEntrance is set to true (after userInfo is entered)
 // useEffect(() => {
 //   if (grantEntrance && !preview) {
 //     createInitialSubmission();
 //   }
 // }, [grantEntrance])


 const reset = () => {
   setCurrentPage(0);
   setInput([]);
 };


 const nextPage = () => {
   // submitSurvey();
   // I commented this out because it was causing the submission to be saved multiple times
   setCurrentPage(prev => prev+1);
 }


 const backPage = () => {
   setCurrentPage(prev => prev-1);
 }


 const directPage = (index) => {
   setCurrentPage(index)
 }


 const handleAnswer = (obj) => {
   const { answerIndex, answer, selected, answers, goNext } = obj;
   const newInput = [...input];
   newInput[currentPage] = {...questions[currentPage], choices:answers, selected:selected};
   // console.log('newInput', newInput);
   setInput(newInput);
   if (goNext) {
     setCurrentPage(prev => questions.length-1 > prev ? prev+1 : 'complete');
   }
 }


 // Create the submission with initial user data
 const createInitialSubmission = async () => {
   const newInput = [ ...input ];
   const inputForSubmission = input.map(x => ({body: '', question_id: ''}))
   if (form) {
     const payload = {
       complete: false,
       formId: form.id,
       formTitle: form.name,
       answer_set: input,
     };
     // if (saved === 'success') {
     //   const updatedForm = putSurvey({
     //     id: form.id,
     //     submissionStarted: arrayUnion(userInfo.email)
     //   })
     //   // console.log('updated form:', updatedForm);
     // }
   } else {
     console.log('No form yet');
   }
 }


 // Updates to the submission when submit button is clicked.
 const submitSurvey = async (complete) => {
  if (input) {
    const reducedInput = getSubmissionReady();
    const submissionObj = {
      token: token,
      payload: {
        answer_set: reducedInput
      }
    }
    try {
      const saved = await postSurveySubmission(submissionObj);
      console.log('saved', saved);
      setStatus(saved.status);

      if (saved.status === "success") {
        setTimeout(() => {
          // Ensure incomingSurvey.redirect_url is defined
          let url = incomingSurvey?.redirect_url;
          
          if (url) {
            // Check if the URL starts with 'http://' or 'https://'
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
              // If not, assume it's missing and add 'http://'
              url = 'http://' + url;
            }
            console.log('url', url);
            window.open(url, '_blank');
          } else {
            console.error('Redirect URL is undefined');
          }
        }, 3000);
      } else {
        console.error('Submission failed with status:', saved.status);
      }
    } catch (error) {
      console.error('Error during survey submission:', error);
    }
  } else {
    console.log('No form yet');
  }
}

  const redirectPreview = () => {
   let url = incomingSurvey.redirect_url;
   // Check if the URL starts with 'http://' or 'https://'
   if (!url.startsWith('http://') && !url.startsWith('https://')) {
     // If not, assume it's missing and add 'http://'
     url = 'http://' + url;
   }
   window.open(url, '_blank');
 }


 return (
   grantEntrance
     ?
       form && questions &&
         <Grid
           container
           style={{
             position:'relative',
             height:'100%',
             // flexDirection:'column',
             justifyContent:'center',
             alignItems:'center',
             overflowY:'auto'
           }}
         >
           <Grid
             container
             style={{
               // position:'absolute',
               // top:60,
               // left:0
             }}
           >
             {/* <Grid
               container
               style={{
                 padding:'0 24px',
                 display:'flex',
                 alignItems:'center',
                 justifyContent:'space-between'
               }}
             >
               <Typography
                 style={{
                   fontSize:'1.3rem'
                 }}
               >
                 {form.name}
               </Typography>
               <Button
                 onClick={handleSurveyType}
               >
                 {surveyType === "build" ? 'View' : '< Build Survey'}
               </Button>
             </Grid> */}


             {
               questions.map((item, navIndex) => (
                 <Grid
                   item
                   onClick={() => {
                     if (input[navIndex]?.choices.length || input.slice(navIndex+1, input.length).filter(x => x.choices.length > 0).length) {
                       console.log('direct')
                       directPage(navIndex);
                     } else {
                       const lastAnswered = input.length ? input.filter(x => x.choices.length).length : 1;
                       console.log('last answered')
                       directPage(lastAnswered);
                     }
                   }}
                   style={{
                     position:'relative',
                     height:30,
                     cursor:'pointer'
                   }}
                   xs
                 >
                   <Grid
                     style={{
                       height:currentPage === navIndex ? '30%' : '30%',
                       transition:'.4s',
                       background: currentPage === navIndex || input[navIndex]?.choices.length ? theme.palette.primary.main : `${theme.palette.primary.light}70`,
                       border:'1px solid white'
                     }}
                   />
                   <UpAngleSVG
                     width="100%"
                     color1={currentPage === navIndex ? theme.palette.primary.main : theme.palette.primary.light}
                     style={{
                       position:'absolute',
                       width: currentPage == navIndex ? 14 : 0,
                       // bottom:30,
                       left: 'calc(50% - 10px)',
                       transition:'.4s'
                     }}
                   />
                 </Grid>
               ))
             }
           </Grid>


           <Grid container style={{justifyContent:'center', padding:36}}>
             <Grid
               item
               style={{
                 padding:32,
                 background:'#fff',
                 borderRadius:16,
                 boxShadow:'5px 5px 10px #00000015'
               }}
               xs={12}
               sm={10}
               md={8}
               lg={6}
               >
                 {
                   currentPage < questions.length
                     ?
                       <FormPage
                         question={questions[currentPage]}
                         index={currentPage}
                         handleAnswer={handleAnswer}
                         input={input}
                         preview={preview}
                         redirectPreview={redirectPreview}
                         nextPage={nextPage}
                         backPage={backPage}
                       />
                     :
                       <CompletePage submitSurvey={submitSurvey} status={status}/>
                 }
                
               </Grid>
            
           </Grid>


           <Grid
             container
           >
            
           </Grid>




         </Grid>
     :
         <Grid
           container
           style={{
             position:'relative',
             height:'calc(100vh - 59px)',
             flexDirection:'column',
             // justifyContent:'center',
             alignItems:'center',
             overflowY:'auto'
           }}
         >
           <Grid
             container
             style={{
               flexDirection:'column',
               maxWidth:500
             }}
           >
             <Typography
               style={{
                 textAlign:'center',
                 fontSize:'1.3rem',
                 textTransform:'uppercase',
                 fontWeight:600
               }}
             >
               {form?.name}
             </Typography>
             <Typography
               style={{
                 // textAlign:'center',
                 fontSize:'1rem'
               }}
             >
               {form?.details}
             </Typography>
           </Grid>


           {/* <EntranceForm setUserInfo={setUserInfo} setGrantEntrance={setGrantEntrance} form={form} /> */}
         </Grid>
 )
}


export default SurveyDisplayStartCollect
