import { useTheme } from '@emotion/react'
import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { getScreenSize, useScreenSize, useScreenSizeCalc } from '../../lib/Interface'
import { LogoHorizontal } from '../../assets/logos'
import { ScrollToGrow } from '../ui/ScrollAnimations'
import { Angle, DotPattern, Parallelogram } from '../../assets/graphics'
import MainNav from '../nav/MainNav'
import { featureList } from '../../lib/Data'
import Pricing from '../pricing/Pricing'
import { hashScroll } from '../../lib/Functions'
import LandingFeatures from './LandingFeatures'
import { landingCopy } from '../../lib/Copy'
import { Animated3Dots, AnimatedDotGrid } from '../../assets/animations'
import { CenterColumn } from './LandingHome'
import { ArrowSVG, UpCaretSVG } from '../../assets/icons'

const LandingWhyUs = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      id="top"
      style={{
        background: theme.palette.background.secondary,
        paddingTop:70,
        paddingBottom:196
      }}
    >
      <Grid
        id="why"
        container
      >
        <CenterColumn
          id="why-us"
          alignment={{x:'flex-start'}}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
              fontWeight:700,
              lineHeight:1,
              // textTransform:'uppercase',
              color:theme.palette.primary.dark, //'#fff'
              transition:'.4s',
              zIndex:2
            }}
          >
            Why Us
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '1.4rem', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
              lineHeight:1.2,
              margin:'16px 0',
              transition:'.4s',
            }}
          >
            What sets <span style={{fontSize:'1.1em', fontFamily:'fill', marginRight:'.65em', color:theme.palette.primary.main }}>f</span> apart from the other larger CRM and email marketing platforms? We're glad you asked.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '1.4rem', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
              lineHeight:1.2,
              margin:'16px 0',
              transition:'.4s',
            }}
          >
            Here are a few things we think will make you interested in working with us instead of some of the other platforms available to you.
          </Typography>
          <Grid
            container
            flexDirection="column"
          >
            {
              landingCopy.why_us.filter(x => x.hash).map((item, index) => (
                <ListButton item={item} index={index} />
              ))
            }
          </Grid>
        </CenterColumn>

        <Grid container gap={{xs:8, sm:16, lg:18, xl:32}} marginTop="48px">

          {
            landingCopy.why_us.map((item, index) => (
              <div
                key={item.title}
                id={item.hash}
                style={{
                  position:'relative',
                  width:'100%',
                  overflow:'hidden'
                }}
              >
                <Grid
                  container
                  style={{
                    position:'relative',
                    justifyContent:'center',
                    // boxShadow:'0 0 5px #00000020',
                    // background:'#ffffff',
                    padding:'24px 48px',
                    // minHeight:'100vh',
                    // maxHeight:'100vh',
                    // minHeight:400,
                    borderRadius:16,
                    opacity:.9,
                    zIndex:100
                  }}
                >
                  <CenterColumn
                    id="why-us"
                    alignment={{x:'flex-start'}}
                    style={{
                      position:'relative'
                    }}
                  >
                    <Typography
                      variant="landing_title"
                      marginBottom="16px"
                      // fontFamily="Poppins"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="landing"
                      style={{
                        whiteSpace:'pre-line'
                      }}
                    >
                      {item.body}
                    </Typography>
                    {
                      item.ctaHref && item.cta &&
                      <Grid container marginTop={2}>
                        <CtaButton
                          variant="contained"
                          href={item.ctaHref}
                        >
                          {item.cta}
                        </CtaButton>
                      </Grid>
                    }
                    {/* <DeterminedGraphic index={index} /> */}
                    {
                      item.items &&
                        <SubItems items={item.items} divKey={item.key} />
                    }
                  </CenterColumn>
                </Grid>
              </div>
            ))
          }
        </Grid>
      </Grid>
      <div
        onClick={() => hashScroll('top')}
        style={{
          position:'fixed',
          bottom:40,
          right:40,
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          background:theme.palette.tertiary.main,
          borderRadius:30,
          width:50,
          height:50,
          zIndex:1000
        }}
      >
        <UpCaretSVG width={45} color1="#FFF" />
      </div>
    </Grid>
  )
}

export default LandingWhyUs

const CtaButton = (props) => {
  const { children, onClick, href } = props;

  return (
    <Button
      variant="contained"
      onClick={onClick}
      href={href}
      style={{
        fontSize:'1.2rem'
      }}
    >
      {children}
    </Button>
  )
}

const DeterminedGraphic = (props) => {
  const { index } = props;
  const theme = useTheme();

  const optionsOne = [
    <AnimatedDotGrid
      size={500}
    />,
    <AnimatedDotGrid
      size={200}
      animationSpeed={2}
      minRadius={3}
      startColor={theme.palette.background.secondary}
      endColor={theme.palette.background.secondary}
      background={theme.palette.secondary.light}
    />,
    <Animated3Dots
      circles={[
        {cx: 150, cy:150, speed: 8, radiusValue: `120;130;120`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
        {cx: 60, cy:250, speed: 2, radiusValue: `40;45;40`, fillValue: `${theme.palette.primary.dark};${theme.palette.primary.main};${theme.palette.primary.dark}`},
      ]}
    />
  ]

  const optionsTwo = [
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
        {cx: 150, cy:170, speed: 3, radiusValue: `25;30;25`, fillValue: `${theme.palette.alt.light};${theme.palette.alt.main};${theme.palette.alt.light}`},
      ]}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
    />
  ]

  const randomOffset = {
    x: Math.random() * (20 - 10) + 10,
    y: Math.random() * (20 - 10) + 10
  }

  
  return (
    <div
      style={{
        position:'absolute',
        top:0,//`calc(50% -${randomOffset.y}vw)`, //randomOffset.y * -1,
        left: index % 2 ? null : '95%',// index % 2 ? `-${randomOffset.x}vw` : `${100 + randomOffset.x}%`,
        right: index % 2 ? '95%' : null,
        zIndex:0
      }}
    >
      {index % 2 ? optionsOne[index % optionsOne.length] : optionsTwo[index % optionsTwo.length]}
    </div>
  )
}

const SubItems = ({ items, divKey }) => {
  const theme = useTheme();
  const screenSize = useScreenSize();
  const [svgPaths, setSvgPaths] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      // Use requestAnimationFrame to ensure DOM updates are synchronized with the next paint
      requestAnimationFrame(() => connectDivs(items, divKey));
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial connection of divs
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items]);

  const connectDivs = (items, divKey) => {
    const paths = [];
    for (let i = 0; i < items.length - 1; i++) {
      const topDiv = document.getElementById(`${i + 1}-top-${divKey}`);
      const bottomDiv = document.getElementById(`${i}-bottom-${divKey}`);
  
      if (topDiv && bottomDiv) {
        const topRect = topDiv.getBoundingClientRect();
        const bottomRect = bottomDiv.getBoundingClientRect();
        const containerRect = document.getElementById(`svg-container-${divKey}`).getBoundingClientRect();
  
        const x1 = bottomRect.left + bottomRect.width / 2 - containerRect.left;
        const y1 = bottomRect.top + bottomRect.height / 2 - containerRect.top;
        const x2 = topRect.left + topRect.width / 2 - containerRect.left;
        const y2 = topRect.top + topRect.height / 2 - containerRect.top;
  
        const curveHeight = Math.abs(y2 - y1) / 2;
        const controlPointOffset = Math.min(curveHeight, 50);
  
        let pathData;
        if (x1 < x2) {
          // Case where bottom div is on the left and top div is on the right
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 + controlPointOffset} ${y1 + curveHeight}
            L ${x2 - controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        } else {
          // Case where bottom div is on the right and top div is on the left
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 - controlPointOffset} ${y1 + curveHeight}
            L ${x2 + controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        }
  
        paths.push(<path d={pathData} stroke={theme.palette.primary.main} fill="transparent" strokeWidth="3" strokeDasharray={10} key={`${i}-${divKey}`} />);
      }
    }
  
    setSvgPaths(paths);
  };
  
  return (
    <div style={{ position: 'relative' }}>
      <svg id={`svg-container-${divKey}`} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
        <g id="svg-paths">{svgPaths}</g>
      </svg>
      {items.map((subItem, subIndex) => (
        <Grid
          container
          key={subIndex}
          style={{
            justifyContent: subIndex % 2 ? 'flex-start' : 'flex-end',
            // marginTop: subIndex === 0 ? -16 : 0,
          }}
        >
          <Grid
            container
            style={{
              position: 'relative',
              width: '90%',
              borderRadius: 16,
              background: `${theme.palette.alt.light}aa`,
              marginBottom: 48,
              opacity: 0.9,
              zIndex: 1000
            }}
          >
            <div
              id={`${subIndex}-top-${divKey}`}
              style={{
                display: subIndex === 0 ? 'none' : 'inherit',
                position: 'absolute',
                top: -13,
                left: screenSize.xs ? (subIndex % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
                width: 26,
                height: 26,
                borderRadius:26,
                background: theme.palette.primary.main,
                // outline: `3px solid ${theme.palette.background.secondary}`
              }}
            />
            <div
              id={`${subIndex}-bottom-${divKey}`}
              style={{
                display: subIndex === items.length-1 ? 'none' : 'inherit',
                position: 'absolute',
                bottom: -13,
                left: screenSize.xs ? (subIndex % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
                width: 26,
                height: 26,
                borderRadius:26,
                background: `${theme.palette.alt.light}`,
                outline: `3px solid ${theme.palette.background.secondary}`
              }}
            />
            <Typography
              variant="h3"
              style={{
                fontSize: '1.5rem',
                fontWeight: 600,
                color: '#fff',
                background: theme.palette.primary.main,
                width: '100%',
                padding: '8px 24px',
                borderRadius:'16px 16px 0'
              }}
            >
              {subItem.title}
            </Typography>
            <Typography
              variant="landing"
              sx={{
                padding: {xs: '24px', lg:'24px 48px 48px'},
              }}
            >
              {subItem.body}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const ListButton = ({ item, index }) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  return (
    <a
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // href={`#${item.hash}`}
      onClick={() => hashScroll(item.hash)}
      style={{textDecoration:'none', color:'inherit', display:'flex', alignItems:'center', cursor:'pointer'}}
    >
      <Typography variant="landing">
        {index+1}. {item.title}
      </Typography>
      <div
        style={{
          position:'relative',
          width:50,
          display:'flex',
          alignItems:'center',
          height:'100%',
          marginLeft:8,
          overflow:'hidden'
        }}
      >
        <ArrowSVG width={14} color1={theme.palette.primary.main} style={{marginLeft: hover ? 0 : -14, transition:'.3s'}} />
      </div>
    </a>
  )
}