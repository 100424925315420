import React, { useEffect, useState } from 'react'
import SignupWizard from '../components/signup/SignupWizard'

const SignupPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const [djangoState, setDjangoState] = useState(currentEnv === "STAGING" ? 1 : null);
  console.log('djangoState', djangoState);
    
  // Get the script element by its id
  const scriptElement = document.getElementById('react_root');
  console.log('scriptElement', scriptElement);

  useEffect(() => {
    console.log('runnin get stage function');
    if (!djangoState) {
      console.log('inside of django state effect');
      getStageFromDjango();
    }
  }, [scriptElement, djangoState]);

  const getStageFromDjango = () => {
    if (scriptElement) {
      // Access the content of the script element
      const djangoStageString = scriptElement?.textContent;
      console.log('djangoStageString', djangoStageString);
    
      // Parse the JSON data
      const djangoStage = JSON.parse(djangoStageString);
      console.log('djangoStage', djangoStage);
  
      // Get the current id representing the stage
      const djangoStageId = djangoStage.id
      console.log('djangoStageId', djangoStageId);
  
      let id = null;
  
      if (djangoStageId === "register_root") {
        id = 1;
      } else if (djangoStageId === "login_root") {
        id = 2;
      } else if (djangoStageId === "tenant_root") {
        id = 3;
      } else if (djangoStageId === "pricing_root") {
        id = 4;
      }
  
      // Set the contact id state from the JSON data
      setDjangoState(id)
    }
  }


  
  return (
    // <MainPageTemplate title="Signup">
    djangoState
      ? <SignupWizard djangoState={djangoState} />
      : null
    // </MainPageTemplate>
  )
}

export default SignupPage