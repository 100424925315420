import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import EmailTemplatesMenu from '../components/email_builder/EmailTemplatesMenu'
import EmailTemplates from '../components/email_builder/EmailTemplates'

const EmailTemplatesPage = () => {
  return (

    // <MainPageTemplate title="Welcome">
      <EmailTemplates />
    // </MainPageTemplate>

    
  )
}

export default EmailTemplatesPage