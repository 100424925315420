import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import TermsCookies from '../components/terms/TermsCookies'

const TermsCookiesPage = () => {
  return (
    <LandingTemplate title="Cookies Policy">
      <TermsCookies />
    </LandingTemplate>
  )
}

export default TermsCookiesPage
