import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';


const CompletePage = (props) => {
 const { submitSurvey, status } = props;

 const checkSubmitStatus = async () => {
   if (status === null) {
     try {
       await submitSurvey();
     } catch (error) {
       console.error('error submitting survey', error);
     }
   }
 };

 return (
   <Grid container direction="column" alignItems="center" justifyContent="center">
     {status === null ? (
       <>
         <Typography style={{fontSize: '1.25rem', marginTop: '1rem', marginBottom: '1rem', fontWeight: '400'}}>
          All set. Just press submit!
          </Typography>
         <Button variant="contained" onClick={submitSurvey} >
           Submit
         </Button>
         {checkSubmitStatus}
       </>
    ) : status === 'success' ? (
       <>
         <Typography style={{fontSize: '1.25rem', marginTop: '1rem', marginBottom: '1rem', fontWeight: '400'}}>
          That's it! Good job.
          </Typography>
       </>
     ) : (
       <>
         <Typography style={{fontSize: '1.25rem', marginTop: '1rem', marginBottom: '1rem', fontWeight: '400'}}>
          There was an error submitting the survey.
          </Typography>
       </>
     )}
   </Grid>
 );
};


export default CompletePage;