import React, { useState, useEffect } from 'react'
import SenderDetailsWizard from '../components/account_settings/SenderDetailsWizard'
import { useGetAllSenderDetails } from '../hooks/mutations';

const SenderDetailsWizardPage = () => {
  const [reload, setReload] = useState();
  const senderDetails = useGetAllSenderDetails(reload);
  const existingSenderDetail = senderDetails?.length ? senderDetails[0] : null;

  console.log('reload', reload);
  
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);


  
  return (
    // <MainPageTemplate title="Signup">
      <SenderDetailsWizard
        existingSenderDetail={existingSenderDetail}
        setReload={setReload}
      />
    // </MainPageTemplate>
  )
}

export default SenderDetailsWizardPage