import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import Landing from '../components/landing/Landing'

const LandingPage = () => {
  return (
    <LandingTemplate title="Welcome">
      <Landing />
    </LandingTemplate>
  )
}

export default LandingPage
