import React, { cloneElement, useEffect, useLayoutEffect, useState } from 'react'
import { useApi } from '../../context/apiContext';
import DragAndDropFormCustomFieldOrder from '../ui/DragAndDropFormCustomFieldOrder';
import { getAllCustomFields, putUserDataBag, useGetUserDataBag } from '../../hooks/mutations';
import { useTheme } from '@emotion/react';
import { Button, Grid, IconButton, Switch, Typography } from '@mui/material';
import { CloseFilledSVG, EditSVG } from '../../assets/icons';
import { InputField } from '../ui/Fields';
import CustomTooltip from '../ui/CustomTooltip';
import Infotip from '../ui/Infotip';

const customGeneralSettings = {
  show_info: false
}

const SettingsGeneral = () => {
  const apiContext = useApi();
  const contextUserDataBag = apiContext.contextUserDataBag;
  const savedGeneralSettings = apiContext.contextUserDataBag?.generalSettings;
  const contextCustomFields = apiContext.contextCustomFields;
  const [updatedGeneralSettings, setUpdatedGeneralSettings] = useState(savedGeneralSettings);
  const [currentLayout, setCurrentLayout] = useState(null);
  const [activeLayout, setActiveLayout] = useState()
  const userDataBag = useGetUserDataBag();
  const theme = useTheme();

  // console.log('userDataBag', userDataBag)
  console.log('updatedGeneralSettings', updatedGeneralSettings)

  // Initialize field layouts
  useLayoutEffect(() => {
    if (userDataBag?.data?.general) {
      setUpdatedGeneralSettings(userDataBag.data.general);
    } else {
      setUpdatedGeneralSettings(customGeneralSettings);
    }
  }, [userDataBag?.data?.general])
  
  const updateDataBag = async () => {

    const dataObj = {
      id: userDataBag.id,
      data: {
        data: {
          ...userDataBag.data,
          general: updatedGeneralSettings
        }
      }
    }

    const updateDataBag = await putUserDataBag(dataObj);

    if (updateDataBag.status === 'success') {
      // console.log(updateDataBag.response);
      apiContext.handleSetContextUserDataBag(updateDataBag.data);
    }
  }

  // Save data bag field updates after no changes have been made for 3 seconds
  useEffect(() => {
    let timeoutId;
    if (updatedGeneralSettings && userDataBag) {
      timeoutId = setTimeout(() => {
        updateDataBag();       
      }, 100);
    }
  
    return () => {
      // Cleanup function
      clearTimeout(timeoutId);
    };
  }, [updatedGeneralSettings]);
  

  return (
    userDataBag &&
    <Grid
    >
      <Grid
        style={{
          display:'flex',
          flex:1,
          height:70,
          borderBottom:'1px solid #aaa',
          alignItems:'center',
          justifyContent:'space-between',
          padding:'0 8px',
        }}
      >
        <div style={{display:'flex', alignItems:'center', gap:8}}>
          <Typography
            style={{
              fontSize:'1.1rem',
              fontWeight:500,
              color:'#aaa',
              marginLeft:8
            }}
          >
            General
          </Typography>
          <Infotip
            question
            title="General"
            tip="Update all your general settings here"
            messages={{
              one: "Contact Profiles allow you to quickly display a predefined set of fields for a contact.",
              two: "Profiles do not modify contact data. It only changes the fields that are currently being displayed. All other fields are still available when the profile is turned off."
            }}
          />
        </div>
      </Grid>
      <Grid
        container
      >
        {/* <Grid
          item
          style={{
            height:'calc(100vh - 70px)',
            paddingTop:16,
            width: 250,
            borderRight:'1px solid #aaa'
          }}
          xs="auto"
        >
          {
            updatedGeneralSettings?.map((layout, index) => (
              <MenuTab
                key={layout.id}
                tab={layout}
                index={index}
                currentLayout={currentLayout}
                setCurrentLayout={setCurrentLayout}
                handleCustomFieldLabel={handleCustomFieldLabel}
              />
            ))
          }
        </Grid> */}

        <Grid
          style={{
            height:'calc(100vh - 70px)',
            borderRight:'1px solid #aaa',
            overflow:'auto'
          }}
        >
          <Grid
            style={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              // width: currentLayout ? '100%' : '0%',
              transition: '.4s',
              overflowX:'hidden',
              paddingTop:16,
              width: 300,
              // height:'100vh'
            }}
          >
            {
              // TODO: Make this setting show or hide all (except 100% necessary) Infotip components
              // This setting is available in apiContext.contextUserDataBag.general.show_info
            }
            <SettingsTab>
              <Typography>Show Info icons</Typography>
              <Switch
                value={updatedGeneralSettings.show_info}
                onChange={(e) => setUpdatedGeneralSettings(prev => ({ ...prev, show_info: e.target.checked }) )}
              />
            </SettingsTab>
            {
              // TODO: Make this setting default expand/collapse custom fields/mailing lists/tags accordions in ContactEdit component
              // This setting is available in apiContext.contextUserDataBag.general.show_info
            }
            <SettingsTab>
              <Typography>Default expand contact items</Typography>
              <Switch
                value={updatedGeneralSettings.expand_contact_lists}
                onChange={(e) => setUpdatedGeneralSettings(prev => ({ ...prev, expand_contact_lists: e.target.checked }) )}
              />
            </SettingsTab>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default SettingsGeneral

const SettingsTab = (props) => {
  const { children } = props;
  
  return (
    <Grid
      container
      // onClick={() => setCurrentLayout(tab.id)}
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
      style={{
        // display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        width:'100%',
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        transition:'.4s',
        paddingLeft:24,
        cursor:'pointer',
      }}
    >
      <Grid
        item
        xs
      >
        { cloneElement( children.length ? children[0] : children, { } ) }
      </Grid>
      <Grid
        item
        xs="auto"
      >
        { cloneElement( children.length ? children[1] : children, { } ) }
      </Grid>
    </Grid>
  )
}

const MenuTab = (props) => {
  const { tab, index, currentLayout, setCurrentLayout, handleCustomFieldLabel } = props;
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const theme = useTheme();

  return (
    <div
      onClick={() => setCurrentLayout(tab.id)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        background: currentLayout === tab.id ? `${theme.palette.primary.light}10` : 'none',
        transition:'.4s',
        paddingLeft:24,
        cursor:'pointer',
        // borderRight: currentLayout === tab.id ? `10px solid blue` : 'none'
      }}
    >
      {
        edit
          ?
            <InputField
              value={tab.label}
              onChange={(e) => handleCustomFieldLabel(e.target.value, index)}
              onBlur={() => setEdit(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setEdit(false);
                }
              }}
              style={{
                maxWidth:170
              }}
            />
          :
              <CustomTooltip
                title={tab.label.length > 14 ? tab.label : null} // Only show tooltip if label is long enough to overflow
              >
                <Typography
                  sx={{ // Match nav button style in django
                    fontSize: '1rem',
                    fontWeight: currentLayout === tab.id ? 500 : 400,
                    lineHeight:1.2,
                    color:'inherit',
                    textTransform:'none',
                    maxWidth:150,
                    overflow:'hidden',
                    whiteSpace:'nowrap',
                    textOverflow:'ellipsis',
                    '&&:hover': {
                      fontWeight:500,
                      background:'none'
                    }
                  }}
                >
                  {index+1}. {tab.label}
                </Typography>
              </CustomTooltip>
      }
      {
        hover && !edit &&
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEdit(true)
            }}
            style={{marginLeft:8}}
          >
            <EditSVG width={15} />
          </IconButton>
      }
      <div
        style={{
          position:'absolute',
          top: currentLayout === tab.id ? 0 : '50%',
          right:0,
          background:theme.palette.primary.main,
          height: currentLayout === tab.id ? '100%' : '0%',
          width:'10px',
          transition:'.4s'
        }}
      />
      <div
        style={{
          position:'absolute',
          top: 'calc(50% - 8px)',
          right:18,
          opacity: currentLayout === tab.id ? 1 : 0,
        }}
      >
        <a
          onClick={(e) => {
            if (currentLayout === tab.id) {
              e.stopPropagation();
              setCurrentLayout(null)
            }
          }}
          style={{
            position:'relative',
            zIndex:1200
          }}
        >
          <CloseFilledSVG width={16} color1={`${theme.palette.primary.light}77`} />
        </a>
      </div>
    </div>
  )
}