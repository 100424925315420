import { Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { theme } from '../../../../theme';
import { DeleteSVG } from '../../../../assets/icons';


const AnswerElement = (props) => {
  const { index, answer, answerIndex, handlePageAnswerChange, deleteAnswer } = props;
  const [differentValue, setDifferentValue] = useState(false);

  return (
    <Grid
      container
      style={{
        borderTop: '1px solid #ccc',
        padding: '16px 0',
        alignItems: differentValue ? 'flex-end' : 'center'
      }}
    >
      {
      // differentValue
      //   ?
      //   <Grid
      //     item
      //     xs
      //   >
      //     <TextField
      //       name="answer"
      //       label={`Answer ${answerIndex + 1}`}
      //       style={{
      //         margin: '8px 0',
      //       }}
      //       fullWidth
      //       autoFocus
      //       value={answer.answer}
      //       onChange={(e) => handlePageAnswerChange('answer', index, answerIndex, e.target.value)} />
      //     <TextField
      //       name="value"
      //       label={`Value ${answerIndex + 1}`}
      //       style={{
      //         margin: '8px 0'
      //       }}
      //       fullWidth
      //       autoFocus
      //       value={answer.value}
      //       onChange={(e) => handlePageAnswerChange('value', index, answerIndex, e.target.value)} />
      //   </Grid>
      //   :
        <>
          <Grid
            item
            xs
          >
            <TextField
              name="answer"
              label={`Answer ${answerIndex + 1}`}
              style={{
                margin: '2px 0',
              }}
              fullWidth
              autoFocus
              value={answer}
              onChange={(e) => {handlePageAnswerChange(0, index, answerIndex, 'choice_text', e.target.value);}}
            />
          </Grid>
        </>
      }
      <Grid
        item
        xs="auto"
      >
        {/* <Button
          onClick={() => {
            if (differentValue) {
              setDifferentValue(false);
              handlePageAnswerChange('value', index, answerIndex, answer.answer);
            } else {
              setDifferentValue(true);
            }
          }}
          style={{
            marginBottom: differentValue ? 18 : 0,
            padding: '0 8px'
          }}
        >
          {differentValue ? 'Remove' : 'Add Value'}
        </Button> */}

        <DeleteSVG
          onClick={() => deleteAnswer(0, index, answerIndex)}
          width={13}
          color1={`${theme.palette.secondary.main}aa`}
          style={{
            margin:'0 8px 0 16px'
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AnswerElement