import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  Typography,
  Collapse,
  Dialog,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, CheckmarkSVG, CloseRoundedSVG, DeleteSVG, DownTriangleSVG, EmailSVG, EventSVG, FileSVG, FilterSVG, GallerySVG, LinkSVG, SelectSVG, NumberSVG, PhoneSVG, RichTextSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG, WarningSVG, ListSVG, EditSVG, CloseFilledSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { deleteCustomField, putCustomField, useGetAllCustomFields, useGetCustomFields } from '../../hooks/mutations';
import CreateCustomFields from './CreateCustomFields';
import { customFieldColumns } from '../../lib/Data';
import ImportCsvHeaders from '../import/ImportCsvHeaders';
import CustomFieldComparison from './CustomFieldComparison';
import Papa from 'papaparse';
import { translateFieldType } from '../../lib/Functions';
import { InputField } from '../ui/Fields';
import Paginate from '../ui/Paginate';
import { DjangoComponents } from '../../templates/DjangoStyles';
import { useApi } from '../../context/apiContext';
import CustomTooltip from '../ui/CustomTooltip';
import SnackbarWrapper from '../ui/SnackbarWrapper';

const CustomFields = (props) => {
  const { importOnly } = props;
  const theme = useTheme();
  const [reload, setReload] = useState(true);
  const [pages, setPages] = useState({id: 1, count:null, next:null, prev:null});
  const [destinationPageId, setDestinationPageId] = useState(1);
  const incomingCustomFields = useGetCustomFields(reload, destinationPageId);
  const [newCustomFields, setNewCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isImportingHeaders, setIsImportingHeaders] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [csvFile, setCsvFile] = useState(null);
  const existingNameArray = customFields ? customFields.map(x => x.field_name) : [];
  const [snackMessage, setSnackMessage] = useState("");

  const screenSize = useScreenSize();
  const apiContext = useApi();

  // console.log('reload', reload)
  // console.log('pages', pages)
  // console.log("snackMessage: ", snackMessage, "data_type: ", typeof snackMessage);

  // Reset reload after it is switched to true
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])
  
  // Set state if importOnly is true. This is used as the first step in importing a csv
  useEffect(() => {
    if (importOnly) {
      setIsImportingHeaders(true);
    }
  }, [importOnly])
  
  // Set custom field state to incoming data on load
  useEffect(() => {
    if (incomingCustomFields && resetSearch) {
      setCustomFields(incomingCustomFields.results);
      setResetSearch(false);
    }
  }, [incomingCustomFields, resetSearch]);

  // Update both customFields and contextCustomFields after incomingCustomFields is reloaded
  useEffect(() => {
    if (incomingCustomFields ) {
      console.log('incoming customs', incomingCustomFields)
      setCustomFields(incomingCustomFields.results);
      apiContext.reduceCustomFields(incomingCustomFields.results)
      apiContext.syncAllFields(incomingCustomFields.results)
    }
  }, [incomingCustomFields]);

  useEffect(() => {
    if (newCustomFields.length) {
      setIsAddingField(true);
    }
  }, [newCustomFields])

  // Handle uploading of csv file
  useEffect(() => {
    if (csvFile) {
      handleUpload();
    }
  }, [csvFile]);

    // Set initial custom fields state and pages state from incomingCustomFields get custom fields api response
    useEffect(() => {
      if (incomingCustomFields) {
        setCustomFields(incomingCustomFields.results);
        const next = incomingCustomFields.next?.split('?page=')[1] || null;
        // console.log('next', next);
        const prev = incomingCustomFields.previous?.split('?page=')[1] || null;
        // console.log('prev', prev);
        const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
        setPages({
          id: id,
          count: incomingCustomFields.count,
          next: next,
          prev: prev
        })
      }
    }, [incomingCustomFields])
  

  const nextPage = () => {
    if (pages.next) {
      setDestinationPageId(prev => prev + 1);    
    }
  }

  const prevPage = () => {
    if (pages.previous) {
      setDestinationPageId(prev => prev - 1);    
    }
  }

  const directPage = (pageNumber) => {
    setDestinationPageId(pageNumber);    
  }


  const handleUpload = async () => {
    if (!csvFile) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvText = e.target.result;
      const { data } = Papa.parse(csvText, { header: false });
      const headerData = data[0];
      setHeaders(headerData);
    };

    reader.readAsText(csvFile.file);
  }

  const resetCsvFile = () => {
    setIsImportingHeaders(false);
    setCsvFile(null);
    setHeaders(null);
    setNewCustomFields([]);
  }

  const cancelNewFields = () => {
    if (setIsAddingField) {
      setIsAddingField(false);
    }
    setNewCustomFields([]);
    resetCsvFile();
  };

  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          // borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 0 10px #00000030',
          width: '100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >
        <DjangoComponents inner>
          <DjangoComponents card padding>
            <Grid
              container
              style={{
                justifyContent:'flex-end',
                // marginBottom:16
              }}
            >
              {/* <Typography
                sx={{
                  fontSize:'1.8rem',
                  fontWeight:600,
                  color:'#ccc',
                }}
              >
                Custom Fields
              </Typography> */}
              <Grid
                style={{
                  display:'flex',
                  gap:16
                }}
              >
                {
                  !isAddingField && !isImportingHeaders
                    ?
                      <ReusableSearch
                        useSearch
                        placeholder="Search Fields"
                        content={incomingCustomFields?.results}
                        setContent={setCustomFields}
                        searchFields={['field_name']}
                      />
                    :
                      null
                }
                {
                  isImportingHeaders &&
                    <Button
                      onClick={resetCsvFile}
                      startIcon={<CloseFilledSVG width={15} />}
                    >
                      Cancel
                    </Button>
                }
                {
                  isAddingField &&
                    <Button
                      onClick={cancelNewFields}
                      startIcon={<CloseFilledSVG width={15} />}
                    >
                      Cancel
                    </Button>
                }
                {
                  !isAddingField && !isImportingHeaders
                    ?
                      <>
                      <CustomTooltip
                        title="Upload CSV"
                      >
                        <Button
                          onClick={() => setIsImportingHeaders(true)}
                          variant="contained"
                          style={{
                            columnGap:12,
                          }}
                        >
                          <UploadSVG width={20} color1="#fff" />
                          { screenSize.sm ? null : "Import From CSV"}
                        </Button>
                        </CustomTooltip>
                        <Button
                          onClick={() => setIsAddingField(true)}
                          variant="contained"
                          style={{
                            columnGap:12,
                          }}
                        >
                        <AddSVG width={16} color1="#fff" />
                          { screenSize.sm ? null : "New Field"}
                          </Button>
                      </>
                    :
                      null
                }
              </Grid>
            </Grid>
            <Collapse
              in={isAddingField}
              style={{
                width:'100%'
              }}
            >
              <CreateCustomFields
                setReload={setReload}
                setIsAddingField={setIsAddingField}
                incomingNewCustomFields={newCustomFields}
                resetCsvFile={resetCsvFile}
                setSnackMessage={setSnackMessage}
              />
            </Collapse>
            <Collapse
              in={isImportingHeaders}
              style={{
                width:'100%'
              }}
            >
              {
                !headers
                  ?
                    <ImportCsvHeaders
                      setHeaders={setHeaders}
                      csvFile={csvFile}
                      setCsvFile={setCsvFile}
                      resetCsvFile={resetCsvFile}
                    />
                  :
                    <CustomFieldComparison
                      setIsImportingHeaders={setIsImportingHeaders}
                      customFields={[ {field_name: 'Name'}, {field_name: 'Email'}, ...customFields || [] ]}
                      headers={headers}
                      newCustomFields={newCustomFields}
                      setNewCustomFields={setNewCustomFields}
                      resetCsvFile={resetCsvFile}
                    />
              }
            </Collapse>
          </DjangoComponents>
          
            {
              importOnly
                ? null
                :
                  <DjangoComponents card>
                      <HeaderRow />
                    <div style={{maxHeight:800, overflow:'auto'}}>
                      <TableWrapper>
                        {
                          customFields
                            ?
                              customFields.map((customField, index) => (
                                <TableRow
                                  key={customField.id}
                                  customField={customField}
                                  index={index}
                                  setReload={setReload}
                                  existingNameArray={existingNameArray}
                                  setSnackMessage={setSnackMessage}
                                />
                              ))
                            :
                              <Grid
                                container
                                style={{
                                  justifyContent:'center',
                                  padding:'24px 0'
                                }}
                              >
                                <Typography>No custom fields have been created yet.</Typography>
                              </Grid>
                        }
                      </TableWrapper>
                    </div>
                      <Paginate
                        currentPage={destinationPageId}
                        count={pages.count}
                        prevClick={prevPage}
                        nextClick={nextPage}
                        directPage={directPage}
                      />
                  </DjangoComponents>
              }  
            <SnackbarWrapper
              onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
              notice={snackMessage}
            />
        </DjangoComponents>
      </Grid>
    </Grid>
  );
};

export default CustomFields;

export const selectedIcons = {
  text: {label: 'text', icon: <TextSVG width={20} />}, // <Typography style={{fontStyle:'italic', lineHeight:1, textTransform:'uppercase', fontWeight:800}}>T</Typography>,
  number: {label: 'number', icon: <NumberSVG width={30} />}, //<Typography style={{lineHeight:1, fontSize:'.75rem', fontWeight:900}}>123</Typography>,
  email: {label: 'email', icon: <EmailSVG width={20} />},
  date: {label: 'date', icon: <EventSVG width={20} />},
  enum: {label: 'select', icon: <SelectSVG width={17} />},
  list: {label: 'list', icon: <ListSVG width={17} />},
  bool: {label: 'checkbox', icon: <CheckmarkSVG width={20} />},
  image_file: {label: 'image', icon: <GallerySVG width={22} />},
  file: {label: 'file', icon: <FileSVG width={13} />},
  url: {label: 'link', icon: <LinkSVG width={22} />},
  large_text_field: {label: 'rich text', icon: <RichTextSVG width={28} />},
  phone_number: {label: 'phone', icon: <PhoneSVG width={13} />},
}

const HeaderRow = (props) => {
  const { align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
      }}
    >
      {
        customFieldColumns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        // maxHeight:600
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { customField, index, setReload, existingNameArray, setSnackMessage } = props;
  const [editField, setEditField] = useState(customField);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [notUnique, setNotUnique] = useState(false);
  const theme = useTheme();
  const apiContext = useApi();

  // console.log('customField', customField);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  }

  const deleteField = async () => {
    setDeleting(true);
    const deletedField = await deleteCustomField(customField.id);
    if (deletedField.status === 'success') {
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setSnackMessage({ open: true, message: 'Custom Field deleted', severity: 'success' })
      
    }
  }

  const saveField = async () => {
    try {
      setSaving(true);
  
      const customFieldObj = {
        customFieldId: editField.id,
        payload: editField
      }
      const savedField = await putCustomField (customFieldObj);
  
      if (savedField.status === 'success') {
        setOpenEdit(false);
        setSaved(true);
        setSaving(false);
        handleCloseEdit(true);
        setReload(true);
        setSnackMessage({ open: true, message: 'Custom Field updated', severity: 'success' })
      }
    }

    catch (error) {
      console.log('error', error)
    }
  }
  
  const handleEditName = (value) => {
    const selectedFieldArray = existingNameArray.filter(x => x !== customField.field_name);
    const uniqueExistingCheck = selectedFieldArray.includes(value);

    if (uniqueExistingCheck) {
      setNotUnique(true);
    } else {
      setNotUnique(false);
    }

    setEditField({ ...editField, field_name: value })
  }

  const handleEditEnum = (value, enum_index) => {
    const newField = { ...customField };
    const enums = newField.enum_values;
    enums[enum_index] = value;
    setEditField({ ...editField, enum_values: enums})
  }

  const handleAddEnum = () => {
    const newField = { ...customField };
    const enums = [...newField.enum_values, ''];
    setEditField({ ...editField, enum_values: enums})
  }

  const handleDeleteEnum = (option) => {
    const newField = { ...editField };
    const updatedEnums = newField.enum_values.filter(x => x !== option)
    setEditField({ ...editField, enum_values: updatedEnums})
  }

  useEffect(() => {
    if (deleted) {
      setTimeout(() => {
        setReload(true);
        // setSnackMessage({ open: true, message: 'Custom Field deleted', severity: 'success' })
      }, 1000);
    }
  }, [deleted])

  

  return (
    <Grid
      container
      style={{
        background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'12px 32px',
        border: deleted ? `2px solid ${theme.palette.warning.main}` : null,
        opacity: deleted ? 0 : 1,
        transition: 'opacity 1s'
      }}
    >
      {
        customFieldColumns.map(item => (
          <TableColumn item={item}>
            {
              item.id === 'data_type' &&
                <Grid
                  style={{
                    width:50,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    marginLeft:-10
                  }}
                >
                  {cloneElement(selectedIcons[customField.data_type].icon, {color1:'#777'})}
                </Grid>
            }
            {
              item.id === 'actions' &&
                <Grid
                  style={{
                    display:'flex',
                    alignItems:'center',
                    gap:8
                  }}
                >
                  {/* <VisibleSVG width={20} color1="#777" /> */}
                  <Tooltip
                    title="Edit Custom Field"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          background: theme.palette.primary.main,
                          px:2,
                          py:1
                        },
                        "& .MuiTooltip-arrow": {
                          color: theme.palette.primary.main,
                        }
                      }
                    }}
                  >
                    <IconButton
                      onClick={() => setOpenEdit(true)}
                    >
                      <EditSVG width={16} color1="#777" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Delete Custom Field"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          background: theme.palette.primary.main,
                          px:2,
                          py:1
                        },
                        "& .MuiTooltip-arrow": {
                          color: theme.palette.primary.main,
                        }
                      }
                    }}
                  >
                    <IconButton
                      onClick={() => setOpenConfirmDelete(true)}
                    >
                      <DeleteSVG width={13} color1="#777" />
                    </IconButton>
                  </Tooltip>
                </Grid>
            }
            <Typography style={{ fontSize:'1rem'}}>
              {
                item.id === 'data_type'
                  ? translateFieldType(customField[item.id])
                  : customField[item.id]
              }
            </Typography>
          </TableColumn>
        ))
      }

      <Dialog
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
      >
        <Grid
          container
          style={{
            padding:32,
            paddingTop:16,
            width:'100%',
            flexDirection:'column',
            alignItems:'center'
          }}
        >
          <Grid
            style={{
              display:'flex',
              gap:8,
              alignItems:'center',
              paddingBottom:16
            }}
          >
            <WarningSVG width={24} color1={theme.palette.warning.main} />
            <Typography
              style={{
                textTransform:'uppercase',
                fontWeight:700,
                color: theme.palette.warning.main
              }}
            >
              Delete Custom Field
            </Typography>
          </Grid>
          <Typography
            style={{
              fontSize:'1.3rem'
            }}
          >
            Are you sure you want to delete <span style={{fontWeight:700}}>{customField.field_name}</span>?
          </Typography>
          <Typography

          >
            This will remove the custom field from all contacts that currently use it. All information stored in this field will be permanently lost. <span style={{fontWeight:500, fontStyle:'italic'}}>This can not be undone</span>.
          </Typography>

          <Grid
            container
            style={{
              marginTop:16,
              justifyContent:'space-between'
            }}
          >
            <Button
              color="mid"
              variant="outlined"
              startIcon={<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />}
              onClick={handleCloseConfirmDelete}
            >
              Cancel
            </Button>
            <Button
              color="warning"
              variant="contained"
              startIcon={deleting ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : <DeleteSVG width={12} color1="#fff" />}
              onClick={deleteField}
            >
              {deleting ? 'Deleting' : 'Delete'}
            </Button>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
      >
        <Grid
          container
          style={{
            padding:32,
            paddingTop:16,
            width:'100%',
            // flexDirection:'column',
            // justifyContent:'center',
            alignItems:'center'
          }}
        >
          <Grid
            container
            style={{
              display:'flex',
              gap:8,
              justifyContent:'center',
              paddingBottom:16
            }}
          >
            <EditSVG width={18} color1={theme.palette.primary.main} />
            <Typography
              style={{
                textTransform:'uppercase',
                fontWeight:700,
                color: theme.palette.primary.main
              }}
            >
              Edit Custom Field
            </Typography>
          </Grid>
          {/* <Typography
            style={{
              fontSize:'1.3rem',
              fontWeight:700
            }}
          >
            {editField.field_name}
          </Typography> */}

          <InputField
            label='Field Name'
            placeholder='Field Name'
            value={editField.field_name}
            onChange={(e) => handleEditName(e.target.value)}
            margin={notUnique || !editField.field_name.length ? false : true}
          />
          <Grid
            container
            style={{
              marginBottom: notUnique || !editField.field_name.length ? 16 : 0

            }}
          >
            {
              notUnique
                ?
                  <>
                    <Typography
                      color="warning.main"
                      style={{
                        marginTop:8,
                      }}
                    >
                      That field already exists.
                    </Typography>
                  </>
                :
                  null
            }
            {
              !editField.field_name.length
                ?
                  <>
                    <Typography
                      color="warning.main"
                      style={{
                        marginTop:8
                      }}
                    >
                      Names cannot be blank.
                    </Typography>
                  </>
                :
                  null
            }

          </Grid>


          {
            editField.enum_values
              ?
                <>
                  <Typography
                    style={{
                      margin:'0 16px',
                      fontWeight:600,
                      color: theme.palette.primary.main
                    }}
                  >
                    Options
                  </Typography>

                  {
                    editField.enum_values.map((value, enum_index) => (
                      <InputField
                        key={`${value}-${enum_index}`}
                        // label='Field Name'
                        placeholder='Field Name'
                        value={value}
                        onChange={(e) => handleEditEnum(e.target.value, enum_index)}
                        marginAmount="4px 0"
                        customInputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              onClick={() => handleDeleteEnum(value)}
                            >
                              <CloseFilledSVG
                                width={20}
                                color1={theme.palette.mid.main}
                              />
                            </IconButton>
                          </InputAdornment>,
                        }}
                      />
                    ))
                  }
                  <Button
                    onClick={handleAddEnum}
                    // variant="outlined"
                    startIcon={<AddSVG width={10} color1={theme.palette.primary.main} />}
                    fullWidth
                  >
                    Add Option
                  </Button>
                </>
              :
                <InputField
                  label='Default Value'
                  placeholder='Default Value'
                  value={editField.field_default_value}
                  onChange={(e) => setEditField({ ...editField, field_default_value: e.target.value })}
                  margin
                />

          }

          <Grid
            container
            style={{
              marginTop:16,
              justifyContent:'space-between'
            }}
          >
            <Button
              color="mid"
              variant="outlined"
              startIcon={<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />}
              onClick={handleCloseEdit}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={notUnique}
              startIcon={saving ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : null } //<DeleteSVG width={12} color1="#fff" />}
              onClick={saveField}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </Dialog>

    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}