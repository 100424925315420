import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import PaymentPortal from '../components/account_settings/PaymentPortal'

const PaymentPortalPage = () => {
  return (
      <PaymentPortal />
  )
}

export default PaymentPortalPage