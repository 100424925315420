import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import CsvImportWizard from '../components/csv/CsvImportWizard'

const CsvUploadPage = () => {
  return (
    <MainPageTemplate title="Welcome">
      <CsvImportWizard />
    </MainPageTemplate>
  )
}

export default CsvUploadPage