import { useTheme } from '@emotion/react'
import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import VerifyMessage from '../components/auth/VerifyMessage'

const EmailVerificationPage = () => {
  const theme = useTheme();
  
  return (
    <MainPageTemplate
      title="Verify Your Email"
      // background={`linear-gradient(-45deg, ${theme.palette.light.blue}aa, #fff`}
    >
      <VerifyMessage />
    </MainPageTemplate>
  )
}

export default EmailVerificationPage