import './policy-css.css'

export const privacy_policy_html = `
<body>
  <h1 class="c3" id="h.fepx4jq7ud8u"><span class="c10 c18">PRIVACY POLICY</span></h1>
  <p class="c15"><span class="c22 c10 c26">Effective date: August 01, 2024</span></p>
  <p class="c15"><span class="c20">Last updated July 05, 2024</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <div class="table-of-contents">
  <p class="c2"><span class="c14">1. </span><span class="c6"><a class="c4" href="#h.qnkvxznnu65x">WHAT INFORMATION DO WE
        COLLECT?</a></span></p>
  <p class="c2"><span>2. </span><span class="c6"><a class="c4" href="#h.8hdmxelkd26g">HOW DO WE PROCESS YOUR
        INFORMATION?</a></span></p>
  <p class="c2"><span>3. </span><span class="c6"><a class="c4" href="#h.r52uwrl4rf9k">WHEN AND WITH WHOM DO WE SHARE
        YOUR PERSONAL INFORMATION?</a></span></p>
  <p class="c2"><span>4. </span><span class="c6"><a class="c4" href="#h.wcndqtwwgiz6">DO WE USE COOKIES AND OTHER
        TRACKING TECHNOLOGIES?</a></span></p>
  <p class="c2"><span>5. </span><span class="c6"><a class="c4" href="#h.r0touykwpkx7">HOW DO WE HANDLE YOUR SOCIAL
        LOGINS?</a></span></p>
  <p class="c2"><span>6. </span><span class="c6"><a class="c4" href="#h.zdy2uvad57le">IS YOUR INFORMATION TRANSFERRED
        INTERNATIONALLY?</a></span></p>
  <p class="c2"><span>7. </span><span class="c6"><a class="c4" href="#h.oxc3tqhio23d">HOW LONG DO WE KEEP YOUR
        INFORMATION?</a></span></p>
  <p class="c2"><span>8. </span><span class="c6"><a class="c4" href="#h.2ifwmzfju5ar">DO WE COLLECT INFORMATION FROM
        MINORS?</a></span></p>
  <p class="c2"><span>9. </span><span class="c6"><a class="c4" href="#h.yumzggs1m5wz">WHAT ARE YOUR PRIVACY
        RIGHTS?</a></span></p>
  <p class="c2"><span>10. </span><span class="c6"><a class="c4" href="#h.hu47117qzui9">CONTROLS FOR DO-NOT-TRACK
        FEATURES</a></span></p>
  <p class="c2"><span>11. </span><span class="c6"><a class="c4" href="#h.2gxo2yonblit">DO WE MAKE UPDATES TO THIS
        NOTICE?</a></span></p>
  <p class="c2"><span>12. </span><span class="c6"><a class="c4" href="#h.nhzqa0pk8as2">HOW CAN YOU CONTACT US ABOUT THIS
        NOTICE?</a></span></p>
  <p class="c2"><span>13. </span><span class="c6"><a class="c4" href="#h.8f29wubqlzw2">HOW CAN YOU REVIEW, UPDATE, OR
        DELETE THE DATA WE COLLECT FROM YOU?</a></span></p>
  </div>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">This privacy notice for __________ (&quot;</span><span class="c8">we</span><span
      class="c8">,&quot; &quot;</span><span class="c8">us</span><span class="c8">,&quot; or &quot;</span><span
      class="c8">our</span><span class="c8">&quot;), describes how and why we might collect, store, use, and/or share
      (&quot;</span><span class="c8">process</span><span class="c8">&quot;) your information when you use our services
      (&quot;</span><span class="c8">Services</span><span class="c1">&quot;), such as when you:</span></p>
  <p class="c2"><span class="c8">Questions or concerns? </span><span class="c1">Reading this privacy notice will help
      you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not
      use our Services.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.9glhfagda3mf"><span>SUMMARY OF KEY POINTS</span></h2>
  <p class="c2"><span class="c8 c9">This summary provides key points from our privacy notice, but you can find out more
      details about any of these topics by clicking the link following each key point or by using our </span><span
      class="c7 c9"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23toc&amp;sa=D&amp;source=editors&amp;ust=1723420082932613&amp;usg=AOvVaw11_LfJljBwII-iKL-dY8fb">table
        of contents</a></span><span class="c8 c10 c9">&nbsp;below to find the section you are looking for.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">What </span><span>personal </span><span class="c8">information do we
      process?</span><span class="c8">&nbsp;When you visit, use, or navigate our Services, we may process personal
      information depending on how you interact with us and the Services, the choices you make, and the products and
      features you use. Learn more about </span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23personalinfo&amp;sa=D&amp;source=editors&amp;ust=1723420082933386&amp;usg=AOvVaw0CtnFIfIthm7vHC6l6KyDZ">personal
        information you disclose to us</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">Do we process any sensitive personal information?</span><span class="c1">&nbsp;We do
      not process sensitive personal information.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">Do we collect any information from third parties?</span><span class="c8">&nbsp;We may
      collect information from public databases, marketing partners, social media platforms, and other outside sources.
      Learn more about </span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23othersources&amp;sa=D&amp;source=editors&amp;ust=1723420082934121&amp;usg=AOvVaw1FTnLU7kmYJOBTBOUSRrwj">information
        collected from other sources</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">How do we process your information?</span><span class="c8">&nbsp;We process your
      information to provide, improve, and administer our Services, communicate with you, for security and fraud
      prevention, and to comply with law. We may also process your information for other purposes with your consent. We
      process your information only when we have a valid legal reason to do so. Learn more about </span><span
      class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23infouse&amp;sa=D&amp;source=editors&amp;ust=1723420082934695&amp;usg=AOvVaw3RLp8KCLcGbyOvlpc9JZym">how
        we process your information</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">In what situations and with which parties do we share personal information?</span><span
      class="c8">&nbsp;We may share information in specific situations and with specific third parties. Learn more about
    </span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23whoshare&amp;sa=D&amp;source=editors&amp;ust=1723420082935209&amp;usg=AOvVaw0EVDDu7zOl44WVo88oNrly">when
        and with whom we share your personal information</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">What are your rights?</span><span class="c8">&nbsp;Depending on where you are located
      geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
      Learn more about </span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23privacyrights&amp;sa=D&amp;source=editors&amp;ust=1723420082935759&amp;usg=AOvVaw2YHQV6-V78KhawzLMlPUdt">your
        privacy rights</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">How do you exercise your rights?</span><span class="c8">&nbsp;The easiest way to
      exercise your rights is by submitting a </span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/notify/b752abe1-9352-48c3-9395-e342bbba4d6f&amp;sa=D&amp;source=editors&amp;ust=1723420082936167&amp;usg=AOvVaw1QuitO4WLmtQd_mpXTL-Bl">data
        subject access request</a></span><span class="c1">, or by contacting us. We will consider and act upon any
      request in accordance with applicable data protection laws.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">Want to learn more about what we do with any information we collect? </span><span
      class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23toc&amp;sa=D&amp;source=editors&amp;ust=1723420082936640&amp;usg=AOvVaw1sHKVPkvsvLSVc6M7K8wtg">Review
        the privacy notice in full</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.qnkvxznnu65x"><span class="c16 c10">1. WHAT INFORMATION DO WE COLLECT?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c17 c10">Personal information you disclose to us</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c10 c9">We collect personal information that you
      provide to us.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We collect personal information that you voluntarily provide to us when you register on
      the Services, express an interest in obtaining information about us or our products and Services, when you
      participate in activities on the Services, or otherwise when you contact us.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">Sensitive Information.</span><span class="c1">&nbsp;We do not process sensitive
      information.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">All personal information that you provide to us must be true, complete, and accurate,
      and you must notify us of any changes to such personal information.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c17 c10">Information automatically collected</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c10 c9">Some information &mdash; such as your
      Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when
      you visit our Services.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We automatically collect certain information when you visit, use, or navigate the
      Services. This information does not reveal your specific identity (like your name or contact information) but may
      include device and usage information, such as your IP address, browser and device characteristics, operating
      system, language preferences, referring URLs, device name, country, location, information about how and when you
      use our Services, and other technical information. This information is primarily needed to maintain the security
      and operation of our Services, and for our internal analytics and reporting purposes.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">Like many businesses, we also collect information through cookies and similar
      technologies.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.8hdmxelkd26g"><span class="c16 c10">2. HOW DO WE PROCESS YOUR INFORMATION?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c10 c9">We process your information to provide,
      improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with
      law. We may also process your information for other purposes with your consent.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We process your personal information for a variety of reasons, depending on how you
      interact with our Services, including:</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.r52uwrl4rf9k"><span class="c16 c10">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
      INFORMATION?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short:</span><span class="c8 c10 c9">&nbsp;We may share information in specific
      situations described in this section and/or with the following third parties.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We may need to share your personal information in the following situations:</span></p>
  <ul class="c21 lst-kix_qq5tc78o4vl7-0 start">
    <li class="c12 li-bullet-0"><span class="c8">Business Transfers.</span><span class="c1">&nbsp;We may share or
        transfer your information in connection with, or during negotiations of, any merger, sale of company assets,
        financing, or acquisition of all or a portion of our business to another company.</span></li>
  </ul>
  <ul class="c21 lst-kix_5r0tnlyet9jz-0 start">
    <li class="c12 li-bullet-0"><span class="c8">Affiliates. </span><span class="c1">We may share your information with
        our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include
        our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are
        under common control with us.</span></li>
  </ul>
  <ul class="c21 lst-kix_lxa5dl1lam84-0 start">
    <li class="c12 li-bullet-0"><span class="c8">Business Partners.</span><span class="c13 c10">&nbsp;We may share your
        information with our business partners to offer you certain products, services, or promotions.</span></li>
  </ul>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.wcndqtwwgiz6"><span class="c16 c10">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
  </h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short:</span><span class="c8 c10 c9">&nbsp;We may use cookies and other tracking
      technologies to collect and store your information.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We may use cookies and similar tracking technologies (like web beacons and pixels) to
      gather information when you interact with our Services. Some online tracking technologies help us maintain the
      security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site
      functions.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We also permit third parties and service providers to use online tracking technologies
      on our Services for analytics and advertising, including to help manage and display advertisements, to tailor
      advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication
      preferences). The third parties and service providers use their technology to provide advertising about products
      and services tailored to your interests which may appear either on our Services or on other websites.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">Specific information about how we use such technologies and how you can refuse certain
      cookies is set out in our Cookie Notice.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.r0touykwpkx7"><span class="c10 c16">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c10 c9">If you choose to register or log in to our
      Services using a social media account, we may have access to certain information about you.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">Our Services offer you the ability to register and log in using your third-party social
      media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain
      profile information about you from your social media provider. The profile information we receive may vary
      depending on the social media provider concerned, but will often include your name, email address, friends list,
      and profile picture, as well as other information you choose to make public on such a social media
      platform.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We will use the information we receive only for the purposes that are described in this
      privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not
      control, and are not responsible for, other uses of your personal information by your third-party social media
      provider. We recommend that you review their privacy notice to understand how they collect, use, and share your
      personal information, and how you can set your privacy preferences on their sites and apps.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.zdy2uvad57le"><span class="c16 c10">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span>
  </h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c10 c9">We may transfer, store, and process your
      information in countries other than your own.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">Our servers are located in. If you are accessing our Services from outside, please be
      aware that your information may be transferred to, stored, and processed by us in our facilities and by those
      third parties with whom we may share your personal information (see &quot;</span><span class="c25"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23whoshare&amp;sa=D&amp;source=editors&amp;ust=1723420082944474&amp;usg=AOvVaw20uSKhy8WjSLeHDbRtb0dt">WHEN
        AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span><span class="c1">&quot; above), in and other
      countries.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or
      Switzerland, then these countries may not necessarily have data protection laws or other similar laws as
      comprehensive as those in your country. However, we will take all necessary measures to protect your personal
      information in accordance with this privacy notice and applicable law.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.oxc3tqhio23d"><span class="c16 c10">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c9 c10">We keep your information for as long as
      necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We will only keep your personal information for as long as it is necessary for the
      purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as
      tax, accounting, or other legal requirements).</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">When we have no ongoing legitimate business need to process your personal information,
      we will either delete or anonymize such information, or, if this is not possible (for example, because your
      personal information has been stored in backup archives), then we will securely store your personal information
      and isolate it from any further processing until deletion is possible.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.2ifwmzfju5ar"><span class="c16 c10">8. DO WE COLLECT INFORMATION FROM MINORS?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short:</span><span class="c8 c10 c9">&nbsp;We do not knowingly collect data from
      or market to children under 18 years of age.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We do not knowingly collect, solicit data from, or market to children under 18 years of
      age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at
      least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of
      the Services. If we learn that personal information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly delete such data from our records. If you
      become aware of any data we may have collected from children under age 18, please contact us at __________.</span>
  </p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.yumzggs1m5wz"><span class="c16 c10">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short:</span><span class="c8 c10 c9">&nbsp; You may review, change, or terminate
      your account at any time, depending on your country, province, or state of residence.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c24">Withdrawing your consent:</span><span class="c8">&nbsp;If we are relying on your
      consent to process your personal information, which may be express and/or implied consent depending on the
      applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time
      by contacting us by using the contact details provided in the section &quot;</span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/privacy-policy%23contact&amp;sa=D&amp;source=editors&amp;ust=1723420082947648&amp;usg=AOvVaw044Gi6V0gU6pfsMvvELKDv">HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?</a></span><span class="c1">&quot; below.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">However, please note that this will not affect the lawfulness of the processing before
      its withdrawal nor, when applicable law allows, will it affect the processing of your personal information
      conducted in reliance on lawful processing grounds other than consent.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c10 c17">Account Information</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c10 c13">If you would at any time like to review or change the information in your account
      or terminate your account, you can:</span></p>
  <p class="c2"><span class="c1">Upon your request to terminate your account, we will deactivate or delete your account
      and information from our active databases. However, we may retain some information in our files to prevent fraud,
      troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
      requirements.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.hu47117qzui9"><span class="c16 c10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">Most web browsers and some mobile operating systems and mobile applications include a
      Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have
      data about your online browsing activities monitored and collected. At this stage, no uniform technology standard
      for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
      browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a
      standard for online tracking is adopted that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.2gxo2yonblit"><span class="c16 c10">11. DO WE MAKE UPDATES TO THIS NOTICE?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8 c9">In Short: </span><span class="c8 c10 c9">Yes, we will update this notice as
      necessary to stay compliant with relevant laws.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c1">We may update this privacy notice from time to time. The updated version will be
      indicated by an updated &quot;Revised&quot; date at the top of this privacy notice. If we make material changes to
      this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly
      sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are
      protecting your information.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.nhzqa0pk8as2"><span class="c16 c10">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">If you have questions or comments about this notice, you may contact us </span><span>at
      fillmarketing.user@gmail.com</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <h2 class="c11" id="h.8f29wubqlzw2"><span class="c16 c10">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
      COLLECT FROM YOU?</span></h2>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2"><span class="c8">Based on the applicable laws of your country, you may have the right to request access
      to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or
      delete your personal information. You may also have the right to withdraw your consent to our processing of your
      personal information. These rights may be limited in some circumstances by applicable law. To request to review,
      update, or delete your personal information, please fill out and submit a </span><span class="c7"><a class="c4"
        href="https://www.google.com/url?q=https://app.termly.io/notify/b752abe1-9352-48c3-9395-e342bbba4d6f&amp;sa=D&amp;source=editors&amp;ust=1723420082951187&amp;usg=AOvVaw3oa6vgu3nrmDtBa47D6PHC">data
        subject access request</a></span><span class="c1">.</span></p>
  <p class="c2 c5"><span class="c0"></span></p>
  <p class="c2 c5"><span class="c1"></span></p>
</body>

`