import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import TermsPrivacy from '../components/terms/TermsPrivacy'

const TermsPrivacyPage = () => {
  return (
    <LandingTemplate title="Privacy Policy">
      <TermsPrivacy />
    </LandingTemplate>
  )
}

export default TermsPrivacyPage
