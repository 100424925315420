import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingHome from '../components/landing/LandingHome'
import Terms from '../components/terms/Terms'

const TermsPage = () => {
  return (
    <LandingTemplate title="Terms">
      <Terms />
    </LandingTemplate>
  )
}

export default TermsPage
