import React, { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import newsletterPlugin from 'grapesjs-preset-newsletter';
import { getEmailTemplateFolder, putEmailTemplate, putStorageFile } from '../../hooks/mutations';
import { Button, Dialog, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import SnackbarWrapper  from '../ui/SnackbarWrapper';
import './grapes.css'; // Import GrapesJS CSS file
import { copyString, getRandomInts } from '../../lib/Functions';
import { CopySVG, EditSVG } from '../../assets/icons';
import { InputField } from '../ui/Fields';
import EmailBuilderInstructions from './EmailBuilderInstructions';
import { constantTemplateVariables } from '../../lib/Data';


// Load plugins

const EmailBuilder = (props) => {
  const { emailTemplate, customFields, setReload } = props;
  // const location = useLocation();
  // const { projectId } = location.state;
  // const emailTemplate = useGetEmailTemplate(projectId);
  // console.log('email template', JSON.stringify(emailTemplate.data))

  return (
    emailTemplate
      ? <GrapeJSComponent emailTemplate={emailTemplate.data} template={emailTemplate.template} file={emailTemplate.storageFile} customFields={customFields} />
      : "LOADING>>>"
  )
}

export default EmailBuilder

const GrapeJSComponent = ({ emailTemplate, template, file, customFields }) => {
  const [templateName, setTemplateName] = useState(template.name);
  const [snackMessage, setSnackMessage] = useState(null);
  const [openVariables, setOpenVariables] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [openInstructions, setOpenInstructions] = useState(false);

  const saveTemplateName = async () => {
    const emailTemplateObj = {
      emailTemplateId: template.id,
      payload: { ...template, name: templateName },
    }
    const savedTemplate = await putEmailTemplate(emailTemplateObj);
    console.log('savedTemplate', savedTemplate)
    if (savedTemplate.status === "success") {
      setOpenName(false);
      setSnackMessage({open: true, message: 'Name updated successfully', severity: 'success'})
  
    } else if (savedTemplate.status === "error") {
      setSnackMessage({open: true, message: savedTemplate.error, severity: 'error'})
    }
  }

  const handleVariableCopy = (field) => {
    console.log('field', field);
    copyString(`{-{${field.field_name}}-}`);
    setSnackMessage({open: true, message: `${field.user_visible_name} variable copied`, severity: 'success'});
    setOpenVariables(false);
  }

  const remoteStoragePlugin = (editor) => {

    let storeTimeout;

    const debouncedStore = (data) => {
      if (storeTimeout) {
        clearTimeout(storeTimeout);
      }

      storeTimeout = setTimeout(async () => {
        try {
          const storageFolder = await getEmailTemplateFolder();
          const storageFolderId = storageFolder.folderId;
          const jsonBlob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
          // const fileName = template.name;
          const fileName = `${template.name.replace(' ', '_')}-${file.id}-${getRandomInts(16)}`;
          const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
          const formData = new FormData();
          formData.append('file', jsonFileObj);
          formData.append('name', fileName); // <-- Replace this string with state variable to save and edit the name
          formData.append('folder', storageFolderId);

          setSnackMessage({open: true, message: <LinearProgress color='primary' style={{width:'200px', height:'15px', borderRadius:30}} />})
          const storageObj = {
            storageFilePayload: formData,
            folderId: storageFolderId,
            fileId: template.content_file
          }

          const updatedFile = await putStorageFile(storageObj);
          if (updatedFile.status === "success") {
            setSnackMessage({open: true, message: 'Template Saved', severity: 'success'})
  
          } else {
            setSnackMessage({open: true, message: 'Error saving template', severity: 'error'})

          }

        } catch (error) {
          console.log(`Error updating template ${emailTemplate.id}`, error)
        }
      }, 1200); // change time duration as preferred - tested with many variables and for now it's set to the most comfortable delay time - Hayeong
    };

    // console.log('emailTemplate from here', emailTemplate);
    editor.Storage.add('remote', {

      async load() {

        const htmlString = ``;

        try {

          // const emailTemplate = await getEmailTemplate(emailTemplate.id);
          // if (emailTemplate.status === "success") {
          //   console.log('emailTemplate data', emailTemplate.data);
          //   console.log('emailTemplate from deep inside', emailTemplate)

          //   return emailTemplate.editor_json
          // }
          if (emailTemplate) {
            return emailTemplate
          }
        } catch (error) {
          console.log('There was an error making a GET request to the /templates/api/email_templates/${projectId}/ api endpoint')
        }

        editor.setComponents(htmlString);
      },

      async store(data) {
        debouncedStore(data);
      }

    });

  }

  useEffect(() => {
      // Initialize GrapesJS editor
      if (emailTemplate) {

        const editor = grapesjs.init({
          container: '#gjs', //editorRef.current,
          // Your GrapesJS configuration options
          projectData: emailTemplate.data,
          height: 'calc(100vh - 64px)', // Set editor height
          plugins:[
            newsletterPlugin,
            remoteStoragePlugin
          ],
          storageManager: {
            type: 'remote', // Store changes remotely
            stepsBeforeSave: 1,
            options: {
              remote: {                
                //Enrich remote call to include HTML/CSS of pages along with IDs and data
                onStore: (data, editor) => {
                  const htmlWithCss = editor.runCommand('gjs-get-inlined-html');
                  return { data, content: htmlWithCss, existing: true };
                },
                onLoad: result => result.data,
                contentTypeJson: true, 
              }                 
            } 
          },
        });

        console.log('email template name', template)

        // Add commands to be used in panels and buttons
        editor.Commands.add('variables', editor => {setOpenVariables(true)})
        editor.Commands.add('name', editor => {setOpenName(true)})
        // editor.Commands.add('instructions', editor => {setOpenInstructions(true)}) // Add back in after instructions is completed

        // Add buttons
        editor.Panels.addButton('options', [
          {
            id: 'variables',
            active: false, // active by default
            className: 'btn-toggle-borders',
            label: 'Variables',
            command: 'variables', // Built-in command
          },
          // { // Add back in after instructions is completed
          //   id: 'instructions',
          //   active: false, // active by default
          //   className: 'btn-toggle-borders',
          //   label: 'Instructions',
          //   command: 'instructions', // Built-in command
          // },
          // {
          //   id: 'name',
          //   active: false, // active by default
          //   className: 'btn-toggle-borders',
          //   label: template.name,
          //   command: 'name', // Built-in command
          // },
        ]);

        editor.Panels.addButton('devices-c', [
          {
            id: 'name',
            active: false, // active by default
            className: 'btn-toggle-borders',
            label: templateName,
            command: 'name', // Built-in command
          },
        ])

        // editor.Panels.addPanel({
        //   id: 'myNewPanel',
        //   visible: true,
        //   buttons: [
        //     {
        //       id: 'name',
        //       active: false, // active by default
        //       className: 'btn-toggle-borders',
        //       label: templateName,
        //       command: 'name', // Built-in command
        //     },
        //   ],
        //  });


        // // Example of adding a custom block
        // editor.BlockManager.add('my-custom-block', {
        //   label: 'My Custom Block',
        //   // Add your block definition here
        //   content: '<div>My Custom Block Content</div>',
        // });
  
        // Cleanup function
        return () => {
            // Destroy the GrapesJS editor instance when the component unmounts
            editor.destroy();
        };
      }
  }, [emailTemplate, templateName]); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div id='gjs'>
      {/* GrapesJS editor container */}
      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
      <Dialog
        open={openVariables}
        onClose={() => setOpenVariables(false)}
      >
        <Grid
          container
          style={{
            padding:24
          }}
        >
          <Typography
            style={{
              fontSize:'1.3rem',
              textAlign:'center',
              width:'100%'
            }}
          >
            Field Variables
          </Typography>
          <Typography
            style={{
              textAlign:'center',
              width:'100%',
              marginBottom:16
            }}
          >
            Click to copy
          </Typography>
          {
            [ ...constantTemplateVariables, ...customFields ]?.map(field => (
              <Grid
                container
                onClick={() => handleVariableCopy(field)}
                sx={{
                  alignItems:'center',
                  padding:'4px 16px',
                  background:'#eee',
                  '&&:hover':{
                    background:'#ddd'
                  },
                  borderRadius:2,
                  marginBottom:1,
                  cursor:'pointer'
                }}
              >
                <Grid
                  item
                  // xs
                >
                  <Typography
                    style={{
                      fontSize:'.9rem',
                    }}
                  >
                    {field.user_visible_name}
                  </Typography>
                  <Typography
                    style={{
                      fontSize:'.9rem',
                      fontWeight: 600
                    }}
                  >
                    {`{-{${field.field_name}}-}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display:'flex',
                    justifyContent:'flex-end'
                  }}
                  xs
                >
                  <IconButton

                  >
                    <CopySVG width={15} />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={() => setOpenInstructions(false)}
      >
        <Grid
          container
          style={{
            padding:24
          }}
        >
          <Typography
            style={{
              fontSize:'1.3rem',
              textAlign:'center',
              width:'100%',
              marginBottom: 16
            }}
          >
            Email Builder Instructions
          </Typography>
            
          <EmailBuilderInstructions />
        </Grid>
      </Dialog>

      <Dialog
        open={openName}
        onClose={() => setOpenName(false)}
        fullWidth
      >
        <Grid container justifyContent="center">
          <Grid
            container
            style={{
              padding:24,
              flexDirection:'column',
              alignItems:'center',
              maxWidth:350
            }}
          >
            <Typography
              style={{
                fontSize:'1.3rem',
                textAlign:'center',
                width:'100%',
                marginBottom: 16
              }}
            >
              Edit Email Template Name
            </Typography>

            <InputField
              // label="Email Template Name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              margin
            />

            <Button
              onClick={saveTemplateName}
              variant="contained"
            >
              Save
            </Button>

          </Grid>
        </Grid>
      </Dialog>

    </div>
  );
};
