import { Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react'
import { DeleteSVG, EditSVG } from '../../assets/icons';
import EditSenderDetail from './EditSenderDetail';
import { deleteSenderDetails } from '../../hooks/mutations';
import DialogWarning from '../ui/DialogWarning';

const SenderDetailsList = (props) => {
  const { senderDetails, setExistingSenderDetail, setReload } = props;

  return (
    <Grid
      container
    >
      {
        senderDetails.map((detail, index) => (
          <DetailItem detail={detail} index={index} setExistingSenderDetail={setExistingSenderDetail} setReload={setReload} />
        ))
      }

    </Grid>
  )
}

export default SenderDetailsList

const DetailItem = (props) => {
  const { detail, index, setExistingSenderDetail, setReload } = props;
  const [openWarning, setOpenWarning] = useState(false);

  const handleCloseWarning = () => {
    setOpenWarning(false);
  }

  // Delete selected sender detail
  const handleDeleteDetail = async () => {
    const deleted = await deleteSenderDetails(detail.id);
    console.log('deleted', deleted);
    if (deleted.status === "success") {
      setReload(true);
    } else if (deleted.status === "error") {

    }
  }


  return (
    <Grid
      container
      style={{
        justifyContent:'space-between',
        borderBottom: `1px solid #ccc`
      }}
    >
      <Grid
        item
        xs
      >
        <Typography>{detail.domain_name}</Typography>
      </Grid>
      <Grid
        item
        xs="auto"
      >
        <IconButton
          onClick={() => setExistingSenderDetail(detail)}
        >
          <EditSVG height={16} />
        </IconButton>
        <IconButton
          onClick={() => setOpenWarning(true)}
        >
          <DeleteSVG height={16} />
        </IconButton>
      </Grid>

      <DialogWarning
        open={openWarning}
        onClose={handleCloseWarning}
        header="Delete Sender Details"
        title={`You are about to delete sender details`}
        messages={{
          one:'Are you sure you want to delete these details? They will be permanently removed once you submit changes.',
          confirm:'Yes'
        }}
        // deleting
        cancel={handleCloseWarning}
        confirm={handleDeleteDetail}
        // icons
      />

    </Grid>

  )
}