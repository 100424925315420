import React, { cloneElement, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  TextField, MenuItem,
  Typography, ListItemIcon, IconButton, Divider
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, DownloadFileSVG, UploadSVG, VisibleSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
// import { selectedIcons } from './CustomFields';
import { postTag, putContact, putTag, useGetTags } from '../../hooks/mutations';
import { tagsColumns } from '../../lib/Data';
import TagSearchAndCreate from './TagSearchAndCreate';
import { getContrastTone, hashScroll } from '../../lib/Functions';
import { Tag } from '../ui/Tags';

const ImportTags = (props) => {
  const { closeMenu, contactId, contact, setContact, existingTags, handleTagImport, removeTag, showExisting, setEditing, setReload, reloadContact } = props;
  const theme = useTheme();
  const [selectedTags, setSelectedTags] = useState({});
  const incomingTags = useGetTags({});
  const [tags, setTags] = useState(incomingTags);
  const [tagIds, setTagIds] = useState(null);
  const [searchBy, setSearchBy] = useState('');
  const [newTagColor, setNewTagColor] = useState(null);

  const screenSize = useScreenSize();


  // Set tags state to incoming data on load
  useEffect(() => {
    if (incomingTags) {
      setTags(incomingTags);
      setTagIds(incomingTags.map(x => x.id))
    }
  }, [incomingTags]);
  
  const handleEditingClick = (fieldKey) => {
    setEditing(fieldKey);
    setTimeout(() => {
      if (document.getElementById(fieldKey)) {
        document.getElementById(fieldKey).focus();
      }
    }, 0);
  };

  // ADD TAG
  const addTag = async (e) => {
    e.stopPropagation();
    const tagObj = {
      payload: {
        name: searchBy,
        description: '',
        color: newTagColor ? newTagColor : '#cccccc',
      }
    }

    const updatedTag = await postTag(tagObj);
    if (updatedTag.status === "success") {
      console.log('successful tag addition');

      if (contact) {
        const existingTagNames = existingTags.map(x => x.name);
    
        const contactObj = {
          contactId: contactId,
          payload: { ...contact, tags: [ ...existingTagNames, searchBy ]}
        }
        const updatedContact = await putContact(contactObj);
        if (updatedContact.status === 'success') {
          // console.log('successfully added new tag to contact')
          if (setContact) {
            setContact(updatedContact.data);
          }
          if (reloadContact) {
            reloadContact(contact.id);
          }
        }
      }
      console.log('abouttoreload')
      if (setReload) {
        setReload(true);
        console.log('jsut reloaded')
      }

      closeMenu(false);
    }
  }

  // const removeTag = async (removedTagId) => {
  //   console.log('existingTags', existingTags)
  //   console.log('removedTagId', removedTagId)
  //   const updatedTags = existingTags.filter(x => x.id !== removedTagId);
  //   console.log('updatedTags', updatedTags)
  //   const contactObj = {
  //     contactId: contactId,
  //     payload: { ...contact, tags: updatedTags}
  //   }
  //   const updatedContact = await putContact(contactObj);
  //   if (updatedContact.status === 'success') {
  //     console.log('successfully removed tag to contact')
  //     setReload(true);
  //   }
  // }

  const importTag = async (e, tag) => {
    e.stopPropagation();
    const existingTagNames = existingTags.map(x => x.name);
  
    const contactObj = {
      contactId: contactId,
      payload: { ...contact, tags: [ ...existingTagNames, tag.name ]}
    }
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      // console.log('successfully added new tag to contact')
      if (setContact) {
        setContact(updatedContact.data);
      }
      if (setReload) {
        setReload(true);
      }
      if (reloadContact) {
        reloadContact(contactId);
      }
    }

  }


  return (
    <Grid
      container
      style={{
        // outline: `2px solid ${theme.palette.primary.main}`,
        // borderRadius: 8,
        // padding: 24,
        width:250,
        maxHeight:'50vh',
        overflowY:'auto'
      }}
    >
      {
        showExisting &&
          <>
            <Grid
              container
              gap={1}
              style={{
                padding:'0 8px'
              }}
            >
              {
                existingTags?.map(tag => (
                  // <Tag tag={tag} removeTag={removeTag} showOptions setReload={setReload} />
                  <Tag
                    tag={tag}
                    removeTag={removeTag}
                    // setReload={setReload}
                    contactId={contact.id}
                    reloadContact={reloadContact}
                    showOptions
                  />

                ))
              }
            </Grid>
            <Divider style={{width:'100%', margin:'8px 0'}} />
          </>
      }
      <Grid
        container
        justifyContent="space-between"
      >
        <TagSearchAndCreate
          useSearch
          isOpen
          placeholder="Search Tags"
          content={incomingTags}
          setContent={setTags}
          searchFields={['name']}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addTag(e)
            }
          }}
        />
      </Grid>
      <Divider style={{width:'100%', margin:'8px 0 0 0'}} />
      {
        !existingTags.map(x => x.name).includes(searchBy) && searchBy
          ?
            <Grid
              container
              style={{
                padding:'0 8px',
                justifyContent:'center'
              }}
            >
              <Typography
                style={{
                  fontStyle:'italic',
                  color:'#999'
                }}
              >
                Press Enter to create tag
              </Typography>
            </Grid>
          :
            null
      }
      {/* <HeaderRow
        columns={tagsColumns}
      /> */}
      <TableWrapper>
        {
          tags
            ?
              tags.map((tag, index) => (
                <TableRow
                  tag={tag}
                  importTag={importTag}
                  existingTags={existingTags}
                  columns={tagsColumns}
                  index={index}
                  handleTagImport={handleTagImport}
                  handleEditingClick={handleEditingClick}
                />
              ))
            :
              <Typography>No custom fields have been created yet.</Typography>
        }
      </TableWrapper>

      {/* <Grid
        container
        sx={{
          justifyContent: 'space-between',
          marginTop: '8px'
        }}
      >
        <Button
          onClick={cancelImportFields}
        >
          Close
        </Button>
        <Button

        >
          Import
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default ImportTags

const HeaderRow = (props) => {
  const { columns, align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16
      }}
    >
      {
        columns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        maxHeight:240
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { tag, importTag, columns, index, handleTagImport, existingTags, handleEditingClick } = props;
  const [hover, setHover] = useState(false);

  const backgroundColor = getContrastTone(tag.color).background; // tag.color ? tag.color : '#ccc';
  const fontColor = getContrastTone(tag.color).text;
  const outlineColor = getContrastTone(tag.color).outline;

  return (
    <Grid
      container
      onClick={(e) => importTag(e, tag)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        cursor:'pointer',
        background: hover ? `${backgroundColor}30` : 'transparent',
      }}
    >
      <Typography
        style={{
          color: tag.color ? fontColor : '#999',
          fontWeight:500,
          fontSize:'.8rem',
          padding:'8px 8px',
        }}
      >
        {tag.name}
      </Typography>
      <Divider style={{width:'100%'}} />
      {
        // columns.map(item => (
        //   <TableColumn item={item}>

        //     {
        //       item.id === 'actions' ?
        //         existingTags.includes(tag.id)
        //           ?
        //             <IconButton
        //               onClick={() => {
        //                 handleEditingClick(tag.id);
        //                 hashScroll(`r-${tag.id}`);
        //               }}
        //               style={{
        //                 height:36
        //               }}
        //             >
        //               <VisibleSVG width={20} color1="#777" style={{transform:'rotate(180deg)', cursor:'pointer'}} />
        //             </IconButton>
        //           :
        //             <IconButton
        //               onClick={() => handleTagImport(tag)}
        //               style={{
        //                 height:36
        //               }}
        //             >
        //               <UploadSVG width={20} color1="#777" style={{transform:'rotate(180deg)', cursor:'pointer'}} />
        //             </IconButton>
        //         :
        //           null
        //     }
        //     <Typography style={{ fontSize:'1rem'}}>{tag[item.id]}</Typography>
        //   </TableColumn>
        // ))
      }
    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}
