import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  MenuItem,
  Typography,
  Collapse,
  ListItemIcon,
  Menu,
  Dialog,
  useScrollTrigger,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddFillSVG, AddSVG, CheckmarkSVG, ChevronSVG, CloseFilledSVG, CloseOutlineSVG, CloseRoundedSVG, CloseThinSVG, CopySVG, DeleteSVG, EditSVG, EmailSVG, EventSVG, FilterSVG, NumberSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG, WarningSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { postContactToMailingList, createContact, createStorageFile, deleteContactFromMailingList, getCustomFieldFolder, putContact, putStorageFile, useGetContacts, useGetCustomFields, useGetTags } from '../../hooks/mutations';
import ImportCustomFields from '../custom_fields/ImportCustomFields';
import { InputField, InputFieldType } from '../ui/Fields';
import { useGetContact } from '../../hooks/mutations'
import { copyString, generateContactName } from '../../lib/Functions';
import ImportTags from '../tags/ImportTags';
import { Tag, TagButton } from '../ui/Tags';
import DialogWarning from '../ui/DialogWarning';
import { contactStatusEnum } from '../../lib/Data';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { useApi } from '../../context/apiContext';
import Infotip from '../ui/Infotip';

function ElevationScroll(props) {
  const { children, window  } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const triggerOnScrollElement = document.getElementById('drawer-contents');
  const screenSize = useScreenSize();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
    target: triggerOnScrollElement  ? triggerOnScrollElement : undefined,
  });

  return cloneElement(children, {
    style:{
      position:'sticky',
      top:0,
      padding: screenSize.xs ? '0 8px 8px 8px' : '0 32px 8px 32px',
      paddingBottom:16,
      background:trigger ? '#fff' : 'transparent',
      zIndex:1000,
      boxShadow: trigger ? '0 4px 5px #00000030' : 'none',
      transition:'box-shadow .5s'
    }

  });
}


const ContactEdit = (props) => {
  const { contacts, setSelectedContact, customFields, lists, setReloadCustomFields, incomingContact, updateContacts, reloadContact, setReload, setSnackMessage } = props;
  // const [reload, setReload] = useState(false);
  // const receivedContact = useGetContact(1, reload);
  // const customFields = useGetCustomFields();
  // const tags = useGetTags();
  const screenSize = useScreenSize();
  const theme = useTheme();
  const apiContext = useApi();
  const contactProfiles = apiContext.contextUserDataBag?.data?.contactProfiles ?? [];
  const [contact, setContact] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isAddingList, setIsAddingList] = useState(false);
  // const [isAddingTag, setIsAddingTag] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [editing, setEditing] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [fieldToRemove, setFieldToRemove] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [expandedLists, setExpandedLists] = useState(true);
  const [expandedFields, setExpandedFields] = useState(true);
  const [expandedTags, setExpandedTags] = useState(true);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [profileFields, setProfileFields] = useState(incomingContact.custom_fields);
  const [profile, setProfile] = useState(null);

  // console.log('isSaved', isSaved);
  // console.log('loaded', loaded);
  // console.log('updated', updated);

  console.log('contact', contact);
  // console.log('incomingContact', incomingContact.tags);

  // Set contact from api response 'incomingContact' state
  useEffect(() => {
    if (incomingContact && !loaded) {
      setContact(incomingContact)
      setLoaded(true);
    }
  }, [incomingContact])

  // Open warning dialog when a field is selected to remove
  useEffect(() => {
    if (fieldToRemove) {
      setOpenWarning(true);
    }
  }, [fieldToRemove])

  useEffect(() => {
    if (contact && loaded) {
      let timeoutId;
      let savingId;
  
      clearTimeout(timeoutId);
      clearTimeout(savingId);
      setIsSaved(false);
      timeoutId = setTimeout(() => {
        onSubmit(contact);
        setUpdated(false);
      }, 2500);
  
      savingId = setTimeout(() => {
        setIsSaving(true);
      }, 250);
  
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(savingId);
      };
    } else {
      setLoaded(true);
    }

  }, [updated])

  const handleIsAddingList = () => {
    if (isAddingList) {
      setIsAddingList(false)
    } else {
      setIsAddingList(true);
    }
  }

  const handleIsAddingField = () => {
    if (isAddingField) {
      setIsAddingField(false)
    } else {
      setIsAddingField(true);
      setExpandedFields(true);
    }
  }

  const handleCloseWarning = () => {
    setOpenWarning(false);
    setTimeout(() => {
      setFieldToRemove(null);
    }, 1500); 
  }

  const handleTextStandard = (value, name) => {
    setContact({
      ...contact,
      [name]: value
    })
    setUpdated(true);
  }

  const handleResetContact = () => {
    setContact(incomingContact);
  }

  const handleTextCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleListCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const copiedFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...copiedFields, [name]: value }
    const currentCustomField = customFields.find(x => x.id == name);
    
    setProfileFields(updatedCustomFields);

    if (currentCustomField.enum_values.includes(value)) {
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    } else {
      if (value) {
        // setReloadCustomFields(true);
        setContact(prev => ({
          ...prev,
          custom_fields: updatedCustomFields
        }))
      }
    }
    setUpdated(true);
  }

  const handleNumberCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: Number(value) }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleDateCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: new Date(value).toLocaleDateString() }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleBoolCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleImageCustomField = async (value, name) => {
    console.log('value', value);
    const fileExists = contact.custom_fields[name];
    console.log('fileExists', fileExists);

    if (!value) {
      const copiedContact = { ...contact };
      const customFields = { ...copiedContact.custom_fields };
      const updatedCustomFields = { ...customFields, [name]: '' }
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
      setProfileFields(updatedCustomFields);
      setUpdated(true);
      return;
    }

    try {
      // Save file to storage
      const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
      const storageFolderId = storageFolder.folderId;
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows whent the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          console.log('storedImage', storedImage.data);
  
          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: storedImage.data.id }
          setContact(prev => ({
            ...prev,
            custom_fields: updatedCustomFields
          }))
          setProfileFields(updatedCustomFields);
          setUpdated(true);
          
          // const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
          // const savedContact = await onSubmit(updatedContact);
      }


      }

    }

    catch (error) {

    }
  }

  const handleFileCustomField = async (value, name) => {
    // console.log('value', value);
    const fileExists = contact.custom_fields[name];
    // console.log('fileExists', fileExists);

    try {
      // Save file to storage
      const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
      const storageFolderId = storageFolder.folderId;
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows when the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          // console.log('storedImage', storedImage.data);
  
          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: storedImage.data.id }
          setContact(prev => ({
            ...prev,
            custom_fields: updatedCustomFields
          }))
          setProfileFields(updatedCustomFields);

          // const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
          // const savedContact = await onSubmit(updatedContact);
      }


      }

    }

    catch (error) {

    }
  }

  const handleRichTextCustomField = async (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    const savedContact = await onSubmit({...copiedContact, custom_fields: updatedCustomFields});
    if (savedContact) {
      console.log('success', savedContact);
    }

  }

  const handleImportAllFields = (customFields) => {
    const copiedContact = { ...contact };
    const existingFields = { ...copiedContact.custom_fields };
    // console.log('existingFields', existingFields);
    // console.log('object keys existingFields', Object.keys(existingFields));
    let newFields = { ...existingFields };
    // console.log('newFields', newFields);
    // console.log('customFields', customFields);
    
    customFields.forEach(field => {
      if (Object.keys(existingFields).includes(String(field.id))) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        newFields = { ...newFields, [field.id]: '' }
      }
    });

    
    // console.log('NEW newFields', newFields);

    setContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
    setUpdated(true);
  }

  const removeList = async (listId) => {
    const updated = await deleteContactFromMailingList(contact.id, listId);
    console.log('listId', listId);
    if (updated.status === "success") {
      const existingLists = [ ...contact.mailing_lists ];
      console.log('existingLists', existingLists);
      const filteredLists = existingLists.filter(x => x.id !== listId);
      console.log('filteredLists', filteredLists);
      const updatedContact = { ...contact, mailing_lists: filteredLists };
      console.log('updatedContact', updatedContact);
      updateContactsList(updatedContact);
      setContact(prev => ({
        ...prev,
        mailing_lists: filteredLists
      }))
    }
  }

  const addList = async (list) => {
    const mailingListObj = {
      contactId: contact.id,
      payload: {
        mailing_lists: [list.id]
      }
    }

    const updated = await postContactToMailingList(mailingListObj);
    
    if (updated.status === "success") {
      const existingLists = [ ...contact.mailing_lists ];
      // Merge arrays and remove duplicates based on the 'id' property
      const combinedListsSet = new Set([...existingLists, {id:list.id, name:list.name}].map(JSON.stringify));
      const combinedLists = Array.from(combinedListsSet).map(JSON.parse);
      updateContactsList(combinedLists);
      setContact(prev => ({
        ...prev,
        mailing_lists: combinedLists
      }))}
  }

  // const removeList = (id) => {
  //   const existingLists = [ ...contact.mailing_lists ];

  //   const filteredLists = existingLists.filter(x => x.id !== id);
  //   console.log('filteredLists', filteredLists);
    
  //   setContact(prev => ({
  //     ...prev,
  //     mailing_lists: filteredLists
  //   }))
  //   setUpdated(true);

  // }

  // const addList = async (list) => {
  //   const existingLists = [ ...contact.mailing_lists ];
  //   const newListNames = lists.map(x => ({id: x.id}));
  //   console.log('existingLists', existingLists);
  //   // Merge arrays and remove duplicates based on the 'id' property
  //   const combinedListsSet = new Set([...existingLists, {id:list.id, name:list.name}].map(JSON.stringify));
  //   console.log('combinedListsSet', combinedListsSet);
  //   const combinedLists = Array.from(combinedListsSet).map(JSON.parse);
  //   console.log('combinedLists', combinedLists);
  
  //   setContact(prev => ({
  //     ...prev,
  //     mailing_lists: combinedLists
  //   }))
  //   setUpdated(true);

  // }

  const handleProfileFields = (incomingProfile) => {
    if (profile?.id === incomingProfile.id) {
      console.log('already there')
      setProfile(null);
      return;
    }
    const newProfileFields = [ ...incomingProfile.order ];
    console.log('newProfileFields', newProfileFields)

    const copiedCustomFields = { ...contact.custom_fields };
    console.log('copiedCustomFields', copiedCustomFields)
    let updatedFields = {};

    const visibleProfileFields = newProfileFields.filter(x => x.visible);
    //   const filteredItems = copiedCustomFields.filter(item =>
    //     visibleProfileFields.some(x => x.id === item.id)
    //   );
    visibleProfileFields.forEach(field => {
      if (copiedCustomFields[field.id]) {
        updatedFields = { ...updatedFields, [field.id]: copiedCustomFields[field.id] }
      } else {
        updatedFields = { ...updatedFields, [field.id]: '' }
      }
    })

    setProfile(incomingProfile);
    setProfileFields(prev => ({ ...prev, custom_fields: updatedFields }));
  }


  const handleContactProfile = (fields) => {
    const copiedContact = { ...contact };
    const existingCustomFields = { ...copiedContact.custom_fields };
    console.log('fields', fields.map(x => x.field_name))
    let updatedCustomFields = [];

    fields.forEach(field => {
      if (Object.keys(existingCustomFields).includes(field.id)) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        updatedCustomFields = { ...updatedCustomFields, [field.id]: '' };
      }
    })

    // Combine the existing custom fields and the newly added custom fields
    const combinedCustomFields = { ...existingCustomFields, ...updatedCustomFields };
    console.log('combinedCustomFields', combinedCustomFields);

    setContact(prev => ({
      ...prev,
      custom_fields: combinedCustomFields
    }))
    setUpdated(true);

  }


  const handleFieldImport = (field) => {
    const newItem = {[field.id]: ''}
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };

    if (Object.keys(customFields).includes(field.id)) {
      console.log('THAT FIELD ALREADY EXISTS');
    } else {
      const updatedCustomFields = { ...customFields, ...newItem }
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
      setProfileFields(updatedCustomFields);
      setUpdated(true);
    }
  }

  const confirmFieldRemove = (field) => {
    setFieldToRemove(field);
  }

  const handleFieldRemove = () => {  
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
  
    // Create a copy of customFields without the specified key
    delete customFields[fieldToRemove.key];
  
    setContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));
    setProfileFields(customFields);
    setUpdated(true);
    setOpenWarning(false);
  };

  const removeTag = async (removedTagId) => {
    const existingTags = [ ...contact.tags ];
    const updatedTags = existingTags.filter(x => x.id !== removedTagId);
    const updatedTagNames = updatedTags.map(x => x.name);
    const contactObj = {
      contactId: contact.id,
      payload: { ...contact, tags: updatedTagNames}
    }
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully removed tag to contact')
      setContact(updatedContact.data)
      if (reloadContact) {
        reloadContact(incomingContact.id);
      }
    }
  }

  // CREATE USER NAME FROM CUSTOM FIELDS
  const createNameFromFields = () => {
    const fullName = generateContactName(contact.custom_fields, customFields);
    setContact(prev => ({ ...prev, name: fullName }))
    setUpdated(true);
  }

  // ADD TAG BUTTON MENU
  const [anchorEl, setAnchorEl] = useState(null);
  const isAddingTag = Boolean(anchorEl);
  const handleAddTagClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddTagClose = () => {
    setAnchorEl(null);
  };

  // ADD TAG BUTTON MENU
  const [anchorElTag, setAnchorElTag] = useState(null);
  const isAddingButtonTag = Boolean(anchorElTag);
  const handleAddButtonTagClick = (event) => {
    if (isAddingButtonTag || expandedTags) {
      event.stopPropagation();
    }
    setAnchorElTag(event.currentTarget);
  };
  const handleAddButtonTagClose = (event) => {
    // event.stopPropagation();
    setAnchorElTag(null);
  };

  // UPDATE STATUS BUTTON MENU
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const isAddingButtonStatus = Boolean(anchorElStatus);
  const handleAddButtonStatusClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleAddButtonStatusClose = () => {
    setAnchorElStatus(null);
  };

  // ADD RESET BUTTON MENU
  const [anchorElReset, setAnchorElReset] = useState(null);
  const openReset = Boolean(anchorElReset);
  const handleResetClick = (event) => {
    setAnchorElReset(event.currentTarget);
  };
  const handleResetClose = () => {
    setAnchorElReset(null);
  };

  // NAVIGATE NEXT/PREV CONTACT IN CRM DRAWER
  const navigateContact = (direction) => {
    const currentIndex = contacts.findIndex(x => x.id === contact.id);
    setLoaded(false);
    if (direction === 'next') {
      const next = contacts[currentIndex + 1];
      if ((currentIndex) < contacts.length - 1) {
        console.log('next', next);
        setSelectedContact(next);
      }  
    }
    if (direction === 'back') {
      const next = contacts[currentIndex - 1];
      if ((currentIndex) > 0) {
        console.log('back', next);
        setSelectedContact(next);
      }  
    }
  }


  const onSubmit = async (contact) => {
    console.log('contact', contact);
    const existingTags = [ ...contact.tags ];
    const existingTagNames = existingTags.map(x => x.name);
  
    const contactObj = {
      contactId: contact.id,
      payload: {
        ...contact,
        tags: existingTagNames,
      }
    }
    const updatedContact = await putContact(contactObj);
    setIsSaving(false);
    if (updatedContact.status === 'success') {
      updateContactsList(updatedContact.data);
      // setSnackMessage({ open: true, message: 'Contact updated', severity: 'success' })
      // setIsSaved(true);
      // // console.log('successfully updated contact');
      // // if (setReload) {
      // //   setReload(true);
      // // }
      // if (updateContacts) {
      //   updateContacts(updatedContact.data);
      // }

    }
  }

  const updateContactsList = (updatedContactData) => {
    setSnackMessage({ open: true, message: 'Contact updated', severity: 'success' })
    setIsSaved(true);
    if (updateContacts) {
      updateContacts(updatedContactData);
    }
  }

  return (
    contact
      ?
        <Grid
          style={{
            position:'relative'
          //   background:'#fff',
          //   borderRadius: 8,
          //   padding: screenSize.sm ? '36px 24px' : '36px 72px',
          //   boxShadow:'0 2px 10px #00000020',
          //   width: screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          //   transition:'.4s',
          //   maxWidth:1600
          }}
        >
            {/* <Grid
              elevation={4}
              style={{
                position:'sticky',
                top:0,
                paddingBottom:16,
                background:'#fff',
                zIndex:1000
              }}
            > */}
          <ElevationScroll {...props}>
            <div >
              <Grid
                container
                style={{
                  justifyContent:'space-between',
                  paddingTop:8
                }}
              >
              {
                setSelectedContact
                  ?
                    <Grid
                      container
                      style={{
                        justifyContent:'space-between'
                      }}
                    >
                      <Button
                        onClick={() => navigateContact('back')}
                        startIcon={<ChevronSVG height={12} color1={theme.palette.primary.main} />}
                        style={{
                          // position:'absolute',
                          // top:0,
                          // left:0,
                          // width:25,
                          minWidth:0,
                          // height:'100%',
                          display:'flex',
                          // padding:'20px 0',
                          zIndex:1001
                        }}
                      >
                        {screenSize.sm ? 'Previous' : 'Previous Contact'}
                      </Button>

                      <Button
                        onClick={() => navigateContact('next')}
                        endIcon={<ChevronSVG height={12} color1={theme.palette.primary.main} style={{transform:'scale(-1)'}} />}
                        style={{
                          // position:'absolute',
                          // top:0,
                          // right:0,
                          lineHeight:1,
                          // width:25,
                          minWidth:0,
                          // height:'100%',
                          display:'flex',
                          // padding:'20px 0',
                          zIndex:1001
                        }}
                      >
                        {screenSize.sm ? 'Next' : 'Next Contact'}
                        
                      </Button>
                    </Grid>
                  :
                    null
              }


                <Grid
                
                >
                  {
                    editTitle
                      ?
                        <TextField
                          id="name"
                          onBlur={() => setEditTitle(false)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setEditTitle(false)
                            }
                          }}          
                          value={contact.name}
                          onChange={(e) => handleTextStandard(e.target.value, 'name')}
                          variant='standard'
                          autoFocus
                          inputProps={{
                            style:{
                              padding:0,
                              background:'transparent'
                            }
                          }}
                          InputProps={{
                            disableUnderline:true,
                            readOnly: !editTitle,
                            sx:{
                              fontSize:'2rem',
                              fontWeight:500,
                              lineHeight:1,
                              transition: '.3s',
                              borderRadius:1,
                            }
                          }}
                          style={{
                            borderRadius:8,
                            width:'auto',
                            margin:0,
                            // boxShadow:readOnly ? null : '0 0 5px #00000030',
                            padding:0,
                          }}
                        />
                      :
                        <Grid display="flex" alignItems="center">
                          <Typography
                            style={{
                              fontSize:'2rem',
                              fontWeight:500,
                              lineHeight:1,
                              color: contact.name ? 'inherit' : '#aaa',
                              fontStyle: contact.name ? 'inherit' : 'italic' 
                            }}
                          >
                            {contact.name ? contact.name : 'Unnamed Contact'}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              setEditTitle(true);
                              // document.getElementById('name').focus();
                            }}
                            style={{
                              marginLeft:8
                            }}
                          >
                            <EditSVG width={15} color1={theme.palette.primary.dark} />
                          </IconButton>
                          {
                            !contact.name.length &&
                              <TagButton
                                bgColor={`${theme.palette.primary.main}20`}
                                fontColor={theme.palette.primary.main}
                                outlined
                                onClick={createNameFromFields}
                                // iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                                label="Generate Name"
                              />
      
                          }
                        </Grid>
                  }

                  <Typography

                  >
                    {contact.primary_email}
                  </Typography>

                  {
                    contact.created_on &&
                      <Typography
                        style={{
                          marginBottom:8
                        }}
                      >
                        Added on {new Date(contact.created_on).toLocaleString('en-US', {month:'short', day:'2-digit', year:'numeric'})}
                      </Typography>
                  }

                </Grid>


                <Grid
                  style={{
                    display:'flex',
                    gap:16,
                    alignItems:'flex-start'
                  }}
                >
                  {/* <ButtonCustom
                    onClick={handleResetClick}
                    variant="outlined"
                    color="secondary"
                    iconStart={<WarningSVG width={15} color1={theme.palette.secondary.main} />}
                    text="Reset"
                  />
                  <Menu
                    id="reset-menu"
                    anchorEl={anchorElReset}
                    open={openReset}
                    onClose={handleResetClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}          
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    style={{
                      marginTop:4
                    }}
                  >
                    <Grid
                      container
                      style={{
                        width:300,
                        padding:'4px 16px',
                        justifyContent:'center'
                      }}
                    >
                    <Typography
                      style={{
                        fontSize:'1rem',
                        fontWeight:600
                      }}
                    >
                      Do you want to reset the contact?
                    </Typography>
                      <Typography
                      >
                        Unsaved changes will be deleted.
                      </Typography>
                      <Grid
                        container
                        justifyContent="space-around"
                        marginTop={1}
                      >
                        <ButtonCustom
                          onClick={handleResetClose}
                          variant="outlined"
                          color="secondary"
                          // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                          text="No"
                        />
                        <ButtonCustom
                          onClick={() => {
                            handleResetContact();
                            handleResetClose();
                          }}
                          color="secondary"
                          // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                          text="Yes"
                        />
                      </Grid>
                    </Grid>
                  </Menu> */}

                  {/* <ButtonCustom
                    onClick={() => isAddingField ? setIsAddingField(false) : setIsAddingField(true)}
                    variant="outlined"
                    iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                    text={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field")}
                  /> */}

                  <Button
                    disabled={!contact.primary_email || isSaved || isSaving}
                    variant="contained"
                    onClick={() => onSubmit(contact)}
                  >
                    {
                      isSaved ? 'Saved'
                        : isSaving
                          ? 'Saving...'
                          : 'Save'
                    }
                  </Button>

                  {
                    // !isAddingTag &&
                    //   <>
                    //   </>
                  }
                </Grid>


                  {
                    // 1 - Pending
                    // 2 - Subscribed
                    // 3 - Unsubscribed
                    // 4 - Cleaned
                    // 5 - EmailBounced
                    // 6 - EmailReportedSpam

                    // STATUS  /  COPIED FROM TAGS -- NEEDS UPDATING
                    <Grid
                      container
                      alignItems="center"
                    >
                      <TagButton
                        bgColor={contactStatusEnum.filter(x => x.status === contact.status)[0]?.color}
                        fontColor={contactStatusEnum.filter(x => x.status === contact.status)[0]?.text}
                        outlined
                        outlinedColor={contactStatusEnum.filter(x => x.status === contact.status)[0]?.color}
                        onClick={handleAddButtonStatusClick}
                        // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingButtonTag ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                        label={contactStatusEnum.filter(x => x.status === contact.status)[0]?.label}
                      />
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElStatus}
                        open={isAddingButtonStatus}
                        onClose={handleAddButtonStatusClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}          
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        style={{
                          marginTop:4
                        }}
                      >
                        {
                          contactStatusEnum.map(status => (
                            <MenuItem
                              onClick={(e) => handleTextStandard(e.target.value, 'status')}
                              value={status.status}
                            >
                              {status.label}
                            </MenuItem>

                          ))
                        }
                      </Menu>

                    </Grid>
                }

                {
                  // TAGS
                  // contact.tags
                  //   ?
                  //     <Grid
                  //       container
                  //       gap={1}
                  //       alignItems="center"
                  //       style={{
                  //         marginTop:8
                  //       }}
                  //     >
                  //       {
                  //         contact.tags.map(tag => (
                  //           <Tag
                  //             tag={tag}
                  //             removeTag={removeTag}
                  //             // setReload={setReload}
                  //             contactId={contact.id}
                  //             reloadContact={reloadContact}
                  //             showOptions
                  //           />
                  //         ))
                  //       }
                  //       <TagButton
                  //         bgColor="#eeeeee"
                  //         fontColor="#777777"
                  //         outlined
                  //         onClick={handleAddButtonTagClick}
                  //         iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingButtonTag ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                  //         label={ isAddingButtonTag ? 'Close' : 'Tag' }
                  //       />
                  //       <Menu
                  //         id="basic-menu"
                  //         anchorEl={anchorElTag}
                  //         open={isAddingButtonTag}
                  //         onClose={handleAddButtonTagClose}
                  //         anchorOrigin={{
                  //           vertical: 'bottom',
                  //           horizontal: 'right',
                  //         }}
                  //         transformOrigin={{
                  //           vertical: 'top',
                  //           horizontal: 'right',
                  //         }}          
                  //         MenuListProps={{
                  //           'aria-labelledby': 'basic-button',
                  //         }}
                  //         style={{
                  //           marginTop:4
                  //         }}
                  //       >
                  //         <ImportTags
                  //           setIsOpen={isAddingButtonTag}
                  //           contact={contact}
                  //           contactId={contact.id}
                  //           existingTags={contact.tags}
                  //           handleFieldImport={handleFieldImport}
                  //           closeMenu={handleAddButtonTagClose}
                  //           removeTag={removeTag}
                  //           setEditing={setEditing}
                  //           reloadContact={reloadContact}
                  //           // setReload={setReload}
                  //         />
                  //       </Menu>

                  //     </Grid>
                  //   :
                  //     null
                }

                {
                  // CUSTOM FIELD ADDING
                  <Grid
                    container
                    style={{
                      marginTop:8
                    }}
                  >
                    {/* <ButtonCustom
                      onClick={() => isAddingField ? setIsAddingField(false) : setIsAddingField(true)}
                      variant="outlined"
                      iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                      text={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field")}
                    /> */}

                    {/* <TagButton
                      bgColor={`${theme.palette.primary.main}20`}
                      fontColor={theme.palette.primary.main}
                      outlined
                      onClick={handleIsAddingField}
                      iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                      label={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field") }
                    /> */}

                  </Grid>
                }

              </Grid>


            </div>
          </ElevationScroll>
            {/* </Grid> */}
          
          <TableWrapper>
            <SectionHeader
              title="TAGS"
              expanded={expandedTags}
              setExpanded={setExpandedTags}
              button={
                <>
                  <TagButton
                    bgColor="#eeeeee"
                    fontColor="#777777"
                    outlined
                    onClick={handleAddButtonTagClick}  
                    iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingButtonTag ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                    label={ isAddingButtonTag ? 'Close' : (screenSize.sm ? 'Tag' : "Add Tag") }
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElTag}
                    open={isAddingButtonTag}
                    onClose={handleAddButtonTagClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}          
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    style={{
                      marginTop:4
                    }}
                  >
                    <ImportTags
                      setIsOpen={isAddingButtonTag}
                      contact={contact}
                      setContact={setContact}
                      contactId={contact.id}
                      existingTags={contact.tags}
                      handleFieldImport={handleFieldImport}
                      closeMenu={handleAddButtonTagClose}
                      removeTag={removeTag}
                      setEditing={setEditing}
                      reloadContact={reloadContact}
                      // setReload={setReload}
                    />
                  </Menu>
                </>
              }
            >
              <Grid
                container
                gap={1}
                variant="center"
                style={{
                  padding:'16px'
                }}
              >
                { // Must use incomingContact instead of contact to make sure tag colors update
                  incomingContact.tags.map(tag => (
                    <Tag
                      tag={tag}
                      removeTag={removeTag}
                      // setReload={setReload}
                      contactId={contact.id}
                      reloadContact={reloadContact}
                      showOptions
                    />
                  ))
                }
              </Grid>
            </SectionHeader>
            <SectionHeader
              title="MAILING LISTS"
              expanded={expandedLists}
              setExpanded={setExpandedLists}
              button={
                <TagButton
                  bgColor="#eeeeee"
                  fontColor="#777777"
                  // bgColor={`${theme.palette.primary.main}20`}
                  // fontColor={theme.palette.primary.main}
                  outlined
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsAddingList();
                  }}
                  iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingList ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                  label={ isAddingList ? 'Close' : (screenSize.sm ? 'List' : "Add List") }
                />
              }
              actions={
                <Grid
                  container
                  style={{
                    padding:8, 
                    borderRadius:8,
                    border: isAddingList ? `1px solid #ccc` : `1px solid #cccccc00`,
                    transition:'.5s'
                  }}
                >
                  <Collapse
                    in={isAddingList}
                    style={{
                      width:'100%',
                      // marginTop:isAddingList ? 16 : 0,
                      transition:'.75s'
                    }}
                  >
                    <Grid
                      container
                      variant="center"
                    >
                      {
                          lists?.filter(listItem => !contact.mailing_lists.some(mailingList => mailingList.id === listItem.id)).length
                            ?
                              lists?.filter(listItem =>
                                !contact.mailing_lists.some(mailingList => mailingList.id === listItem.id)
                              ).map((list, index) => (
                                <MailingListAddItem
                                  key={list.id}
                                  list={list}
                                  addList={addList}
                                />
                              ))
                            :
                              <Typography>{contact.name ? contact.name : 'This contact'} is subscribed to all mailing lists!</Typography>
                      }
                    </Grid>
                  </Collapse>

                </Grid>

              }
            >
              {
                contact?.mailing_lists?.length
                  ?
                    <Grid container>
                      {/* <TableRow
                        customFields={customFields}
                        customField={{key: 'primary_email', value: contact.primary_email}}
                        handleTextCustomField={handleTextStandard}
                        setEditing={setEditing}
                        active={editing === 'primary_email'}
                      /> */}
                      {
                        contact.mailing_lists.map((list, index) => (
                          <MailingListItem
                            key={list.id}
                            list={list}
                            removeList={removeList}
                          /> 
                        ))
                      }
                    </Grid>
                  :
                    <Typography width="100%" textAlign="center">No mailing lists have been created yet.</Typography>
              }
            </SectionHeader>
            <SectionHeader
              title="CUSTOM FIELDS"
              expanded={expandedFields}
              setExpanded={setExpandedFields}
              button={
                <TagButton
                  bgColor="#eeeeee"
                  fontColor="#777777"
                  // bgColor={`${theme.palette.primary.main}20`}
                  // fontColor={theme.palette.primary.main}
                  outlined
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsAddingField();
                  }}
                  iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                  label={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field") }
                />
              }
              subSummary={
                contactProfiles &&
                <>
                  <Grid container variant="center" gap={2} mt={1}>
                    <Grid sx={{display:'flex', alignItems:'center', gap:1, mr:2}}>
                      <Typography>Profiles</Typography>
                      <Infotip
                        question
                        title="Contact Profiles"
                        tip="Add preset fields from a profile"
                        messages={{
                          one: "Contact profiles allow you to add a group of custom fields that have been assigned to that profile.",
                          two: 'You can assign fields to contact profiles in the Preferences menu under the Contact Profiles tab.'
                        }}
                      />
                    </Grid>
                    {
                      contactProfiles.map(profileItem => (
                        <Grid item>
                          <TagButton
                            label={profileItem.label}
                            // fontColor="#777"
                            // outlinedColor="#CCC"
                            regular
                            onClick={(e) => {
                              e.stopPropagation();
                              // handleContactProfile(profileItem.order.filter(x => x.visible));
                              handleProfileFields(profileItem)
                            }}
                            // bgColor={profileItem.id === profile?.id
                            //   ? `${theme.palette.primary.main}99`
                            //   : `${theme.palette.primary.main}20`
                            // }
                            outlinedColor={profileItem.id === profile?.id
                              ? theme.palette.primary.main
                              : '#CCC' //theme.palette.primary.main
                            }
                            fontColor={profileItem.id === profile?.id
                              ? theme.palette.primary.main
                              : '#777'
                            }
                            outlined
                            active={profileItem.id === profile?.id}
      
                          />
                        </Grid>
                      ))
                    }
                  </Grid>

                  <Grid container variant="center" sx={{my:1, py:1, background:'#eee'}}>
                    <Typography>Empty custom fields will be removed to reduce clutter.</Typography>
                  </Grid>
                </>
              }
              actions={
                <Grid
                  container
                  style={{
                    padding:8, 
                    borderRadius:8,
                    border: isAddingField ? `1px solid #ccc` : `1px solid #cccccc00`,
                    transition:'.5s'
                  }}
                >
                  <Collapse
                    in={isAddingField}
                    style={{
                      width:'100%',
                      marginTop:isAddingField ? 16 : 0,
                      transition:'.75s'
                    }}
                  >
                    <ImportCustomFields
                      setIsOpen={setIsAddingField}
                      existingCustomFields={Object.keys(contact.custom_fields)}
                      handleFieldImport={handleFieldImport}
                      handleImportAllFields={handleImportAllFields}
                      setEditing={setEditing}
                      handleOpen={setIsAddingField}
                    />
                  </Collapse>

                </Grid>

              }
            >
            {
              contact
                ?
                  profile
                    ?
                      <>
                        {
                          // Object.entries(profileFields.custom_fields).map(([key, value], index) => {
                            Object.entries(profile.order.filter(x => x.visible).reduce((acc, field) => ({
                                ...acc,
                                [field.id]: contact.custom_fields[field.id] || ''
                            }), {})
                          ).map(([key, value], index) => {
                              // const fieldIdArr = customFields.map(x => x.id);
                            const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                            if (fieldId) {
                              return (
                                <TableRow
                                  contactId={contact.id}
                                  customFields={customFields}
                                  customField={{ key: fieldId, value: value }}
                                  index={index}
                                  handleTextCustomField={handleTextCustomField}
                                  handleListCustomField={handleListCustomField}
                                  handleNumberCustomField={handleNumberCustomField}
                                  handleDateCustomField={handleDateCustomField}
                                  handleBoolCustomField={handleBoolCustomField}
                                  handleImageCustomField={handleImageCustomField}
                                  handleFileCustomField={handleFileCustomField}
                                  handleRichTextCustomField={handleRichTextCustomField}
                                  confirmFieldRemove={confirmFieldRemove}
                                  setReloadCustomFields={setReloadCustomFields}
                                  showDelete
                                  setEditing={setEditing}
                                  active={editing === key}
                                />
                              )
                            }
                          })
                        }
                      </>
                    :
                      <>
                        {
                          Object.entries(profileFields)?.map(([key, value], index) => {
                              // const fieldIdArr = customFields.map(x => x.id);
                            const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                            if (fieldId) {
                              return (
                                <TableRow
                                  contactId={contact.id}
                                  customFields={customFields}
                                  customField={{ key: fieldId, value: value }}
                                  index={index}
                                  handleTextCustomField={handleTextCustomField}
                                  handleListCustomField={handleListCustomField}
                                  handleNumberCustomField={handleNumberCustomField}
                                  handleDateCustomField={handleDateCustomField}
                                  handleBoolCustomField={handleBoolCustomField}
                                  handleImageCustomField={handleImageCustomField}
                                  handleFileCustomField={handleFileCustomField}
                                  handleRichTextCustomField={handleRichTextCustomField}
                                  confirmFieldRemove={confirmFieldRemove}
                                  setReloadCustomFields={setReloadCustomFields}
                                  showDelete
                                  setEditing={setEditing}
                                  active={editing === key}
                                />
                              )
                            }
                          })
                        }
                      </>
                :
                <Typography>No custom fields have been created yet.</Typography>
            }

              {
                // Object.keys(profileFields).length
                //   ?
                //     <>
                //       {/* <TableRow
                //         customFields={customFields}
                //         customField={{key: 'primary_email', value: contact.primary_email}}
                //         handleTextCustomField={handleTextStandard}
                //         setEditing={setEditing}
                //         active={editing === 'primary_email'}
                //       /> */}
                //       {
                //         Object.entries(profileFields).map(([key, value], index) => {
                //           // const fieldIdArr = customFields.map(x => x.id);
                //           const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                //           if (fieldId) {
                //             return (
                //               <TableRow
                //                 contactId={contact.id}
                //                 customFields={customFields}
                //                 customField={{key: fieldId, value: value}}
                //                 index={index}
                //                 handleTextCustomField={handleTextCustomField}
                //                 handleListCustomField={handleListCustomField}
                //                 handleNumberCustomField={handleNumberCustomField}
                //                 handleDateCustomField={handleDateCustomField}
                //                 handleBoolCustomField={handleBoolCustomField}
                //                 handleImageCustomField={handleImageCustomField}
                //                 handleFileCustomField={handleFileCustomField}
                //                 handleRichTextCustomField={handleRichTextCustomField}
                //                 confirmFieldRemove={confirmFieldRemove}
                //                 setReloadCustomFields={setReloadCustomFields}
                //                 showDelete
                //                 setEditing={setEditing}
                //                 active={editing === key}
                //               />
                //             )
                //           }
                //         })
                //       }
                //     </>
                //   :
                //     <Typography width="100%" textAlign="center">No custom fields have been created yet.</Typography>
              }
            </SectionHeader>
          </TableWrapper>

          {/* <Grid
            container
            variant="center"
            style={{
              paddingBottom:36
            }}
          >
            <Button
              disabled={!contact.primary_email}
              variant="contained"
              onClick={() => onSubmit(contact)}
            >
              {screenSize.sm ? 'Save' : "Save Contact"}
            </Button>
          </Grid> */}

          {/* REMOVE CUSTOM FIELD DIALOG */}
          <DialogWarning
            open={openWarning}
            onClose={handleCloseWarning}
            header="Remove custom field"
            title={`You are about to remove the ${customFields?.filter(x => x.id === fieldToRemove?.key )[0]?.field_name} field`}
            messages={{
              one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
              confirm:'Yes'
            }}
            // deleting
            cancel={handleCloseWarning}
            confirm={handleFieldRemove}
            // icons
          />

        </Grid>
      :
        null
  )
}

export default ContactEdit

const MailingListItem = (props) => {
  const { list, removeList } = props;
  const [hover, setHover] = useState(false);

  return (
    <Grid
      item
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display:'flex',
        alignItems:'center',
        columnGap:8,
      }}
      xs={12}
      sm={6}
      md={4}
      lg={3}
    >
      <Grid
        style={{
          border: `1px solid #ccc`,
          borderRadius:16,
          padding:'0 4px 0 30px',
          display:'flex',
          alignItems:'center',
          cursor:'pointer'
        }}
      >

      <a
        href={`/lists/${list.id}`}
        style={{
          textDecoration:'none',
          color:'inherit'
        }}
      >
        <Typography>{list.name}</Typography>
      </a>
      <div
        style={{
          width:20,
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          marginLeft:8
        }}
      >
        {
          hover &&
            <IconButton
              onClick={() => removeList(list.id)}
              style={{
                padding:0
              }}

            >
              <CloseFilledSVG width={16} />
            </IconButton>
        }
      </div>
      </Grid>

    </Grid>
  )
}

const MailingListAddItem = (props) => {
  const { list, addList } = props;
  const [hover, setHover] = useState(false);

  return (
    <Grid
      item
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display:'flex',
        alignItems:'center',
        columnGap:8,
      }}
      xs={12}
      sm={6}
      md={4}
      lg={3}
    >
      <Grid
        // container
        onClick={() => addList(list)}
        style={{
          border: `1px solid #ccc`,
          borderRadius:16,
          padding:'0 30px 0 4px',
          display:'flex',
          alignItems:'center',
          cursor:'pointer'
        }}
      >
        <div
          style={{
            width:20,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            marginRight:8
          }}
        >
          {
            // hover &&
              <IconButton
                style={{
                  padding:0
                }}

              >
                <AddFillSVG width={16} style={{transform:hover ? 'scale(1)' : 'scale(0)', transition:'.2s'}} />
              </IconButton>
          }
        </div>

        {/* <a
          href={`/lists/${list.id}`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        > */}
          <Typography>{list.user_visible_name}</Typography>
        {/* </a> */}
      </Grid>
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  const screenSize = useScreenSize();
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        padding: screenSize.xs ? '16px 8px' : '16px 32px',
        // transition:'.5s'
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { contactId, customField, index, handleTextCustomField, handleListCustomField, handleNumberCustomField, handleDateCustomField, handleBoolCustomField, handleImageCustomField, handleFileCustomField, handleRichTextCustomField, confirmFieldRemove, showDelete, setEditing, active, customFields, setReloadCustomFields } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        // width: showDelete ? 'calc(100% - 50px)' : '100%',
        alignItems:'center'
      }}
    >
      {
        index !== null && index !== undefined
          ?
            <EditableDisplay
              fieldKey={customField.key}
              fieldValue={customField.value}
              customField={customField}
              contactId={contactId}
              onTextChange={(e) => handleTextCustomField(e.target.value, customField.key)}
              onListChange={(e) => handleListCustomField(e, customField.key)}
              onNumberChange={(e) => handleNumberCustomField(e.target.value, customField.key)}
              onDateChange={(e) => handleDateCustomField(e, customField.key)}
              onBoolChange={(e) => handleBoolCustomField(e.target.checked, customField.key)}
              onImageChange={(e) => handleImageCustomField(e, customField.key)}
              onFileChange={(e) => handleFileCustomField(e, customField.key)}
              onRichTextChange={(e) => handleRichTextCustomField(e, customField.key)}
              active={active}
              setEditing={setEditing}
              showDelete={showDelete}
              onClickRemove={() => confirmFieldRemove(customField)}
              setReloadCustomFields={setReloadCustomFields}
              customFields={customFields}
            />
          :
            <>
              <Grid display="flex" alignItems="center">
                <Typography
                  style={{
                    marginLeft:16,
                    marginRight:4,
                    fontWeight:500,
                    color: theme.palette.primary.main,
                  }}
                >
                  {customField.key}
                </Typography>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    copyString(customField.value);
                  }}
                >
                  <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
                </IconButton>
              </Grid>
              <InputField
                // placeholder='Field Name'
                // label={customField.key}
                value={customField.value}
                onChange={(e) => handleTextCustomField(e.target.value, customField.key)}
                // width="calc(100% - 50px)"
                margin
              />
            </>

      }
      {/* <Grid
        style={{
          width:50,
          margin:'auto',
          textAlign:'center'
        }}
      >
        {
          showDelete &&
            <IconButton
              onClick={() => confirmFieldRemove(customField)}
              style={{
                width:35,
                height:35,
                margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
              }}
            >
              <CloseFilledSVG width={25} color1="#ccc" />
            </IconButton>
        }
      </Grid> */}
      {
        // columns.map(item => (
        //   <TableColumn item={item}>
        //     {
        //       item.id === 'data_type' &&
        //         <Grid
        //           style={{
        //             width:50,
        //             display:'flex',
        //             alignItems:'center',
        //             justifyContent:'center',
        //             marginLeft:-10
        //           }}
        //         >
        //           {cloneElement(selectedIcons[customField.data_type], {color1:'#777'})}
        //         </Grid>
        //     }
        //     {
        //       item.id === 'actions' &&
        //         <VisibleSVG width={20} color1="#777" />
        //     }
        //     <Typography style={{ fontSize:'1rem'}}>{customField[item.id]}</Typography>
        //   </TableColumn>
        // ))
      }
    </Grid>
  )
}

const EditableDisplay = (props) => {
  const { contactId, fieldKey, fieldValue, active, onTextChange, onListChange, onNumberChange, onDateChange, onBoolChange, onImageChange, onFileChange, onRichTextChange, setEditing, showDelete, onClickRemove, customField, customFields, setReloadCustomFields } = props;
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    console.log('active', active);
    if (!active) {
      setEditing(fieldKey);
      setTimeout(() => {
        if (document.getElementById(fieldKey)) {
          document.getElementById(fieldKey).focus();
        }
      }, 0);
    }
  }

  const fieldName = customFields.filter(x => x.id === fieldKey)[0].field_name;
  const fieldType = customFields.filter(x => x.id === fieldKey)[0].data_type;
  const copyable = ['text', 'number', 'email', 'date', 'url', 'large_text_field', 'phone_number'].includes(fieldType);

  return (
    <Grid
      id={`r-${fieldKey}`}
      container
    >
      <Grid
        container
        tabIndex="0"
        justifyContent="space-between"
        onClick={handleClick}
        // onKeyDown={(e) => { // <-- This is handled automatically by the onFocus
        //   if (e.key === "Enter") {
        //     handleClick()
        //   }
        // }}
        onFocus={handleClick}
        style={{
          background:hover ? '#eee' : 'transparent'
        }}
      >
        <Grid display="flex" alignItems="center">
          <Typography
            style={{
              marginLeft:16,
              marginRight:4,
              fontWeight:500,
              color: theme.palette.primary.main,
            }}
          >
            {fieldName}
          </Typography>

          {
            copyable &&
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyString(fieldValue);
                }}
              >
                <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
              </IconButton>
          }
          {
            // active
            //   ?
            //   <IconButton
            //     onClick={() => setEditing(null)}
            //   >
            //     <CloseRoundedSVG width={15} color1={theme.palette.primary.dark} />
            //   </IconButton>
            //   :
            //   <IconButton
            //   // onClick={() => {
            //   //   setEditing(fieldKey);
            //   //   setTimeout(() => {
            //   //     setFocus();
            //   //   }, 0);
            //   // }}
            //   >
            //     <EditSVG width={15} color1={theme.palette.primary.dark} />
            //   </IconButton>
          }
        </Grid>
        <Grid display="flex" alignItems="center">
          {
            showDelete &&
              <IconButton
                onClick={onClickRemove}
                style={{
                  width:35,
                  height:35,
                  // margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
                }}
              >
                <DeleteSVG width={12} color1={hover ? "#333" : "#aaa"} />
              </IconButton>
          }
        </Grid>


      </Grid>
      <Grid
        container
        style={{
          padding:'0 8px'
        }}
      >
        <InputFieldType
          id={fieldKey}
          contactId={contactId}
          customField={customField}
          customFields={customFields}
          value={fieldValue}
          onTextChange={onTextChange}
          onListChange={onListChange}
          onNumberChange={onNumberChange}
          onDateChange={onDateChange}
          onBoolChange={onBoolChange}
          onRichTextChange={onRichTextChange}
          onSelectChange={onTextChange}
          onFileChange={onFileChange}
          onImageChange={onImageChange}
          enableOnClick={handleClick}
          setReloadCustomFields={setReloadCustomFields}
          noAutoFocus
          margin
        />

        {/* <InputField
          id={fieldKey}
          // placeholder='Field Name'
          // label={fieldKey}
          value={fieldValue}
          onChange={onChange}
          // readOnly={!active}
          enableOnClick={handleClick}
          // width="calc(100% - 50px)"
          noAutoFocus
          margin
        /> */}
      </Grid>

      {
        // active
        //   ?
        //     <>
        //       <InputField
        //         // placeholder='Field Name'
        //         // label={fieldKey}
        //         value={fieldValue}
        //         onChange={onChange}
        //         // width="calc(100% - 50px)"
        //         margin
        //       />
        //     </>
        //   :
        //     <>
        //       <Typography>{fieldValue}</Typography>
        //     </>
      }
    </Grid>

  )
}

const SectionHeader = (props) => {
  const { children, actions, title, subSummary, button, expanded, setExpanded } = props;

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={() => setExpanded(prev => !prev)}
      style={{
        width:'100%',
        marginBottom:8
      }}
      sx={{
        '&&.MuiPaper-root': {
          boxShadow:`0 0 5px #00000030`,
          borderRadius:2
        }
      }}
    >
      {/* <Divider style={{marginTop:16, width:'100%'}} /> */}
      <AccordionSummary >
        <Grid
          container
          style={{
            position:'relative',
            padding:'8px 0',
            justifyContent:'center'
          }}
        >
          <Typography
            style={{
              fontSize:'.85rem',
              // padding:'2px 12px',
              // borderRadius:20,
              fontWeight:600,
              // background:'#ddd'
            }}
          >
            {title}
          </Typography>
          <div
            style={{
              position:'absolute',
              right:0
            }}
          >
            {button}
          </div>
        <Grid container>
          <Divider style={{marginTop: expanded ? 16 : 0, width: '100%', opacity: expanded ? 1 : 0, transition:'.2s'}} />
        </Grid>
        {expanded && subSummary}
        </Grid>

      </AccordionSummary>
      <AccordionActions disableSpacing disableGutters>
        {actions}
      </AccordionActions>
      <AccordionDetails disableSpacing disableGutters>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}