import { Grid, Typography } from '@mui/material'
import React from 'react'
import FormDisplay from './FormDisplay'
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';

const imageArray = [
  'automated-campaigns',
  'crm-emails',
  'custom-properties',
  'dedicated-ip',
  'double-optin',
  'early-access',
  'email-design',
  'email-love'

]

const FormSubscribeContainer = (props) => {
  const { form, token } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        minHeight:'100vh',
        background:`${theme.palette.primary.main}33`,
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'     
      }}
    >
      <Grid container style={{zIndex:10, justifyContent:'center'}}>
        <FormDisplay form={form} token={token} incomingPreview />
      </Grid>
      <Typography>Powered by Fill Marketing</Typography>
      <img
        src={`https://static.fillmarketing.com/static/static/img/email-love_500px.png`}
        style={{
          position:'fixed',
          bottom:0,
          left:0,
          height:'50%',
          width:'auto',
          objectFit:'contain',
          zIndex:0
        }}
      />
      <a
        href="https://fillmarketing.com"
        target="_blank"
        style={{
          position:'fixed',
          bottom:20,
          right:20,
          display:'flex',
          flexDirection:'column',
          alignItems:'flex-end',
          textDecoration:'none',
          color:'inherit'
        }}
      >
        <LogoHorizontal width={50} />
        <Typography>Fill Marketing</Typography>
      </a>
        
    </Grid>
  )
}

export default FormSubscribeContainer