import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import Building1 from '../../assets/lottie/Building1.json'
import LottiePlayer from '../ui/LottiePlayer';

const Step4ConfirmCsvUpload = (props) => {
  const { csvFile } = props;


  return (
    csvFile &&
    <Grid
      container
      style={{
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        paddingTop:40
      }}
    >
      <LottiePlayer
          data={Building1}
          height={150}
          ratio="xMidYMax"
        />
      <Typography style={{fontSize:'1.3rem', marginBottom:8}}>Please wait while we build your custom fields for you.</Typography>

      <Typography>This may take a minute or two. You will be redirected when your new fields are ready.</Typography>
    </Grid>
  )
}

export default Step4ConfirmCsvUpload