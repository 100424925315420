import React, { useState, useEffect } from 'react'
import { Button, Drawer, Grid, Typography } from '@mui/material'
import { AccountSVG, CloseFilledSVG, RadialClosedSVG } from '../../assets/icons';
import SettingsFieldOrder from './SettingsFieldOrder';
import { useTheme } from '@emotion/react';
import SettingsContactProfiles from './SettingsContactProfiles';
import SettingsGeneral from './SettingsGeneral';

const SettingsDrawer = () => {
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  // Listen for settings button click
  useEffect(() => {
    const settingsButton = document.getElementById('settings-button');

    if (settingsButton) {
        settingsButton.addEventListener('click', handleOpen);
    }

    return () => {
        if (settingsButton) {
            settingsButton.removeEventListener('click', handleOpen);
        }
    };
  }, []); // Empty dependency array to run the effect only once


  return (
    <>
      {/* <div
        style={{
          height:'57px',
          display:'flex',
          alignItems:'center',
          paddingLeft:'24px'
        }}
      >
        <a
          onClick={handleOpen}
          sx={{ // Match nav button style in django
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight:1.2,
            color:'inherit',
            textTransform:'none',
            cursor:'pointer',
            '&&:hover': {
              fontWeight:500,
              background:'none'
            }
          }}
        >
          <AccountSVG width={16} />
        </a>
      </div> */}
      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            overflowY:'inherit',
            // width:'40%',
            // minWidth:250
          }
        }}
      >
        <Grid
          style={{
            background:'#fff',
            height:'100vh'
          }}
        >
          <SettingsContent />
        </Grid>

      </Drawer>
    </>
  )
}

export default SettingsDrawer

const settingsMenuTabs = [
  {
    label: 'Custom Field Layouts',
    id: 'layouts'
  },
  {
    label: 'Contact Profiles',
    id: 'profiles'
  },
  {
    label: 'General',
    id: 'general'
  },
]

const SettingsContent = () => {
  const [setting, setSetting] = useState(null);

  return (
    <div
      style={{
        // padding:'0 0 16px 24px'
      }}
    >
      <Grid
        container
      >
        <Grid
          style={{
            paddingTop:16,
            width: 250,
            borderRight:'1px solid #aaa'
          }}
        >
          <div
            style={{
              display:'flex',
              gap:8,
              marginBottom:16,
              padding:'0 0 16px 24px'
            }}
          >
            <AccountSVG width={25} />
            <Typography
              style={{
                fontSize:'1.6rem',
                fontWeight:600
              }}
            >
              Settings
            </Typography>
          </div>
          {
            settingsMenuTabs.map(tab => (
              <MenuTab key={tab.id} tab={tab} setting={setting} setSetting={setSetting} />
            ))
          }
        </Grid>
        <Grid
        
        >
          <Grid
            style={{
              width: setting ? '100%' : '0%',
              transition: '.4s',
              overflowX:'hidden',
              // overflowY:'auto',
              height:'100vh'
            }}
          >
            {
              setting === "layouts" &&
                <SettingsFieldOrder />
            }
            {
              setting === "profiles" &&
                <SettingsContactProfiles />
            }
            {
              setting === "general" &&
                <SettingsGeneral />
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const MenuTab = (props) => {
  const { tab, setting, setSetting } = props;
  const theme = useTheme();

  return (
    <div
      onClick={() => setSetting(tab.id)}
      style={{
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        background: setting === tab.id ? `${theme.palette.primary.light}10` : 'none',
        transition:'.4s',
        paddingLeft:24,
        cursor:'pointer',
        // borderRight: setting === tab.id ? `10px solid blue` : 'none'
      }}
    >
      <Typography
        sx={{ // Match nav button style in django
          fontSize: '1rem',
          fontWeight: setting === tab.id ? 500 : 400,
          lineHeight:1.2,
          color:'inherit',
          textTransform:'none',
          '&&:hover': {
            fontWeight:500,
            background:'none'
          }
        }}
      >
        {tab.label}
      </Typography>
      <div
        style={{
          position:'absolute',
          top: setting === tab.id ? 0 : '50%',
          right:0,
          background:theme.palette.primary.main,
          height: setting === tab.id ? '100%' : '0%',
          width:'10px',
          transition:'.4s'
        }}
      />
      <div
        style={{
          position:'absolute',
          top: 'calc(50% - 8px)',
          right:18,
          opacity: setting === tab.id ? 1 : 0,
        }}
      >
        <a
          onClick={(e) => {
            if (setting === tab.id) {
              e.stopPropagation();
              setSetting(null)
            }
          }}
          style={{
            position:'relative',
            zIndex:1200
          }}
        >
          <CloseFilledSVG width={16} color1={`${theme.palette.primary.light}77`} />
        </a>
      </div>
    </div>
  )
}