import React, { useState } from 'react'
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import ResetMessage from './ResetMessage';
import { postResendEmail } from '../../hooks/mutations';

const ResetPasswordForm = ({setForgot}) => {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);

  const theme = useTheme();

  const resetPassword = () => {
    const resetObj = {
      payload: {
        email: email
      }
    }
    const reset = postResendEmail(resetObj);
    if (reset.status === "success") {
      resetSent(true);
    }
  }

  return (
    resetSent
      ?
        <ResetMessage />
      :
        <Card
          sx={{

          }}
        >
          <Grid
            variant="center"
            sx={{
              flexDirection:'column'
            }}
          >
            <LogoHorizontal
              width={65
              }
            />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                fontSize:'1.4rem',
                fontWeight: 600
              }}
            >
              RECOVER PASSWORD
            </Typography>
          </Grid>

          <Grid
            variant="center"
            flexDirection="column"
          >
            <TextField
              label="Email"
              autoFocus
              // InputLabelProps={{ shrink: true }}
              fullWidth
              style={{
                maxWidth:400
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Typography
              style={{
                maxWidth:400,
                textAlign:'center',
                lineHeight:1.2,
                marginBottom:16
              }}
            >
              <span style={{fontWeight:600}}>Enter the email</span> you used to register. You will receive a password reset link.
            </Typography>
            <Button
              variant="contained"
              onClick={resetPassword}
              disabled={!email}
            >
              {
                // registerFirstName && registerLastName && registerPassword && registerPassword === confirmPassword 
                //   ? 'Register'
                //   : 'Fill out all fields'
              }
              Send Link
            </Button>
            <Button style={{marginTop:24}} onClick={() => setForgot(false)}>
              {`< Back to login`}
            </Button>

          </Grid>

        </Card>
  )
}

export default ResetPasswordForm