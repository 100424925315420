export const email_template_ex_1 = 
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1400" className="parent"><defs></defs><title>email_template-ex-1</title>
      <rect class="a" width="1920" height="1400" rx="87.5" ry="87.5"/>
      <rect class="b anim1 anim-init" x="473.0722" y="120" width="974.8556" height="490" rx="27.75" ry="27.75"/>
      <path class="f anim1 anim-init" d="M783.5855,257.6754V448.4767a24.2586,24.2586,0,0,0,24.2587,24.2587h302.5711a24.2586,24.2586,0,0,0,24.2587-24.2587V257.6754a24.2586,24.2586,0,0,0-24.2587-24.2587H807.8442A24.2587,24.2587,0,0,0,783.5855,257.6754Zm306.8762,182.6233h-9.051a12.7052,12.7052,0,0,1-10.5443-5.6171l-55.5291-82.6062a12.7053,12.7053,0,0,0-20.7022-.5436L976.7,375.4039a12.7053,12.7053,0,0,1-20.2321.11l-57.1952-74.428a12.7052,12.7052,0,0,0-20.4335.386l-39.8909,56.18c-7.1664,10.0928-23.0645,5.0226-23.0645-7.3557V279.4321a12.7052,12.7052,0,0,1,12.7052-12.7052h261.8726a12.7052,12.7052,0,0,1,12.7052,12.7052V427.5935A12.7052,12.7052,0,0,1,1090.4617,440.2987Z"/>
      <rect class="c" x="472.1848" y="648" width="447.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="472.1848" y="723" width="447.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="472.1848" y="798" width="447.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="472.1848" y="1177" width="951.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="472.1848" y="1251" width="951.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="1000.1848" y="917" width="447.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="1000.1848" y="992" width="447.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="c" x="1000.1848" y="1067" width="447.8152" height="55.5001" rx="27.75" ry="27.75"/>
      <rect class="d anim3 anim-init" x="474.1611" y="878.9766" width="443.8623" height="277.0469" rx="25.7734" ry="25.7734"/>
      <path class="d anim3 anim-init" d="M880.23,906.954a8.6827,8.6827,0,0,1,8.6728,8.673v203.746a8.6827,8.6827,0,0,1-8.6728,8.673H515.04a8.6829,8.6829,0,0,1-8.6731-8.673V915.627a8.6829,8.6829,0,0,1,8.6731-8.673H880.23m0-3.954H515.04a12.627,12.627,0,0,0-12.627,12.627v203.746A12.627,12.627,0,0,0,515.04,1132H880.23a12.627,12.627,0,0,0,12.6269-12.627V915.627A12.627,12.627,0,0,0,880.23,903Z"/>
      <path class="e anim3 anim-init" d="M892.25,880.954a23.8229,23.8229,0,0,1,23.7959,23.796v225.5a23.8229,23.8229,0,0,1-23.7959,23.796H499.9348a23.823,23.823,0,0,1-23.7961-23.796V904.75a23.823,23.823,0,0,1,23.7961-23.796H892.25m0-3.954H499.9348a27.75,27.75,0,0,0-27.75,27.75v225.5a27.75,27.75,0,0,0,27.75,27.75H892.25A27.75,27.75,0,0,0,920,1130.25V904.75A27.75,27.75,0,0,0,892.25,877Z"/>
      <rect class="e anim3 anim-init" x="504.3896" y="904.9766" width="386.4902" height="225.0469" rx="10.6504" ry="10.6504"/>
      <g id="circle-anim2" class="anim2 anim-init">
        {/* <animateTransform
          attributeName="transform"
          values="5; .9; 1.05; .95; 1; 1"
          keyTimes="0; 0.1; 0.15; 0.18; 0.2; 1"
          dur="4s"
          type="scale"
          repeatCount="indefinite"
          keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
          calcMode="spline"
        /> */}
        <circle class="b" cx="1224" cy="765" r="126">
        </circle>
        <path class="f anim2" transform="50,50" d="M1144.26,721.67v86.812a11.0373,11.0373,0,0,0,11.0373,11.0373h137.6657A11.0374,11.0374,0,0,0,1304,808.4821V721.67a11.0374,11.0374,0,0,0-11.0374-11.0374H1155.2969A11.0374,11.0374,0,0,0,1144.26,721.67Zm139.6244,83.0911h-4.1181a5.7807,5.7807,0,0,1-4.7975-2.5558l-25.265-37.5846a5.7806,5.7806,0,0,0-9.4192-.2473l-8.16,10.8614a5.7807,5.7807,0,0,1-9.2053.05l-26.023-33.8637a5.7807,5.7807,0,0,0-9.297.1756l-18.15,25.561a5.7807,5.7807,0,0,1-10.494-3.3467V731.5691a5.7807,5.7807,0,0,1,5.7807-5.7807H1283.884a5.7807,5.7807,0,0,1,5.7807,5.7807v67.4114A5.7807,5.7807,0,0,1,1283.884,804.7612Z">
        </path>
      </g>
      </svg>
      <style jsx>{`
        .parent {
          transition: transform 0.3s ease;
          border-radius:32px;
          box-shadow: 0 0 10px #00000030;
          overflow: visible;
        }
        .a {
          fill:#fff;
        }
        .b{
          fill:#c9e7f4;
        }
        .c{
          fill:#e5e5e5;
        }
        .d{
          fill:#efc899;
          transform: scale(5);
        }
        .e{
          fill:#fbeee0;
          transform: scale(5);
        }
        .f{
          fill:#27ade7;
          transform-origin: center;
          // animation: scaleAnimation 4s infinite;
        }
        .anim-init{
          transform:scale(5);
          opacity:0;
        }
        .anim1{
          transform-origin: center;
          animation: scaleAnimation 4s infinite;
        }
        .anim2{
          transform-origin: center;
          animation: scaleAnimation 4s infinite;
          animation-delay: .2s;
        }
        .anim3{
          transform-origin: center;
          animation: scaleAnimation 4s infinite;
          animation-delay: .4s;
        }
        .open .rect1 {
          transform: translate(40px, 15px) rotate(45deg);
        }
        .open .rect2 {
          opacity: 0;
        }
        .open .rect3 {
          transform: translate(-100px, 45px) rotate(-45deg);
        }
        @keyframes scaleAnimation {
          0% {
            transform: scale(5);
            opacity: 0;
          }
          2% {
            opacity: 0;
          }
          10% {
            transform: scale(0.9);
            opacity: 1;
          }
          15% {
            transform: scale(1.05);
          }
          18% {
            transform: scale(0.98);
          }
          20% {
            transform: scale(1);
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }

      `}</style>
    </>
