import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import FileDropZone from '../../../ui/FileDropZone';
import { getRandomInts } from '../../../../lib/Functions';
import { InputField } from '../../../ui/Fields';

const ImageUploadAnswerElement = (props) => {
  const { image, questionIndex, imageIndex, images, handlePageAnswerChange, handleAnswerImageChange } = props;
  const [file, setFile] = useState(null)

  // Save the file to state in parent component when updated
  useEffect(() => {
    if (file) {
      handleFileUpload();
    }
  }, [file])

  // Set file state if an images has already been uploaded
  useEffect(() => {
    if (image) {
      setFile(image);
    }
  }, [image])

  // const handleAnswerImageChange = (images) => {
  //   handlePageAnswerChange(0, questionIndex, imageIndex, 'choice_images', images)
  // }

  const handleFileUpload = () => {
    if (image) {
      const newImages = [ ...images ];
    } else {
      const newImages = [ ...images ];
      console.log('newImages', newImages);
      const updatedImages = [ ...newImages, { ...file, id: getRandomInts(8), choice_text:'' } ];
      console.log('updatedImages', updatedImages);
      handleAnswerImageChange(updatedImages);
    }
    
  }

  return (
    <Grid
      container
    >
      {
        image
          ?
            <Grid
              container
              gap={2}
              style={{
                alignItems:'center',
                borderTop: '1px solid #ccc',
                padding: '16px 0',        
              }}
            >
              <Grid
                item
                xs="auto"
              >
                <img
                  src={image.blob}
                  style={{
                    height:80,
                    width:80,
                    borderRadius:16,
                    objectFit:'cover',
                    objectPosition:'center'
                  }}
                />
              </Grid>
              <Grid
                item
                style={{
                  display:'flex',
                  alignItems:'center'
                }}
                xs
              >
                <InputField
                  placeholder="Description (optional)"
                  value={file?.choice_text}
                  onChange={(e) => handlePageAnswerChange(0, questionIndex, imageIndex, 'choice_text', e.target.value)}
                  multiline
                  maxRows={2}
                  width="100%"
                  marginAmount="8px 0"
                />
              </Grid>

            </Grid>
          :
            <FileDropZone setFile={setFile} maxSize={8_000_000} multiple={false} />
      }
    </Grid>
  )
}

export default ImageUploadAnswerElement