import React from 'react'
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import MainNav from '../components/nav/MainNav';

const MainPageTemplate = (props) => {
  const { children, background, title, description, name, type, hideNav } = props;
  const theme = useTheme();
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;

  return (
    <div
      style={{
        background: currentEnv == "STAGING"
          ? 
            background
              ? background
              : theme.palette.background.secondary
          : 'none'
        ,
        // minHeight:'100vh'
      }}
    >
      <Helmet>
        <title>{title ? title : ''} | Fill Marketing</title>
        <meta name={'description'} content={description} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        { /* End Twitter tags */ }
      </Helmet>
      {
        // hideNav
        //   ?
        //     null
        //   :
        //     <MainNav />
      }
      <div
        style={{
          paddingTop: hideNav ? '0' : '0px',
          position:'relative',
          // minHeight:'100vh',
          overflow:'auto',
          display:'flex',
          flexDirection:'column'
          // alignItems:'center'
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default MainPageTemplate