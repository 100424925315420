import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import CRM from '../components/crm/CRM'
import ContactCRM from '../components/contacts-crm/ContactCRM'

const ContactCRMPage = () => {
  return (
    // <MainPageTemplate title="CRM">
      <ContactCRM />
    // </MainPageTemplate>
  )
}

export default ContactCRMPage