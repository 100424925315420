import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Collapse, Grid, IconButton, InputBase, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { CloseOutlineSVG, FilterSVG, SearchSVG } from '../../assets/icons';
import CustomTooltip from './CustomTooltip';

const ReusableContactSearch = (props) => {
  const { content, setContent, filterBy, setFilterBy, searchFields, useSearch, useSort, placeholder, isOpen } = props;
  const [initContent, setInitContent] = useState(null);

  const theme = useTheme();

  // Search Menu
  const [searchBy, setSearchBy] = useState('');
  const [searchOpen, setSearchOpen] = useState(isOpen ? isOpen : false);
  const [isSearching, setIsSearching] = useState(false);

  // Update search when new search terms are typed
  // useEffect(() => {
  //   if (searchBy.length) {
  //     handleApiQuerySearch();
  //     if (setIsSearching) {
  //       setIsSearching(true);
  //     }
  //   } else {
  //     clearSearch();
  //     if (setIsSearching) {
  //       setIsSearching(false);
  //     }
  //   }
  // }, [searchBy])

  // Save the initial content state to be reset when queries are cleared
  useEffect(() => {
    if (content && !initContent) {
      setInitContent(content);
    }
  }, [content])

  useEffect(() => {
    if (searchBy.length) {
      let timeoutId;

      setIsSearching(true);
      
      timeoutId = setTimeout(() => {
        handleApiQuerySearch();
        setIsSearching(false);
      }, 750);
      
      return () => {
        clearTimeout(timeoutId);
      };
    } else if (!isSearching && searchBy.length === 0 && content !== initContent) {
      clearSearch();
    }
  }, [searchBy]);

  // Set isSeaching to false when content reloads
  useEffect(() => {
    if (content) {
      setIsSearching(false);
    }
  }, [content])


  const handleSearchClick = (event) => {
    if (searchOpen) {
      setSearchOpen(false);
    } else {
      setSearchOpen(true);
      const searchEl = document.getElementById('searchbar-input');
      setTimeout(() => {
        searchEl.focus();
      }, 100);
    }
  }

  // Clear the searched terms and reset the displayed content
  const clearSearch = () => {
    console.log('running clear search')
    setSearchBy('');
    setContent(initContent);
  }

  // Search through database for contact
  const handleApiQuerySearch = () => {
    setFilterBy(prev => ({
      ...prev,
      search: searchBy
    }))
  }

  // Handle searching through content
  const handleSearch = () => {
    // Initilaize search result array
    let searchResults = [];

    // Search through contents of each field provided in searchFields array prop
    searchFields.forEach(field => {
      const foundItems = searchByField(field);
      
      // Push each item that matches the search term into the searchResults array
      foundItems.forEach(item => searchResults.push(item));
    })

    // Remove any duplicate result items with a new Set()
    const uniqueSearchResults = [ ...new Set(searchResults) ];
    // setContent(uniqueSearchResults);

  }

  const searchByField = (field) => {
    // Make field and search strings lower case and search field to see if any string matches the search term
    const filteredContent = content.filter(x => x[field].toLowerCase().includes(searchBy.toLowerCase()));
    return filteredContent;
  }

  return (
    <div id="search-wrapper">
        <Box sx={{display:'flex', alignItems:'center'}}>
          {useSearch &&
            <Box sx={{display:'flex', alignItems:'center'}}>
                <Grid
                  container
                  sx={{
                    background: searchOpen ? `${theme.palette.primary.main}20` : 'transparent',
                    border:`1px solid`,
                    borderColor: searchOpen ? 'primary.main' : 'transparent',
                    borderRadius:20,
                    justifyContent:'center',
                    alignItems:'center',
                    pr:0,
                    transition:'.3s'
                  }}
                  // xs={12}
                >
                  <Collapse orientation="horizontal" in={searchOpen} >
                    <InputBase
                      id="searchbar-input"
                      placeholder={placeholder}
                      inputProps={{
                        style:{
                          textAlign:'right'
                        } ,
                        'aria-label': 'search users'
                      }}
                      sx={{
                        overflow:'hidden',
                        textOverflow:'ellipsis',
                        ml:3,
                        flex:1,
                        alignItems:'center',
                        justifyContent:'flex-end'
                      }}
                      value={searchBy}
                      onChange={(e) => {
                        setSearchBy(e.target.value);
                      }}
                    />
                  </Collapse>
                  {
                    searchBy?.length
                      ?
                        isSearching
                          ?
                            <CircularProgress size={20} style={{padding:8}} />
                          :
                            <IconButton
                              id="close-button"
                              onClick={clearSearch}
                              style={{
                                color:'#888',
                                // opacity: sortOpen ? .5 : 1,
                                padding:8
                              }}  
                            >
                              <CloseOutlineSVG height={20} color1={theme.palette.primary.main} />
                            </IconButton>
                      :
                        <CustomTooltip
                          title="Search contacts"
                        >
                          <IconButton
                            id="search-button"
                            onClick={handleSearchClick}
                            style={{
                              color:'#888',
                              // opacity: sortOpen ? .5 : 1,
                              padding:8,
                            }}  
                          >
                            <SearchSVG height={20} color1={theme.palette.primary.main} />
                          </IconButton>
                        </CustomTooltip>
                  }
                </Grid>
            </Box>
          }
          
          {
            // useSort?.length &&
            //   <Box sx={{display:'flex', alignItems:'center'}}>
            //     <Collapse orientation="horizontal" in={sortDisplay}>
            //       <Typography sx={{whiteSpace:'nowrap'}}>{sortBy}</Typography>
            //     </Collapse>
            //     <IconButton
            //       id="sort-button"
            //       onClick={handleSortClick}
            //       aria-controls={sortOpen ? 'basic-menu' : undefined}
            //       aria-haspopup="true"
            //       aria-expanded={sortOpen ? 'true' : undefined}
            //       sx={{
            //         color:'#888',
            //         opacity: sortOpen ? .5 : 1,
            //         borderRadius:2
            //       }}  
            //     >
            //       <FilterSVG sx={{fontSize:42}} />
            //     </IconButton>
            //     <Menu
            //       id="sort-menu"
            //       anchorEl={anchorEl}
            //       open={sortOpen}
            //       onClose={handleSortClose}
            //       anchorOrigin={{
            //         vertical: 'bottom',
            //         horizontal: 'center',
            //       }}
            //       transformOrigin={{
            //         vertical: 'top',
            //         horizontal: 'center',
            //       }}    
            //       MenuListProps={{
            //         'aria-labelledby': 'filter-button',
            //       }}
            //     >
            //       {useSort?.map((item) => (
            //         <MenuItem onClick={() => handleSort(item.title)}>{item.title}</MenuItem>
            //       ))}
            //       {/* {useSort?.includes('oldest') && <MenuItem onClick={() => handleSort('Oldest')}>Oldest</MenuItem>}
            //       {useSort?.includes('a-z') && <MenuItem onClick={() => handleSort('A-Z')}>Oldest</MenuItem>}
            //       {useSort?.includes('z-a') && <MenuItem onClick={() => handleSort('Z-A')}>Oldest</MenuItem>} */}
                  
                  
            //     </Menu>
            //   </Box>
          }

        </Box>

    </div>
  )
}

export default ReusableContactSearch