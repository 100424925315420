import React, { useEffect, useState } from 'react'
import { Grid, Button, Divider, Typography, Slider, Stack, Switch, Collapse, InputAdornment, TableRow, TableCell, Table } from '@mui/material'
import { theme } from '../../theme'
import SignupPriceTable from './SignupPriceTable'
import { useScreenSize } from '../../lib/Interface';
import { postPayment } from '../../hooks/mutations'
import { initProduct } from '../../lib/Data'
import { useTheme } from '@emotion/react';
import { NumberField } from '../ui/Fields';
import { TeamSVG } from '../../assets/icons';

export function valueLabelFormat(value) {
  const units = ['K', 'M'];

  let unitIndex = 0;
  let emailNumber = value * 10;
  let scaledValue = emailNumber;
  
  if (value > 99) {
    unitIndex = 1;
    scaledValue = emailNumber/100;
  }

  return `${scaledValue} ${units[unitIndex]}`;
  // return <div style={{padding:'8px', borderRadius:16, background:theme.palette.primary.main}}>{`${scaledValue} ${units[unitIndex]}`}</div>
}

const markSteps = [
  {
    // label: '0',
    value: 0
  },
  {
    // label: '1',
    value: 1
  },
  {
    // label: '2',
    value: 2
  },
  {
    // label: '3',
    value: 3
  },
  {
    // label: '4',
    value: 4
  },
  {
    // label: '5',
    value: 5
  },
  {
    // label: '6',
    value: 6
  },
  {
    // label: '7',
    value: 7
  },
  {
    // label: '8',
    value: 8
  },
  {
    // label: '9',
    value: 9
  },
  {
    // label: '10',
    value: 10
  },
  {
    // label: '15',
    value: 15
  },
]

const product_data = {
  subscription_monthly: {
    id: 1,
    price: 38,
    name: 'Subscription (monthly)',
    term: 'month'
  },
  subscription_annual: {
    id: 2,
    price: 380,
    name: 'Subscription (annual)',
    term: 'year'
  },
  ip_monthly_addon: {
    id: 3,
    price: 98,
    name: 'Dedicated IP (monthly)',
    term: 'month'
  },
  ip_annual_addon: {
    id: 4,
    price: 1176,
    name: 'Dedicated IP (annual)',
    term: 'year'
  },
  email_monthly_addon: {
    id: 5,
    price: null,
    name: 'Additional Emails (monthly)',
    term: 'month'
  },
  email_annual_addon: {
    id: 6,
    price: null,
    name: 'Additional Emails (annual)',
    term: 'year'
  },
  member_monthly_addon: {
    id: 7,
    price: 12,
    name: 'Members (monthly)',
    term: 'month'
  },
  member_annual_addon: {
    id: 8,
    price: 108,
    name: 'Members (annual)',
    term: 'year'
  },
}

const Step4Subscribe = () => {
  const [priceModel, setPriceModel] = useState('monthly');
  const [emailUnits, setEmailUnits] = useState(0);
  const [memberUnits, setMemberUnits] = useState(0);
  const [dedicatedIp, setDedicatedIp] = useState(false);
  const [additionalMembers, setAdditionalMembers] = useState(false);
  const [additionalEmails, setAdditionalEmails] = useState(false);
  const [isTieredEmailPricing, setisTieredEmailPricing] = useState(true);
  const [product, setProduct] = useState(initProduct);
  const [emailCost, setEmailCost] = useState(0);
  const [memberCost, setMemberCost] = useState(0);
  const [ipCost, setIpCost] = useState(0);
  const [total, setTotal] = useState(0);

  const theme = useTheme();
  const screenSize = useScreenSize();

  console.log('product', product);
  console.log('memberUnits', memberUnits);

  const subscribeTable = {
    title:'Basic',
    id:'basic',
    priceMon:'38',
    priceAnn:'29',
    priceMonId: 1,
    priceAnnId: 2,
    term:'month',
    description:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    checkout:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  }

  useEffect(() => {
    getTotalMonthlyPrice();
  }, [priceModel, emailUnits, memberUnits, dedicatedIp, additionalEmails])
  
  const getTotalMonthlyPrice = () => {
    
    let emailUnitCost = 0;

    if (isTieredEmailPricing) {
      if (priceModel === "monthly") {
        emailUnitCost = emailUnits * 18;
        if (emailUnits > 4) {
          emailUnitCost = emailUnits * 14;
        } 
        if (emailUnits > 9) {
          emailUnitCost = emailUnits * 12;
        } 
        if (emailUnits > 19) {
          emailUnitCost = emailUnits * 10;
        }

      } else {
        emailUnitCost = emailUnits * (18 * 12);
        if (emailUnits > 4) {
          emailUnitCost = emailUnits * (14 * 12);
        } 
        if (emailUnits > 9) {
          emailUnitCost = emailUnits * (12 * 12);
        } 
        if (emailUnits > 19) {
          emailUnitCost = emailUnits * (10 * 12);
        }
      }
  
    } else {
      emailUnitCost = additionalEmails ? emailUnits * 10 : 0;
    }

    console.log('email units', emailUnits);
    console.log('emailUnitCost', emailUnitCost);

    // const baseCost = priceModel === "monthly" ? subscribeTable.priceMon : subscribeTable.priceAnn;
    const baseCost = priceModel === "monthly" ? product_data.subscription_monthly.price : product_data.subscription_annual.price;
    const ipUnitCost = dedicatedIp ? (priceModel === "monthly" ? product_data.ip_monthly_addon.price : product_data.ip_annual_addon.price) : 0;
    const memberUnitCost = (priceModel === "monthly" ? product_data.member_monthly_addon.price * memberUnits : product_data.member_annual_addon.price * memberUnits);
    const total = Number(baseCost) + Number(emailUnitCost) + Number(ipUnitCost) + Number(memberUnitCost);
    
    setMemberCost(memberUnitCost);
    setIpCost(ipUnitCost);
    setEmailCost(emailUnitCost);
    setTotal(total);
  }

  const handleEmailUnitsChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setEmailUnits(newValue);
    }
  }

  const handleMemberUnitsChange = (value) => {
    if (value > -1) {
      setMemberUnits(Number(value));
    }
  }

  const handleIpChange = () => {
    if (dedicatedIp) {
      setDedicatedIp(false);
    } else {
      setDedicatedIp(true);
    }
  }

  const handleAdditionalEmailChange = () => {
    if (additionalEmails) {
      setAdditionalEmails(false);
      setEmailUnits(0);
    } else {
      setAdditionalEmails(true);
    }
  }

  const handleMemberChange = () => {
    if (additionalMembers) {
      setAdditionalMembers(false);
      setMemberUnits(0);
    } else {
      setAdditionalMembers(true);
    }
  }

  const submitPayment = async () => {
    const ip_monthly_addon = {
      id: product_data.ip_monthly_addon.id,
      amount: 1
    }
    const ip_annual_addon = {
      id: product_data.ip_annual_addon.id,
      amount: 1
    }
    const email_monthly_addon = {
      id: product_data.email_monthly_addon.id,
      amount: emailUnits
    }
    const email_annual_addon = {
      id: product_data.email_annual_addon.id,
      amount: emailUnits
    }
    const member_monthly_addon = {
      id: product_data.member_monthly_addon.id,
      amount: memberUnits
    }
    const member_annual_addon = {
      id: product_data.member_annual_addon.id,
      amount: memberUnits
    }

    let addonItems = [];

    // Add the "monthly" addon products
    if (priceModel === "monthly") {
      if (dedicatedIp) {
        addonItems.push(ip_monthly_addon);
      }
      if (additionalEmails && emailUnits > 0) {
        addonItems.push(email_monthly_addon);
      }
      if (additionalMembers && memberUnits > 0) {
        addonItems.push(member_monthly_addon);
      }
    }
    
    // Add the "annual" addon products
    else {
      if (dedicatedIp) {
        addonItems.push(ip_annual_addon);
      }
      if (additionalEmails && emailUnits > 0) {
        addonItems.push(email_annual_addon);
      }
      if (additionalMembers && memberUnits > 0) {
        addonItems.push(member_annual_addon);
      }
    }
    

    console.log('addons', addonItems);

    const productObj = {
      productId: priceModel === 'monthly'
        ? 1 // id for monthly stripe subscription
        : 2, // id for annual stripe subscription
      productPayload: {
        addons: addonItems
      }
    }
    const submittedPayment = await postPayment(productObj);
    console.log('submittedPayment', submittedPayment);
    if (submittedPayment.status === "success") {
      console.log('successful payment submission', submittedPayment.data.url);
      window.location.href = submittedPayment.data.url;
    } else if (submittedPayment.status === 'error') {
      console.log('error submitting payment');
    }
  }

  return (
    <div>
      {/* <Pricing /> */}
      <Grid
        id="top"
        variant="center"
        style={{
          // minHeight: 'calc(100vh - 80px)'
          marginTop:40
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 1400,
            zIndex: 1
          }}
        >
          <Grid
            container
            display="column"
            style={{
              // background: `${theme.palette.background.main}aa`,
              borderRadius: 16,
              // boxShadow: '0 0 10px #000000030',
              justifyContent:'center',
              paddingBottom:16
            }}
          >
            {/* <Grid
              container
              style={{
                padding: 16,
                // marginTop: 100,
                justifyContent: 'center'
              }}
            >
              <Grid
                variant="center"
                flexDirection="column"
              >

                <Typography variant="h4">
                  <span style={{fontWeight:700}}>MORE BANG!</span> Less Buck.
                </Typography>
                <Typography>
                  One price for all our core features. It's that simple. We promise.
                </Typography>
              </Grid>
            </Grid> */}

            <Grid
              container
              style={{
                padding:24,
                paddingBottom:42,
                borderRadius:30,
                maxWidth:700,
                boxShadow:'0 0 10px #00000030',
                background:'#ffffffaa'
              }}
            >
              <Grid
                variant="center"
                style={{
                  flex:'display',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems:'center'
                }}
                xs={12}
              >
                <Grid
                  variant="center"
                  onClick={() => {
                    if (priceModel === 'annual') {
                      setPriceModel('monthly')
                    } else {
                      setPriceModel('annual')
                    }
                  }}
                  style={{
                    outline: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 32,
                    background: `${theme.palette.background.main}cc`,
                    padding: 4,
                    margin: '16px 0'
                  }}
                >
                  <Grid
                    style={{
                      padding: '4px 16px',
                      background: priceModel === 'monthly' ? theme.palette.primary.main : 'transparent',
                      transition: '.4s',
                      borderRadius: 32,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: priceModel === 'monthly' ? '#ffffff' : '#777777',
                      }}
                    >
                      Monthly
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: '4px 16px',
                      background: priceModel === 'annual' ? theme.palette.primary.dark : 'transparent',
                      transition: '.4s',
                      borderRadius: 32,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: priceModel === 'annual' ? '#ffffff' : '#777777',
                      }}
                    >
                      Annual
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  variant="space-between"
                  flexDirection="column"
                  style={{
                    background:'#fff',
                    borderRadius:'16px',
                    padding: screenSize.xs ? 8 : 8,
                    // boxShadow:'5px 5px 5px #00000010',
                    height:'100%',
                    width:'100%'
                  }}
                >
                  <Grid
                    variant="center"
                    flexDirection="column"
                  >
                    <Grid
                      variant="center"
                      flexDirection="column"
                      mb={4}
                    >
                      <Grid
                        container
                        direction="column"
                        columnGap={1}
                      >
                        <Grid
                          sx={{
                            textAlign:'center'
                          }}
                        >
                          <Typography
                            heading="h1"
                            style={{
                              display:'flex',
                              justifyContent:'center',
                              alignItems:'flex-start',
                              fontSize:72,
                              lineHeight:1,
                              color:theme.palette.primary.main,
                              padding:0,
                              margin:0,
                              marginTop:8,
                              marginLeft:-24
                            }}
                          >
                            <span style={{fontSize:48}}>$</span>
                            {priceModel === 'monthly' ? product_data.subscription_monthly.price : product_data.subscription_annual.price}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{
                            display:'flex',
                            // flexDirection:'column',
                            justifyContent:'center',
                            // alignItems:'flex-start',
                            marginBottom:6
                          }}
                        >
                          <Typography style={{lineHeight:1}}>
                            /{priceModel === 'monthly' ? product_data.subscription_monthly.term : product_data.subscription_annual.term}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography
                        style={{
                          color:'#aaa'
                        }}
                      >
                        Includes 500 free emails to send per month
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

              <Divider style={{width:'100%', marginBottom:32}} />

              <Grid
                container
                rowGap={2}
              >
                <Grid
                  item
                  // variant="center"
                  style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    flexDirection:'column',
                    boxShadow:'0 0 5px #00000030',
                    background:'#ffffff',
                    borderRadius:16,
                    padding:16,
                    outline: additionalEmails ? `4px solid ${theme.palette.primary.main}` : 'none'
                  }}
                  xs={12}
                  // sm
                >
                  <Grid
                    container
                    variant="center"
                  >
                    <Typography
                      style={{
                        fontSize:'1.3rem',
                        textAlign:'center',
                        lineHeight:1.2
                      }}
                    >
                      Additional Emails
                    </Typography>

                    <AddonPriceDisplay
                      price={
                        isTieredEmailPricing
                          ?
                            emailUnits == 0
                              ? (priceModel === 'monthly' ? '10-18' : '110–198')
                              : emailUnits < 5
                                ? priceModel === 'monthly' ? 18 : 18*11
                                : emailUnits < 10
                                  ? priceModel === 'monthly' ? 14 : 14*11
                                  : emailUnits < 20
                                    ? priceModel === 'monthly' ? 12 : 12*11
                                    : priceModel === 'monthly' ? 10 : 10*11
                          : 10
                      }
                      term={priceModel === 'monthly' ? "10k/month" : "10k/year"}
                    />
                    <Collapse in={additionalEmails} style={{}}>
                      <Grid
                        style={{
                          display:'flex',
                          width:"100%",
                          flexDirection:'column',
                          alignItems:'center',
                          justifyContent:'center'
                        }}
                      >
                        <Grid
                          style={{
                            width:"90%"
                          }}
                        >
                          <Slider
                            aria-label="Temperature"
                            defaultValue={30}
                            value={emailUnits}
                            onChange={handleEmailUnitsChange}
                            getAriaValueText={valueLabelFormat}
                            valueLabelFormat={valueLabelFormat}
                            valueLabelDisplay="auto"
                            sx={{
                              height:14,
                              '& .MuiSlider-thumb': {
                                  height: 24,
                                  width: 24,
                                  backgroundColor: '#fff',
                                  border: '2px solid currentColor',
                                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                    boxShadow: 'inherit',
                                  },
                                  '&::before': {
                                    display: 'none',
                                  },
                              },
                              '& .MuiSlider-valueLabel': {
                                borderRadius: '10px',
                                backgroundColor: theme.palette.primary.main,
                              },
                            }}
                            shiftStep={30}
                            step={1}
                            marks
                            min={0}
                            max={30}
                          />
                        </Grid>
                        <Grid
                          style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center'
                          }}
                        >
                          <Typography
                            style={{
                              width:'100%', 
                              textAlign:'center',
                              fontSize:'1.2rem',
                              fontWeight:700
                            }}
                          >
                            {Number(emailUnits * 10000).toLocaleString('en-US')}
                          </Typography>
                          <Typography
                            style={{
                              width:'100%', 
                              textAlign:'center'
                            }}
                          >
                            additional emails per month selected 
                          </Typography>
                        </Grid>

                      </Grid>
                    </Collapse>

                  </Grid>

                  <Grid container variant="center">
                    <Grid item style={{display:'flex', justifyContent:'flex-end'}} xs>
                      {
                        additionalEmails
                          ? <Typography style={{color:'#aaa'}}>off</Typography>
                          : <Typography>off</Typography>
                      }
                    </Grid>
                    <Switch
                      checked={additionalEmails}
                      onChange={handleAdditionalEmailChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Grid item xs>
                      {
                        additionalEmails
                          ? <Typography color="primary.dark" style={{fontWeight:500}}>Selected</Typography>
                          : <Typography>On</Typography>
                      }
                    </Grid>
                  </Grid>

                </Grid>

                <Grid
                  item
                  style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    flexDirection:'column',
                    boxShadow:'0 0 5px #00000030',
                    background:'#ffffff',
                    borderRadius:16,
                    padding:16,
                    outline: additionalMembers ? `4px solid ${theme.palette.primary.main}` : 'none'
                  }}
                  xs={12}
                  // sm
                >
                  <Grid
                    container
                    variant="center"
                  >
                    <Typography
                      style={{
                        fontSize:'1.3rem',
                        textAlign:'center',
                        lineHeight:1.2
                      }}
                    >
                      Members
                    </Typography>
                    <AddonPriceDisplay
                      price={priceModel === 'monthly' ? product_data.member_monthly_addon.price : product_data.member_annual_addon.price}
                      term={priceModel === 'monthly' ? 'member/month' : 'member/year'}
                    />
                    {/* <Typography
                      style={{
                        margin:'16px 0'
                      }}
                    >
                      Add additional members to your subscription.
                    </Typography> */}
                  </Grid>
                  <Collapse in={additionalMembers} style={{width:'100%'}}>
                      <Grid
                        style={{
                          display:'flex',
                          width:"100%",
                          flexDirection:'column',
                          alignItems:'center',
                          justifyContent:'center',
                          margin:'16px 0'
                        }}
                      >
                          <NumberField
                            width={120}
                            value={memberUnits || 0}
                            onChange={(e) => handleMemberUnitsChange(e.target.value)}
                            adornment={{startAdornment:
                                <InputAdornment position="start">
                                  <TeamSVG width={26} />
                                </InputAdornment>
                            }}
                            inputProps={{
                              style:{
                                padding:0,
                                fontSize:'1.3rem',
                                fontWeight:600,
                                textAlign:'center'
                              }
                            }}
                    
                          />
                          {
                            // [1,2,3,'other'].map(num => (
                            //   <Grid
                            //     item
                            //     style={{
                            //       padding:8
                            //     }}
                            //     xs
                            //   >
                            //     <Grid
                            //       container
                            //       variant="center"
                            //       style={{
                            //         borderRadius:8,
                            //         boxShadow:'0 0 5px #00000020'
                            //       }}
                            //     >
                            //       <Typography>{num}</Typography>
                            //     </Grid>
                            //   </Grid>
                            // ))
                          }
                        <Grid
                          style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center'
                          }}
                        >
                          {/* <Typography
                            style={{
                              width:'100%', 
                              textAlign:'center',
                              fontSize:'1.2rem',
                              fontWeight:700
                            }}
                          >
                            {Number(emailUnits * 10000).toLocaleString('en-US')}
                          </Typography> */}
                          <Typography
                            style={{
                              width:'100%', 
                              textAlign:'center'
                            }}
                          >
                            additional members 
                          </Typography>
                        </Grid>

                      </Grid>
                    </Collapse>

                  <Grid container variant="center">
                    <Grid item style={{display:'flex', justifyContent:'flex-end'}} xs>
                      {
                        additionalMembers
                          ? <Typography style={{color:'#aaa'}}>off</Typography>
                          : <Typography>off</Typography>
                      }
                    </Grid>
                    <Switch
                      checked={additionalMembers}
                      onChange={handleMemberChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Grid item xs>
                      {
                        additionalMembers
                          ? <Typography color="primary.dark" style={{fontWeight:500}}>Selected</Typography>
                          : <Typography>On</Typography>
                      }
                    </Grid>
                  </Grid>

                </Grid>

                <Grid
                  item
                  style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    flexDirection:'column',
                    boxShadow:'0 0 5px #00000030',
                    background:'#ffffff',
                    borderRadius:16,
                    padding:16,
                    outline: dedicatedIp ? `4px solid ${theme.palette.primary.main}` : 'none'
                  }}
                  xs={12}
                  // sm
                >
                  <Grid
                    container
                    variant="center"
                  >
                    <Typography
                      style={{
                        fontSize:'1.3rem',
                        textAlign:'center',
                        lineHeight:1.2
                      }}
                    >
                      Dedicated IP
                    </Typography>
                    <AddonPriceDisplay
                      price={priceModel === 'monthly' ? product_data.ip_monthly_addon.price : product_data.ip_annual_addon.price}
                      term={priceModel === 'monthly' ? product_data.ip_monthly_addon.term : product_data.ip_annual_addon.term}
                    />
                    <Typography
                      style={{
                        margin:'16px 0',
                        maxWidth:500
                      }}
                    >
                      Send your emails from a dedicated IP to improve domain reputation and get better open and click-through rates.
                    </Typography>
                  </Grid>

                  <Grid container variant="center">
                    <Grid item style={{display:'flex', justifyContent:'flex-end'}} xs>
                      {
                        dedicatedIp
                          ? <Typography style={{color:'#aaa'}}>off</Typography>
                          : <Typography>off</Typography>
                      }
                    </Grid>
                    <Switch
                      checked={dedicatedIp}
                      onChange={handleIpChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Grid item xs>
                      {
                        dedicatedIp
                          ? <Typography color="primary.dark" style={{fontWeight:500}}>Selected</Typography>
                          : <Typography>On</Typography>
                      }
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>

              <Divider style={{width:'100%', margin:'32px 0'}} />

              <Grid
                container
                variant="center"
                style={{
                  display:'flex',
                  flexDirection:'column',
                }}
              >
                {/* <Grid
                  container
                  style={{
                    gap:8
                  }}
                >
                  <Grid
                    item
                    style={{
                      display:'flex',
                      justifyContent:'flex-end'
                    }}
                    xs
                  >
                    <Typography
                      style={{
                        fontSize:'1.3rem'                        
                      }}
                    >
                      Total: 
                    </Typography>                    
                  </Grid>
                  <Grid
                    item
                    style={{
                      display:'flex',
                    }}
                    xs
                  >
                    <Typography
                      style={{
                        fontSize:'1.3rem'                        
                      }}
                    >
                      ${total.toLocaleString()}
                    </Typography>                    
                  </Grid>
                </Grid> */}
                <Table>
                  {
                    <TableRow>
                      <TableCell>
                        <Typography>
                          Subscription
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography>
                          ${priceModel === "monthly" ? product_data.subscription_monthly.price.toLocaleString() : product_data.subscription_annual.price.toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }

                  {
                    additionalEmails && emailUnits > 0
                      ?
                        <TableRow>
                          <TableCell>
                            <Typography>
                              Additional Emails
                            </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <Typography>
                              ${emailCost.toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      : null
                  }

                  {
                    additionalMembers && memberUnits > 0
                      ?
                        <TableRow>
                          <TableCell>
                            <Typography>
                              Additional Members
                            </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <Typography>
                              ${memberCost.toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      : null
                  }

                  {
                    dedicatedIp
                      ?
                        <TableRow>
                          <TableCell>
                            <Typography>
                              Dedicated IP
                            </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            ${ipCost.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      : null
                  }

                  <TableRow
                  >
                    <TableCell>
                      <Typography
                        style={{
                          // fontSize:'1.3rem'                        
                        }}
                      >
                        Total: 
                      </Typography>                    
                    </TableCell>
                    <TableCell align='right'>
                      <Typography
                        style={{
                          // fontSize:'1.3rem'                        
                        }}
                      >
                        ${total.toLocaleString()}
                      </Typography>                    
                    </TableCell>
                  </TableRow>
                </Table>
                <Typography
                  style={{
                    gap:8,
                    marginBottom:16
                  }}
                >
                  {`+ tax (if applicable)`} 
                </Typography>                    
                <Button
                  onClick={submitPayment}
                  // href="https://buy.stripe.com/test_14kdRtaQqfLD5dm4gg"
                  target="_blank"
                  variant="contained"
                >
                  CHECK OUT
                </Button>
              </Grid>

              {/* <Grid
                variant="center"
                style={{
                  flex:'display',
                  flexDirection:'column',
                  justifyContent:'space-between',
                  alignItems:'center'
                }}
                xs={12}
                sm={6}
              >
                <Grid
                  style={{
                    display:'flex',
                    width:"100%",
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center'
                  }}
                >
                  <Typography
                    style={{
                      width:'100%', 
                      textAlign:'center'
                    }}
                  >
                    You can send 5k emails for free every month.
                  </Typography>
                  <Grid
                    style={{
                      width:"90%"
                    }}
                  >
                    <Slider
                      aria-label="Temperature"
                      defaultValue={30}
                      value={emailUnits}
                      onChange={handleEmailUnitsChange}
                      getAriaValueText={valueLabelFormat}
                      valueLabelFormat={valueLabelFormat}
                      valueLabelDisplay="auto"
                      sx={{
                        height:14,
                        '& .MuiSlider-thumb': {
                            height: 24,
                            width: 24,
                            backgroundColor: '#fff',
                            border: '2px solid currentColor',
                            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                              boxShadow: 'inherit',
                            },
                            '&::before': {
                              display: 'none',
                            },
                        },
                        '& .MuiSlider-valueLabel': {
                          // lineHeight: 1.2,
                          // fontSize: 12,
                          // background: 'unset',
                          // padding: .75,
                          // width: 16,
                          // height: 16,
                          borderRadius: '10px',
                          backgroundColor: theme.palette.primary.main,
                          // transformOrigin: 'bottom left',
                          // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                          // '&::before': { display: 'none' },
                          // '&.MuiSlider-valueLabelOpen': {
                          //   transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                          // },
                          // '& > *': {
                          //   transform: 'rotate(45deg)',
                          // },
                        },
                      }}
                      shiftStep={30}
                      step={1}
                      marks
                      min={0}
                      max={30}
                    />
                  </Grid>
                  <Grid
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      alignItems:'center'
                    }}
                  >
                    <Typography
                      style={{
                        width:'100%', 
                        textAlign:'center',
                        fontSize:'1.2rem',
                        fontWeight:700
                      }}
                    >
                      {Number(emailUnits * 10000).toLocaleString('en-US')}
                    </Typography>
                    <Typography
                      style={{
                        width:'100%', 
                        textAlign:'center'
                      }}
                    >
                      additional emails per month selected 
                    </Typography>
                  </Grid>

                </Grid>
                <Grid
                  style={{
                    display:'flex',
                    alignItems:'center'
                  }}
                >
                  <Typography

                  >
                    Total: 
                  </Typography>
                  <Button
                    onClick={submitPayment}
                    // href="https://buy.stripe.com/test_14kdRtaQqfLD5dm4gg"
                    target="_blank"
                    variant="contained"
                  >
                    SUBSCRIBE
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Step4Subscribe

export const AddonPriceDisplay = (props) => {
  const { price, term } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      columnGap={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Grid
        style={{
          textAlign:'center'
        }}
      >
        <Typography
          heading="h1"
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-start',
            fontSize:32,
            lineHeight:1,
            color:theme.palette.primary.main,
            padding:0,
            margin:0,
            marginTop:8,
            // marginLeft:-24
          }}
        >
          <span style={{fontSize:20}}>$</span>
          {price}
        </Typography>
      </Grid>
      <Grid
        style={{
          display:'flex',
          // flexDirection:'column',
          justifyContent:'center',
          // alignItems:'flex-start',
          marginBottom:6
        }}
      >
        {
          term &&
            <Typography style={{lineHeight:1}}>
              /{term}
            </Typography>
        }
      </Grid>
    </Grid>

  )
}