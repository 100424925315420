import React from 'react';
import { useTheme } from "@emotion/react";

export const Angle = (props) => {
  let { width, height, color1, color2, style } = props;
  const theme = useTheme();
  color1 = color1 ? color1 : theme.palette.primary.main
  color2 = color2 ? color2 : theme.palette.secondary.main

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', ...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width={width}
        height={height}
        preserveAspectRatio="none"
      >
        {/* <title>email</title> */}
        <polygon fill={color1} points="0,0 100,0 0,100" />
      </svg>
    </div>
  )
}

export const Header1 = (props) => {
  let { width, height, color1, style } = props;
  const theme = useTheme();
  color1 = color1 ? color1 : theme.palette.primary.main

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', ...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 99 72"
        width={width}
        height={height}
        preserveAspectRatio="none"
      >
        <path fill={color1} d="M99,36C63,36,72,72,27,72H0V0H99Z"/>
      </svg>
    </div>
  )
}

export const Header2 = (props) => {
  let { width, height, color1, style } = props;
  const theme = useTheme();
  color1 = color1 ? color1 : theme.palette.primary.main

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', ...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 99 36"
        width={width}
        height={height}
        preserveAspectRatio="none"
      >
        <path fill={color1} d="M0,0V36H27C72,36,63,0,99,0Z"/>
      </svg>
    </div>
  )
}

export const DotPattern = (props) => {
  const { dotCount = 10, dotRadius = 5, offset = false, color="#27D8D3" } = props;
  const parentRef = React.useRef(null);
  const [parentDimensions, setParentDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        const { width, height } = parentRef.current.getBoundingClientRect();
        setParentDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dotSpacing = parentDimensions.width / dotCount;
  const rows = Math.floor(parentDimensions.height / dotSpacing) + 1;
  const dots = [];

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < dotCount; col++) {
      const cx = col * dotSpacing + (offset && row % 2 === 1 ? dotSpacing / 2 : 0);
      const cy = row * dotSpacing;
      dots.push(<circle key={`${row}-${col}`} cx={cx} cy={cy} r={dotRadius} fill={color} />);
    }
  }

  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
      <svg width="100%" height="100%">
        {dots}
      </svg>
    </div>
  );
};

export const Parallelogram = (props) => {
  const { color="black", width="100%", height="100%", fill=40 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox='0 0 100 100'
      preserveAspectRatio="none"
    >
      <path fill={color} d={`M 100,0 L 100,${fill} L 0,100 L 0,${100 - fill} Z`} />
    </svg>
  )
}
