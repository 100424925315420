import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import CRM from '../components/crm/CRM'

const CRMPage = () => {
  return (
    // <MainPageTemplate title="CRM">
      <CRM />
    // </MainPageTemplate>
  )
}

export default CRMPage