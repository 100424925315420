import { useTheme } from '@emotion/react'
import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { getScreenSize, useScreenSize, useScreenSizeCalc } from '../../lib/Interface'
import { LogoHorizontal } from '../../assets/logos'
import { ScrollToGrow } from '../ui/ScrollAnimations'
import { Angle, DotPattern, Parallelogram } from '../../assets/graphics'
import MainNav from '../nav/MainNav'
import { featureList } from '../../lib/Data'
import Pricing from '../pricing/Pricing'
import { hashScroll } from '../../lib/Functions'
import LandingFeatures from './LandingFeatures'
import { landingCopy } from '../../lib/Copy'
import { Animated3Dots, AnimatedDotGrid } from '../../assets/animations'

const LandingHome = () => {
  const theme = useTheme();
  useLayoutEffect(() => {
    console.log('window', window.location.hash)
    if (window.location.hash.includes('#why-us')) {
      hashScroll('why-us');
    }
  })
  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary,
        overflow:'clip',
        paddingBottom:96
      }}
    >
      {/* <MainNav /> */}
      <Header />
      <General />
      <LandingFeatures />
    </Grid>
  )
}

export default LandingHome

const OldHeader = () => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Grid
      container
      style={{
        position:'relative',
        height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
        justifyContent:'center',
        background: `linear-gradient(to bottom, #000000 0%, #00000000 60%), ${process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? 'url(static/img/CharactersInSpace002.jpg) 50% 50%/cover' : 'url(https://static.fillmarketing.com/static/static/img/CharactersInSpace002.jpg) 50% 50%/cover'}`, //`linear-gradient(#00000030 #00000030), url('static/img/CharactersInSpace001.jpg')`,
        // backgroundImage: process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `url(static/img/CharactersInSpace002.jpg)` : `url(https://static.fillmarketing.com/static/static/img/CharactersInSpace002.jpg)`,
        // backgroundSize:'cover',
        // backgroundPosition:'50% 50%',
        overflow:'hidden'
      }}
    >
      {/* <Grid
        style={{
          position:'absolute',
          // top:60,
          right:0,
          height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
          background:'blue',
          background:'url(https://images.unsplash.com/photo-1554902843-260acd0993f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
          backgroundPosition:'center',
          width:'50vw',
          overflow:'hidden'
        }}
      >
        <Angle width={"100%"} height="100%" color1={theme.palette.primary.dark} style={{height:'100%', width:'100%'}} />
      </Grid> */}

      <Grid
        container
        sx={{
          position:'relative',
          alignItems:'center',
          height:'70%',
          width:{xs:250, sm:500, md:800, xl:1000}
        }}
      >
        <Typography
          sx={{
            fontSize: {xs: '1.75rem', sm:'2.5rem', md:'3rem', lg:'3rem', xl:'4rem'},
            transition:'.4s',
            fontWeight:700,
            lineHeight:1,
            textTransform:'uppercase',
            color:theme.palette.alt.light, //'#fff'
            // textShadow:`
            //   5px 5px ${theme.palette.primary.dark},
            //   0px 5px ${theme.palette.primary.dark},
            //   5px 0px ${theme.palette.primary.dark},
            //   -5px -5px ${theme.palette.primary.dark},
            //   0px -5px ${theme.palette.primary.dark},
            //   -5px 0px ${theme.palette.primary.dark},
            //   -5px 5px ${theme.palette.primary.dark},
            //   5px -5px ${theme.palette.primary.dark}
            // `,
            zIndex:2
          }}
        >
          Built for <br></br>small companies <br></br>with large reach. 
        </Typography>
      </Grid>
    </Grid>
  )
}

const Header = () => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  const [svgPaths, setSvgPaths] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      // Use requestAnimationFrame to ensure DOM updates are synchronized with the next paint
      requestAnimationFrame(() => connectDivs(landingCopy.header_card, landingCopy.header_card.title));
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial connection of divs
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [landingCopy.header_card]);

  const connectDivs = (items) => {
    const paths = [];
    const headerConnectorDiv = document.getElementById('header-connector');
    
    if (!headerConnectorDiv) {
      console.error('Header connector div not found');
      return;
    }
    
    const noCurveRange = 10; // Adjust this value as needed
    const blendCurveRange = 30;
    
    for (let i = 0; i < items.length; i++) {
      const topDiv = document.getElementById(`${i}-top`);
    
      if (topDiv && headerConnectorDiv) {
        const topRect = topDiv.getBoundingClientRect();
        const bottomRect = headerConnectorDiv.getBoundingClientRect();
        const containerRect = document.getElementById('svg-container-header').getBoundingClientRect();
    
        const x1 = bottomRect.left + bottomRect.width / 2 - containerRect.left;
        const y1 = bottomRect.top + bottomRect.height / 2 - containerRect.top;
        const x2 = topRect.left + topRect.width / 2 - containerRect.left;
        const y2 = topRect.top + topRect.height / 2 - containerRect.top;
    
        const curveHeight = Math.abs(y2 - y1) / 2;
        const initControlPointOffset = Math.min(curveHeight, 20); 
        const controlPointOffset = Math.abs(x2 - x1) < blendCurveRange ? initControlPointOffset -15 : initControlPointOffset // Math.min(curveHeight, 20);
    
        let pathData;
        if (Math.abs(x2 - x1) < noCurveRange) {
          // Straight line if within the no curve range
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y2}
          `;
        } else if (x1 < x2) {
          // Case where header connector is on the left and top div is on the right
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 + controlPointOffset} ${y1 + curveHeight}
            L ${x2 - controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        } else {
          // Case where header connector is on the right and top div is on the left
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 - controlPointOffset} ${y1 + curveHeight}
            L ${x2 + controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        }
    
        paths.push(<path d={pathData} stroke={theme.palette.alt.main} fill="transparent" strokeWidth="5" strokeDasharray={0} key={i} />);
      }
    }
    
    setSvgPaths(paths);
  };
  
  return (
    <Grid
      container
      style={{
        position:'relative',
        // height: screenSize.xs ? 400 : ( screenSize.md ? 600 : '70vh'),
        // height:'100%',
        justifyContent:'center',
        alignItems:'flex-start',
        paddingTop:90,
        // background: `linear-gradient(to bottom, #000000 0%, #00000000 60%), ${process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? 'url(static/img/CharactersInSpace002.jpg) 50% 50%/cover' : 'url(https://static.fillmarketing.com/static/static/img/CharactersInSpace002.jpg) 50% 50%/cover'}`, //`linear-gradient(#00000030 #00000030), url('static/img/CharactersInSpace001.jpg')`,
        // overflow:'hidden'
      }}
    >
      <div
        style={{
          background:`${theme.palette.primary.dark}`,
          position:'absolute',
          top:0,
          left:0,
          height:'80%',
          // height:screenSize.xs ? '40vh' : screenSize.sm ? '50vh' : '70vh',
          width:'100%'
        }}
      >
        <DotPattern
          dotCount={screenSize.xs ? 20 : 50}
          dotRadius={2}
          offset
          color={`${theme.palette.alt.main}50`}
        />
      </div>

      <Grid container variant="center">
        <Grid
          container
          sx={{
            position:'relative',
            alignItems:'flex-start',
            justifyContent:'center',
            // height:'70%',
            paddingBottom:24,
            width:{xs:'100%', sm:500, md:800, xl:1000}
          }}
        >
          <svg id={`svg-container-header`} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
            <g id="svg-paths">{svgPaths}</g>
          </svg>
          <Typography
            sx={{
              position:'relative',
              fontSize: {xs: '1.75rem', sm:'2.5rem', md:'3rem', lg:'3rem', xl:'4rem'},
              transition:'.4s',
              fontWeight:700,
              lineHeight:1,
              // marginLeft:4,
              textTransform:'uppercase',
              color:theme.palette.primary.dark, //'#fff'
              background:'#FFF',
              borderRadius: '24px 0 0 24px',
              padding:3,
              // textShadow:`
              //   5px 5px ${theme.palette.primary.dark},
              //   0px 5px ${theme.palette.primary.dark},
              //   5px 0px ${theme.palette.primary.dark},
              //   -5px -5px ${theme.palette.primary.dark},
              //   0px -5px ${theme.palette.primary.dark},
              //   -5px 0px ${theme.palette.primary.dark},
              //   -5px 5px ${theme.palette.primary.dark},
              //   5px -5px ${theme.palette.primary.dark}
              // `,
              zIndex:2
            }}
          >
            <div
              id="header-connector"
              style={{
                position:'absolute',
                bottom:'-5%',
                left:-0,
                background: '#FFFFFF', // theme.palette.primary.main,
                width: 26,
                height: 26,
                borderRadius:26,
                border: '1px solid #eee',
                outline: `6px solid ${theme.palette.alt.light}90`,
                zIndex:0
              }}
            >

            </div>
              
            Built for <br></br><span style={{ color: theme.palette.primary.main }}>small</span> companies <br></br>with <span style={{ color: theme.palette.primary.main }}>large</span> reach<span style={{ fontSize:'4rem', lineHeight:.1, color: theme.palette.secondary.main }}>.</span>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        // gap={3}
        sx={{
          width:{xs:'100%', sm:500, md:800, xl:1000}
        }}
      >
        {
          landingCopy.header_card.map((item, index) => (
            <Grid
              item
              key={item.title}
              sx={{
                display:'flex',
                justifyContent:'center',
                padding:{xs:'0 8px', md:'0 16px'},
                zIndex:10
              }}
              xs={6}
            >
              <Grid
                container
                style={{
                  position:'relative',
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  borderRadius:24,
                  background:'#ffffff',
                  boxShadow:'0 0 5px #00000020'
                }}
              >
                <div
                  id={`${index}-top`}
                  style={{
                    position: 'absolute',
                    top: -13,
                    left: index % 2 ? '75%' : screenSize.sm ? '0%' : '10%',
                    width: 26,
                    height: 26,
                    borderRadius:26,
                    boxShadow:'0 0 5px #00000020',
                    background: '#FFFFFF', // theme.palette.primary.main,
                    border: '1px solid #eee',
                    outline: `6px solid ${theme.palette.alt.light}90`,
                    zIndex:0
                  }}
                />

                <img
                  src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `static/img/${item.icon}` : `https://static.fillmarketing.com/static/static/img/${item.icon}`}
                  style={{
                    height: screenSize.xs ? 100 : (screenSize.sm ? 150 : 200),
                    marginTop: screenSize.xs ? -25 : -50,
                    filter:'drop-shadow(-2px -2px 0 #fff) drop-shadow(2px -2px 0 #fff) drop-shadow(-2px 2px 0 #fff) drop-shadow(2px 2px 0 #fff)',
                    zIndex:1000
                  }}
                />
                <Grid
                  container
                  flexDirection="column"
                  variant="center"
                  sx={{
                    padding:{xs: '0 8px 16px', md: '0 36px 16px'}
                  }}
                >
                  <Typography
                    sx={{
                      fontSize:{xs:'1.3rem', sm:'1.8rem', md:'2rem'},
                      marginTop:{xs:'1rem', md:'.75rem'},
                      marginBottom:'.5rem',
                      textAlign:'center',
                      width:'100%',
                      lineHeight:1,
                      fontFamily:'Poppins'
                    }}
                  >
                    {screenSize.sm ? item.shortTitle : item.title}
                  </Typography>
                  {
                    !screenSize.sm &&
                      <Typography
                        style={{
                          fontSize:'1.1rem',
                          // fontFamily:'Poppins'
                        }}
                      >
                        {item.body}
                      </Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
          ))
        }
      </Grid>

    </Grid>
  )
}

const HeaderCard = (props) => {
  const { item } = props;
  const theme = useTheme();

  return (
    <Grid
      item
      style={{
        display:'flex',
        justifyContent:'center'
      }}
      xs
    >
      <Grid
        container
        style={{
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          borderRadius:24,
          background:'#ffffffaa',
          boxShadow:'0 0 5px #00000020'
        }}
      >
        <img
          src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `static/img/${item.icon}` : `https://static.fillmarketing.com/static/static/img/${item.icon}`}
          style={{
            height:200,
            marginTop:-50
          }}
        />
        <Grid
          container
          variant="center"
          style={{
            // background:`${theme.palette.alt.light}aa`
          }}
        >

          <Typography
            style={{
              fontSize:'2rem',
              fontFamily:'Poppins'
            }}
          >
            {item.title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const General = () => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Grid
      container
      style={{
        position:'relative',
      }}
    >
      <div
        style={{
          position:'absolute',
          top:'-20%',
          left:0,
          height:'140%',
          width:'100%',
          display:'flex',
          alignItems:'center',
          zIndex:0
        }}
      >
        <Parallelogram
          fill={70}
          color={theme.palette.alt.light}
          width="100%"
          height={screenSize.sm ? "50%" : "100%"}
          // height="100%"
        />
      </div>
      <CenterColumn id="general" alignment={{x: 'flex-start'}}>
        <Grid
          container
          style={{
            boxShadow:'0 0 5px #00000020',
            background:'#fff',
            borderRadius:16,
            margin:'72px 0 0 0',
            padding:32,
            zIndex:2
          }}
        >
          <Typography
            sx={{
              fontSize: {xs: '2.5rem', sm:'3rem', md:'4rem', xl:'4.5rem'},
              fontWeight:700,
              lineHeight:1,
              // textTransform:'uppercase',
              color:theme.palette.primary.dark, //'#fff',
              transition:'.4s'
            }}
          >
            The best alternative to HubSpot for small business.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '1.4', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
              fontWeight:600,
              lineHeight:1.2,
              marginTop:'16px',
              transition:'.4s'
            }}
          >
            Most CRM and Marketing platforms focus on large enterprise businesses—and they price it that way. Not us.
          </Typography>
          <Button
            href="/prices/"
            variant="contained"
            style={{
              fontSize:'1.4rem',
              margin:'24px 0 0 0 '
            }}
          >
            See Pricing
          </Button>
        </Grid>
      </CenterColumn>
    </Grid>
  )
}

const WhyUs = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        position:'relative',
      }}
    >

      <CenterColumn id="why-us">
        <Grid container gap={{xs:8, sm:16, lg:18, xl:32}}>

          {
            landingCopy.why_us.map((item, index) => (
              <div
                key={item.title}
                style={{
                  position:'relative'
                }}
              >
                <Grid
                  container
                  style={{
                    position:'relative',
                    boxShadow:'0 0 5px #00000020',
                    background:'#ffffff',
                    padding:'24px 48px',
                    borderRadius:16,
                    opacity:.9,
                    zIndex:100
                  }}
                >
                  <Typography
                    variant="landing_title"
                    marginBottom="16px"
                    // fontFamily="Poppins"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="landing"
                    style={{
                      whiteSpace:'pre-line'
                    }}
                  >
                    {item.body}
                  </Typography>
                  {
                    item.ctaHref && item.cta &&
                    <Grid container marginTop={2}>
                      <CtaButton
                        variant="contained"
                        href={item.ctaHref}
                      >
                        {item.cta}
                      </CtaButton>
                    </Grid>
                  }
                </Grid>
                {
                  item.items &&
                    <SubItems items={item.items} divKey={item.key} />
                }
                <DeterminedGraphic index={index} />
              </div>
            ))
          }
        </Grid>
      </CenterColumn>
    </Grid>
  )
}

const SubItems = ({ items, divKey }) => {
  const theme = useTheme();
  const screenSize = useScreenSize();
  const [svgPaths, setSvgPaths] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      // Use requestAnimationFrame to ensure DOM updates are synchronized with the next paint
      requestAnimationFrame(() => connectDivs(items, divKey));
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial connection of divs
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items]);

  const connectDivs = (items, divKey) => {
    const paths = [];
    for (let i = 0; i < items.length - 1; i++) {
      const topDiv = document.getElementById(`${i + 1}-top-${divKey}`);
      const bottomDiv = document.getElementById(`${i}-bottom-${divKey}`);
  
      if (topDiv && bottomDiv) {
        const topRect = topDiv.getBoundingClientRect();
        const bottomRect = bottomDiv.getBoundingClientRect();
        const containerRect = document.getElementById(`svg-container-${divKey}`).getBoundingClientRect();
  
        const x1 = bottomRect.left + bottomRect.width / 2 - containerRect.left;
        const y1 = bottomRect.top + bottomRect.height / 2 - containerRect.top;
        const x2 = topRect.left + topRect.width / 2 - containerRect.left;
        const y2 = topRect.top + topRect.height / 2 - containerRect.top;
  
        const curveHeight = Math.abs(y2 - y1) / 2;
        const controlPointOffset = Math.min(curveHeight, 50);
  
        let pathData;
        if (x1 < x2) {
          // Case where bottom div is on the left and top div is on the right
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 + controlPointOffset} ${y1 + curveHeight}
            L ${x2 - controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        } else {
          // Case where bottom div is on the right and top div is on the left
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 - controlPointOffset} ${y1 + curveHeight}
            L ${x2 + controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        }
  
        paths.push(<path d={pathData} stroke={theme.palette.primary.main} fill="transparent" strokeWidth="3" strokeDasharray={10} key={`${i}-${divKey}`} />);
      }
    }
  
    setSvgPaths(paths);
  };
  
  return (
    <div style={{ position: 'relative' }}>
      <svg id={`svg-container-${divKey}`} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
        <g id="svg-paths">{svgPaths}</g>
      </svg>
      {items.map((subItem, subIndex) => (
        <Grid
          container
          key={subIndex}
          style={{
            justifyContent: subIndex % 2 ? 'flex-start' : 'flex-end',
            marginTop: subIndex === 0 ? -16 : 0,
          }}
        >
          <Grid
            container
            style={{
              position: 'relative',
              width: '90%',
              borderRadius: 16,
              background: `${theme.palette.alt.light}aa`,
              marginBottom: 48,
              opacity: 0.9,
              zIndex: 1000
            }}
          >
            <div
              id={`${subIndex}-top-${divKey}`}
              style={{
                display: subIndex === 0 ? 'none' : 'inherit',
                position: 'absolute',
                top: -13,
                left: screenSize.xs ? (subIndex % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
                width: 26,
                height: 26,
                borderRadius:26,
                background: theme.palette.primary.main,
                // outline: `3px solid ${theme.palette.background.secondary}`
              }}
            />
            <div
              id={`${subIndex}-bottom-${divKey}`}
              style={{
                display: subIndex === items.length-1 ? 'none' : 'inherit',
                position: 'absolute',
                bottom: -13,
                left: screenSize.xs ? (subIndex % 2 ? 'calc(20%)' : 'calc(70%)') : 'calc(50% - 13px)',
                width: 26,
                height: 26,
                borderRadius:26,
                background: `${theme.palette.alt.light}`,
                outline: `3px solid ${theme.palette.background.secondary}`
              }}
            />
            <Typography
              variant="h3"
              style={{
                fontSize: '1.5rem',
                fontWeight: 600,
                color: '#fff',
                background: theme.palette.primary.main,
                width: '100%',
                padding: '8px 24px',
                borderRadius:'16px 16px 0'
              }}
            >
              {subItem.title}
            </Typography>
            <Typography
              variant="landing"
              sx={{
                padding: {xs: '24px', lg:'24px 48px 48px'},
              }}
            >
              {subItem.body}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const DeterminedGraphic = (props) => {
  const { index } = props;
  const theme = useTheme();

  const optionsOne = [
    <AnimatedDotGrid
      size={500}
    />,
    <AnimatedDotGrid
      size={200}
      animationSpeed={2}
      minRadius={3}
      startColor={theme.palette.background.secondary}
      endColor={theme.palette.background.secondary}
      background={theme.palette.secondary.light}
    />,
    <Animated3Dots
      circles={[
        {cx: 150, cy:150, speed: 8, radiusValue: `120;130;120`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
        {cx: 60, cy:250, speed: 2, radiusValue: `40;45;40`, fillValue: `${theme.palette.primary.dark};${theme.palette.primary.main};${theme.palette.primary.dark}`},
      ]}
    />
  ]

  const optionsTwo = [
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
        {cx: 150, cy:170, speed: 3, radiusValue: `25;30;25`, fillValue: `${theme.palette.alt.light};${theme.palette.alt.main};${theme.palette.alt.light}`},
      ]}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
    />
  ]

  const randomOffset = {
    x: Math.random() * (20 - 10) + 10,
    y: Math.random() * (20 - 10) + 10
  }

  
  return (
    <div
      style={{
        position:'absolute',
        top:0,//`calc(50% -${randomOffset.y}vw)`, //randomOffset.y * -1,
        left: index % 2 ? null : '95%',// index % 2 ? `-${randomOffset.x}vw` : `${100 + randomOffset.x}%`,
        right: index % 2 ? '95%' : null,
        zIndex:0
      }}
    >
      {index % 2 ? optionsOne[index % optionsOne.length] : optionsTwo[index % optionsTwo.length]}
    </div>
  )
}

const RandomGraphic = (props) => {
  const { index } = props;
  const theme = useTheme();

  const optionsOne = [
    <AnimatedDotGrid
      size={500}
    />,
    <AnimatedDotGrid
      size={200}
      startColor={theme.palette.background.secondary}
      endColor={theme.palette.background.secondary}
      background={theme.palette.secondary.light}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
      circle1={{cx: 15, cy:15, radiusValue: `20;30;20`, fillValue: `${theme.palette.primary.main};${theme.palette.secondary.main};${theme.palette.primary.main}`}}
    />
  ]

  const optionsTwo = [
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
        {cx: 150, cy:170, speed: 3, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.main};${theme.palette.secondary.dark};${theme.palette.secondary.main}`},
      ]}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
    />,
    <Animated3Dots
      circles={[
        {cx: 100, cy:100, speed: 8, radiusValue: `80;90;80`, fillValue: `${theme.palette.alt.main};${theme.palette.primary.light};${theme.palette.alt.main}`},
        {cx: 30, cy:30, speed: 4, radiusValue: `25;30;25`, fillValue: `${theme.palette.secondary.light};${theme.palette.secondary.main};${theme.palette.secondary.light}`},
      ]}
    />
  ]

  const randomOffset = {
    x: Math.random() * (20 - 10) + 10,
    y: Math.random() * (20 - 10) + 10
  }


  const randomGraphicOption = index % 2
    ? optionsOne[Math.floor(Math.random() * optionsOne.length)]
    : optionsTwo[Math.floor(Math.random() * optionsTwo.length)]
  
  return (
    <div
      style={{
        position:'absolute',
        top:`calc(50% -${randomOffset.y}vw)`, //randomOffset.y * -1,
        left:`-${randomOffset.x}vw`,
        zIndex:0
      }}
    >
      {randomGraphicOption}
    </div>
  )
}

const Features = () => {
  const theme = useTheme();
  return (
    <Grid
      id="features"
      container
    >
      <CenterColumn
        alignment={{x:'flex-start'}}
      >
      <Typography
        sx={{
          fontSize: {xs: '2.5rem', sm:'3rem', md:'4rem', xl:'4.5rem'},
          fontWeight:700,
          lineHeight:1,
          // textTransform:'uppercase',
          color:theme.palette.primary.dark, //'#fff'
          transition:'.4s',
          zIndex:2
        }}
      >
        Features
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: '1.4', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
          lineHeight:1.2,
          marginTop:'16px',
          transition:'.4s'
        }}
      >
        The Fill CRM and email marketing platform has an amazing list of features built to compete with similar products from the corporate marketing overlords.
      </Typography>

      <Grid container variant="center">
        {
          featureList.map(feature => (
            <Grid
              item
              style={{
                padding:4
              }}
              xs={8}
              sm={6}
              md={4}
              lg={3}
            >
              <Grid
                container
                variant="center"
                onClick={() => hashScroll(feature.icon)}
                style={{
                  padding:'2px 8px',
                  borderRadius:10,
                  background:theme.palette.primary.main,
                  color: '#fff',
                  cursor:'pointer'
                }}
              >
                <Typography>{feature.shortName}</Typography>
              </Grid>
            </Grid>
          ))
        }
      </Grid>

      </CenterColumn>
      {
        featureList.map((feature, index) => (
          <FeatureItem
            key={feature.name}
            feature={feature}
            index={index}
          />
        ))
      }
    </Grid>
  )
}

const FeatureItem = (props) => {
  const { feature, index } = props;
  const [available, setAvailable] = useState({
    label: '',
    color: null,
    background: null
  });
  const theme = useTheme();
  const screenSize = useScreenSize();
  const reverse = index % 2;

  useEffect(() => {
    if (feature) {
      if (feature.availability === "now") {
        setAvailable({
          label: 'Available Now',
          color: '#fff', //nowTheme.color,
          background: theme.palette.primary.main //nowTheme.background
        })
      } else
      if (feature.availability === "soon") {
        setAvailable({
          label: 'Coming Soon',
          color: '#fff', //theme.palette.primary.dark, //soonTheme.color,
          background: theme.palette.primary.light //soonTheme.background
        })
      } else
      if (feature.availability === "later") {
        setAvailable({
          label: 'Later This Year',
          color: '#fff', //laterTheme.color,
          background: theme.palette.secondary.main //laterTheme.background
        })
      }
    }
  }, [feature]);

  return (
    <Grid
      id={feature.icon}
      container
      sx={{
        // margin:'48px 0',
        flexDirection: {xs: 'row', md: reverse ? 'row-reverse' : 'row'},
        padding: '24px',
        // paddingBottom: {xs:4, md:0},
        borderBottom:'1px solid #ccc',
        borderTop: index === 0 ? '1px solid #ccc' : null
      }}
    >
        <Grid
          item
          sx={{
            // height:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            // padding:{xs: 0, md: '0 90px'},
          }}
          xs={12}
          md="auto"        >
          {
            feature.icon
              ?
                <img
                  src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/${feature.icon}_500px.png` : `https://static.fillmarketing.com/static/static/img/${feature.icon}_500px.png`}
                  style={{
                    width: screenSize.xs ? '60%' : (screenSize.sm ? '40%' : '80%'),
                    height:'80%',
                    objectFit:'contain'
                  }}
                />
              :
                null
          }
        </Grid>
        <Grid
          item
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}
          xs={12}
          md
        >
            <Grid
              container
              style={{
                maxWidth:500
              }}
            >
              <Typography
                style={{
                  width:'100%',
                  // textAlign: reverse ? 'right' : 'left',
                  lineHeight:1,
                  fontSize:'2.75rem',
                  fontWeight:600,
                  marginBottom:8,
                  color:theme.palette.primary.dark
                }}
              >
                {feature.name}
              </Typography>
              <Typography
                style={{
                  width:'100%',
                  // textAlign: reverse ? 'right' : 'left',
                  textTransform:'uppercase',
                  lineHeight:1.1,
                  fontSize:'2rem',
                  fontWeight:500,
                  marginBottom:16,
                  color:theme.palette.primary.main
                }}
              >
                {feature.short}
              </Typography>
              <Typography
                style={{
                  width:'100%',
                  fontSize:'1.3rem'
                  // textAlign: reverse ? 'right' : 'left'
                }}
              >
                {feature.description}
              </Typography>
              <Grid
                style={{
                  background:available.background,
                  color: available.color,
                  padding:'0 12px',
                  borderRadius:16,
                  marginTop:8
                }}
              >
                <Typography
                  style={{
                    fontSize:'.85rem',
                    fontWeight:500,
                    textTransform:'uppercase',
                    color:available.color
                  }}
                >
                  {available.label}
                </Typography>
              </Grid>
            </Grid>
        </Grid>
    </Grid>
  )
}

const Prices = () => {
  return (
    <CenterColumn id="pricing">
      <Pricing />
    </CenterColumn>
  )
}

const CtaButton = (props) => {
  const { children, onClick, href } = props;

  return (
    <Button
      variant="contained"
      onClick={onClick}
      href={href}
      style={{
        fontSize:'1.2rem'
      }}
    >
      {children}
    </Button>
  )
}

export const CenterColumn = ({ children, id, alignment, style }) => {
  const screenSize = useScreenSize();
  const theme = useTheme();


  return (
    <Grid
      id={id}
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid
        container
        sx={{
          width:{xs:'96%', sm:600, md:800, xl:1000},
          padding:'48px 0',
          justifyContent: alignment?.x ? alignment.x : 'center',
          ...style
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}