import React, { useState, useRef } from 'react';

const CustomColorPicker = ({ color="#000000", setColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [tempColor, setTempColor] = useState(color);
  const colorInputRef = useRef(null);
  console.log('color', color)

  const handleClick = () => {
    setDisplayColorPicker(true);
    colorInputRef.current.click();
  };

  const handleChange = (event) => {
    setTempColor(event.target.value);
    setDisplayColorPicker(false);
  };

  const handleSave = () => {
    setColor(tempColor);
  }

  return (
    <div style={{ position: 'relative', padding: '20px' }}>
      <button
        onClick={handleClick}
        onBlur={handleSave}
        style={{
          // padding: '10px 20px',
          position:'relative',
          width:30,
          height:30,
          borderRadius:16,
          backgroundColor: tempColor ? tempColor : color,
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
      <input
        type="color"
        ref={colorInputRef}
        value={tempColor}
        onChange={handleChange}
        style={{
          position: 'absolute',
          left: 0,
          bottom: -10,
          opacity: 0,
          pointerEvents: displayColorPicker ? 'auto' : 'none',
        }}
      />
      </button>
      {/* <div style={{ marginTop: '20px' }}>
        Selected Color: <span id="colorValue">{color}</span>
      </div> */}
    </div>
  );
};

export default CustomColorPicker;
