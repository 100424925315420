import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { RadialClosedSVG, RadialOpenSVG } from '../../../../assets/icons';


const AnswerItem = (props) => {
  const { key, type, selected, input, index, answerIndex, answer, children, onClick } = props;
  const [hover, setHover] = useState(false);
  const [localSelect, setLocalSelect] = useState(false);
  const theme = useTheme();

  if (selected) {
    // console.log('local select', localSelect, answer);
  }
  // console.log('localSelect', answer, input[index]?.selected === answerIndex)
  const [width, setWidth] = useState(0);

  const handleClick = () => {
    onClick();
    setLocalSelect(prev => !prev);
  };

  useEffect(() => {
    if (selected) {
      setLocalSelect(true);
    } else {
      setLocalSelect(false)
    }

  }, [selected]);

  return (
    <Grid
      key={key}
      onClick={handleClick}
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
      container
      style={{
        background: '#fff',
        position: 'relative',
        outline: selected ? `1px solid ${theme.palette.primary.light}` : '1px solid #fff',
        transition: '.4s',
        padding: 16,
        marginBottom: 8,
        cursor: 'pointer',
        borderRadius: 12,
        // boxShadow: localSelect === key ? '0 0 10px -3px #00000040' : null,
        overflow: 'clip'
      }}
    >
      <Grid
        container
        style={{
          zIndex: 1
        }}
      >
        {
          type === 'multiple' 
            ?
              selected
                ? <RadialClosedSVG width={20} style={{ marginRight: 8 }} color1={theme.palette.primary.dark} />
                : <RadialOpenSVG width={20} style={{ marginRight: 8 }} color1="#ccc" />
            :
              null
        }
        {
          answer
            ?
            <Typography>
              {answer}
            </Typography>
            :
            children
        }
      </Grid>
      <Grid
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: selected ? '100%' : 0,
          height: '100%',
          background: `${theme.palette.primary.light}60`,
          transition: '.4s',
          zIndex: 0
        }} />
    </Grid>

  );
};

export default AnswerItem;