import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import TermsOfService from '../components/terms/TermsOfService'

const TermsServicePage = () => {
  return (
    <LandingTemplate title="Terms of Service">
      <TermsOfService />
    </LandingTemplate>
  )
}

export default TermsServicePage
