import React, { useEffect, useState } from 'react'
import FileDropZone from '../ui/FileDropZone'
import Papa from 'papaparse';
import { Button, Grid, Typography } from '@mui/material';
import { FlipSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';

const Step1ImportCsvHeaders = (props) => {
  const { setIsImportingHeaders, setHeaders, csvFile, setCsvFile, resetCsvFile } = props;
  const theme = useTheme();

  // useEffect(() => {
  //   if (csvFile) {
  //     handleUpload();
  //   } else {
  //     console.log('NO csvFile EXISTS')
  //   }
  // }, [csvFile])

  // const handleUpload = async () => {
  //   if (!csvFile) return;

  //   const reader = new FileReader();
  //   reader.onload = async (e) => {
  //     const csvText = e.target.result;
  //     const { data } = Papa.parse(csvText, { header: false });
  //     const headerData = data[0];
  //     setHeaders(headerData);
  //   };

  //   reader.readAsText(csvFile.file);
  // }

  return (
    csvFile
      ?
        <Grid
          container
          variant="center"
          style={{
            flexDirection:'column',
            padding:24,
            minHeight:150,
            border: `5px dashed ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            style={{
              marginBottom:16
            }}
          >
            {csvFile.file.name}
          </Typography>
          <Button
            onClick={resetCsvFile}
            variant="contained"
            startIcon={<FlipSVG width={14} color1="#fff" />}
          >
            Change CSV File
          </Button>
        </Grid>
      :
        <FileDropZone
          setFile={setCsvFile}
        />
  )
}

export default Step1ImportCsvHeaders