import React, { useEffect, useState } from 'react'
import { useGetUsage } from '../../hooks/mutations'
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { WarningSVG } from '../../assets/icons';

const Usage = () => {
  const usage = useGetUsage();
  console.log('usage', usage);
  return (
    <Grid
      container
      style={{
        padding:16
      }}
    >
      <UsageItem
        title="Contacts"
        item={usage?.number_of_contacts}
      />
      <UsageItem
        title="Custom Fields"
        item={usage?.number_of_custom_fields}
      />
      <UsageItem
        title="Funnels"
        item={usage?.number_of_funnels}
      />
      <UsageItem
        title="Mailing Lists"
        item={usage?.number_of_mailing_lists}
      />
      <UsageItem
        title="Stages"
        item={usage?.number_of_stages}
      />
      <UsageItem
        title="Users"
        item={usage?.number_of_users}
      />
      <Details />
    </Grid>
  )
}

export default Usage

// UsageItem with words
const UsageItem = (props) => {
  const { title, item } = props;
  const theme = useTheme();
  const [percent, setPercent] = useState('0%')

  useEffect(() => {
    const newPercent = getPercentage();
    console.log('newPercent', newPercent);
    setPercent(newPercent);
  }, [item?.limit, item?.usage, item?.exceeded])

  const getPercentage = () => {
    if (item?.exceeded) {
      return 1
    } else if (item?.usage/item?.limit) {
      return item?.usage/item?.limit
    } else {
      return 0
    }
  }

  console.log(title, percent);

  return (
    <Grid
      container
      style={{
        marginBottom:8
      }}
    >
      <Grid
        container
        style={{
          justifyContent:'space-between'
        }}
      >
        <Typography>{title}</Typography>
        {
          percent > .8
            ? <Typography>{item?.usage}/{item?.limit}</Typography>
            : <Typography>{item?.limit}</Typography>
        }

      </Grid>
      <div
        style={{
          position:'relative',
          width:'100%',
          height:40,
          borderRadius:8,
          background:'#ddd',
          overflow:'clip'
        }}
      >
        <div
          style={{
            position:'absolute',
            top:0,
            left:0,
            height:'100%',
            width:`${percent * 100}%`,
            transition:'.4s',
            background:
              item?.exceeded || item?.usage/item?.limit === 1
                ? theme.palette.error.main
                : percent > .8
                  ? theme.palette.warning.main
                  : theme.palette.primary.main
          }}
        />
        <Typography
          style={{
            display: !item?.exceeded ? 'none' : 'initial',
            position:'absolute',
            top:11,
            right:20,
            fontWeight:600,
            color:'#FFF',
            lineHeight:1
          }}
        >
          Limit Exceeded
        </Typography>
        <Typography
          style={{
            display: percent > .8 && !item?.exceeded ? 'initial' : 'none',
            position:'absolute',
            top:11,
            left:`calc(${(percent * 100)}% - 170px)`,
            width:150,
            textAlign:'right',
            fontWeight:600,
            color:'#FFF',
            lineHeight:1
          }}
        >
          {item?.usage/item?.limit === 1 ? 'Limit Reached' : 'Approaching Limit'}
        </Typography>
      </div>
    </Grid>
  )
}

// UsageItem with numbers
// const UsageItem = (props) => {
//   const { title, item } = props;
//   const theme = useTheme();
//   const [percent, setPercent] = useState(0)

//   useEffect(() => {
//     const newPercent = getPercentage();
//     console.log('newPercent', newPercent);
//     setPercent(newPercent);
//   }, [item?.limit, item?.usage, item?.exceeded])

//   const getPercentage = () => {
//     if (item?.exceeded) {
//       return 1
//     } else if (item?.usage/item?.limit) {
//       return item?.usage/item?.limit
//     } else {
//       return 0
//     }
//   }

//   console.log(title, percent);

//   return (
//     <Grid
//       container
//       style={{
//         marginBottom:8
//       }}
//     >
//       <Grid
//         container
//         style={{
//           justifyContent:'space-between'
//         }}
//       >
//         <Typography>{title}</Typography>
//         <Grid
//           variant="center"
//           gap={1}
//         >
//           {
//             percent > .8 && !item?.exceeded
//               ? <WarningSVG width={16} color1={percent == 1 ? theme.palette.error.main : theme.palette.warning.main} />
//               : null
//           }
//           {
//             percent > .8
//               ? <Typography>{item?.usage}/{item?.limit}</Typography>
//               : <Typography>{item?.limit}</Typography>
//           }
//         </Grid>

//       </Grid>
//       <div
//         style={{
//           position:'relative',
//           width:'100%',
//           height:40,
//           borderRadius:8,
//           background:'#ddd',
//           overflow:'clip'
//         }}
//       >
//         <div
//           style={{
//             position:'absolute',
//             top:0,
//             left:0,
//             height:'100%',
//             width:`${percent * 100}%`,
//             transition:'.4s',
//             background:
//               item?.exceeded || item?.usage/item?.limit === 1
//                 ? theme.palette.error.main
//                 : percent > .8
//                   ? theme.palette.warning.main
//                   : theme.palette.primary.main
//           }}
//         />
//         <Typography
//           style={{
//             display: !item?.exceeded ? 'none' : 'initial',
//             position:'absolute',
//             top:11,
//             right:20,
//             fontWeight:600,
//             color:'#FFF',
//             lineHeight:1
//           }}
//         >
//           Limit Exceeded
//         </Typography>
//         <div
//           style={{
//             display: percent > .95 || item?.exceeded  ? 'none' : 'flex',
//             position:'absolute',
//             top:5,
//             left: percent === 0 ? 5 : `calc(${(percent * 100)}% - 15px)`,
//             minWidth:30,
//             height:30,
//             borderRadius:20,
//             alignItems:'center',
//             justifyContent:'center',
//             transition:'.4s',
//             outline:'3px solid #ddd',
//             background:
//             item?.exceeded || item?.usage/item?.limit === 1
//               ? theme.palette.error.main
//               : percent > .8
//                 ? theme.palette.warning.main
//                 : theme.palette.primary.main

//           }}
//         >
//           <Typography
//             style={{
//               textAlign:'center',
//               fontWeight:600,
//               color:'#FFF',
//               lineHeight:1,  
//             }}
//           >
//             {item?.usage}
//           </Typography>
//         </div>
//       </div>
//     </Grid>
//   )
// }

const Details = () => (
  <Grid
    container
    style={{
      marginTop:24
    }}
  >
    <H1>
      Why we set limits
    </H1>
    <Typography>
      We set limits on every account for a few reasons. The limit makes sure too many emails are not sent before the system is warmed up. Sending too many emails during the warm up period will likely damage your sender reputation. Limits also help us make sure that users are not abusing the platform. You may contact us if you need to increase your limits.
    </Typography>
    <H1>
      How to increase your limit
    </H1>
    <Typography>
      If you have reached a limit, please email support@fillmarketing.com and we will be happy to help you increase your limit!
    </Typography>
  </Grid>
)

const H1 = ({ children }) => {
  return (
    <Typography
      variant="h1"
      sx={{
        marginTop:2,
        fontWeight:400,
        fontSize: {
          xs:'1.3rem',
          md:'1.5rem',
          lg:'1.6rem',
        }
      }}
    >
      {children}
    </Typography>
  )
}