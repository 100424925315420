import React from 'react'
import { Button, Grid, Typography } from '@mui/material';
import { CheckmarkSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';
import { LogoHorizontal } from '../../assets/logos';

const SignupPageHeader = (props) => {
  const { step } = props;
  const theme = useTheme();

  return (
    <Grid
      container
    >
      <Grid
        container
        style={{
          height: 64,
          padding:'0 64px',
          background:'#ffffff',
          alignItems:'center',
          justifyContent:'space-between',
          boxShadow:'0 0 10px #00000075'
        }}
      >
        <Grid style={{display:'flex', alignItems:'flex-end'}}>
          <LogoHorizontal width={40} />
        </Grid>
          <Typography style={{marginLeft:16, lineHeight:.7, fontSize:'1.3rem'}}>Account Setup</Typography>
        <Button 
          onClick={() => window.location.href = '/accounts/logout/'}
        >
          Logout
        </Button>

      </Grid>
      <Grid
        container
        style={{
          padding:8,
        }}
      >
        <Grid
          container
        >
          {
            [
              {label: '1', position: 1, description: 'Register'},  // Register, verify email
              {label: '2', position: 2, description: 'Add Company'},  // Create Tenant
              // {label: '3', position: 3, description: 'Company Details'},  // Create Tenant Details
              {label: '3', position: 3, description: 'Pick Subscription'},  // Create payment session and subscribe via stripe
            ].map(stage => (
              <Grid
                key={stage.label}
                item
                // onClick={() => setStep(stage.position)} // for development only -- remove for production
                style={{
                  position:'relative',
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems:'center',
                  padding:'16px 16px 0 16px',
                  cursor: step === stage.position ? 'pointer' : 'default'
                }}
                xs
              >
                  {
                    step > stage.position
                      ?
                        <CheckmarkSVG width={25} color1={step === stage.position ? theme.palette.primary.main : '#ccc'} />
                      :
                        <Grid
                          style={{
                            width:25,
                            height:25,
                            borderRadius:25,
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            background: step === stage.position || step === stage.position ? theme.palette.primary.main : '#bbb'
                          }}
                        >
                          <Typography
                            style={{
                              fontSize:'.75rem',
                              textTransform:'uppercase',
                              fontWeight:700,
                              color:'#fff'
                            }}
                          >
                            {stage.label}
                          </Typography>
                        </Grid>
                  }
                <Typography
                  sx={{
                    display:{xs:'none', sm: 'inherit'},
                    fontSize:'.95rem',
                    fontWeight:600,
                    color: step === stage.position ? theme.palette.primary.main : '#bbb'
                    // textTransform:'uppercase',
                  }}
                >
                  {stage.description}
                </Typography>
              </Grid>
            ))

          }
        </Grid>
      </Grid>
    </Grid>
  )
}


export default SignupPageHeader