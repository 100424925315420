import { Button, Checkbox, Collapse, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { theme } from '../../../theme'
import { AddSVG, EditSVG, ListSVG, UpAngleSVG } from '../../../assets/icons'
import DragAndDropForm from '../../ui/DragAndDropForm'
import { useNavigate, useParams } from 'react-router-dom'
import { postSurvey, putSurvey, useGetSurvey } from '../../../hooks/mutations'
import ReusableModal from '../../ui/ReusableModal'
import { initSurvey, initQuestionSet } from '../../../lib/Data'
import QuestionElement from './form-build-elements/QuestionElement'
import { formatApiResponse, getRandomInts } from '../../../lib/Functions'
import { useTemp } from '../../../context/tempContext'
import SnackbarWrapper from '../../ui/SnackbarWrapper'
import Infotip from '../../ui/Infotip'

const SurveyBuilder1 = (props) => {
  const { survey, setSurvey, incomingSurvey, surveyType, handleSurveyType } = props;
  const { reactSurveyId } = useParams();
  const [categories, setCategories] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showReorder, setShowReorder] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [sidebar, setSidebar] = useState(true);
  const [saved, setSaved] = useState(false);
  const [inputInitial, setInputInitial] = useState(true);
  const [snackMessage, setSnackMessage] = useState(null);
  const navigate = useNavigate();

  // console.log('survey', survey);
  // console.log('incomingSurvey', incomingSurvey);

  // reset 'saved' state after survey creation or update
  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 5000);
    }
  }, [saved])

  const addQuestion = (categoryIndex) => {
    const newQuestion = { ...initQuestionSet, id: getRandomInts(8), new: true };
    let existingSurvey = { ...survey };
    let existingCategorySet = existingSurvey.category_set.length
      ? existingSurvey.category_set 
      : [{
        question_set: [],
        name: "default",
        order: 0,
        description: ""    
      }];
    console.log('existingCategorySet', existingCategorySet);
    let existingQuestionSet = existingCategorySet[0].question_set;
    console.log('existingQuestionSet', existingQuestionSet);

    const updatedQuestionSet = [ ...existingQuestionSet, newQuestion ];
    console.log('updatedQuestionSet', updatedQuestionSet);

    existingSurvey.category_set[0] = { ...existingCategorySet[0], question_set: updatedQuestionSet };
    console.log('existingSurvey', existingSurvey);

    // existingSurvey = { ...existingSurvey, category_set: existingCategorySet };
    // console.log('existingSurvey', existingSurvey);
    


    setSurvey(existingSurvey);

    setCurrentPage(survey?.category_set[0]?.question_set?.length - 1);
  }

  const deleteQuestion = (index) => {
    if (currentPage === survey?.category_set[0]?.question_set?.length - 1) {
      setCurrentPage(prev => prev - 1);
    }
    const newSurveyQuestions = [...survey?.category_set[0]?.question_set];
    newSurveyQuestions.splice(index,1);

    setSurvey((prev) => ({
      ...prev,
      category_set: [
        {
          ...prev.category_set[0],
          question_set: newSurveyQuestions,
        },
      ],  
    }))
  }

  const addAnswer = (categoryIndex, questionIndex) => {
    const existingSurvey = { ...survey };

    const newSurveyQuestions = [...survey?.category_set[categoryIndex]?.question_set];
    // USE ONCE DATA SET IS CHANGED TO ACCEPT OBJECT ARRAYS INSTEAD OF STRINGS
    const newAnswer = {
      id: getRandomInts(8),
      choice_text: '',
      new: true
    };

    newSurveyQuestions[questionIndex].choice_set = [...newSurveyQuestions[questionIndex].choice_set, newAnswer];

    existingSurvey.category_set[0].question_set = newSurveyQuestions;

    setSurvey(existingSurvey);
  }

  const deleteAnswer = (categoryIndex, questionIndex, answerIndex) => {
    const existingSurvey = { ...survey };
    const newSurveyQuestions = [...survey?.category_set[categoryIndex]?.question_set];
    newSurveyQuestions[questionIndex].choice_set.splice(answerIndex,1);
    existingSurvey.category_set[categoryIndex].question_set = newSurveyQuestions;
    setSurvey(existingSurvey);
  }

  // Handle updates to each question page
  const handlePageOrderChange = (value) => {
    const existingSurvey = { ...survey };

    existingSurvey.category_set[0].question_set = value;

    setSurvey(existingSurvey)
  };

  // Handle updates to each question page
  const handlePageChange = (field, categoryIndex, questionIndex, value) => {
    const existingSurvey = { ...survey };
    const newSurveyQuestions = [...existingSurvey.category_set[categoryIndex].question_set];
    const updatedQuestion = {
      ...newSurveyQuestions[questionIndex],
      [field]: value,
    };

    existingSurvey.category_set[categoryIndex].question_set[questionIndex] = updatedQuestion;

    setSurvey(existingSurvey)
  };

  const handlePageAnswerChange = (categoryIndex, questionIndex, answerIndex, field, value) => {
    const existingSurvey = { ...survey };
    console.log('existingSurvey', existingSurvey);
    existingSurvey.category_set[categoryIndex].question_set[questionIndex].choice_set[answerIndex] = {
      ...existingSurvey.category_set[categoryIndex].question_set[questionIndex].choice_set[answerIndex],
      [field]: value
    };
    setSurvey(existingSurvey)
  };

  // Handle updates to the survey details
  const handleDetailsChange = (field, e) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      [field]: e,
    }))
  };

  
  const handleNext = () => {
    if (currentPage < survey?.category_set[0]?.question_set?.length-1) {
      setCurrentPage(prev => prev + 1);
    }
  }

  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  }

  // const saveSurvey = async () => {
  //   const existingSurvey = { ...survey };
  //   console.log('existingSurvey', existingSurvey)
  //   const noIdQuestions = survey.category_set[0].question_set.map(({ id, ...x }) => x);
  //   console.log('noIdQuestions', noIdQuestions)
  //   // const noIdChoices = survey.category_set[0].question_set.map(x => ({...x, choices: x.choice_set.map(({ id, ...x }) => x) }) )
  //   // console.log('noIdChoices', noIdChoices)
  //   existingSurvey.category_set[0].question_set = noIdQuestions;
  //   const adjustedSurveyQuestions = existingSurvey.category_set[0].question_set.map(x => {
  //     // Set question choices field to null if type field is not radio, select, or checkbox
  //     if (
  //       x.type !== 'radio' &&
  //       x.type !== 'select' &&
  //       x.type !== 'select-multiple' &&
  //       x.type !== 'checkbox'
  //     ) {
  //       // Omit the 'choices' field from the object
  //       const { choices, ...rest } = x;
  //       return rest;
  //     } else {
  //       // Remove 'id' field from each choice in the 'choices' array
  //       console.log('x choices', x.choice_set);
  //       const updatedChoices = x.choice_set.map(choice => {
  //         const { id, ...choiceWithoutId } = choice;
  //         return choiceWithoutId;
  //       });
  //       return {
  //         ...x,
  //         choices: updatedChoices
  //       }
  //     }
  //   })

  //   console.log('adjustedSurveyQuestions', adjustedSurveyQuestions)

  //   existingSurvey.category_set[0].question_set = adjustedSurveyQuestions;

  //   const surveyObj = {
  //     surveyId: surveyId ? surveyId : null,
  //     payload: {...existingSurvey}
  //   }

  //   if (surveyId) {
  //     // Update survey
  //     putSurvey(surveyObj);
  //     setSaved(true);
  //   } else {
  //     // Create survey
  //     const created = await postSurvey(surveyObj);
  //     if (created.status === "success") {
  //       navigate('/surveys');
  //     }
  //   }
  // }

  const saveSurvey = async () => {
    // Clone the original object to avoid modifying the original data
    const existingSurvey = { ...survey };
    console.log('existingSurvey', existingSurvey);

    const adjustedSurveyQuestions = existingSurvey.category_set[0].question_set.map(x => {
      // Set question choices field to null if type field is not radio, select, or checkbox
      if (
        x.type !== 'radio' &&
        x.type !== 'select' &&
        x.type !== 'select-multiple' &&
        x.type !== 'checkbox'
      ) {
        // Omit the 'choices' field from the object
        const { choice_set, ...rest } = x;
        return rest;
      } else {
        // Remove 'id' field from each choice in the 'choices' array
        console.log('x choices', x.choice_set);
        const updatedChoices = x.choice_set.map(choice => {
          const { id, ...choiceWithoutId } = choice;
          return choiceWithoutId;
        });
        return {
          ...x,
          choice_set: updatedChoices
        }
      }
    })

    existingSurvey.category_set[0].question_set = adjustedSurveyQuestions;   

    // Remove 'id' and 'new' fields from questions and choices
    const removeIdAndNew = (item) => {
      delete item.id;
      delete item.new;
    };

    // Update category_set
    existingSurvey.category_set.forEach((category) => {
      category.question_set.forEach((question) => {
        if (question.new) {
          // Remove 'id' and 'new' fields from the question
          removeIdAndNew(question);

          // Remove 'id' and 'new' fields from choices in the choices array
          if (question.choice_set && Array.isArray(question.choice_set)) {
            question.choice_set.forEach((choice) => {
              if (choice.new) {
                removeIdAndNew(choice);
              }
              
            }); 
          }
        } else if (question.choice_set && Array.isArray(question.choice_set)) {
          // Remove 'id' and 'new' fields from choices in the choices array
          question.choice_set.forEach((choice) => {
            if (choice.new) {
              removeIdAndNew(choice);
            }
          });
        }
      });
    });

    const surveyObj = {
      surveyId: incomingSurvey ? incomingSurvey.id : null,
      payload: {...existingSurvey}
    }

    if (incomingSurvey) {
      // Update survey
      const updated = await putSurvey(surveyObj);
      if (updated.status === "success") {
        // setSurvey(updated);
        setSaved(true);
      }
    } else {
      // Create survey
      const created = await postSurvey(surveyObj);
      if (created.status === "success") {
        setSnackMessage({open: true, message: 'Created new survey', severity: 'success'});
        navigate('/surveys');
      } else {
        console.log('created data', created.error);
        // const formatted = formatApiResponse(created.error.response);
        setSnackMessage({open: true, message: created.error, severity: 'error'});
      }
    }
  }

  return (
    survey &&
    <Grid
      container
      style={{
        width:'100%'
      }}
    >
      {/* <Grid
        container
        style={{
          position:'sticky',
          top:0,
          justifyContent:'space-between',
          alignItems:'center',
          background:theme.palette.background.secondary,
          minHeight:60,
          zIndex:100
        }}
      >
        <Grid
          container
          style={{
            alignItems:'center',
            justifyContent:'space-between',
            paddingTop:8
          }}
        >
          <Grid
            // onClick={() => setShowDetails(prev=>!prev)}
            style={{
              display:'flex',
              cursor:'pointer',
              marginLeft:16
            }}
          >
            <ReusableModal preOpen={false}>
              <Grid display="flex">
                <Typography>
                  {survey.name ? survey.name : 'Survey Details'}
                </Typography>
                <EditSVG width={15} style={{marginLeft:8}} />
              </Grid>

              <Grid
                container
                style={{
                  padding:48
                }}
              >
                <DetailsSurveyElement
                  survey={survey}
                  handleDetailsChange={handleDetailsChange}
                  categories={categories}
                  setCategories={setCategories}
                  initialSurvey={incomingSurvey}
                />
              </Grid>
            </ReusableModal>
              
          </Grid>

          <Grid
            style={{
              display:'flex',
              alignItems:'center'
            }}
          >
            <Button
              onClick={handleSurveyType}
            >
              {surveyType === "build" ? 'View' : 'Build Survey'}
            </Button>
            <Button
              onClick={saveSurvey}
              variant="contained"
              disabled={saved}
              style={{
                padding:'4px 16px',
                marginRight:16
              }}
            >
              {saved ? 'Survey Saved' : (reactSurveyId ? 'Update' : 'Save')}
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            position:'relative',
            marginBottom:8
          }}
        >
          <Grid
            container
            style={{
              position:'absolute',
              top:0,
              left:0
            }}
          >
            {
              survey?.category_set?.map((category, index) => (
                category.question_set?.map((item, navIndex) => (
                  <Grid
                    item
                    onClick={() => {
                      if (survey.category_set[0].question_set[navIndex]) {
                        setCurrentPage(navIndex);
                      } 
                    }}
                    style={{
                      position:'relative',
                      height:30,
                      cursor:'pointer'
                    }}
                    xs
                  >
                    <Grid
                      style={{
                        height:currentPage === navIndex ? '30%' : '30%',
                        transition:'.4s',
                        background: currentPage === navIndex ? theme.palette.primary.main : theme.palette.primary.light,
                        border:'1px solid white'
                      }}
                    />
                    <UpAngleSVG
                      width="100%"
                      color1={currentPage === navIndex ? theme.palette.primary.main : theme.palette.primary.light}
                      style={{
                        position:'absolute',
                        width: currentPage == navIndex ? 14 : 0,
                        // bottom:30,
                        left: 'calc(50% - 10px)',
                        transition:'.4s'
                      }}
                    />
                  </Grid>
              ))
              ))

            }
          </Grid>

        </Grid>
      </Grid> */}
      
      {
        sidebar
          ?
            <Sidebar
              showReorder={showReorder}
              setShowAll={setShowAll}
              showAll={showAll}
              survey={survey}
              setSurvey={setSurvey}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              addQuestion={addQuestion}
              setShowReorder={setShowReorder}
              handlePageOrderChange={handlePageOrderChange}
            />
          :
            <Footbar
              showReorder={showReorder}
              setShowAll={setShowAll}
              showAll={showAll}
              survey={survey}
              setSurvey={setSurvey}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              addQuestion={addQuestion}
              setShowReorder={setShowReorder}
              handlePageOrderChange={handlePageOrderChange}
            />

      }

      <Grid
        item
        style={{
          height: sidebar ? 'calc(100vh - 60px)' : showReorder ? 'calc(100vh - 260px)' : 'calc(100vh - 60px)',
          overflowY:'auto'
        }}
        xs={sidebar ? true : 12}
      >
        <Grid
          container
          style={{
            justifyContent:'center',
            alignItems:'flex-start',
            position:'relative',
          }}
        >
          
          <Grid
            item
            style={{
              minHeight:'80vh',
              // padding:'72px 0',
              paddingTop:'10vh',
              display:'flex',
              flexDirection:'column',
              // justifyContent:'center'
            }}
            xs={sidebar ? 11 : 12}
            md={sidebar ? 10 : 12}
            lg={sidebar ? 6 : 12}
          >
            {
              showAll
                ?
                  survey?.category_set.map((category, index) => (
                    category.question_set?.map((page, index) => (
                      <QuestionElement
                        key={page.id}
                        page={page}
                        index={index}
                        handlePageChange={handlePageChange}
                        handlePageAnswerChange={handlePageAnswerChange}
                        addAnswer={addAnswer}
                        deleteQuestion={deleteQuestion}
                      />
                    ))
                  ))
                :
                  survey?.category_set.map((category, index) => (
                    category.question_set?.length
                    ?
                        <Grid
                          container
                          style={{
                            position:'relative',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center'
                          }}
                        >
                          <Grid
                            container
                            style={{
                              justifyContent:'space-between',
                              marginBottom:24
                            }}
                          >
                            <Grid
                              onClick={handleBack}
                              variant="center"
                              style={{
                                width:50,
                                height:50,
                                cursor:currentPage === 0 ? 'default' : 'pointer',
                                color:currentPage === 0 ? '#ccc' : theme.palette.primary.main
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize:'1.5rem',
                                  fontWeight:600,
                                  color:'inherit'
                                }}
                              >
                                {`<`}
                              </Typography>
                            </Grid>

                            <Button
                              onClick={addQuestion}
                              // variant="contained"
                            >
                              Add Question
                            </Button>

                            <Grid
                              onClick={handleNext}
                              variant="center"
                              style={{
                                width:50,
                                height:50,
                                cursor:currentPage === survey?.category_set[0]?.question_set?.length-1 ? 'default' : 'pointer',
                                color:currentPage === survey?.category_set[0]?.question_set?.length-1 ? '#ccc' : theme.palette.primary.main
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize:'1.5rem',
                                  fontWeight:600,
                                  color:'inherit'
                                }}
                              >
                                {`>`}
                              </Typography>
                            </Grid>
                          </Grid>
                          <QuestionElement
                            page={survey?.category_set[0]?.question_set?.[currentPage]}
                            index={currentPage}
                            handlePageChange={handlePageChange}
                            handlePageAnswerChange={handlePageAnswerChange}
                            addAnswer={addAnswer}
                            deleteAnswer={deleteAnswer}
                            deleteQuestion={deleteQuestion}
                          />
                        </Grid>
                      :
                        <Button
                          onClick={addQuestion}
                          // variant="contained"
                        >
                          Add Question
                        </Button>
                  ))
            }
          </Grid>
        </Grid>
      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </Grid>
  )
}

export default SurveyBuilder1

const DetailsSurveyElement = (props) => {
  const { handleDetailsChange, survey, categories, setCategories, initialSurvey } = props;

  return (
    <Grid
      container
      style={{
        justifyContent:'center'
      }}
    >
      <Divider style={{width:'100%', marginBottom:24}}>
        <Typography
          style={{
            textAlign:'center',
            fontSize:'1.2rem',
            lineHeight:1,
            fontWeight:400
          }}
        >
          Survey Details
        </Typography>
      </Divider>

      <Grid
        item
        style={{
          display:'flex',
          flexDirection:'column'
        }}
        // xs={11}
        // md={8}
        xs={12}
      >
        <TextField
          name="name"
          label="Name"
          style={{
            margin:'8px 0'
          }}
          value={survey.name}
          onChange={(e) => handleDetailsChange('name', e.target.value)}
        />
        <TextField
          name="description"
          label="Description"
          multiline
          maxRows={4}
          style={{
            background:'#fff',
            margin:'8px 0'
          }}
          value={survey.description}
          onChange={(e) => handleDetailsChange('description', e.target.value)}
        />
        <TextField
          name="redirect_url"
          label="Redirect URL"
          multiline
          maxRows={4}
          style={{
            background:'#fff',
            margin:'8px 0'
          }}
          value={survey.redirect_url}
          onChange={(e) => handleDetailsChange('redirect_url', e.target.value)}
        />
        {/* <TextField
          name="instructions"
          label="Instructions"
          multiline
          maxRows={4}
          style={{
            background:'#fff',
            margin:'8px 0'
          }}
          value={survey.instructions}
          onChange={(e) => handleDetailsChange('instructions', e.target.value)}
        /> */}
        {
          !initialSurvey 
            ?
              <>
                <FormControlLabel
                  value={categories}
                  onChange={(e) => setCategories(e.target.checked)}
                  control={<Checkbox checked={categories} />}
                  label="Separate questions by category"
                  labelPlacement="end"
                />
                {
                  categories &&
                    <TextField
                      name="categories"
                      label="Custom Survey Name"
                      style={{
                        margin:'8px 0'
                      }}
                      value={survey.categories}
                      onChange={(e) => handleDetailsChange('categories', e.target.value)}
                    />
                }
              </>
            :
              <Typography
                style={{
                  color:'#bbb'
                }}
              >
                Survey ID: {survey.id}
              </Typography>
        }

        <Divider style={{width:'100%', margin:'8px 0'}} />
        <FormControlLabel
          value={survey.getContact}
          control={
            <Checkbox
              checked={survey.getContact}
              onChange={(e) => handleDetailsChange('getContact', e.target.checked)}
            />
          }
          label={
            <Typography
              style={{
                lineHeight:1
              }}
            >
              Require user contact before starting survey
            </Typography>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          value={survey.multiSubmissions}
          control={
            <Checkbox
              checked={survey.multiSubmissions}
              onChange={(e) => handleDetailsChange('multiSubmissions', e.target.checked)}
            />
          }
          label={
            <Typography
              style={{
                lineHeight:1
              }}
            >
              Allow multiple submissions for each user
            </Typography>
          }
          labelPlacement="end"
        />
      </Grid>
        
    </Grid>
  )
}

const Sidebar = (props) => {
  const { showReorder, setShowAll, showAll, survey, setSurvey, setCurrentPage, currentPage, addQuestion, setShowReorder, handlePageOrderChange } = props;
  return (
    <Grid
      item
      order={0}
      style={{
        position: 'relative',
        // top:48,
        left: 0,
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent:'center',
        height: 'calc(100vh - 60px)',
        alignItems: 'center'
      }}
      xs="auto"
    >
      <Grid
        container
      >
        <Grid
          item
          style={{
            height: 'calc(100vh - 60px)',
            overflowY: showReorder ? 'auto' : 'hidden'
          }}
        >
          <Collapse
            in={showReorder ? true : false}
            orientation='horizontal'
            // collapsedSize={30}
            style={{
              // width:'100%'
            }}
          >
            <Grid
              container
              style={{
                flexDirection: 'column',
                minWidth: 200,
                // paddingTop:24
                padding: '24px 16px 0 16px'
              }}
            >
              <Grid
                style={{
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  columnGap:4
                }}
              >
                <Button
                  onClick={() => setShowAll(prev => !prev)}
                  style={{
                    // marginRight:16
                  }}
                >
                  {showAll ? 'Show One' : 'Show All'}
                </Button>
                <Infotip
                  question
                  title="Survey Questions"
                  tip="Change how the questions are displayed"
                  messages={{
                    one: <>Toggle between <span style={{fontWeight:600}}>SHOW ONE</span> and <span style={{fontWeight:600}}>SHOW ALL</span> to view one question at a time or all of the questions at once.</>
                  }}
                />
              </Grid>

              <DragAndDropForm
                incomingData={survey?.category_set[0]?.question_set}
                updateData={handlePageOrderChange}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                nameField="text"
                title="Question"
                style={{
                  // padding:'0 16px'
                }} />

              <Button
                onClick={addQuestion}
                variant="contained"
                style={{
                  margin: '8px 0'
                }}
              >
                <AddSVG width={15} color1="#fff" style={{ padding: 8 }} />
              </Button>

            </Grid>
          </Collapse>
        </Grid>
        <Grid
          item
          onClick={() => setShowReorder(prev => !prev)}
          sx={{
            width: 20,
            // background: 'blue',
            borderRight: '1px solid #ccc',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <UpAngleSVG
            width={12}
            color1="#ccc"
            style={{
              transform: showReorder ? 'rotate(-90deg)' : 'rotate(90deg)',
              transition: '.4s'
            }} />
        </Grid>

      </Grid>

    </Grid>
  )
}

const Footbar = (props) => {
  const { showReorder, setShowAll, showAll, survey, setSurvey, setCurrentPage, currentPage, addQuestion, setShowReorder, handlePageOrderChange } = props;
  return (
    <Grid
      item
      order={1}
      style={{
        position: 'relative',
        // top:48,
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        // justifyContent:'center',
        background:'blue',
        alignItems: 'center'
      }}
      xs={12}
    >
      <Grid
        container
        flexDirection="column"
      >
        <Grid
          item
          onClick={() => setShowReorder(prev => !prev)}
          sx={{
            width: 20,
            // background: 'blue',
            borderRight: '1px solid #ccc',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <UpAngleSVG
            width={12}
            color1="#ccc"
            style={{
              transform: showReorder ? 'rotate(-90deg)' : 'rotate(90deg)',
              transition: '.4s'
            }} />
        </Grid>
        <Grid
          item
          style={{
            height: 100,
            display:'flex',
            alignItems:'flex-end',
            overflowY: showReorder ? 'auto' : 'hidden'
          }}
        >
          <Collapse
            in={showReorder ? true : false}
            orientation='vertical'
            // collapsedSize={30}
            style={{
              width:'100%'
            }}
          >
            <Grid
              container
              style={{
                background:'yellow',
                flexDirection: 'row',
                padding: '24px 16px 0 16px'
              }}
            >
              <Button
                onClick={() => setShowAll(prev => !prev)}
                style={{
                  // marginRight:16
                }}
              >
                {showAll ? 'Show One' : 'Show All'}
              </Button>

              <DragAndDropForm
                incomingData={survey?.category_set[0].question_set}
                updateData={handlePageOrderChange}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                style={{
                  // padding:'0 16px'
                  display:'flex'
                }} />

              <Button
                onClick={addQuestion}
                variant="contained"
                style={{
                  margin: '8px 0'
                }}
              >
                <AddSVG width={15} color1="#fff" style={{ padding: 8 }} />
              </Button>

            </Grid>
          </Collapse>
        </Grid>

      </Grid>

    </Grid>
  )
}
