import React, { useState, useEffect } from 'react';
import { CameraSVG, CloseFilledSVG, Column1SVG, Column2SVG, Column3SVG, FlipSVG } from '../../assets/icons';
import { Button, Collapse, Dialog, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import FileDropZone from '../ui/FileDropZone';
import { createStorageFile, deleteStorageFile, putTenantDataBag, putStorageFile, useGetTenantDataBag, useGetPublicFolder, getAllPublicStorageFileUploads } from '../../hooks/mutations';
import { ImageField } from '../ui/Fields';
import { useApi } from '../../context/apiContext';
import { useTheme } from '@emotion/react';
import DialogUpload from '../ui/DialogUpload';
import ColorPicker from '../ui/ColorPicker';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const SectionEditor = ({ selectedElement, onSectionChange, onSectionStyleChange }) => {
  // Combine all text-related styles into a single `style` object
  // console.log('sle', selectedElement.style.backgroundColor);
  const [style, setStyle] = useState({
    backgroundColor: selectedElement?.style.backgroundColor || 'transparent',
    backgroundSize: selectedElement?.style.backgroundSize || 'contain',
    textAlign: selectedElement?.style.textAlign || 'left',
  });

  const [alt, setAlt] = useState(selectedElement.alt || '');
  const [openGallery, setOpenGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState(null)
  const publicFolder = useGetPublicFolder({reload:null});

  // Whenever the selectedElement changes, update the local state
  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.style) {

      };
      setStyle({
        backgroundColor: selectedElement?.style.backgroundColor || 'transparent',
        backgroundSize: selectedElement?.style.backgroundSize || 'contain',
        textAlign: selectedElement?.style.textAlign || 'left',
      });
    }
  }, [selectedElement]);

  // // Update width when value changes
  // useEffect(() => {
  //   if (width && widthFormat) {
  //     setStyle(prev => ({ ...prev, width: `${width}${widthFormat}` }))
  //     if (onSectionStyleChange) {
  //       const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
  //       onSectionStyleChange({...reducedStyle.style, width: `${width}${widthFormat}` });
  //     }
  //   }
  // }, [width, widthFormat])

  // // Update height when value changes
  // useEffect(() => {
  //   if (height && heightFormat) {
  //     setStyle(prev => ({ ...prev, height: `${height}${heightFormat}` }))
  //     if (onSectionStyleChange) {
  //       const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
  //       onSectionStyleChange({...reducedStyle.style, height: `${height}${heightFormat}` });
  //     }
  //   }
  // }, [height, heightFormat])

  // // Update alt when value changes
  // useEffect(() => {
  //   if (alt) {
  //     if (onTextAltChange) {
  //       onTextAltChange(alt);
  //     }
  //   }
  // }, [alt])

  // // Update background when url value changes
  // useEffect(() => {
  //   if (url) {
  //     if (onTextUrlChange) {
  //       onTextUrlChange(url);
  //     }
  //   }
  // }, [url])

  // Change the number of columns in the section
  const handleSwitchColumns = (num) => {
    if (onSectionChange) {
      const prevNum = Number(selectedElement.label.split(' ')[0]);
      let updatedElement = { ...selectedElement };
      console.log('test', updatedElement.elements.map(x => { if(x.columnIndex == 2) {return {...x, columnIndex: 1}} else return x } ))
      if (num == 2) {
        console.log('running 2 column')
        updatedElement.elements = selectedElement.elements.map(x => { if(x.columnIndex == 2) {return {...x, columnIndex: 1}} else return x } )
      }

      if (num == 1) {
        console.log('running single column')
        updatedElement.elements = selectedElement.elements.map(x => ({...x, columnIndex: 0}) )
      }
      console.log('updatedElement', updatedElement)
      onSectionChange({
          ...updatedElement,
          id: `column-${num}`,
          type: `column-${num}`,
          label: `${num} Column`
      })
    }
  }

  // Handle change for the style fields
  const handleStyleChange = (field, value) => {
    const reducedStyle = JSON.parse(JSON.stringify(style));
    const updatedStyle = { ...reducedStyle, [field]: value };
    setStyle(updatedStyle);
    // Call the onSectionStyleChange function to update the text element's style
    if (onSectionStyleChange) {
      onSectionStyleChange(updatedStyle);
    }

  };

  // Handle change for the style fields
  const handleStyleImageChange = (field, value) => {
    const reducedStyle = JSON.parse(JSON.stringify(style));
    const updatedStyle = { ...reducedStyle, [field]: `url(${value})`};
    setStyle(updatedStyle);
    // Call the onSectionStyleChange function to update the text element's style
    if (onSectionStyleChange) {
      onSectionStyleChange(updatedStyle);
    }

  };

  const handleOpenGallery = async () => {
    setOpenGallery(true);
    if (!galleryImages) {
      console.log('publicFolder.id', publicFolder.id)
      const gallery = await getAllPublicStorageFileUploads(publicFolder.id);
      if (gallery.status === "success") {
        setGalleryImages(gallery.data.results);
      }
    }
  }

  return (
    <div className="text-editor">
      <div className="flex gap-8">
        <button
          className={selectedElement.id === "column-1" ? "active" : ""} 
          type="string"
          onClick={() => handleSwitchColumns(1)}
        >
          <Column1SVG color="#FFF" width={20} />
        </button>

        <button
          className={selectedElement.id === "column-2" ? "active" : ""}
          type="string"
          onClick={() => handleSwitchColumns(2)}
        >
          <Column2SVG color="#FFF" width={20} />
        </button>

        <button
          className={selectedElement.id === "column-3" ? "active" : ""}
          type="string"
          onClick={() => handleSwitchColumns(3)}
        >
          <Column3SVG color="#FFF" width={20} />
        </button>
      </div>

      <h4>Edit Style</h4>

      <div className="columns editor-field">
        <label>Alt Text</label>
        <input
          className="editor-field-input input-no-padding-block" 
          type="string"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
        />
      </div>

      <div className="columns editor-field">
        <label>Background URL</label>
        <input
          className="editor-field-input input-no-padding-block" 
          type="string"
          value={style.backgroundImage?.split('(')[1].split(')')[0]}
          onChange={(e) => handleStyleImageChange('backgroundImage', e.target.value)}
        />
      </div>

      {/* <label>Image Gallery</label> */}
      <button
        className="editor-field-input" 
        type="string"
        onClick={handleOpenGallery}
      >
        Open Gallery
      </button>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Background</label>
        </div>
        <div className="editor-field-value-col-2">
          <ColorPicker
            value={selectedElement?.style.backgroundColor}
            onChange={ (color) => handleStyleChange('backgroundColor', color.hex) }
          />
          {/* <input
            className="editor-field-input"
            type="color"
            value={style.backgroundColor}
            onChange={(e) => handleStyleChange('backgroundColor', e.target.value)}
            /> */}
        </div>
        <div className="editor-field-value-col-3">
          <button
            className="invisible"
            onClick={() => handleStyleChange('backgroundColor', 'transparent')}
          >
            <CloseFilledSVG width={16} />
          </button>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Image Fit</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.backgroundSize} onChange={(e) => handleStyleChange('backgroundSize', e.target.value)}>
            <option value="cover">Cover</option>
            <option value="contain">Contain</option>
            <option value="fill">Fill</option>
            <option value="scale-down">Scale-down</option>
          </select>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Image Position</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.backgroundPosition} onChange={(e) => handleStyleChange('backgroundPosition', e.target.value)}>
          <option value="top left">Top Left</option>
            <option value="top">Top</option>
            <option value="top right">Top Right</option>
            <option value="center left">Center Left</option>
            <option value="center">Center</option>
            <option value="center right">Center Right</option>
            <option value="bottom left">Bottom Left</option>
            <option value="bottom">Bottom</option>
            <option value="bottom right">Bottom Right</option>
          </select>
        </div>
      </div>

      {/* <div className="editor-field">
        <div className="editor-field-key">
          <label>Alignment</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.textAlign} onChange={(e) => handleStyleChange('textAlign', e.target.value)}>
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
            <option value="justify">Justify</option>
          </select>
        </div>
      </div> */}

      {/* <div className="editor-field">
        <div className="editor-field-key">
          <label>Height</label>
        </div>
        <div className="editor-field-value-col-2">
          <input
            className="editor-field-input input-no-padding-block"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="editor-field-value-col-3">
          <div
            className={`editor-field-value-col-3 item ${heightFormat === "px" ? 'selected' : ''}`}
            onClick={() => setHeightFormat('px')}
          >
            px
          </div>
          <div
            className={`editor-field-value-col-3 item ${heightFormat === "%" ? 'selected' : ''}`}
            onClick={() => setHeightFormat('%')}
          >
            %
          </div>
        </div>
      </div> */}

      <DialogUpload
        open={openGallery}
        onClose={() => setOpenGallery(false)}
        hideButtons
        header="Upload Image"
        // title="Create An Email Template"
        messages={{
          // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
        }}
      >
        <GalleryDialog
          openGallery={openGallery}
          onClose={() => setOpenGallery(false)}
          url={style.url}
          setUrl={handleStyleImageChange}
          galleryImages={galleryImages}
          publicFolder={publicFolder}
        />
      </DialogUpload>


      {/* <Dialog
        open={openGallery}
        onClose={() => setOpenGallery(false)}
      >
        <Grid
          container
        ></Grid>
      </Dialog> */}
    </div>
  );
};

export default SectionEditor;

const GalleryDialog = ({ openGallery, setOpenGallery, onClose, url, setUrl, publicFolder, galleryImages }) => {
  const live = true;
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [tab, setTab] = useState(0);

  const dataBag = useGetTenantDataBag();
  const theme = useTheme();
  const apiContext = useApi();
  const contextTenantDataBag = apiContext.contextTenantDataBag;
  const navBadge = contextTenantDataBag?.data?.navBadge;
  
  // Create a badge image file when it does not exist yet
  const uploadImage = async () => {
    // console.log('uploading image')
    // Upload image to Public storage folder
    const storageFolderId = publicFolder.id;
    const fileName = file.file.name;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', fileName);
    formData.append('folder', storageFolderId);
    console.log('formData', formData);

    const storageFileObj = {
      folderId: storageFolderId,
      storageFilePayload: formData
    }

    // Replace the file, keeping the same file id
    const storedImage = await createStorageFile(storageFileObj);
    console.log('storedImage', storedImage);

    // If image is stored successfully then save the image url to the data bag
    if (storedImage.status === "success") {
      // Add code to save the url
      setUrl('backgroundImage', storedImage.data.file)
    }
 

  }

  // Replace a badge image with a new file
  const replaceImage = async () => {
    console.log('replacing image', file)
    // Upload image to storage
    const storageFolderId = publicFolder.id;
    const fileName = file.file.name;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', fileName);
    formData.append('folder', storageFolderId);
    console.log('formData', formData);

    const storageFileObj = {
      folderId: storageFolderId,
      fileId: contextTenantDataBag.data.navBadge.storageId,
      storageFilePayload: formData
    }

    // Replace the file, keeping the same file id
    const storedImage = await putStorageFile(storageFileObj);
    console.log('storedImage', storedImage);

    // // If image is stored successfully then save the image url to the data bag
    if (storedImage.status === "success") {
      // Add code to save the badge image to it's location
      const dataObj = {
        id: dataBag.id,
        data: {
          data: {
            ...dataBag.data,
            navBadge: {
              url: storedImage.data.file,
              folderId: storedImage.data.folder,
              storageId: storedImage.data.id,
            }
          }
        }
      }
      const updateBadge = await putTenantDataBag(dataObj);
      console.log('updateBadge', updateBadge)
      if (updateBadge.status === 'success') {
        console.log(updateBadge.response);
        apiContext.handleSetContextTenantDataBag(updateBadge.data);
        handleClose();
      }

    }
 

  }

  // Replace a badge image that already exists
  const existingImage = async (img) => {
    console.log('existing image', img)

    // Add code to save the badge image to it's location
    const dataObj = {
      id: dataBag.id,
      data: {
        data: {
          ...dataBag.data,
          navBadge: {
            url: img.file,
            folderId: img.folder,
            storageId: img.id,
          }
        }
      }
    }
    const updateBadge = await putTenantDataBag(dataObj);
    console.log('updateBadge', updateBadge)
    if (updateBadge.status === 'success') {
      console.log(updateBadge.response);
      apiContext.handleSetContextTenantDataBag(updateBadge.data);
      handleClose();
    }

  }
 
  const deleteBadgeItem = async () => {
    const storageFileObj = {
      folderId: contextTenantDataBag.data.navBadge.folderId,
      fileId: contextTenantDataBag.data.navBadge.storageId,
    }
    const deletedBadge = await deleteStorageFile(storageFileObj);
    if (deletedBadge.status === "success") {
      const dataObj = {
        id: dataBag.id,
        data: {
          data: {
            ...dataBag.data,
            navBadge: {}
          }
        }
      }

      const updateBadge = await putTenantDataBag(dataObj);
      console.log('updateBadge', updateBadge)
      if (updateBadge.status === 'success') {
        console.log(updateBadge.response);
        apiContext.handleSetContextTenantDataBag(updateBadge.data);
      }

    }
  }

  const handleClose = () => {
    setOpen(false);
    setHover(false);
    setFile(null);
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };


  return (
    // <Dialog
    //   open={openGallery}
    //   onClose={onClose}
    //   fullWidth
    // >
      <Grid
        container
        style={{
          flexDirection:'column',
        }}
      >
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Upload" />
          <Tab label="Gallery" />
        </Tabs>

        <CustomTabPanel value={tab} index={0}>
          {
            file
              ?
                file.blob
                  ?
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        minHeight:150,
                        border: '5px solid #ccc',
                      }}
                    >
                      <Button
                        onClick={() => setFile(null)}
                        style={{
                          columnGap:8,
                          marginBottom:8
                        }}
                      >
                        <FlipSVG width={15} color1={theme.palette.primary.main} />
                        Change
                      </Button>

                      <img
                        src={file.blob}
                        style={{
                          width:50
                        }}
                      />

                    </div>
                  :
                    null
              :
                  <Grid style={{textAlign:'center'}}>
                    <FileDropZone
                      setFile={setFile}
                      message="Replace current badge"
                    />
                  </Grid>
          }
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          {
            galleryImages
              ?
                galleryImages.length
                  ?
                    <Grid container>
                      {
                        galleryImages.map(img => (
                          <ImageGallery img={img} existingImage={existingImage} />
                        ))
                      }
                    </Grid>
                  :
                    <Grid container padding={3} height="100%" variant="center">
                      <Typography>No images have been uploaded yet.</Typography></Grid>
              :
                <Grid height="100%" variant="center"><Typography>Loading Gallery...</Typography></Grid>
          }
        </CustomTabPanel>

        {/* <Grid
          container
        >
          <Grid
            item
            style={{
              // padding:16,
              display:'flex',
              flexDirection:'column',
              alignItems:'center',
              justifyContent:'center',
            }}
            xs={12}
            sm={4}
          >
            {
              contextTenantDataBag?.data?.navBadge?.url
                ?
                  <>
                    <Button
                      onClick={deleteBadgeItem}
                      style={{
                        columnGap:8,
                        marginBottom:8
                      }}
                    >
                      <CloseFilledSVG width={15} color1={theme.palette.primary.main} />
                      Remove
                    </Button>

                    <img
                      src={contextTenantDataBag.data.navBadge.url}
                      style={{
                        width:50
                      }}
                    />
                  </>
                :
                  <img
                    src="https://static.fillmarketing.com/static/static/img/fill-logo_500px.png"
                    style={{
                      width:50
                    }}
                  />
            }
          </Grid>
          <Grid
            item
            style={{
              padding:16,
              minHeight:220
            }}
            xs={12}
            sm={8}
          >
            {
              file
                ?
                  file.blob
                    ?
                      <div
                        style={{
                          display:'flex',
                          flexDirection:'column',
                          alignItems:'center',
                          justifyContent:'center',
                          minHeight:150,
                          border: '5px solid #ccc',
                        }}
                      >
                        <Button
                          onClick={() => setFile(null)}
                          style={{
                            columnGap:8,
                            marginBottom:8
                          }}
                        >
                          <FlipSVG width={15} color1={theme.palette.primary.main} />
                          Change
                        </Button>

                        <img
                          src={file.blob}
                          style={{
                            width:50
                          }}
                        />

                      </div>
                    :
                      null
                :
                  !openGallery
                    ?
                      galleryImages
                        ?
                          <Grid
                            container
                          >
                            <Divider
                              style={{
                                width:'100%'
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize:'.85rem',
                                  fontWeight:500,
                                  color:"#aaa",
                                  display:'flex',
                                  alignItems:'center'
                                }}
                              >
                                GALLERY
                                <IconButton
                                  // onClick={() => setOpenGallery(false)}
                                >
                                  <CloseFilledSVG width={14} color1="#aaa" />
                                </IconButton>
                              </Typography>
                            </Divider>
                            {
                              galleryImages.length
                                ?
                                  <Grid container>
                                    {
                                      galleryImages.map(img => (
                                        <ImageGallery img={img} existingImage={existingImage} />
                                      ))
                                    }
                                  </Grid>
                                :
                                  <Grid container marginTop={6} height="100%" variant="center">
                                    <Typography>No images have been uploaded yet.</Typography></Grid>
                            }
                          </Grid>
                        :
                          <Grid height="100%" variant="center"><Typography>Loading Gallery...</Typography></Grid>

                    :
                      <Grid style={{textAlign:'center'}}>
                        <FileDropZone
                          setFile={setFile}
                          message="Replace current badge"
                        />
                        <Button
                          // onClick={handleOpenGallery}
                        >
                          Pick From Gallery
                        </Button>
                      </Grid>
            }

          </Grid>
        </Grid> */}

        <Grid
          container
          style={{
            justifyContent:'space-between',
            padding:'0 16px 8px'
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={uploadImage}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    // </Dialog>
  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        minHeight:150
      }}
      {...other}
    >
      {value === index && <Grid sx={{ p: 3 }}>{children}</Grid>}
    </div>
  );
}


const ImageGallery = (props) => {
  const { img, existingImage } = props;
  const [hover, setHover] = useState(false);
  return(
    <Grid
      item
      style={{
        padding:0
      }}
      xs
    >

      <img
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => existingImage(img)}
        src={img.file}
        style={{
          width:90,
          height:90,
          backgroundSize:'contain',
          borderRadius: 16,
          padding:8,
          border: `1px solid #ccc`,
          outline: hover ? '1px solid black' : '1px solid transparent',
          cursor:'pointer'
        }}
      />
    </Grid>
  )
}