import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Button, Grid, Typography } from '@mui/material';
import { GrabSVG, HiddenSVG, VisibleSVG } from '../../assets/icons';
import { Tag, TagSimple } from './Tags';

const DragAndDropFormCustomFieldOrder = (props) => {
  const { incomingData, updateData, currentPage, setCurrentPage, title, nameField, style, handleCustomFieldVisibility } = props;
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [questionData, setQuestionData] = useState(incomingData);
  const [hoverItem, setHoverItem] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    if (incomingData) {
      setQuestionData(incomingData);
    }
  }, [incomingData]);

  const handleDataUpdate = () => {
    updateData(questionData);
  }

  const handleDragStart = (index, e) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    var emptyImage = document.createElement('img');
    // Set the src to be a 0x0 gif
    emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    e.dataTransfer.setDragImage(emptyImage, 0, 0);

  };

  const handleDragEnter = (index) => {
    if (draggedIndex === null || index === draggedIndex) return;

    setPlaceholderIndex(index);

    const newData = [...questionData];
    const [draggedItem] = newData.splice(draggedIndex, 1);
    newData.splice(index, 0, draggedItem);

    setQuestionData(newData);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setPlaceholderIndex(null);
    setDraggedIndex(null);
    handleDataUpdate();
  };

  return (
    questionData &&
    <div style={{...style}}>
      {questionData?.map((item, index) => (
        <div
          key={index}
          draggable
          onClick={() => setCurrentPage(index)}
          onDragStart={(e) => {
            e.stopPropagation();
            handleDragStart(index, e)
          }}
          onDragEnter={() => handleDragEnter(index)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnd={handleDragEnd}
        >
          {
            placeholderIndex === index 
              ?
                <PlaceholderElement
                  title={title}
                  index={index}
                >
                  {item[nameField]
                    ? item[nameField]
                    : 'No Item'
                  }
                </PlaceholderElement>
              :
                <ItemElement index={index} item={item} draggedIndex={draggedIndex} currentPage={currentPage}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontSize:'.8rem',
                          textTransform:'uppercase'
                        }}
                      >
                        {title ? `${title} ${index+1}` : `Item ${index+1}`}
                      </Typography>

                      {item[nameField]
                        ? item[nameField]
                        : 'No Item'
                      }
                    </Grid>
                    <Grid
                      item
                      variant="center"
                      sx={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                      <Button
                        onMouseEnter={() => setHoverItem(item.id)}
                        onMouseLeave={() => setHoverItem(null)}
                        style={{
                          padding:0,
                          fontSize:'.8rem',
                          lineHeight:1,
                          columnGap:4,
                          display:'flex',
                          alignItems:'flex-start',
                          justifyContent:'flex-end',
                          marginBottom:4
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCustomFieldVisibility(!item.visible, questionData, index)
                        }}
                      >
                        {
                          // item.visible
                          //   ?
                          //     hoverItem === item.id
                          //       ?
                          //         <>
                          //           hide
                          //           <HiddenSVG height=".65rem" color1={theme.palette.primary.main} />
                          //         </>
                          //       :
                          //         <>
                          //           visible
                          //           <VisibleSVG height=".65rem" color1={theme.palette.primary.main} />
                          //         </>
                          //   :
                          //     hoverItem === item.id
                          //       ?
                          //         <>
                          //           show
                          //           <VisibleSVG height=".65rem" color1={theme.palette.primary.main} />
                          //         </>
                          //       :
                          //         <>
                          //           hidden
                          //           <HiddenSVG height=".65rem" color1={theme.palette.primary.main} />
                          //         </>
                        }
                        {
                          item.visible
                            ?
                              <>
                                visible
                                {
                                  hoverItem === item.id
                                    ? <HiddenSVG height=".65rem" color1={theme.palette.primary.main} />
                                    : <VisibleSVG height=".65rem" color1={theme.palette.primary.main} />
                                }
                              </>
                            :
                              <>
                                hidden
                                {
                                  hoverItem === item.id
                                    ? <VisibleSVG height=".65rem" color1={theme.palette.primary.main} />
                                    : <HiddenSVG height=".65rem" color1={theme.palette.primary.main} />
                                }
                              </>
                        }
                      </Button>
                      <TagSimple name={item.column_type} thin />
                    </Grid>
                  </Grid>
                </ItemElement>
          }
        </div>
      ))}
    </div>
  );
};
export default DragAndDropFormCustomFieldOrder;

const ItemElement = (props) => {
  const { children, index, item, draggedIndex, currentPage } = props;
  const theme = useTheme();

  return (
    <div
      style={{
        display:'flex',
        alignItems:'center'
      }}
    >
      <div
        className='grab-cursor'
        style={{
          pointerEvents: draggedIndex === index ? 'none' : 'inherit' ,
        }}
      >
        <GrabSVG width={20} style={{paddingRight:10}} />
      </div>
      <div
        className='grab-cursor'
        style={{
          padding:'8px 24px',
          borderRadius:4,
          margin:'8px 0',
          background: item.visible ? '#fff' : '#eee',
          pointerEvents: draggedIndex === index ? 'none' : 'inherit' ,
          width:200,
          overflow:'hidden',
          whiteSpace:'nowrap',
          textOverflow:'ellipsis',
          overflow:'hidden',
          boxShadow: item.visible ? '0 0 10px #00000030' : null
        }}
      >
        <Typography
          style={{
            fontSize:'1rem',
            color: item.text ? 'inherit' : currentPage === index ?'#fff' : '#777',
            whiteSpace:'nowrap',
            textOverflow:'ellipsis',
            overflow:'hidden'
          }}
        >
          {children}
        </Typography>
          
      </div>
    </div>

  )
}

const PlaceholderElement = (props) => {
  const { children, title, index } = props;

  return (
    <div
      style={{
        display:'flex',
        alignItems:'center'
      }}
    >
      <div
        style={{
        }}
      >
        <GrabSVG width={20} style={{paddingRight:10}} />
      </div>

      <div
        style={{
          width:'100%',
          padding:'8px 24px',
          margin:'8px 0',
          background:'#fff',
          border:'3px dashed #ccc'
        }}
      >
        <Typography
          style={{
            fontSize:'.8rem',
            textTransform:'uppercase'
          }}
        >
          {title ? `${title} ${index+1}` : `Item ${index+1}`}
        </Typography>

        <Typography
          style={{
            fontSize:'1rem'
          }}
        >
          {children}
        </Typography>
          
      </div>
    </div>

  )
}
