import React, { useEffect, useState } from 'react'
import { useGetForm } from '../hooks/mutations'
import MainPageTemplate from '../templates/MainPageTemplate'
import FormEditWrapper from '../components/forms/FormEditWrapper'
import { useParams } from 'react-router-dom'

const FormsEditPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { reactFormId } = useParams();
  const [reload, setReload] = useState();
  const [formId, setFormId] = useState(currentEnv === "STAGING" ? reactFormId : reactFormId);

  // Get the script element by its id
  const scriptElement = document.getElementById('django-edit-form');

  if (scriptElement && !formId) {
    // Access the content of the script element
    const djangoFormString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoForm = JSON.parse(djangoFormString);

    // Set the form id state from the JSON data
    setFormId(djangoForm.id)
  }

  // const incomingForm = useGetForm(formId, reload);

  return (
    // <MainPageTemplate title="Welcome">
      <FormEditWrapper formId={formId} />
    // </MainPageTemplate>
  )
}

export default FormsEditPage
