import { getAuth } from "firebase/auth";

export const api_endpoint = () => {
    // return process.env.API_URL? process.env.API_URL: "https://signedpic.com";  //"https://signedpic.com"; // "http://localhost:8000"; //
    return process.env.REACT_APP_CURRENT_ENV === 'STAGING'
    ? 
      "http://internal.localfill.com:8000"
      // "http://stringlocalfill.com:8000"
      // "http://localfill.com:8000" 
      //"https://internal.fillmarketing.com"
      // http://73.59.90.103:8000 
    : 
      "";
}

export const fakeBasicAuthHeaders = () => {
  var username = 'user_woo@example.com';
  var password = 'string';
  const token = `${username}:${password}`;
  const encodedToken = btoa(token);
  // var headers = await auth_request_headers();
  var headers = {
  'accept': 'application/json',
  // 'Authorization': 'Basic ' + encodedToken,
  'Content-Type': 'application/json',
  };
  return headers;
}


export const auth_request_headers = async () => {
    if (process.env.REACT_APP_CURRENT_ENV === 'STAGING') {
      return fakeBasicAuthHeaders();
    }
    const user = await user_details();

    var token = await user.getIdToken();

    return {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token
    };
};

export const auth_request_form_headers = async () => {
    const user = await user_details();

    var token = await user.getIdToken();

    return {
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + token
    };
};

export const user_details = async () => {    
  const user = await new Promise((resolve, reject) =>
      getAuth().onAuthStateChanged((user) =>
      resolve(user), (e) => reject(e)));

  //const user = auth.currentUser;
  if(user == null) {
      console.log("Error: No user");
      
      // TODO redirect to login?
      //window.location.replace('/login');
  } else {
      return user;
  }
}