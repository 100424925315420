import React, { useEffect, useState } from 'react'
import { Box, Button, Collapse, Dialog, Grid, Typography, useMediaQuery } from '@mui/material';
import { addDoc, doc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../context/authContext';
import { db } from '../../firebase';
import ReusableModal from '../ui/ReusableModal';
import FeedbackForm from './FeedbackForm';
import BugReportForm from './BugReportForm';
import FeatureRequestForm from './FeatureRequestForm';
import LottiePlayer from '../ui/LottiePlayer';
import Feedbackin from '../../assets/lottie/Feedbackin.json'
import FeatureRequestin2 from '../../assets/lottie/FeatureRequestin2.json'
import Building1 from '../../assets/lottie/Building1.json'
import BugCrushin from '../../assets/lottie/BugCrushin.json'
import { useTheme } from '@emotion/react';
import { ArrowSVG, FeedbackSVG } from '../../assets/icons';

const FeedbackPopout = ({ visible }) => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hover, setHover] = useState(false);

  const handleClose = () => {
    setSubmitted(true);
    setTimeout(() => {
      setOpen(false);
      setSubmitted(false);
    }, 1500);
  }
  
  const handleOpen = () => {
    setOpen(true);
  }

  // Listen for feedback button click
  useEffect(() => {
    const feedbackButton = document.getElementById('feedback-button');

    if (feedbackButton) {
        feedbackButton.addEventListener('click', handleOpen);
    }

    return () => {
        if (feedbackButton) {
            feedbackButton.removeEventListener('click', handleOpen);
        }
    };
  }, []); // Empty dependency array to run the effect only once
  

  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})


  return (
      // <ReusableModal maxWidth="md">
      <>
        {
          visible &&
            <Grid
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={() => setOpen(true)}
              sx={{
                background:hover ? theme.palette.secondary.main : theme.palette.secondary.dark,
                // background: isMobile ? `linear-gradient(-45deg, ${theme.palette.brand.eight}, ${theme.palette.brandLight.six})` : theme.palette.brand.eight,
                p: isMobile ? 1 : '4px 12px',
                position:'fixed',
                top:'80%',
                right:0,
                display: isMobile ? 'flex' : 'block',
                borderRadius: isMobile ? '10px 0 0 10px' : '16px 16px 0 0',
                transform: isMobile ? 'rotate(0deg)' : 'rotate(-90deg)',
                marginRight: isMobile ? 0 : -4,
                marginTop: 0,
                cursor:'pointer',
                transition:'.4s',
                pointerEvents:'auto',
                zIndex:10
              }}
            >
              {
                isMobile
                  ?
                    <FeedbackSVG
                      width={20}
                      color1="#fff"
                    />
                  :
                    <Typography
                      sx={{
                        color:'#fff',
                        fontSize:'1rem',
                        fontWeight:600,
                      }}
                    >
                      Feedback
                    </Typography>
              }
            </Grid>
        }

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
        >
          {
            submitted
              ? <Success />
              : <FeatureFeedbackModal handleClose={handleClose} />
          }
        </Dialog>
      </>

      // </ReusableModal>
  )
}

export default FeedbackPopout

const FeatureFeedbackModal = (props) => {
  const { handleClose } = props;
  const [featureOrFeedback, setFeatureOrFeedback] = useState();
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        // background: '#F6F6F6'
        maxWidth:500,
        background:`${theme.palette.primary.light}17`
      }}
    >
      {
        !featureOrFeedback
          ?
            <Grid
              container
              sx={{
                py:2,
                px:3,
                // background:'#FFFFFF'
              }}
            >
              <FeedbackRequestButton
                onClick={() => setFeatureOrFeedback('feedback')}
                animation={Feedbackin}
              >
                I want to leave feedback
              </FeedbackRequestButton>
              <FeedbackRequestButton
                onClick={() => setFeatureOrFeedback('fix')}
                animation={BugCrushin}
              >
                I found a bug that needs fixin'
              </FeedbackRequestButton>
              <FeedbackRequestButton
                onClick={() => setFeatureOrFeedback('feature')}
                animation={FeatureRequestin2}
              >
                I have a feature request
              </FeedbackRequestButton>
            </Grid>
          :
            null
      }
      {
        featureOrFeedback === 'feedback'
          ?
            <Grid
              container
            >
              <Grid
                container
                sx={{
                  py:2,
                  px:4,
                  background:'#FFFFFF',
                  justifyContent:'space-between',
                  justifyContent:'space-between',
                  alignItems:'center'
                }}
              >
                <Typography
                  variant="h4"
                >
                  Feedback
                </Typography>
                <div
                  onClick={() => setFeatureOrFeedback(null)}
                >
                  <ArrowSVG width={24} color1="#999" style={{transform:'rotate(180deg)'}} />
                </div>
              </Grid>

              <Grid
                container
                sx={{
                  p:4,
                }}
              >
                <FeedbackForm handleClose={handleClose} />
              </Grid>
            </Grid>
          :
            null
      }
      {
        featureOrFeedback === 'fix'
          ?
            <Grid
              container
            >
              <Grid
                container
                sx={{
                  py:2,
                  px:4,
                  background:'#FFFFFF',
                  justifyContent:'space-between',
                  alignItems:'center'
                }}
              >
                <Typography
                  variant="h4"
                >
                  Bug Report
                </Typography>
                <div
                  onClick={() => setFeatureOrFeedback(null)}
                >
                  <ArrowSVG width={24} color1="#999" style={{transform:'rotate(180deg)'}} />
                </div>
              </Grid>

              <Grid
                container
                sx={{
                  p:4,
                }}
              >
                <BugReportForm handleClose={handleClose} />
              </Grid>
            </Grid>
          :
            null
      }
      {
        featureOrFeedback === 'feature'
          ?
            <Grid
              container
            >
              <Grid
                container
                sx={{
                  py:2,
                  px:4,
                  background:'#FFFFFF',
                  justifyContent:'space-between',
                  alignItems:'center'
                }}
              >
                <Typography
                  variant="h4"
                >
                  Feature Request
                </Typography>
                <div
                  onClick={() => setFeatureOrFeedback(null)}
                >
                  <ArrowSVG width={24} color1="#999" style={{transform:'rotate(180deg)'}} />
                </div>
              </Grid>

              <Grid
                container
                sx={{
                  p:4,
                }}
              >
                <FeatureRequestForm handleClose={handleClose} />
              </Grid>
            </Grid>
          :
            null
      }
    </Grid>
  )
}

const FeedbackRequestButton = (props) => {
  const { children, animation, onClick } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})


  return (
    <Grid
      container
      onClick={onClick}
      sx={{
        py:2,
        px:3,
        my:1,
        borderRadius:2,
        width:'100%',
        boxShadow:'0 0 7px #00000030',
        background:'#fff',
        cursor:'pointer',
        alignItems:'center'
      }}
    >
      <Grid
        item
        order={{xs:1, sm:0}}
        xs={12}
        sm
      >
        <Typography
          sx={{
            textAlign:{xs:'center', sm:'inherit'},
            fontSize:24,
            fontWeight:500,
            lineHeight:1
          }}
        >
          {children}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm
      >
        <LottiePlayer
          data={animation}
          height={isMobile ? 100 : 150}
          ratio="xMidYMax"
        />
      </Grid>
    </Grid>
  )
}

const Success = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})
  return (
    <div style={{width:'100%'}}>
      <Grid
        container
        style={{
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center',
          padding:16
        }}
      >
        <LottiePlayer
          data={Building1}
          height={isMobile ? 100 : 150}
          ratio="xMidYMax"
        />
        <Typography
          style={{
            fontSize:'1.4rem',
            fontWeight:600
          }}
        >
          Woo-hoo! We got your message.
        </Typography>
      </Grid> 
    </div>
  )
}