import React, { useEffect, useState } from 'react'
import { useGetForm, useGetFormSubscribe } from '../hooks/mutations'
import MainPageTemplate from '../templates/MainPageTemplate'
import FormEditWrapper from '../components/forms/FormEditWrapper'
import FormSubscribeContainer from '../components/forms/FormSubscribeContainer'
import { useParams } from 'react-router'

const FormsSubscribeContainerPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const [reload, setReload] = useState();
  const { tokenId } = useParams();
  const [token, setToken] = useState(currentEnv === "STAGING" ? tokenId : null);
  
  // Get the script element by its id
  const scriptElement = document.getElementById('external-forms-root');
  console.log('scriptElement', scriptElement)

  if (scriptElement && !token) {
    // Access the content of the script element
    const djangoFormString = scriptElement?.textContent;
    console.log('djangoFormString', djangoFormString)
  
    // Parse the JSON data
    const djangoForm = JSON.parse(djangoFormString);
    console.log('djangoForm', djangoForm)

    // Set the form id state from the JSON data
    setToken(djangoForm.id)
  }

  const incomingForm = useGetFormSubscribe(token);
  console.log('incomingForm', incomingForm)

  return (
    <MainPageTemplate title="Form">
      <FormSubscribeContainer form={incomingForm} token={token} />
    </MainPageTemplate>
  )
}

export default FormsSubscribeContainerPage
