import React, { useState } from 'react'
import { Button, Grid, InputAdornment, Typography, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { HiddenSVG, VisibleSVG } from '../../assets/icons';
import { InputField } from '../ui/Fields';
import { postRegisterTenant, putAdminDetails } from '../../hooks/mutations';
import { useTheme } from "@emotion/react";
import { getMarkerEnd } from 'reactflow';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { LogoHorizontal } from '../../assets/logos';
import Step3TenantDetails from './Step3TenantDetails';

const Step3Tenant = () => {
  const [registerTenantName, setRegisterTenantName] = useState(null);
  const [registerDomain, setRegisterDomain] = useState(null);
  const [tempDomain, setTempDomain] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);
  const [registerTenantDetails, setRegisterTenantDetails] = useState(null);
  console.log('registerTenantDetails', registerTenantDetails)
  const theme = useTheme();

  const addTenant = async () => {
    setButtonClicked(true);
    // setErrorDisplay(null);

    if (
      !registerTenantName ||
      !registerDomain ||
      !registerTenantDetails?.registerStreetAddress ||
      !registerTenantDetails?.registerCity ||
      !registerTenantDetails?.registerCountry ||
      !registerTenantDetails?.registerState ||
      !registerTenantDetails?.registerZipCode
    ) {
      setSnackMessage({
        open: true,
        message: [
          !registerTenantName && <div>Please fill out the company name</div>,
          !registerDomain && <div>Please fill out your subdomain name</div>,
          !registerTenantDetails?.registerStreetAddress && <div>Please enter the street address</div>,
          !registerTenantDetails?.registerCity && <div>Please enter the city</div>,
          !registerTenantDetails?.registerCountry && <div>Please enter the country</div>,
          !registerTenantDetails?.registerState && <div>Please enter the state</div>,
          !registerTenantDetails?.registerZipCode && <div>Please enter the zip code</div>
        ],
        severity:'error'
      });
      setButtonClicked(false);
      return;
    }


    // Object for POST tenant api call
    const tenantObj = {
      payload: {
        name: registerTenantName,
        domain: registerDomain
      }
    }
    // POST tenant api call
    const registeredTenant = await postRegisterTenant(tenantObj);

    // Object for PUT admin details api call
    const adminObj = {
      payload: {
        address: {
          locality: {
            state: {
              country: {
                code: registerTenantDetails.registerCountry.code || "",
                name: registerTenantDetails.registerCountry.name || ""
              },
              name: registerTenantDetails.registerState.name || "",
              code: registerTenantDetails.registerState.code || ""
            },
            // name: "string",
            postal_code: registerTenantDetails.registerZipCode || ""
          },
          street_number: registerTenantDetails.registerStreetAddress || "",
          // route: "string",
          // raw: "string",
          // formatted: "string",
          // latitude: 0,
          // longitude: 0
        },
        // support_email_address: registerTenantDetails.registerSupportEmail || "",
        // phone_number: registerTenantDetails.registerPhone || ""
      }
    }
    // PUT admin details api call
    const registeredDetails = await putAdminDetails(adminObj);

    if (registeredTenant.status === "success" && registeredDetails.status === "success") {
      window.location = "/pricing/";

    } else {
      // setErrorDisplay("Please fill out all required fields")
      setButtonClicked(false);
    }
  }


  //Clean up subdomain input
  const handleSubdomainFormatting = (subdomain) => {
    return subdomain.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();
  }

  const handleTenantName = (value) => {
    setRegisterTenantName(value);
    setTempDomain(value);
  }

  const handleTempDomain = () => {
    console.log('focusing')
    if (registerDomain) return;

    if (!registerDomain && tempDomain) {
      const formattedDomain = handleSubdomainFormatting(tempDomain);
      setRegisterDomain(formattedDomain);
    }
  }

  // const ErrorDisplay = () => {

  //   return (
  //     errorDisplay &&
  //     <Grid container justifyContent="center" marginBottom={2}>

  //       <Alert severity="warning">
  //         <AlertTitle>{errorDisplay}</AlertTitle>
  //       </Alert>
  //     </Grid>

  //   )
  // }

  return (
    <Grid
      container
      variant="center"
      style={{
        margin:'40px 0'
      }}
    >
      <Grid
        container
        // justifyContent="center"
        sx={{
          // p: 3,
          boxShadow:'0 0 10px #00000030',
          background:'#ffffff',
          borderRadius:2
        }}
        xs={11}
        sm={10}
        md={8}
        lg={6}
      >
        <Grid
          container
          sx={{
            flexDirection:'column',
            padding:4
          }}
        >
          <Grid
            variant="center"
            sx={{
              flexDirection:'column'
            }}
          >
            {/* <LogoHorizontal
              width={65}
            /> */}
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                fontSize:'1.4rem',
                fontWeight: 600
              }}
            >
              ADD COMPANY
            </Typography>
          </Grid>

          <InputField
            // disabled
            label="Company Name"
            // variant="standard"
            placeholder="Enter the name of your company"
            fullWidth
            value={registerTenantName}
            onChange={(e) => handleTenantName(e.target.value)}
            margin
          />
          <InputField
            // disabled
            label="Company Subdomain"
            // variant="standard"
            placeholder="Enter the subdomain"
            fullWidth
            value={registerDomain}
            onFocus={handleTempDomain}
            onChange={(e) => setRegisterDomain(handleSubdomainFormatting(e.target.value))}
            margin
          />
          {/* <Typography style={{
            textAlign: "center",
            margin: '0 0 2px 16px',
            fontWeight: 500,
            color: theme.palette.primary.main,
            display: 'block',

            gap: 8
          }}>Subdomain Preview</Typography> */}
          <Typography
            style={{
              textAlign: "center",
              width:'100%',
              margin: '0 0 2px 16px',
              fontWeight: 700,
              fontSize:'1.3rem',
              color: theme.palette.primary.dark, // "#777",
              paddingBottom: '10px'
            }}
          >
            {registerDomain}<span style={{color:'#aaa'}}>.fillmarketing.com</span>
          </Typography>

          <Step3TenantDetails setRegisterTenantDetails={setRegisterTenantDetails} />
          <Grid
            container
            style={{
              justifyContent: 'center',
            }}
          >
            {/* <ErrorDisplay /> */}

            <Button
              variant="contained"
              startIcon={buttonClicked && !errorDisplay ? <CircularProgress size={16} thickness={8} style={{ color: '#fff' }} /> : null}
              onClick={addTenant}
              style={{
                minWidth: 120,
                margin: '0 0 10px 16px',
              }}
            >
              {buttonClicked && !errorDisplay ? "Creating company" : "Create company"}
            </Button>
          </Grid>
          
        </Grid>
      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </Grid>
  )
}

export default Step3Tenant