import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  Typography,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG, CheckmarkSVG, DownTriangleSVG, EmailSVG, EventSVG, FilterSVG, SelectSVG, NumberSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { createUploadCsv, useGetAllCustomFields, useGetCustomFields } from '../../hooks/mutations';
import CreateCustomFields from '../custom_fields/CreateCustomFields';
import { customFieldColumns } from '../../lib/Data';
import CustomFieldComparison from '../custom_fields/CustomFieldComparison';
import { useNavigate } from 'react-router-dom';
import Step4ConfirmCsvUpload from './Step4ConfirmCsvUpload';
import Papa from 'papaparse';
import Step1ImportCsvHeaders from './Step1ImportCsvHeaders';
import Step2CustomFieldComparison from './Step2CustomFieldComparison';
import Step3CreateCustomFields from './Step3CreateCustomFields';

// STEP 1: Upload csv
// STEP 2: Choose fields to add
// STEP 3: Set new field types
// STEP 4: Confirm csv import
// STEP 5: Correlate existing and incoming fields

export const CsvImportWizard = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [reload, setReload] = useState(true);
  const incomingCustomFields = useGetAllCustomFields(reload);
  const [newCustomFields, setNewCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState(incomingCustomFields);
  const [headers, setHeaders] = useState(null);
  const [preview, setPreview] = useState(null);
  const [assumedHeaderTypes, setAssumedHeaderTypes] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isImportingHeaders, setIsImportingHeaders] = useState(false);
  const [isConnectingFields, setIsConnectingFields] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [goToNew, setGoToNew] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [trigger, setTrigger] = useState(null);

  const screenSize = useScreenSize();

  // console.log('assumedHeaderTypes', assumedHeaderTypes)
  
  // Reset reload after it is switched to true
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])
  
  // Set custom field state to incoming data on load
  useEffect(() => {
    if (incomingCustomFields && resetSearch) {
      setCustomFields(incomingCustomFields);
      setResetSearch(false);
    }
  }, [incomingCustomFields, resetSearch]);

  // Update customFields after incomingCustomFields is reloaded
  useEffect(() => {
    if (incomingCustomFields ) {
      setCustomFields(incomingCustomFields);
    }
  }, [incomingCustomFields]);

  useEffect(() => {
    if (newCustomFields.length) {
      setIsAddingField(true);
    }
  }, [newCustomFields]);

  // Handle auto navigation in Wizard
  const AutoNext = () => {
    const nextImportButton = document.getElementById('next-import-button');

    // Check if the button element exists
    if (nextImportButton) {
      setTimeout(() => {
        // Click the button
        nextImportButton.click();
      }, 1000);
    } else {
        console.error('Button with id "next-import-button" not found');
    }
    
  }

  // STEP 1: Upload csv
  const handleTrigger_Step_1 = () => {
    handleLocalUpload();
  }

  // Upload to user browser for header retrieval
  const handleLocalUpload = async () => {
    if (!csvFile) return;

    const reader = new FileReader();
    // // Get the header and first five rows of data without validating if there is data in the row cells
    // reader.onload = async (e) => {
    //   const csvText = e.target.result;
    //   const { data } = Papa.parse(csvText, { header: false });
    //   const headerData = data[0];
    //   console.log(JSON.stringify(data.splice(1,100)))
    //   const previewData = data.splice(1,5);
    //   setPreview(previewData);
    //   setHeaders(headerData);
    // };

    reader.onload = async (e) => {
      const csvText = e.target.result;
      const { data } = Papa.parse(csvText, { header: false });
      const headerData = data[0];
      // const previewData = Array.from({length:5}).fill(Array(headerData.length).fill(""));
      // Map over every item until one item has been found for each index or until there are no more items
      // Do this for each item in the previewData array
      let indexArray = Array.from({ length: headerData.length }, () => []);
      // console.log('indexArray', indexArray);

      const dataSlice = data.slice(1); // Remove header

      dataSlice.map((contact) => {
        contact.map((item, itemIndex) => {
          if (item) {
            if (indexArray[itemIndex].length < 2) {
              indexArray[itemIndex].push(item);
            }
          }
        })
      })
      // console.log('indexArray', indexArray);
      let headerTypes = [];
      headerData.forEach(header => {
        const headerType = analyzeHeaderTypes(data, header);
        headerTypes.push(headerType);
      })
      setAssumedHeaderTypes(headerTypes);
      setPreview(indexArray);
      setHeaders(headerData);
    };

    reader.readAsText(csvFile.file);
  }

  const analyzeHeaderTypes = (csvData, headerName) => {
    const headerIndex = csvData[0].indexOf(headerName);
    console.log('headerName', headerName);
    if (headerIndex === -1) {
        // Header name not found
        return {header: headerName, assumedType: "Header not found", values: null};
    }

    const columnValues = csvData.slice(1).map(row => row[headerIndex]);
    const valueCounts = {};

    // Count occurrences of each value and collect all unique values
    const uniqueValuesArray = [];
    columnValues.forEach(value => {
        if (!valueCounts.hasOwnProperty(value)) {
            valueCounts[value] = 1;
            if (value) {
              uniqueValuesArray.push(value);
            }
        } else {
            valueCounts[value]++;
        }
    });
    // console.log('uniqueValuesArray', uniqueValuesArray)

    const totalValues = columnValues.length;
    const uniqueValues = uniqueValuesArray.length;
    const valueFrequency = Object.values(valueCounts);

    // Check if the column is empty
    if (totalValues === 0) {
        return {header: headerName, assumedType: "empty", values: null};
    }

    // Check for phone numbers
    const phoneRegex = /^\+?\d{7,12}$/;
    const phoneValues = uniqueValuesArray.filter(value => value && phoneRegex.test(value));
    const phoneValuesFrequency = phoneValues.length / totalValues;
    console.log('hader', headerName.toLowerCase())
    if (phoneValuesFrequency >= 0.4 || headerName.toLowerCase().includes('phone')) {
        return {header: headerName, assumedType: "phone_number", values: uniqueValuesArray};
    }

    // Check for dates
    const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4} (0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
    const isDate = uniqueValuesArray.every(value => value && dateRegex.test(value));
    if (isDate) {
        return {header: headerName, assumedType: "date", values: uniqueValuesArray};
    }

    // Check for boolean values
    if (uniqueValues === 2 && valueCounts.hasOwnProperty("true") && valueCounts.hasOwnProperty("false")) {
        return {header: headerName, assumedType: "bool", values: uniqueValuesArray};
    }

    // Check for numbers
    const isNumber = uniqueValuesArray.every(value => !isNaN(value));
    if (isNumber) {
        return {header: headerName, assumedType: "number", values: uniqueValuesArray};
    }

    // Check for lists
    const separatorChars = [",", ";"];
    const separatorThreshold = 0.5;
    const separatorCount = uniqueValuesArray.reduce((count, value) => {
        if (separatorChars.some(char => value.includes(char))) {
            return count + 1;
        }
        return count;
    }, 0);

    if (separatorCount / uniqueValuesArray.length >= separatorThreshold) {
      const listValues = [...new Set(uniqueValuesArray.flatMap(value => {
          if (separatorChars.some(char => value.includes(char))) {
              return value.split(new RegExp(`[${separatorChars.join('\\')}]`)).map(val => val.trim()).filter(val => val !== "");
          } else {
              return value;
          }
      }))];
      return { header: headerName, assumedType: "list", values: listValues };
  }
              
    // Check for enums
    const enumThreshold = totalValues * 0.8; // 80% threshold for considering as enum
    const isEnum = valueFrequency.some(count => count >= enumThreshold);
    if (isEnum) {
        return {header: headerName, assumedType: "enum", values: uniqueValuesArray};
    }

    // Check for URLs
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    const isUrl = uniqueValuesArray.every(value => value && urlRegex.test(value));
    if (isUrl) {
        return {header: headerName, assumedType: "url", values: uniqueValuesArray};
    }

    // Default to text
    return {header: headerName, assumedType: "text", values: uniqueValuesArray};
};

  const resetCsvFile = () => {
    setCsvFile(null);
    setHeaders(null);
    setNewCustomFields([]);
    setIsConnectingFields(false);
  }

  // STEP 2: Choose fields to add
  const handleTrigger_Step_2 = () => {
    setTrigger(2);
  }

  // STEP 3: Set new field types
  const handleTrigger_Step_3 = () => {
    setTrigger(3);
  }

  // STEP 4: Confirm csv import
  const handleTrigger_Step_4 = () => {
    handleApiUpload();
  }

  // Upload to database via api for custom mapping and contact import
  const handleApiUpload = async () => {
    if (csvFile) {
      try {
        console.log('csvFile', csvFile);
        const result = await createUploadCsv(csvFile.file);

        if (result.status === 'success') {
          console.log('responseURL', result.responseURL)
          window.location.href = result.responseURL;

        } else {
          console.log('Error uploading CSV file:', result.error); 

          // setUploadStatus('Error uploading CSV file');        
        }
      } catch (error) {
        console.log('Error uploading CSV file:', error);
      }
    } else {
      console.log('Please select a file to upload');
    }
  };


  // Handle trigger state reset
  useEffect(() => {
    if (trigger) {
      setTrigger(null);
    }
  }, [trigger])


  return (
    <>
      <WizardGuide
        triggers = {{
          csvExists: csvFile ? true : false,
          step_1: headers ? true : false,
          step_2: newCustomFields.length ? true : false || isConnectingFields,
          step_3: isConnectingFields,
          step_4: goToNew
        }}
        next = {{
          step_1: handleTrigger_Step_1,
          step_2: handleTrigger_Step_2,
          step_3: handleTrigger_Step_3,
          step_4: handleTrigger_Step_4,
        }}
        step_1 = { // UPLOAD CSV FILE
          <Step1ImportCsvHeaders
            setHeaders={setHeaders}
            csvFile={csvFile}
            setCsvFile={setCsvFile}
            resetCsvFile={resetCsvFile}
          />
        }
        step_2 = { // CHOICE FIELDS TO ADD
          <Step2CustomFieldComparison
            trigger={trigger === 2}
            customFields={[ {field_name: 'Name'}, {field_name: 'Email'}, ...customFields || [] ]}
            headers={headers}
            newCustomFields={newCustomFields}
            setNewCustomFields={setNewCustomFields}
            setIsConnectingFields={setIsConnectingFields}
            assumedHeaderTypes={assumedHeaderTypes}
          />
        }
        step_3 = { // SET NEW FIELD TYPES
          <Step3CreateCustomFields
            trigger={trigger === 3}
            setIsAddingField={setIsAddingField}
            incomingNewCustomFields={newCustomFields}
            setIsConnectingFields={setIsConnectingFields}
            preview={preview}
            headers={headers}
            setReload={setReload}
          />
        }
        step_4 ={ // CONFIRM CSV IMPORT
          <Grid
            container
          >
            <Step4ConfirmCsvUpload csvFile={csvFile} />
          </Grid>
        }
      />
    </>
  );
};

export default CsvImportWizard;

const WizardGuide = (props) => {
  const { triggers, next, step_1, step_2, step_3, step_4 } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const theme = useTheme();

  // Automatically trigger step 4
  useEffect(() => {
    if (currentStep === 4) {
      setTimeout(() => {
        triggerNext();  
      }, 2500);
    }
  }, [currentStep])

  useEffect(() => {
    if (triggers.step_3) {
      setCurrentStep(4);
    } else if (triggers.step_2) {
      setCurrentStep(3);
    } else if (triggers.step_1) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }, [triggers])

  const goDirect = (pos) => {
    if (triggers[`step_${pos}`]) {
      setCurrentStep(pos);
    }
  }

  const triggerNext = () => {
    if (next[`step_${currentStep}`]) {
      next[`step_${currentStep}`]();
    } else {
      setCurrentStep(prev => prev + 1);
    }
  }

  return (
    <Grid
      container
      style={{
        padding:8
      }}
    >
      <Grid
        container
      >
        {
          [
            {label: '1', position: 1, description: 'Upload CSV file'},
            {label: '2', position: 2, description: 'Select new fields'},
            {label: '3', position: 3, description: 'Set field types'},
            {label: '4', position: 4, description: 'Build fields'},
          ].map(step => (
            <Grid
              item
              onClick={() => goDirect(step.position)}
              style={{
                position:'relative',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                padding:'16px 16px 0 16px',
                cursor: triggers[`step_${step.position}`] ? 'pointer' : 'default'
                // borderBottom: currentStep === step.position ? `4px solid ${theme.palette.primary.main}` : `2px solid #ccc`
              }}
              // xs={12}
              xs
            >
              {/* <Grid
                style={{
                  position:'absolute',
                  top:'100%',
                  width: 'calc(100% - 32px)',
                  height: currentStep === step.position ? 4 : 0,
                  background: theme.palette.primary.main,
                  transition:'.2s'
                }}
              /> */}
                {
                  triggers[`step_${step.position}`]
                    ?
                      <CheckmarkSVG width={25} color1={currentStep === step.position ? theme.palette.primary.main : '#ccc'} />
                    :
                      <Grid
                        style={{
                          width:25,
                          height:25,
                          borderRadius:25,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          background: currentStep === step.position || triggers[`step_${step.position}`] ? theme.palette.primary.main : '#bbb'
                        }}
                      >
                        <Typography
                          style={{
                            fontSize:'.75rem',
                            textTransform:'uppercase',
                            fontWeight:700,
                            color:'#fff'
                          }}
                        >
                          {step.label}
                        </Typography>
                      </Grid>
                }
              <Typography
                sx={{
                  display:{xs:'none', sm: 'inherit'},
                  fontSize:'.95rem',
                  fontWeight:600,
                  color: currentStep === step.position ? theme.palette.primary.main : '#bbb'
                  // textTransform:'uppercase',
                }}
              >
                {step.description}
              </Typography>
            </Grid>
          ))

        }
      </Grid>

      <Grid
        container
        style={{
          // border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 8,
          marginTop:24,
          padding: 24
        }}
      >
        {
          // HEADER
        }
        <Grid
          container
          style={{
            justifyContent:'space-between',
            marginBottom:16
          }}
        >
          {
            currentStep === 4
              ?
                null
              :
                <>
                  <Button
                    onClick={() => setCurrentStep(prev => prev - 1)}
                    disabled={currentStep <= 1}
                    variant="outlined"
                  >
                    Back
                  </Button>
                  <Button
                    id="next-import-button"
                    disabled={!triggers.csvExists}
                    onClick={triggerNext}
                    variant={!triggers.csvExists ? "outlined" : "contained"}
                  >
                    Next
                  </Button>
                </>
            }
          
        </Grid>
        {currentStep == 1 && step_1}
        {currentStep == 2 && step_2}
        {currentStep == 3 && step_3}
        {currentStep == 4 && step_4}
      </Grid>
    </Grid>
  )
}
