import React, { useEffect, useState } from 'react'
import { CameraSVG, CloseFilledSVG, FlipSVG } from '../assets/icons';
import { LogoHorizontal } from '../assets/logos';
import { Button, Collapse, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import FileDropZone from '../components/ui/FileDropZone';
import { createStorageFile, deleteStorageFile, getCustomFieldFolder, putTenantDataBag, putStorageFile, useGetTenantDataBag, getStorageFile, getAllStorageFileUploads, useGetPublicFolder, getAllPublicStorageFileUploads } from '../hooks/mutations';
import { ImageField } from '../components/ui/Fields';
import { useApi } from '../context/apiContext';
import { useTheme } from '@emotion/react';

const BadgeSingle = () => {
  const live = true;
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [openGallery, setOpenGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState(null)

  const dataBag = useGetTenantDataBag();
  const theme = useTheme();
  const apiContext = useApi();
  const contextTenantDataBag = apiContext.contextTenantDataBag;
  const navBadge = contextTenantDataBag?.data?.navBadge;

  const publicFolder = useGetPublicFolder({reload:null});

  // console.log('navBadge', navBadge);
  // console.log('is time exceeded', isTimeExceeded);

  // useEffect(() => {
  //   const checkTime = () => {
  //     const date = new Date(navBadge?.lastUrl);
  //     const currentTime = new Date();
  //     const fiftyMinutesInMillis = 50 * 60 * 1000;

  //     const timeDifference = currentTime - date;

  //     console.log('time difference', timeDifference);

  //     setIsTimeExceeded(timeDifference > fiftyMinutesInMillis);
  //   };

  //   // Check time on component mount
  //   checkTime();

  //   // Set an interval to check periodically, e.g., every minute
  //   const interval = setInterval(checkTime, 60 * 1000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(interval);
  // }, [navBadge?.lastUrl]);

  // // Reset isTimeExceeded flag after it is triggered in useEffect above
  // useEffect(() => {
  //   if (isTimeExceeded) {
  //     getBadgeItem();
  //     setIsTimeExceeded(false);
  //   }
  // }, [isTimeExceeded])
  
  // Create a badge image file when it does not exist yet
  const createBadgeImage = async () => {
    console.log('creating image')
    // Upload image to Public storage folder
    const storageFolderId = publicFolder.id;
    const fileName = file.file.name;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', fileName);
    formData.append('folder', storageFolderId);
    console.log('formData', formData);

    const storageFileObj = {
      folderId: storageFolderId,
      storageFilePayload: formData
    }

    // Replace the file, keeping the same file id
    const storedImage = await createStorageFile(storageFileObj);
    console.log('storedImage', storedImage);

    // If image is stored successfully then save the image url to the data bag
    if (storedImage.status === "success") {
      // Add code to save the badge image to it's location
      const dataObj = {
        id: dataBag.id,
        data: {
          data: {
            ...dataBag.data,
            navBadge: {
              url: storedImage.data.file,
              folderId: storedImage.data.folder,
              storageId: storedImage.data.id,
            }
          }
        }
      }
      const updateBadge = await putTenantDataBag(dataObj);
      console.log('updateBadge', updateBadge)
      if (updateBadge.status === 'success') {
        console.log(updateBadge.response);
        apiContext.handleSetContextTenantDataBag(updateBadge.data);
        handleClose();
      }

    }
 

  }

  // Replace a badge image with a new file
  const replaceBadgeImage = async () => {
    console.log('replacing image', file)
    // Upload image to storage
    const storageFolderId = publicFolder.id;
    const fileName = file.file.name;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', fileName);
    formData.append('folder', storageFolderId);
    console.log('formData', formData);

    const storageFileObj = {
      folderId: storageFolderId,
      fileId: contextTenantDataBag.data.navBadge.storageId,
      storageFilePayload: formData
    }

    // Replace the file, keeping the same file id
    const storedImage = await putStorageFile(storageFileObj);
    console.log('storedImage', storedImage);

    // // If image is stored successfully then save the image url to the data bag
    if (storedImage.status === "success") {
      // Add code to save the badge image to it's location
      const dataObj = {
        id: dataBag.id,
        data: {
          data: {
            ...dataBag.data,
            navBadge: {
              url: storedImage.data.file,
              folderId: storedImage.data.folder,
              storageId: storedImage.data.id,
            }
          }
        }
      }
      const updateBadge = await putTenantDataBag(dataObj);
      console.log('updateBadge', updateBadge)
      if (updateBadge.status === 'success') {
        console.log(updateBadge.response);
        apiContext.handleSetContextTenantDataBag(updateBadge.data);
        handleClose();
      }

    }
 

  }

  // Replace a badge image that already exists
  const existingBadgeImage = async (img) => {
    console.log('existing image', img)

    // Add code to save the badge image to it's location
    const dataObj = {
      id: dataBag.id,
      data: {
        data: {
          ...dataBag.data,
          navBadge: {
            url: img.file,
            folderId: img.folder,
            storageId: img.id,
          }
        }
      }
    }
    const updateBadge = await putTenantDataBag(dataObj);
    console.log('updateBadge', updateBadge)
    if (updateBadge.status === 'success') {
      console.log(updateBadge.response);
      apiContext.handleSetContextTenantDataBag(updateBadge.data);
      handleClose();
    }

  }
 
  const deleteBadgeItem = async () => {
    const storageFileObj = {
      folderId: contextTenantDataBag.data.navBadge.folderId,
      fileId: contextTenantDataBag.data.navBadge.storageId,
    }
    const deletedBadge = await deleteStorageFile(storageFileObj);
    if (deletedBadge.status === "success") {
      const dataObj = {
        id: dataBag.id,
        data: {
          data: {
            ...dataBag.data,
            navBadge: {}
          }
        }
      }

      const updateBadge = await putTenantDataBag(dataObj);
      console.log('updateBadge', updateBadge)
      if (updateBadge.status === 'success') {
        console.log(updateBadge.response);
        apiContext.handleSetContextTenantDataBag(updateBadge.data);
      }

    }
  }

  const handleOpenGallery = async () => {
    setOpenGallery(true);
    if (!galleryImages) {
      console.log('publicFolder.id', publicFolder.id)
      const gallery = await getAllPublicStorageFileUploads(publicFolder.id);
      if (gallery.status === "success") {
        setGalleryImages(gallery.data.results);
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
    setHover(false);
    setFile(null);
  }

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          width: live ? '100%' : '90px',
          height: live ? '100%' : '90px',
          aspectRatio:1/1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100px',
          background: '#eee',
          position:'relative',
          overflow:'clip',
          cursor:'pointer',
          background: hover ? '#00000030' : 'none',
          transition:'.2s',
        }}
      >
        <div
          style={{
            position:'absolute',
            top: 0,
            left:0,
            width:'100%',
            height:'100%',
            background:'#ffffff',
            zIndex:5
          }}
        />
        <div
          style={{
            position:'absolute',
            top: hover ? 'calc(100% - 30px)' : '100%',
            left:0,
            width:'100%',
            height:30,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            paddingTop:4,
            transition:'.2s',
            background:'#000000aa',
            zIndex:200 
          }}
        >
          <CameraSVG width="25%" color1="#ffffffcc" />
        </div>
        <div
          style={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'center',
            zIndex:50
          }}
        >
          {
            contextTenantDataBag?.data?.navBadge?.url
              ?
                <img
                  src={contextTenantDataBag.data.navBadge.url}
                  style={{
                    width:'70%'
                  }}
                />
              :
                <img
                  src="https://static.fillmarketing.com/static/static/img/fill-logo_500px.png"
                  style={{
                    width:'65%'
                  }}
                />
          }
          {/* <LogoHorizontal width={50} /> */}
        </div>

      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <Grid
          container
          style={{
            padding:8
          }}
        >
          <Grid
            container
          >
            <Grid
              item
              style={{
                padding:16,
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
              }}
              xs={12}
              sm={4}
            >
              {
                contextTenantDataBag?.data?.navBadge?.url
                  ?
                    <>
                      <Button
                        onClick={deleteBadgeItem}
                        style={{
                          columnGap:8,
                          marginBottom:8
                        }}
                      >
                        <CloseFilledSVG width={15} color1={theme.palette.primary.main} />
                        Remove
                      </Button>

                      <img
                        src={contextTenantDataBag.data.navBadge.url}
                        style={{
                          width:50
                        }}
                      />
                    </>
                  :
                    <img
                      src="https://static.fillmarketing.com/static/static/img/fill-logo_500px.png"
                      style={{
                        width:50
                      }}
                    />
              }
            </Grid>
            <Grid
              item
              style={{
                padding:16,
                minHeight:220
              }}
              xs={12}
              sm={8}
            >
              {
                file
                  ?
                    file.blob
                      ?
                        <div
                          style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                            minHeight:150,
                            border: '5px solid #ccc',
                          }}
                        >
                          <Button
                            onClick={() => setFile(null)}
                            style={{
                              columnGap:8,
                              marginBottom:8
                            }}
                          >
                            <FlipSVG width={15} color1={theme.palette.primary.main} />
                            Change
                          </Button>

                          <img
                            src={file.blob}
                            style={{
                              width:50
                            }}
                          />

                        </div>
                      :
                        null
                  :
                    openGallery
                      ?
                        galleryImages
                          ?
                            <Grid
                              container
                            >
                              <Divider
                                style={{
                                  width:'100%'
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize:'.85rem',
                                    fontWeight:500,
                                    color:"#aaa",
                                    display:'flex',
                                    alignItems:'center'
                                  }}
                                >
                                  GALLERY
                                  <IconButton
                                    onClick={() => setOpenGallery(false)}
                                  >
                                    <CloseFilledSVG width={14} color1="#aaa" />
                                  </IconButton>
                                </Typography>
                              </Divider>
                              {
                                galleryImages.length
                                  ?
                                    <Grid container>
                                      {
                                        galleryImages.map(img => (
                                          <ImageGallery img={img} existingBadgeImage={existingBadgeImage} />
                                        ))
                                      }
                                    </Grid>
                                  :
                                    <Grid container marginTop={6} height="100%" variant="center">
                                      <Typography>No images have been uploaded yet.</Typography></Grid>
                              }
                            </Grid>
                          :
                            <Grid height="100%" variant="center"><Typography>Loading Gallery...</Typography></Grid>
  
                      :
                        <Grid style={{textAlign:'center'}}>
                          <FileDropZone
                            setFile={setFile}
                            message="Replace current badge"
                          />
                          <Button
                            onClick={handleOpenGallery}
                          >
                            Pick From Gallery
                          </Button>
                        </Grid>
              }

            </Grid>
          </Grid>
          <Grid
            container
            style={{
              justifyContent:'space-between',
              padding:'0 16px 8px'
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={contextTenantDataBag?.data?.navBadge?.storageId ? replaceBadgeImage : createBadgeImage}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
          {/* <Collapse
            in={openGallery}
            style={{
              width:'100%'
            }}
          >
            {
              galleryImages
                ?
                  <Grid
                    container
                    gap={2}
                  >
                    <Divider
                      style={{
                        width:'100%'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:'.85rem',
                          fontWeight:500,
                          color:"#aaa",
                          display:'flex',
                          alignItems:'center'
                        }}
                      >
                        GALLERY
                        <IconButton
                          onClick={() => setOpenGallery(false)}
                        >
                          <CloseFilledSVG width={14} color1="#aaa" />
                        </IconButton>
                      </Typography>
                    </Divider>
                    {
                      galleryImages.map(img => (
                        <ImageGallery img={img} existingBadgeImage={existingBadgeImage} />
                      ))
                    }
                  </Grid>
                :
                  <Typography>Loading Gallery</Typography>
            }
          </Collapse> */}
        </Grid>
      </Dialog>
    </>
  )
}

export default BadgeSingle

const ImageGallery = (props) => {
  const { img, existingBadgeImage } = props;
  const [hover, setHover] = useState(false);
  return(
    <Grid
      item
      style={{
        padding:0
      }}
      xs
    >

      <img
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => existingBadgeImage(img)}
        src={img.file}
        style={{
          width:90,
          height:90,
          objectFit:'contain',
          borderRadius: 16,
          padding:8,
          border: `1px solid #ccc`,
          outline: hover ? '1px solid black' : '1px solid transparent',
          cursor:'pointer'
        }}
      />
    </Grid>
  )
}