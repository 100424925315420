import './policy-css.css';

export const service_policy_html = `
<body class="c21 c44 doc-content">
  <h1 class="c4 c16" id="h.tngymhpgjev4"><span class="c1 c15 c34">TERMS OF SERVICE</span></h1>
  <p class="c25 c30 c21"><span class="c1 c15 c39 c2 c43">Effective date: August 01, 2024</span></p>
  <p class="c25 c30 c21"><span class="c1 c15 c2 c39">Last updated July 05, 2024</span></p>
  <p class="c21 c5 c26"><span class="c8 c2"></span></p>
  <p class="c35 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c30 c21 c5 c35"><span class="c8 c2"></span></p>
  <div class="table-of-contents">
  <p class="c12"><span>1. </span><span class="c6"><a class="c10" href="#h.475kn095mfz6">OUR SERVICES</a></span></p>
  <p class="c12"><span>2. </span><span class="c6"><a class="c10" href="#h.zfqglv7zi2d">INTELLECTUAL PROPERTY
        RIGHTS</a></span></p>
  <p class="c12"><span>3. </span><span class="c6"><a class="c10" href="#h.k4pkmbs3yfbk">USER REPRESENTATIONS</a></span>
  </p>
  <p class="c12"><span>4. </span><span class="c6"><a class="c10" href="#h.demq6yw39rag">USER REGISTRATION</a></span></p>
  <p class="c12"><span>5. </span><span class="c6"><a class="c10" href="#h.8wdu7pdfcdnk">PURCHASES AND PAYMENT</a></span>
  </p>
  <p class="c12"><span>6. </span><span class="c6"><a class="c10" href="#h.hqn3ixkmxg4b">SUBSCRIPTIONS</a></span></p>
  <p class="c12"><span>7. </span><span class="c6"><a class="c10" href="#h.6vw6ocymg29a">PROHIBITED ACTIVITIES</a></span>
  </p>
  <p class="c12"><span>8. </span><span class="c6"><a class="c10" href="#h.apg507bz0bfz">USER GENERATED
        CONTRIBUTIONS</a></span></p>
  <p class="c12"><span>9. </span><span class="c6"><a class="c10" href="#h.y15xsyyeb050">CONTRIBUTION LICENSE</a></span>
  </p>
  <p class="c12"><span>10. </span><span class="c6"><a class="c10" href="#h.a6lq992xu2nc">THIRD-PARTY WEBSITES AND
        CONTENT</a></span></p>
  <p class="c12"><span>11. </span><span class="c6"><a class="c10" href="#h.p7ngp1rz7xrg">SERVICES MANAGEMENT</a></span>
  </p>
  <p class="c12"><span>12. </span><span class="c6"><a class="c10" href="#h.p0018o4ssku9">PRIVACY POLICY</a></span></p>
  <p class="c12"><span>13. </span><span class="c6"><a class="c10" href="#h.vlrkbil4xpw0">TERM AND TERMINATION</a></span>
  </p>
  <p class="c12"><span>14. </span><span class="c6"><a class="c10" href="#h.7109etrlsxjx">MODIFICATIONS AND
        INTERRUPTIONS</a></span></p>
  <p class="c12"><span>15. </span><span class="c6"><a class="c10" href="#h.fk1kic8np0jp">GOVERNING LAW</a></span></p>
  <p class="c12"><span>16. </span><span class="c6"><a class="c10" href="#h.xncev9rmywy3">DISPUTE RESOLUTION</a></span>
  </p>
  <p class="c12"><span>17. </span><span class="c6"><a class="c10" href="#h.s9w4of5k7fw2">CORRECTIONS</a></span></p>
  <p class="c12"><span>18. </span><span class="c6"><a class="c10" href="#h.utu6qpma6ldi">DISCLAIMER</a></span></p>
  <p class="c12"><span>19. </span><span class="c6"><a class="c10" href="#h.3g47q4h186kn">LIMITATIONS OF
        LIABILITY</a></span></p>
  <p class="c12"><span>20. </span><span class="c6"><a class="c10" href="#h.to3irj7ef2lr">INDEMNIFICATION</a></span></p>
  <p class="c12"><span>21. </span><span class="c6"><a class="c10" href="#h.bkja5kp2tcz8">USER DATA</a></span></p>
  <p class="c12"><span>22. </span><span class="c6"><a class="c10" href="#h.eaq7u1fl88fb">ELECTRONIC COMMUNICATIONS,
        TRANSACTIONS, AND SIGNATURES</a></span></p>
  <p class="c12"><span>23. </span><span class="c6"><a class="c10" href="#h.mregc8eqyq6t">CALIFORNIA USERS AND
        RESIDENTS</a></span></p>
  <p class="c12"><span>24. </span><span class="c6"><a class="c10" href="#h.o4ljh4tdrhmn">MISCELLANEOUS</a></span></p>
  <p class="c12"><span>25. </span><span class="c6"><a class="c10" href="#h.bprnw3lu4nbs">CONTACT US</a></span></p>
  </div>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c4"><span class="c13 c15 c41">AGREEMENT TO OUR LEGAL TERMS</span></h2>
  <p class="c30 c21 c5 c31"><span class="c2 c8"></span></p>
  <p class="c4"><span class="c1">We are </span><span class="c1 c2">Fill</span><span class="c1">&nbsp;(&quot;</span><span
      class="c1 c15 c2">Company</span><span class="c1">,&quot; &quot;</span><span class="c1 c15 c2">we</span><span
      class="c1">,&quot; &quot;</span><span class="c1 c15 c2">us</span><span class="c1">,&quot; &quot;</span><span
      class="c1 c15 c2">our</span><span class="c1">&quot;)</span><span class="c11 c1 c2">.</span></p>
  <p class="c31 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">We operate the website </span><span class="c2 c42"><a class="c10"
        href="https://www.google.com/url?q=https://fillmarketing.com/&amp;sa=D&amp;source=editors&amp;ust=1723421348670023&amp;usg=AOvVaw3JDAZbHclY53EyUD9KjTWJ">https://fillmarketing.com</a></span><span
      class="c1">&nbsp;(the &quot;</span><span class="c1 c2 c15">Site</span><span class="c1">&quot;), the site
      subdomains, as well as any other related products and services that refer or link to these legal terms (the
      &quot;</span><span class="c1 c15 c2">Legal Terms</span><span class="c1">&quot;) (collectively, the
      &quot;</span><span class="c1 c15 c2">Services</span><span class="c3 c1">&quot;).</span></p>
  <p class="c25 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">You can contact us by </span><span class="c11 c1 c2">email at
      fillmarketing.user@gmail.com or by mail to 100 N Howard St ste w, Spokane, WA 99201, USA, Spokane, WA 99201,
      United States.</span></p>
  <p class="c25 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">These Legal Terms constitute a legally binding agreement made between you, whether
      personally or on behalf of an entity (&quot;</span><span class="c1 c15 c2">you</span><span class="c1">&quot;), and
    </span><span class="c1 c2">Fill</span><span class="c3 c1">, concerning your access to and use of the Services. You
      agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal
      Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
      SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</span></p>
  <p class="c25 c21 c5 c30"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">We will provide you with prior notice of any scheduled changes to the Services you are
      using. The modified Legal Terms will become effective upon posting or notifying you by </span><span
      class="c1 c2">info@fillmarketing.com</span><span class="c3 c1">, as stated in the email message. By continuing to
      use the Services after the effective date of any changes, you agree to be bound by the modified terms.</span></p>
  <p class="c31 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">The Services are intended for users who are at least 18 years old. Persons under the
      age of 18 are not permitted to use or register for the Services.</span></p>
  <p class="c25 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c2">We recommend that you print a copy of these Legal Terms for your records.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.475kn095mfz6"><span class="c14 c1">1. OUR SERVICES</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c0">The information provided when using the Services is not intended for distribution to or
      use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law
      or regulation or which would subject us to any registration requirement within such jurisdiction or country.
      Accordingly, those persons who choose to access the Services from other locations do so on their own initiative
      and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</span>
  </p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c0">The Services are not tailored to comply with industry-specific regulations (Health
      Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.),
      so if your interactions would be subjected to such laws, you may not use the Services. You may not use the
      Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.zfqglv7zi2d"><span class="c14 c1">2. INTELLECTUAL PROPERTY RIGHTS</span></h2>
  <h3 class="c16 c18" id="h.ub7e4corx4qk"><span>Our intellectual property</span></h3>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We are the owner or the licensee of all intellectual property rights in our
      Services, including all source code, databases, functionality, software, website designs, audio, video, text,
      photographs, and graphics in the Services (collectively, the &quot;Content&quot;), as well as the trademarks,
      service marks, and logos contained therein (the &quot;Marks&quot;).</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">Our Content and Marks are protected by copyright and trademark laws (and various
      other intellectual property rights and unfair competition laws) and treaties in the United States and around the
      world.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">The Content and Marks are provided in or through the Services &quot;AS IS&quot; for
      your personal, non-commercial use or internal business purpose only.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.2ivc0am347bz"><span class="c24 c15">Your use of our Services</span></h3>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c19">Subject to your compliance with these Legal Terms, including the &quot;</span><span
      class="c19 c32"><a class="c10"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/terms-of-service%23prohibited&amp;sa=D&amp;source=editors&amp;ust=1723421348672965&amp;usg=AOvVaw20quOTWTffvlK7s5fYPvr8">PROHIBITED
        ACTIVITIES</a></span><span class="c0">&quot; section below, we grant you a non-exclusive, non-transferable,
      revocable license to:</span></p>
  <ul class="c36 lst-kix_2fr0013j0wmk-0 start">
    <li class="c22 li-bullet-0"><span class="c0">access the Services; and</span></li>
    <li class="c22 li-bullet-0"><span class="c0">download or print a copy of any portion of the Content to which you
        have properly gained access.</span></li>
  </ul>
  <p class="c4"><span class="c3 c1">solely for your personal, non-commercial use or internal business purpose.</span>
  </p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">Except as set out in this section or elsewhere in our Legal Terms, no part of the
      Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
      displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any
      commercial purpose whatsoever, without our express prior written permission.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">If you wish to make any use of the Services, Content, or Marks other than as set out in
      this section or elsewhere in our Legal Terms, please address your request to: </span><span
      class="c1 c2">fillmarketing.user@gmail.com</span><span class="c3 c1">. If we ever grant you the permission to
      post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or
      licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is
      visible on posting, reproducing, or displaying our Content.</span></p>
  <p class="c21 c5 c29"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We reserve all rights not expressly granted to you in and to the Services, Content,
      and Marks.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">Any breach of these Intellectual Property Rights will constitute a material breach
      of our Legal Terms and your right to use our Services will terminate immediately.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.ksa9032m1eec"><span class="c15 c24">Your submissions</span></h3>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c19">Please review this section and the &quot;</span><span class="c32 c2"><a class="c10"
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/terms-of-service%23prohibited&amp;sa=D&amp;source=editors&amp;ust=1723421348674655&amp;usg=AOvVaw2X5DDcIgTsOcC2KRMdH3zG">PROHIBITED
        ACTIVITIES</a></span><span class="c0">&quot; section carefully prior to using our Services to understand the (a)
      rights you give us and (b) obligations you have when you post or upload any content through the Services.</span>
  </p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c15 c2">Submissions:</span><span class="c0">&nbsp;By directly sending us any question,
      comment, suggestion, idea, feedback, or other information about the Services (&quot;Submissions&quot;), you agree
      to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission
      and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without
      acknowledgment or compensation to you.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c15 c2">You are responsible for what you post or upload:</span><span class="c0">&nbsp;By
      sending us Submissions through any part of the Services you:</span></p>
  <ul class="c36 lst-kix_ftc3v3mm8432-0 start">
    <li class="c22 li-bullet-0"><span class="c1 c19">confirm that you have read and agree with our &quot;</span><span
        class="c32 c19"><a class="c10"
          href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/0d8f2cba-cc70-4b6e-9421-48965aac0593/terms-of-service%23prohibited&amp;sa=D&amp;source=editors&amp;ust=1723421348675479&amp;usg=AOvVaw00UgIY5_WNC_dibsS6R6g1">PROHIBITED
          ACTIVITIES</a></span><span class="c0">&quot; and will not post, send, publish, upload, or transmit through the
        Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
        discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or
        misleading;</span></li>
    <li class="c22 li-bullet-0"><span class="c0">to the extent permissible by applicable law, waive any and all moral
        rights to any such Submission;</span></li>
    <li class="c22 li-bullet-0"><span class="c0">warrant that any such Submission are original to you or that you have
        the necessary rights and licenses to submit such Submissions and that you have full authority to grant us the
        above-mentioned rights in relation to your Submissions; and</span></li>
    <li class="c22 li-bullet-0"><span class="c0">warrant and represent that your Submissions do not constitute
        confidential information.</span></li>
  </ul>
  <p class="c4"><span class="c11 c1 c2">You are solely responsible for your Submissions and you expressly agree to
      reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third
      party&rsquo;s intellectual property rights, or (c) applicable law.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.k4pkmbs3yfbk"><span class="c14 c1">3. USER REPRESENTATIONS</span></h2>
  <p class="c29 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">By using the Services, you represent and warrant that:</span><span
      class="c1 c2">&nbsp;</span><span class="c1">(1) all registration information you submit will be true, accurate,
      current, and complete; (2) you will maintain the accuracy of such information and promptly update such
      registration information as necessary;</span><span class="c1 c2">&nbsp;</span><span class="c1">(3) you have the
      legal capacity and you agree to comply with these Legal Terms;</span><span class="c1 c2">&nbsp;</span><span
      class="c1">(4) you are not a minor in the jurisdiction in which you reside</span><span class="c1 c2">;
      (</span><span class="c3 c1">5) you will not access the Services through automated or non-human means, whether
      through a bot, script or otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and
      (7) your use of the Services will not violate any applicable law or regulation.</span></p>
  <p class="c29 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">If you provide any information that is untrue, inaccurate, not current, or
      incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of
      the Services (or any portion thereof).</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.demq6yw39rag"><span class="c14 c1">4. USER REGISTRATION</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">You may be required to register to use the Services. You agree to keep your password
      confidential and will be responsible for all use of your account and password. We reserve the right to remove,
      reclaim, or change a username you select if we determine, in our sole discretion, that such username is
      inappropriate, obscene, or otherwise objectionable.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.8wdu7pdfcdnk"><span class="c14 c1">5. PURCHASES AND PAYMENT</span></h2>
  <p class="c29 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We accept the following forms of payment:</span></p>
  <p class="c25 c30 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4 c28"><span class="c1">- &nbsp;</span><span class="c11 c1 c2">Visa</span></p>
  <p class="c4 c28"><span class="c1">- &nbsp;</span><span class="c11 c1 c2">Mastercard</span></p>
  <p class="c4 c28"><span class="c1">- &nbsp;</span><span class="c11 c1 c2">Discover</span></p>
  <p class="c4 c28"><span class="c1">- &nbsp;</span><span class="c11 c1 c2">American Express</span></p>
  <p class="c4 c28"><span class="c1">- &nbsp;</span><span class="c11 c1 c2">PayPal</span></p>
  <p class="c30 c21 c5 c37"><span class="c3 c1"></span></p>
  <p class="c4"><span class="c1">You agree to provide current, complete, and accurate purchase and account information
      for all purchases made via the Services. You further agree to promptly update account and payment information,
      including email address, payment method, and payment card expiration date, so that we can complete your
      transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by
      us. We may change prices at any time. All payments shall be </span><span class="c1 c19">in </span><span
      class="c1 c2">US dollars</span><span class="c0">.</span></p>
  <p class="c29 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">You agree to pay all charges at the prices then in effect for your purchases and any
      applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon
      placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already
      requested or received payment.</span></p>
  <p class="c29 c21 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We reserve the right to refuse any order placed through the Services. We may, in our
      sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions
      may include orders placed by or under the same customer account, the same payment method, and/or orders that use
      the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment,
      appear to be placed by dealers, resellers, or distributors.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.hqn3ixkmxg4b"><span class="c14 c1">6. SUBSCRIPTIONS</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.k08jz56esr28"><span>Billing and Renewal</span></h3>
  <p class="c4"><span class="c0">Your subscription will continue and automatically renew unless canceled. You consent to
      our charging your payment method on a recurring basis without requiring your prior approval for each recurring
      charge, until such time as you cancel the applicable order. The length of your billing cycle will depend on the
      type of subscription plan you choose when you subscribed to the Services.</span></p>
  <p class="c4 c5"><span class="c8 c19"></span></p>
  <h3 class="c18 c16" id="h.7h1wcia9ty3y"><span>Free Trial</span></h3>
  <p class="c4"><span class="c1 c19">We offer a </span><span class="c1 c2">7</span><span class="c1 c19">-day free trial
      to new users who register with the Services. </span><span class="c1 c2">The account will be charged according to
      the user&#39;s chosen subscription</span><span class="c0">&nbsp;at the end of the free trial.</span></p>
  <p class="c4 c5"><span class="c8 c19"></span></p>
  <h3 class="c18 c16" id="h.crqhegcij89p"><span>Cancellation</span></h3>
  <p class="c4"><span class="c11 c1 c2">You can cancel your subscription at any time by logging into your account. Your
      cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied
      with our Services, please email us at fillmarketing.user@gmail.com.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.9pyqqb38n2y2"><span class="c24 c15">Fee Changes</span></h3>
  <p class="c12 c21"><span class="c11 c1 c2">We may, from time to time, make changes to the subscription fee and will
      communicate any price changes to you in accordance with applicable law.</span></p>
  <p class="c4 c5"><span class="c11 c19 c38"></span></p>
  <h2 class="c7" id="h.6vw6ocymg29a"><span class="c14 c1">7. PROHIBITED ACTIVITIES</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">You may not access or use the Services for any purpose other than that for which we
      make the Services available. The Services may not be used in connection with any commercial endeavors except those
      that are specifically endorsed or approved by us.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">As a user of the Services, you agree not to:</span></p>
  <ul class="c36 lst-kix_zy1w53vpb7-0 start">
    <li class="c22 li-bullet-0"><span class="c0 c21">Systematically retrieve data or other content from the Services to
        create or compile, directly or indirectly, a collection, compilation, database, or directory without written
        permission from us.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Trick, defraud, or mislead us and other users, especially in any
        attempt to learn sensitive account information such as user passwords.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Circumvent, disable, or otherwise interfere with security-related
        features of the Services, including features that prevent or restrict the use or copying of any Content or
        enforce limitations on the use of the Services and/or the Content contained therein.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Disparage, tarnish, or otherwise harm, in our opinion, us and/or
        the Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Use any information obtained from the Services in order to
        harass, abuse, or harm another person.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Make improper use of our support services or submit false reports
        of abuse or misconduct.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Use the Services in a manner inconsistent with any applicable
        laws or regulations.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Engage in unauthorized framing of or linking to the
        Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Upload or transmit (or attempt to upload or to transmit) viruses,
        Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of
        repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the Services or
        modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance
        of the Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Engage in any automated use of the system, such as using scripts
        to send comments or messages, or using any data mining, robots, or similar data gathering and extraction
        tools.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Delete the copyright or other proprietary rights notice from any
        Content.</span></li>
    <li class="c22 li-bullet-0"><span class="c1 c2 c9">Attempt to impersonate another user or person or use the username
        of another user.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Upload or transmit (or attempt to upload or to transmit) any
        material that acts as a passive or active information collection or transmission mechanism, including without
        limitation, clear graphics interchange formats (&quot;gifs&quot;), 1&times;1 pixels, web bugs, cookies, or other
        similar devices (sometimes referred to as &quot;spyware&quot; or &quot;passive collection mechanisms&quot; or
        &quot;pcms&quot;).</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Interfere with, disrupt, or create an undue burden on the
        Services or the networks or services connected to the Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Harass, annoy, intimidate, or threaten any of our employees or
        agents engaged in providing any portion of the Services to you.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Attempt to bypass any measures of the Services designed to
        prevent or restrict access to the Services, or any portion of the Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Copy or adapt the Services&#39; software, including but not
        limited to Flash, PHP, HTML, JavaScript, or other code.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Except as permitted by applicable law, decipher, decompile,
        disassemble, or reverse engineer any of the software comprising or in any way making up a part of the
        Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Except as may be the result of standard search engine or Internet
        browser usage, use, launch, develop, or distribute any automated system, including without limitation, any
        spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any
        unauthorized script or other software.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Use a buying agent or purchasing agent to make purchases on the
        Services.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Make any unauthorized use of the Services, including collecting
        usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited
        email, or creating user accounts by automated means or under false pretenses.</span></li>
    <li class="c22 li-bullet-0"><span class="c9 c1 c2">Use the Services as part of any effort to compete with us or
        otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial
        enterprise.</span></li>
  </ul>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.apg507bz0bfz"><span class="c14 c1">8. USER GENERATED CONTRIBUTIONS</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">The Services does not offer users to submit or post content. We may provide you with
      the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and
      materials to us or on the Services, including but not limited to text, writings, video, audio, photographs,
      graphics, comments, suggestions, or personal information or other material (collectively,
      &quot;Contributions&quot;). Contributions may be viewable by other users of the Services and through third-party
      websites. When you create or make available any Contributions, you thereby represent and warrant that:</span></p>
  <p class="c30 c21 c45"><span class="c8 c2">&nbsp; &nbsp;</span></p>
  <ul class="c36 lst-kix_2ybetjr5xndj-0 start">
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">The creation, distribution, transmission, public display, or
        performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the
        proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights
        of any third party.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">You are the creator and owner of or have the necessary licenses,
        rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the
        Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">You have the written consent, release, and/or permission of each
        and every identifiable individual person in your Contributions to use the name or likeness of each and every
        such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated
        by the Services and these Legal Terms.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions are not false, inaccurate, or misleading.
      </span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions are not unsolicited or unauthorized
        advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
        solicitation.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions are not obscene, lewd, lascivious, filthy,
        violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us). </span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions do not ridicule, mock, disparage, intimidate,
        or abuse anyone.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions are not used to harass or threaten (in the
        legal sense of those terms) any other person and to promote violence against a specific person or class of
        people.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions do not violate any applicable law,
        regulation, or rule.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions do not violate the privacy or publicity
        rights of any third party.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions do not violate any applicable law concerning
        child pornography, or otherwise intended to protect the health or well-being of minors.</span></li>
    <li class="c27 li-bullet-0"><span class="c11 c1 c2">Your Contributions do not include any offensive comments that
        are connected to race, national origin, gender, sexual preference, or physical handicap.</span></li>
    <li class="c27 li-bullet-0"><span class="c1 c2 c11">Your Contributions do not otherwise violate, or link to material
        that violates, any provision of these Legal Terms, or any applicable law or regulation.</span></li>
  </ul>
  <p class="c4"><span class="c3 c1">Any use of the Services in violation of the foregoing violates these Legal Terms and
      may result in, among other things, termination or suspension of your rights to use the Services.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.y15xsyyeb050"><span class="c14 c1">9. CONTRIBUTION LICENSE</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">You and Services agree that we may access, store, process, and use any information
      and personal data that you provide and your choices (including settings).</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">By submitting suggestions or other feedback regarding the Services, you agree that
      we can use and share such feedback for any purpose without compensation to you.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We do not assert any ownership over your Contributions. You retain full ownership of
      all of your Contributions and any intellectual property rights or other proprietary rights associated with your
      Contributions. We are not liable for any statements or representations in your Contributions provided by you in
      any area on the Services. You are solely responsible for your Contributions to the Services and you expressly
      agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding
      your Contributions.</span></p>
  <p class="c4 c5"><span class="c11 c1 c2"></span></p>
  <h2 class="c7" id="h.a6lq992xu2nc"><span class="c14 c1">10. THIRD-PARTY WEBSITES AND CONTENT</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">The Services may contain (or you may be sent via the Site) links to other websites
      (&quot;Third-Party Websites&quot;) as well as articles, photographs, text, graphics, pictures, designs, music,
      sound, video, information, applications, software, and other content or items belonging to or originating from
      third parties (&quot;Third-Party Content&quot;). Such Third-Party Websites and Third-Party Content are not
      investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not
      responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on,
      available through, or installed from the Services, including the content, accuracy, offensiveness, opinions,
      reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party
      Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any
      Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and
      access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you
      should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, including
      privacy and data gathering practices, of any website to which you navigate from the Services or relating to any
      applications you use or install from the Services. Any purchases you make through Third-Party Websites will be
      through other websites and from other companies, and we take no responsibility whatsoever in relation to such
      purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do
      not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm
      caused by your purchase of such products or services. Additionally, you shall hold us blameless from any losses
      sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any
      contact with Third-Party Websites.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.p7ngp1rz7xrg"><span class="c14 c1">11. SERVICES MANAGEMENT</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c11 c1 c2">We reserve the right, but not the obligation, to: (1) monitor the Services for
      violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion,
      violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement
      authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability
      of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in
      our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable
      all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise
      manage the Services in a manner designed to protect our rights and property and to facilitate the proper
      functioning of the Services.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.p0018o4ssku9"><span class="c14 c1">12. PRIVACY POLICY</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">We care about data privacy and security. By using the Services, you agree to be bound
      by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the
      Services are hosted in the </span><span class="c1 c2">United States</span><span class="c1">. If you access the
      Services from any other region of the world with laws or other requirements governing personal data collection,
      use, or disclosure that differ from applicable laws in the </span><span class="c1 c2">United States</span><span
      class="c1">, then through your continued use of the Services, you are transferring your data to the </span><span
      class="c1 c2">United States</span><span class="c1">, and you expressly consent to have your data transferred to
      and processed in the </span><span class="c1 c2">United States</span><span class="c3 c1">.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.vlrkbil4xpw0"><span class="c14 c1">13. TERM AND TERMINATION</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">These Legal Terms shall remain in full force and effect while you use the Services.
      WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
      WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
      ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
      WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
      USE OR PARTICIPATION IN THE SERVICES OR DELETE </span><span class="c1 c2">YOUR ACCOUNT AND </span><span
      class="c3 c1">ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
      DISCRETION.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">If we terminate or suspend your account for any reason, you are prohibited from
      registering and creating a new account under your name, a fake or borrowed name, or the name of any third party,
      even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we
      reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and
      injunctive redress.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.7109etrlsxjx"><span class="c14 c1">14. MODIFICATIONS AND INTERRUPTIONS</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We reserve the right to change, modify, or remove the contents of the Services at
      any time or for any reason at our sole discretion without notice. However, we have no obligation to update any
      information on our Services. We will not be liable to you or any third party for any modification, price change,
      suspension, or discontinuance of the Services.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We cannot guarantee the Services will be available at all times. We may experience
      hardware, software, or other problems or need to perform maintenance related to the Services, resulting in
      interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or
      otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no
      liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services
      during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate
      us to maintain and support the Services or to supply any corrections, updates, or releases in connection
      therewith.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.fk1kic8np0jp"><span class="c14 c1">15. GOVERNING LAW</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">These Legal Terms and your use of the Services are governed by and construed in
      accordance with the laws of </span><span class="c1 c2">the State of Washington</span><span
      class="c1">&nbsp;applicable to agreements made and to be entirely performed within</span><span
      class="c1 c2">&nbsp;the State of Washington</span><span class="c3 c1">, without regard to its conflict of law
      principles.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.xncev9rmywy3"><span class="c14 c1">16. DISPUTE RESOLUTION</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.m2ta83oimj68"><span>Informal Negotiations</span></h3>
  <p class="c4"><span class="c1 c19">To expedite resolution and control the cost of any dispute, controversy, or claim
      related to these Legal Terms (each a &quot;Dispute&quot; and collectively, the &quot;Disputes&quot;) brought by
      either you or us (individually, a &quot;Party&quot; and collectively, the &quot;Parties&quot;), the Parties agree
      to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least
    </span><span class="c1 c2">thirty (30)</span><span class="c0">&nbsp;days before initiating arbitration. Such
      informal negotiations commence upon written notice from one Party to the other Party.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.w2ha3m7240s"><span>Binding Arbitration</span></h3>
  <p class="c4"><span class="c1 c19">If the Parties are unable to resolve a Dispute through informal negotiations, the
      Dispute (except those Disputes expressly excluded below) will be finally and exclusively resolved by binding
      arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY
      TRIAL. The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American
      Arbitration Association (&quot;AAA&quot;) and, where appropriate, the AAA&rsquo;s Supplementary Procedures for
      Consumer Related Disputes (&quot;AAA Consumer Rules&quot;), both of which are available at the </span><span
      class="c42 c2"><a class="c10"
        href="https://www.google.com/url?q=http://www.adr.org/&amp;sa=D&amp;source=editors&amp;ust=1723421348692857&amp;usg=AOvVaw2Zdt-C7rCBtaCXFPfWZnby">American
        Arbitration Association (AAA) website</a></span><span class="c1 c19">. Your arbitration fees and your share of
      arbitrator compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA
      Consumer Rules. The arbitration may be conducted in person, through the submission of documents, by phone, or
      online. The arbitrator will make a decision in writing, but need not provide a statement of reasons unless
      requested by either Party. The arbitrator must follow applicable law, and any award may be challenged if the
      arbitrator fails to do so. Except where otherwise required by the applicable AAA rules or applicable law, the
      arbitration will take place in </span><span class="c1 c2">King County</span><span class="c1 c19">, </span><span
      class="c1 c2">Washington</span><span class="c0">. Except as otherwise provided herein, the Parties may litigate in
      court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter
      judgment on the award entered by the arbitrator.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c19">If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
      shall be commenced or prosecuted in the</span><span class="c1 c2">&nbsp;</span><span class="c1 c19">&nbsp;state
      and federal courts located in</span><span class="c1 c2">&nbsp;King County</span><span class="c0">, Washington, and
      the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens
      with respect to venue and jurisdiction in such state and federal courts. Application of the United Nations
      Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act
      (UCITA) are excluded from these Legal Terms.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c19">In no event shall any Dispute brought by either Party related in any way to the
      Services be commenced more than </span><span class="c1 c2">one (1)</span><span class="c0">&nbsp;years after the
      cause of action arose. If this provision is found to be illegal or unenforceable, then neither Party will elect to
      arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such
      Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and
      the Parties agree to submit to the personal jurisdiction of that court.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.fzxxgvqpgk5y"><span class="c24 c15">Restrictions</span></h3>
  <p class="c4"><span class="c11 c1 c2">The Parties agree that any arbitration shall be limited to the Dispute between
      the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other
      proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to
      utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a
      purported representative capacity on behalf of the general public or any other persons.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h3 class="c18 c16" id="h.dxd72yisafzu"><span class="c24 c15">Exceptions to Informal Negotiations and
      Arbitration</span></h3>
  <p class="c4"><span class="c11 c1 c2">The Parties agree that the following Disputes are not subject to the above
      provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or protect,
      or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or
      arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for
      injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to
      arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such
      Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and
      the Parties agree to submit to the personal jurisdiction of that court.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.s9w4of5k7fw2"><span class="c14 c1">17. CORRECTIONS</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c11 c1 c2">There may be information on the Services that contains typographical errors,
      inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We
      reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the
      Services at any time, without prior notice.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.utu6qpma6ldi"><span class="c14 c1">18. DISCLAIMER</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR
      USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
      EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
      WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&#39; CONTENT OR THE CONTENT OF
      ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR
      ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
      NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF
      OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
      LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
      ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
      POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
      ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
      HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
      NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
      PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
      ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.3g47q4h186kn"><span class="c14 c1">19. LIMITATIONS OF LIABILITY</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c23 c1 c2">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR
      ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
      INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF
      WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span><span class="c1 c23">&nbsp;</span><span
      class="c23 c1 c2">NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
      WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT
      PAID, IF ANY, BY YOU TO US </span><span class="c23 c1">DURING THE </span><span class="c23 c1 c2">SIX
      (6)</span><span class="c23 c1">&nbsp;MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING</span><span
      class="c23 c1 c2">&nbsp;OR $500.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
      IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
      THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</span><sup><a
        href="#cmnt1" id="cmnt_ref1">[a]</a></sup></p>
  <p class="c4 c5"><span class="c11 c1 c2"></span></p>
  <p class="c4"><span class="c11 c1 c33 c2">WE DON&rsquo;T EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE
      ILLEGAL TO DO SO. IN COUNTRIES WHERE THE BELOW TYPES OF EXCLUSIONS AREN&rsquo;T ALLOWED, WE&rsquo;RE RESPONSIBLE
      TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE CARE
      AND SKILL OR OUR MATERIAL BREACH OF OUR CONTRACT WITH YOU. THIS PARAGRAPH DOESN&rsquo;T AFFECT CONSUMER RIGHTS
      THAT CAN&rsquo;T BE WAIVED OR LIMITED BY ANY CONTRACT OR AGREEMENT.</span></p>
  <p class="c4 c5"><span class="c11 c1 c33 c2"></span></p>
  <p class="c4"><span class="c11 c1 c2 c33">THE INFORMATION PRESENTED ON OR THROUGH THE SERVICES IS MADE AVAILAY,
      COMPLETENESS, OR USEFULNESS OF THE INFORMATION. ANY RELIANCE YOU BLE SOLELY FOR INFORMATIONAL PURPOSES. WE DO NOT
      CONFIRM THE ACCURACPLACE ON SUCH INFORMATION IS SOLELY AT YOUR OWN RISK.</span></p>
  <p class="c4 c5"><span class="c11 c1 c33 c2"></span></p>
  <p class="c4"><span class="c11 c1 c33 c2">IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED,
      NEITHER FILL, ITS AFFILIATES, OUR DIRECTORS, EMPLOYEES, AGENTS, OR OUR SUPPLIERS INVOLVED IN CREATING, PRODUCING,
      OR DELIVERING THE SERVICES WILL BE LIABLE, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, FOR ANY
      INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST
      SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM
      FAILURE, OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM
      THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING
      NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT FILL OR ITS SUPPLIERS HAVE BEEN
      INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY PROVIDED IN THESE TERMS IS FOUND TO HAVE
      FAILED OF ITS ESSENTIAL PURPOSE.</span></p>
  <p class="c4 c5"><span class="c11 c1 c33 c2"></span></p>
  <p class="c4"><span class="c1 c33 c2">BESIDES THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS DESCRIBED IN THIS
      SECTION), FILL LIMITS OUR LIABILITY TO YOU TO THE GREATER OF (A) THE AMOUNTS YOU HAVE PAID US IN THE THREE MONTHS
      BEFORE YOU FIRST ASSERT A CLAIM OR (B) $100 USD (OR THE EQUIVALENT IN YOUR LOCAL CURRENCY).</span><sup><a
        href="#cmnt2" id="cmnt_ref2">[b]</a></sup></p>
  <p class="c4 c5"><span class="c11 c1 c2"></span></p>
  <p class="c4 c5"><span class="c11 c1 c2"></span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.to3irj7ef2lr"><span class="c14 c1">20. INDEMNIFICATION</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
      affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss,
      damage, liability, claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third
      party due to or arising out of: </span><span class="c1 c2">(</span><span class="c1">1</span><span class="c1 c2">)
      use of the Services; (</span><span class="c1">2</span><span class="c1 c2">) breach of these Legal Terms;
      (</span><span class="c1">3</span><span class="c1 c2">) any breach of your representations and warranties set forth
      in these Legal Terms; (</span><span class="c1">4</span><span class="c1 c2">) your violation of the rights of a
      third party, including but not limited to intellectual property rights; or (</span><span class="c1">5</span><span
      class="c11 c1 c2">) any overt harmful act toward any other user of the Services with whom you connected via the
      Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense
      and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense,
      with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or
      proceeding which is subject to this indemnification upon becoming aware of it.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.bkja5kp2tcz8"><span class="c1 c14">21. USER DATA</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">We will maintain certain data that you transmit to the Services for the purpose of
      managing the performance of the Services, as well as data relating to your use of the Services. Although we
      perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates
      to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any
      loss or corruption of any such data, and you hereby waive any right of action against us arising from any such
      loss or corruption of such data.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.eaq7u1fl88fb"><span class="c14 c1">22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
      SIGNATURES</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1 c3">Visiting the Services, sending us emails, and completing online forms constitute
      electronic communications. You consent to receive electronic communications, and you agree that all agreements,
      notices, disclosures, and other communications we provide to you electronically, via email and on the Services,
      satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
      SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
      TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under
      any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original
      signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any
      means other than electronic means.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.mregc8eqyq6t"><span class="c14 c1">23. CALIFORNIA USERS AND RESIDENTS</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c3 c1">If any complaint with us is not satisfactorily resolved, you can contact the
      Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in
      writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or
      (916) 445-1254.</span></p>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <h2 class="c7" id="h.o4ljh4tdrhmn"><span class="c14 c1">24. MISCELLANEOUS</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">These Legal Terms and any policies or operating rules posted by us on the Services or
      in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to
      exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
      provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our
      rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay,
      or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of
      these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is
      deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining
      provisions. There is no joint venture, partnership, employment or agency relationship created between you and us
      as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed
      against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the
      electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal
      Terms.</span></p>
  <h2 class="c7" id="h.bprnw3lu4nbs"><span class="c14 c1">25. CONTACT US</span></h2>
  <p class="c4 c5"><span class="c8 c2"></span></p>
  <p class="c4"><span class="c1">In order to resolve a complaint regarding the Services or to receive further
      information regarding use of the Services, please contact us at fillmarketing.user@gmail.com.</span></p>
</body>
`