import React, { cloneElement, useEffect, useRef, useState } from 'react'
import { Button, ClickAwayListener, Collapse, Dialog, Divider, Grid, IconButton, LinearProgress, Menu, Stack, TextField, Typography, gridClasses } from '@mui/material';
import { useTheme } from '@emotion/react';
import ReusableModal from '../ui/ReusableModal';
import ReusableSearch from '../ui/ReusableSearch';
import { AddRoundedSVG, AddSVG, AlignJustifySVG, CheckSVG, CloseFilledSVG, ColorFillSVG, ContactSVG, CopySVG, DeleteSVG, DownloadSVG, EditSVG, EmailSVG, FilterSVG, MoreMenuSVG, MoreOptionsSVG, OpenInNewSVG, SelectSVG, TeamSVG, UploadSVG } from '../../assets/icons';
import { copyString, generateContactName, transformFields, translateFieldType } from '../../lib/Functions';
import { contactStatusEnum, initHeaderColumns } from '../../lib/Data';
import { deleteContact, getCustomFieldFolder, getStorageFile, postTag, putContact, useGetAllLists, useGetAllTags, useGetTags } from '../../hooks/mutations';
import { DataGrid } from '@mui/x-data-grid';
import { Tag, TagButton } from '../ui/Tags';
import Infotip from '../ui/Infotip';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { InputField, SelectField } from '../ui/Fields';
import ReusableContactSearch from '../ui/ReusableContactSearch';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import { DjangoComponents } from '../../templates/DjangoStyles';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import { FillContactTable } from '../ui/FillContactTable';
import TagSearchAndCreate from '../tags/TagSearchAndCreate';

const ContactsBulkTags = (props) => {
  const { setSelectedContact, contacts, headerColumns, selectedContacts, setSelectedContacts, setActionType, open, setOpen, setFocusField, setReloadContacts, filterBy, setFilterBy, customFields, lists, pages, setDestinationPageId, activeFieldLayout, rowsPerPage, setRowsPerPage } = props;
  const [currentContact, setCurrentContact] = useState(null);
  const [selected, setSelected] = useState([]);
  const [sortedContacts, setSortedContacts] = useState([]);
  const includedFields = ['name', 'primary_email', 'tags'];
  const filteredColumns = initHeaderColumns.filter(column => includedFields.includes(column.field));
  // Show the contacts passed into the component to be displayed in the table
  // console.log("ContactsBulkTags table, contacts: ", contacts);
  const displayColumns = [
    {
      field: 'name',
      label: 'Name',
      type: 'text',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/forms/${params.row.id}/edit/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'primary_email',
      label: 'Email',
      type: 'text',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'tags',
      label: 'Tags',
      type: 'tags',
      minWidth:125,
      flex:1,
      editable: false,
    },
  ]
  const [reloadTags, setReloadTags] = useState();
  const tags = useGetAllTags(reloadTags);
  const theme = useTheme();

  useEffect(() => {
    if (contacts) {
      sortContacts();
      if (selected) {
        updateSelected();
      }
      if (currentContact) {
        updateCurrentContact();
      }
      // generateHeaderColumns();
    }
  }, [contacts])

  useEffect(() => {
    if (reloadTags) {
      setReloadTags(false);
    }
  }, [reloadTags]);

  // Update dataColumns every time displayColums changes
  useEffect(() => {
    if (displayColumns) {
      dataGridDisplayColumns();
    }
  }, [displayColumns])

  // Update existing selected contacts state ('selected') with new contact data when contacts are updated
  const updateSelected = () => {
    const selectedIds = selected.map(x => x.id);
    const updated = contacts.filter(x => selectedIds.includes(x.id));
    setSelected(updated);
  }

  // Set display columns for data grid display
  const dataGridDisplayColumns = () => {
    const newDataColumns = displayColumns.map(item => {
      return ({
        field: item.field,
        headerName: item.label,
        minWidth:125,
        flex:1,
        type: item.field === 'tags' ? 'actions' : null,
        editable: false,
        renderCell: 
        (params) => {
          if (item.type === 'enum' || item.type === 'list') {
            return null;
          }
          const contact = params.row;

          return (
            <Stack
              direction="row"
              spacing={2}
            >
              {
                <TextCell
                  contactId={contact.id}
                  key={item.field}
                  field={item.field}
                  type={item.type}
                  input={item.columnType === 'custom'
                    ? contact.custom_fields?.[item.id] // We use the id in custom fields because the format is { [id]: value }
                    : typeof contact[item.field] === 'object' && Array.isArray(contact[item.field])
                      ? item.type === 'tags'
                        ? contact[item.field]
                        : contact[item.field].map(item => item.name)
                      : contact[item.field]
                  }
                  // selectedContact={selectedContact}
                  setCurrentContact={setCurrentContact}
                  displayColumns={displayColumns}
                />
              }
            </Stack>

          )

        }

      })
    })

  }

  // Update currently selected contact if contacts state changes
  const updateCurrentContact = () => {
    const newContacts = [ ...contacts ];
    const currentItem = newContacts.filter(x => x.id === currentContact.id);
    setCurrentContact(currentItem);
  }

  const handleFocus = (contact) => {
    setCurrentContact(contact);
    setSelectedContact(contact);
  }

  // Sort contacts
  const sortContacts = () => {
    const newContacts = [...contacts];
    // const sorted = newContacts.filter(x => x.status === "Make Call");
    // console.log('sorted', sorted);
    // const unsorted = newContacts.filter(x => x.status != "Make Call");
    // console.log('unsorted', unsorted);

    setSortedContacts(newContacts);

  }

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => contacts.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

  const handleRowClick = (row) => {
    handleRowSelection([row.id])
    // setCurrentContact(row);
    // setSelectedContact(row);
    // setSelectedContacts([]);
    // if (!open) setOpen(true);
  }


  // MENU ITEMS
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const openFilter = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  // CUSTOM FILTER KEYS
  const keys = Object.keys(filterBy);

  function filterKeysWithValues(obj) {
    const keysWithValues = Object.keys(obj).filter(key => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== undefined;
    });
  
    return keysWithValues;
  }

  const keysWithFilter = filterKeysWithValues(filterBy);




  return (
    <DjangoComponents card inner>
      <Grid
        style={{
          // maxHeight:'calc(100vh - 200px)',
          flex:1,
          height:'100%',
          overflowY:'clip'
        }}
      >
        {
          sortedContacts
            ?
              <FillTable
                className="fill-scroll"
                title="Contacts Bulk Tags"
                rows={sortedContacts?.length ? sortedContacts : []}
                columns={displayColumns}
                setFilterBy={setFilterBy}
                pages={pages}
                setDestinationPageId={setDestinationPageId}
                selectedRows={selected}
                setSelectedRows={setSelected}
                // onRowClick={handleRowClick}
                handleRowSelection={handleRowSelection}
                renderCell={(row, column) => (
                  <TextCell
                    contactId={row.id}
                    key={column.field}
                    field={column.field}
                    type={column.type}
                    input={column.columnType === 'custom'
                      ? row.custom_fields?.[column.id] // We use the id in custom fields because the format is { [id]: value }
                      : typeof row[column.field] === 'object' && Array.isArray(row[column.field])
                        ? column.type === 'tags'
                          ? row[column.field]
                          : row[column.field].map(column => column.name)
                        : row[column.field]
                    }
                    setReloadContacts={setReloadContacts}
                    setCurrentContact={setCurrentContact}
                    displayColumns={displayColumns}
                  />
                )}
                searchButton={
                  <ReusableFilterSearch
                    useSearch
                    isOpen
                    placeholder="Search for name or email"
                    content={contacts}
                    setContent={setSortedContacts}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    searchFields={['name', 'primary_email']}
                  />
                }
                toolbarButtons={[
                  <>
                    <CustomTooltip
                      title="Filter contacts"
                    >
                      <IconButton
                        onClick={handleClickFilter}
                      >
                        <FilterSVG width={15} color1={theme.palette.primary.main} />
                      </IconButton>
                    </CustomTooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElFilter}
                      open={openFilter}
                      onClose={handleCloseFilter}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        padding:0,
                      }}
                    >
                      <FilterMenu
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        customFields={customFields}
                        keys={keys}
                        keysWithFilter={keysWithFilter}
                      />
                    </Menu>
                  </>,
                ]}
                toolbarActions={
                  <BulkActions
                    selected={selected}
                    contacts={contacts}
                    tags={tags}
                    setReloadContacts={setReloadContacts}
                    setReloadTags={setReloadTags}
                  />
                }
                sx={{
                  ...fill_mui_scrollbar
                }}
              />
            : null
        }

      </Grid>

    </DjangoComponents>
  )
}

export default ContactsBulkTags

const TextCell = (props) => {
  const { contactId, input, type, field, onClick, header, setReloadContacts } = props;
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [file, setFile] = useState(null);
  const [openListDialog, setOpenListDialog] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [hover, setHover] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      if (input && type === 'image_file') {
        // console.log('input', input)
        try {
          const storageFolder = await getCustomFieldFolder();
          const storageFolderId = storageFolder.folderId;

          const imgObj = {
            folderId: storageFolderId,
            fileId: input
          };
    
          const file = await getStorageFile(imgObj);
          setFile(file.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      setFile(null); // cleanup function
    };
  }, [type, input]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied])

  // Tag Menu
  const handleClickTag = () => {
    setOpenTag(true);
  }

  const handleCloseTag = () => {
    setOpenTag(false);
  }

  const handleClickList = () => {
    setOpenList(true);
  }

  const handleCloseList = () => {
    setOpenList(false);
  }

  const copyText = () => {
    const copiedString = copyString(input);
    console.log('copied', copiedString);
  }

  const TextWrap = () => {
    
    switch (type) {
      case 'tags': // This is a type for the tags fields (not a custom field type)
        return (
          <Grid
            container
            onMouseEnter={handleClickTag}
            onMouseLeave={handleCloseTag}
            gap={.5}
            style={{
              alignItems:'flex-start'
            }}
          >
            {
              input?.slice(0,20)?.map(tag => (
                <Tag
                  tag={tag}
                  // removeTag={removeTag}
                  setReload={setReloadContacts}
                  // contactId={contact.id}
                  // reloadContact={reloadContact}
                  showOptions
                />
              ))
            }
            {
              input.length > 20
                ?
                  <div>
                    <TagButton
                      bgColor={theme.palette.light.light}
                      fontColor={theme.palette.mid.dark}
                      // outlined
                      // outlinedColor={contactStatusEnum.filter(x => x.status === input)[0].color}
                      onClick={handleClickTag}
                      // iconStart={<MoreOptionsSVG width={12} color1="#fff" />}
                      label={<MoreOptionsSVG width={20} color1="#000" style={{height:14}} />} //{`${input.length - 2} more`}
                    />

                    <ClickAwayListener
                      onClickAway={handleCloseTag}
                    >
                    <div style={{position:'absolute', zIndex:500}}>
                      <Collapse
                        in={openTag}
                        onKeyDown={(e) => {
                          if (e.key === "Escape") {
                            handleCloseTag()
                          }
                        }}  
                      >
                        <Grid
                          container
                          style={{
                            flexDirection:'column',
                            gap:4,
                            marginTop:4,
                            padding:16,
                            background:'#fff',
                            borderRadius:8,
                            boxShadow: `0 0 10px #00000030`
                          }}
                        >
                          {
                            input.map(tag => (
                              <Tag
                                tag={tag}
                                showOptions
                              />
                            ))
                          }
                        </Grid>
                      </Collapse>
                    </div>
                    </ClickAwayListener>
                  </div>
                :
                  null
            }
          </Grid>
      );
        return (
          <Grid
            container
            style={{
              position:'relative',
              overflow:'hidden',
              textOverflow:'ellipsis',
              whiteSpace:'nowrap',
            }}
          >
            {
              input?.length > 1
                ?
                  <Grid container style={{position:'relative'}}>
                    <Grid
                      item
                      id={`more-${contactId}`}
                      component="button"
                      onClick={() => setOpenListDialog(true)}
                      style={{
                        position:'relative',
                        border:`1px solid ${theme.palette.primary.light}`,
                        background:'#ffffff70',
                        cursor:header ? 'default' : 'pointer',
                        padding:'2px 8px',
                        margin:'1px 1px',
                        borderRadius:16
                      }}
                    >
                      <Typography
                        component="button"
                        style={{
                          textDecoration:'none',
                          outline:'none',
                          border:'none',
                          background:'none',
                          padding:0,
                          lineHeight:1,
                          fontSize:'.85rem',
                          fontWeight:500,
                          overflow:'hidden',
                          textOverflow:'ellipsis',
                          whiteSpace:'nowrap',
                          color:theme.palette.primary.main
                        }}
                      >
                        {input?.length} lists
                      </Typography>
                    </Grid>
                    <Dialog
                      id="basic-menu"
                      open={openListDialog}
                      onClose={() => setOpenListDialog(false)}
                    >
                      <Grid
                        container
                        variant="center"
                        style={{
                          padding:24
                        }}
                      >
                        <Grid
                          container
                          variant="center"
                        >
                          <Typography
                            style={{
                              fontSize:'1.3rem',
                              fontWeight:500
                            }}
                          >
                            {field}
                          </Typography>
                        </Grid>
                        {input?.map(item => (
                          <Grid
                            item
                            onDoubleClick={copyText}
                            style={{
                              border:`1px solid ${theme.palette.primary.light}`,
                              background:'#ffffff70',
                              cursor:header ? 'default' : 'pointer',
                              padding:'2px 16px',
                              margin:'4px 4px',
                              borderRadius:16
                            }}
                          >
                            <Typography
                              id={`id-${item}`}
                              style={{
                                padding:0,
                                // lineHeight:1,
                                // fontSize:'.85rem',
                                overflow:'hidden',
                                textOverflow:'ellipsis',
                                whiteSpace:'nowrap',
                                // cursor:'default'
                              }}
                            >
                              {item}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                    </Dialog>

                  </Grid>
                :
                  input?.map(item => (
                    <Grid
                      item
                      // onDoubleClick={copyText}
                      style={{
                        border:`1px solid ${theme.palette.primary.light}`,
                        background:'#ffffff70',
                        cursor:header ? 'default' : 'pointer',
                        padding:'2px 8px',
                        margin:'1px 1px',
                        borderRadius:16
                      }}
                    >
                      <Typography
                        id={`id-${item}`}
                        style={{
                          padding:0,
                          lineHeight:1,
                          fontSize:'.85rem',
                          fontWeight:500,
                          overflow:'hidden',
                          textOverflow:'ellipsis',
                          whiteSpace:'nowrap',
                          color:theme.palette.primary.main
                        }}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  ))
            }

          </Grid>
        );
      
      default:
        return (
          <Grid
            container
            style={{
              position:'relative',
              cursor:header ? 'default' : 'pointer'
            }}
          >
            <CustomTooltip
              title={input}
            >
              <Typography
                id={`id-${input}`}
                style={{
                  // padding:'16px 12px',
                  overflow:'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace:'nowrap',
                  cursor:'default'
                }}
              >
                {input}
              </Typography>
            </CustomTooltip>
              
          </Grid>
        );
    }
  }

  const HeaderWrap = () => {
    return (
      <Grid
        onDoubleClick={copyText}
        style={{
          cursor:header ? 'default' : 'pointer'
        }}
      >
        <Typography
          id={`id-${input}`}
          style={{
            // padding:'16px 12px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            color:'#fff'
          }}
        >
          {input}
        </Typography>
      </Grid>

    )
  }


  return (
    <>
      <Grid
        container
        onClick={onClick}
        style={{
          alignItems:'flex-start'
        }}
        // xs={12 / displayColumns.length}
      >
        {
          header && !type
            ? <HeaderWrap />
            : <TextWrap />

        }
      </Grid>
      
      {
        copied &&
          <Grid
            style={{
              position:'fixed',
              bottom:50,
              right:50,
              display:'flex',
              background:'#333',
              padding:'4px 16px',
              borderRadius:8,
              zIndex:5000
            }}
          >
            <CopySVG width={20} color1="#fff" color2="#aaa" />
            <Typography style={{color:'#fff', marginLeft:8}}>
              {input}
            </Typography>
          </Grid>
      }
    </>
  )
}

const BulkActions = (props) => {
  const { selected, contacts, tags, setReloadContacts, setReloadTags } = props;
  const [action, setAction] = useState(null);
  const theme = useTheme();

  const handleCancel = () => {
    setAction(null)
    setReloadContacts();
  }

  return (
    <Grid container
      style={{gap:8}}
    >
      <ActionButton
        label="Add Tags"
        icon={<CheckSVG height={16} />}
        color={theme.palette.primary.light}
        setAction={() => setAction('add-tag')}
      />
      <ActionButton
        label="Remove Tags"
        icon={<CheckSVG height={16} />}
        color={theme.palette.primary.light}
        setAction={() => setAction('remove-tag')}
      />

      <Dialog
        open={action}
        onClose={handleCancel}
      >
        {action === 'add-tag' && 
          <BulkAddTags
            selected={selected}
            contacts={contacts}
            tags={tags}
            setReloadContacts={setReloadContacts}
            setReloadTags={setReloadTags}
            handleCancel={handleCancel}
          />
        }
        {action === 'remove-tag' && 
          <BulkRemoveTags
            selected={selected}
            contacts={contacts}
            tags={tags}
            setReloadContacts={setReloadContacts}
            setReloadTags={setReloadTags}
            handleCancel={handleCancel}
          />
        }
      </Dialog>

    </Grid>
  )
}

const BulkAddTags = (props) => {
  const { selected, contacts, tags, setReloadContacts, setReloadTags, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [existingTags, setExistingTags] = useState(tags);
  const [searchBy, setSearchBy] = useState('');
  const [newTagColor, setNewTagColor] = useState('#CCCCCC');
  const [filteredTags, setFilteredTags] = useState([]);
  const [refreshFiltered, setRefreshFiltered] = useState(false);

  const theme = useTheme();

  const updateFilteredTags = () => {
    const mapSelected = new Map(selected.map(c => [c.id, c]));
    const updatedContacts = contacts.filter(c => mapSelected.has(c.id));
    const uniqueTags = new Map();
    updatedContacts.forEach(contact => {
      contact.tags.forEach(tag => {
        if (!uniqueTags.has(tag.id)) {
          uniqueTags.set(tag.id, tag);
        }
      });
    }); // filter out contacts.tags? 
    setFilteredTags(Array.from(uniqueTags.values()));
  };

  useEffect(() => {
    updateFilteredTags();
  }, [selected]);

  useEffect(() => {
    if (refreshFiltered) {
      setRefreshFiltered(false);
    }
  }, [refreshFiltered]);

  // console.log("filtered tags", filteredTags);

  const updateContacts = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const contact = selected[index];
        // console.log('contact', contact);
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            addTag(selectedTags, contact)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 250);
        });
      }

      setReloadTags(true);

      if (setReloadContacts) {
        setReloadContacts(true);
      }

    }
    
    catch (error) {
      console.log(error)
    }
  };

  // ADD TAG TO CONTACT
  const addTag = async (tags, contact) => {
    const existingTagNames = contact.tags.map(x => x.name);
    const newTagNames = tags.map(x => x.name);
    const combinedTags = [ ...new Set([ ...existingTagNames, ...newTagNames ]) ];
  
    const contactObj = {
      contactId: contact.id,
      payload: { ...contact, tags: combinedTags}
    }

    // console.log('contactObj', contactObj);
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully added new tag to contacts', updatedContact.data)
    }

  }

  // CREATE NEW TAG
  const createTag = async (e) => {
    e.stopPropagation();
    const tagObj = {
      payload: {
        name: searchBy,
        description: '',
        color: newTagColor ? newTagColor : '#cccccc',
      }
    }

    const updatedTag = await postTag(tagObj);
    if (updatedTag.status === "success") {
      console.log('successful tag addition');
      setReloadTags(true);
      setSearchBy('');
      setNewTagColor('#CCCCCC')
      }
  }

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      const newTags = selectedTags.filter(x => x.id != tag.id);
      setSelectedTags(newTags);
    } else {
      const newTags = [ ...selectedTags, tag ];
      setSelectedTags(newTags);
    }
  }

  const updateContactsTags = async () => {
    await updateContacts();
    updateFilteredTags();
  };

  // console.log("BulkAddTags, selected", selected);

  // MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openOptions = Boolean(anchorEl);
  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };



  return (
    <ActionContent
      title="Add Tag"
      description="Pick tags to add to the selected contacts"
      progress={progress}
      cancel={handleCancel}
      confirm={updateContactsTags}
      confirmCTA="Add Tags"
      disableConfirm={!tags?.length || !selectedTags.length}
      completedMessage="Finished Adding Tags"
    >
      <Grid
        container
        style={{
          gap:8,
          justifyContent:'center'
        }}
      >
        {
          tags.length
            ?
              tags.map(tag => (
                <a
                  onClick={() => handleTagClick(tag)}
                  style={{
                    cursor:'pointer'
                  }}
                >
                  <Grid
                    style={{
                      outline: selectedTags.includes(tag) ? `1px solid ${theme.palette.primary.main}` : 'none',
                      background: selectedTags.includes(tag) ? `${theme.palette.primary.light}20` : 'none',
                      padding:2,
                      borderRadius:20,
                      display:'flex'
                    }}
                  >
                    <CheckSVG width={15} color1={theme.palette.primary.main} style={{padding:'0 4px', width:selectedTags.includes(tag) ? 15 : 0, transform:selectedTags.includes(tag) ? `scale(1)` : `scale(0)`, transition:'.4s'}} />
                    <Tag
                      tag={tag}
                      // showOptions
                      setReload={setReloadTags}
                    />
                  </Grid>
                </a>
              ))
            :
              <>
                <Typography
                  style={{
                    margin:'36px 0'
                  }}
                >
                  You have not created any tags yet. Add your first tag now.
                </Typography>

                </>

        }
        <Grid
          container
          variant="center"
          style={{
            marginTop:16
          }}
        >
          <Grid
            style={{
              border:'1px solid #ccc',
              borderRadius:16
            }}
          >
            <Grid container variant="center" padding="0 4px">
              <Grid item width={20}>
                {
                  searchBy &&
                    <ColorFillSVG
                      tabIndex={0}
                      height={20}
                      color1={newTagColor}
                      onClick={(e) => {
                        handleOptionsClick(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleOptionsClick(e);
                        }
                      }}              
                    />
                }
                <Menu
                  id="tag-options-menu"
                  anchorEl={anchorEl}
                  open={openOptions}
                  onClose={handleOptionsClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}          
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  style={{
                    marginTop:10
                  }}
                >
                  <Grid
                    container
                    style={{
                      padding:'0 8px'
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom:8
                      }}
                    >
                      Change Color
                    </Typography>
                    <input
                      type="color"
                      tabIndex={0}
                      // placeholder='Field Name'
                      // label={fieldKey}
                      value={newTagColor}
                      onChange={(e) => setNewTagColor(e.target.value)}
                      style={{
                        width:'100%'
                      }}
                    />
                  </Grid>
                </Menu>

              </Grid>
              <Grid item xs>
                <TagSearchAndCreate
                  useSearch
                  isOpen
                  placeholder="Create New Tag"
                  content={tags}
                  setContent={setExistingTags}
                  searchFields={['name']}
                  searchBy={searchBy}
                  setSearchBy={setSearchBy}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      createTag(e)
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {
            searchBy &&
              <Typography
                style={{
                  textAlign:'center',
                  width:'100%',
                  marginTop:8
                }}
              >
                Press <span
                  tabIndex={0}
                  onClick={(e) => createTag(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      createTag(e)
                    }
                  }}
                  style={{
                    padding:'4px 8px',
                    fontSize:'.85rem',
                    borderRadius:8,
                    border:'2px solid #ccc',
                    cursor:'pointer'
                  }}
                >
                  ENTER
                </span> to save tag
              </Typography>
          }
        </Grid>
      </Grid>
    </ActionContent>
  )
}

const BulkRemoveTags = (props) => {
  const { selected, contacts, tags, setReloadContacts, setReloadTags, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [refreshFiltered, setRefreshFiltered] = useState(false);

  const theme = useTheme();

  const updateFilteredTags = () => {
    // const addedTags = new Map();
    // // compare 'selected' and 'contacts' 
    // // make a new array of common contacts 
    // // get tags from the common contacts from 'contacts'

    // copies selected contacts and adds id to an array
    const mapSelected = new Map(selected.map(c => [c.id, c]));

    // checks if contact from contacts list has the same id as a selected contact
    const updatedContacts = contacts.filter(c => mapSelected.has(c.id));

    // Creates an empty map
    const uniqueTags = new Map();

    // 
    updatedContacts.forEach(contact => {
      contact.tags.forEach(tag => {
        if (!uniqueTags.has(tag.id)) {
          uniqueTags.set(tag.id, tag);
        }
      });
    }); // filter out contacts.tags? 
    console.log('new set', Array.from(uniqueTags.values()))
    setFilteredTags(Array.from(uniqueTags.values()));
  };

  useEffect(() => {
    updateFilteredTags();
  }, [selected]);

  useEffect(() => {
    if (refreshFiltered) {
      setRefreshFiltered(false);
    }
  }, [refreshFiltered]);

  // console.log("filtered tags", filteredTags);
  

  const updateContacts = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const contact = selected[index];
        // console.log('contact', contact);
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            removeTag(contact)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 250);
        });
      }

      setReloadTags(true);
      setRefreshFiltered(true);

      if (setReloadContacts) {
        setReloadContacts(true);
      }

      updateFilteredTags();

    }
    
    catch (error) {
      console.log(error)
    }
  };

  // REMOVE TAG FROM CONTACT
  const removeTag = async (contact) => {
    const existingTags = [ ...contact.tags ];
    const removeTagIds = selectedTags.map(x => x.id);
    // console.log('contact', contact.name);
    // console.log('existingTags', existingTags);
    // console.log('removeTagIds', removeTagIds);
    const updatedTags = existingTags.filter(x => !removeTagIds.includes(x.id));
    // console.log('updatedTags', updatedTags);
    const updatedTagNames = updatedTags.map(x => x.name);
    // console.log('updatedTagNames', updatedTagNames);
    const contactObj = {
      contactId: contact.id,
      payload: { ...contact, tags: updatedTagNames}
    }
    const updatedContact = await putContact(contactObj);
    if (updatedContact.status === 'success') {
      console.log('successfully removed tag to contact')
    }

    setRefreshFiltered(true)
  }

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      const newTags = selectedTags.filter(x => x.id != tag.id);
      setSelectedTags(newTags);
    } else {
      const newTags = [ ...selectedTags, tag ];
      setSelectedTags(newTags);
    }
  }

  const updateContactsTags = async () => {
    await updateContacts();
    updateFilteredTags();
  };

  // console.log("BulkRemoveTags, selected", selected)

  return (
    <ActionContent
      title="Remove Tag"
      description="Pick tags to remove from the selected contacts"
      progress={progress}
      cancel={handleCancel}
      confirm={updateContactsTags}
      confirmCTA="Remove Tags"
      disableConfirm={!tags?.length || !selectedTags?.length}
      completedMessage="Finished Removing Tags"
    >
      <Grid
        container
        style={{
          gap:8,
          justifyContent:'center'
        }}
      >
        {
          filteredTags.length 
            ?
              filteredTags.map(tag => (
                <a
                  onClick={() => handleTagClick(tag)}
                  style={{
                    cursor:'pointer'
                  }}
                >
                  <Grid
                    style={{
                      outline: selectedTags.includes(tag) ? `1px solid ${theme.palette.primary.main}` : 'none',
                      background: selectedTags.includes(tag) ? `${theme.palette.primary.light}20` : 'none',
                      padding:2,
                      borderRadius:20,
                      display:'flex'
                    }}
                  >
                    <CheckSVG width={15} color1={theme.palette.primary.main} style={{padding:'0 4px', width:selectedTags.includes(tag) ? 15 : 0, transform:selectedTags.includes(tag) ? `scale(1)` : `scale(0)`, transition:'.4s'}} />
                    <Tag
                      tag={tag}
                      // showOptions
                      setReload={setReloadTags}
                    />
                  </Grid>
                </a>
              ))
            :
              <Typography
                style={{
                  margin:'36px 0'
                }}
              >
                No tag has been added to the selected contact(s).
              </Typography>


        }
      </Grid>
    </ActionContent>
  )
}

const ActionButton = (props) => {
  const { label, setAction } = props;

  return (
    <CustomTooltip
      title={label}
    >
      <Button onClick={setAction}>
        {label}
      </Button>
    </CustomTooltip>
  )
}

const ActionContent = (props) => {
  const { children, progress, title, description, cancel, confirm, confirmCTA, completedMessage, disableConfirm } = props;

  return (
    <Grid container>
      <Grid container padding={3}>
        <Typography
          style={{
            fontSize:'1.3rem',
            width:'100%',
            textAlign:'center'
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            width:'100%',
            textAlign:'center'
          }}
        >
          {description}
        </Typography>
        
        {
          progress < 100 &&
            <Grid container style={{margin:'16px 0'}}>
              {children}
            </Grid>
        }

        {
          progress < 100 && progress > 0
            ?
              <>
                <LinearProgress variant="determinate" value={progress} style={{width:'100%', height:10, borderRadius:20}} />
                <Typography
                  color="primary"
                  style={{
                    textAlign:'center',
                    width:'100%',
                    fontWeight:600,
                  }}
                >
                  {Math.floor(progress)}%
                </Typography>
              </>
            :
              progress > 0
                ?
                  <Typography
                    color="primary"
                    style={{
                      textAlign:'center',
                      width:'100%',
                      fontWeight:600,
                      margin:'36px 0'
                    }}
                  >
                    {completedMessage}
                  </Typography>
                : null

        }
      </Grid>
      <Divider style={{width:'100%', margin:'0px 0 0 0'}} />
      <Grid
        container
        style={{
          justifyContent:'space-between',
          alignItems:'center',
          padding:8
        }}
      >
        <Button
          onClick={cancel}
        >
          {progress < 100 ? 'Cancel' : 'Close'}
        </Button>
        {
          progress < 100
            ?
              <Button
                disabled={disableConfirm}
                onClick={confirm}
              >
                {confirmCTA}
              </Button>
            :
              null
        }

      </Grid>
    </Grid>

  )
}

const FilterMenu = (props) => {
  const { filterBy, setFilterBy, customFields, keys, keysWithFilter} = props;
  // console.log('filterBy', filterBy)
  const { name, email, status, tags, mailing_lists, field, search } = filterBy;
  const [filter, setFilter] = useState(filterBy);
  const [addFilter, setAddFilter] = useState(false);
  const theme = useTheme();

  // useEffect(() => {
  //   if (filter) {
  //     setFilterBy(filter)
  //   }
    
  // }, [filter])

  // const keys = Object.keys(filterBy);

  // function filterKeysWithValues(obj) {
  //   const keysWithValues = Object.keys(obj).filter(key => {
  //     const value = obj[key];
  //     if (Array.isArray(value)) {
  //       return value.length > 0;
  //     }
  //     return value !== null && value !== undefined;
  //   });
  
  //   return keysWithValues;
  // }

  // const keysWithFilter = filterKeysWithValues(filterBy);
  // console.log('keysWIthFIlter', keysWithFilter)

  const clearFilters = () => {
    setFilterBy({
      name:null,
      email:null,
      status:null,
      tags:null,
      mailing_lists: null,
      fields: [],
      search:null  
    })
  }

  // Currently onSubmit is not necessary because filterBy is automatically updated on change
  const onSubmit = () => {
    setFilterBy(filter);
  }

  return (
    <Grid
      container
      style={{
        rowGap:8,
        padding:16,
        borderRadius:8,
        minWidth:350,
        maxWidth:720
      }}
    >
      <Grid
        container
        style={{
          justifyContent:'space-between'
        }}
      >
        <Typography>Filter</Typography>
        {
          keysWithFilter.length
            ?
              <Button
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            :
              null
        }
      </Grid>
      <Divider style={{width:'100%'}} />
      {
        keysWithFilter.map(key => (
          key === 'fields'
            ?
              <FilterFieldItem
                filter={key}
                keys={keys}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                customFields={customFields}
              />
            :
              <FilterItem
                filter={key}
                keys={keys}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
              />
        ))
      }
      {
        addFilter
          ?
            <FilterItem
              keys={keys}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              setAddFilter={setAddFilter}
            />
          :
            <Grid container style={{justifyContent:'space-between'}}>
              <Button
                variant="outlined"
                onClick={() => setAddFilter(true)}
                startIcon={<AddSVG width={10} color1={theme.palette.primary.main} />}
              >
                Add Filter
              </Button>
              {/* <Button
                variant="contained"
                // color='light'
                onClick={() => setAddFilter(true)}
                startIcon={<FilterSVG width={14} color1="#fff" />}
              >
                Filter Contacts
              </Button> */}
            </Grid>
      }
    </Grid>
  )
}

const FilterItem = (props) => {
  const { filter, keys, filterBy, setFilterBy, setAddFilter } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        background:'#eee',
        padding:8,
        borderRadius:8,
        alignItems:'center'
      }}
    >
      <Grid
        item
        // order={{xs:0, sm:0}}
        style={{
          padding:'0 8px',
          minWidth:115
          // minWidth:filter ? 115 : '100%'
        }}
        xs
        sm={filter ? "auto" : true}
      >
        {
          filter
            ?
              <Typography>{filter}</Typography>
            :
              <SelectField
                value="Select"
                placeholder="Select"
                onChange={(e) => {
                  setFilterBy(prev => (
                    { 
                      ...prev, 
                      [e.target.value]: e.target.value === 'fields'
                        ? [ ...prev.fields, {name:null, value:null}] // Set empty object to trigger an "active" fields object
                        : " " 
                    }
                  ));
                  setAddFilter(false);
                }}
                options={keys.map(key => ({key: key, value: key}))}
              />
        }
      </Grid>

      <Grid
        item
        order={{xs:3, sm:2}}
        style={{
          padding:'0 8px'
        }}
        xs={12}
        sm
      >
        {
          filter &&
            <InputField
              value={filterBy[filter]}
              onChange={(e) => setFilterBy(prev => ({ ...prev, [filter]: e.target.value.trim() }) )}
            />
        }
      </Grid>

      <Grid
        item
        order={{xs:1, sm:2}}
        style={{
          width:50
        }}
      >
        <IconButton
          onClick={() => {
            if (filter) {
              if (filter === "fields") {
                setFilterBy(prev => ({ ...prev, [filter]: [] }) )
              } else {
                setFilterBy(prev => ({ ...prev, [filter]: null }) )
              }
            } else {
              setAddFilter(false)
            }
          }}
        >
          <CloseFilledSVG width={20} color1="#ccc" /> 
        </IconButton>
      </Grid>
    </Grid>
  )
}

const FilterFieldItem = (props) => {
  const { filter, keys, filterBy, setFilterBy, setAddFilter, customFields } = props;
  const theme = useTheme();
  // console.log('filter fields', filterBy.fields);
  // console.log('filter', filter);

  function arrayToObject(arr) {
    return arr.reduce((obj, item) => {
      obj[item.field_name] = item.id;
      return obj;
    }, {});
  }
  
  const allFieldKeys = customFields?.map(field => field.field_name);
  const usedFieldKeys = filterBy.fields.map(x => x.name);
  const customFieldKeys = allFieldKeys.filter(key => !usedFieldKeys.includes(key));
  const allFieldIdMaps = arrayToObject(customFields);
  // console.log('allFieldIdMaps', allFieldIdMaps);

  return (
    <Grid
      container
      style={{
        background:'#eee',
        padding:8,
        borderRadius:8,
        alignItems:'center'
      }}
    >
      <Grid
        container
        style={{
          justifyContent:'space-between',
          alignItems:'center'
        }}
      >
        <Grid
          item
          order={{xs:0, sm:0}}
          style={{
            padding:'0 8px',
            minWidth:115
            // minWidth:filter ? 115 : '100%'
          }}
          xs
          sm={filter ? "auto" : true}
        >
          {
            filter
              ?
                <Typography>{filter}</Typography>
              :
                null
          }
        </Grid>

        <Grid
          item
          order={{xs:3, sm:2}}
          style={{
            padding:'0 8px'
          }}
          xs={12}
          sm
        >
          <SelectField
            value="Select"
            placeholder="Select"
            trimValue="Select A Field"
            // helperText="Select A Field"
            // FormHelperTextProps={{
            //   style:{
            //     background:'#ffffff',
            //     margin:0,
            //     padding:'0 8px'
            //   }
            // }}
            onChange={(e) => {
              setFilterBy(prev => {
                const removedEmpty = prev.fields.filter(x => x.name);
                const updatedFields = [ 
                  ...removedEmpty,
                  {
                    name: e.target.value,
                    id:allFieldIdMaps[e.target.value],
                    value:" "
                  }
                ]
                return ({ 
                  ...prev,
                  fields: updatedFields
                })
              });
              // setAddFilter(false);
            }}
            options={customFieldKeys.map(cfk => ({key: cfk, value: cfk}))}
          />
        </Grid>

        <Grid
          item
          order={{xs:2, sm:3}}
          style={{
            width:50
          }}
        >
          <IconButton
            onClick={() => {
              if (filter) {
                if (filter === "fields") {
                  setFilterBy(prev => ({ ...prev, [filter]: [] }) )
                } else {
                  setFilterBy(prev => ({ ...prev, [filter]: null }) )
                }
              } else {
                setAddFilter(false)
              }
            }}
          >
            <CloseFilledSVG width={20} color1="#ccc" /> 
          </IconButton>
        </Grid>

      </Grid>

      {
        filterBy.fields.length
          ?
            <Grid
              item
              // order={{xs:3, sm:2}}
              style={{
                padding:'0 8px'
              }}
              xs={12}
              sm
            >
              {
                filterBy.fields?.map((field, index) => (
                  <Grid
                    id={field.name}
                    container
                    style={{
                      // margin:'8px 0',
                      alignItems:'center',
                      justifyContent:'space-between'
                    }}
                  >
                    <Divider style={{width:'100%', margin:'8px 0'}} />
                    <Grid
                      item
                      order={{xs:3, sm:2}}
                      style={{
                        padding:'0 8px'
                      }}
                      xs={12}
                      sm
                    >
                      {
                        field.name && <Typography>{field.name}</Typography>
                      }

                    </Grid>
                    {
                      field.value &&
                        <>
                          <Grid
                            item
                            order={{xs:3, sm:2}}
                            xs={12}
                            sm={6}
                          >
                            <InputField
                              value={field.value}
                              onChange={(e) => {
                                const copied = [ ...filterBy.fields ];
                                const fieldId = copied.findIndex(x => x.id === field.id);
                                copied[fieldId] = { ...copied[fieldId], value: e.target.value.trim() };
                                const removedEmpty = copied.filter(x => x.value.length);
                                const updatedFields = removedEmpty.length ? removedEmpty : [{name:null, value:''}];
                                setFilterBy(prev => ({ ...prev, fields: updatedFields }))
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            order={{xs:2, sm:3}}
                            style={{
                              width:50,
                              padding:'0 8px'
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                const updatedFields = filterBy.fields.filter(x => x.name !== field.name);
                                setFilterBy(prev => ({ ...prev, fields: updatedFields }) )
                              }}
                            >
                              <CloseFilledSVG width={20} color1="#ccc" /> 
                            </IconButton>
                          </Grid>
                        </>
                      }

                  </Grid>
                ))
              }
            </Grid>
          :
            <Typography>No fields yet</Typography>
      }
      
    </Grid>
  )
}
