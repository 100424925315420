import { Button, OutlinedInput, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { theme as staticLightTheme, dark as staticDarkTheme } from '../../theme/index';

export const H1 = (props) => {
  const { children, contrast, sxProps, ...options } = props;
  const theme = useTheme();

  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: {
          xs: '2rem',
          sm: '3.5rem',
          md: '4.25rem',
          lg: '5.5rem'
        },
        whiteSpace:'pre-line',
        // color: contrast
        //   ? theme.palette.mode === 'light' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        //   : theme.typography.h1.color //theme.palette.mode === 'dark' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        // ,
        ...sxProps
      }}
      {...options}
    >
      {children}
    </Typography>
  )
}

export const H1Small = (props) => {
  const { children, contrast, sxProps, ...options } = props;
  const theme = useTheme();

  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: {
          xs: '1.7rem',
          sm: '2em',
          md: '2.25rem',
          lg: '2.5rem'
        },
        fontWeight:600,
        whiteSpace:'pre-line',
        // color: contrast
        //   ? theme.palette.mode === 'light' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        //   : theme.typography.h1.color //theme.palette.mode === 'dark' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        // ,
        ...sxProps
      }}
      {...options}
    >
      {children}
    </Typography>
  )
}

export const H2Small = (props) => {
  const { children, contrast, sxProps, ...options } = props;
  const theme = useTheme();

  return (
    <Typography
      variant="h2"
      sx={{
        fontSize: {
          xs: '1.5rem',
          sm: '1.75em',
          md: '2rem',
          lg: '2.25rem'
        },
        fontWeight:600,
        whiteSpace:'pre-line',
        // color: contrast
        //   ? theme.palette.mode === 'light' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        //   : theme.typography.h1.color //theme.palette.mode === 'dark' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        // ,
        ...sxProps
      }}
      {...options}
    >
      {children}
    </Typography>
  )
}

export const P1 = (props) => {
  const { children, contrast, sxProps, ...options } = props;
  const theme = useTheme();

  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: {
          xs: '1.07rem',
          md: '1.07rem',
          lg: '1.2rem'
        },
        fontWeight:400,
        lineHeight:1.3,
        whiteSpace:'pre-line',
        // color: contrast
        //   ? theme.palette.mode === 'light' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        //   : theme.typography.h1.color //theme.palette.mode === 'dark' ? staticDarkTheme.typography.h1.color : staticLightTheme.typography.h1.color
        // ,
        ...sxProps
      }}
      {...options}
    >
      {children}
    </Typography>
  )
}

