import React from 'react'
import TermsTemplate from './TermsTemplate'
import { termsOfService } from './TermsOfService'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { CenterColumn } from '../landing/Landing'

const Terms = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary,
        paddingTop:70
      }}
    >
      <Grid
        id="features"
        container
      >
        <CenterColumn
          alignment={{x:'flex-start'}}
        >
          <Typography
            sx={{
              fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
              fontWeight:700,
              lineHeight:1,
              // textTransform:'uppercase',
              color:theme.palette.primary.dark, //'#fff'
              transition:'.4s',
              zIndex:2
            }}
          >
            Terms & Policies
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '1.4rem', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
              lineHeight:1.2,
              margin:'16px 0',
              transition:'.4s',
            }}
          >
            All the legal stuff we need to keep you happy.
          </Typography>
      <Grid
        sx={{
          marginTop:12,
          padding:'0 16px',
          justifyContent:'center',
          alignItems:'flex-start',
          width:700,
          minHeight:'calc(100vh - 96px)' // calculating 120px for footer and 96px for margin top
        }}
      >
        {
          policies.map((policy, index) => (
            <TermItem policy={policy} index={index} />
          ))
        }
      </Grid>
        </CenterColumn>
      </Grid>
    </Grid>
  )
}

export default Terms

const policies = [
  {
    title: 'Service',
    url: '/terms/service/',
    description: 'The Terms of Service outline the rules and guidelines you agree to follow when using our website. They cover important topics such as your responsibilities, acceptable use, and the rights we have regarding the content and functionality of the site.',
    updated: 'July 5, 2024'
  },
  {
    title: 'Privacy',
    url: '/terms/privacy/',
    description: 'Our Privacy Policy explains how we collect, use, and protect your personal information. It details what data we gather, how we use it, and the measures we take to ensure your privacy is safeguarded.',
    updated: 'July 5, 2024'
  },
  {
    title: 'Cookies',
    url: '/cookies/',
    description: 'Our Cookie Policy details how we use cookies on our website to enhance your browsing experience. It explains the types of cookies we use, why we use them, and how you can manage your cookie preferences.',
    updated: 'July 5, 2024'
  },
]

const TermItem = (props) => {
  const { policy, index } = props;
  const theme = useTheme();

  return (
    <Grid container sx={{mb:4}}>
      <a href={policy.url} style={{textDecoration:'none', color:'inherit'}}>
        <Typography
          style={{
            color: theme.palette.primary.main,
            fontSize:'1.4rem',
            fontWeight:600
          }}
        >
          {/* <span style={{color:'#000', marginRight:12}}>{index+1}.</span>  */}
          {policy.title}
        </Typography>
      </a>
      <Grid container>
        <Typography>
          {policy.description}
        </Typography>
      </Grid>
    </Grid>
  )
}