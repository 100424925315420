import { Button } from "@mui/material";

export const ButtonCustom = (props) => {
  const { iconStart, iconEnd, text, style, ...other } = props;

  return (
    <Button
      variant="contained"
      style={{
        columnGap:12,
        textTransform:'capitalize',
        fontWeight:600,
        letterSpacing:1.1,
        ...style
      }}
      {...other}
    >
      {iconStart}
      {text}
      {iconEnd}
    </Button>

  )
}