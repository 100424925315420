import React, { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  CircularProgress,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import { CloseRoundedSVG, CopySVG, DeleteSVG, ShareSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';
import { copyString } from '../../lib/Functions';
import SnackbarWrapper from './SnackbarWrapper';

function a11yProps(index) {
  return {
    id: `share-${index}`,
    'aria-controls': `share-${index}`,
  };
}


const DialogShare = (props) => {
  const { open, onClose, header, title, messages, link, embed, sharing, icons, cancel, confirm } = props;
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [snackMessage, setSnackMessage] = useState(null);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const copyShareLink = () => {
    copyString(link);
    setSnackMessage({open: true, message: 'Share link copied', severity: 'success'});
  }

  const copyShareEmbed = () => {
    copyString(embed);
    setSnackMessage({open: true, message: 'Embed code copied', severity: 'success'});
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
        width:'100%',
      }}
      PaperProps={{
        style:{
          width:'100%',
          overflow:'visible',
          borderRadius:16
        }
      }}
    >
      <Grid
        container
        style={{
          position:'relative',
          padding:32,
          // paddingTop:16,
          width:'100%',
          flexDirection:'column',
          alignItems:'center'
        }}
      >
        <Grid
          style={{
            position:'absolute',
            top:-25,
            display:'flex',
            gap:8,
            alignItems:'center',
            background:'#fff',
            padding:'4px 16px 20px 16px',
            borderRadius:8
          }}
        >
          <ShareSVG width={16} color1={theme.palette.primary.main} />
          <Typography
            style={{
              textTransform:'uppercase',
              fontSize:'.9rem',
              fontWeight:700,
              color: theme.palette.primary.main
            }}
          >
            {header}
          </Typography>
        </Grid>
        <Typography
          style={{
            fontSize:'1.3rem',
            marginBottom:8
          }}
        >
          {title}
        </Typography>
        {
          link && !embed
            ?
              <>
                <Typography
                  style={{
                    marginBottom:8,
                    overflowWrap:'anywhere',
                    flexWrap:'wrap',
                  }}
                >
                  {link}
                </Typography>

                <Grid
                  container
                  style={{
                    marginTop:24,
                    justifyContent:'space-around'
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={icons ? (icons.cancel ? icons.cancel :null) : null}
                    onClick={cancel ? cancel : onClose}
                    style={{
                      minWidth:120
                    }}
                  >
                    {messages.cancel ? messages.cancel : 'CLose'}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={icons ? (sharing ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : icons.confirm ? icons.confirm : null) : null}
                    onClick={copyShareLink}
                    style={{
                      minWidth:120
                    }}
                  >
                    Copy
                  </Button>
                </Grid>
              </>
            : null
              // <>
              //   <Typography
              //     style={{
              //       marginBottom:8
              //     }}
              //   >
              //     {messages.one}
              //   </Typography>
              //   <Typography

              //   >
              //     {messages.two}
              //   </Typography>

              //   <Grid
              //     container
              //     style={{
              //       marginTop:24,
              //       justifyContent:'space-around'
              //     }}
              //   >
              //     <Button
              //       color="primary"
              //       variant="outlined"
              //       startIcon={icons ? (icons.cancel ? icons.cancel :null) : null}
              //       onClick={cancel ? cancel : onClose}
              //       style={{
              //         minWidth:120
              //       }}
              //     >
              //       {messages.cancel ? messages.cancel : 'Nevermind'}
              //     </Button>
              //     <Button
              //       color="primary"
              //       variant="contained"
              //       startIcon={icons ? (sharing ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : icons.confirm ? icons.confirm : null) : null}
              //       onClick={confirm}
              //       style={{
              //         minWidth:120
              //       }}
              //     >
              //       {sharing ? (messages.inAction ? messages.inAction : 'Deleting') : (messages.confirm ? messages.confirm : 'Delete')}
              //     </Button>
              //   </Grid>
              // </>
        }
        {
          !link && embed
            ?
              <>
                <Typography
                  style={{
                    marginBottom:8,
                    overflowWrap:'anywhere',
                    flexWrap:'wrap',
                  }}
                >
                  {embed}
                </Typography>

                <Grid
                  container
                  style={{
                    marginTop:24,
                    justifyContent:'space-around'
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={icons ? (icons.cancel ? icons.cancel :null) : null}
                    onClick={cancel ? cancel : onClose}
                    style={{
                      minWidth:120
                    }}
                  >
                    {messages.cancel ? messages.cancel : 'CLose'}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={icons ? (sharing ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : icons.confirm ? icons.confirm : null) : null}
                    onClick={copyShareLink}
                    style={{
                      minWidth:120
                    }}
                  >
                    Copy
                  </Button>
                </Grid>
              </>
            : null
        }
        {
          link && embed
            ?
              <Grid
                container
                style={{
                  width:'100%'
                }}
              >
                <Grid
                  container
                  style={{
                    justifyContent:'center',
                    width:'100%'
                  }}
                >
                  <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Link URL" {...a11yProps(0)} />
                    <Tab label="Embed Code" {...a11yProps(1)} />
                  </Tabs>
                </Grid>
                <Grid
                  container
                  style={{
                    marginTop:16
                  }}
                >
                  {
                    tab === 0 &&
                      <div style={{position:'relative'}}>
                        <IconButton
                          onClick={copyShareLink}
                          style={{
                            position:'absolute',
                            top:0,
                            right:0
                          }}
                        >
                          <CopySVG width={20} color1={theme.palette.primary.main} color2={theme.palette.primary.light} color3={theme.palette.primary.light} />
                        </IconButton>
                        <Typography
                          style={{
                            marginBottom:8,
                            overflowWrap:'anywhere',
                            flexWrap:'wrap',
                            padding:36
                          }}
                        >
                          {link}
                        </Typography>
                      </div>
                  }
                  {
                    tab === 1 &&
                      <div style={{position:'relative'}}>
                        <IconButton
                          onClick={copyShareEmbed}
                          style={{
                            position:'absolute',
                            top:0,
                            right:0
                          }}
                        >
                          <CopySVG width={20} color1={theme.palette.primary.main} color2={theme.palette.primary.light} color3={theme.palette.primary.light} />
                        </IconButton>
                        <Typography
                          style={{
                            marginBottom:8,
                            overflowWrap:'anywhere',
                            flexWrap:'wrap',
                            padding:36
                          }}
                        >
                          {embed}
                        </Typography>
                      </div>
                  }
                </Grid>
              </Grid>
            : null
        }

      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
    </Dialog>
  )
}

export default DialogShare