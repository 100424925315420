import React, { cloneElement, useEffect, useState } from 'react'
import { AccountSVG, ActionSVG, ArticleSVG, Contact2SVG, ContactSVG, Email2SVG, FeedbackOutlineSVG, MessagingSVG, MoreMenuSVG, QuestionFillSVG, SendSVG, TemplatesdSVG } from '../../assets/icons'
import { useTheme } from '@emotion/react';
import { Header2 } from '../../assets/graphics';
import { Typography, Menu, MenuItem } from '@mui/material';
import BadgeSingle from '../../singles/BadgeSingle';
import SettingsDrawer from '../settings/SettingsDrawer';
import FeedbackPopout from '../feedback/FeedbackPopout';
import PreferencesModal from '../settings/PreferencesModal';
import { useTemp } from '../../context/tempContext';

const SideMenu = () => {
  const collapsedState = JSON.parse(window.localStorage.getItem('nav-collapsed'));
  const tempContext = useTemp();
  const [collapsed, setCollapsed] = useState(collapsedState ? collapsedState : false);
  const [active, setActive] = useState(null);
  const theme = useTheme();
  // Get active nav from localstorage
  useEffect(() => {
    const navLocation = window.localStorage.getItem('nav-location');
    urlNavLocation();
    // if (navLocation) {
    //   setActive(navLocation);
    // }
  }, [])

  // Collapsed Events
  const handleCollapse = () => {
    if (collapsed) {
      setCollapsed(false);
      window.localStorage.setItem('nav-collapsed', false);
      tempContext.setIsNavCollapsed(false);
    } else {
      setCollapsed(true);
      window.localStorage.setItem('nav-collapsed', true);
      tempContext.setIsNavCollapsed(true);
    }
    
  }

  // Generate nav location from URL
  const urlNavLocation = () => {
    const urlPath = window.location.pathname.split('/')[1];
    setActive(urlPath);
  }

  // Account Menu
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);
  const handleMenuClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };

  // Listen for account menu button click
  useEffect(() => {
    const menuButton = document.getElementById('account-button');

    if (menuButton) {
        menuButton.addEventListener('click', handleMenuClick);
    }

    return () => {
        if (menuButton) {
            menuButton.removeEventListener('click', handleMenuClick);
        }
    };
  }, []); // Empty dependency array to run the effect only once



  return (
    <div
      id="side-nav-menu"
      style={{
        height:'100vh',
        width: collapsed ? 80 : 250,
        position:'sticky',
        top:0,
        background:'#fff',
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        zIndex:100,
        boxShadow:'3px 0 10px #00000030',
        zIndex:500,
        transition:'.2s',
        paddingBottom:16
      }}
    >
      <div
        style={{
          position:'absolute',
          top:10,
          left: collapsed ? 10 : 25,
          width: collapsed ? '70%' : '40%',
          zIndex:10,
          transition:'.2s'
        }}
      >
        <BadgeSingle />
      </div>
      <div
        style={{
          position:'absolute',
          top: collapsed ? 0 : 12,
          right: collapsed ? -36 : 12,
          zIndex:10,
          transition:'.2s',
          display:'flex',
          transition:'.2s'
        }}
      >
        <a
          onClick={handleCollapse}
          style={{
            width:40,
            height: collapsed ? 64 : 40,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer',
            background: collapsed ? theme.palette.primary.main : 'none',
            borderRadius: '0 24px 24px 0',
            transition:'.2s'
          }}
        >
          <MoreMenuSVG width={16} color1="#fff"  />
        </a>
      </div>
      <div
        id='absolute-image'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width:'100%',
          fill: theme.palette.primary.main,
          zIndex: 1,
        }}
      >
        <div
          style={{
            height:'64px',
            background:theme.palette.primary.main
          }}
        />
        <Header2 width="100%" height="100%" style={{height:"60px", transition:'.4s'}} />
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          justifyContent:'space-between',
          marginTop:148,
          height:'100%',
          overflow:'clip'
        }}
      >
        <div>
          {
            menu.map(item => (
              <NavItem
                key={item.url}
                item={item}
                collapsed={collapsed}
                active={active}
                setActive={setActive}
              />
            ))
          }
        </div>
        <div>
          {
            settings.map(item => (
              <SettingsItem
                key={item.id}
                item={item}
                collapsed={collapsed}
                active={active}
                setActive={setActive}
              />
            ))
          }
        </div>
      </div>
      {/* <SettingsDrawer /> */}
      <PreferencesModal />
      <FeedbackPopout />
      <Menu
        id="basic-menu"
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => window.location.href = '/core/settings/'}
        >
          Account Settings
        </MenuItem>
        <MenuItem 
          onClick={() => window.location.href = '/accounts/logout/'}
        >
          Logout
        </MenuItem>
      </Menu>

    </div>
  )
}

export default SideMenu

const NavItem = (props) => {
  const { item, collapsed, active, setActive } = props;
  const [hover, setHover] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);
  const theme = useTheme();

  const current = active === item.url ? true : false;

  const setActiveNav = (e) => {
    window.localStorage.setItem('nav-location', item.url);
    window.location.href = e.target.href; // `/${item.url}/`;
  }

  return (
    <a
      href={`/${item.url}/`}
      onClick={setActiveNav}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        color: current ? theme.palette.primary.dark : ( hover ? '#333' : '#AAA'),
        background: current ? `${theme.palette.primary.light}1A` : 'none',
        borderLeft: current ? `8px solid ${theme.palette.primary.dark}` : '8px solid transparent',
        textDecoration: 'none',
        display:'flex',
        alignItems:'center',
        gap:20,
        height:41,
        padding:'8px 0 8px 24px',
        cursor: 'pointer'
      }}
    >
      {cloneElement(item.icon, { width: 16, color1: current ? theme.palette.primary.dark : '#777' } )}
      <Typography
        style={{
          fontWeight: current ? 600 : 500,
          color: current ? theme.palette.primary.dark : ( hover ? '#000000B3' : '#00000080' ),
          transform: collapsed ? 'translate(12px, 0)' : 'translate(0)',
          whiteSpace:'nowrap',
          transition:'.2s'
        }}
      >
        {item.label}
      </Typography>
    </a>
  )
}

const SettingsItem = (props) => {
  const { item, collapsed, active, setActive } = props;
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const current = active === item.url ? true : false;

  return (
    <a
      id={item.id}
      // onClick={() => setActive(item.event)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        color: current ? theme.palette.primary.dark : ( hover ? '#333' : '#AAA'),
        background: current ? `${theme.palette.primary.light}1A` : 'none',
        borderLeft: current ? `8px solid ${theme.palette.primary.dark}` : '8px solid transparent',
        textDecoration: 'none',
        display:'flex',
        alignItems:'center',
        gap:20,
        height:41,
        padding:'8px 0 8px 24px',
        cursor:'pointer'
      }}
    >
      {cloneElement(item.icon, { width: 16, color1: current ? theme.palette.primary.dark : '#777' } )}
      <Typography
        style={{
          fontWeight: current ? 600 : 500,
          color: current ? theme.palette.primary.dark : ( hover ? '#000000B3' : '#00000080' ),
          transform: collapsed ? 'translate(12px, 0)' : 'translate(0)',
          transition:'.2s'
        }}
      >
        {item.label}
      </Typography>
    </a>
  )
}

const menu = [
  // {
  //   label: 'CRM',
  //   url: 'crm',
  //   icon: <ActionSVG />
  // },
  {
    label: 'CRM', // Previously Contacts
    url: 'contacts',
    icon: <Contact2SVG />
  },
  {
    label: 'Campaigns',
    url: 'campaigns',
    icon: <SendSVG />
  },
  {
    label: 'Mailing Lists',
    url: 'lists',
    icon: <Email2SVG />
  },
  {
    label: 'Templates',
    url: 'templates',
    icon: <TemplatesdSVG />
  },
  {
    label: 'Forms',
    url: 'forms',
    icon: <ArticleSVG />
  },
  {
    label: 'Surveys',
    url: 'surveys',
    icon: <QuestionFillSVG />
  },
]

const settings = [
  {
    label: 'Feedback',
    id: 'feedback-button',
    event: '',
    icon: <MessagingSVG />    
  },
  {
    label: 'Preferences',
    id: 'settings-button',
    event: '',
    icon: <AccountSVG />    
  },
  {
    label: 'Account',
    id: 'account-button',
    event: '',
    icon: <ContactSVG />    
  },
]