export const mockData = {
  'contacts': {
    'contacts': [
      {
        "id": 2,
        "tags": "string-t2,another1",
        "tenant_id": 1,
        "uuid": "878eddc1-1fe0-4b71-ba05-6fc328964948",
        "open_rate": 0,
        "click_rate": 0,
        "update_date": "2023-10-15T20:25:57.925000Z",
        "status": 1,
        "optin_ip_address": null,
        "optin_date": "2023-11-20T13:05:16.435260Z",
        "confirm_ip_address": null,
        "confirm_date": null,
        "last_seen_ip_address": null,
        "last_seen_date": null,
        "last_sent": null,
        "salutation": "MR",
        "first_name": "string",
        "last_name": "string",
        "date_of_birth": "2023-10-15",
        "organization": "string",
        "title": "string",
        "primary_email": "user3d1f9d192@example.com",
        "secondary_email": "",
        "mobile_number": null,
        "secondary_number": null,
        "department": "string",
        "language": "string",
        "do_not_call": true,
        "description": "string",
        "linked_in_url": null,
        "facebook_url": null,
        "twitter_username": null,
        "created_by": null,
        "created_on": "2023-11-20T13:05:16.435804Z",
        "is_active": true,
        "country": null,
        "custom_fields": [
          {
            "id": 0,
            "tenant_id": 0,
            "data_type": "text",
            "validation_rule": "not_null",
            "validation_value": "string",
            "field_name": "display_name"
          },
          {
            "id": 1,
            "tenant_id": 0,
            "data_type": "bool",
            "validation_rule": "not_null",
            "validation_value": true,
            "field_name": "called"
          },
          {
            "id": 2,
            "tenant_id": 0,
            "data_type": "date",
            "validation_rule": "not_null",
            "validation_value": "2023-11-20T13:05:16.435260Z",
            "field_name": "last-contact"
          },
          {
            "id": 3,
            "tenant_id": 0,
            "data_type": "number",
            "validation_rule": "not_null",
            "validation_value": 4,
            "field_name": "contact attempts"
          }
        ],
        "domain": null,
        "mailing_list": null,
        "location": null,
        "address": null
      },
      {
        "id": 1,
        "tags": "",
        "tenant_id": 1,
        "uuid": "c480826e-1876-4547-83c6-8469cf487957",
        "open_rate": 0,
        "click_rate": 0,
        "update_date": "2023-10-25T01:57:40Z",
        "status": 2,
        "optin_ip_address": null,
        "optin_date": "2023-09-20T00:00:00Z",
        "confirm_ip_address": null,
        "confirm_date": null,
        "last_seen_ip_address": null,
        "last_seen_date": null,
        "last_sent": null,
        "salutation": "MR",
        "first_name": "Foo",
        "last_name": "Bar",
        "date_of_birth": null,
        "organization": null,
        "title": "",
        "primary_email": "foobar@example.com",
        "secondary_email": "",
        "mobile_number": null,
        "secondary_number": null,
        "department": null,
        "language": null,
        "do_not_call": false,
        "description": "",
        "linked_in_url": null,
        "facebook_url": null,
        "twitter_username": null,
        "created_by": null,
        "created_on": "2023-10-25T01:57:40.533765Z",
        "is_active": false,
        "country": null,
        "custom_fields": [
          {
            "id": 0,
            "tenant_id": 0,
            "data_type": "text",
            "validation_rule": "not_null",
            "validation_value": "string",
            "field_name": "nickname"
          },
          {
            "id": 1,
            "tenant_id": 0,
            "data_type": "bool",
            "validation_rule": "not_null",
            "validation_value": false,
            "field_name": "called"
          },
          {
            "id": 2,
            "tenant_id": 0,
            "data_type": "date",
            "validation_rule": "not_null",
            "validation_value": "2023-11-20T13:05:16.435260Z",
            "field_name": "last-contact"
          }
        ],
        "domain": 1,
        "mailing_list": 1,
        "location": null,
        "address": null
      }
    ],
    'contacts/{id}': {
      "id": 0,
      "tags": [
        "string"
      ],
      "tenant_id": 0,
      "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "open_rate": 0,
      "click_rate": 0,
      "update_date": "2023-11-20T20:17:33.807Z",
      "status": 1,
      "optin_ip_address": "string",
      "optin_date": "2023-11-20T20:17:33.808Z",
      "confirm_ip_address": "string",
      "confirm_date": "2023-11-20T20:17:33.808Z",
      "last_seen_ip_address": "string",
      "last_seen_date": "2023-11-20T20:17:33.808Z",
      "last_sent": "2023-11-20T20:17:33.808Z",
      "salutation": "MR",
      "first_name": "string",
      "last_name": "string",
      "date_of_birth": "2023-11-20",
      "organization": "string",
      "title": "string",
      "primary_email": "user@example.com",
      "secondary_email": "user@example.com",
      "mobile_number": "string",
      "secondary_number": "string",
      "department": "string",
      "language": "string",
      "do_not_call": true,
      "description": "string",
      "linked_in_url": "string",
      "facebook_url": "string",
      "twitter_username": "string",
      "created_by": 0,
      "created_on": "2023-11-20T20:17:33.808Z",
      "is_active": true,
      "country": "GB",
      "custom_fields": {
        "additionalProp1": "string",
        "additionalProp2": "string",
        "additionalProp3": "string"
      },
      "domain": 0,
      "mailing_list": 0,
      "location": 0,
      "address": 0
    },
    'custom-fields': [
      {
        "id": 1,
        "tenant_id": 1,
        "data_type": "text",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "First Name"
    },
    {
        "id": 2,
        "tenant_id": 1,
        "data_type": "text",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "Last Name"
    },
    {
        "id": 3,
        "tenant_id": 1,
        "data_type": "date",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "Birthday"
    }
    ],
    'custom-fields/{id}': {
        "id": 1,
        "tenant_id": 1,
        "data_type": "text",
        "validation_rule": "not_null",
        "validation_value": "string",
        "field_name": "First Name"
    },
  },
  'surveys': {
    'surveys': [
      {
        "id": 0,
        "category_set": [
          {
            "id": 0,
            "question_set": [
              {
                "id": 0,
                "tenant_id": 0,
                "text": "string",
                "order": 0,
                "required": true,
                "type": "text",
                "choices": "string"
              }
            ],
            "tenant_id": 0,
            "name": "string",
            "order": 0,
            "description": "string"
          }
        ],
        "name": "string",
        "description": "string",
        "need_logged_user": true,
        "editable_answers": true,
        "display_method": 1,
        "expire_date": "2023-11-20",
        "redirect_url": "string",
        "created_by_user": 0,
        "status": 1,
        "create_date": "2023-11-20T20:21:28.656Z",
        "update_date": "2023-11-20T20:21:28.656Z"
      }
    ],
    'surveys/{id}': {
      "id": 0,
      "category_set": [
        {
          "id": 0,
          "question_set": [
            {
              "id": 0,
              "tenant_id": 0,
              "text": "string",
              "order": 0,
              "required": true,
              "type": "text",
              "choices": "string"
            }
          ],
          "tenant_id": 0,
          "name": "string",
          "order": 0,
          "description": "string"
        }
      ],
      "name": "string",
      "description": "string",
      "need_logged_user": true,
      "editable_answers": true,
      "display_method": 1,
      "expire_date": "2023-11-20",
      "redirect_url": "string",
      "created_by_user": 0,
      "status": 1,
      "create_date": "2023-11-20T20:22:51.661Z",
      "update_date": "2023-11-20T20:22:51.661Z"
    },
  },
  'cms': {
    'funnels': [
      {
        "id": 1,
        "steps": [
          {
            "id": 1,
            "name": "source_trigger_1_signup_event_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "source_trigger",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": 1,
            "edges": {
              "next_yes": 2
            }
          },
          {
            "id": 2,
            "name": "action_step_2_send_email_1",
            "delay_hours": 168,
            "type_id": 1,
            "node_type": "target_action",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": 3
            }
          },
          {
            "id": 3,
            "name": "condition_step_3_user_opened_email_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "condition",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": 4,
              "next_no": 5
            }
          },
          {
            "id": 4,
            "name": "action_step_4_send_email_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "target_action",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": null
            }
          },
          {
            "id": 5,
            "name": "action_step_5_send_email_1",
            "delay_hours": null,
            "type_id": 1,
            "node_type": "target_action",
            "node_params": {
              "email_template_id": 5
            },
            "funnel": 1,
            "stage": null,
            "edges": {
              "next_yes": null
            }
          }
        ],
        "stages": [
          {
            "id": 1,
            "tenant_id": 1,
            "name": "stage1",
            "description": null,
            "funnel": 1
          }
        ],
        "tenant_id": 1,
        "created_by": null,
        "created_on": "2023-10-25T01:33:41.514493Z",
        "name": "internal-test-funnel",
        "description": null,
        "step_count": null
      }
    ],
    'funnels/{id}': {
      "id": 0,
      "steps": [
        {
          "id": 0,
          "name": "string",
          "delay_hours": 0,
          "type_id": 0,
          "node_type": "target_action",
          "node_params": {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
          },
          "funnel": 0,
          "stage": 0
        }
      ],
      "stages": [
        {
          "id": 0,
          "tenant_id": 0,
          "name": "string",
          "description": "string",
          "funnel": 0
        }
      ],
      "tenant_id": 0,
      "created_by": 0,
      "created_on": "2023-11-20T20:28:01.682Z",
      "name": "string",
      "description": "string",
      "step_count": 0
    },
    'funnels/{id}/stages': [
      {
        "id": 0,
        "tenant_id": 0,
        "name": "string",
        "description": "string",
        "funnel": 0
      }
    ],
    'funnels/{id}/stages/{id}': {
      "id": 0,
      "tenant_id": 0,
      "name": "string",
      "description": "string",
      "funnel": 0
    },
    'funnels/{id}/steps': [
      {
        "id": 0,
        "name": "string",
        "delay_hours": 0,
        "type_id": 0,
        "node_type": "target_action",
        "node_params": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "funnel": 0,
        "stage": 0
      }
    ],
    'funnels/{id}/steps/{id}': {
      "id": 0,
      "name": "string",
      "delay_hours": 0,
      "type_id": 0,
      "node_type": "target_action",
      "node_params": {
        "additionalProp1": "string",
        "additionalProp2": "string",
        "additionalProp3": "string"
      },
      "funnel": 0,
      "stage": 0
    }
  },
  'forms': [
    {
      "created": "2024-03-12T14:26:26.067290-07:00",
      "custom_field_options": ["g1d0mxkq5VqW", "0d4jmAoLwYgA", "zNPK5Yg3XQDr"],
      "default_from_email": "",
      "default_from_name": "",
      "double_opt_in": false,
      "id": "dzPDm3GmanAb",
      "mailing_list_options": [],
      "modified": "2024-03-12T14:26:26.067299-07:00",
      "name": "Homepage Signup",
      "redirect_url": "",
      "style_details": null,
      "tenant_id": 4,
      "user_visible_description": "Description for joining us",
      "user_visible_icon": null,
      "user_visible_name": "Join Us"
    },
    {
      "created": "2024-03-19T00:31:54.755472-07:00",
      "custom_field_options": [],
      "default_from_email": "",
      "default_from_name": "",
      "double_opt_in": false,
      "id": "zNPK5Y3XQDrJ",
      "mailing_list_options": [],
      "modified": "2024-03-19T00:31:54.755481-07:00",
      "name": "Demo Form",
      "redirect_url": "",
      "style_details": null,
      "tenant_id": 4,
      "user_visible_description": "Description of the demo form.",
      "user_visible_icon": null,
      "user_visible_name": "Demo Form"
    }
  ],
  'templates': [
    {
      "content": "",
      "content_file": "zNPK5Y3XQDrJ",
      "created": "2024-03-12T14:31:30.227840-07:00",
      "id": "3QBVwRbwy1b0",
      "last_used_campaign_id": "3QBVwRbwy1b0",
      "last_used_date": "2024-03-12T21:43:10.077745Z",
      "modified": "2024-03-12T14:31:30.227849-07:00",
      "name": "Welcome Email"
    },
    {
      "content": "",
      "content_file": "1J9QXpAwnMOk",
      "created": "2024-03-19T10:26:52.643807-07:00",
      "id": "1J9QXpAwnMOk",
      "last_used_campaign_id": null,
      "last_used_date": null,
      "modified": "2024-03-19T10:26:52.643816-07:00",
      "name": "Newsletter 1"
    }
  ],
  'custom_fields': [
    {
      "id": "4dMKm16zX3ab",
      "created": "2024-03-12T15:48:14.439308-07:00",
      "modified": "2024-03-12T15:48:14.439317-07:00",
      "data_type": "url",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Twitter Account",
      "user_visible_name": "Twitter Account",
      "field_default_value": null,
      "enum_values": null
    },
    {
      "id": "G7Ky5kl4mDLY",
      "created": "2024-03-12T15:48:14.440218-07:00",
      "modified": "2024-03-12T15:48:14.440225-07:00",
      "data_type": "url",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "LinkedIn Account",
      "user_visible_name": "LinkedIn Account",
      "field_default_value": null,
      "enum_values": null
    },
    {
      "id": "A4QOXJzLXvDp",
      "created": "2024-03-12T15:43:12.093395-07:00",
      "modified": "2024-03-12T15:43:12.093411-07:00",
      "data_type": "list",
      "validation_rule": "enum_values",
      "validation_value": null,
      "field_name": "Events Attended",
      "user_visible_name": "Events Attended",
      "field_default_value": null,
      "enum_values": [
        "Under-represented Founder Groups",
        "Bootcamp 201",
        "Bootcamp 101 Feb 6 2024",
        "AI Hackathon 5.0",
        "Grand Opening",
        "Holiday Party 2023",
        "Jewish Angel Network",
        "Launchpad Expo #01 (11/2023)",
        "Pitch Clinic (10/2023) - Melissa Reaves",
        "New Tech Eastside Meetup (11/2023)",
        "Black Founders Group Formation",
        "Launchpad Expo Fintech",
        "VPEG ",
        "Mentor Workshop (09/2023)- Michael Stein",
        "Unlisted Event"
      ]
    },
    {
      "id": "vLMN5j6o53b0",
      "created": "2024-03-12T16:35:53.142842-07:00",
      "modified": "2024-03-12T16:35:53.142850-07:00",
      "data_type": "image_file",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Company Logo",
      "user_visible_name": "Company Logo",
      "field_default_value": null,
      "enum_values": null
    },
    {
      "id": "JGKomvW2X49E",
      "created": "2024-03-12T16:38:08.862868-07:00",
      "modified": "2024-03-12T16:38:08.862879-07:00",
      "data_type": "large_text_field",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Rich Text",
      "user_visible_name": "Rich Text",
      "field_default_value": null,
      "enum_values": null
    }
  ],
  'surveys': [
    {
      "id": 'jjwoeifj2o35',
      "category_set": [
        {
          "id": '234kg324j32bk3',
          "question_set": [
            {
              "id": 'kjh5kjh7f3f4',
              "choices": [
                {
                  "id": 'kj34h435g43f3',
                  "choice_text": "string"
                }
              ],
              "tenant_id": 0,
              "text": "string",
              "order": 0,
              "required": true,
              "type": "text"
            }
          ],
          "tenant_id": 0,
          "name": "string",
          "order": 0,
          "description": "string"
        }
      ],
      "name": "Survey One",
      "description": "string",
      "editable_answers": true,
      "display_method": 1,
      "expire_date": "2024-09-20T05:08:30.460Z",
      "redirect_url": "string",
      "created_by_user": 0,
      "status": 1,
      "create_date": "2024-03-20T05:08:30.460Z",
      "update_date": "2024-03-20T05:08:30.461Z"
    },
    {
      "id": 'cf3c4f3cf434c',
      "category_set": [
        {
          "id": 'jn3jn43j34n3j4',
          "question_set": [
            {
              "id": 'gh3f4h3f4h3f4',
              "choices": [
                {
                  "id": 'j34hkh7ggf88d9f',
                  "choice_text": "string"
                }
              ],
              "tenant_id": 0,
              "text": "string",
              "order": 0,
              "required": true,
              "type": "text"
            }
          ],
          "tenant_id": 0,
          "name": "string",
          "order": 0,
          "description": "string"
        }
      ],
      "name": "Food Preferences",
      "description": "string",
      "editable_answers": true,
      "display_method": 1,
      "expire_date": "2024-09-20T05:08:30.460Z",
      "redirect_url": "string",
      "created_by_user": 0,
      "status": 1,
      "create_date": "2024-03-20T05:08:30.460Z",
      "update_date": "2024-03-20T05:08:30.461Z"
    }
  ]
  
}

const funnelResponse =  {
  "id": 12,
  "steps": [
    {
      "id": 12,
      "name": "Funnel",
      "delay_hours": null,
      "type_id": 1,
      "node_type": "funnel",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": 11,
      "edges": {
        "next_yes": 20
      }
    },
    {
      "id": 20,
      "name": "trigger_1_signup_event_12",
      "delay_hours": null,
      "type_id": 1,
      "node_type": "trigger",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": 11,
      "edges": {
        "next_yes": 21
      }
    },
    {
      "id": 21,
      "name": "action_step_2_send_email_12",
      "delay_hours": 168,
      "type_id": 1,
      "node_type": "action",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": null,
      "edges": {
        "next_yes": 22
      }
    },
    {
      "id": 22,
      "name": "condition_step_3_user_opened_email_12",
      "delay_hours": null,
      "type_id": 1,
      "node_type": "condition",
      "node_params": {
        "email_template_id": 5
      },
      "funnel": 12,
      "stage": null,
      "edges": {
        // "next_yes": 23,
        // "next_no": 24
      }
    },
    // {
    //   "id": 23,
    //   "name": "action_step_4_send_email_12",
    //   "delay_hours": null,
    //   "type_id": 1,
    //   "node_type": "action",
    //   "node_params": {
    //     "email_template_id": 5
    //   },
    //   "funnel": 12,
    //   "stage": null,
    //   "edges": {
    //     "next_yes": null
    //   }
    // },
    // {
    //   "id": 24,
    //   "name": "action_step_5_send_email_12",
    //   "delay_hours": null,
    //   "type_id": 1,
    //   "node_type": "action",
    //   "node_params": {
    //     "email_template_id": 5
    //   },
    //   "funnel": 12,
    //   "stage": null,
    //   "edges": {
    //     "next_yes": null
    //   }
    // }
  ],
  "tenant_id": 1,
  "created_by": null,
  "created_on": "2023-10-24T04:45:05.940212Z",
  "name": "internal-test-funnel",
  "description": null,
  "step_count": null
}

export const realMockInitialData = () => {
  const response = {...funnelResponse}
  
  const nodes = [];
  const edges = [];
  
  // Create nodes
  response.steps.forEach((step) => {
    nodes.push({
      id: step.id.toString(),
      type: step.node_type,
      data: { label: step.name },
      position: { x: 0, y: 0 },
    });
  
    // Check if the step has edges
    if (step.edges) {
      // Handle 'next_yes' edge
      if (step.edges.next_yes) {
        edges.push({
          id: `${step.id}_next_yes`,
          source: step.id.toString(),
          target: step.edges.next_yes.toString(),
        });
      }
  
      // Handle 'next_no' edge if it exists
      if (step.edges.next_no) {
        edges.push({
          id: `${step.id}_next_no`,
          source: step.id.toString(),
          target: step.edges.next_no.toString(),
        });
      }
    }
  });
  
  // Resulting nodes and edges for React Flow
  console.log("Nodes:", nodes);
  console.log("Edges:", edges);

  return {
    nodes: nodes,
    edges: edges
  }
  
} 

export const contactMockData = {
  count:20,
  next: null,
  previous: null,
  results: [
      {
        "id": 1,
        "name": "One",
        "primary_email": "One@test.com"
      },
      {
        "id": 2,
        "name": "Two",
        "primary_email": "Two@test.com"
      },
      {
        "id": 3,
        "name": "Three",
        "primary_email": "Three@test.com"
      },
      {
        "id": 4,
        "name": "Four",
        "primary_email": "Four@test.com"
      },
      {
        "id": 5,
        "name": "Five",
        "primary_email": "Five@test.com"
      },
      {
        "id": 6,
        "name": "Six",
        "primary_email": "Six@test.com"
      },
      {
        "id": 7,
        "name": "Seven",
        "primary_email": "Seven@test.com"
      },
      {
        "id": 8,
        "name": "Eight",
        "primary_email": "Eight@test.com"
      },
      {
        "id": 9,
        "name": "Nine",
        "primary_email": "Nine@test.com"
      },
      {
        "id": 10,
        "name": "Ten",
        "primary_email": "Ten@test.com"
      },
      {
        "id": 11,
        "name": "Eleven",
        "primary_email": "Eleven@test.com"
      },
      {
        "id": 12,
        "name": "Twelve",
        "primary_email": "Twelve@test.com"
      },
      {
        "id": 13,
        "name": "Thirteen",
        "primary_email": "Thirteen@test.com"
      },
      {
        "id": 14,
        "name": "Fourteen",
        "primary_email": "Fourteen@test.com"
      },
      {
        "id": 15,
        "name": "Fifteen",
        "primary_email": "Fifteen@test.com"
      },
      {
        "id": 16,
        "name": "Sixteen",
        "primary_email": "Sixteen@test.com"
      },
      {
        "id": 17,
        "name": "Seventeen",
        "primary_email": "Seventeen@test.com"
      },
      {
        "id": 18,
        "name": "Eighteen",
        "primary_email": "Eighteen@test.com"
      },
      {
        "id": 19,
        "name": "Nineteen",
        "primary_email": "Nineteen@test.com"
      },
      {
        "id": 20,
        "name": "Twenty",
        "primary_email": "Twenty@test.com"
      },
      {
        "id": 21,
        "name": "Twenty One",
        "primary_email": "Twenty One@test.com"
      },
      {
        "id": 22,
        "name": "Twenty Two",
        "primary_email": "Twenty Two@test.com"
      },
      {
        "id": 23,
        "name": "Twenty Three",
        "primary_email": "Twenty Three@test.com"
      },
      {
        "id": 24,
        "name": "Twenty Four",
        "primary_email": "Twenty Four@test.com"
      },
      {
        "id": 25,
        "name": "Twenty Five",
        "primary_email": "Twenty Five@test.com"
      },
      {
        "id": 26,
        "name": "Twenty Six",
        "primary_email": "Twenty Six@test.com"
      },
      {
        "id": 27,
        "name": "Twenty Seven",
        "primary_email": "Twenty Seven@test.com"
      },
      {
        "id": 28,
        "name": "Twenty Eight",
        "primary_email": "Twenty Eight@test.com"
      },
      {
        "id": 29,
        "name": "Twenty Nine",
        "primary_email": "Twenty Nine@test.com"
      },
      {
        "id": 30,
        "name": "Thirty",
        "primary_email": "Thirty@test.com"
      },
      {
        "id": 31,
        "name": "Thirty One",
        "primary_email": "Thirty One@test.com"
      },
      {
        "id": 32,
        "name": "Thirty Two",
        "primary_email": "Thirty Two@test.com"
      },
      {
        "id": 33,
        "name": "Thirty Three",
        "primary_email": "Thirty Three@test.com"
      },
      {
        "id": 34,
        "name": "Thirty Four",
        "primary_email": "Thirty Four@test.com"
      },
      {
        "id": 35,
        "name": "Thirty Five",
        "primary_email": "Thirty Five@test.com"
      },
      {
        "id": 36,
        "name": "Thirty Six",
        "primary_email": "Thirty Six@test.com"
      },
      {
        "id": 37,
        "name": "Thirty Seven",
        "primary_email": "Thirty Seven@test.com"
      },
      {
        "id": 38,
        "name": "Thirty Eight",
        "primary_email": "Thirty Eight@test.com"
      },
      {
        "id": 39,
        "name": "Thirty Nine",
        "primary_email": "Thirty Nine@test.com"
      },
      {
        "id": 40,
        "name": "Forty",
        "primary_email": "Forty@test.com"
      },
      {
        "id": 41,
        "name": "Forty One",
        "primary_email": "Forty One@test.com"
      },
      {
        "id": 42,
        "name": "Forty Two",
        "primary_email": "Forty Two@test.com"
      },
      {
        "id": 43,
        "name": "Forty Three",
        "primary_email": "Forty Three@test.com"
      },
      {
        "id": 44,
        "name": "Forty Four",
        "primary_email": "Forty Four@test.com"
      },
      {
        "id": 45,
        "name": "Forty Five",
        "primary_email": "Forty Five@test.com"
      },
      {
        "id": 46,
        "name": "Forty Six",
        "primary_email": "Forty Six@test.com"
      },
      {
        "id": 47,
        "name": "Forty Seven",
        "primary_email": "Forty Seven@test.com"
      },
      {
        "id": 48,
        "name": "Forty Eight",
        "primary_email": "Forty Eight@test.com"
      },
      {
        "id": 49,
        "name": "Forty Nine",
        "primary_email": "Forty Nine@test.com"
      },
      {
        "id": 50,
        "name": "Fifty",
        "primary_email": "Fifty@test.com"
      }
    ]
}

export const mockGetCustomFields = [
  {
      "id": "QAp5L1mW",
      "created": "2024-06-11T14:11:35.831916-07:00",
      "modified": "2024-06-11T14:11:35.831916-07:00",
      "data_type": "number",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Number",
      "user_visible_name": "Number",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "7XplO1KP",
      "created": "2024-06-11T14:11:35.926917-07:00",
      "modified": "2024-06-11T14:11:35.926917-07:00",
      "data_type": "large_text_field",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Rich Text",
      "user_visible_name": "Rich Text",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "bVeqDGv4",
      "created": "2024-06-11T14:11:35.853932-07:00",
      "modified": "2024-06-11T14:11:35.853932-07:00",
      "data_type": "text",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Text",
      "user_visible_name": "Text",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "6Qp6bpYd",
      "created": "2024-06-11T14:11:35.930918-07:00",
      "modified": "2024-06-11T14:11:35.930918-07:00",
      "data_type": "image_file",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Image",
      "user_visible_name": "Image",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "9EGnJGPV",
      "created": "2024-06-11T14:11:35.938932-07:00",
      "modified": "2024-06-11T14:11:35.938932-07:00",
      "data_type": "file",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "File",
      "user_visible_name": "File",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "xkej31RN",
      "created": "2024-06-11T14:11:35.941916-07:00",
      "modified": "2024-06-11T14:11:35.941916-07:00",
      "data_type": "url",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Link",
      "user_visible_name": "Link",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "VveMYeZX",
      "created": "2024-06-11T14:11:36.710627-07:00",
      "modified": "2024-06-11T14:11:36.710627-07:00",
      "data_type": "list",
      "validation_rule": "enum_values",
      "validation_value": null,
      "field_name": "List",
      "user_visible_name": "List",
      "field_default_value": null,
      "enum_values": [
          "placeholder"
      ],
      "order": 0
  },
  {
      "id": "9rpNRpyl",
      "created": "2024-06-11T14:11:36.864637-07:00",
      "modified": "2024-06-11T14:11:36.864637-07:00",
      "data_type": "bool",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Checkbox",
      "user_visible_name": "Checkbox",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "Q014KejE",
      "created": "2024-06-11T14:11:37.051650-07:00",
      "modified": "2024-06-11T14:11:37.051650-07:00",
      "data_type": "phone_number",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Phone",
      "user_visible_name": "Phone",
      "field_default_value": null,
      "enum_values": null,
      "order": 0
  },
  {
      "id": "YvpxK165",
      "created": "2024-06-11T14:11:37.042637-07:00",
      "modified": "2024-06-11T14:11:37.042637-07:00",
      "data_type": "enum",
      "validation_rule": null,
      "validation_value": null,
      "field_name": "Select",
      "user_visible_name": "Select",
      "field_default_value": null,
      "enum_values": [
          "placeholder"
      ],
      "order": 0
  }
]

export const mockGetContacts = [
  {
    "id": "0D1KPvpz",
    "name": "",
    "primary_email": "michael.nguyen@amperity.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:22.262070-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Michael",
        "zmeD8edV": "Nguyen",
        "jd1doeMq": "Amperity",
        "kKeb31wx": "Seattle",
        "BXeYOpvW": "Washington",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "amperity.com",
        "xVerneNY": "Partner Engagement Manager",
        "xkej31RN": "http://www.linkedin.com/in/nguyen86"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "LJpm881D",
    "name": "",
    "primary_email": "thabatar@dlcoffee.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:22.130238-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Thabata",
        "zmeD8edV": "Regiani",
        "jd1doeMq": "Distant Lands Coffee",
        "kKeb31wx": "Seattle",
        "BXeYOpvW": "Washington",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "dlcoffee.com",
        "xVerneNY": "Senior Graphic Designer",
        "xkej31RN": "http://www.linkedin.com/in/thabataregiani"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "r0ePKmGQ",
    "name": "",
    "primary_email": "brandon.gagliardi@jpmorgan.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.998237-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Brandon",
        "zmeD8edV": "Gagliardi",
        "jd1doeMq": "J.P. Morgan",
        "kKeb31wx": "Charlotte",
        "BXeYOpvW": "North Carolina",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "jpmorgan.com",
        "xVerneNY": "Vice President, Commercial Bank",
        "xkej31RN": "http://www.linkedin.com/in/brandon-gagliardi-17b84089"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "EZpkEe8R",
    "name": "",
    "primary_email": "smith@gflenv.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.885425-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Christopher",
        "zmeD8edV": "Smith",
        "jd1doeMq": "GFL Environmental Inc.",
        "kKeb31wx": "Indianapolis",
        "BXeYOpvW": "Indiana",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "gflenv.com",
        "xVerneNY": "Fleet Maintenance Coordinator",
        "xkej31RN": "http://www.linkedin.com/in/christopher-smith-718191116"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "J4GENeAk",
    "name": "",
    "primary_email": "jnettles@daltonagency.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.748866-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Jeremy",
        "zmeD8edV": "Nettles",
        "jd1doeMq": "Dalton Agency",
        "kKeb31wx": "Jacksonville",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "daltonagency.com",
        "xVerneNY": "Senior Vice President, Social Media",
        "xkej31RN": "http://www.linkedin.com/in/jeremynettles"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "DoGVD1rg",
    "name": "",
    "primary_email": "tim.mcmullin@goosehead.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.605418-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Tim",
        "zmeD8edV": "McMullin",
        "jd1doeMq": "Goosehead Insurance Agency",
        "kKeb31wx": "Chadds Ford",
        "jJGvBGDO": "19317",
        "BXeYOpvW": "Pennsylvania",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "goosehead.com",
        "xVerneNY": "Broker / Agency Owner",
        "xkej31RN": "http://www.linkedin.com/in/tim-mcmullin-99644211"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "L4eXKGBW",
    "name": "",
    "primary_email": "mark.neuhausen@hp.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.470361-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Mark",
        "zmeD8edV": "Neuhausen",
        "jd1doeMq": "Technology Association of Oregon (TAO)",
        "kKeb31wx": "Portland",
        "BXeYOpvW": "Oregon",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "hp.com",
        "xVerneNY": "Executive in Residence",
        "xkej31RN": "http://www.linkedin.com/in/markneuhausen"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "q9eyv14P",
    "name": "",
    "primary_email": "karen.hall@purelogics.net",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.350387-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Karen",
        "zmeD8edV": "Hall",
        "jd1doeMq": "PureLogics",
        "kKeb31wx": "Seattle",
        "BXeYOpvW": "Washington",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "purelogics.net",
        "xVerneNY": "SVP, Chief Marketing and Communications Officer",
        "xkej31RN": "http://www.linkedin.com/in/karen-hall-8bb329164"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "ZL17yGR5",
    "name": "",
    "primary_email": "gustavo.roig@fiu.edu",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.220012-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Gustavo",
        "zmeD8edV": "Roig",
        "jd1doeMq": "Florida SBDC at FIU",
        "kKeb31wx": "Miami",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "fiu.edu",
        "xVerneNY": "professor",
        "xkej31RN": "http://www.linkedin.com/in/gustavo-roig-12204122"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "jWG2OegO",
    "name": "",
    "primary_email": "aobeng@nla.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:21.080980-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Abigail",
        "zmeD8edV": "Obeng",
        "jd1doeMq": "National Lottery Authority",
        "nMpOz1Pg": "Ghana",
        "YoGW8eOB": "nla.com",
        "xVerneNY": "Call Center Officer",
        "xkej31RN": "http://www.linkedin.com/in/abigail-obeng-7b809837"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "gAGwq1b2",
    "name": "",
    "primary_email": "bcarline@seabest.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.953743-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Bluzette",
        "zmeD8edV": "Carline",
        "jd1doeMq": "Beaver Street Fisheries",
        "kKeb31wx": "Jacksonville",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "seabest.com",
        "xVerneNY": "Director of Marketing - Beaver Street Fisheries, Inc., Tropic Seafood, Ltd.",
        "xkej31RN": "http://www.linkedin.com/in/bluzettecarline"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "7L1JPGZj",
    "name": "",
    "primary_email": "mohammad@mslcompanies.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.832761-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Mohammad",
        "zmeD8edV": "Bataineh",
        "jd1doeMq": "MSL Capital",
        "kKeb31wx": "Jacksonville",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "mslcompanies.com",
        "xVerneNY": "Chief Executive Officer",
        "xkej31RN": "http://www.linkedin.com/in/mohammad-bataineh"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "ZXGaZed9",
    "name": "",
    "primary_email": "josh.stancliff@rayonier.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.712766-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Joshua",
        "zmeD8edV": "Stancliff, Crm",
        "jd1doeMq": "Rayonier",
        "kKeb31wx": "Jacksonville",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "rayonier.com",
        "xVerneNY": "Risk Manager",
        "xkej31RN": "http://www.linkedin.com/in/joshua-stancliff-crm-54674244"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "xZGRWezX",
    "name": "",
    "primary_email": "ccramer@highline.edu",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.567761-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Cait",
        "zmeD8edV": "Cramer",
        "jd1doeMq": "Highline College",
        "kKeb31wx": "Fort Wayne",
        "BXeYOpvW": "Indiana",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "highline.edu",
        "xVerneNY": "Engineering Faculty",
        "xkej31RN": "http://www.linkedin.com/in/cait-cramer"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "3bGgWGqa",
    "name": "",
    "primary_email": "sgrandin@coj.net",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.435701-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Susan",
        "zmeD8edV": "Grandin",
        "jd1doeMq": "City of Jacksonville, Florida",
        "kKeb31wx": "Jacksonville",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "coj.net",
        "xVerneNY": "Assistant General Counsel",
        "xkej31RN": "http://www.linkedin.com/in/susan-grandin-6a0b8894"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "qRp37pEV",
    "name": "",
    "primary_email": "pcochrane@prosourceit.net",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.320722-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Pete",
        "zmeD8edV": "Cochrane",
        "jd1doeMq": "pro/source <IT>",
        "kKeb31wx": "Jacksonville",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "prosourceit.net",
        "xVerneNY": "Senior Managing Director",
        "xkej31RN": "http://www.linkedin.com/in/petecochrane"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "rXpLJeOx",
    "name": "",
    "primary_email": "zdenson@fortinet.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.174368-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Zach",
        "zmeD8edV": "Denson",
        "jd1doeMq": "Fortinet",
        "kKeb31wx": "Fort Myers",
        "jJGvBGDO": "33916",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "fortinet.com",
        "xVerneNY": "Major Account Manager",
        "xkej31RN": "http://www.linkedin.com/in/zach-denson-6b546a116"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "vApQbGjo",
    "name": "",
    "primary_email": "kelsey.parker@frontrowag.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:20.028843-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Kelsey",
        "zmeD8edV": "Parker",
        "jd1doeMq": "Front Row Ag",
        "kKeb31wx": "Arcata",
        "BXeYOpvW": "California",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "frontrowag.com",
        "xVerneNY": "Creative Manager",
        "xkej31RN": "http://www.linkedin.com/in/klspkr"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "Ar1AnpRo",
    "name": "",
    "primary_email": "rileygaffney@umpquabank.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:19.886145-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Riley",
        "zmeD8edV": "Gaffney",
        "jd1doeMq": "Umpqua Bank",
        "kKeb31wx": "Seattle",
        "BXeYOpvW": "Washington",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "umpquabank.com",
        "xVerneNY": "Senior Vice President, Commercial Banker",
        "xkej31RN": "http://www.linkedin.com/in/rileygaffney"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
},
{
    "id": "boG0DGN8",
    "name": "",
    "primary_email": "jmehta@foley.com",
    "notes": null,
    "created_by": 4,
    "created_on": "2024-06-11T14:17:19.762070-07:00",
    "is_active": false,
    "custom_fields": {
        "yJG8meWa": "Jason",
        "zmeD8edV": "Mehta",
        "jd1doeMq": "Foley & Lardner LLP",
        "kKeb31wx": "Tampa",
        "BXeYOpvW": "Florida",
        "nMpOz1Pg": "United States",
        "YoGW8eOB": "foley.com",
        "xVerneNY": "Partner",
        "xkej31RN": "http://www.linkedin.com/in/jason-mehta"
    },
    "tags": [],
    "open_rate": 0.0,
    "click_rate": 0.0,
    "status": 2,
    "last_seen_date": null,
    "location": null,
    "last_sent": null,
    "mailing_lists": [],
    "validation_status": 1,
    "quality_score": 10
}

]

export const mockGetSurveys = [
  {
      "id": "0D1Kvezd",
      "category_set": [
          {
              "id": "0D1Kvezd",
              "question_set": [
                  {
                      "id": "0D1Kvezd",
                      "choice_set": [],
                      "created": "2024-06-06T19:39:09.824918-07:00",
                      "modified": "2024-06-06T19:39:09.824918-07:00",
                      "text": "Question One",
                      "order": 0,
                      "required": true,
                      "type": "text"
                  },
                  {
                      "id": "kae9aeDE",
                      "choice_set": [
                          {
                              "id": "QAp5L1mW",
                              "choice_text": "Yes"
                          },
                          {
                              "id": "7XplO1KP",
                              "choice_text": "No way"
                          }
                      ],
                      "created": "2024-06-06T19:40:51.943358-07:00",
                      "modified": "2024-06-06T19:40:51.943358-07:00",
                      "text": "Do you like me?",
                      "order": 0,
                      "required": true,
                      "type": "select"
                  }
              ],
              "created": "2024-06-06T19:39:09.822918-07:00",
              "modified": "2024-06-06T19:39:09.822918-07:00",
              "tenant_id": 1,
              "name": "default",
              "order": 0,
              "description": ""
          }
      ],
      "created": "2024-06-06T19:39:09.817918-07:00",
      "modified": "2024-06-06T19:39:09.817918-07:00",
      "name": "Survey One",
      "description": "details",
      "editable_answers": true,
      "display_method": 0,
      "expire_date": null,
      "redirect_url": "",
      "created_by_user": null,
      "status": 1,
      "create_date": "2024-06-06T19:39:09.817918-07:00",
      "update_date": "2024-06-06T12:39:09.817918-07:00"
  },
  {
      "id": "kae9aeDE",
      "category_set": [
          {
              "id": "kae9aeDE",
              "question_set": [
                  {
                      "id": "M9pZVpRk",
                      "choice_set": [],
                      "created": "2024-06-07T12:58:21.702240-07:00",
                      "modified": "2024-06-07T12:58:21.702240-07:00",
                      "text": "What are we doing?",
                      "order": 0,
                      "required": true,
                      "type": "text"
                  },
                  {
                      "id": "QAp5L1mW",
                      "choice_set": [
                          {
                              "id": "Q014KejE",
                              "choice_text": "Yes"
                          },
                          {
                              "id": "YvpxK165",
                              "choice_text": "No"
                          }
                      ],
                      "created": "2024-06-07T12:58:21.703223-07:00",
                      "modified": "2024-06-07T12:58:21.703223-07:00",
                      "text": "Have you had a fun day?",
                      "order": 0,
                      "required": true,
                      "type": "select"
                  },
                  {
                      "id": "7XplO1KP",
                      "choice_set": [
                          {
                              "id": "yJG8meWa",
                              "choice_text": "Food"
                          },
                          {
                              "id": "zmeD8edV",
                              "choice_text": "Sunshine"
                          },
                          {
                              "id": "jJGvBGDO",
                              "choice_text": "Flowers"
                          },
                          {
                              "id": "kKeb31wx",
                              "choice_text": "Sand"
                          }
                      ],
                      "created": "2024-06-07T12:58:21.707245-07:00",
                      "modified": "2024-06-07T12:58:21.707245-07:00",
                      "text": "Which of these do you like?",
                      "order": 0,
                      "required": true,
                      "type": "select-multiple"
                  },
                  {
                      "id": "bVeqDGv4",
                      "choice_set": [],
                      "created": "2024-06-07T13:26:05.295018-07:00",
                      "modified": "2024-06-07T13:26:05.295018-07:00",
                      "text": "How old are you?",
                      "order": 0,
                      "required": true,
                      "type": "float"
                  },
                  {
                      "id": "6Qp6bpYd",
                      "choice_set": [],
                      "created": "2024-06-07T13:26:05.296012-07:00",
                      "modified": "2024-06-07T13:26:05.296012-07:00",
                      "text": "Birthday. Do you have one? Don't lie to me. When is it?",
                      "order": 0,
                      "required": true,
                      "type": "date"
                  }
              ],
              "created": "2024-06-07T12:58:21.700224-07:00",
              "modified": "2024-06-07T12:58:21.700224-07:00",
              "tenant_id": 1,
              "name": "default",
              "order": 0,
              "description": ""
          }
      ],
      "created": "2024-06-07T12:58:21.671222-07:00",
      "modified": "2024-06-07T12:58:21.671222-07:00",
      "name": "Survey Two",
      "description": "Test survey",
      "editable_answers": true,
      "display_method": 0,
      "expire_date": null,
      "redirect_url": "",
      "created_by_user": null,
      "status": 1,
      "create_date": "2024-06-07T12:58:21.671222-07:00",
      "update_date": "2024-06-07T05:58:21.671222-07:00"
  }
]