import React, { useState, useEffect } from 'react'
import MainPageTemplate from '../../templates/MainPageTemplate'
import { InputField } from '../ui/Fields';
import { Button, Grid, Typography } from '@mui/material'
import { putUser } from '../../hooks/mutations';
import SnackbarWrapper from '../ui/SnackbarWrapper';
 
const SubuserEdit = ({ user, setReloadUsers }) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [snackMessage, setSnackMessage] = useState(null);

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setUserEmail(user.email);
    }
  }, [user]);

  const editUser = async () => {
    if (userName === user.name) {
      setSnackMessage({open: true, message: 'No change has been made', severity: 'info'});
      return;
    }

    const userObj = {
      userId: user.id,
      payload: {
        email: userEmail,
        name: userName
      }
    }

    const edittedUser = await putUser(userObj);
    console.log('edittedUser', edittedUser)

    if (edittedUser.status == "success") {
      setSnackMessage({open: true, message: 'User updated', severity:'success'});
      setReloadUsers(true)
    } else {
      setSnackMessage({open: true, message: 'Error editing user', severity:'error'})
    }
  }

  return (
    <MainPageTemplate title="Edit User">
      <Grid
        container
        minHeight="calc(40vh)"
        minWidth="500px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          padding: 16
        }}
      >
        <Typography
          variant="h5"
          style={{
            paddingBottom: 16
          }}
        >
          Edit User Name
        </Typography>
        <Grid
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
        >
          <InputField
            label="Name"
            margin
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder='Enter user name'
            name="name"
            width="350px"
          />
          <InputField 
            label="Email"
            margin
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder='Enter user email'
            name="email"
            width="350px"
            readOnly
          />
          <Typography
            style={{
              fontSize: '0.85rem',
              color: '#8B0000',
              marginBottom: 24
            }}
          >
            Please delete the user and recreate if email address needs to be updated.
          </Typography>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={editUser}
          sx={{
            px:4,
            py:2,
            mt:0
          }}
        >
          Update
        </Button>
      </Grid>
      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
    </MainPageTemplate>
  );
};

export default SubuserEdit;