import { Button, Collapse, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BoolField, InputField, InputFieldTypeDisplay, SliderField, ToggleField } from '../ui/Fields';
import { AccountSVG, CheckSVG, EditSVG, ShareSVG, VisibleSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';
import { postForm, postFormShareLink, postFormSubscribe, putForm } from '../../hooks/mutations';
import ReCAPTCHA from 'react-google-recaptcha';
import { useScreenSize } from '../../lib/Interface';
import Infotip from '../ui/Infotip';
import DialogShare from '../ui/DialogShare';
import { red } from '@mui/material/colors';
import DialogSettings from '../ui/DialogSettings';
import SnackbarWrapper from '../ui/SnackbarWrapper';

const FormDisplay = (props) => {
  const { form, token, formPreview, formEdit, setFormEdit, customFields, mailingLists, incomingForm, incomingPreview } = props;

  // =========================================================
  // FORM DISPLAY STATE
  // =========================================================
  const [preview, setPreview] = useState(incomingPreview ? true : false);
  const [submission, setSubmission] = useState({
    primary_email: '',
    custom_fields: {},
    mailing_lists: [],
  })
  const theme = useTheme();
  const brandColor = form?.style_details?.brandColor ? form.style_details.brandColor : theme.palette.primary.main
  const backgroundColor = form?.style_details?.backgroundColor ? `${form.style_details.backgroundColor}${form.style_details.backgroundAlpha ? form.style_details.backgroundAlpha : 'ff'}` : "#FFFFFF"
  const [rtoken, setRToken] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showAll, setShowAll] = useState(false); //added state to control list for custom fields and mail lists

  const handleRecaptchaChange = (value) => {
    setRToken(value);
    const handleShowAll = () => { //change showAll state for custom fields and mail lists
      setShowAll(true);
    }
  };

  // console.log('form', form);
  // console.log('formEdit', formEdit);
  // console.log('submission', submission);

  // =========================================================
  // FORM EDIT STATE
  // =========================================================
  const [addToLists, setAddToLists] = useState(incomingForm?.mailing_list_options.length ? true : false);
  const [addToFields, setAddToFields] = useState(incomingForm?.custom_field_options.length ? true : false);
  const [customizeSettings, setCustomizeSettings] = useState(incomingForm?.style_details ? true : false);
  const [saveSnack, setSaveSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [openShare, setOpenShare] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [shared, setShared] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [shareLink, setShareLink] = useState(null);
  const [shareEmbed, setShareEmbed] = useState(null);
  const [hover, setHover] = useState(null);
  const [editing, setEditing] = useState(false);
  const screenSize = useScreenSize();
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [showMailingList, setShowMailingList] = useState(false);


  // console.log(' form', form);
  // console.log(' formEdit', formEdit);
  // console.log('submission', submission);


  // =========================================================
  // FORM DISPLAY FUNCTIONS AND HOOKS
  // =========================================================

  // useEffect(() => {
  //   console.log("resetting form")
  //   if (form) {
  //     console.log("resetting form true")
  //     setSubmission({
  //       primary_email: '',
  //       custom_fields: Object.fromEntries(form.custom_fields.map(item => [item.id, ''])),
  //       // custom_fields: { ...form.custom_fields.map(x => ({ [x.id]: '' })) },
  //       mailing_lists: []
  //     })
  //   }
  // }, [form])

  const handleChange = (value, field) => {
    setSubmission(prev => ({ ...prev, [field]: value }))
  }

  const handleMailingListChange = (value) => {
    const copiedSubmission = { ...submission };
    const mailingLists = [...copiedSubmission.mailing_lists];
    if (mailingLists.map(x => x).includes(value.id)) {
      // Remove mailing list from lists
      const updatedLists = mailingLists.filter(x => x !== value.id);
      setSubmission(prev => ({ ...prev, mailing_lists: updatedLists }))
    } else {
      // Add mailing list to lists
      const updatedLists = [...mailingLists, value.id];
      setSubmission(prev => ({ ...prev, mailing_lists: updatedLists }))
    }
  }

  const handleTextCustomField = (value, name) => {
    const copiedSubmission = { ...submission };
    const customFields = { ...copiedSubmission.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setSubmission(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleListCustomField = (value, id) => {
    const copiedSubmission = { ...submission };
    const copiedFields = { ...copiedSubmission.custom_fields };

    const updatedCustomFields = { ...copiedFields, [id]: value };
    console.log('updatedCustomFields', updatedCustomFields)
    setSubmission(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))

  }

  const handleNumberCustomField = (value, name) => {
    const copiedSubmission = { ...submission };
    const customFields = { ...copiedSubmission.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: Number(value) }
    setSubmission(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleDateCustomField = (value, name) => {
    const copiedSubmission = { ...submission };
    const customFields = { ...copiedSubmission.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: new Date(value).toLocaleDateString() }
    setSubmission(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleBoolCustomField = (value, name) => {
    const copiedSubmission = { ...submission };
    const customFields = { ...copiedSubmission.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setSubmission(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const saveFormSubmission = async () => {
    // Use the token value in your form submission
    console.log('reCAPTCHA token:', rtoken);
    submission['captcha_token'] = rtoken
    const submissionObj = {
      token: token,
      payload: submission,
      recaptchaToken: rtoken,
    }
    const savedForm = await postFormSubscribe(submissionObj);
    if (savedForm.status === "success") {
      console.log('success', savedForm);
      if (form.redirect_url.length) {
        setTimeout(() => {
          let url = form.redirect_url;
  
          // Check if the URL starts with 'http://' or 'https://'
          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            // If not, assume it's missing and add 'http://'
            url = 'http://' + url;
          }
          
          window.open(url, '_blank');
              }, 3000);
      } else {
        setFormSubmitted(true);
      }
    }
  }

  const redirectPreview = () => {
    console.log('form redirect', form.redirect_url)
    let url = form.redirect_url;
  
    // Check if the URL starts with 'http://' or 'https://'
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // If not, assume it's missing and add 'http://'
      url = 'http://' + url;
    }
    
    window.open(url, '_blank');
  }











  // =========================================================
  // FORM EDIT FUNCTIONS AND HOOKS
  // =========================================================


  // Automatically switch mobilePreview to false if the screen size goes above 'xs' in order
  // to prevent the display component from showing and the 'switch to edit' button disappearing
  // useEffect(() => {
  //   if (!screenSize.xs) {
  //     setMobilePreview(false);
  //   }
  // }, [screenSize.xs])

  // Open Mailing List and Custom Field options if incomingForm has any
  useEffect(() => {
    if (incomingForm) {
      if (incomingForm.mailing_list_options.length) {
        setAddToLists(true);
      }

      if (incomingForm.custom_field_options.length) {
        setAddToFields(true);
      }
    }
  }, [incomingForm]);

  // Automatically create a share link if it doesn't exist when Share button is clicked
  useEffect(() => {
    if (openShare && !shareLink) {
      handleShareForm();
    }
  }, [openShare])

  // Clear all selected mailing lists if addToLists is unchecked
  useEffect(() => {
    if (!addToLists && setFormEdit) {
      setFormEdit(prev => ({ ...prev, mailing_list_options: [] }));
      // setFormPreview(prev => ({ ...prev, mailing_lists: [] })); // List for preview
    }
  }, [addToLists])

  // Clear all selected custom fields if addToFields is unchecked
  useEffect(() => {
    if (!addToFields && setFormEdit) {
      setFormEdit(prev => ({ ...prev, custom_field_options: [] }));
      // setFormPreview(prev => ({ ...prev, mailing_lists: [] })); // List for preview
    }
  }, [addToFields])

  // Clear all style details
  useEffect(() => {
    if (!customizeSettings && setFormEdit) {
      setFormEdit(prev => ({ ...prev, style_details: null }));
      // setFormPreview(prev => ({ ...prev, mailing_lists: [] })); // List for preview
    }
  }, [customizeSettings])

  // Handle updates to form name / visible name / description
  const handleEditChange = (value, field) => {
    setFormEdit(prev => ({ ...prev, [field]: value }));
    // setFormPreview(prev => ({ ...prev, [field]: value }));
  }

  const handleSettingsEditChange = (value, field) => {
    setFormEdit(prev => ({ ...prev, style_details: { ...prev.style_details, [field]: value } }))
  };

  // Handle Mailing List updates (uses useEffect below)
  // Returns only the mailing list id in the array
  const handleMailingListEditChange = (value) => {
    const existingList = [...formEdit.mailing_list_options];

    if (existingList.includes(value.id)) {
      console.log(value, ' already included -- being removed');
      const newList = existingList.filter(x => x != value.id);
      setFormEdit(prev => ({ ...prev, mailing_list_options: newList }));

    } else {
      const newList = [...new Set([...existingList, value.id])];
      setFormEdit(prev => ({ ...prev, mailing_list_options: newList }));
    }
  }

  // Handle Custom Field updates (uses useEffect below)
  // Returns only the custom field id in the array
  const handleCustomFieldEditChange = (value) => {
    // console.log('value', value);
    const existingList = [...formEdit.custom_field_options];
    // console.log('FormDisplay, handleCustomFieldEditChange, start, existingList: ', existingList);

    if (existingList.includes(value.id)) {
      console.log(value, ' already included -- being removed');
      const newList = existingList.filter(x => x != value.id);
      setFormEdit(prev => ({ ...prev, custom_field_options: newList }));
      // console.log('FormDisplay, handleCustomFieldEditChange, if, newList: ', newList);
    } else {
      const newList = [...new Set([...existingList, value.id])];
      setFormEdit(prev => ({ ...prev, custom_field_options: newList }));
      // console.log('FormDisplay, handleCustomFieldEditChange, else, existingList: ', existingList);
      // console.log('FormDisplay, handleCustomFieldEditChange, else, newList: ', newList);
    }
  }


  // Close Settings dialog
  const closeSettings = () => {
    setOpenSettings(false);
  }

  // Close Share dialog
  const closeShare = () => {
    setOpenShare(false);
  }

  // Share the form via share api post
  const handleShareForm = async () => {
    setSharing(true);
    const shareObj = {
      payload: {
        form_id: form.id,
        validity_duration_in_days: 3650 // Valid for 10 years
      }
    }

    const shared = await postFormShareLink(shareObj);
    if (shared.status === 'success') {
      setShareLink(shared.data.share_link);
      setShareEmbed(`<iframe src="${shared.data.share_link}"></iframe>`)
      setShared(true);
      setSharing(false);
    }

  }

  // Save the form to db via form api post
  const saveForm = async () => {
    const formCopy = { ...formEdit };
    const updatedMailingLists = formCopy.mailing_list_options.length ? formCopy.mailing_list_options.map(list => list.id ? list.id : list) : [];
    const updatedCustomFields = formCopy.custom_field_options.length ? formCopy.custom_field_options.map(field => field.id ? field.id : field) : [];

    if (formEdit.id) {
      // Do PUT api call for existing form
      console.log('PUT api call')
      const formObj = {
        formId: formEdit.id,
        payload: {
          ...formEdit,
          mailing_list_options: updatedMailingLists,
          custom_field_options: updatedCustomFields
        }
      }
      const updatedForm = await putForm(formObj);
      if (updatedForm.status === "success") {
        setSnackMessage({ open: true, message: 'Form saved', severity: 'success' })
      } else if (updatedForm.status === "error") {
        console.log('updatedForm', updatedForm);
        setSnackMessage({ open: true, message: updatedForm.error, severity: 'error' })
      }
    } else {
      // Do POST api call for new form
      console.log('POST api call')
      const formObj = {
        payload: {
          ...formEdit,
          mailing_list_options: updatedMailingLists,
          custom_field_options: updatedCustomFields
        }
      }
      const savedForm = await postForm(formObj);
      if (savedForm) {
        if (savedForm.status === "success") {
          console.log('savedForm', savedForm);
          setFormEdit(savedForm.data);
          setSnackMessage({ open: true, message: 'New form created', severity: 'success' })
        } else if (savedForm.status === "error") {
          console.log('error', savedForm);
          setSnackMessage({ open: true, message: savedForm.error, severity: 'error' })
        }
      }

    }

  }

  const handleCustomFieldClose = () => {
    if (form?.custom_fields.length > 0) {
      setShowCustomFields(prev => !prev);
    } else {

      setAddToFields(false);
    }
  }

  const handleMailingListClose = () => {
    if (form?.mailing_lists.length > 0) {
      setShowMailingList(prev => !prev)
    } else {
      setAddToLists(false)
    }
  }



  return (
    form
      ?
      <>
        {
          formEdit
            ?
              <Grid
                container
                style={{
                  position: 'sticky',
                  top: 0,
                  left: 0,
                  gap: 8,
                  padding: '8px 16px',
                  justifyContent: screenSize.xs ? 'space-between' : 'space-between',
                  alignContent:'center',
                  background: theme.palette.primaryBackground.main,
                  zIndex: 500
                }}
              >
                <Grid
                  style={{
                    alignContent: 'center',
                    gap: 8,
                  }}
                >
                  <Typography
                    style={{
                      fontWeight:600
                    }}
                  >
                    {
                      form.name
                    }
                  </Typography>
                </Grid>

                <Grid
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    gap: 8,
                  }}
                >
                  <Button
                    onClick={() => setOpenSettings(true)}
                    variant="outlined"
                    style={{
                      gap: 8
                    }}
                  // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                  >
                    <AccountSVG width={15} color1={theme.palette.primary.main} />
                    {screenSize.xs ? '' : 'Settings'}
                  </Button>
                  <DialogSettings
                    open={openSettings}
                    onClose={closeSettings}
                    hideButtons
                    header="Settings"
                    // title="Create An Email Template"
                    messages={{
                      // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
                    }}
                  >
                    <Grid container style={{marginTop:-32}}>

                      <BoolField
                        label="Enable Google Recaptcha"
                        boolMessage
                        value={form.enable_captcha}
                        onChange={(e) => handleEditChange(e.target.checked, 'enable_captcha')}
                        // margin
                        infotip={
                          <Infotip
                            info
                            title="Google Recaptcha"
                            tip="Prevent Spam Submissions"
                            messages={{
                              one: 'Google recaptcha requires contacts to confirm they are human before the from can be submitted. This prevents bots from spamming you.',
                              two: 'We highly recommend using this option.'
                            }}
                          />
                        }
                      />
                      <BoolField
                        label="Enable Double Opt-in"
                        boolMessage
                        value={form.double_opt_in}
                        onChange={(e) => handleEditChange(e.target.checked, 'double_opt_in')}
                        // margin
                        infotip={
                          <Infotip
                            info
                            title="Double Opt-in"
                            tip="Ensure Contact Integrity"
                            messages={{
                              one: 'A double opt-in occurs when a user signs up for a mailing list, and then an email is sent out to the user which includes a link to click and confirm the subscription. Only after the confirmation click is completed will the user officially be added to the mailing list. This helps to ensure you have quality contacts and a contact has not been added with an email they can not actually use.',
                              two: 'We highly recommend using this option.'
                            }}
                          />
                        }
                      />

                      <>
                        <BoolField
                          label="Customize form settings"
                          boolMessage
                          placeholder="Add subscriber to mailing list(s)?"
                          value={customizeSettings}
                          onChange={(e) => setCustomizeSettings(e.target.checked)}
                          infotip={
                            <Infotip
                              info
                              title="Custom Form Settings"
                              tip="Customize your form look and feel."
                              messages={{
                                one: 'We currently allow customizing the background, and primary colors, as well as the form width.',
                                two: 'Leave us feedback if you would like to see more customization options.'
                              }}
                            />
                          }
                        />
                        <Collapse
                          in={customizeSettings}
                          style={{
                            marginBottom: 24,
                            width: '100%'
                          }}
                        >
                          <Grid
                            container
                            style={{
                              background: '#fff',
                              borderTop: `1px solid ${theme.palette.primary.main}`,
                              // boxShadow:'0 0 10px #00000030',
                              padding: 16
                            }}
                          >

                            <InputField
                              label="Form width"
                              value={form.style_details?.maxWidth}
                              onChange={(e) => handleSettingsEditChange(e.target.value, 'maxWidth')}
                              margin
                            />
                            <InputField
                              label="Brand Color"
                              type="color"
                              singleLine
                              value={form.style_details?.brandColor}
                              onChange={(e) => handleSettingsEditChange(e.target.value, 'brandColor')}
                              margin
                            />
                            <InputField
                              label="Background Color"
                              type="color"
                              singleLine
                              value={form.style_details?.backgroundColor}
                              onChange={(e) => handleSettingsEditChange(e.target.value, 'backgroundColor')}
                              margin
                            />
                            <SliderField
                              label="Background Transparency"
                              description=""
                              value={form.style_details?.backgroundAlpha}
                              onChange={(e) => handleSettingsEditChange(Math.floor(e.target.value * 255).toString(16).padStart(2, '0'), 'backgroundAlpha')}
                              sliderProps={{
                                min:0,
                                max:1,
                                step:.01,
                                valueLabelDisplay:'auto'
                              }}
                              margin
                            />
                            <ToggleField
                              label="Form Shadow"
                              description="Show shadow on form box"
                              value={form.style_details?.showShadow}
                              onChange={(e) => handleSettingsEditChange(e.target.checked, 'showShadow')}
                            />
                          </Grid>
                        </Collapse>
                      </>
                      
                      <InputField
                        placeholder={`Submit`}
                        label="Submit Button"
                        value={form.submit_text}
                        onChange={(e) => handleEditChange(e.target.value, 'submit_text')}
                        margin
                        infotip={
                          <Infotip
                            info
                            title="Submit Button"
                            tip="Replace submit button text"
                            messages={{
                              one: 'Replace the text for the form submit button.',
                              two: 'Add a custom "submit" button text to let users know to click the button to submit the form.'
                            }}
                          />
                        }
                      />

                      <InputField
                        placeholder={`Leave blank if you don't want to redirect after submission`}
                        label="Redirect URL"
                        value={form.redirect_url}
                        onChange={(e) => handleEditChange(e.target.value, 'redirect_url')}
                        // margin
                        infotip={
                          <Infotip
                            info
                            title="Redirect URL"
                            tip="Redirect user after submission"
                            messages={{
                              one: 'The redirect URL must be a full url (http://example.com or https://example.com).',
                              two: 'Leave the input blank if you do not want to redirect contacts after they submit your form.'
                            }}
                          />
                        }
                      />
                    </Grid>

                  </DialogSettings>
                  { // Only show SHARE button if the formEdit has already been saved and has a formEdit id
                    formEdit?.id
                      ?
                      <>
                        <Button
                          onClick={() => setOpenShare(true)}
                          variant="outlined"
                          style={{
                            gap: 8
                          }}
                        // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                        >
                          <ShareSVG width={15} color1={theme.palette.primary.main} />
                          {screenSize.xs ? '' : 'Share'}
                        </Button>
                        <DialogShare
                          open={openShare}
                          onClose={closeShare}
                          header="Share Form"
                          title={`Share your ${formEdit.user_visible_name} form`}
                          messages={{
                            one: 'This will create a link that you can send to your contacts to fill out the formEdit.',
                            confirm: 'Share'
                          }}
                          link={shareLink}
                          embed={shareEmbed}
                          sharing={sharing}
                          cancel={closeShare}
                          // confirm={handleShareForm}
                          icons
                        />
                      </>
                      :
                      null
                  }

                  {
                    preview
                      ?
                        <Button
                          onClick={() => setPreview(prev => !prev)}
                          variant="outlined"
                          style={{
                            width:118,
                            gap: 8
                          }}
                        // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                        >
                          <EditSVG width={15} color1={theme.palette.primary.main} />
                          {screenSize.xs ? '' : 'Edit'}
                        </Button>
                      :
                        <Button
                          onClick={() => setPreview(prev => !prev)}
                          variant="outlined"
                          style={{
                            width:118,
                            gap: 8
                          }}
                        // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                        >
                          <VisibleSVG width={15} color1={theme.palette.primary.main} />
                          {screenSize.xs ? '' : 'Preview'}
                        </Button>
                  }

                  <Button
                    onClick={saveForm}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            :
              null
        }

        <Grid
          id="preview-container"
          container
          style={{
            padding: 10,
            justifyContent: 'center'
          }}
          xs={12}
          sm={10}
          md={8}
          lg={6}
        >
          <Grid
            sx={{
              borderRadius: 2,
              boxShadow: form.style_details?.showShadow || form.style_details?.showShadow === undefined ? '0 0 10px #00000030' : 'none',
              width: '100%',
              background: backgroundColor,
              py: { xs: 1, sm: 2, md: 3 },
              px: { xs: 1, sm: 3, md: 4 },
              maxWidth: form.style_details?.maxWidth,
            }}
          >
            {
              formSubmitted
                ?
                  <SuccessMessage />
                :
                  <>
                    {
                      // FORM HEADER: NAME AND DESCRIPTION
                    }
                    <Grid
                      style={{
                        marginBottom: 16
                      }}
                    >
                      <EditingSection title="TITLE" preview={preview} />
                      <Typography
                        style={{
                          fontSize: '1.5rem',
                          fontWeight: 600
                        }}
                      >
                        {
                          form.user_visible_name
                            ?
                              form.user_visible_name
                            :
                              null
                              // <span style={{ fontStyle: 'italic', color: '#999' }}>Untitled Form</span>
                        }
                      </Typography>
                      {
                        !preview && formEdit
                          ?
                            <EditingItem
                              editing={editing}
                              setEditing={setEditing}
                            >
                              <InputField
                                placeholder='Enter the form name your users will see'
                                label="Form Name"
                                value={formEdit.user_visible_name}
                                onChange={(e) => handleEditChange(e.target.value, 'user_visible_name')}
                                margin
                                infotip={
                                  <Infotip
                                    question
                                    tip="Form name your contacts will see"
                                    title="Form name (public)"
                                    messages={{
                                      one: 'This is the title of the form that will be displayed publicly to let users know what the purpose of the form is. Make it memorable.'
                                    }}
                                  />
                                }
                              />
                              <InputField
                                placeholder='Enter the form name that you will see'
                                label="Internal Form Name"
                                value={formEdit.name}
                                onChange={(e) => handleEditChange(e.target.value, 'name')}
                                margin
                                infotip={
                                  <Infotip
                                    question
                                    tip="Form name only you will see"
                                    title="Form name (internal)"
                                    messages={{
                                      one: 'This is the name that only you will see. This name can be more descriptive and help you organize your forms.'
                                    }}
                                  />
                                }
                              />
                            </EditingItem>
                          :
                            null
                      }
                      <EditingSection title="DESCRIPTION" preview={preview} />
                      <Typography>
                        {
                          form.user_visible_description
                            ?
                              form.user_visible_description
                            :
                              null
                              // <span style={{ fontStyle: 'italic', color: '#999' }}>Form description</span>
                        }
                      </Typography>
                      {
                        !preview && formEdit
                          ?
                            <EditingItem
                              editing={editing}
                              setEditing={setEditing}
                            >
                              <InputField
                                placeholder='Enter a description for the form'
                                label="Form Description (Shown To Users)"
                                value={formEdit.user_visible_description}
                                onChange={(e) => handleEditChange(e.target.value, 'user_visible_description')}
                                multiline
                                minRows={2}
                                maxRows={6}
                                margin
                              />
                            </EditingItem>
                          :
                            null
                      }
                    </Grid>

                    {
                      // NECESSARY FORM INPUT: EMAIL
                    }
                    <EditingSection title="REQUIRED FIELDS" preview={preview} />
                    <InputField
                      label="Email"
                      value={submission.primary_email} s
                      onChange={(e) => handleChange(e.target.value, 'primary_email')}
                      brandColor={brandColor}
                      margin
                    />

                    {
                      // CUSTOM FIELDS
                    }
                    {
                      // form?.custom_fields.length
                      //   ?
                      <>
                        <EditingSection title="CUSTOM FIELDS" preview={preview} />
                        {
                          form?.custom_fields?.map((field, index) => (
                            <>
                              <InputFieldTypeDisplay
                                label={field.user_visible_name}
                                id={field.id}
                                type={field.data_type}
                                // contactId={contactId}
                                customField={field}
                                // customFields={customFields}
                                value={submission.custom_fields[field.id]}
                                onTextChange={(e) => handleTextCustomField(e.target.value, field.id)}
                                onListChange={(e) => handleListCustomField(e, field.id)}
                                onNumberChange={(e) => handleNumberCustomField(e.target.value, field.id)}
                                onDateChange={(e) => handleDateCustomField(e, field.id)}
                                onBoolChange={(e) => handleBoolCustomField(e.target.checked, field.id)}
                                // onImageChange={(e) => handleImageCustomField(e, field.id)}
                                // onFileChange={(e) => handleFileCustomField(e, field.id)}
                                // onRichTextChange={(e) => handleRichTextCustomField(e, field.id)}
                                // enableOnClick={handleClick}
                                boolMessage
                                boolLabel
                                brandColor={brandColor}
                                noAutoFocus
                                margin
                              />

                            </>
                          ))
                        }
                        {
                          (!preview && !showCustomFields) &&
                          <>
                            <BoolField
                              label="Add custom fields?"
                              boolMessage
                              placeholder="Add custom fields for the contact to fill out"
                              value={addToFields}
                              onChange={(e) => setAddToFields(e.target.checked)}
                              infotip={
                                <Infotip
                                  info
                                  title="Custom Fields"
                                  messages={{
                                    one: 'Add custom fields that you want the contact to fill out before submitting the form.'
                                  }}
                                />
                              }    
                            />
                            <Collapse
                              in={addToFields}
                              style={{
                                marginBottom: 24,
                                width: '100%'
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  background: '#fff',
                                  borderTop: `1px solid ${theme.palette.primary.main}`,
                                  // boxShadow:'0 0 10px #00000030',
                                  padding: 16,
                                  maxHeight: '200px', //add scroll bar for custom fields
                                  overflowY: 'auto'
                                }}
                              >
                                <Typography
                                  style={{
                                    // margin:'0 16px',
                                    fontWeight: 600,
                                    color: theme.palette.primary.main
                                  }}
                                >
                                  Custom Fields
                                </Typography>

                                {
                                  customFields?.map((field, index) => {
                                    // Don't show any fields that can't currently be handled correctly
                                    if (
                                      field.data_type == 'image_file' ||
                                      field.data_type == 'file' ||
                                      field.data_type == 'large_text_field'
                                      // field.data_type == 'date'
                                    ) {
                                      return (
                                        <Grid
                                          container
                                          style={{
                                            background: '#EEE',
                                            border: `1px solid ${theme.palette.mid.main}`,
                                            padding: '2px 8px',
                                            margin: 2,
                                            borderRadius: 8,
                                            transition: 'border-radius .4s, padding .4s',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              color: 'inherit'
                                            }}
                                          >
                                            {field.field_name}
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontSize: '.85rem',
                                              lineHeight: 1
                                            }}
                                          >
                                            Not Available
                                          </Typography>
                                        </Grid>

                                      )
                                    };

                                    const selected = formEdit.custom_field_options.map(x => x.id ? x.id : x).includes(field.id);
                                    return (
                                      <Grid
                                        container
                                        onMouseEnter={() => setHover(`field-${field.id}`)}
                                        onMouseLeave={() => setHover(null)}
                                        onClick={() => handleCustomFieldEditChange(field)}
                                        style={{
                                          background: selected ? theme.palette.primary.main : (hover === `field-${field.id}` ? `${theme.palette.primary.light}30` : `${theme.palette.primary.light}15`),
                                          padding: selected ? '2px 16px' : '2px 8px',
                                          margin: 2,
                                          borderRadius: selected ? 16 : 8,
                                          transition: 'border-radius .4s, padding .4s',
                                          justifyContent: 'space-between',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: selected ? '#ffffff' : 'inherit'
                                          }}
                                        >
                                          {field.field_name}
                                        </Typography>
                                        <CheckSVG width={15} color1="#fff" style={{ transform: selected ? 'scale(1)' : 'scale(0)', transition: '.6s' }} />
                                      </Grid>
                                    )
                                  })
                                }
                              </Grid>
                              <Button
                                  onClick={
                                    // () => setShowCustomFields(prev => !prev)
                                    () => handleCustomFieldClose()
                                  }
                                  variant="outlined"
                                  style={{
                                    gap: 8,
                                    marginTop: 8,
                                    marginBottom: 8
                                  }}
                                >
                                  Done
                                </Button>

                            </Collapse>

                          </>
                        }
                        {
                          (form?.custom_fields.length && showCustomFields && !preview) ?
                            <Button
                              onClick={
                                // () => setShowCustomFields(prev => !prev)
                                () => handleCustomFieldClose()
                              }
                              variant="outlined"
                              style={{
                                gap: 8,
                                marginBottom: 8
                              }}
                            // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                            >
                              Edit Custom Fields
                            </Button>
                            :
                            null
                        }

                      </>

                    }


                    {
                      // MAILING LISTS
                    }
                    {
                      <>
                        <EditingSection title="MAILING LISTS" preview={preview} />

                        <Grid container>
                          {
                            form?.mailing_lists.length
                              ?
                                <Typography>
                                  Join the following mailing lists
                                </Typography>
                              :
                                preview
                                  ?
                                    null
                                  :
                                    <Typography style={{textAlign:'center', width:'100%'}}>
                                      No mailing lists are currently an option for contacts to join
                                    </Typography>

                          }
                          {
                            form.mailing_lists.map(list => {
                              const selected = submission.mailing_lists.map(x => x.id ? x.id : x).includes(list.id);
                              return (
                                <Grid
                                  container
                                  onClick={() => handleMailingListChange(list)}
                                  style={{
                                    background: selected ? theme.palette.primary.main : `${theme.palette.primary.light}15`,
                                    padding: selected ? '4px 8px' : '2px 8px',
                                    margin: 4,
                                    borderRadius: selected ? 16 : 8,
                                    transition: 'border-radius .4s, padding .4s',
                                    // justifyContent:'space-between',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <div style={{ width: selected ? 35 : 10, transition: '.25s', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CheckSVG width={15} color1="#fff" style={{ transform: selected ? 'scale(1)' : 'scale(0)', transition: '.5s' }} />
                                  </div>
                                  <Typography
                                    style={{
                                      color: selected ? '#ffffff' : 'inherit'
                                    }}
                                  >
                                    {list.user_visible_name}
                                  </Typography>
                                </Grid>
                              )
                            })
                          }
                        </Grid>

                        {
                          (!preview && !showMailingList) &&
                          <>
                            <BoolField
                              label="Let subscriber join mailing list(s)?"
                              placeholder="Add subscriber to mailing list(s)?"
                              boolMessage
                              value={addToLists}
                              onChange={(e) => setAddToLists(e.target.checked)}
                              infotip={
                                <Infotip
                                  info
                                  title="Mailing Lists"
                                  messages={{
                                    one: 'Add contacts you want this form mailed to.'
                                  }}
                                />
                              }    
                            />
                            <Collapse
                              in={addToLists}
                              style={{
                                marginBottom: 24,
                                width: '100%',
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  background: '#fff',
                                  borderTop: `1px solid ${theme.palette.primary.main}`,
                                  // boxShadow:'0 0 10px #00000030',
                                  padding: 16,
                                }}
                              >
                                <Typography
                                  style={{
                                    // margin:'0 16px',
                                    fontWeight: 600,
                                    color: theme.palette.primary.main
                                  }}
                                >
                                  Mailing Lists
                                </Typography>

                                {
                                  mailingLists?.map((list, index) => {
                                    const selected = formEdit.mailing_list_options.map(x => x.id ? x.id : x).includes(list.id);
                                    return (
                                      <Grid
                                        container
                                        onMouseEnter={() => setHover(`list-${list.id}`)}
                                        onMouseLeave={() => setHover(null)}
                                        onClick={() => handleMailingListEditChange(list)}
                                        style={{
                                          background: selected ? theme.palette.primary.main : (hover === `list-${list.id}` ? `${theme.palette.primary.light}30` : `${theme.palette.primary.light}15`),
                                          padding: selected ? '2px 16px' : '2px 8px',
                                          margin: 2,
                                          borderRadius: selected ? 16 : 8,
                                          transition: 'border-radius .4s, padding .4s',
                                          justifyContent: 'space-between',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: selected ? '#ffffff' : 'inherit'
                                          }}
                                        >
                                          {list.name}
                                        </Typography>
                                        <CheckSVG width={15} color1="#fff" style={{ transform: selected ? 'scale(1)' : 'scale(0)', transition: '.6s' }} />
                                      </Grid>
                                    )
                                  })
                                }
                                <Button
                                  onClick={
                                    () => handleMailingListClose()
                                  }
                                  variant="outlined"
                                  style={{
                                    gap: 8,
                                    marginTop: 8,
                                    marginBottom: 8
                                  }}
                                >
                                  Done
                                </Button>
                              </Grid>
                            </Collapse>
                          </>}
                        {(form?.mailing_lists.length && showMailingList && !preview)
                          ?
                          <Button
                            onClick={
                              () => handleMailingListClose()
                            }
                            variant="outlined"
                            style={{
                              gap: 8,
                              marginBottom: 8,
                              marginTop: 8
                            }}
                          >
                            Edit Mailing Lists
                          </Button>
                          :
                          null}
                      </>

                    }


                    {
                      form.enable_captcha &&
                      <div
                        style={{
                          display:'flex',
                          justifyContent:'center'
                        }}
                      >
                        <ReCAPTCHA
                          sitekey="6Ldaip0pAAAAAAz_R4wD1m2yoS7MJvLnhErQEKQb"
                          onChange={handleRecaptchaChange}
                        />
                      </div>
                    }

                    {
                      // FORM SUBMISSION
                    }
                    <Grid
                      container
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 16
                      }}
                    >
                      <Button
                        onClick={formPreview ? redirectPreview : saveFormSubmission}
                        target="_blank"
                        variant="contained"
                        sx={{
                          background: brandColor,
                          filter: 'brightness(100%)',
                          marginTop: form.enable_captcha ? 2 : 0,
                          '&&:hover': {
                            background: form?.style_details?.brandColor ? brandColor : null,
                            filter: form?.style_details?.brandColor ? 'brightness(110%) hue-rotate(10deg) saturate(120%)' : null,
                            transition: '.4s'
                          }

                        }}
                      >
                        {form.submit_text ? form.submit_text : "Submit"}
                      </Button>
                      {
                        !preview &&
                        <Typography style={{ marginTop: 8 }}>Click to test submit redirect url link</Typography>
                      }
                    </Grid>
                  </>
            }
          </Grid>
        </Grid>

        <SnackbarWrapper
          onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
          notice={snackMessage}
        />

      </>
      :
      null
  )
}

export default FormDisplay

const MailingLists = (props) => {

  return (
    <Grid
      container
    >

    </Grid>
  )
}

const EditingItem = ({ children, buttonText }) => {
  const [edit, setEdit] = useState(false);

  return (
    edit
      ?
      <>
        {children}
        <Button
          variant="outlined"
          onClick={() => setEdit(false)}
          style={{
            marginBottom: 8
          }}
        >
          Done
        </Button>
      </>
      :
      <Button
        variant="outlined"
        onClick={() => setEdit(true)}
        style={{
          marginBottom: 8
        }}
      >
        {buttonText ? buttonText : "Edit"}
      </Button>
  )
}

const EditingSection = (props) => {
  const { title, preview } = props;
  return (
    !preview
      ?
        <Divider style={{marginTop:16}}>
          <Typography
            style={{
              fontSize:'.75rem',
              padding:'2px 12px',
              borderRadius:20,
              fontWeight:600,
              background:'#ddd'
            }}
          >
            {title}
          </Typography>
        </Divider>
      :
        null
  )
}

const SuccessMessage = () => {
  console.log('successssss');

  return (
    <Grid
      container
      variant="center"
      flexDirection="column"
    >
      <img
        src={`https://static.fillmarketing.com/static/static/img/email-love_500px.png`}
        style={{
          height:150,
          width:'auto',
          objectFit:'contain'
        }}
      />

      <Typography
        style={{
          fontWeight:600,
          fontSize:'1.3rem',
          marginTop:16
        }}
      >
        Your form was successfully submitted.
      </Typography>
    </Grid>
  )
}