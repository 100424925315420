import { createContext, useContext, useEffect, useState } from "react";
import { getAllCustomFields, getTenantDataBag, getUserDataBag, getTenantDetails, getUserDetails, getAccount } from "../hooks/mutations";
import { initHeaderColumns } from "../lib/Data";

const apiContext = createContext();

export const useApi = () => {
  return useContext(apiContext);
}

export const ApiProvider = ({children}) => {
  // TEMP STATE
  const [contextCustomFields, setContextCustomFields] = useState(null);
  const [contextAllFields, setContextAllFields] = useState(null);
  const [contextTenant, setContextTenant] = useState(null);
  const [contextUser, setContextUser] = useState(null);
  const [contextTenantDataBag, setContextTenantDataBag] = useState(null);
  const [contextUserDataBag, setContextUserDataBag] = useState(null);
  
  // console.log('contextCustomFields', contextCustomFields)

  // =====================================
  //        USER DETAILS CONTEXT
  // =====================================
  const isLoggedIn = async () => {
    const userDetails = await getAccount();
    console.log('userDetails', userDetails);
    if (userDetails.status === "success") {
      console.log('is logged in')
      return true;
    } else {
      console.log('is NOT logged in')
      return false;
    }
  }


  // =====================================
  //        CUSTOM FIELD CONTEXT
  // =====================================

  // GET CUSTOM FIELDS FROM INDEXED DB ON PAGE LOAD / REFRESH / NEW TAB / ETC
  useEffect(() => {
    getCustomFieldsItem();
  }, [])

  // STORE CUSTOM FIELDS INTO INDEXED DB WHEN THE CUSTOM FIELDS STATE UPDATES
  useEffect(() => {
    if (contextCustomFields) {
      setCustomFieldsItem(contextCustomFields);
    } 
  }, [contextCustomFields])

  // SET CUSTOM FIELDS STATE TO LOCAL STORAGE
  const setCustomFieldsItem = async (customFieldsInfo) => {
    try {
      const customFieldsInfoCopy = JSON.stringify(customFieldsInfo); // make a copy
      localStorage.setItem('customFieldsContext', customFieldsInfoCopy);
    }

    catch (error) {
      console.log('Error setting custom fields info', error);
    }
  }

  // GET CUSTOM FIELDS FROM LOCAL STORAGE
  const getCustomFieldsItem = async () => {
    // Set custom fields state
    try {
      const customFieldsValue = localStorage.getItem('customFieldsContext');
      if (customFieldsValue) {
        setContextCustomFields(JSON.parse(customFieldsValue));
      } else {
        const incoming = await getAllCustomFields();
        reduceCustomFields(incoming.data);
        // const reduced = incoming.data.map(x => ({ data_type: x.data_type, field_name: x.field_name, order: x.order}))
        // setContextCustomFields(reduced);
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting custom fields state:', error);
    }

  }

  // REDUCE CUSTOM FIELDS TO ONLY THE NECESSARY DATA
  // PLACED IN A SEPARATE FUNCTION SO IT CAN BE EXPORTED AND REUSED DURING DYNAMIC CUSTOM FIELD UPDATES
  const reduceCustomFields = (incoming) => {
    const reduced = incoming.map(x => ({ id: x.id, column_type: 'custom', data_type: x.data_type, field_name: x.field_name, order: x.order }))
    setContextCustomFields(reduced);
  }

  // HANDLE SETTING CUSTOM FIELD CONTEX FROM A COMPONENT
  const handleSetContextCustomFields = (context) => {
    setContextCustomFields(context);
  }


  // =====================================
  //        ALL FIELD CONTEXT
  // =====================================

  // GET ALL FIELDS FROM INDEXED DB ON PAGE LOAD / REFRESH / NEW TAB / ETC
  useEffect(() => {
    getAllFieldsItem();
  }, [])

  // STORE ALL FIELDS INTO INDEXED DB WHEN THE ALL FIELDS STATE UPDATES
  useEffect(() => {
    if (contextAllFields) {
      setAllFieldsItem(contextAllFields);
    } 
  }, [contextAllFields])

  // SET ALL FIELDS STATE TO LOCAL STORAGE
  const setAllFieldsItem = async (allFieldsInfo) => {
    try {
      const allFieldsInfoCopy = JSON.stringify(allFieldsInfo); // make a copy
      localStorage.setItem('allFieldsContext', allFieldsInfoCopy);
    }

    catch (error) {
      console.log('Error setting all fields info', error);
    }
  }

  // GET ALL FIELDS FROM LOCAL STORAGE
  const getAllFieldsItem = async () => {
    // Set all fields state
    try {
      const allFieldsValue = localStorage.getItem('allFieldsContext');
      if (allFieldsValue) {
        setContextAllFields(JSON.parse(allFieldsValue));
      } else {
        const incomingCustom = await getAllCustomFields();
        const incomingStandard = initHeaderColumns;
        // const incomingAll = [ ...incoming.data, ...initHeaderColumns ];
        reduceAllFields(incomingCustom.data, incomingStandard);
        // const reduced = incoming.data.map(x => ({ data_type: x.data_type, field_name: x.field_name, order: x.order}))
        // setContextAllFields(reduced);
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting all fields state:', error);
    }

  }

  // REDUCE ALL FIELDS TO ONLY THE NECESSARY DATA
  // PLACED IN A SEPARATE FUNCTION SO IT CAN BE EXPORTED AND REUSED DURING DYNAMIC ALL FIELD UPDATES
  const reduceAllFields = (custom, standard) => {
    const reducedCustom = custom.map(x => ({ id: x.id, column_type: 'custom', data_type: x.data_type, field_name: x.field_name, order: x.order }));
    const reducedStandard = standard.map(x => ({id: x.field, column_type: x.columnType, data_type: x.type, field_name: x.label, field: x.field, order:0, visible:true}));
    const reduced = [ ...reducedCustom, ...reducedStandard ];
    setContextAllFields(reduced);
  }

  const syncAllFields = async (customFieldsContext) => {
  // Step 1: Remap custom fields
  const remappedCustomFields = customFieldsContext.map(x => ({ id: x.id, column_type: 'custom', data_type: x.data_type, field_name: x.field_name, order: x.order }));

  // Step 2: Filter out all "custom" fields from contextAllFields
  const filteredAllFields = contextAllFields.filter(field => field.column_type !== 'custom');

  // Step 3: Add remappedCustomFields items to the filtered array
  const combinedFields = [...remappedCustomFields, ...filteredAllFields];

  // Step 4: Sort the combined array to ensure "custom" type fields are above "default" type fields
  // combinedFields.sort((a, b) => {
  //   if (a.column_type === 'custom' && b.column_type === 'default') {
  //     return -1; // "custom" comes first
  //   } else if (a.column_type === 'default' && b.column_type === 'custom') {
  //     return 1;  // "default" comes after "custom"
  //   }
  //   return 0; // keep the same order for fields with the same column_type
  // });
  setContextAllFields(combinedFields);
  return combinedFields;
  };
  
  // HANDLE SETTING CUSTOM FIELD CONTEX FROM A COMPONENT
  const handleSetContextAllFields = (context) => {
    setContextAllFields(context);
  }


  // =====================================
  //        TENANT CONTEXT
  // =====================================

  useEffect(() => {
    getTenantItem();
  }, [])

  // STORE CUSTOM FIELDS INTO INDEXED DB WHEN THE CUSTOM FIELDS STATE UPDATES
  useEffect(() => {
    if (contextTenant) {
      setTenantItem(contextTenant);
    }
  }, [contextTenant])


  // SET CUSTOM FIELDS STATE TO INDEXED DB API STORE
  const setTenantItem = async (tenantInfo) => {
    try {
      const tenantInfoCopy = JSON.stringify(tenantInfo); // make a copy
      localStorage.setItem('tenantContext', tenantInfoCopy);
    }

    catch (error) {
      console.log('Error setting tenant info', error);
    }
  }

  // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  const getTenantItem = async () => {
    // Set custom fields state
    try {
      const tenantValue = localStorage.getItem('tenantContext');
      if (tenantValue) {
        setContextTenant(JSON.parse(tenantValue));
      } else {
        const loggedIn = await isLoggedIn();
        if (loggedIn) {
          const incoming = await getTenantDetails();
          if (incoming.status === 'success' && typeof(incoming.data) !== "string") {
            setContextTenant(incoming.data);
          }
        }
        // const reduced = incoming.data.map(x => ({ data_type: x.data_type, field_name: x.field_name, order: x.order}))
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting tenant state:', error);
    }

  }

  // HANDLE SETTING TENANT CONTEX FROM A COMPONENT
  const handleSetContextTenant = (context) => {
    setContextTenant(context);
  }



  // =====================================
  //        TENANT DATA BAG CONTEXT
  // =====================================

  useEffect(() => {
    getTenantDataBagItem();
  }, [])

  // STORE CUSTOM FIELDS INTO INDEXED DB WHEN THE CUSTOM FIELDS STATE UPDATES
  useEffect(() => {
    if (contextTenantDataBag) {
      setTenantDataBagItem(contextTenantDataBag);
    }
  }, [contextTenantDataBag])


  // SET CUSTOM FIELDS STATE TO INDEXED DB API STORE
  const setTenantDataBagItem = async (dataBagInfo) => {
    try {
      const dataBagInfoCopy = JSON.stringify(dataBagInfo); // make a copy
      localStorage.setItem('tenantDataBagContext', dataBagInfoCopy);
    }

    catch (error) {
      console.log('Error setting tenant dataBag info', error);
    }
  }

  // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  const getTenantDataBagItem = async () => {
    // Set custom fields state
    try {
      const dataBagValue = localStorage.getItem('tenantDataBagContext');
      if (dataBagValue) {
        setContextTenantDataBag(JSON.parse(dataBagValue));
      } else {
        const loggedIn = await isLoggedIn();
        if (loggedIn) {
          const incoming = await getTenantDataBag();
          if (incoming.status === 'success' && typeof(incoming.data) !== "string") {
            setContextTenantDataBag(incoming.data);
          }
        }
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error getting tenant dataBag state:', error);
    }

  }

  // HANDLE SETTING TENANT DATA BAG CONTEXT FROM A COMPONENT
  const handleSetContextTenantDataBag = (context) => {
    setContextTenantDataBag(context);
  }
  


    // =====================================
  //        USER CONTEXT
  // =====================================

  useEffect(() => {
    getUserItem();
  }, [])

  // STORE CUSTOM FIELDS INTO INDEXED DB WHEN THE CUSTOM FIELDS STATE UPDATES
  useEffect(() => {
    if (contextUser) {
      setUserItem(contextUser);
    }
  }, [contextUser])


  // SET CUSTOM FIELDS STATE TO INDEXED DB API STORE
  const setUserItem = async (userInfo) => {
    try {
      const userInfoCopy = JSON.stringify(userInfo); // make a copy
      localStorage.setItem('userContext', userInfoCopy);
    }

    catch (error) {
      console.log('Error setting user info', error);
    }
  }

  // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  const getUserItem = async () => {
    // Set custom fields state
    try {
      const userValue = localStorage.getItem('userContext');
      if (userValue) {
        setContextUser(JSON.parse(userValue));
      } else {
        const loggedIn = await isLoggedIn();
        if (loggedIn) {
          const incoming = await getUserDetails();
          if (incoming.status === 'success' && typeof(incoming.data) !== "string") {
            setContextUser(incoming.data);
            // const reduced = incoming.data.map(x => ({ data_type: x.data_type, field_name: x.field_name, order: x.order}))
          }
        }
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting user state:', error);
    }

  }

  // HANDLE SETTING USER CONTEXT FROM A COMPONENT
  const handleSetContextUser = (context) => {
    setContextUser(context);
  }



// =====================================
  //        USER DATA BAG CONTEXT
  // =====================================

  useEffect(() => {
    getUserDataBagItem();
  }, [])

  // STORE CUSTOM FIELDS INTO INDEXED DB WHEN THE CUSTOM FIELDS STATE UPDATES
  useEffect(() => {
    if (contextUserDataBag) {
      setUserDataBagItem(contextUserDataBag);
    }
  }, [contextUserDataBag])


  // SET CUSTOM FIELDS STATE TO INDEXED DB API STORE
  const setUserDataBagItem = async (dataBagInfo) => {
    try {
      const dataBagInfoCopy = JSON.stringify(dataBagInfo); // make a copy
      localStorage.setItem('userDataBagContext', dataBagInfoCopy);
    }

    catch (error) {
      console.log('Error setting user dataBag info', error);
    }
  }

  // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  const getUserDataBagItem = async () => {
    // Set custom fields state
    try {
      const dataBagValue = localStorage.getItem('userDataBagContext');
      if (dataBagValue) {
        setContextUserDataBag(JSON.parse(dataBagValue));
      } else {
        const loggedIn = await isLoggedIn();
        if (loggedIn) {
          const incoming = await getUserDataBag();
          if (incoming.status === 'success' && typeof(incoming.data) !== "string") {
            setContextUserDataBag(incoming.data);
          }
        }
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error getting user dataBag state:', error);
    }

  }

  // HANDLE SETTING USER DATA BAG CONTEXT FROM A COMPONENT
  const handleSetContextUserDataBag = (context) => {
    setContextUserDataBag(context);
  }



  // =====================================
  //        USING LOCAL FORAGE
  // =====================================

  // ====================================================================================
  // LOCAL FORAGE CONFIG
  // ====================================================================================
  // localforage.config({
  //   name        : 'apiStore',
  //   storeName   : 'apiStore', // Should be alphanumeric, with underscores.
  // });
  // const apiStore = localforage.createInstance({name: 'apiStore', storeName: 'apiStore'});


  // // SET CUSTOM FIELDS STATE TO INDEXED DB API STORE
  // const setCustomFieldsItem = async (customFieldsInfo) => {
  //   try {
  //     const customFieldsInfoCopy = JSON.parse(JSON.stringify(customFieldsInfo)); // make a copy
  //     await apiStore.setItem('customFieldsContext', customFieldsInfoCopy);
  //   }

  //   catch (error) {
  //     console.log('Error setting custom fields info', error);
  //   }
  // }

  // // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  // const getCustomFieldsItem = async () => {
  //   // Set custom fields state
  //   try {
  //     const customFieldsValue = await apiStore.getItem('customFieldsContext');
  //     setContextCustomFields(customFieldsValue);

  //   } catch (error) {
  //       // This code runs if there were any errors.
  //       console.log('Error setting custom fields state:', error);
  //   }

  // }




  return (
    <>
      <apiContext.Provider
        value={{
          contextCustomFields,
          handleSetContextCustomFields,
          contextAllFields,
          handleSetContextAllFields,
          contextTenant,
          handleSetContextTenant,
          contextUser,
          handleSetContextUser,
          contextTenantDataBag,
          handleSetContextTenantDataBag,
          contextUserDataBag,
          handleSetContextUserDataBag,
          reduceCustomFields,
          syncAllFields
        }}
      >
        {children}
      </apiContext.Provider>
    </>
  )
}