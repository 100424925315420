import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Dialog, Divider, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { deleteForm, putForm, useGetAllCustomFields, useGetFilteredForms } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG, CheckSVG, SelectSVG, RemoveFillSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { formColumns } from '../../lib/Data';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import CustomTooltip from '../ui/CustomTooltip';
import ReusableModal from '../ui/ReusableModal';
import { mockData } from '../../lib/mockData';
import Loading from '../ui/Loading';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import ActionContent from '../ui/ActionContent';

const Forms = () => {
  const [reloadForms, setReloadForms] = useState(false);
  const [reloadFields, setReloadFields] = useState();
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingForms = useGetFilteredForms({reload:reloadForms, filterBy:filterBy});
  const customFields = useGetAllCustomFields(reloadFields); // (mockData.custom_fields);  // <-- Replace mockData
  const screenSize = useScreenSize();
  const [forms, setForms] = useState(incomingForms); // (mockData.forms); // <-- Replace mockData
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();

  console.log('customFields', customFields);

  // Reset 'reloadForms' state to false after it is triggered
  useEffect(() => {
    if (reloadForms) {
      setReloadForms(false);
    }
  }, [reloadForms])

  // Reset 'reloadFields' state to false after it is triggered
  useEffect(() => {
    if (reloadFields) {
      setReloadFields(false);
    }
  }, [reloadFields])

  // Set forms state to incoming data on load
  useEffect(() => {
    if (incomingForms) {
      setForms(incomingForms.results);
      const next = incomingForms.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingForms.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingForms.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingForms]);

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => forms.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

  const displayColumns = [
    {
      field: 'name',
      label: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/forms/${params.row.id}/edit/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'user_visible_name',
      label: 'Visible Name',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'user_visible_description',
      label: 'Description',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'actions',
      label: '',
      width:100,
      // flex:1,
      editable: false,
      renderCell: (params) => (<FormActions 
        form={params.row}
        setReloadForms={setReloadForms} />)
    }
  ]

  const dataColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/forms/${params.row.id}/edit/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'user_visible_name',
      headerName: 'Visible Name',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'user_visible_description',
      headerName: 'Description',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'actions',
      headerName: '',
      width:100,
      // flex:1,
      editable: false,
      renderCell: (params) => (<FormActions 
        form={params.row}
        setReloadForms={setReloadForms} />)
    }
  ]

  return (
    <Grid
      container
      style={{
        // height:'calc(100vh - 148px)', // Add or subtract 55px if the Footer Paginate comopnent is used
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >

        {/* <HeaderRow />
        <TableWrapper>
          {
            forms?.length
              ?
                forms.map((form, index) => (
                  <TableRow
                    form={form}
                    index={index}
                    key={form.id}
                    setReloadForms={setReloadForms}
                  />
                ))
              :
                <Typography>No forms have been created yet.</Typography>
          }
        </TableWrapper> */}


      </Grid>
        {
          !isSearching || forms.length
            ?
              // <DataGrid
              //   getRowId={(row) => row.id} 
              //   rows={forms?.length ? forms : []}
              //   columns={dataColumns}
              //   density="comfortable"
              //   disableVirtualization
              //   onRowSelectionModelChange={(ids) => {
              //     handleRowSelection(ids);
              //   }}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 20,
              //       },
              //     },
              //   }}
              //   sx={{
              //     '&.MuiDataGrid-root':{
              //       borderBottom:'none',
              //       '& .MuiDataGrid-cell:focus': {
              //         outline: 'none',
              //         borderBottom:`2px solid ${theme.palette.primary.main}`,
              //         background:`${theme.palette.primary.light}15`
              //       }
              //     },
              //     ...fill_mui_scrollbar
              //   }}
              //   // pageSizeOptions={[5]}
              //   // autoPageSize
              //   checkboxSelection
              //   disableRowSelectionOnClick
              // />
              <FillTable
                title="Forms List"
                rows={forms?.length ? forms : []}
                columns={displayColumns}
                // onRowClick={handleRowClick}
                setFilterBy={setFilterBy}
                pages={pages}
                selectedRows={selected}
                setSelectedRows={setSelected}
                handleRowSelection={handleRowSelection}
                actionCell={(row) => (
                  <FormActions form={row} setReloadForms={setReloadForms} />
                )}
                renderCell={(row, column) => (
                  <Stack direction="row">
                    <RenderCell row={row} column={column} />
                  </Stack>
                )}
                searchButton={
                  <ReusableFilterSearch
                    useSearch
                    isOpen
                    placeholder="Search Forms"
                    content={forms}
                    setContent={setForms}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    searchFields={['name']}
                  />
                }
                toolbarButtons={[
                  <CustomTooltip
                    title="Create Form"
                  >
                    <IconButton
                      href="/forms/new/"
                      text={screenSize.sm ? null : "New Form"}
                    >
                      <AddSVG width={18} />
                    </IconButton>
                  </CustomTooltip>
                ]}
                toolbarActions={
                  <BulkActions
                    selected={selected}
                    fields={customFields}
                    setReloadForms={setReloadForms}
                  />
                }
                sx={{
                  ...fill_mui_scrollbar
                }}
              />

            :
              <Grid
                container
                style={{
                  justifyContent:'center',
                }}
              >
                <Typography>No Forms Found.</Typography>
              </Grid>
        }

    </Grid>
  )
}

export default Forms

const HeaderRow = (props) => {
  const { align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16,
        // borderRadius:'4px 4px 0 0'
      }}
    >
      {
        formColumns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        maxHeight:200
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { form, index, setReloadForms } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const deleteField = async () => {
    setDeleting(true);
    const deletedForm = await deleteForm(form.id)
    if (deletedForm.status === 'success') {
      setReloadForms(true); // <-- Reload the current forms to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
    }
  }


  return (
    <Grid
      container
      style={{
        background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'12px 32px',
      }}
    >
      {
        formColumns.map(item => (
          <TableColumn item={item}>
            {
              // item.id === 'data_type' &&
              //   <Grid
              //     style={{
              //       width:50,
              //       display:'flex',
              //       alignItems:'center',
              //       justifyContent:'center',
              //       marginLeft:-10
              //     }}
              //   >
              //     {cloneElement(selectedIcons[form.data_type], {color1:'#777'})}
              //   </Grid>
            }
            {
              item.id === 'actions' &&
                <div
                  style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'flex-end',
                    width:'100%'
                  }}
                >
                  {/* <Link
                    to={`/forms-take/${form.id}`}
                    style={{
                      textDecoration:'none',
                      color:'inherit'
                    }}
                  >
                    <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                    >
                      <VisibleSVG width={20} color1="#777" />
                    </Grid>
                    </Link>*/}
                  <a
                    href={`/forms/${form.id}/edit/`}
                    style={{
                      textDecoration:'none',
                      color:'inherit'
                    }}
                  >
                    <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                    >
                      <EditSVG width={17} color1="#777" />
                    </Grid>
                  </a>

                  <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                      onClick={() => setOpenConfirmDelete(true)}
                  >
                    <DeleteSVG width={13} color1="#777" />
                  </Grid>
                  
                </div>
            }
            <Typography style={{ fontSize:'1rem'}}>{form[item.id]}</Typography>
          </TableColumn>
        ))
      }

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Form"
        title={`You are about to delete the ${form.name} form`}
        messages={{
          one:'Are you sure you want to delete this form? It will be permanently deleted.',
          confirm:'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={deleteField}
        icons
      />

    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}

const FormActions = (props) => {
  const { form, setReloadForms } = props;
  // const form = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const removeForm = async () => {
    setDeleting(true);
    const deletedForm = await deleteForm(form.id)
    if (deletedForm.status === 'success') {
      setReloadForms(true); // <-- Reload the current forms to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
    }
  }


  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit form"
      >
        <IconButton
          href={`/forms/${form.id}/edit/`}
        >
          <EditSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete form"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Form"
        title={`You are about to delete the ${form.name} form`}
        messages={{
          one:'Are you sure you want to delete this form? It will be permanently deleted.',
          confirm:'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removeForm}
        icons
      />

    </Stack>

  )

}

const BulkActions = (props) => {
  const { selected, fields, setReloadForms, setReloadFields } = props;
  const [action, setAction] = useState(null);
  const [warning, setWarning] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedOptIn, setSelectedOptIn] = useState(null);
  const theme = useTheme();
  const screenSize = useScreenSize();

  const goToCustomFields = () => {
    window.location.href = "/"
  }

  const handleCancel = () => {
    setAction(null);
    // setSelectedOptIn();
    setSelectedFields([]);
    setProgress(0);
  }

  return (
    <Grid
      container
      gap={1}
    >
      <ActionButton
        label="Add Custom Fields"
        icon={<SelectSVG height={40} />}
        color={theme.palette.primary.dark}
        setAction={() => {
          fields
            ? setAction('field')
            : setWarning('field')
        }}
        handleCancel={handleCancel}
      />
      <ActionButton
        label="Change Opt-in mode"
        icon={<CheckSVG height={40} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('optin')}
        handleCancel={handleCancel}
      />
      <ActionButton
        label="Delete Forms"
        icon={<DeleteSVG height={40} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('delete')}
        handleCancel={handleCancel}
      />

        <DialogWarning
          open={warning}
          onClose={() => setWarning(null)}
          header="No custom fields"
          title={`You have not created any custom fields yet. You must create at least one custom field before adding custom fields to your form.`}
          messages={{
            one:'Do you want to leave surveys and create a custom field now?',
            confirm:'Yes'
          }}
          // deleting
          // cancel={handleCloseWarning}
          // confirm={handleFieldRemove}
          // icons
        />

      <Dialog
        open={action}
        onClose={() => setAction(null)}
        scroll={screenSize.md ? 'body' : 'paper'}
      >
        {
          action === 'field' &&
          <BulkFields
            selected={selected}
            fields={fields}
            setReloadForms={setReloadForms}
            handleCancel={handleCancel}
          />
        }
        {
          action === 'optin' &&
          <BulkOptin
            selected={selected}
            setReloadForms={setReloadForms}
            handleCancel={handleCancel}
          />
        }
        {
          action === 'delete' &&
          <BulkDelete
            selected={selected}
            setReloadForms={setReloadForms}
            handleCancel={handleCancel}
          />
        }
      </Dialog>

    </Grid>
  )
}

const BulkFields = (props) => {
  const { selected, fields, setReloadForms, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedFields, setSelectedFields] = useState([]);
  const theme = useTheme();

  const updateForms = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const form = selected[index];
        
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            addField(selectedFields, form)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadForms) {
        setReloadForms(true);
      }

    }
    
    catch (error) {
      console.log()
    }
  };

  const addField = async (fields, form) => {
    const existingFieldNames = [ ...form.custom_field_options ];
    const newFieldNames = fields.map(x => x.id);
    // console.log('existingFieldNames', existingFieldNames);
    const combinedFields = [ ...new Set([ ...existingFieldNames, ...newFieldNames ]) ];
    // console.log('combinedFields', combinedFields);
  
    const formObj = {
      formId: form.id,
      payload: { ...form, custom_field_options: combinedFields}
    }

    // console.log('formObj', formObj);
    const updatedForm = await putForm(formObj);
    if (updatedForm.status === 'success') {
      console.log('successfully added new field to forms', updatedForm.data)
    }

  }

  const handleFieldClick = (field) => {
    if (selectedFields.includes(field)) {
      const newFields = selectedFields.filter(x => x.id != field.id);
      setSelectedFields(newFields);
    } else {
      const newFields = [ ...selectedFields, field ];
      setSelectedFields(newFields);
    }
  }

  return (
    <ActionContent
      title="Add Field"
      progress={progress}
      cancel={handleCancel}
      confirm={updateForms}
      confirmCTA="Add Fields"
      completedMessage="Finished Adding Fields"
    >
      <Grid
        container
        style={{
          gap:8,
          justifyContent:'center'
        }}
      >
        {
          fields.length
            ?
              fields.map(field => (
                <a
                  onClick={() => handleFieldClick(field)}
                  style={{
                    cursor:'pointer'
                  }}
                >
                  {/* {console.log('field in a', field)} */}
                  <Grid
                    style={{
                      outline: selectedFields.includes(field) ? `1px solid ${theme.palette.primary.main}` : 'none',
                      background: selectedFields.includes(field) ? `${theme.palette.primary.light}20` : 'none',
                      padding:'2px 12px 2px 2px',
                      borderRadius:20,
                      display:'flex'
                    }}
                  >
                    <CheckSVG width={15} color1={theme.palette.primary.main} style={{padding:'0 4px', width:selectedFields.includes(field) ? 15 : 0, transform:selectedFields.includes(field) ? `scale(1)` : `scale(0)`, transition:'.4s'}} />
                    <Typography
                    >
                      {field.field_name}
                    </Typography>
                  </Grid>
                </a>
              ))
            :
              <Typography
                style={{
                  margin:'36px 0'
                }}
              >
                You have not created any fields yet.
              </Typography>

        }
      </Grid>
    </ActionContent>
  )
}

const BulkOptin = (props) => {
  const { selected, setReloadForms, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedOptIn, setSelectedOptIn] = useState(null);
  const theme = useTheme();

  const updateForms = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const form = selected[index];
        
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            addOptin(selectedOptIn, form)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadForms) {
        setReloadForms(true);
      }

    }
    
    catch (error) {
      console.log()
    }
  };

  const addOptin = async (value, form) => {
  
    const formObj = {
      formId: form.id,
      payload: {
        ...form,
        double_opt_in: value,
      }
    }

    // console.log('formObj', formObj);
    const updatedForm = await putForm(formObj);
    if (updatedForm.status === 'success') {
      console.log('successfully added new tag to forms', updatedForm.data)
    }

  }

  const handleOptinClick = (value) => {
      setSelectedOptIn(value);
  }

  return (
    <ActionContent
      title="Use Double Opt-in"
      progress={progress}
      cancel={handleCancel}
      confirm={updateForms}
      confirmCTA="Update Opt-in"
      completedMessage="Finished Adding Forms To Mailing Lists"
    >
      <Grid
        container
        style={{
          justifyContent:'center'
        }}
      >
        {
          [{label:"No", value:false}, {label:"Yes", value:true}].map((optin, index) => {
            const selectedValue = optin.value === true ? selectedOptIn : !selectedOptIn;
            return (
              <Grid
                item
                // onMouseEnter={() => setHover(`optin-${optin.id}`)}
                // onMouseLeave={() => setHover(null)}
                onClick={() => handleOptinClick(optin.value)}
                style={{
                  background: selectedValue ? theme.palette.primary.main : `${theme.palette.primary.light}15`,
                  padding: '16px 8px',
                  margin:4,
                  borderRadius: 8,
                  transition:'border-radius .4s, padding .4s',
                  cursor:'pointer',
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
                xs
              >
                {
                  optin.value
                    ?
                      <CheckSVG width={30} color1={selectedValue ? "#fff" : theme.palette.primary.light} />
                    :
                      <RemoveFillSVG width={30} color1={selectedValue ? "#fff" : theme.palette.primary.light} color2={selectedValue ? theme.palette.primary.main : "#fff"} />
                }
                <Typography
                  style={{
                    fontSize:'1.3rem',
                    fontWeight:700,
                    color: selectedValue ? '#ffffff' : theme.palette.primary.light
                  }}
                >
                  {optin.label}
                </Typography>
                </Grid>
            )
          })
        }
      </Grid>

    </ActionContent>
  )
}

const BulkDelete = (props) => {
  const { selected, setReloadForms, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const theme = useTheme();

  const updateForms = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const form = selected[index];
        
        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue*100); // Update progress state
            removeForm(form)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadForms) {
        setReloadForms(true);
      }
    }
    
    catch (error) {
      console.log()
    }
  };

  const removeForm = async (form) => {
    const deletedForm = await deleteForm(form.id);
    if (deletedForm.status === 'success') {
      console.log('successfully deleted the form(s)', deletedForm.data)
    }

  }

  return (
    <ActionContent
      title="Delete forms"
      progress={progress}
      cancel={handleCancel}
      confirm={updateForms}
      confirmCTA="Delete Forms"
      completedMessage="Finished Deleting Forms"
    >
      <Grid
        container
        style={{
          justifyContent:'center'
        }}
      >
        <Typography

        >
          The following forms will be permanently deleted. Are you sure?
        </Typography>
        <Grid
          container
          style={{
            marginTop:16,
            maxHeight:'70vh',
            overflowY:'auto'
          }}
        >
          {
            selected.map(form => (
              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {form.name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  {console.log(new Date(form.modified).toLocaleDateString())}
                  <Typography
                  >
                    {new Date(form.modified).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Divider style={{width:'100%', margin:'8px 0'}} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>

    </ActionContent>
  )
}

const ActionButton = (props) => {
  const { label, icon, color, setAction } = props;

  return (
    <CustomTooltip
      title={label}
    >
      <IconButton
        onClick={setAction}
        style={{
          width:34,
          height:34
        }}
      >
        {
          cloneElement(icon, {color1: color, height:18})
        }
      </IconButton>
    </CustomTooltip>
  )
}

// const ActionContent = (props) => {
//   const { children, progress, title, cancel, confirm, confirmCTA, completedMessage } = props;

//   return (
//     <Grid container>
//       <Typography
//         style={{
//           fontSize:'1.3rem',
//           width:'100%',
//           textAlign:'center'
//         }}
//       >
//         {title}
//       </Typography>
      
//       {
//         progress < 100 &&
//           <Grid container style={{margin:'16px 0'}}>
//             {children}
//           </Grid>
//       }

//       {
//         progress < 100 && progress > 0
//           ?
//             <>
//               <LinearProgress variant="determinate" value={progress} style={{width:'100%', height:10, borderRadius:20}} />
//               <Typography
//                 color="primary"
//                 style={{
//                   textAlign:'center',
//                   width:'100%',
//                   fontWeight:600,
//                 }}
//               >
//                 {Math.floor(progress)}%
//               </Typography>
//             </>
//           :
//             progress > 0
//               ?
//                 <Typography
//                   color="primary"
//                   style={{
//                     textAlign:'center',
//                     width:'100%',
//                     fontWeight:600,
//                     margin:'36px 0'
//                   }}
//                 >
//                   {completedMessage}
//                 </Typography>
//               : null

//       }
//       <Grid
//         container
//         style={{
//           justifyContent:'space-between'
//         }}
//       >
//         <Divider style={{width:'100%', margin:'16px 0 8px 0'}} />
//         <Button
//           onClick={cancel}
//         >
//           {`< Back`}
//         </Button>
//         <Button
//           onClick={confirm}
//         >
//           {confirmCTA}
//         </Button>

//       </Grid>
//     </Grid>

//   )
// }

const RenderCell = ({ row, column }) => {

  switch (column.field) {
    case 'name':
      return (
        <a
          href={`/forms/${row.id}/edit/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.name}
        </a>
      )
    case 'user_visible_name':
      return (
        row.user_visible_name
      )
    case 'user_visible_description':
      return (
        row.user_visible_description
      )
    default:
      break;
  }
}