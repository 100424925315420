import React from 'react'
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import MainNav from '../components/nav/MainNav';
import FooterNav from '../components/nav/FooterNav';

const LandingTemplate = (props) => {
  const { children, background, title, description, name, type, hideNav, centered } = props;
  const theme = useTheme();
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;

  return (
    <div
      style={{
      }}
    >
      <Helmet>
        <title>{title ? title : ''} | Fill Marketing</title>
        <meta name={'description'} content={description} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        { /* End Twitter tags */ }
      </Helmet>
      <MainNav />
      {children}
      <FooterNav />
    </div>
  )
}

export default LandingTemplate