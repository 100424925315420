import React, { useEffect, useState } from 'react'
import { Button, Grid, InputAdornment, Typography, Alert, AlertTitle, CircularProgress, MenuItem, TextField, Select, Collapse } from '@mui/material';
import { HiddenSVG, VisibleSVG } from '../../assets/icons';
import { GeneralSelectField, InputField } from '../ui/Fields';
import { postAdminDetails, postRegisterTenant, putAdminDetails } from '../../hooks/mutations';
import { useTheme } from "@emotion/react";
import { getMarkerEnd } from 'reactflow';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { LogoHorizontal } from '../../assets/logos';
import { CountrySelect, GetCity, GetCountries, GetState } from 'react-country-state-city';
import "react-country-state-city/dist/react-country-state-city.css";

const details = {
  address: {
    locality: {
      state: {
        country: {
          code: "st",
          name: "string"
        },
        name: "string",
        code: "string"
      },
      name: "string",
      postal_code: "string"
    },
    street_number: "string",
    route: "string",
    raw: "string",
    formatted: "string",
    latitude: 0,
    longitude: 0
  },
  created: "2024-06-30T18:04:10.874Z",
  modified: "2024-06-30T18:04:10.874Z",
  support_email_address: "user@example.com",
  phone_number: "string"
}

const Step3TenantDetails = ({ setRegisterTenantDetails }) => {
  // const [registerCountry, setRegisterCountry] = useState({name: 'US', code: ''});
  const [registerCountry, setRegisterCountry] = useState({name: '', code: ''});
  const [registerState, setRegisterState] = useState({name: '', code: ''});
  const [registerCity, setRegisterCity] = useState('');
  const [registerStreetAddress, setRegisterStreetAddress] = useState(null);
  const [registerZipCode, setRegisterZipCode] = useState('');
  const [registerSupportEmail, setRegisterSupportEmail] = useState('');
  const [registerPhone, setRegisterPhone] = useState('');
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const theme = useTheme();

  // Only display certain countries (North America subregion)
  useEffect(() => {
    GetCountries().then((result) => {
      const northAmerica = result.filter(x => ["United States", "Canada", "Mexico"].includes(x.name))
      setCountryList(northAmerica);
    })
  }, []);

  const handleCountry = (value) => {
    const countryValue = { name: value.name, code: value.id };
    setRegisterCountry(countryValue);
    handleRegisterTenantDetails('registerCountry', countryValue);
    GetState(value.id).then((result) => {
      setStateList(result);
    })
  }

  const handleState = (value) => {
    const stateValue = { name: value.name, code: value.id } 
    setRegisterState(stateValue);
    handleRegisterTenantDetails('registerState', stateValue);
    // console.log(registerCountry.code, value.id);
    // GetCity(registerCountry.code, value.id).then((result) => {
    //   setCityList(result);
    // })
  }

  // Pass state back to parent component (Step3Tenant)
  const handleRegisterTenantDetails = (field, value) => {
    setRegisterTenantDetails(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const addDetails = async () => {
    setButtonClicked(true);
    // setErrorDisplay(null);
    
    if (
      !registerStreetAddress ||
      !registerCity ||
      !registerCountry ||
      !registerState ||
      !registerZipCode
    ) {
      setSnackMessage({
        open: true,
        message: [
          !registerStreetAddress && <div>Please enter the street address</div>,
          !registerCity && <div>Please enter the city</div>,
          !registerCountry && <div>Please enter the country</div>,
          !registerState && <div>Please enter the state</div>,
          !registerZipCode && <div>Please enter the zip code</div>
        ],
        severity:'error'
      });
    }

    const adminObj = {
      payload: {
        address: {
          locality: {
            state: {
              country: {
                code: registerCountry.code || "",
                name: registerCountry.name || ""
              },
              name: registerState.name || "",
              code: registerState.code || ""
            },
            // name: "string",
            postal_code: registerZipCode || ""
          },
          street_number: registerStreetAddress || "",
          // route: "string",
          // raw: "string",
          // formatted: "string",
          // latitude: 0,
          // longitude: 0
        },
        // support_email_address: registerSupportEmail || "",
        // phone_number: registerPhone || ""
      }
    }

    const registered = await putAdminDetails(adminObj);
    console.log('registered', registered);
    if (registered.status === "success") {
      // window.location = "/pricing/";
      console.log('success', registered);
    } else {
      // setErrorDisplay("Please fill out all required fields")
      setButtonClicked(false);
    }
  }


  // const ErrorDisplay = () => {

  //   return (
  //     errorDisplay &&
  //     <Grid container justifyContent="center" marginBottom={2}>

  //       <Alert severity="warning">
  //         <AlertTitle>{errorDisplay}</AlertTitle>
  //       </Alert>
  //     </Grid>

  //   )
  // }

  return (
    // <Grid
    //   container
    //   variant="center"
    //   style={{
    //     marginTop:40
    //   }}
    // >
    //   <Grid
    //     container
    //     // justifyContent="center"
    //     sx={{
    //       // p: 3,
    //       boxShadow:'0 0 10px #00000030',
    //       background:'#ffffff',
    //       borderRadius:2
    //     }}
    //     xs={11}
    //     sm={10}
    //     md={8}
    //     lg={6}
    //   >
    //     <Grid
    //       container
    //       sx={{
    //         flexDirection:'column',
    //         padding:4
    //       }}
    //     >
      <>
          <Grid
            variant="center"
            sx={{
              flexDirection:'column'
            }}
          >
            {/* <LogoHorizontal
              width={65}
            /> */}
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                fontSize:'1.4rem',
                fontWeight: 600
              }}
            >
              COMPANY DETAILS
            </Typography>
          </Grid>

          <InputField
            label="Country"
            value={registerCountry || ""}
            margin
            select
            SelectProps={{
              displayEmpty:true,
              renderValue: selected => selected.name
            }}
            onChange={(e) => handleCountry(e.target.value)}
            fullWidth
          >
            {
              countryList.map(country => (
                <MenuItem
                  key={country.id}
                  value={country}
                >
                  {country.name}
                </MenuItem>
              ))
            }
          </InputField>

          <Collapse
            in={registerCountry.name}
            style={{
              width:'100%'
            }}
          >
            <InputField
              label="Street Address"
              value={registerStreetAddress}
              margin
              onChange={(e) => {
                setRegisterStreetAddress(e.target.value);
                handleRegisterTenantDetails('registerStreetAddress', e.target.value);
              }}
              fullWidth
            />
           
            <InputField
              label="City"
              value={registerCity}
              margin
              onChange={(e) => {
                setRegisterCity(e.target.value);
                handleRegisterTenantDetails('registerCity', e.target.value);
              }}
              fullWidth
            />
            
            <InputField
              label="State"
              value={registerState || ""}
              margin
              select
              SelectProps={{
                displayEmpty:true,
                renderValue: selected => selected.name
              }}
              onChange={(e) => handleState(e.target.value)}
              fullWidth
            >
              {
                stateList.map(state => (
                  <MenuItem
                    key={state.id}
                    value={state}
                  >
                    {state.name}
                  </MenuItem>
                ))
              }
            </InputField>

            <InputField
              label="Zip Code"
              value={registerZipCode}
              margin
              onChange={(e) => {
                setRegisterZipCode(e.target.value);
                handleRegisterTenantDetails('registerZipCode', e.target.value);
              }}
              fullWidth
            />
          </Collapse>

          {/* <InputField
            label="Support Email Address"
            value={registerSupportEmail}
            margin
            onChange={(e) => setRegisterSupportEmail(e.target.value)}
            fullWidth
          /> */}

        {/* <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            startIcon={buttonClicked && !errorDisplay ? <CircularProgress size={16} thickness={8} style={{ color: '#fff' }} /> : null}
            onClick={addDetails}
            style={{
              minWidth: 120,
              margin: '0 0 10px 16px',
            }}
          >
            {buttonClicked && !errorDisplay ? "Creating company" : "Add Details"}
          </Button>
        </Grid> */}
      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
      </>

           
      //   </Grid>
      // </Grid>


    // </Grid>
  )
}

export default Step3TenantDetails