import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import IndustryTemplate from '../components/industry/IndustryTemplate'
import { industryDetails, industryList } from '../lib/Data'

const IndustryDetailPage = () => {
  const path = window.location.pathname.split('/');
  const current = path[path.length-2];
  const industry = industryList.filter(x => x.hash === current)[0];
  const detail = industryDetails[current];
  // console.log('path', path);
  // console.log('current', current);
  // console.log('industry', industry);
  // console.log('detail', detail);

  return (
    <LandingTemplate
      title={detail.meta.title}
      description={detail.meta.description}
      name="Fill CRM"
      type="article"
    >
      <IndustryTemplate industry={industry} detail={detail} />
    </LandingTemplate>
  )
}

export default IndustryDetailPage