import React, { useState, useEffect } from 'react'
import { Button, Dialog, Drawer, Grid, Typography } from '@mui/material'
import { AccountSVG, CloseFilledSVG, RadialClosedSVG } from '../../assets/icons';
import PreferencesFieldOrder from './PreferencesFieldOrder';
import PreferencesContactProfiles from './PreferencesContactProfiles';
import { useTheme } from '@emotion/react';
import SettingsGeneral from './SettingsGeneral';
import Infotip from '../ui/Infotip';
import PreferencesGeneral from './PreferencesGeneral';

const PreferencesModal = () => {
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  // Listen for settings button click
  useEffect(() => {
    const settingsButton = document.getElementById('settings-button');

    if (settingsButton) {
        settingsButton.addEventListener('click', handleOpen);
    }

    return () => {
        if (settingsButton) {
            settingsButton.removeEventListener('click', handleOpen);
        }
    };
  }, []); // Empty dependency array to run the effect only once


  return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        scroll="paper"
        PaperProps={{
          sx:{
            borderRadius:8,
            // height:'92vh',
            background:'#ffffff',
            minHeight:'92vh',
          }
        }}
      >
        <Grid
          sx={{
            height:'100%',
          }}
        >
          <SettingsContent />
        </Grid>

      </Dialog>
  )
}

export default PreferencesModal

const settingsMenuTabs = [
  {
    label: 'Field Layouts',
    id: 'layouts'
  },
  {
    label: 'Contact Profiles',
    id: 'profiles'
  },
  {
    label: 'General',
    id: 'general'
  },
]

const SettingsContent = () => {
  const [setting, setSetting] = useState(null);

  return (
    <Grid
      container
      sx={{
        height:'100%',
      }}
    >
        <Grid
          container
          sx={{
            justifyContent:'center',
            gap:2,
            padding:'16px 0 16px 24px',
            borderBottom: '1px solid #aaa'
          }}
        >
          <AccountSVG width={25} />
          <Typography
            style={{
              fontSize:'1.6rem',
              lineHeight:1,
              fontWeight:600
            }}
          >
            User Preferences
          </Typography>

          {
            setting === 'layouts' &&
              <div style={{display:'flex', alignItems:'center', marginTop:6, gap:8}}>
                <Typography
                  style={{
                    fontSize:'1.1rem',
                    fontWeight:500,
                    lineHeight:1,
                    color:'#aaa',
                    marginLeft:8
                  }}
                >
                  Field Layouts
                </Typography>
                <Infotip
                  question
                  title="Field Layouts"
                  tip="Layouts let you order your custom fields in the CRM"
                  messages={{
                    one: "Custom field layouts allow you to display different fields in a custom order in the CRM.",
                    two: "Custom field layouts do not modify contact data. They only change which fields are shown, and the order of the displayed fields."
                  }}
                />
              </div>
          }
          {
            setting === 'profiles' &&
              <div style={{display:'flex', alignItems:'center', marginTop:6, gap:8}}>
                <Typography
                  style={{
                    fontSize:'1.1rem',
                    fontWeight:500,
                    lineHeight:1,
                    color:'#aaa',
                    marginLeft:8
                  }}
                >
                  Contact Profies
                </Typography>
                <Infotip
                  question
                  title="Contact Profiles"
                  tip="Profiles are quick ways to display fields"
                  messages={{
                    one: "Contact Profiles allow you to quickly display a predefined set of fields for a contact.",
                    two: "Profiles do not modify contact data. It only changes the fields that are currently being displayed. All other fields are still available when the profile is turned off."
                  }}
                />
              </div>
          }
          {
            setting === 'general' &&
              <div style={{display:'flex', alignItems:'center', marginTop:6, gap:8}}>
                <Typography
                  style={{
                    fontSize:'1.1rem',
                    fontWeight:500,
                    lineHeight:1,
                    color:'#aaa',
                    marginLeft:8
                  }}
                >
                  General
                </Typography>
                <Infotip
                  question
                  title="General"
                  tip="Update all your general settings here"
                  messages={{
                    one: "Contact Profiles allow you to quickly display a predefined set of fields for a contact.",
                    two: "Profiles do not modify contact data. It only changes the fields that are currently being displayed. All other fields are still available when the profile is turned off."
                  }}
                />
              </div>
          }

        </Grid>
      <Grid
        sx={{
          // width: 250,
          borderRight:'1px solid #aaa',
          paddingBottom:2
        }}
      >
        {
          settingsMenuTabs.map(tab => (
            <MenuTab key={tab.id} tab={tab} setting={setting} setSetting={setSetting} />
          ))
        }
      </Grid>
      <Grid
        item
        sx={{
          height:'100%'
        }}
        xs
      >
        <Grid
          style={{
            // width: setting ? '100%' : '0%',
            width:'100%',
            height:'100%',
            transition: '.4s',
            overflowX:'hidden',
            // overflowY:'auto',
            // height:'100vh'
          }}
        >
          {
            setting === "layouts" &&
              <PreferencesFieldOrder />
          }
          {
            setting === "profiles" &&
              <PreferencesContactProfiles />
          }
          {
            setting === "general" &&
              <PreferencesGeneral />
          }
        </Grid>
      </Grid>
      </Grid>
  )
}

const MenuTab = (props) => {
  const { tab, setting, setSetting } = props;
  const theme = useTheme();

  return (
    <div
      onClick={() => setSetting(tab.id)}
      style={{
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        background: setting === tab.id ? `${theme.palette.primary.light}10` : 'none',
        transition:'.4s',
        paddingLeft:24,
        paddingRight:48,
        cursor:'pointer',
        // borderRight: setting === tab.id ? `10px solid blue` : 'none'
      }}
    >
      <Typography
        sx={{ // Match nav button style in django
          fontSize: '1rem',
          fontWeight: setting === tab.id ? 500 : 400,
          lineHeight:1.2,
          color:'inherit',
          textTransform:'none',
          '&&:hover': {
            fontWeight:500,
            background:'none'
          }
        }}
      >
        {tab.label}
      </Typography>
      <div
        style={{
          position:'absolute',
          top: setting === tab.id ? 0 : '50%',
          right:0,
          background:theme.palette.primary.main,
          height: setting === tab.id ? '100%' : '0%',
          width:'10px',
          transition:'.4s'
        }}
      />
      <div
        style={{
          position:'absolute',
          top: 'calc(50% - 8px)',
          right:18,
          opacity: setting === tab.id ? 1 : 0,
        }}
      >
        <a
          onClick={(e) => {
            if (setting === tab.id) {
              e.stopPropagation();
              setSetting(null)
            }
          }}
          style={{
            position:'relative',
            zIndex:1200
          }}
        >
          <CloseFilledSVG width={16} color1={`${theme.palette.primary.light}77`} />
        </a>
      </div>
    </div>
  )
}