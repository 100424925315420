import React, { useEffect, useState } from 'react'
import FileDropZone from '../ui/FileDropZone'
import Papa from 'papaparse';
import { Button, Grid, Typography } from '@mui/material';

const ImportCsvHeaders = (props) => {
  const { setIsImportingHeaders, setHeaders, csvFile, setCsvFile, resetCsvFile } = props;

  // useEffect(() => {
  //   if (csvFile) {
  //     handleUpload();
  //   } else {
  //     console.log('NO csvFile EXISTS')
  //   }
  // }, [csvFile])

  const handleUpload = async () => {
    if (!csvFile) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvText = e.target.result;
      const { data } = Papa.parse(csvText, { header: false });
      const headerData = data[0];
      setHeaders(headerData);
    };

    reader.readAsText(csvFile.file);
  }

  return (
    csvFile
      ?
        <Grid
          container
          style={{
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
          }}
        >
          <Typography

          >
            {csvFile.file.name}
          </Typography>
          <Button
            onClick={resetCsvFile}
            variant="contained"
          >
            Change CSV File
          </Button>
        </Grid>
      :
        <FileDropZone
          setFile={setCsvFile}
        />
  )
}

export default ImportCsvHeaders