export const SEOData = {
  product: {
    title: 'Product',
    description: 'Fill Marketing product information.',
    name: 'Fill Marketing',
    type: 'website',
  },
  why: {
    title: 'Why Fill Marketing',
    description: 'Find out why Fill Marketing is the best choice for your marketing needs.',
    name: 'Fill Marketing',
    type: 'website',
  },
  pricing: {
    title: 'Pricing',
    description: 'Fill Marketing pricing information.',
    name: 'Fill Marketing',
    type: 'website',
  },
  login: {
    title: 'Login',
    description: 'Login to Fill Marketing.',
    name: 'Fill Marketing',
    type: 'website',
  },
  contact: {
    title: 'Contact Us',
    description: 'Contact Fill Marketing for more information.',
    name: 'Fill Marketing',
    type: 'website',
  },
}