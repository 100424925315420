import React from 'react'
import LandingTemplate from '../templates/LandingTemplate'
import LandingWhyUs from '../components/landing/LandingWhyUs'
import { SEOData } from '../lib/SEOData';

const LandingWhyUsPage = () => {
  const data = SEOData.why;

  return (
    <LandingTemplate 
      title={data.title}
      description={data.description}
      name={data.name}
      type={data.type}
    >
      <LandingWhyUs />
    </LandingTemplate>
  )
}

export default LandingWhyUsPage
