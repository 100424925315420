import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material'
import { featureList, industryList } from '../../lib/Data'
import { productCopy } from '../../lib/Copy'
import { CenterColumn } from './Landing'
import { LogoHorizontal } from '../../assets/logos'
import { email_template_ex_1 } from '../../assets/window/email-template-ex-1'
import { Alignment, Fit, Layout, useRive, useStateMachineInput } from '@rive-app/react-canvas'
import RiveRender from '../ui/RiveRender'

const LandingProduct = () => {
  const theme = useTheme();
  const coreList = [ {name: 'Core Features', hash: 'core', title:true}, ...featureList.filter(x => x.availability === 'now' && x.tier === 'included') ]
  const addonList = [ {name: 'Addons', hash: 'addon', title:true}, ...featureList.filter(x => x.availability === 'now' && x.tier === 'addon') ]

  const hash = window.location.hash;

  useEffect(() => {
    if (hash) {
      let elem = document.getElementById(hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [hash])
  
  // SVG PATH FUNCTIONS
  const [svgCorePaths, setSvgCorePaths] = useState([]);
  const [svgAddonPaths, setSvgAddonPaths] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      // Use requestAnimationFrame to ensure DOM updates are synchronized with the next paint
      requestAnimationFrame(() => connectDivs(coreList, 'feature'));
      requestAnimationFrame(() => connectDivs(addonList, 'addon'));
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial connection of divs
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const connectDivs = (items, divKey) => {
    const paths = [];
    const noCurveRange = 10; // Adjust this value as needed
    const blendCurveRange = 30;
    
    for (let i = 0; i < items.length - 1; i++) {
      const topDiv = document.getElementById(`${i + 1}-top-${divKey}`);
      const bottomDiv = document.getElementById(`${i}-bottom-${divKey}`);
  
      if (topDiv && bottomDiv) {
        const topRect = topDiv.getBoundingClientRect();
        const bottomRect = bottomDiv.getBoundingClientRect();
        const containerRect = document.getElementById(`svg-container-features`).getBoundingClientRect();
  
        const x1 = bottomRect.left + bottomRect.width / 2 - containerRect.left;
        const y1 = bottomRect.top + bottomRect.height / 2 - containerRect.top;
        const x2 = topRect.left + topRect.width / 2 - containerRect.left;
        const y2 = topRect.top + topRect.height / 2 - containerRect.top;
  
        const curveHeight = Math.abs(y2 - y1) / 2;
        const initControlPointOffset = Math.min(curveHeight, 20); 
        const controlPointOffset = Math.abs(x2 - x1) < blendCurveRange ? initControlPointOffset -15 : initControlPointOffset // Math.min(curveHeight, 20);
  
        let pathData;
        if (Math.abs(x2 - x1) < noCurveRange) {
          // Straight line if within the no curve range
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y2}
          `;
        } else if (x1 < x2) {
          // Case where bottom div is on the left and top div is on the right
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 + controlPointOffset} ${y1 + curveHeight}
            L ${x2 - controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        } else {
          // Case where bottom div is on the right and top div is on the left
          pathData = `
            M ${x1} ${y1}
            L ${x1} ${y1 + curveHeight - controlPointOffset}
            Q ${x1} ${y1 + curveHeight} ${x1 - controlPointOffset} ${y1 + curveHeight}
            L ${x2 + controlPointOffset} ${y1 + curveHeight}
            Q ${x2} ${y1 + curveHeight} ${x2} ${y1 + curveHeight + controlPointOffset}
            L ${x2} ${y2}
          `;
        }
  
        paths.push(<path d={pathData} stroke={theme.palette.primary.main} fill="transparent" strokeWidth="3" strokeDasharray={10} key={`${i}-${divKey}`} />);
      }
    }
  
    if (divKey === "feature") {
      setSvgCorePaths(paths);
    } else if (divKey === "addon") {
      setSvgAddonPaths(paths);
    }
  };


  return (
    <Grid
      container
      style={{
        background: theme.palette.background.secondary,
        paddingTop:70
      }}
    >
      <Grid
        id="product"
        container
      >
        <CenterColumn
          alignment={{x:'flex-start'}}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
              fontWeight:700,
              lineHeight:1,
              // textTransform:'uppercase',
              color:theme.palette.primary.dark, //'#fff'
              transition:'.4s',
              zIndex:2
            }}
          >
            Product Benefits
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '1.4rem', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
              lineHeight:1.2,
              margin:'16px 0',
              transition:'.4s',
            }}
          >
            <span style={{fontSize:'1.1em', fontFamily:'fill', marginRight:'.7em', color:theme.palette.primary.main }}>f</span> combines the best of Customer Relationship Management and Email Marketing to create the easiest experience for growing your small business.
          </Typography>

          <ProductUses />
        </CenterColumn>
      </Grid>
      <Grid
        id="industry"
        container
        style={{
          marginBottom:180
        }}
      >
        <CenterColumn
          alignment={{x:'flex-start'}}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: {xs: '3rem', sm:'4rem', md:'5rem', xl:'6rem'},
              fontWeight:700,
              lineHeight:1,
              // textTransform:'uppercase',
              color:theme.palette.primary.dark, //'#fff'
              transition:'.4s',
              zIndex:2
            }}
          >
            Industries
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '1.4rem', sm:'1.4rem', md:'1.6rem', xl:'2rem'},
              lineHeight:1.2,
              margin:'16px 0',
              transition:'.4s',
            }}
          >
            <span style={{fontSize:'1.1em', fontFamily:'fill', marginRight:'.7em', color:theme.palette.primary.main }}>f</span> is built to be used by all industries. We have templates built for specific industries, but if you need something more customized, we will help you build it for free, as part of your subscription.
          </Typography>

          <IndustryUses />
        </CenterColumn>
      </Grid>
    </Grid>
  )
}

export default LandingProduct

const ProductUses = () => {
  const theme = useTheme();
  return (
    productCopy.map(use => (
        <Grid
          id={use.hash}
          container
        >
          {
            use.benefits.map(benefit => (
              <Grid
                key={benefit.label}
                container
                spacing={6}
                style={{
                  marginTop:48,
                  // flexDirection:'column',
                  // height:500
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                <Typography
                  variant="h3"
                  style={{
                    fontFamily:'Poppins',
                    lineHeight:1.1,
                    textShadow:`1px 3px 0 ${theme.palette.background.secondary}`,
                    
                  }}
                >
                  {
                    benefit.label
                    // benefit.label.split(' ').map(word => <div>{word}</div>)
                  }
                </Typography>
                <Typography
                  variant="h2"
                  style={{
                    fontSize:'.9rem',
                    fontWeight:600,
                    color:theme.palette.primary.dark,
                    textTransform:'uppercase'
                    // width:'100%',
                    // paddingTop:96
                  }}
                >
                  {use.name}
                </Typography>
                  <Typography style={{marginTop:16, fontSize:'1.1rem', fontWeight:500}}>
                    {benefit.body}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  {
                    benefit.graphic &&
                      <Grid container justifyContent="flex-end">
                        <div
                          style={{
                            // marginTop:-40,
                            // marginRight:-100,
                            width:'100%'
                          }}
                        >

                          {graphics[benefit.graphic]}
                        </div>
                      </Grid>
                  }
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      ))
  )
}

const IndustryUses = () => {
  const theme = useTheme();
  const [current, setCurrent] = useState(null);
  const industryHash = window.location.hash.replace('#', '');
  useEffect(() => {
    if (industryHash) {
      setCurrent(industryHash);
    }
  }, [industryHash])

  return (
    industryList.map(industry => (
      <IndustryAccordion
        key={industry.name}
        industry={industry}
        current={current}
        setCurrent={setCurrent}
      />
    ))
  )
}

const IndustryAccordion = (props) => {
  const { industry, current, setCurrent } = props;
  const [open, setOpen] = useState(false); // Use to allow more than one accordion open at same time (if current is not used)
  const expanded = current === industry.hash;
  const theme = useTheme();

  return (
    <Accordion
      id={industry.hash} aria-controls="panel-content"
      key={industry.name}
      // expanded={open} // Use to allow more than one accodion open at same time
      // onChange={() => setCurrent(!open)} // Use to allow more than one accodion open at same time
      expanded={expanded}
      onChange={() => setCurrent(industry.hash)}
      elevation={0}
      sx={{
        position:'relative',
        width:'100%',
        my:1,
        px:2,
        borderRadius:3,
        overflow:'clip',
        boxShadow:'0 0 10px #00000020',
        '&&.MuiAccordion-root:first-of-type': {
          borderTopLeftRadius:12,
          borderTopRightRadius:12
        },
        '&&.MuiAccordion-root:last-of-type': {
          borderBottomLeftRadius:12,
          borderBottomRightRadius:12
        }
      }}
      disableGutters
    >
      <AccordionSummary
        sx={{
          position:'unset',
          height:100
        }}
      >
        <Typography variant="landing" fontWeight={500}>{industry.name}</Typography>
        <img
          src={industry.img}
          style={{
            position:'absolute',
            top: expanded ? 0 : -10,
            right: expanded ? 0 : 20,
            width: expanded ? '50%' : 120,
            height: expanded ? '100%' : 120,
            borderRadius: expanded ? '0 12px 12px 0' : 200,
            objectFit:'cover',
            transition:'.25s'
          }}
        />

      </AccordionSummary>
      <AccordionDetails>
        {
          industry.properties.map(property => (
            <Typography>{property}</Typography>
          ))
        }
        <Button
          href={`/industry/${industry.hash}/`}
          sx={{mt:2}}
        >
          See More
        </Button>

      </AccordionDetails>
    </Accordion>
  )
}

const graphicStyle = {
  borderRadius:32,
}

const graphics = {
  email_template_ex_1: email_template_ex_1,
  segmentation:
    <RiveRender
      artboard="Segmentation"
      state="Segmentation State"
      style={graphicStyle}
      restart={5000}
    />,
    scheduling:
    <RiveRender
      artboard="Scheduling"
      state="Scheduling State"
      style={graphicStyle}
      restart={5000}
    />,
    deliverability:
    <RiveRender
      artboard="Deliverability"
      state="Deliverability State"
      style={graphicStyle}
    />,
    security:
    <RiveRender
      artboard="Security"
      state="Security State"
      style={graphicStyle}
    />,
    scalable:
    <RiveRender
      artboard="Scalable"
      state="Scalable State"
      style={graphicStyle}
    />,
    centralized:
    <RiveRender
      artboard="Centralized"
      state="Centralized State"
      style={graphicStyle}
    />,
    support:
    <RiveRender
      artboard="Support"
      state="Support State"
      style={graphicStyle}
    />,
    performance:
    <RiveRender
      artboard="Performance"
      state="Performance State"
      style={graphicStyle}
    />,
    insights:
    <RiveRender
      artboard="Insights"
      state="Insights State"
      style={graphicStyle}
    />
}