import React from "react";
import { Snackbar, Slide, Alert } from "@mui/material";

const SnackbarWrapper = (props) => {
  const { notice, onClose } = props;

  const open = notice?.open || false;
  const message = notice?.message || "";
  const severity = notice?.severity || "success";
  const autoHideDuration = notice?.autoHideDuration || 3000;

  return (
    notice
      ?
        <Snackbar
          // anchorOrigin={{
          //   vertical: "bottom",
          //   horizontal: "center"
          // }}
          autoHideDuration={autoHideDuration ? autoHideDuration : 3000}
          open={open}
          onClose={onClose}
          TransitionComponent={Slide}
        >
          <Alert variant="filled" onClose={onClose} severity={severity} icon={false}>
            {message}
          </Alert>
        </Snackbar>
      : null
  );
};

export default SnackbarWrapper