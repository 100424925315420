import React, { useState } from 'react'
import { Grid } from '@mui/material';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';

const Login = () => {
  const [forgot, setForgot] = useState(false);
  
  return (
    <>
      <Grid
        container
        sx={{
          paddingTop:'20vh',
          paddingBottom:'16px',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
        <Grid
          item
          sx={{
            // p: 3,
            boxShadow:'0 0 10px #00000030',
            background:'#ffffff',
            borderRadius:2
          }}
  
          zIndex={1}
          xs={11}
          sm={10}
          md={8}
          lg={6}
          >
          {
            forgot
              ? <ResetPasswordForm setForgot={setForgot} />
              : <LoginForm setForgot={setForgot} />
          }
        </Grid>

      </Grid>

    </>
  )
}

export default Login