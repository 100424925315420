import React from 'react'
import { emailBuilderInstructions } from '../../lib/Data'
import { Grid, Typography } from '@mui/material'

const EmailBuilderInstructions = () => {
  return (
      emailBuilderInstructions.map(instruction => (
        <Grid
          container
        >
          <Typography>{instruction.name}</Typography>
          <Typography>{instruction.description}</Typography>
          {
            instruction.steps.map(step => (
              <Grid
                container
              >
                <Typography>{step.name}</Typography>
                <Typography>{step.description}</Typography>
              </Grid>
            ))
          }

        </Grid>
      ))
  )
}

export default EmailBuilderInstructions