export const fill_mui_scrollbar = {
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: 10,
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    backgroundColor: '#AAA',
    borderRadius: 20
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}