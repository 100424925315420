import React, { useEffect, useState } from 'react'
import { getContact, useGetAllCustomFields, useGetAllLists, useGetContacts, useGetCustomFields, useGetFilteredContacts } from '../../hooks/mutations'
import { Button, Drawer, Grid, Typography } from '@mui/material';
import ContactsCRMDisplayGrid from './ContactsCRMDisplayGrid';
import ContactUpdate from '../crm/ContactUpdate';
import { initHeaderColumns } from '../../lib/Data';
import { transformFields } from '../../lib/Functions';
import { useScreenSize } from '../../lib/Interface';
import Paginate from '../ui/Paginate';
import { contactMockData, mockData } from '../../lib/mockData';
import ContactEdit from '../contacts/ContactEdit';
import { useApi } from '../../context/apiContext';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import ContactsCRMFillTable from './ContactsCRMFillTable';

const ContactCRM = () => {
  const [reloadContacts, setReloadContacts] = useState(false);
  const [reloadCustomFields, setReloadCustomFields] = useState();
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [filterOn, setFilterOn] = useState(false);
  const [focusField, setFocusField] = useState(null);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [headersExist, setHeadersExist] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [snackMessage, setSnackMessage] = useState("");
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const [destinationPageId, setDestinationPageId] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const searchParams = new URLSearchParams(window.location.search);
  const query_tag = searchParams.get('query_tag');
  const query_list = searchParams.get('query_list');
  const [filterBy, setFilterBy] = useState({
    name:null,
    email:null,
    status:null,
    page:1,
    tags:query_tag ? query_tag : null,
    mailing_lists: query_list ? query_list : null,
    page_size: 10,
    fields: [],
    search:null
  });

  const receivedContacts = useGetFilteredContacts({reload: reloadContacts, pageId: destinationPageId, filterBy: filterBy} )
  const customFields = useGetAllCustomFields(reloadCustomFields);
  const lists = useGetAllLists();
  const apiContext = useApi();
  const savedFieldLayouts = apiContext.contextUserDataBag?.data?.fieldLayouts;
  const activeFieldLayout = savedFieldLayouts?.filter(x => x.active === true)[0];
  const visibleFields = activeFieldLayout?.order?.filter(x => x.visible);
  const screenSize = useScreenSize();
  
  // console.log('query_list', query_list);
  // console.log('query_tag', query_tag);
  const handleDrawerClose = () => {
    setOpen(false);
  }

  // Update currently selected contact if contacts state changes
  const updateCurrentContact = () => {
    const newContacts = [ ...contacts ];
    const currentItem = newContacts.filter(x => x.id === selectedContact.id)[0];
    setSelectedContact(currentItem);
  }

  // Update contacts when a contact is updated without having to make another api call
  const updateContacts  = (contact) => {
    const newContacts = [ ...contacts ];
    const updatedContacts = newContacts.map(item => (item.id === contact.id ? contact : item));
    setContacts(updatedContacts);
  }

  // Get a single contact and replace it in the contacts state
  const reloadContact = async (contactId) => {
    const updatedContact = await getContact(contactId);
    if (updatedContact.status === "success") {
      updateContacts(updatedContact.data);
      setSelectedContact(updatedContact.data);
    } 
  }

  // Set initial contacts state and pages state from receivedContacts get contacts api response
  useEffect(() => {
    if (receivedContacts) {
      setContacts(receivedContacts.results);
      const next = receivedContacts.next?.split('?page=')[1]?.split('&page')[0] || null;
      // console.log('next', next);
      const prev = receivedContacts.previous?.split('?page=')[1]?.split('&page')[0] || null;
      // console.log('prev', prev);
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: receivedContacts.count,
        next: next,
        prev: prev
      })
    }
  }, [receivedContacts])

  // Reset reload contacts state
  useEffect(() => {
    if (reloadContacts) {
      setReloadContacts(false);
    }
  }, [reloadContacts]);

  // Reset reload custom fields state
  useEffect(() => {
    if (reloadCustomFields) {
      setReloadCustomFields(false);
    }
  }, [reloadCustomFields]);

  const nextPage = () => {
    setDestinationPageId(pages.next);    
  }

  const prevPage = () => {
    setDestinationPageId(pages.prev);    
  }

  const directPage = (pageNumber) => {
    setDestinationPageId(pageNumber);    
  }

  const handleFilterClick = () => {
    if (filterOn) {
      setActionType(null);
    } else {
      setActionType("Filter List");
    }
    setFilterOn(prev => !prev);
  }

  useEffect(() => {
    if (contacts && customFields) {
      if (!headersExist) {
        generateHeaderColumns();
      }
      
      if (selectedContact) {
        updateCurrentContact();
      }
    }
  }, [contacts, customFields, visibleFields]);

  // Update header fields if they are changed in settings and fieldLayouts is changed in context
  useEffect(() => {
    if (contacts && customFields && headersExist) {
      generateHeaderColumns();
    }
  }, [savedFieldLayouts])

  // Create header column list based on incoming contact fields
  const generateHeaderColumns = () => {
    // console.log('contacts', contacts);
    // console.log('customFields', customFields);
    // const headers = transformFields(contacts, customFields);
    if (visibleFields) {
      const headers = visibleFields.map(field => {
        return ({
          columnType: 'custom',
          type: field.data_type,
          field: field.field_name,
          id: field.id,
          label: field.field_name
            .replace(/\_/g, ' ')
            .replace(/-/g, ' ')
            .split(' ')
            .map(word => word[0]?.toUpperCase() + word.slice(1))
            .join(' ')
        })
      })
      // console.log('headers', headers);
      setHeaderColumns([ ...headers, ...initHeaderColumns]);
      setHeadersExist(true);

    } else {
      const headers = customFields.map(field => {
        return ({
          columnType: 'custom',
          type: field.data_type,
          field: field.field_name,
          id: field.id,
          label: field.field_name
            .replace(/\_/g, ' ')
            .replace(/-/g, ' ')
            .split(' ')
            .map(word => word[0]?.toUpperCase() + word.slice(1))
            .join(' ')
        })
      })
      // console.log('headers', headers);
      setHeaderColumns([ ...headers, ...initHeaderColumns]);
      
    }
  }


  return (
    <div
      style={{
        // padding:screenSize.xs ? 12 : (screenSize.sm ? 24 : 48)
      }}
    >
      {/* <ContactsCRMDisplayGrid
        contacts={contacts}
        setSelectedContact={setSelectedContact}
        setActionType={setActionType}
        headerColumns={headerColumns}
        open={open}
        setOpen={setOpen}
        setFocusField={setFocusField}
        handleFilterClick={handleFilterClick}
        filterOn={filterOn}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        setReloadContacts={setReloadContacts}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        customFields={customFields}
        pages={pages}
        setDestinationPageId={setDestinationPageId}
        activeFieldLayout={activeFieldLayout}
        lists={lists}
      /> */}
      <ContactsCRMFillTable
        contacts={contacts}
        setSelectedContact={setSelectedContact}
        setActionType={setActionType}
        headerColumns={headerColumns}
        open={open}
        setOpen={setOpen}
        setFocusField={setFocusField}
        handleFilterClick={handleFilterClick}
        filterOn={filterOn}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        setReloadContacts={setReloadContacts}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        customFields={customFields}
        pages={pages}
        setDestinationPageId={setDestinationPageId}
        activeFieldLayout={activeFieldLayout}
        lists={lists}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      {/* <Paginate
        currentPage={destinationPageId}
        count={pages.count}
        prevClick={prevPage}
        nextClick={nextPage}
        directPage={directPage}
      /> */}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        disableEscapeKeyDown
        PaperProps={{
          id:'drawer-contents',
          style:{
            width:'90%'
          }
        }}
      >
        <Grid
          style={{
            // width:'90%'
          }}
        >
          <ContactEdit
            contacts={contacts}
            setSelectedContact={setSelectedContact}
            customFields={customFields}
            lists={lists}
            setReloadCustomFields={setReloadCustomFields}
            incomingContact={selectedContact}
            updateContacts={updateContacts}
            reloadContact={reloadContact}
            setSnackMessage={setSnackMessage}
          />
          <SnackbarWrapper
              onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
              notice={snackMessage}
            />

        </Grid>
      </Drawer>
    </div>
  )
}

export default ContactCRM