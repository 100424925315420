import React from 'react'
import { useTemp } from '../context/tempContext'

export const DjangoComponents = ({ children, inner, card, padding, scrollable }) => {
  return (
    inner
      ?
        <ContentInner>
          {
            card
              ?
                <ContentCard scrollable={scrollable}>
                  {
                    padding
                      ?
                        <InnerPadding>
                          {children}
                        </InnerPadding>
                      :
                        children
                  }
                </ContentCard>
              :
                padding
                  ?
                    <InnerPadding>
                      {children}
                    </InnerPadding>
                  :
                    children
          }
        </ContentInner>
      :
        card
          ?
            <ContentCard scrollable={scrollable}>
              {
                padding
                  ?
                    <InnerPadding>
                      {children}
                    </InnerPadding>
                  :
                    children
              }
            </ContentCard>
          :
            padding
              ?
                <InnerPadding>
                  {children}
                </InnerPadding>
              :
                children
  )
}

export const ContentInner = ({ children }) => {
  return (
    <div
      id="content-inner"
      style={{
        padding: '8px 24px 24px 24px',
        display:'flex',
        flexDirection:'column',
        gap:'24px',
      }}
    >
      {children}
    </div>
  )
}

export const ContentCard = ({ children, scrollable }) => {
  const tempContext = useTemp();
  const isNavCollapsed = tempContext.isNavCollapsed;

  return (
    <div
      id="content-card"
      style={{
        position: 'relative',
        boxShadow: '0 0 10px #00000030',
        borderRadius: '8px',
        background: '#ffffff',
        height:'100%',
        width: scrollable ? (isNavCollapsed ? 'calc(100vw - 130px)' : 'calc(100vw - 300px)') : null,
        transition:'.2s',
        overflowY: 'clip',
      }}
    >
      {children}
    </div>
  )
}

export const InnerPadding = ({ children }) => {
  return (
    <div
      id="content-card-inner-padding"
      style={{
        padding: '1.25rem'
      }}
    >
      {children}
    </div>
  )
}

// BREADCRUMB NAVIGATION
export const Breadcrumb = ({ children }) => {
  return (
    <div
      id="breadcrumb"
      style={{
        padding: '20px 48px 8px'
      }}
    >
      {children}
    </div>
  )
}

