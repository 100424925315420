import React from 'react'
import { Button, Divider, Grid, LinearProgress, Typography } from '@mui/material';

const ActionContent = (props) => {
  const { children, progress, title, cancel, confirm, confirmCTA, completedMessage } = props;

  return (
    <Grid container>
      <Grid container padding={3}>
        <Typography
          style={{
            fontSize:'1.3rem',
            width:'100%',
            textAlign:'center'
          }}
        >
          {title}
        </Typography>
        
        {
          progress < 100 &&
            <Grid container style={{margin:'16px 0'}}>
              {children}
            </Grid>
        }

        {
          progress < 100 && progress > 0
            ?
              <>
                <LinearProgress variant="determinate" value={progress} style={{width:'100%', height:10, borderRadius:20}} />
                <Typography
                  color="primary"
                  style={{
                    textAlign:'center',
                    width:'100%',
                    fontWeight:600,
                  }}
                >
                  {Math.floor(progress)}%
                </Typography>
              </>
            :
              progress > 0
                ?
                  <Typography
                    color="primary"
                    style={{
                      textAlign:'center',
                      width:'100%',
                      fontWeight:600,
                      margin:'36px 0'
                    }}
                  >
                    {completedMessage}
                  </Typography>
                : null

        }
      </Grid>
      <Divider style={{width:'100%', margin:'0px 0 0 0'}} />
      <Grid
        container
        style={{
          justifyContent:'space-between',
          alignItems:'center',
          padding:8
        }}
      >
        <Button
          onClick={cancel}
        >
          {progress < 100 ? 'Cancel' : 'Close'}
        </Button>
        {
          progress < 100
            ?
              <Button
                onClick={confirm}
              >
                {confirmCTA}
              </Button>
            :
              null
        }

      </Grid>
    </Grid>

  )
}

export default ActionContent