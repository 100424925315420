import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import SenderDetails from '../components/account_settings/SenderDetails'
import SingleSenderDetails from '../components/account_settings/SingleSenderDetails'

const SenderDetailsPage = () => {
  return (
    // <MainPageTemplate title="Welcome">
      // <SenderDetails /> // Use this when we add ability to have multiple sender details
      <SingleSenderDetails /> // Use this when there is just one sender details item from the api response
    // </MainPageTemplate>
  )
}

export default SenderDetailsPage