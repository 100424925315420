import React, { useEffect, useState } from 'react'
import { Grid, Typography, Slider, Switch } from '@mui/material'
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import { AddonPriceDisplay, valueLabelFormat } from './Step4Subscribe';

const PricingAdditionalEmailsCalculator = () => {
  const [emailUnits, setEmailUnits] = useState(0);
  const [additionalEmails, setAdditionalEmails] = useState(true);
  const [useTieredEmailPricing, setTieredEmailPricing] = useState(true);
  const [total, setTotal] = useState(0);
  const theme = useTheme();

  console.log('total', total)

  const handleEmailUnitsChange = (event, newValue) => {
    console.log('newValue', newValue);
    if (typeof newValue === 'number') {
      setEmailUnits(newValue);
    }
  }

  const handleAdditionalEmailChange = (e) => {
    if (additionalEmails) {
      setAdditionalEmails(false);
    } else {
      setAdditionalEmails(true);
    }
  }

  useEffect(() => {
    getTotalPrice();
  }, [emailUnits, additionalEmails])
  
  const getTotalPrice = () => {
    
    let emailUnitCost = 0;

    if (useTieredEmailPricing) {
      emailUnitCost = emailUnits * 18;
      if (emailUnits > 4) {
        emailUnitCost = emailUnits * 14;
      } 
      if (emailUnits > 9) {
        emailUnitCost = emailUnits * 12;
      } 
      if (emailUnits > 19) {
        emailUnitCost = emailUnits * 10;
      }
  
      emailUnitCost = additionalEmails ? emailUnitCost : 0;
    } else {
      emailUnitCost = additionalEmails ? emailUnits * 10 : 0;
    }

    console.log('email units', emailUnits);
    console.log('emailUnitCost', emailUnitCost);

    // const baseCost = priceModel === "monthly" ? subscribeTable.priceMon : subscribeTable.priceAnn;
    // const ipCost = dedicatedIp ? 98 : 0;
    // const total = Number(baseCost) + Number(emailUnitCost) + Number(ipCost);
    
    setTotal(emailUnitCost);
  }


  return <Grid
    item
    // variant="center"
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      boxShadow: '0 0 5px #00000030',
      background: '#f1f8fa',
      borderRadius: 16,
      padding: 16,
      // outline: additionalEmails ? `4px solid ${theme.palette.primary.main}` : 'none'
    }}
    xs={12}
    sm
  >
    <Grid
      container
      variant="center"
    >
      <Typography
        style={{
          fontSize: '1.3rem'
        }}
      >
        Additional Emails
      </Typography>

      <AddonPriceDisplay
        price={useTieredEmailPricing
          ?
          emailUnits == 0
            ? '10-18'
            : emailUnits < 5
              ? 18
              : emailUnits < 10
                ? 14
                : emailUnits < 20
                  ? 12
                  : 10
          : 10}
        term="10k/month" />
      {additionalEmails &&
        <Grid
          style={{
            display: 'flex',
            width: "100%",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Grid
            style={{
              width: "90%"
            }}
          >
            <Slider
              aria-label="Temperature"
              defaultValue={30}
              value={emailUnits}
              onChange={handleEmailUnitsChange}
              getAriaValueText={valueLabelFormat}
              valueLabelFormat={valueLabelFormat}
              valueLabelDisplay="auto"
              sx={{
                height: 14,
                '& .MuiSlider-thumb': {
                  height: 24,
                  width: 24,
                  backgroundColor: '#fff',
                  border: '2px solid currentColor',
                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'inherit',
                  },
                  '&::before': {
                    display: 'none',
                  },
                },
                '& .MuiSlider-valueLabel': {
                  borderRadius: '10px',
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              shiftStep={30}
              step={1}
              marks
              min={0}
              max={30} />
          </Grid>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '1.2rem',
                fontWeight: 700
              }}
            >
              {Number(emailUnits * 10000).toLocaleString('en-US')}
            </Typography>
            <Typography
              style={{
                width: '100%',
                textAlign: 'center'
              }}
            >
              additional emails per month selected
            </Typography>
          </Grid>

        </Grid>}

    </Grid>

    {/* <Grid container variant="center">
      <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} xs>
        {additionalEmails
          ? <Typography style={{ color: '#aaa' }}>off</Typography>
          : <Typography>off</Typography>}
      </Grid>
      <Switch
        checked={additionalEmails}
        onChange={handleAdditionalEmailChange}
        inputProps={{ 'aria-label': 'controlled' }} />
      <Grid item xs>
        {additionalEmails
          ? <Typography color="primary.dark" style={{ fontWeight: 500 }}>Selected</Typography>
          : <Typography>On</Typography>}
      </Grid>
    </Grid> */}

  </Grid>;
};

export default PricingAdditionalEmailsCalculator