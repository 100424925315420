import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import SurveyAnalytics from '../components/surveys/SurveyAnalytics'
import { useGetSurvey, useGetSurveyResponses, useGetSurveyAnalytics, useGetAllSurveyResponses } from '../hooks/mutations';
import { useParams } from 'react-router-dom';
import Loading from '../components/ui/Loading';

const SurveyAnalyticsPage = () => {

  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { reactSurveyId } = useParams();
  const [surveyId, setSurveyId] = useState(currentEnv === "STAGING" ? (reactSurveyId ? reactSurveyId : '0D1Kvezd') : null); // Replace 0D1Kvezd with your chosen survey id from the database to be used during dev testing
  const [reload, setReload] = useState(false);
  // const [reloadAllResponses, setReloadAllResponses] = useState();
  const [reloadResponses, setReloadResponses] = useState(false);

  console.log('surveyId', surveyId)

  // Get the script element by its id
  const scriptElement = document.getElementById('django-detail-survey');
  if (scriptElement && !surveyId) {
    // Access the content of the script element
    const djangoSurveyString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoSurvey = JSON.parse(djangoSurveyString);

    // Set the survey id state from the JSON data
    setSurveyId(djangoSurvey.id)
  }

  const incomingSurvey = useGetSurvey(surveyId, reload);
  // console.log('incomingSurvey', incomingSurvey);
  // const allSurveyResponses = useGetAllSurveyResponses(surveyId, reloadAllResponses);  // This is only necessary until the dates / submissions are returned in the analytics response
  // console.log('allSurveyResponses', allSurveyResponses);
  const surveyAnalytics = useGetSurveyAnalytics(surveyId, reload);
  // console.log('surveyAnalytics', surveyAnalytics);

  // Reset 'reloadResponses' state
  useEffect(() => {
    if (reloadResponses) {
      setReloadResponses(false);
    }
  }, [reloadResponses])

  // Reset 'reloadAllResponses' state
  // useEffect(() => {
  //   if (reloadAllResponses) {
  //     setReloadAllResponses(false);
  //   }
  // }, [reloadAllResponses])

  return (
    // <MainPageTemplate title="Welcome">
    incomingSurvey 
      ? <SurveyAnalytics incomingSurvey={incomingSurvey} surveyAnalytics={surveyAnalytics} />
      : <Loading />
    // </MainPageTemplate>
  )
}

export default SurveyAnalyticsPage