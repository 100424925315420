import React, { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react';
// import { ColorCustomField, ColorTextField } from '../../lib/Styles';
import { Box, Button, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../context/authContext';
import { db } from '../../firebase';
// import * as Yup from 'yup';
// import { ErrorMessage, Field, Form, Formik } from 'formik';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { InputField } from '../ui/Fields';
import { postMessage } from '../../hooks/mutations';
import SnackbarWrapper from '../ui/SnackbarWrapper';

const LandingContactForm = () => {
  const [message, setMessage] = useState({
    email: '',
    phone: '',
    // emailMe: false,
    // callMe: false,
    message: '',
    name: '',
  })
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);

  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setSubmitted(false);
      }, 2000);
    }
  }, [submitted])

  // ===========================================================================
  // SEND FEEDBACK TO DATABASE
  // ===========================================================================

  const sendMessage = async () => {
    try {
      if (!message.phone && !message.email) {
        setSnackMessage({open: true, message: 'Phone or email must be included', severity:'error'});
        return;
      }

      if (!message.name) {
        setSnackMessage({open: true, message: 'Please include your name', severity:'error'});
        return;
      }

      if (!message.message) {
        setSnackMessage({open: true, message: 'Please include a message', severity:'error'});
        return;
      }

      setSubmitting(true);
      const messagePayload = {
        ...message,
        location: window.location.href || 'unknown location',
        browserName: browserName || 'unknown browser',
        browserVersion: browserVersion || 'unknown browser version',
        osName: osName || 'unknown OS',
        osVersion: osVersion || 'unknown OS version',
      }

      const sentMessage = await postMessage(messagePayload);
      if (sentMessage.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setMessage({
          email: '',
          phone: '',
          message: '',
          name: ''
        })
        setSnackMessage({open: true, message: 'Your message has been sent', severity:'success'});
      }

    } catch (error) {
      console.log('Error sending message', error);
    }

  }


  
  // ===========================================================================
  // FORMIK FUNCTIONS
  // ===========================================================================
  
  // const validationSchema = Yup.object().shape({
  //   // name: Yup.string().required('Enter your name'),
  //   // email: Yup.string().required('Enter your email'),
  //   message: Yup.string().required('Enter your message'),
  // })

  const onSubmit = (e) => {
    e.preventDefault();
    sendMessage();
    console.log('Form has been submitted');
  }


  return (
    <form
      onSubmit={onSubmit}
      style={{
        width:'100%'
      }}
    >
      <InputField
        label="Name"
        margin
        value={message.name}
        onChange={ (e) => setMessage({...message, name: e.target.value}) }
        placeholder={`Enter your name`}
        name="name"
        // color={theme.palette.brandPastel.eight}
        // sx={{
        //   mb:1
        // }}
      />

      <InputField
        label="Message"
        margin
        value={message.message}
        onChange={ (e) => setMessage({...message, message: e.target.value}) }
        placeholder={`Enter your message`}
        name="message"
        multiline
        minRows={2}
        maxRows={6}
        sx={{
          mb:1
        }}
      />

      <InputField
        label="Phone"
        margin
        value={message.phone}
        onChange={ (e) => setMessage({...message, phone: e.target.value}) }
        placeholder={`Enter your phone number`}
        name="phone"
        // color={theme.palette.brandPastel.eight}
        // sx={{
        //   width: message.callMe ? '100%' : '0%',
        //   transition:'.75s'
        // }}
      />
      
      <InputField
        label="Email"
        margin
        value={message.email}
        onChange={ (e) => setMessage({...message, email: e.target.value}) }
        placeholder={`Enter your email`}
        name="email"
        // color={theme.palette.brandPastel.eight}
        // sx={{
        //   width: message.emailMe ? '100%' : '0%',
        //   transition:'.75s',
        //   marginBottom:0
        // }}
      />


      {/* <Grid
        sx={{
          display:'flex',
          alignItems:'center',
          width:'100%',
          mt: message.emailMe ? 3 : 0,
          transition:'.5s'
        }}
      >
        {
          <div
            style={{
              width: message.emailMe ? '100%' : '0%',
              opacity: message.emailMe ? '100%' : '0%',
              transition:'.75s'
            }}
          >
            <InputField
              label="Email"
              margin
              value={message.email}
              onChange={ (e) => setMessage({...message, email: e.target.value}) }
              placeholder={`Enter your email`}
              name="email"
              // color={theme.palette.brandPastel.eight}
              sx={{
                width: message.emailMe ? '100%' : '0%',
                transition:'.75s',
                marginBottom:0
              }}
              style={{
                marginBottom:0
              }}
            />
          </div>
        }


        <FormControlLabel
          value={message.emailMe}
          control={
            <Checkbox
              checked={message.emailMe}
              onChange={(e) => setMessage({...message, emailMe: e.target.checked})}
              disableRipple
              disableTouchRipple
              style={{
                // color:theme.palette.brand.nine,
                transform: "scale(1.5)",
              }}      
            />
          }
          label={
            <Collapse timeout={500} in={!message.emailMe} orientation="horizontal">
              <Typography sx={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
                {isMobile ? `Email me` : `Email me`}
              </Typography>
            </Collapse>
          }
          labelPlacement="end"
          sx={{
            ml:.5,
            // mt:3
          }}
        />
      </Grid>

      <Grid
        sx={{
          display:'flex',
          alignItems:'center',
          width:'100%',
          mt: message.callMe ? 3 : 0,
          transition:'.5s'
        }}
      >
        {
          <div
            style={{
              width: message.callMe ? '100%' : '0%',
              opacity: message.callMe ? '100%' : '0%',
              transition:'.75s'
            }}
          >
            <InputField
              label="Phone"
              margin
              value={message.email}
              onChange={ (e) => setMessage({...message, phone: e.target.value}) }
              placeholder={`Enter your phone number`}
              name="phone"
              // color={theme.palette.brandPastel.eight}
              sx={{
                width: message.callMe ? '100%' : '0%',
                transition:'.75s'
              }}
            />
          </div>
        }


        <FormControlLabel
          value={message.callMe}
          control={
            <Checkbox
              checked={message.callMe}
              onChange={(e) => setMessage({...message, callMe: e.target.checked})}
              disableRipple
              disableTouchRipple
              style={{
                // color:theme.palette.brand.nine,
                transform: "scale(1.5)",
              }}      
            />
          }
          label={
            <Collapse timeout={500} in={!message.callMe} orientation="horizontal">
              <Typography sx={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
                {isMobile ? `Call me` : `Call me`}
              </Typography>
            </Collapse>
          }
          labelPlacement="end"
          sx={{
            ml:.5,
            // mt:3
          }}
        />
      </Grid> */}

      <Grid
        container
        columnGap={2}
        sx={{
          px:2,
          justifyContent:'center'
        }}
      >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // fullWidth
            sx={{
              px:4,
              py:2,
              mt:2
            }}
          >
            Submit Message
          </Button>
      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </form>
  )

}

export default LandingContactForm