import React, { useState } from 'react';
import {
  Button,
  Collapse,
  Grid,
  Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { hashScroll } from '../../lib/Functions';
import ReusableModal from '../ui/ReusableModal';
import { useAuth } from '../../context/authContext';
import FinalCheckoutForm from './FinalCheckoutForm';

// CREATED PLACEHOLDER FOR REGISTERFORM
import RegisterForm from '../auth/RegisterForm';


const PricingDetails = (props) => {
  const { children, showOptions, id, title, description, heading, subscription, subscriptionDetails } = props;
  const [showCheckout, setShowCheckout] = useState(false);

  const authContext = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();


  return (
    <Grid
      id={id}
      variant="centerWidth"
      style={{
        minHeight: 'calc(100vh - 80px)',
        marginBottom: 96,
        paddingBottom: 24
      }}
    >
      <Grid
        container
        style={{
          paddingTop: 48,
          maxWidth: 1000,
          zIndex: 1
        }}
      >
        <Grid
          container
          style={{
            background: `${theme.palette.background.main}dd`,
            borderRadius: 16,
            outline: `20px solid ${theme.palette.background.main}`
          }}
        >
          <Grid
            container
            style={{
              background: `${theme.palette.background.main}f5`,
              padding: 32,
              boxShadow: '10px 0 10px #00000020',
            }}
          >
            <Grid
              ts={12}
            >
              <DetailsHeader
                id={id}
                title={title}
              />
              {/* <Typography
                variant="h2"
              >
                {title}
              </Typography> */}
            </Grid>
            <Grid
              order={{ xs: 2, md: 1 }}
              ts={12}
              md={6}
            >
              <Typography
                style={{
                  whiteSpace: 'pre-line'
                }}
              >
                {heading}
              </Typography>
            </Grid>

            <Grid
              variant="center"
              order={{ xs: 1, md: 2 }}
              ts={12}
              md={6}
            >
              {
                authContext.user
                  ?
                  <Button
                    onClick={() => {
                      if (showOptions) {
                        setShowCheckout(!showCheckout)
                      } else {
                        setShowCheckout(showCheckout)
                      }
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    Get {subscription}
                  </Button>
                  :
                  <ReusableModal>
                    <Button
                      variant="contained"
                      color="secondary"
                    >
                      Get {subscription}
                    </Button>
                    {/* <FinalCheckoutForm
                      subscriptionDetails={subscriptionDetails}
                    /> */}
                    <RegisterForm />
                  </ReusableModal>
              }

            </Grid>
          </Grid>

          <Grid
            container
            style={{
              justifyContent: 'center',
              // background:`${theme.palette.background.main}f5`,
            }}
          >
            <Collapse
              in={showCheckout}
              style={{
                width: '100%',
                background: `${theme.palette.primary.main}40`
              }}
            >
              <Grid
                container
                style={{
                  justifyContent: 'center',
                  padding: '24px 0'
                }}
              >
                <Grid
                  item
                  ts={12}
                  md={8}
                >
                  <FinalCheckoutForm
                    subscriptionDetails={subscriptionDetails}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Grid
              variant="center"
              flexDirection="column"
              padding="48px 32px"
              ts={6}
            >
              <Typography
                style={{
                  whiteSpace: 'pre-line'
                }}
              >
                {description}
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  marginTop: 16
                }}
              >
                Features Include:
              </Typography>
              <Grid
                variant="center"
                style={{
                  boxShadow: '0 0 10px #00000030',
                  background: `${theme.palette.background.main}aa`,
                  borderRadius: 16,
                  marginTop: 8,
                  width: '100%',
                  overflow: 'clip'
                }}
              >
                {
                  children?.length
                    ?
                    children[0]
                    :
                    children

                }
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </Grid>



    </Grid>
  );
};

export default PricingDetails

const plans = [
  { title: 'Basic', id: 'basic' }
]

const DetailsHeader = (props) => {
  const { id, title } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        alignItems: 'flex-end'
      }}
    >
      {
        plans.map((plan) => (
          title === plan.title
            ?
            <Typography
              variant="h2"
              color="primary"
              fontWeight={600}
              // textTransform="uppercase"
              mx={2}
            >
              {plan.title}
            </Typography>
            :
            <Button
              onClick={() => hashScroll(plan.id)}
              variant="sub"
              color="primary"
              style={{
                padding: 8,
                color: theme.palette.primary.light
              }}
            >
              {plan.title}
            </Button>
        ))
      }

    </Grid>
  )
}