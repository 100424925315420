import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DragAndDropForm = (props) => {
  const { incomingData, updateData, currentPage, setCurrentPage, title, nameField, style } = props;
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [questionData, setQuestionData] = useState(incomingData);
  // console.log('questionData', questionData)

  useEffect(() => {
    if (incomingData) {
      setQuestionData(incomingData);
    }
  }, [incomingData]);

  const handleDataUpdate = () => {
    updateData(questionData);
  }

  const handleDragStart = (index, e) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    var emptyImage = document.createElement('img');
    // Set the src to be a 0x0 gif
    emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    e.dataTransfer.setDragImage(emptyImage, 0, 0);

  };

  const handleDragEnter = (index) => {
    if (draggedIndex === null || index === draggedIndex) return;

    setPlaceholderIndex(index);

    const newData = [...questionData];
    const [draggedItem] = newData.splice(draggedIndex, 1);
    newData.splice(index, 0, draggedItem);

    setQuestionData(newData);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setPlaceholderIndex(null);
    setDraggedIndex(null);
    handleDataUpdate();
  };

  return (
    questionData &&
    <div style={{...style}}>
      {questionData?.map((item, index) => (
        <div
          key={index}
          draggable
          onClick={() => setCurrentPage(index)}
          onDragStart={(e) => handleDragStart(index, e)}
          onDragEnter={() => handleDragEnter(index)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnd={handleDragEnd}
        >
          {
            placeholderIndex === index 
              ?
                <PlaceholderElement>
                  {item[nameField]
                    ? item[nameField]
                    : 'No Item'
                  }
                </PlaceholderElement>
              :
                <ItemElement index={index} item={item} draggedIndex={draggedIndex} currentPage={currentPage}>
                  <Typography
                    style={{
                      fontSize:'.8rem',
                      textTransform:'uppercase'
                    }}
                  >
                    {title ? `${title} ${index+1}` : `Item ${index+1}`}
                  </Typography>
                  {item[nameField]
                    ? item[nameField]
                    : 'No Item'
                  }
                </ItemElement>
          }
        </div>
      ))}
    </div>
  );
};
export default DragAndDropForm;

const ItemElement = (props) => {
  const { children, index, item, draggedIndex, currentPage } = props;
  const theme = useTheme();

  return (
    <div
      style={{
        padding:'8px 24px',
        borderRadius:4,
        margin:'8px 0',
        background: currentPage === index ? `${theme.palette.primary.light}77` : '#fff',
        pointerEvents: draggedIndex === index ? 'none' : 'inherit' ,
        width:150,
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        overflow:'hidden',
        boxShadow: '0 0 10px #00000030'
      }}
    >
      <Typography
        style={{
          fontSize:'1rem',
          color: item.text ? 'inherit' : currentPage === index ?'#fff' : '#777',
          whiteSpace:'nowrap',
          textOverflow:'ellipsis',
          overflow:'hidden'
        }}
      >
        {children}
      </Typography>
        
    </div>

  )
}

const PlaceholderElement = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        padding:'8px 24px',
        margin:'16px 0',
        background:'#fff',
        border:'3px dashed #ccc'
      }}
    >
      <Typography
        style={{
          fontSize:'1.2rem'
        }}
      >
        {children}
      </Typography>
        
    </div>

  )
}
