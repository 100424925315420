import React, { useEffect, useState } from 'react'
import SurveyBuilder1 from './form-1/SurveyBuilder1'
import SurveyDisplayStartCollect from './form-1/SurveyDisplayStartCollect';
import { Button, Checkbox, Collapse, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useTemp } from '../../context/tempContext';
import { postSurvey, putSurvey, useGetSurvey, postSurveyShareLink } from '../../hooks/mutations';
import { useParams } from 'react-router-dom';
import { initSurvey } from '../../lib/Data';
import { Breadcrumb } from '../../templates/DjangoStyles';
import { useTheme } from '@emotion/react';
import ReusableModal from '../ui/ReusableModal';
import { EditSVG, ShareSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import DialogShare from '../ui/DialogShare';
import SnackbarWrapper from '../ui/SnackbarWrapper';

const SurveysBuilder = () => {
  const [surveyType, setSurveyType] = useState('build');
  const { reactSurveyId } = useParams();
  const [surveyId, setSurveyId] = useState(reactSurveyId);
  const [survey, setSurvey] = useState(initSurvey);
  const [categories, setCategories] = useState(false);
  const [saved, setSaved] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);
  const [openShare, setOpenShare] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [shareLink, setShareLink] = useState(null);
  const [shareEmbed, setShareEmbed] = useState(null);
  const [shared, setShared] = useState(false);

  console.log('saved', saved);

  const theme = useTheme();
  const screenSize = useScreenSize();

  // Get the script element by its id
  const scriptElement = document.getElementById('django-edit-survey');

  if (scriptElement && !surveyId) {
    // Access the content of the script element
    const djangoContactString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoSurvey = JSON.parse(djangoContactString);

    // Set the contact id state from the JSON data
    setSurveyId(djangoSurvey.id)
  }

  // This hook should make 3 api calls to get the template, templateStorageFile, and finally the file itself
  // It currently cannot get the file from the response because it needs to use 'no-cors' mode
  const incomingSurvey = useGetSurvey(surveyId);


  useEffect(() => {
    if (incomingSurvey) {
      setSurvey(incomingSurvey);
    } else {
      setSurvey(initSurvey);
    }
  }, [incomingSurvey])

  const handleSurveyType = () => {
    if (surveyType === 'build') {
      setSurveyType('view');
    } else {
      setSurveyType('build');
    }
  }

  // reset 'saved' state after survey creation or update
  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 2500);
    }
  }, [saved])


  // Handle updates to the survey details
  const handleDetailsChange = (field, e) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      [field]: e,
    }))
  };

  // Automatically create a share link if it doesn't exist when Share button is clicked
  useEffect(() => {
    if (openShare && !shareLink) {
      handleShareSurvey();
    }
  }, [openShare]);

  // Close Share dialog
  const closeShare = () => {
    setOpenShare(false);
  }

  // Share the form via share api post
  const handleShareSurvey = async () => {
    
    // UPDATE THE CODE BELOW TO WORK WITH SURVEYS
    // IT WAS WRITTEN (AND COPIED) FROM FormDIsplay
    
    setSharing(true);
    const shareObj = {
      payload: {
        survey_id: survey.id,
        validity_duration_in_days: 3650 // Valid for 10 years
      }
    }

    const shared = await postSurveyShareLink(shareObj);
    if (shared.status === 'success') {
      setShareLink(shared.data.share_link);
      setShareEmbed(`<iframe src="${shared.data.share_link}"></iframe>`)
      setShared(true);
      setSharing(false);
    }

  }

  const saveSurvey = async () => {
    // Clone the original object to avoid modifying the original data
    const existingSurvey = { ...survey };
    console.log('existingSurvey', existingSurvey);
    // console.log('existingSurvey question set', existingSurvey.category_set[0].question_set);

    if (!existingSurvey.category_set[0] || !existingSurvey.category_set[0].question_set) {
      setSnackMessage({open: true, message: "You must add a question before saving survey", severity: 'error'});
      return;
    }

    const adjustedSurveyQuestions = existingSurvey.category_set[0].question_set.map(x => {
      // Set question choices field to null if type field is not radio, select, or checkbox
      if (
        x.type !== 'radio' &&
        x.type !== 'select' &&
        x.type !== 'select-multiple' &&
        x.type !== 'checkbox'
      ) {
        // Omit the 'choices' field from the object
        const { choice_set, ...rest } = x;
        return rest;
      } else {
        // Remove 'id' field from each choice in the 'choices' array
        console.log('x choices', x.choice_set);
        const updatedChoices = x.choice_set.map(choice => {
          const { id, ...choiceWithoutId } = choice;
          return choiceWithoutId;
        });
        return {
          ...x,
          choice_set: updatedChoices
        }
      }
    })

    existingSurvey.category_set[0].question_set = adjustedSurveyQuestions;   

    // Remove 'id' and 'new' fields from questions and choices
    const removeIdAndNew = (item) => {
      delete item.id;
      delete item.new;
    };

    // Update category_set
    existingSurvey.category_set.forEach((category) => {
      category.question_set.forEach((question) => {
        if (question.new) {
          // Remove 'id' and 'new' fields from the question
          removeIdAndNew(question);

          // Remove 'id' and 'new' fields from choices in the choices array
          if (question.choice_set && Array.isArray(question.choice_set)) {
            question.choice_set.forEach((choice) => {
              if (choice.new) {
                removeIdAndNew(choice);
              }
              
            }); 
          }
        } else if (question.choice_set && Array.isArray(question.choice_set)) {
          // Remove 'id' and 'new' fields from choices in the choices array
          question.choice_set.forEach((choice) => {
            if (choice.new) {
              removeIdAndNew(choice);
            }
          });
        }
      });
    });

    const surveyObj = {
      surveyId: incomingSurvey ? incomingSurvey.id : null,
      payload: {...existingSurvey}
    }

    if (incomingSurvey) {
      // Update survey
      const updated = await putSurvey(surveyObj);
      console.log("updated: ", updated);
      if (updated.status === "success") {
        setSnackMessage({open: true, message: 'Saved Survey', severity: 'success'});
        setSaved(true);
      } else if (updated.status === "error") {
        setSnackMessage({open: true, message: updated.error, severity: 'error'});
      }
    } else {
      // Create survey
      const created = await postSurvey(surveyObj);
      if (created.status === "success") {
        setSnackMessage({open: true, message: 'Created new survey', severity: 'success'});
        // Redirect to the edit form for the newly created survey
        window.location.href =`/surveys/${created.data.id}/edit`;
      } else {
        console.log('created data', created.error);
        // const formatted = formatApiResponse(created.error.response);
        setSnackMessage({open: true, message: created.error, severity: 'error'});
      }
    }
  }


  return (
    <>
      <Grid
        container
        style={{
          position: 'sticky',
          top: 0,
          left: 0,
          gap: 8,
          padding: '8px 16px 8px 48px',
          justifyContent: 'space-between',
          alignContent:'center',
          background: theme.palette.primaryBackground.main,
          zIndex: 500
        }}
      >
        {
          // This <nav> block css will be overwritten in django.
          // Don't worry about it looking hideous here.
          // <nav aria-label="breadcrumb">
          //   <ol class="breadcrumb">
          //     <li class="breadcrumb-item"><a href="/surveys/">Surveys</a></li>
          //   </ol>
          // </nav>
        }

        <Grid
          style={{
            alignContent: 'center',
            gap: 8,
          }}
        >
          <ReusableModal
            onClose={saveSurvey}
            preOpen={false}
          >
            <Typography
              style={{
                fontWeight:600,
                cursor:'pointer'
              }}
            >
              {
                survey.name ? survey.name : <span style={{color:'#00000075'}}>Untitled Survey</span>
              }
            </Typography>

            <Grid
              container
              style={{
                padding:48
              }}
            >
              <DetailsSurveyElement
                survey={survey}
                handleDetailsChange={handleDetailsChange}
                categories={categories}
                setCategories={setCategories}
                initialSurvey={incomingSurvey}
              />
            </Grid>
          </ReusableModal>              

        </Grid>


        <Grid
          style={{
            display: 'flex',
            flexWrap:'inherit',
            alignContent: 'center',
            gap: 8,
          }}
        >
          <Grid
            // onClick={() => setShowDetails(prev=>!prev)}
            style={{
              display:'flex',
              cursor:'pointer'
            }}
          >
              <ReusableModal preOpen={false}>
                <Button
                  variant="outlined"
                  style={{
                    gap: 8
                  }}
                // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                >
                  <EditSVG width={15} color1={theme.palette.primary.main} />
                  {screenSize.xs ? '' : 'Edit'}
                </Button>

                <Grid
                  container
                  style={{
                    padding:48
                  }}
                >
                  <DetailsSurveyElement
                    survey={survey}
                    handleDetailsChange={handleDetailsChange}
                    categories={categories}
                    setCategories={setCategories}
                    initialSurvey={incomingSurvey}
                  />
                </Grid>
              </ReusableModal>              
          </Grid>

          { // Only show SHARE button if the formEdit has already been saved and has a formEdit id
            surveyId
              ?
              <>
                <Button
                  onClick={() => setOpenShare(true)}
                  variant="outlined"
                  style={{
                    gap: 8
                  }}
                // startIcon={<ShareSVG width={15} color1={theme.palette.primary.main} />}
                >
                  <ShareSVG width={15} color1={theme.palette.primary.main} />
                  {screenSize.xs ? '' : 'Share'}
                </Button>
                <DialogShare
                  open={openShare}
                  onClose={closeShare}
                  header="Share Survey"
                  title={`Share your ${survey.name} survey`}
                  messages={{
                    one: 'This will create a link that you can send to your contacts to fill out the surveyEdit.',
                    confirm: 'Share'
                  }}
                  link={shareLink}
                  embed={shareEmbed}
                  sharing={sharing}
                  cancel={closeShare}
                  confirm={handleShareSurvey}
                  icons
                />
              </>
              :
              null
          }

          <Grid
            style={{
              display:'flex',
              alignItems:'center'
            }}
          >
            <Button
              onClick={handleSurveyType}
            >
              {surveyType === "build" ? 'View' : 'Build Survey'}
            </Button>
            <Button
              onClick={saveSurvey}
              variant="contained"
              disabled={saved}
              style={{
                padding:'4px 16px',
                marginRight:16
              }}
            >
              {saved ? 'Survey Saved' : (reactSurveyId ? 'Update' : 'Save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {  
        surveyType === "build"
          ?
            <SurveyBuilder1 survey={survey} setSurvey={setSurvey} incomingSurvey={incomingSurvey} surveyType={surveyType} handleSurveyType={handleSurveyType} />
          :
            <SurveyDisplayStartCollect preview incomingSurvey={survey} initialForm={incomingSurvey} surveyType={surveyType} handleSurveyType={handleSurveyType} />
      }

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </>
  )
}

export default SurveysBuilder

const DetailsSurveyElement = (props) => {
  const { handleDetailsChange, survey, categories, setCategories, initialSurvey } = props;

  return (
    <Grid
      container
      style={{
        justifyContent:'center'
      }}
    >
      <Divider style={{width:'100%', marginBottom:24}}>
        <Typography
          style={{
            textAlign:'center',
            fontSize:'1.2rem',
            lineHeight:1,
            fontWeight:400
          }}
        >
          Survey Details
        </Typography>
      </Divider>

      <Grid
        item
        style={{
          display:'flex',
          flexDirection:'column'
        }}
        // xs={11}
        // md={8}
        xs={12}
      >
        <TextField
          name="name"
          label="Name"
          style={{
            margin:'8px 0'
          }}
          value={survey.name}
          onChange={(e) => handleDetailsChange('name', e.target.value)}
          required
          autoFocus
        />
        <TextField
          name="description"
          label="Description"
          multiline
          maxRows={6}
          style={{
            background:'#fff',
            margin:'8px 0'
          }}
          required
          value={survey.description}
          onChange={(e) => handleDetailsChange('description', e.target.value)}
        />
        <TextField
          name="redirect_url"
          label="Redirect URL"
          multiline
          maxRows={4}
          style={{
            background:'#fff',
            margin:'8px 0'
          }}
          value={survey.redirect_url}
          onChange={(e) => handleDetailsChange('redirect_url', e.target.value)}
        />
        {/* <TextField
          name="instructions"
          label="Instructions"
          multiline
          maxRows={4}
          style={{
            background:'#fff',
            margin:'8px 0'
          }}
          value={survey.instructions}
          onChange={(e) => handleDetailsChange('instructions', e.target.value)}
        /> */}

        { // SAVE THESE TO ADD IN LATER WHEN NEEDED FOR CATEGORIES, CONTACT, and MULTIPLE SUBMISSION

          // {
          //   !initialSurvey 
          //     ?
          //       <>
          //         <FormControlLabel
          //           value={categories}
          //           onChange={(e) => setCategories(e.target.checked)}
          //           control={<Checkbox checked={categories} />}
          //           label="Separate questions by category"
          //           labelPlacement="end"
          //         />
          //         {
          //           categories &&
          //             <TextField
          //               name="categories"
          //               label="Custom Survey Name"
          //               style={{
          //                 margin:'8px 0'
          //               }}
          //               value={survey.categories}
          //               onChange={(e) => handleDetailsChange('categories', e.target.value)}
          //             />
          //         }
          //       </>
          //     :
          //       <Typography
          //         style={{
          //           color:'#bbb'
          //         }}
          //       >
          //         Survey ID: {survey.id}
          //       </Typography>
          // }

          // <Divider style={{width:'100%', margin:'8px 0'}} />
          // <FormControlLabel
          //   value={survey.getContact}
          //   control={
          //     <Checkbox
          //       checked={survey.getContact}
          //       onChange={(e) => handleDetailsChange('getContact', e.target.checked)}
          //     />
          //   }
          //   label={
          //     <Typography
          //       style={{
          //         lineHeight:1
          //       }}
          //     >
          //       Require user contact before starting survey
          //     </Typography>
          //   }
          //   labelPlacement="end"
          // />
          // <FormControlLabel
          //   value={survey.multiSubmissions}
          //   control={
          //     <Checkbox
          //       checked={survey.multiSubmissions}
          //       onChange={(e) => handleDetailsChange('multiSubmissions', e.target.checked)}
          //     />
          //   }
          //   label={
          //     <Typography
          //       style={{
          //         lineHeight:1
          //       }}
          //     >
          //       Allow multiple submissions for each user
          //     </Typography>
          //   }
          //   labelPlacement="end"
          // />

        }
      </Grid>
        
    </Grid>
  )
}
