import React, { useEffect, useState, cloneElement } from 'react'
import { Button, IconButton, Menu, Stack, Typography } from '@mui/material';
import { AddSVG, EditSVG, DeleteSVG, UsageSVG, ColorOutlineSVG, VisibleSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import { deleteTag, putTag, useGetFilteredTags } from '../../hooks/mutations';
import { Tag } from '../ui/Tags';
import ImportTags from './ImportTags';
import CustomColorPicker from '../ui/CustomColorPicker';
import { InputField } from '../ui/Fields';
import { key } from 'localforage';

const displayColumns = [
  {
    field: 'name',
    label: 'Tag',
    minWidth: 125,
    flex: 1,
    editable: false,
  },
  {
    field: 'color',
    label: 'Color',
    minWidth: 125,
    flex: 1,
    editable: false,
  },
  {
    field: 'display',
    label: 'Display',
    minWidth: 125,
    flex: 1,
    editable: false,
  },
  {
    field: 'actions',
    label: '',
    width: 130,
    editable: false,
  }
];

const TagsList = () => {
  const [reloadTags, setReloadTags] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingTags = useGetFilteredTags({reload:reloadTags, filterBy:filterBy});
  const screenSize = useScreenSize();
  const [tags, setTags] = useState(incomingTags);
  const [resetSearch, setResetSearch] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [expandedTags, setExpandedTags] = useState(true);

  
  console.log('tags', tags);

  useEffect(() => {
    if (reloadTags) {
      setReloadTags(false);
    }
  }, [reloadTags])

  // Set tags state to incoming data on load
  useEffect(() => {
    console.log('incomingTags', incomingTags);
    if (incomingTags) {
      setTags(incomingTags.results);
      const next = incomingTags.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingTags.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingTags.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingTags]);

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => tags.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

  // ADD TAG BUTTON MENU
  const [anchorEl, setAnchorEl] = useState(null);
  const isAddingTag = Boolean(anchorEl);
  const handleAddTagClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddTagClose = () => {
    setAnchorEl(null);
  };

  // ADD TAG BUTTON MENU
  const [anchorElTag, setAnchorElTag] = useState(null);
  const isAddingButtonTag = Boolean(anchorElTag);
  const handleAddButtonTagClick = (event) => {
    if (isAddingButtonTag || expandedTags) {
      event.stopPropagation();
    }
    setAnchorElTag(event.currentTarget);
  };
  const handleAddButtonTagClose = (event) => {
    // event.stopPropagation();
    setAnchorElTag(null);
  };



  return (
    !isSearching || tags.length
      ?
        <FillTable
          title="Tag List"
          rows={tags?.length ? tags : []}
          columns={displayColumns}
          setFilterBy={setFilterBy}
          pages={pages}
          selectedRows={selected}
          setSelectedRows={setSelected}
          handleRowSelection={handleRowSelection}
          checkboxes={false}
          dense
          actionCell={(row) => (
            <TagActions tag={row} setSnackMessage={setSnackMessage} setReloadTags={setReloadTags} />
          )}
          renderCell={(row, column) => (
            <Stack direction="row">
              <RenderCell row={row} column={column} setReloadTags={setReloadTags} />
            </Stack>
          )}
          // searchButton={
          //   <ReusableFilterSearch
          //     useSearch
          //     isOpen
          //     placeholder="Search Tags"
          //     content={tags} // incomingTags
          //     setContent={setTags}
          //     filterBy={filterBy}
          //     setFilterBy={setFilterBy}
          //     searchFields={['name']}
          //   />
          // }
          toolbarButtons={[
            <CustomTooltip
              title="Add tags to multiple contacts"
            >
              <Button
                href="/contacts/tags/bulk-tag/"
              >
                Bulk Tag Contacts
              </Button>
            </CustomTooltip>,

            <>
              <CustomTooltip
                title="Create Tag"
              >
                <IconButton
                  onClick={handleAddButtonTagClick}  
                  text={screenSize.sm ? null : "New Tag"}
                >
                  <AddSVG width={18} />
                </IconButton>
              </CustomTooltip>

              <Menu
                id="basic-menu"
                anchorEl={anchorElTag}
                open={isAddingButtonTag}
                onClose={handleAddButtonTagClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}          
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                style={{
                  marginTop:4
                }}
              >
                <ImportTags
                  setIsOpen={isAddingButtonTag}
                  // contact={contact}
                  // setContact={setContact}
                  // contactId={contact.id}
                  existingTags={tags}
                  // handleFieldImport={handleFieldImport}
                  closeMenu={handleAddButtonTagClose}
                  // removeTag={removeTag}
                  // setEditing={setEditing}
                  // reloadContact={reloadContact}
                  setReload={setReloadTags}
                />
              </Menu>
            </>
          ]}
          // toolbarActions={
          //   <BulkActions
          //     selected={selected}
          //     setReloadTags={setReloadTags}
          //   />
          // }
          sx={{
            ...fill_mui_scrollbar
          }}
        />

      :
        <Typography>No tags have been created yet.</Typography>
  )
}

export default TagsList

const TagActions = (props) => {
  const { tag, setSnackMessage, setReloadTags } = props;
  // const tag = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const removeTag = async () => {
    setDeleting(true);
    const deletedTag = await deleteTag(tag.id)
    if (deletedTag.status === 'success') {
      setReloadTags(true); // <-- Reload the current tags to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setSnackMessage({ open: true, message: 'Tag deleted', severity: 'success' })
    }
  }


  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={.5}
    >
      <CustomTooltip
        title="View tag assignments"
      >
        <IconButton
          href={`/contacts/?query_tag=${tag.id}`}
        >
          <VisibleSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete tag"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Tag"
        title={`You are about to delete the ${tag.name} tag`}
        messages={{
          one: 'Are you sure you want to delete this tag? The tag will be removed from all contacts and permanently deleted.',
          confirm: 'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removeTag}
        icons
      />

    </Stack>

  )
}

// const TagEdit = (props) => {
//   const {  } = props;
  
//   return (
//     <Grid
//       container
//     >
//       <Grid
//         item
//         xs={12}
//         sm
//       >
//         <EditSVG width={18} />
//         <ColorOutlineSVG width={18} />
//       </Grid>
//     </Grid>
//   )
// }

const RenderCell = ({ row, column, setReloadTags }) => {
  const [tagData, setTagData] = useState(row);
  const [editable, setEditable] = useState(false);

  // Handle saving color only
  useEffect(() => {
    if (row.color != tagData.color) {
      editTag(tagData);
    }
  }, [tagData])

  useEffect(() => {
    if (row) {
      setTagData(row)
    }
  }, [row])

  // Function to change color (used in CustomColorPicker)
  const handleColor = (value) => {
    setTagData({
      ...tagData,
      color: value
    });
  }

  // Function to change name (after input blurs)
  const handleName = (e) => {
    setTagData({
      ...tagData,
      name: e.target.value.replace(' ', '-').toLowerCase()
    })
  }

  // API to update tag and reload tags api
  const editTag = async (data) => {
    const tagObj = {
      tagId: row.id,
      payload: data
    }

    const updatedTag = await putTag(tagObj);
    if (updatedTag.status === "success") {
      console.log('successful tag update');
      if (setReloadTags) {
        setReloadTags(true);
      }
    }
  }



  switch (column.field) {
    case 'name':
      return (
        <InputField
          readOnly={!editable}
          noAutoFocus
          onFocus={() => setEditable(true)}
          onBlur={() => {
            setEditable(false);
            if (tagData.name != row.name) {
              editTag(tagData);
            }
          }}
          value={tagData.name}
          onChange={(e) => handleName(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              editTag(tagData);
            }
          }}
          singleLine
        />
      )
      case 'display':
        return (
          <Tag
            tag={row}
            // showOptions
          />
        )
        case 'color':
        return (
          <CustomColorPicker color={tagData.color} setColor={handleColor} />
        )
      default:
      break;
  }
}
