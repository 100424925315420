import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import { useGetCsvImport } from '../hooks/mutations'
import Loading from '../components/ui/Loading'
import { useParams } from 'react-router-dom'
import CsvUploadQueued from '../components/csv/CsvUploadQueued'
import { importStatusEnum } from '../lib/Data'
import { upload } from '@testing-library/user-event/dist/upload'

const CsvUploadQueuedPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { reactUploadId } = useParams();
  const [uploadId, setUploadId] = useState(currentEnv === "STAGING" ? (reactUploadId ? reactUploadId : '0D1Kvezd') : null);
  const [uploadHref, setUploadHref] = useState("");
  const [status, setStatus] = useState(null)
  const [reload, setReload] = useState(false);

  // Get the script element by its id
  const scriptElement = document.getElementById('django-upload');

  if (scriptElement && !uploadId) {
    // Access the content of the script element
    const djangoUploadString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoUpload = JSON.parse(djangoUploadString);

    // Set the contact id state from the JSON data
    setUploadId(djangoUpload.id)
    setUploadHref(djangoUpload.href)
  }

  const incomingUpload = useGetCsvImport(uploadId, reload);

  // Reset 'reload' state
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload])

  // Set status every time it changes
  useEffect(() => {
    let timeoutId;

    if (incomingUpload?.status) {
      const newStatus = importStatusEnum.find(x => x.status === incomingUpload.status);
      setStatus(newStatus);

      if (incomingUpload.status < 4) {
        timeoutId = setTimeout(() => {
          setReload(true);
        }, 1000);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [incomingUpload?.status]);

  return (
    // <MainPageTemplate background="#f8f8f8" title="Uploads">
    incomingUpload
      ? <CsvUploadQueued incomingUpload={incomingUpload} uploadHref={uploadHref} />
      : <Loading />
    // </MainPageTemplate>
  )
}

export default CsvUploadQueuedPage