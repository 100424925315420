import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Tabs, Tab, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { ChartColors } from '../../lib/Data';
import SurveyResponses from './SurveyResponses';
import { useGetSurveyResponses } from '../../hooks/mutations';
import { theme } from '../../theme';
import { LineChart } from '@mui/x-charts';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid sx={{ p: 3 }}>{children}</Grid>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SurveyAnalytics = (props) => {
  const { incomingSurvey, surveyAnalytics } = props;
  const [reloadResponses, setReloadSurveyResponses] = useState(false);
  const [destinationPageId, setDestinationPageId] = useState(1);
  const incomingSurveyResponses = useGetSurveyResponses({surveyId: incomingSurvey.id, pageId: destinationPageId, pageSize:'20'}, reloadResponses);
  // const [responses, setResponses] = useState(null);
  const [constants, setConstants] = useState({
    total_survey_responses:0,
    total_questions:0,
    completion_rate:0
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize:20,
    page:0,
  })
  const [tab, setTab] = useState(0);

  console.log('surveyAnalytics', surveyAnalytics)

  // Load the constants to create the animation on page load
  useEffect(() => {
    if (surveyAnalytics) {
      setConstants({
        total_survey_responses: surveyAnalytics.total_survey_responses,
        total_questions: surveyAnalytics.total_questions,
        completion_rate: Math.round(parseFloat(surveyAnalytics.completion_rate))
      })
    }
  }, [surveyAnalytics])

  // Reset reloadResponses after it is triggered
  useEffect(() => {
    if (reloadResponses) {
      setReloadSurveyResponses(false);
    }
  }, [reloadResponses])

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    if (newValue === 1 && !incomingSurveyResponses) {
      setReloadSurveyResponses(true);
    }
  };

  function countItemsByDate(items) {
    var dateCounts = {};
    
    // Iterate over the items
    items.forEach(function(item) {
      // Extract the date part from the 'created' field
      var date = item.created.split('T')[0];
      
      // If the date already exists in dateCounts, increment its count, otherwise initialize it to 1
      if (dateCounts[date]) {
        dateCounts[date]++;
      } else {
        dateCounts[date] = 1;
      }
    });
    
    // Convert dateCounts object to an array of objects
    var result = [];
    for (var date in dateCounts) {
      result.push({ date:new Date(date), count: dateCounts[date] });
    }
    
    return result;
  }
  

  // FUNCITONS FOR HANDLING RESPONSES
  // Handle server-side pagination
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    const newPage = newPaginationModel.page + 1;
    setDestinationPageId(newPage);
  }

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid
        container
      >
          <Typography
            style={{
              fontSize:'1.4rem',
              width:'100%',
              textAlign:'center'
            }}
          >
            Survey Analytics For {incomingSurvey.name}
          </Typography>
          <Typography
            style={{
              fontSize:'1.1rem',
              fontWeight:500,
              color:'#777',
              width:'100%',
              textAlign:'center'
            }}
          >
            {incomingSurvey.category_set[0].question_set.length} Questions
          </Typography>


      </Grid>
      <Grid sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Responses" {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <CustomTabPanel value={tab} index={0}>
        <Grid
          container
          variant="center"
        >
            <Grid
              container
              rowSpacing={{xs:2, sm:3, md:3}}
              columnSpacing={{xs:2, sm:3, md:6}}
            >
              <Grid
                item
                variant="center"
                style={{
                  display:'flex',
                }}
                xs={12}
                md={6}
                lg={3}
              >
                <DisplayCard title="TOTAL RESPONSES">
                  <Typography
                    style={{
                      fontSize:'4rem',
                      fontWeight:600,
                    }}
                  >
                    {surveyAnalytics?.total_survey_responses}
                  </Typography>
                </DisplayCard>
              </Grid>

              <Grid
                item
                variant="center"
                style={{
                  display:'flex',
                }}
                xs={12}
                md={6}
                lg={3}
              >
                <DisplayCard title="COMPLETION RATE">
                  <div
                    style={{
                      position:'relative'
                    }}
                  >
                    <CircularProgress
                      color={
                        constants.completion_rate > 80
                          ? "primary"
                          : constants.completion_rate > 40
                            ? "tertiary"
                            : constants.completion_rate > 20
                              ? "quaternary"
                              : "warning"
                      }
                      size={130}
                      thickness={7}
                      variant="determinate"
                      value={constants.completion_rate}
                      style={{
                        // position:'absolute',
                        // top:0,
                        // left:0,
                        zIndex:10
                      }}
                    />
                    <CircularProgress
                      size={130}
                      thickness={7}
                      variant="determinate"
                      value={constants.completion_rate - 100}
                      style={{
                        color:'#ccc',
                        position:'absolute',
                        top:0,
                        left:0,
                        zIndex:1
                      }}
                    />
                    <Typography
                      style={{
                        position:'absolute',
                        top:'calc(50% - 25px)',
                        height:50,
                        width:'100%',
                        textAlign:'center',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        fontSize:'1.3rem',
                        fontWeight:700,
                        color:
                          constants.completion_rate > 80
                            ? theme.palette.primary.main
                            : constants.completion_rate > 40
                              ? theme.palette.tertiary.main
                              : constants.completion_rate > 20
                                ? theme.palette.quaternary.main
                                : theme.palette.alt.main  
                      }}
                    >
                      {constants.completion_rate}%
                    </Typography>
                  </div>
                </DisplayCard>
              </Grid>

              {
                surveyAnalytics?.submission_dates &&
                  <Grid
                    item
                    variant="center"
                    style={{
                      display:'flex',
                    }}
                    xs={12}
                    lg={6}
                  >
                    <DisplayCard title="SUBMISSIONS" width={500}>
                      {
                        surveyAnalytics.submission_dates.length
                          ?
                            <QuestionDateDisplay question={surveyAnalytics.submission_dates} />
                          :
                            <Typography>No survey responses yet</Typography>
                      }
                    </DisplayCard>
                  </Grid>
              }
            </Grid>

            <Grid
              container
              rowSpacing={2}
              style={{
                marginTop:24
              }}
            >
              {surveyAnalytics?.survey_insights?.questions?.map((question, index) => (
                <SurveyResponseQuestions
                  question={question}
                  index={index}
                />
              ))}
            </Grid>

        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <SurveyResponses
          surveyId={incomingSurvey?.id}
          incomingSurveyResponses={incomingSurveyResponses}
          handlePaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          count={surveyAnalytics?.total_survey_responses}
        />
      </CustomTabPanel>
    </Grid>

  )
}

export default SurveyAnalytics;

const QuestionSelectDisplay = (props) => {
  const { question } = props;

  return (
    <BarChart
      colors={ChartColors.default}
      xAxis={[
        {
          data: question.answers.map(answer => answer.label),
          scaleType: 'band',
        },
      ]}
      series={[
        {
          data: question.answers.map(answer => parseFloat(answer.percentage)),
        },
      ]}
      yAxis={[
        {
          max: 100,
          label: "% of responses",
        },
      ]}
      grid={{ horizontal: true }}
      width={600}
      height={350}
    />
  )
}

const QuestionDateDisplay = (props) => {
  const { question } = props;
  console.log('question', question);
  const xAxis = question.map(x => new Date(x.date));
  const series = question.map(x => x.submissions)
  return (
    <LineChart
      colors={ChartColors.default}
      xAxis={[{ 
        data: xAxis,
        // data: [...xAxis, new Date('2024-06-08'), new Date('2024-06-09'), new Date('2024-06-10')], 
        scaleType: 'utc',
        tickMinStep: 3600 * 1000 * 24, // min step: 24h
        // tickInterval: [xAxis[0], xAxis[xAxis.length-1]]
      }]}
      // xAxis={[{ data: [1, 2] }]}
      series={[
        {
          curve:'monotoneY',
          data: series,
          // data: [...series, 24, 97, 440],
          // data: [2, 3, 5.5, 8.5, 1.5, 5, 1, 4, 3, 8],
          // showMark: ({ index }) => index % 2 === 0,
        },
      ]}
      // width={500}
      height={200}
    />
  )
}

const QuestionTextDisplay = (props) => {
  const { question } = props;
  return (
    <></>
  )
}

const SurveyResponseQuestions = (props) => {
  const { question, index } = props;

  return (
    <Grid item xs={12} key={index}>
      <Grid
        style={{
          boxShadow:'0 0 10px #00000030',
          background:'#fff',
          borderRadius:16,
          padding:16
        }}
      >
        <Grid
          container
          style={{
            justifyContent:'space-between'
          }}
        >
          <Typography
            style={{
              fontSize:'.75rem',
              fontWeight:600,
              color:'#777',
              textTransform:'uppercase'
            }}
          >
            Question
          </Typography>

          <Typography
            style={{
              fontSize:'.75rem',
              fontWeight:600,
              color:'#777',
              textTransform:'uppercase'
            }}
          >
            {question.total_answers} Answers
          </Typography>

        </Grid>
        <Typography
          style={{
            fontWeight:600,
          }}
        >
          {question.question_name}
        </Typography>
        {
          question.total_answers > 0
            ?
              <>
                {
                  question.question_type === 'select' ||
                  question.question_type === 'select-multiple'
                    ?
                      <QuestionSelectDisplay
                        question={question}
                      />
                    :
                      null
                }
                {
                  question.question_type === 'text' ||
                  question.question_type === 'short-text' ||
                  question.question_type === 'radio' ||
                  question.question_type === 'number' ||
                  question.question_type === 'radio' ||
                  question.question_type === 'date' ||
                  question.question_type === 'float'
                    ?
                      <QuestionTextDisplay
                        question={question}
                      />
                    :
                      null
                }
              </>
            :
                null
        }
      </Grid>
    </Grid>
  )
}

const DisplayCard = (props) => {
  const { children, title, width } = props;

  return (
    <Grid
      style={{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'flex-end',
        boxShadow:'0 0 10px #00000030',
        background:'#ffffff',
        // width: width ? width : 250,
        width:'100%',
        height:250,
        borderRadius:16
      }}
    >
      <Grid
        container
        variant="center"
        height={150}
      >
        {children}

      </Grid>
      <Typography
        style={{
          fontWeight:600,
          height:75,
          display:'flex',
          alignItems:'center'
        }}
      >
        {title}
      </Typography>
    </Grid>

  )
}