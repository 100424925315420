import { Button, Dialog, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { H1, H1Small, H2Small, P1 } from '../ui/Headings'
import { theme } from '../../theme'
import { useTheme } from '@emotion/react'
import Register from '../auth/Register'

const IndustryTemplate = ({ industry, detail }) => {
  const theme = useTheme();
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <Grid
      container
      sx={{
        justifyContent:'center',
        padding:3,
        mt:8
      }}
    >
      <Grid
        container
        style={{
          maxWidth:1200
        }}
      >
        <Grid
          container
          style={{
            justifyContent:'center'
          }}
        >
          <img
            src={industry.img}
            style={{
              objectFit:'cover',
              width:'100%',
              height:400,
              borderRadius:24
            }}
          />
          <H1Small
            sxProps={{
              my:1,
              textAlign:'center'
            }}
          >
            {detail.header.title}
          </H1Small>
        </Grid>

        {
          detail.body.map((item, index) => (
            <BodySection
              item={item}
              index={index}
            />
          ))
        }

        <Grid
          container
          sx={{
            m:2,
            mt:6,
            background:`${theme.palette.secondary.light}33`,
            borderRadius:4,
          }}
        >
          <Grid
            item
            sx={{
              p:{xs:0, md:2}
            }}
            xs={12}
            md={6}
          >
            <Grid
              sx={{
                py:2,
                px:6,
                height:'100%'
              }}
            >
              <H2Small>
                Customized just for {industry.name}{industry.name.charAt(industry.name.length - 1) === 's' ? '' : ' companies'}.
              </H2Small>
              <P1 sxProps={{mt:4}}>
                Fill has built-in customizations for {industry.name} {industry.name.charAt(industry.name.length - 1) === 's' ? '' : 'companies'}. You can organize your contacts with our pre-defined properties or customize them to your own specifications.<br /><br />As part of your subscription, we will help you get set up how you like, for no extra cost.
              </P1>
              <Button
                onClick={() => setOpenRegister(true)}
                variant="contained"
                style={{
                  fontWeight:600,
                  minWidth:0,
                  paddingLeft:24,
                  paddingRight:24,
                  marginTop:48,
                  // textTransform:'capitalize',
                  borderRadius:30
                }}
                >
                Get Started
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              p:{xs:0, md:2}
            }}
            xs={12}
            md={6}
          >
            <Grid
              sx={{
                p:2
              }}
            >
              {
                industry.properties.map(property => (
                  <Grid
                    sx={{
                      borderWidth:1,
                      borderColor:'#fff',
                      borderRadius:24,
                      background:'#ffffffaa',
                      my:1.5,
                      p:2
                    }}
                  >
                    <P1>{property}</P1>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openRegister}
        onClose={() => setOpenRegister(false)}
        maxWidth="sm"
      >
        <Register />
      </Dialog>

    </Grid>
  )
}

export default IndustryTemplate

const BodySection = (props) => {
  const { item, index } = props;

  return (
    <Grid
      container
      flexDirection={index % 2 ? 'row-reverse' : 'row'}
      sx={{
        mt:6
      }}
    >
      <Grid
        item
        sx={{
          p:{xs:0, md:2}
        }}
        xs={12}
        md={6}
      >
        <Grid
          sx={{
            background:`${theme.palette.secondary.main}33`,
            borderRadius:4,
            py:2,
            px:4,
            height:'100%'
          }}
        >
          <H2Small>
            {/* {index+1}. <span style={{marginRight:8}} />  */}
            {item.title}
          </H2Small>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          p:{xs:0, md:2}
        }}
        xs={12}
        md={6}
      >
        <Grid
          sx={{
            p:2
          }}
        >
          <P1>{item.sell}</P1>
        </Grid>
      </Grid>
    </Grid>

  )
}