import React, { useEffect, useState } from 'react'
import { useGetForm, useGetFormSubscribe } from '../hooks/mutations'
import MainPageTemplate from '../templates/MainPageTemplate'
import FormEditWrapper from '../components/forms/FormEditWrapper'
import FormSubscribeContainer from '../components/forms/FormSubscribeContainer'
import FormDisplay from '../components/forms/FormDisplay'

const FormsSubscribePage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const [reload, setReload] = useState();
  const [token, setToken] = useState(null);
  console.log('token', token)

  // Get the script element by its id
  const scriptElement = document.getElementById('external-forms-root');
  console.log('scriptElement', scriptElement)

  if (scriptElement && !token) {
    // Access the content of the script element
    const djangoFormString = scriptElement?.textContent;
    console.log('djangoFormString', djangoFormString)
  
    // Parse the JSON data
    const djangoForm = JSON.parse(djangoFormString);
    console.log('djangoForm', djangoForm)

    // Set the form id state from the JSON data
    setToken(djangoForm.id)
  }

  const incomingForm = useGetFormSubscribe(token);
  console.log('incomingForm', incomingForm)

  return (
    // <MainPageTemplate title="Welcome">
      // {
        incomingForm
          ?
            <FormDisplay form={incomingForm} incomingPreview />
          :
            null
      // }
    // </MainPageTemplate>
  )
}

export default FormsSubscribePage
