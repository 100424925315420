import React from 'react'
import { Button, Card, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ResetMessage = () => {

  const location = useLocation();
  
  return (
    <>
      <Grid
        container
        sx={{
          height:'80vh',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
        <Grid
          item
          zIndex={1}
          display="flex"
          alignItems="center"
          flexDirection="column"
          xs={12}
          sm={10}
          md={8}
          lg={6}
        >
          <Card
            sx={{
              maxWidth:400
            }}
          >
            <Grid
              variant="center"
              flexDirection="column"
              sx={{
                // justifyContent:'center'
              }}
            >
              <Typography textAlign="center">
                {`An email has been sent to ${location.state?.email ? location.state.email : 'your email'}. Reset your password and then login!`}
              </Typography>

              <Button
                variant="contained-thin"
                style={{
                  marginTop:16
                }}
                href="/accounts/login/"
              >
                Go to login
              </Button>
            </Grid>
          </Card>
        </Grid>

      </Grid>

    </>
  )
}

export default ResetMessage