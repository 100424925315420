import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

// Return boolean for whether a screen size has been reached
// Returns true value for multiple at larger screen sizes
export const useScreenSize = () => {
  const theme = useTheme();


  const isXL = useMediaQuery(theme.breakpoints.up('xl'), {defaultMatches: false})
  const isLG = useMediaQuery(theme.breakpoints.down('xl'), {defaultMatches: false})
  const isMD = useMediaQuery(theme.breakpoints.down('lg'), {defaultMatches: false})
  const isSM = useMediaQuery(theme.breakpoints.down('md'), {defaultMatches: false})
  const isXS = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: false})
  const ists = useMediaQuery(theme.breakpoints.down('xs'), {defaultMatches: false})

  // console.log('theme breakpoints', theme.breakpoints);

  // console.log({
  //   XL: isXL,
  //   LG: isLG,
  //   MD: isMD,
  //   SM: isSM,
  //   XS: isXS,
  //   ts: ists
  // })

  const screenSize = {
    xl: isXL,
    lg: isLG,
    md: isMD,
    sm: isSM,
    xs: isXS,
    ts: ists
  }

  return (screenSize)
}

// Returns only the value of the current screen size
// Does not currently pass updated values automatically when used in ui
export function getScreenSize({ ts, xs, sm, md, lg, xl }) {
  const breakpoints = {
    xl: "(min-width: 1920px)",
    lg: "(min-width: 1400px) and (max-width: 1919px)",
    md: "(min-width: 1024px) and (max-width: 1399px)",
    sm: "(min-width: 640px) and (max-width: 1023px)",
    xs: "(min-width: 300px) and (max-width: 639px)",
    ts: "(max-width: 299px)",
  };

  let screenSizeOrder = ['xl', 'lg', 'md', 'sm', 'xs', 'ts'];

  // Filter out keys that are not present and sort them in ascending order
  screenSizeOrder = screenSizeOrder.filter(category => category in breakpoints && eval(category)).sort((a, b) => eval(a) - eval(b));

  function checkScreenSize() {
    let screenSizeCategory = 'ts'; // Default to smallest size
    let screenSizeValue = ts !== undefined ? ts : 0; // Default value for ts

    for (let i = 0; i < screenSizeOrder.length; i++) {
      const category = screenSizeOrder[i];
      if (window.matchMedia(breakpoints[category]).matches) {
        screenSizeCategory = category;
        screenSizeValue = eval(category) !== undefined ? eval(category) : 0;
        break;
      }
    }

    return screenSizeValue;
  }

  // Check the initial screen size value
  let currentScreenSizeValue = checkScreenSize();

  // Function to handle resize event
  function handleResize() {
    const newScreenSizeValue = checkScreenSize();
    if (newScreenSizeValue !== currentScreenSizeValue) {
      // Screen size value has changed
      console.log('Screen size value changed:', newScreenSizeValue);
      currentScreenSizeValue = newScreenSizeValue;
    }
  }

  // Add event listener for window resize event
  window.addEventListener('resize', handleResize);

  // Return initial screen size value
  return currentScreenSizeValue;
}
