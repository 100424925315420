import React, { cloneElement, useEffect, useLayoutEffect, useState } from 'react'
import { useApi } from '../../context/apiContext';
import DragAndDropFormCustomFieldOrder from '../ui/DragAndDropFormCustomFieldOrder';
import { getAllCustomFields, putUserDataBag, useGetAllCustomFields, useGetUserDataBag } from '../../hooks/mutations';
import { useTheme } from '@emotion/react';
import { Button, Grid, IconButton, Switch, Tab, Tabs, Typography } from '@mui/material';
import { ArrowSVG, CloseFilledSVG, EditSVG, InfoFillSVG } from '../../assets/icons';
import { InputField } from '../ui/Fields';
import CustomTooltip from '../ui/CustomTooltip';
import Infotip from '../ui/Infotip';
import DialogWarning from '../ui/DialogWarning';

const customGeneralSettings = {
  show_info: true,
  show_empty_fields: false
}

const PreferencesGeneral = () => {
  const apiContext = useApi();
  const savedGeneralSettings = apiContext.contextUserDataBag?.generalSettings;
  const [updatedGeneralSettings, setUpdatedGeneralSettings] = useState(savedGeneralSettings);
  const userDataBag = useGetUserDataBag();
  const theme = useTheme();


  // Initialize field layouts
  useLayoutEffect(() => {
    if (userDataBag?.data?.general) {
      setUpdatedGeneralSettings(userDataBag.data.general);
    } else {
      setUpdatedGeneralSettings(customGeneralSettings);
    }
  }, [userDataBag?.data?.general])

  // Function to send updates to user bag to the server
  const updateDataBag = async () => {

    const dataObj = {
      id: userDataBag.id,
      data: {
        data: {
          ...userDataBag.data,
          general: updatedGeneralSettings
        }
      }
    }

    const updatedDataBag = await putUserDataBag(dataObj);

    if (updatedDataBag.status === 'success') {
      apiContext.handleSetContextUserDataBag(updatedDataBag.data);
    }
  }

  // Save data bag field updates after no changes have been made for 1/10 of a second
  useEffect(() => {
    let timeoutId;
    if (updatedGeneralSettings && userDataBag) {
      timeoutId = setTimeout(() => {
        updateDataBag();       
      }, 100);
    }
  
    return () => {
      // Cleanup function
      clearTimeout(timeoutId);
    };
  }, [updatedGeneralSettings, userDataBag]);
  

  return (
    userDataBag &&
      <Grid
        container
        style={{
          // height:'calc(100vh - 70px)',
          // borderRight:'1px solid #aaa',
          overflow:'auto'
        }}
      >
        <Grid
          item
          order={{xs:1, md:0}}
          sx={{
            // display:'flex',
            // justifyContent:'center',
            // width: currentLayout ? '100%' : '0%',
            transition: '.4s',
            overflowX:'hidden',
            padding:2,
            width: 400,
            // height:'100vh'
          }}
          // xs={12}
          // md={6}
        >
          <SettingsTab
            title="Show Info Icons"
            tip="What are Info Icons?"
            messages={{
              one:'Info icons can be clicked (or hovered over) to give you more information about a particular part of the application. They are turned on by default.'
            }}
          >
            <Typography>Show Info icons</Typography>
            <Switch
              checked={updatedGeneralSettings.show_info}
              onChange={(e) => setUpdatedGeneralSettings(prev => ({ ...prev, show_info: e.target.checked }) )}
            />
          </SettingsTab>
          {
            // TODO: Make this setting default expand/collapse custom fields/mailing lists/tags accordions in ContactEdit component
            // This setting is available in apiContext.contextUserDataBag.general.show_info
          }
          {/* <SettingsTab>
            <Typography>Default expand contact items</Typography>
            <Switch
              value={updatedGeneralSettings.expand_contact_lists}
              onChange={(e) => setUpdatedGeneralSettings(prev => ({ ...prev, expand_contact_lists: e.target.checked }) )}
            />
          </SettingsTab> */}
          <SettingsTab
            title="Show Empty Fields"
            tip="Show custom fields that don't have any value entered"
            messages={{
              one:`Turn this on if you want to see all custom fields for each contact, even if that contact doesn't have any data for that field.`,
              two:`We recommend leaving this off to reduce clutter when looking at your contacts.`
            }}
          >
            <Typography>Show empty fields</Typography>
            <Switch
              checked={updatedGeneralSettings.show_empty_fields}
              onChange={(e) => setUpdatedGeneralSettings(prev => ({ ...prev, show_empty_fields: e.target.checked }) )}
            />
          </SettingsTab>

        </Grid>
        <Grid
          item
          order={{xs:0, md:1}}
          sx={{
            p:2
          }}
          xs={12}
          md={6}
        >

        </Grid>
      </Grid>
  )
}

export default PreferencesGeneral

const SettingsTab = (props) => {
  const { children, title, tip, messages={one:null,two:null} } = props;
  
  return (
    <Grid
      container
      // onClick={() => setCurrentLayout(tab.id)}
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
      style={{
        alignItems:'center',
        justifyContent:'space-between',
        width:'100%',
        position:'relative',
        minHeight:'57px',
        transition:'.4s',
        paddingLeft:24,
        cursor:'pointer',
      }}
    >
      <Grid
        item
        sx={{
          display:'flex',
          alignItems:'center',
          gap:1
        }}
        xs
      >
        { cloneElement( children.length ? children[0] : children, { } ) }
        {
          (title || tip) &&
            <Infotip
              question
              title={title}
              tip={tip}
              messages={{
                one: messages.one,
                two: messages.two
              }}
            />
        }
      </Grid>
      <Grid
        item
        xs="auto"
      >
        { cloneElement( children.length ? children[1] : children, { } ) }
      </Grid>
    </Grid>
  )
}


const MenuTab = (props) => {
  const { tab, index, currentLayout, setCurrentLayout, handleCustomFieldLabel } = props;
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const theme = useTheme();

  return (
    <Grid
      onClick={() => setCurrentLayout(tab.id)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        position:'relative',
        height:'57px',
        display:'flex',
        alignItems:'center',
        background: currentLayout === tab.id ? `${theme.palette.primary.light}10` : 'none',
        transition:'.4s',
        // paddingLeft:24,
        padding:'0 48px',
        cursor:'pointer',
        // borderRight: currentLayout === tab.id ? `10px solid blue` : 'none'
      }}
    >
      {
        edit
          ?
            <InputField
              value={tab.label}
              onChange={(e) => handleCustomFieldLabel(e.target.value, index)}
              onBlur={() => setEdit(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setEdit(false);
                }
              }}
              style={{
                maxWidth:170
              }}
            />
          :
              <CustomTooltip
                title={tab.label?.length > 14 ? tab.label : null} // Only show tooltip if label is long enough to overflow
              >
                <Typography
                  sx={{ // Match nav button style in django
                    fontSize: '1rem',
                    fontWeight: currentLayout === tab.id ? 500 : 400,
                    lineHeight:1.2,
                    color:'inherit',
                    textTransform:'none',
                    maxWidth:150,
                    overflow:'hidden',
                    whiteSpace:'nowrap',
                    textOverflow:'ellipsis',
                    '&&:hover': {
                      fontWeight:500,
                      background:'none'
                    }
                  }}
                >
                  {tab.label}
                </Typography>
              </CustomTooltip>
      }

    </Grid>
  )
}
