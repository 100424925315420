import React from 'react'
import RegisterForm from '../auth/RegisterForm'
import Register from '../auth/Register'
import { Grid, Typography } from '@mui/material'
import { LogoHorizontal } from '../../assets/logos'
import { useTheme } from '@emotion/react'

const Step1Register = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      variant="center"
      style={{
        marginTop:40,
        paddingBottom:'16px'
      }}
    >
      <Grid
        container
        // justifyContent="center"
        sx={{
          // p: 3,
          boxShadow:'0 0 10px #00000030',
          background:'#ffffff',
          borderRadius:2
        }}
        xs={11}
        sm={10}
        md={8}
        lg={6}
      >
        <Grid
          container
          sx={{
            flexDirection:'column',
            py:4,
            px:{xs:2, sm:4, lg:8}
          }}
        >
          <Grid
            variant="center"
            sx={{
              flexDirection:'column'
            }}
          >
            {/* <LogoHorizontal
              width={65}
            /> */}
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                fontSize:'1.4rem',
                fontWeight: 600
              }}
            >
              REGISTER
            </Typography>
          </Grid>

          <RegisterForm />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Step1Register